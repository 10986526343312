import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import Roles from '@consts/roles'

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: "vuex",
  storage: window.localStorage,
  reducer: ({user}) => ({user})
});

export default new Vuex.Store({
  state: {
    user: {},
    message: [],
    messageTotal: 0,
    alerts: []
  },
  actions: {
    /**fetch user on login */
    async fetchUser({ commit }) {
      const res = await axios.get("/api/user");
      commit("UPDATE_USER_INFO", res.data.data);
    },

    //fetch all messages of the user
    async fetchUserMessage({ commit }) {
      const res = await axios.get("/api/messages?include=users");
      commit("UPDATE_USER_MESSAGE", res.data.data);
    },

    //fetch unread message total
    async fetchUnreadTotal({ commit }) {
      const res = await axios.post("/api/users/unread-total");
      commit("UPDATE_USER_MESSAGE_TOTAL", res.data.total);
    },
    async fetchAlerts({ commit }) {
      const { data: { data } } = await axios.get("/api/alerts");
      commit("SET_ALERTS", data);
    },
    setAlerts({commit}, alerts) {
      commit("SET_ALERTS", alerts);
    },
    // clear state
    async ClearState({commit}) {
      commit("CLEAR_STATE");
      const logout = await axios.post("/logout");
      window.location.href = "/login"
      return logout;
    }
  },
  mutations: {
    //update the state with user details
    UPDATE_USER_INFO(state, payload) {
      state.user = payload;
    },

    UPDATE_USER_MESSAGE(state, payload) {
      state.message = payload;
    },

    UPDATE_USER_MESSAGE_TOTAL(state, messageTotal) {
      state.messageTotal = messageTotal;
    },
    CLEAR_STATE(state){
      state.user = "";
      state.message = []
      this.alerts = []
    },
    SET_ALERTS(state, payload) {
      state.alerts = payload;
    }
  },
  getters: {
    user: state => state.user,
    messageTotal: state => state.messageTotal,
    isCoach: state => state.user.role === Roles.COACH,
    alerts: state => state.alerts
  },
  plugins: [vuexLocalStorage.plugin]
});
