<template>
    <div class="dropdown-item notify-item notify-item-padding">
        <div class="removeBtn" v-on:click="emit" title="Dismiss Alert">-</div>
        <a v-bind:href="alert.link" v-bind:id="'alert-' + alert.id" class="alert-wrapper">
            <span class="notify-details">{{ alert.alert }}</span>
            <small class="text-muted">{{ alert.time }}</small>
        </a>
    </div>
</template>

<script>
    export default {
        name: 'Alerts',
        props: {
            alert: Object,
            link: String
          },
        methods: {
            emit: function (event) {
                event.stopPropagation();
                this.$emit('event_child', alert.id)
            },
        }
    }
</script>
