<template>
  <div class="card">
    <div :class="['card-header', {'active-event': next}]">
        <h4 style="margin-bottom: 0">{{type}}</h4>
    </div>
    <div class="card-body">
      <div><span style="width: 1em;"><i class="ion ion-calendar m-r-5"/></span>{{ date }}</div>
      <div><span style="width: 1em;"><i class="ion ion-clock m-r-5"/></span>{{ time }}</div>
      <div><span style="width: 1em;margin-left: 3px;margin-right: 3px;"><i class="ion ion-location m-r-5"/></span>{{ location }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventCard',
  props: {
    type: {type: String, default: 'N/A', required: true},
    date: {type: String, default: 'N/A', required: false},
    time: {type: String, default: 'N/A', required: false},
    location: {type: String, default: 'N/A', required: false},
    next: {type: Boolean, default: false, required: false}
  },
  data() {
    return {}
  },
  created() {
  },
  mounted() {
  },
  methods: {}
}
</script>

<style scoped>
  .active-event {
    background-color: #000;
  }
  .active-event > h4 {
    color: #FFF !important;
  }
</style>
