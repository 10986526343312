<template>
  <li class="dropdown" id="alerts-dropdown">
    <a class="nav-link dropdown-toggle arrow-none waves-light waves-effect keep-open" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
      <i class="mdi mdi-bell noti-icon"></i>
      <span class="badge badge-pink noti-icon-badge" v-if="unreadCount > 0">{{ unreadCount }}</span>
    </a>
    <div class="dropdown-menu dropdown-menu-right dropdown-arrow dropdown-menu-md" id="dropdown-menu" aria-labelledby="Preview">
      <div id='dropdown-item' class="dropdown-item noti-title">
        <h5 class="font-16">Alerts</h5>
        <span v-if="alerts.length === 0" style="font-family: 'urw-din', sans-serif;">No new alerts.</span>
      </div>
      <alert v-for="(alert, index) in alerts" v-if="index < 5" :key="alert.id" :alert="alert" @click="markAsRead(alert)"/>
      <div class="row">
        <div class="col-4 pr-0">
          <a href="/alert" class="view-all-dropdown-button dropdown-item notify-item notify-all">View All</a>
        </div>
        <div class="col pl-0">
          <a class="mark-all-dropdown-btn dropdown-item notify-item notify-all" @click="markAllRead">Mark All As Read</a>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
  import axios from 'axios'
  import {mapActions, mapGetters} from 'vuex'

  export default {
    name: 'Alerts',
    props: {
      type: String,
      id: Number
    },
    mounted() {
      this.$store.dispatch('fetchAlerts')
    },
    computed: {
      ...mapGetters(['alerts']),
      unreadCount() {
        return this.alerts?.filter(alert => alert.read === 0).length || 0
      }
    },
    methods: {
      markAsRead: async ({ id }) => {
        await axios.delete(`/api/alerts/${id}`)
        await this.$store.dispatch('fetchAlerts')
      },
      markAllRead: async () => {
        await axios.post('/api/alerts/mark-all-read')
        await this.$store.dispatch('setAlerts', [])
      },
    }
  }
</script>
