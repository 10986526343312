<template>
  <div>
    <prospect-lists-table
      ref="prospectlist-table"
      :organization_id="organization_id"
      :organization_name="organization_name"
      :coach_name="coach_name"
      :share="isShareInterface()"
    ></prospect-lists-table>
  </div>
</template>

<script>
  import ProspectListsTable from "./ProspectListsTable"
  import utilityCode from "../utils"

  export default {
  	components: { ProspectListsTable },

  	props: {
      organization_id: {type: String, default: '0', required: false},
      organization_name: {type: String, default: null, required: false},
      coach_name: {type: String, default: null, required: false},
      sport_name: {type: String, default: 'football', required: false},
      sharing: {type: String, default: '0', required: false},
  	},

  	computed: {

    },

  	methods: {
      isShareInterface(){
        return (this.sharing === '1') ? true : false;
      },
    },

  	mounted(){
  	  utilityCode();
  	},
  }
</script>

<style scoped>

</style>
