<template>
  <div :class="['first-journey-wrapper', sport_name]">
    <div class="cta-container">
      <h2>Invite players to view their info</h2>
      <div class="btn-container">
        <a href="imports/ExampleInviteImport.csv" download="InviteExample.csv" class="btn download-btn">Example file</a>
        <invite-button
          class="invite-button"
          @file_chosen="fileChosen"
        ></invite-button>
      </div>
    </div>
  </div>
</template>

<script>
import InviteButton from '../myinvites/components/InviteButton';

export default {
  name: 'FirstJourney',
  components: { InviteButton },
  props: {
    sport_name: {type: String, default: 'football', required: false},
  },
  methods: {
    fileChosen(file) {
      this.$emit('file_chosen', file);
    }
  }
}
</script>
<style scoped lang="scss">
.first-journey-wrapper {
  width: 100%;
  height: 85vh;
  //background-image: url('/build/images/verified_football_bg.png');
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.baseball{
  background-image: url('/build/images/verified_baseball_bg.jpg');
}
.football{
  background-image: url('/build/images/verified_football_bg.png');
}
.softball{
  background-image: url('/build/images/verified_softball_bg.jpg');
}
.mens_soccer{
  background-image: url('/build/images/verified_mens_soccer_bg.jpg');
}
.womens_soccer{
  background-image: url('/build/images/verified_womens_soccer_bg.jpg');
}
.cta-container {
  text-align: center;
}

h2 {
  margin-bottom: 2rem;
  color: white;
}

.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.download-btn {
  font-family: KairosSans,sans-serif;
  text-transform:uppercase;
  background-color: #E5E5E5;
}
</style>
