<template>
  <div style="padding:25px;">
    <p class="h5" style="color:white;">Invite Players</p>
    <div id="step-one" v-if="step === 1">
      <h3 style="color:white;">Athlete Information</h3>
      <preview-invited-players-grid :data_rows="player_data" @save_invited_players="saveInvitedPlayers"></preview-invited-players-grid>
    </div>
    <div v-if="step === 2">
      <h3 style="color:white;">Invite name</h3>
      <invite-name @saveInviteName="saveInviteName" @back="back"></invite-name>
    </div>
    <div v-if="step === 3">
      <h3 style="color:white">Select information you need</h3>
      <profile-attribute-picker @attributes_selected="setAttributes" @back="back"></profile-attribute-picker>
    </div>

    <div class="step-three" v-if="step === 4">
      <h3 style="color:white">Inspire your players to join</h3>
      <coach-message @confirm_info="confirmInfo" @back="back"></coach-message>
    </div>

    <div class="step-four" v-if="step === 5">
      <h3 style="color:white">Confirm Invite Information</h3>
      <confirm-invite :count="sent_count" :invite_data="invite_data" @send_invite="saveInvitation" @back="back"></confirm-invite>
    </div>

    <div class="step-four" v-if="step === 6 && error === null">
      <h3 style="color:white">Success</h3>
      <success-message :count="sent_count" :invite_data="invite_data" @invitation_complete="invitationComplete"></success-message>
    </div>

    <div class="step-four" v-if="step === 6 && error !== null">
      <h3 style="color:white">Error Sending Invitation</h3>
      <error-message :error_message="error" @invitation_complete="invitationComplete"></error-message>
    </div>

  </div>
</template>
<script>
import PreviewInvitedPlayersGrid from "../myinvites/components/PreviewInvitedPlayersGrid";
import InviteName from '../myinvites/components/InviteName';
import ProfileAttributePicker from "../myinvites/components/ProfileAttributePicker";
import CoachMessage from "../myinvites/components/CoachMessage";
import SuccessMessage from "../myinvites/components/SuccessMessage";
import ErrorMessage from "../myinvites/components/ErrorMessage";
import ConfirmInvite from "../myinvites/components/ConfrmInvite";

export default {
  name: "InvitePlayers",
  components: {
    PreviewInvitedPlayersGrid,
    InviteName,
    ProfileAttributePicker,
    CoachMessage,
    SuccessMessage,
    ErrorMessage,
    ConfirmInvite
  },
  props: {
    player_data: { type: Array, default:{}}
  },
  data: () => ({
    step: 1,
    requested_attributes: [],
    invited_players: [],
    error: null,
  }),
  methods: {
    startMessage() {

    },
    saveInvitedPlayers(data) {
      this.invited_players = data;
      this.step = 2;
    },
    saveInviteName(name) {
      this.invite_name = name
      this.step = 3
    },
    setAttributes(data){
      this.requested_attributes = data;
      this.step = 4;
    },
    confirmInfo(message) {
      this.email_title = message.title;
      this.email_message = message.message;
      this.step = 5;
    },
    saveInvitation(){
      this.error = null;
      axios.post('/api/player-invites', {
        invite: {
          name: this.invite_name,
          subject: this.email_title,
          message: this.email_message,
          requested_attributes: this.requested_attributes,
        },
        invited_players: this.invited_players
      }).catch((error) => {
        this.error = 'There was an error saving your invitation. ' + error.response.data.message;
      })
      this.step = 6;
    },
    invitationComplete() {
      this.$emit('invitation_complete');
    },
    back() {
      this.step--;
    }
  },
  computed: {
    sent_count() {
      return this.invited_players.length;
    },
    invite_data() {
      return {
        title: this.email_title,
        message: this.email_message,
        invited_players: this.invited_players,
        requested_attributes:  this.requested_attributes
      }
    },
  }
}
</script>

<style>
  #preview-invites-table .cell h5 {
      text-overflow: ellipsis;
      overflow: hidden;
  }
</style>
