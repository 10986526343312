<template>
  <div>
    <div class="row percentage-cardboxes">
      <stat-card v-if="organization_id" :organization="organization_id" v-for="stat in stats" :key="stat.id" :stat="stat" />
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import PlayerCard from './PlayerCard'
  import StatCard from './StatCard'

  export default {

    name: 'Stats',
    props: {
      positions: Object,
      organization_id: String
    },

    global: {
      loaded: false
    },

    data() {
      return {
        stats: [],
        position: Cookies.get('advanced_search') != null && JSON.parse(Cookies.get('advanced_search'))['position'] !== '' ? JSON.parse(Cookies.get('advanced_search'))['position'] : 'QB',
        players: false
      }
    },

    mounted: function() {
      this.organization_id != 0 && this.setStats()
      // this.getPlayers()
    },

    methods: {
      getPlayers() {
        axios.get('/api/players/top?position=' + this.position).then(response => {
          this.players = response.data.data
        }).catch(error => {
          console.log(error)
        })
      },

      changePosition(position) {
        this.position = position

        let advanced_search = {}
        advanced_search = JSON.parse(Cookies.get('advanced_search'))
        advanced_search['position'] = position
        advanced_search = JSON.stringify(advanced_search)

        Cookies.set('advanced_search', advanced_search, { expires: Infinity })

        this.getPlayers()
      },

      setStats() {
        axios.get(`/api/organizations/${this.organization_id}/stats`).then(response => {
          this.stats = response.data.data
        }).catch(error => {
          console.log(error)
        })
      }
    }
  }
</script>
