<template>
  <div class="my-invites-wrapper">
    <div class="input-group">
      <div class="has-search">
        <img src="/build/images/icon_magnifying_glass.svg" alt="">
        <input
          id="searchList"
          class="form-control"
          placeholder="Search player or list"
          type="search"
          v-model="params.search"
          @keyup="retrieve(1)"
        />
      </div>
    </div>

    <div class="cta-container">
      <table class="table">
        <thead>
        <tr>
          <th @click="orderBy('first_name')">Player <i v-if="params.orderby === 'first_name'" :class="`fa fa-angle-${params.sort === 'asc' ? 'up' : 'down'}`"></i></th>
          <th @click="orderBy('positions')">Positions <i v-if="params.orderby === 'positions'" :class="`fa fa-angle-${params.sort === 'asc' ? 'up' : 'down'}`"></i></th>
          <th @click="orderBy('grad_year')">Grad Year <i v-if="params.orderby === 'grad_year'" :class="`fa fa-angle-${params.sort === 'asc' ? 'up' : 'down'}`"></i></th>
          <th @click="orderBy('height')">Height <i v-if="params.orderby === 'height'" :class="`fa fa-angle-${params.sort === 'asc' ? 'up' : 'down'}`"></i></th>
          <th @click="orderBy('email')">Email <i v-if="params.orderby === 'email'" :class="`fa fa-angle-${params.sort === 'asc' ? 'up' : 'down'}`"></i></th>
          <th @click="orderBy('phone')">Cell Phone <i v-if="params.orderby === 'phone'" :class="`fa fa-angle-${params.sort === 'asc' ? 'up' : 'down'}`"></i></th>
        </tr>
        </thead>
        <tbody v-if="players.length > 0" v-for="player in players">
          <tr>
            <td :class="player.user_id ? 'active-subscribed-td' : ''">
              <a
                v-if="player.user_id"
                @mouseenter="showArrowSpan(player.user_id)"
                @mouseleave="hideArrowSpan"
                :class="player.user?.subscribed ? 'active-subscribed' : ''"
                :href="'/recruit/' + player.user_id">
                  <img v-if="player.user?.subscribed" class="checkmark-green" src="/build/images/icon_checkmark_green.svg" alt="">
                  {{ player.name }}
                  <span v-if="player.user?.subscribed && showArrow === player.user_id" class="down-arrow"></span>
              </a>
              <button
                v-else
                data-dynamic-component="check-player-status"
                :data-modal-title="player.name"
                :data-props="`
                  checked_organization='${player.organization_id}'
                  checked_organization_type='${0}'
                  checked_player_id='${player.id}'
                  checked_player_first_name='${player.first_name}'
                  checked_player_last_name='${player.last_name}'
                  checked_player_phone='${player.phone ?? ''}'
                  checked_player_email='${player.email ?? ''}'
                  checked_player_positions='${player.positions}'
                  checked_player_grad_year='${player.grad_year}'`"
                class="btn player-status-btn"
              >
                {{ player.name }}
              </button>
            </td>
            <td>{{ getPositions(player) }}</td>
            <td>{{ getGradYear(player) }}</td>
            <td>{{ getHeight(player) }}</td>
            <td>{{ player.email }}</td>
            <td>{{ getPhone(player) }}</td>
          </tr>
        </tbody>
        <pagination
          :pagination="pagination"
          :retrieve="retrieve"
          :resource-count="players.length"
          :params="params"
          :colSpan="6"
        />
      </table>
      <div v-if="players.length === 0" class="table-content">
        <p>You currently Have No Prospects.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../myinvites/components/Pagination";
import CheckPlayerStatus from "../../components/CheckPlayerStatus";
export default {
  name: 'CollegeRosterTable',
  components: {Pagination, CheckPlayerStatus},
  props: {
  },
  data() {
    return {
      players: [],
      column: '',
      pagination: {},
      params: {
        page: 1,
        limit: 10,
        search: '',
        orderby: 'created_at',
        sort: 'desc',
      },
      query: new URLSearchParams(window.location.search),
      isModalOpen: false,
      activePlayer: null,
      showArrow: 0,
    }
  },
  mounted() {
    for (let param in this.params) {
      this.params[param] = this.query.get(param) || this.params[param]
    }
    this.retrieve(this.params.page)
  },
  methods: {
    retrieve(page) {
      this.params.page = page
      let query = Object.keys(this.params).map(key => key + '=' + this.params[key]).join('&');
      axios.get('api/college-prospects?' + query).then(response => {
        this.players = response.data.data
        this.pagination = response.data.meta
      })
    },
    orderBy(column) {
      this.params.sort = this.params.sort === 'desc' ? 'asc' : 'desc'
      if (column !== this.params.orderby) {
        this.params.sort = 'asc'
        this.params.orderby = column
      }
      this.retrieve(this.params.page)
    },
    checkPlayerStatus(player) {
      this.activePlayer = player
      this.isModalOpen = true
    },
    closeModal() {
      this.isModalOpen = false
    },
    getPhone(player) {
      return player.user ? player.user?.profiles[0]?.phone : player.phone
    },
    getGradYear(player) {
      return player.user ? player.user?.profiles[0]?.grad_year : player.grad_year
    },
    getHeight(player) {
      return player.user ? player.user?.profiles[0]?.height : ''
    },
    getPositions(player) {
      return player.user ? this.getSportProfilePositions(player.user?.profiles[0]) : player.positions.join(', ')
    },
    getSportProfilePositions(profile) {
      if (profile['softball_profile']) {
        return profile['softball_profile'].positions.map(position => position.shortcode).join(', ')
      }
      if (profile['baseball_profile']) {
        return profile['baseball_profile'].positions.map(position => position.shortcode).join(', ')
      }
      if (profile['football_profile']) {
        return profile['football_profile'].positions.map(position => position.shortcode).join(', ')
      }
    },
    showArrowSpan(id) {
      this.showArrow = id;
    },
    hideArrowSpan() {
      this.showArrow = 0;
    }
  }
}
</script>

<style scoped lang="scss">
.my-invites-wrapper {
  width: 100%;

  & .input-group{
    margin-bottom: 3px;
  }
}

.has-search{
  width: 30%;

  & .form-control{
    background: #F4F4F4;
    border: 0;
    color: #A8A8A8;
    padding-left: 2.5rem;
  }

  & .form-control-feedback {
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align:center;
    pointer-events: none;
    color: black;
  }
  & img {
    position:absolute;
    top:25%;
    left: 10px;
    transform: translateY(25%);
  }
}

#searchList {
  box-shadow: inset 0px -1px 0px #8D8D8D;
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.16px;
}

.invite-button-group{
  flex: 25%;
  justify-content: flex-end;
  font-family: KairosSans,sans-serif;

  & button {
    text-transform:uppercase;
    font-size: 14px;
    font-weight: 700;
    height: 32px;
    align-self: flex-end;
    letter-spacing: 0.16px;

    svg {
      position:relative;
      top: -1px;
    }
  }

  & #importPlayers{
    background-color: #E7F816;
    color: black;
  }

  & #invitePlayer{
    background-color: black;
    color:white;
  }
}

.cta-container {
  text-align: center;
}

h2 {
  margin-bottom: 2rem;
  color: white;
}

.table {
  font-family: 'DINPro', sans-serif;
  font-size: 14px;
  line-height: 18px;

  margin-bottom: 0;

  tr {
    border-bottom: 0 !important;

    button {
      padding: 0;
    }
  }

  .cursor-pointer-td {
    cursor: pointer;
  }
}

.active-row {
  background: #DFDFDF;
  z-index: 1;
}

th {
  border-top: 0 !important;
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
}

th, td {
  text-align: center !important;
}

td {
  border-top: 0;
  position: relative;
}

td img {
  cursor: pointer;
}

.table-content{
  background-color: #f1f1f1;
  font-family: KairosSans,sans-serif;
  padding: 0 !important;
  text-transform:uppercase;
  width: 100%;
}

.table-content p{
  color: #ABABAB;
  font-size: 14px;
  padding: 13px 0 17px;
  width: 100%;
}

.player-status-btn {
  font-family: 'DINPro', sans-serif;
  font-size: 14px;
  line-height: 18px;
  padding: 8px 7px !important;
  text-decoration: none;
  text-transform: capitalize;
}

.down-arrow {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #393939;
  content: "";
  position: absolute;
  left: 47.25%;
  top: 0;
}

.active-subscribed-td {
  display: flex;
  justify-content: center;
}

.active-subscribed {
  align-items: center;
  background: #ECFDF3;
  display: flex;
  justify-content: center;
  padding: 8px 7px;
  width: fit-content;

  .checkmark-green {
    margin-right: 8.3px;
  }

  &:hover {
    color: #000;
  }

  &:hover:after {
    background: #393939;
    border-radius: 2px;
    color: #fff;
    content: "ACTIVE SUBSCRIPTION";
    font-family: KairosSans, serif;
    padding: 8px 16px;
    position: absolute;
    top: -32px;
  }
}
</style>
