export default () => {
  const { EventBus } = window

  $.ajaxSetup({
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
    },
  });

  $(document).ready(() => {

    $("body").tooltip({ selector: '[data-toggle=tooltip]' });

    let query = {};

    initializeSportFilter();

    function initializeSportFilter() {
      $('#sport').select2({
        placeholder: 'Sport',
        ajax: {
          url: '/api/sport-autocomplete-ajax',
          dataType: 'json',
          processResults: function (data) {
            return {
              results: $.map(data, function (item) {
                return {
                  text: item.pretty_name,
                  id: item.name
                }
              })
            };
          },
          cache: true
        }
      });
    }

    function resetPlaceholders() {
      $('#sport').val('').trigger('change.select2');
      $('#organization-search').val('')

      window.vueInstance.$refs['screen-organizations'].$refs['organizations-table'].filters[0].value = ''
      window.vueInstance.$refs['screen-organizations'].$refs['organizations-table'].filters[1].value = ''
    }

    $('#sport').on('select2:select', (e) => {
      const { id } = e.params.data
      window.vueInstance.$refs['screen-organizations'].$refs['organizations-table'].filters[1].value = id
    })

    $('#clearFilters').on('click', () => {
      resetPlaceholders();
      initializeSportFilter();
    });

    $.fn.DataTable.ext.pager.numbers_length = 5;
  });
}
