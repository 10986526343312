<template>
  <div>
    <div v-show="! videoLoaded" class="loading" v-cloak>
      <span>
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div><br>
        Download/Processing Video
      </span>
    </div>
    <div v-if="this.video" class="videoBox" v-show="this.videoLoaded">
      <div v-show="videoLoaded" class="measurement-video">
        <video :id="'video_' + this.video.id" class="video-js" data-setup='{"controls": true, "autoplay": false, "preload": "auto", "muted": false, "loop": true}'>
          <source :src="this.video.file_upload.data.url"></source>
        </video>
      </div>
    </div>
  </div>
</template>

<script>
    import axios from 'axios'

    export default {

        name: 'verification-detail-video',
        props: ['video'],

        data: function() {
          return {
            videoLoaded: false,
            videoDom: false
          }
        },

        mounted() {
          this.loadVideo()
        },

        methods: {
          loadVideo(id) {
            let self = this
            this.videoDom = $(`#video_` + self.video.id)[0]
            videojs(this.videoDom).ready(function() {
              self.videoLoaded = true
              this.currentTime(self.video.start_time)
            })
          },
        }
    }
</script>
