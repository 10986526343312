<template>
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="fill"
      d="M8.666 6.333H3.333v1.334h5.333V6.333ZM3.333 3.667h4V5h-4V3.667ZM8.666 9H3.333v1.333h5.333V9Z"
    />
    <path
      :fill="fill"
      fill-rule="evenodd"
      d="M8.276.333H.666v13.334h10.667V3.39L8.276.333ZM2 12.333V1.667h5.724L10 3.943v8.39H2Z"
      clip-rule="evenodd"
    />
  </svg>
</template>

<script>
  export default {
    name: 'FileIcon',
    props: {
      fill: {
        type: String,
        default: '#000',
      }
    }
  }
</script>
