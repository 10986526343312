import copy from "copy-to-clipboard";
import messages from "../assets/js/ll_messages.js";
import EventBus from "./lib/EventBus";
import Lang from "lang.js";
import SDSPusher from "./lib/pusher";
import store from "./store";
import VueWorker from 'vue-worker';
import Vue from 'vue'
import VueFormulate from '@braid/vue-formulate';
import Toast, { TYPE } from 'vue-toastification';
import CloseIcon from '@components/svg/CloseIcon';
import ToastNotification from '@components/ToastNotification';
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'

// Styles
import "vue-toastification/dist/index.css";

require("./bootstrap");
require("./signing-day");

SigningDay.init();

window.Vue = require("vue").default;
window.Vuex = require("vuex");
window.vuedraggable = require("vuedraggable");
window.VueTheMask = require("vue-the-mask");
window.copy = copy;
window.EventBus = new EventBus();
window.SDSPusher = new SDSPusher();

const default_locale = window.default_language;
const fallback_locale = window.fallback_locale;
const VueToastOptions = {
  icon: {
    iconClass: 'Vue-Toastification__icon',
    iconTag: 'span'
  },
  hideProgressBar: true,
  position: 'bottom-right',
  timeout: 5000,
  toastClassName: 'toastr',
  transition: 'Vue-Toastification__fade',
  closeButton: CloseIcon, // todo - Figure out how to preload close icon image
};

Vue.use(VueTheMask);
Vue.use(VueWorker);
Vue.use(VueFormulate);
Vue.use(Toast, VueToastOptions);
Vue.directive('tooltip', VTooltip);
Vue.directive('close-popover', VClosePopover);
Vue.component('v-popover', VPopover);


Vue.mixin({
  methods: {
    toaster: function ({ type, heading, message, buttonLabel, onClick = () => {} }) {
      this.$toast({
        component: ToastNotification,
        props: {
          heading,
          message,
          buttonLabel,
        },
        listeners: {
          onClick
        }
      },
      {
        type
      });
    }
  }
});

Vue.prototype.trans = new Lang({
  messages,
  locale: default_locale,
  fallback: fallback_locale
});

/**
 * Setup the global Vue components
 */
require("./components.js");

Vue.directive("select2", {
  inserted(el) {
    $(el).on("select2:select", () => {
      const event = new Event("change", { bubbles: true, cancelable: true });
      el.dispatchEvent(event);
    });

    $(el).on("select2:unselect", () => {
      const event = new Event("change", { bubbles: true, cancelable: true });
      el.dispatchEvent(event);
    });
  }
});

if ($("#app")[0]) {
  const app = new Vue({
    el: "#app",
    store: store,
    created() {
      this.$on('fetchUserMessage', () => {
        // TODO - temp fix
        this.$store.dispatch("fetchUserMessage");
      });
    }
  });

  window.vueInstance = app;
}

if ($("#activityBar")[0]) {
  const activityBar = new Vue({
    el: "#activityBar",
    store: store
  });
}

if ($("#alerts")[0]) {
  const alerts = new Vue({
    el: "#alerts",
    store: store
  });
}

if ($("#playerActions")[0]) {
  const alerts = new Vue({
    el: "#playerActions",
    store: store
  });
}

if ($("#tagsView")[0]) {
  const tagsView = new Vue({
    el: "#tagsView",
    store: store
  });
}

if ($("#navWrapper")[0]) {
  const tagsView = new Vue({
    el: "#navWrapper",
    store: store
  });
}

if ($("#left-menu")[0]) {
  const leftMenu = new Vue({
    el: "#left-menu",
    store: store
  });
}
