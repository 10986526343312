<template>
  <fragment>
    <IconButton @onClick="toggleDrawer" title="Edit additional info">
      <PencilIcon />
    </IconButton>
    <Drawer
      heading="Edit Additional Info"
      :isOpen="isDrawerOpen"
      @onClose="handleCancel"
    >
      <EditPlayerAdditionalInfoForm
        v-bind="{defaultValues, player}"
        :name="formName"
        @onCancel="handleCancel"
        @onSuccess="onSuccess"
      />
    </Drawer>
  </fragment>
</template>

<script>
  import { Fragment } from 'vue-frag';
  import Drawer from '@components/Drawer';
  import IconButton from '@components/IconButton';
  import PencilIcon from '@components/svg/PencilIcon';
  import EditPlayerAdditionalInfoForm from '@screens/myinvites/InviteListDetails/InviteListDetailsTable/components/EditPlayerAdditionalInfoForm'

  export default {
    name: "EditPlayerAdditionalInfoButton",
    components: { Fragment, Drawer, EditPlayerAdditionalInfoForm, IconButton, PencilIcon },
    props: ['player'],
    inject: ['getPlayers'],
    data() {
      return {
        isDrawerOpen: false,
        formName: `editPlayerAdditionalInfoForm-${this.player?.id}`,
        defaultValues: {
          parent_name: this?.player?.parent_name,
          parent_email: this?.player?.parent_email,
          parent_phone: this?.player?.parent_phone,
          phone: this?.player?.phone,
        },
      }
    },
    methods: {
      handleCancel() {
        this.$formulate.reset(this.formName, this.defaultValues);
        this.toggleDrawer();
      },
      toggleDrawer() {
        this.isDrawerOpen = !this.isDrawerOpen;
      },
      onSuccess() {
        this.toggleDrawer();
        this.getPlayers();
      }
    }
  }
</script>
