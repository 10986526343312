<template>
  <fragment>
    <div :class="`modal-wrapper ${isOpen ? 'modal-wrapper--open' : ''}`" @click="$emit('onClose')" />
    <div id="modal" :class="`modal-body modal-body-${theme} ${isOpen ? 'modal-body--open' : ''}`" :style="styles">
      <p class="subheading">{{ subHeading }}</p>
      <h2 class="heading">{{ heading }}</h2>
      <IconButton className="modal-close" @onClick="$emit('onClose')" title="Close modal">
        <CloseIcon />
      </IconButton>
      <slot></slot>
      <div class="modal-footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </fragment>
</template>

<script>
  import { Fragment } from 'vue-frag';
  import Button from '@components/Button';
  import IconButton from '@components/IconButton';
  import CloseIcon from '@components/svg/CloseIcon';

  export default {
    name: 'Modal',
    components: { CloseIcon, IconButton, Button, Fragment },
    props: {
      isOpen: Boolean,
      heading: String,
      subHeading: String,
      theme: {
        type: String,
        default: 'dark',
      },
      styles: {
        type: Object,
        default: {},
      },
    },
    mounted() {
      window.addEventListener('keydown', (event) => {
        if (this.isOpen && event.key === 'Escape') {
          this.$emit('onClose');
        }
      });
    },
    beforeDestroy() {
      window.removeEventListener('keydown', (event) => {
        if (this.isOpen && event.key === 'Escape') {
          this.$emit('onClose');
        }
      });
    },
  };
</script>

<style scoped lang="scss">
  .modal-wrapper {
    background-color: rgba(0,0,0,0.66);
    display: none;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 999;

    &.modal-wrapper--open {
      display: flex;
    }
  }
  .modal-body {
    background-color: #262626;
    display: none;
    left: 50%;
    max-width: 640px;
    min-height: 380px;
    overflow: auto;
    padding: 1.5rem 1rem 4.6rem;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 2rem);
    z-index: 9999;

    &.modal-body--open {
      display: block;
    }

    &-light {
      background-color: #fff;

      .heading {
        color: #000;
      }
      .subheading {
        color: #000;
      }
    }
  }

  .modal-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  .heading {
    color: #f4f4f4;
    font-family: 'KairosSans';
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }

  .subheading {
    color: #C6C6C6;
    font-family: DINPro;
    font-size: 0.875rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }

  .modal-footer {
    display: flex;
    bottom: 0;
    border: none;
    left: 0;
    position: absolute;
    width: 100%;
  }
</style>
