import SportPlayerToolKit from "./SportPlayerToolKit.js";

export default class SportPlayer {
  constructor(){
    this.playerToolKit = new SportPlayerToolKit();
  }

  /**
   * mapHeaderToFields definition
   * @param {object} fields
   * @param {object} headers
   * @returns {object} mappedHeaders
   */
  mapHeaderToFields(fields, headers){
    const fieldMap = this.playerToolKit.getFieldMap();
    const isMatchHandler = this.playerToolKit.isMatchHandler;
    const mappedHeaders = {};

    Object.keys(fields).forEach(function(field){
      if (typeof fieldMap[field].fieldMap === 'function') {
        headers.forEach((target, index) => {
          // if field and target match, then save mapping and delete target from rotation
          if( isMatchHandler(target, fieldMap[field].fieldMap) && (typeof mappedHeaders[field] === 'undefined') ) {
            mappedHeaders[field] = index;
            delete headers[index];
            return;
          }
        });
      }
    });

    return mappedHeaders;
  }

  /**
   * mapPlayerToPosition definition
   * @param {string} playerPositions
   * @param {string/object} sdsPositions
   * @param {boolean} sportType
   * @returns {object} mappedPositions
   */
  mapPlayerToPosition(playerPositions, sdsPositions, sportType){
    // set playerPositionsArray
    // replace any slashes with comma in playerPositions, then split the string into an array using comma as seperator
    const playerPositionsArray = (playerPositions !== null) ? playerPositions.replaceAll('/',',').split(",") : [];

    const selectedPositions = [];
    var positionsStr = '';
    const success = [];
    const failed = [];
    var failedStr = '';

    // get positionMap for current sportType
    const positionMap = this.playerToolKit.getPositionMap(sportType);
    const isMatchHandler = this.playerToolKit.isMatchHandler;

    // cycle through each playerPosition and try to map to a sdsPosition
    playerPositionsArray.forEach(function(playerPosition, key){
      var matchFound = false;

      // remove any spaces in positons string and convert to lowercase
      playerPosition = playerPosition.replaceAll(' ', '').toLowerCase();

      // deep copy current errors
      var sportPositions = JSON.parse(JSON.stringify(sdsPositions));

      // cycle through each sportPosition and try to map to current playerPosition
      sportPositions.forEach((sportPosition, index) => {
        // if positionMap exists for current sportPosition and sportPosition matches with playerPosition,
        // then save mapping and delete positions from rotation

        // isMatchHandler(playerPosition, positionMap[sportPosition.shortcode]  );
        if ( isMatchHandler(playerPosition, positionMap[sportPosition.shortcode]) && (typeof positionMap[sportPosition.shortcode] !== 'undefined') ) {
          selectedPositions.push(sportPosition.name);
          positionsStr += sportPosition.shortcode+',';
          matchFound = true;

          delete sportPositions[index];
          return;
        }
      });

      // capture matching results
      if(matchFound){
        success.push(playerPosition);
      }
      else{
        failed.push(playerPosition);
        failedStr += "'"+playerPosition+"', "; 
      }
    });

    const mappedPositions = {
      selectedPositions: selectedPositions,
      positions: positionsStr,
      success: success,
      failed: failed,
      failedStr: failedStr,
    };

    return mappedPositions; 
  }

  /**
   * getPlayerValidator definition
   * @param {object} required
   * @returns {function} playerValidator
   */
  getPlayerValidator(required){
    /**
     * anonymous function definition
     * @param {object} playerData
     * @param {function} emailValidationResponseHandler
     * @returns {object} errors
     */
    return (playerData, emailValidationResponseHandler=null) => {
      const errors = {};

      const playerValidationMap = this.playerToolKit.getPlayerValidationMap();
      const isValidHandler = this.playerToolKit.isValidHandler;

      Object.keys(playerData.attributes).forEach(function(field, index) {
        if (typeof playerValidationMap[field].validate === 'function') {
          if (field === 'email') {
            playerValidationMap[field].validate(playerData, emailValidationResponseHandler);
          }
          else{
            const error = isValidHandler(field, playerData.attributes[field], required[field], playerValidationMap[field].validate );
            if (error) errors[field] = error;
          }
        }
      });

      return errors;
    };
  }
}
