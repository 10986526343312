<template>
  <div>
    <data-tables-server id="TeamRosterLevelTable"
      :data="rosterLevels"
      :total="count"
      :pagination-props="{
          disabled: true
      }"
      class="table"
      @query-change="loadData"
      >
      <!-- Drag and Drop for the sorting.  -->
        <el-table-column label="Order" sort-by="order" sortable>
          <template slot-scope="{ row: { order } }">{{ order }}</template>
        </el-table-column>
        <el-table-column label="Name" sort-by="name" sortable>
          <template slot-scope="{ row: { name } }">{{ name }}</template>
        </el-table-column>
        <el-table-column label="Shortcode" align="center" sort-by="shortcode" sortable>
          <template slot-scope="{ row: { shortcode } }">{{ shortcode }}</template>
        </el-table-column>
        <el-table-column label="Number of Players" align="center" sort-by="player_count" sortable>
          <template slot-scope="{ row: { player_count } }">{{ player_count }}</template>
        </el-table-column>
        <el-table-column label="Actions" align="center">
          <template slot-scope="{ row }">
          <span @click="editRosterLevel(row)" id="edit-roster-level"><i class="mdi mdi-lead-pencil"></i></span>
          <span @click="deleteRosterLevel(row.id)" id="remove-roster-level"><i class="mdi mdi-delete-forever red"></i></span>
          </template>
        </el-table-column>
    </data-tables-server>
    <div class="row" style="margin-top: 10px;">
      <button type="button" class="btn btn-primary waves-effect waves-light" style="margin-left: 10px; margin-top: 10px;" @click="createNewTeamRosterLevel()">Create Roster Level</button>
    </div>
  </div>

</template>

<script>
import DataTablesServer from '@components/ui/DataTablesServer'
import axios from 'axios'

export default {
  name: 'TeamRosterLevelTable',
  components: { DataTablesServer },
  props: {
    role: {type: String, default: "", required: true},
    organization_id: {type: String, default: "", required: true},
    sport_id: {type: String, default: "", required: true}
  },
  data() {
    return {
      url: `/api/organizations/${this.organization_id}/team-roster-levels`,
      previousQuery: null,
      apiData: null,
      loading: false,
      sportname: null,
      order: [
        {
          id: 1,
          text: 1
        },
        {
          id: 2, 
          text: 2
        }
      ],
    }
  },
  computed: {
    rosterLevels: v => _.get(v, 'apiData.team_roster_levels', []),
    count: v => _.get(v, 'apiData.count', 0),
    canUpdate: v => ['coach','sport admin','super admin'].includes(v.role)
  },
  methods: {
    async loadData() {
      this.loading = true
      const sportname = this.sportname;
      const response = await axios.get(this.url)
      this.apiData = response.data
      this.loading = false

      // set values for the current sport
      this.apiData.team_roster_levels = this.apiData.all_team_roster_levels[this.sport_id];
      this.apiData.count = this.apiData.team_roster_levels.length;
    },
    // how much of Swal can be templated? uses the same form as edit
    async createNewTeamRosterLevel() {
      const team_level_limit = 20

      if(this.rosterLevels.length <= team_level_limit){
        const { value: formValues} = await Swal.fire({
          title: 'Create Team Roster Level',
          confirmButtonText: 'Save',
          confirmButtonClass: 'btn btn-confirm mt-2',
          showCancelButton: true,
          cancelButtonClass: 'btn btn-cancel ml-2 mt-2',
          html:
          '<div>' + 
            '<div class="row">' + 
              '<div class="col-md-12">' + 
                '<div class="form-group">' + 
                  '<h4 style="margin-top: 10px;">Name:</h4>' + 
                  '<input id="roster-level-name" class="swal2-input">' +
                  '<input type="hidden" id="roster-level-sport-id" value="'+this.sport_id+'" class="swal2-input">' +
                  '<input type="hidden" id="roster-level-organization-id" value="'+this.organization_id+'" class="swal2-input">' +
                '</div>' + 
              '</div>' + 
            '</div>' + 
            '<div class="row">' + 
              '<div class="col-md-6">' + 
                '<div class="form-group">' + 
                  '<h4 style="margin-top: 10px;">Shortcode:</h4>' + 
                  '<input id="roster-level-shortcode" class="swal2-input">' +
                '</div>' + 
              '</div>' + 
              '<div class="col-md-6">' + 
                '<div class="form-group">' + 
                  '<h4 style="margin-top: 10px;">Order:</h4>' + 
                  '<select id="roster-level-order" class="custom-select" style="margin-top: 15px !important; border: 2px solid #98a6ad; height: calc(1.8em + 0.75rem + 2px); !important;">' + 
                '</div>' + 
              '</div>' + 
            '</div>' + 
          '</div>',
          focusConfirm: false,
          onRender: () => {
            // do this for the total number of team roster levels
            for(let i = 1; i <= this.count + 1; i++) {
              var newOption = new Option(i, i, false, false)
              $('#roster-level-order').append(newOption)
            }
            $('#roster-level-order').trigger('change')
          },
          preConfirm: () => {
            return {
              "name": document.getElementById('roster-level-name').value,
              "shortcode": document.getElementById('roster-level-shortcode').value,
              "order": document.getElementById('roster-level-order').value,
              "sport_id": document.getElementById('roster-level-sport-id').value,
              "organization_id": document.getElementById('roster-level-organization-id').value
            }
          }
        })

        if (formValues) {
          if (formValues.name && formValues.shortcode && formValues.order) { 
            $.ajax({
              url: `/api/team-roster-levels`,
              type: 'POST',
              data: formValues
            })
            .done((d) => {
              if (d.data.response = 'success') {
                this.loadData()
                Swal.fire({
                  title: 'Roster Team Level Created Successfully.',
                  type: 'success',
                  timer: 1700,
                })
              }
            })
          } else {
            Swal.fire({
              title: 'All fields required',
              type: 'warning',
            })
          }
        }
      }
      else{
        Swal.fire({
          title: 'You have reached the allowed limit of '+team_level_limit+' Roster Team Levels',
          type: 'warning',
        })
      }
    },
    async editRosterLevel(row) {
      const { value: formValues} = await Swal.fire({
        title: 'Edit Team Roster Level',
        showCancelButton: true,
        confirmButtonText: 'Save',
        confirmButtonClass: 'btn btn-confirm mt-2',
        cancelButtonClass: 'btn btn-cancel ml-2 mt-2',
        html:
        '<div>' + 
          '<div class="row">' + 
            '<div class="col-md-12">' + 
              '<div class="form-group">' + 
                '<h4 style="margin-top: 10px;">Name:</h4>' + 
                '<input id="roster-level-name" value="'+row.name+'" class="swal2-input">' +
                '<input type="hidden" id="roster-level-sport-id" value="'+this.sport_id+'" class="swal2-input">' +
                '<input type="hidden" id="roster-level-organization-id" value="'+this.organization_id+'" class="swal2-input">' +
              '</div>' + 
            '</div>' + 
          '</div>' + 
          '<div class="row">' + 
            '<div class="col-md-6">' + 
              '<div class="form-group">' + 
                '<h4 style="margin-top: 10px;">Shortcode:</h4>' + 
                '<input id="roster-level-shortcode" value="'+row.shortcode+'" class="swal2-input">' +
              '</div>' + 
            '</div>' + 
            '<div class="col-md-6">' + 
              '<div class="form-group">' + 
                '<h4 style="margin-top: 10px;">Order:</h4>' + 
                '<select id="roster-level-order" class="custom-select" style="margin-top: 15px !important; border: 2px solid #98a6ad; height: calc(1.8em + 0.75rem + 2px); !important;">' + 
              '</div>' + 
            '</div>' + 
          '</div>' + 
        '</div>',
        focusConfirm: false,
        onRender: () => {
          // do this for the total number of team roster levels
          for(let i = 1; i <= this.count; i++) {
            var selected = (row.order == i) ? true : false
            var newOption = new Option(i, i, false, selected)
            $('#roster-level-order').append(newOption)
          }
          $('#roster-level-order').trigger('change')
        },
        preConfirm: () => {
          return {
            "name": document.getElementById('roster-level-name').value,
            "shortcode": document.getElementById('roster-level-shortcode').value,
            "order": document.getElementById('roster-level-order').value,
            "sport_id": document.getElementById('roster-level-sport-id').value,
            "organization_id": document.getElementById('roster-level-organization-id').value
          }
        }
      })

      if (formValues) {
        if (formValues.name && formValues.shortcode && formValues.order) { 
          $.ajax({
            url: `/api/team-roster-levels/${row.id}`,
            type: 'POST',
            data: formValues
          })
          .done((d) => {
            if (d.data.response = 'success') {
              this.loadData()
              Swal.fire({
                title: 'Roster Team Level Updated Successfully.',
                type: 'success',
                timer: 1700, 
              })
            }
          })
        } else {
          Swal.fire({
            title: 'All fields required',
            type: 'warning',
          })
        }
      }
    },
    deleteRosterLevel(id) {
      Swal.fire({
        title: 'Remove this roster level from system?',
        text: 'This will remove the roster level from the system.',
        type: 'warning',
        confirmButtonText: 'Delete',
        confirmButtonClass: 'btn btn-confirm mt-2',
        showCancelButton: true,
        cancelButtonClass: 'btn btn-cancel ml-2 mt-2',
        // reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          $.ajax({
            url: `/api/team-roster-levels/${id}`,
            type: 'DELETE',
          })
          .done((d) => {
            if (d.data.response = 'success') {
              this.loadData()
              Swal.fire({
                title: 'Removed!',
                text: 'Roster Team Level Has Been Removed.',
                type: 'success',
                timer: 1700,
              });
            } else {
              // ajax error
              Swal.fire({
                title: 'Error!',
                text: 'There was an error removing the roster level.',
                type: 'error',
              });
            }
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {}
      });
    },
    initializeOrder(options) {
      options.forEach((option) => {
        var newOption = new Option(option.id, option.text, false, false)
        $('#order').append(newOption)
      })
      $('#order').trigger('change')
    }
  },
}
</script>

<style scoped>

</style>