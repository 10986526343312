<template>
  <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.458008" width="40" height="40" rx="20" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M28.5405 15.2248L17.5803 26.1849L11.4606 20.0652L12.8748 18.651L17.5803 23.3565L27.1263 13.8105L28.5405 15.2248Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "CircleCheckMarkBlack"
}
</script>
