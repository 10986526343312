<template>
  <div>
    <div class="card athlete-profile-card">
      <div class="card-body">
        <div class="athlete-profile-row">
          <div class="athlete-profile-avatar">
            <img
              class="rounded-circle athlete-profile-img"
              :src="player.profile.data.photo"
            />
          </div>

          <div class="athlete-profile-data">
            <h4 class="card-title mb-1">{{player.full_name}}</h4>
            <h5 class="card-subtitle mb-2">{{player.profile.data.basics.data.positions_string}}</h5>
            <h6 class="card-subtitle mb-2 text-muted">{{player.profile.data.basics.data.high_school}}, {{player.profile.data.basics.data.grad_year}}</h6>
            <h6 class="card-subtitle mb-2 text-muted">{{player.profile.data.basics.data.city}}, {{player.profile.data.basics.data.state}}</h6>
            <h6 class="card-subtitle mb-2 text-muted">{{player.profile.data.measurables.data.height}}, {{player.profile.data.measurables.data.weight}}</h6>
            <h6 class="card-subtitle mb-2 text-muted" v-if="sport_name === 'baseball'">{{getBatThrowString(player)}}</h6>
          </div>

          <div class="athlete-profile-remove">
            <div class="org-bg" @click="handlePlayerRemove(player.id)"><i class="ti-minus"></i></div>
          </div>
        </div>

        <div style="border: 0px solid red; padding: 1px;">
          <div v-if="player.prospectlist_comments" style="border: 0px solid blue;">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
              <div style="margin:0; padding: 0; padding-top: 4px;">
                <h5 class="card-subtitle mb-2" style="border: 0px solid green; margin:0; padding: 0;">
                  Comments: 
                </h5>
              </div>

              <div v-if="!isAlreadyShort" style="border: 0px solid purple; margin: 0; padding: 0; cursor: pointer;">
                <span v-if="!display_full_comments" @click="toggleCommentsDisplay" style="font-size: 12px; color: gray;"><i class="ti-angle-down"></i> <i>Show More</i></span>
                <span v-else @click="toggleCommentsDisplay" style="font-size: 12px; color: gray;"><i class="ti-angle-up"></i> <i>Show Less</i></span>
              </div>
            </div>

            <div style="border: 0px solid yellow;">
              <p v-if="!display_full_comments" :id="'testing_'+player.id" class="prospectlist-comments-short show-white-space">{{short_comments}}</p>
              <p v-else class="prospectlist-comments-full show-white-space">{{player.prospectlist_comments}}</p>
            </div>
          </div>

          <button 
            type="button" 
            :class="getCommentBtnClass()" 
            data-dynamic-component="prospectlist-add-athlete-comments"
            :data-modal-title="title()"
            :data-props="`
              player_id='${this.player_id()}'
              player_comments='${this.getPlayerComments()}'
            `"
            v-text="getCommentBtnText()"
            @click="handleSetPlayerInputData"
          >
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      player: {type: Object, default() {return {}}, required: false},
      sport_id: {type: String, default: '1', required: false},
      sport_name: {type: String, default: 'football', required: false},
      handlePlayerCommentsChange: {type: Function, default() {return null}, required: false},
      handlePlayerRemove: {type: Function, default() {return null}, required: false},
    },

    data() {
        return {
          display_full_comments: true,
          short_comments: '',
          isAlreadyShort: false,
        }
    },

    methods: {
      player_id() { return this.player.id; },
      getPlayerComments() {return (this.player.prospectlist_comments) ? $.parseHTML(this.player.prospectlist_comments) : ''},
      getCommentBtnText() {return (this.player.prospectlist_comments) ? 'Edit Player Comments' : 'Add Player Comments'},
      getCommentBtnClass() {return (this.player.prospectlist_comments) ? 'btn btn-info btn-sm float-right' : 'btn btn-primary btn-sm float-right'},
      title() {return 'Comments for '+this.player.full_name;},
      handleCommentsChange(data){
        this.handlePlayerCommentsChange(data, this.activateShowMore);
      },

      toggleCommentsDisplay(){
        this.display_full_comments = !this.display_full_comments;
      },
      handleSetPlayerInputData(){
        // setting timeout here to allow dynamic-component 'prospectlist-add-athlete-comments' modal to generate
        // this sets the default input data to be displayed when the modal appears
        setTimeout(()=>{
          window.EventBus.publish('sds_handleSetPlayerInputData'+this.player.id, {
            comments: this.player.prospectlist_comments,
          });
        }, 500);
      },
      getBatThrowString(player) {
        const sportBasics = player.profile.data.basics.data.sportBasics;
        const bats = sportBasics?.data?.bats ? sportBasics.data.bats.slice(0, 1)?.toUpperCase() : 'N/A '
        const throws = sportBasics?.data?.throws ? sportBasics.data.throws.slice(0, 1).toUpperCase() : ' N/A'
        return `${bats}/${throws}`;
      },
      activateShowMore(){
        if(this.player.prospectlist_comments){
          let limit = 50;
          let newlinePos = this.player.prospectlist_comments.indexOf("\n");
          this.isAlreadyShort = false;

          if(newlinePos !== -1){
            this.display_full_comments = false;
            this.short_comments = this.player.prospectlist_comments.slice(0,newlinePos);

            if(this.short_comments.length > limit) this.short_comments = this.short_comments.slice(0,limit);

            this.short_comments += '...';
          }
          else if(this.player.prospectlist_comments.length > limit){
            this.display_full_comments = false;
            this.short_comments = this.player.prospectlist_comments.slice(0,limit);
            this.short_comments += '...';
          }
          else{
            this.display_full_comments = true;
            this.short_comments = '';
            this.isAlreadyShort = true;
          }
        }
      },
    },

    mounted() {
      window.EventBus.subscribe('sds_handlePlayerCommentsChange', (data) => {
        this.handleCommentsChange(data);
      });

      this.activateShowMore();
    },
  }
</script>

<style scoped>
  .athlete-profile-avatar {
    width: 35%;
  }

  .athlete-profile-data {
    width: 55%;
  }

  .athlete-profile-remove {
    width: 10%;
  }

  .card.athlete-profile-card {
    border-radius: 0;
    display: flex;
    flex-direction: column;
  }
  
  .card.athlete-profile-card:nth-child(odd) { background: #F7F7F7; }
  
  .card.athlete-profile-card:nth-child(even) { background: #FBFBFB; }

  .athlete-profile-card .card-body {
    /*border: 1px solid orange;*/
    display: flex; 
    flex-direction: column; 
    justify-content: space-between;
    gap: 5px;
  }

  .athlete-profile-card .card-body .athlete-profile-row {
    /*border: 1px solid orange;*/
    display: flex; 
    flex-direction: row; 
    justify-content: space-between; 
    align-content: center;
    gap: 5px;
  }

  .athlete-profile-card {
    /*border: 1px solid orange;*/
    padding: 5px;
  }

  .athlete-profile-img {
    height: 90px;
    width: 90px;
    padding: 7px;
  }

  .athlete-profile-remove {
    display: flex; 
    flex-direction: column; 
    align-content: center;
    justify-content: center; 
  }

  .athlete-profile-remove div {
    cursor: pointer;
    height: 28px; 
    width: 28px; 
    border-radius: 50%; 
    display: flex; 
    flex-direction: column; 
    align-content: center; 
    justify-content: center; 
    align-items: center;
  }

  .athlete-profile-remove div i {
    font-weight: 700; 
    font-size:18px;
  }

  .prospectlist-comments-short {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .show-white-space {
      white-space: pre-wrap;
  }
</style>
