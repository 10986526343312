<template>
  <b-container>
    <b-row class="header">
      <b-col class="headerCol">Send a Message to your recruits</b-col>
    </b-row>
    <b-row class="dataRow">
      <b-col class="dataCol">
        <b-form-input v-model="title" placeholder="Email subject"></b-form-input><br>
        <b-form-textarea
            id="message"
            v-model="message"
            placeholder="An inspiring quote might help..."
            :state="message.length < 255"
            rows="10"
        ></b-form-textarea>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="invite-footer">
        <back-button @back="$emit('back')"></back-button>
        <button @click="confirmInfo" :disabled="message.length >= 255" class="btn btn-primary">Continue</button>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import {BContainer, BRow, BCol,BFormInput,BFormTextarea } from 'bootstrap-vue';
import BackButton from './BackButton';

export default {
  name: 'CoachMessage',
  components: { BContainer,BRow,BCol,BFormInput,BFormTextarea, BackButton },
  data: () => ({
    title:'',
    message: ''
  }),
  methods: {
    confirmInfo(){
      this.$emit('confirm_info',{title: this.title, message: this.message});
    }
  }
}
</script>

<style scoped>
.header{
  background:black;
  color:white;
  border-top:5px solid #E7F818;
  border-radius:5px 5px 0px 0px;
  padding: 1rem 2rem;
}
.headerCol{
  font-family: KairosSans,sans-serif;
  text-transform:uppercase;
  color:white;
}
.dataRow{
  padding: 2rem;
  background:#f0f0f0;
}

.invite-footer {
  padding: 1rem 2rem;
  background: #f9f9f9;
  border-radius: 0 0 5px 5px;
  display: flex;
  justify-content: space-between;
}

.btn {
  text-transform:uppercase;
  font-family: KairosSans,sans-serif;
}
</style>
