<template>
  <div>
    <div v-for="player in this.players" :key="player.profile_id" id="player">
      <div class="row" style="border-style: none none solid none">
        <h4 class="player-name" style="margin-top: 15px">{{player.first_name + ' ' + player.last_name}}</h4>
        <input :id="player.profile_id" :value="player.profile_id" type="checkbox" v-model="selectedPlayers" style="position: absolute; right: 50px; margin-top: 15px;"/>
      </div>
    </div>  
    <div style="margin-top:10px">
      <button v-on:click="submitTeamRosterAdditions" type="button" class="btn btn-primary waves-effect waves-light">Submit</button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal" style="margin-left:10px">Cancel</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

let timeout

export default {
  name: "AddRosterLevelPlayer",
  components: {},
  props: {
    teamrosterlevelid: Number, 
    players: Array
  },
  data: function () {
    return {
      loading: true,
      positions: window.signingDayConstants.user_positions,
      apiData: [],
      url: `api/players-to-add/${this.teamrosterlevelid}`,
      previousQuery: null,
      selectedPlayers: [], 
      filters: [
        { search_prop: 'full_name', value: '', }
      ],
    };
  },
  computed: {
    // players: (v) => _.get(v, 'apiData', []),
    // count: (v) => _.get(v, 'apiData.meta.pagination.total', 0),
  },
  mounted() {
    $(".select2").select2();
  },
  methods: {
    async loadData() {
      const response = await axios.get(this.url)
      this.apiData = response.data

      this.loading = false
    },
    submitTeamRosterAdditions(){
      axios.post(`api/add-players/${this.teamrosterlevelid}`, this.selectedPlayers).then((d) => {
        if (d.data.response = 'success') {
          window.location.reload();
          Swal.fire({
            title: 'Updated Successfully!',
            type: 'success',
            timer: 1700, 
          })
        }
      });
    },
    created() {
      console.log('created');
      this.loadData();
    }
  },
}
</script>