<template>
  <div class="container-fluid">
    <user-action-bar
      :filters="filters"
      :share="share"
      :organization_id="organization_id"
      :disabledState="disabledState"
      :handleSubmitShareProspectlists="handleSubmitShareProspectlists"
    ></user-action-bar>

    <data-tables-server
      :loading="loading"
      :data="getData"
      :total="getCount"
      :filters="filters"
      @query-change="loadData"
      :current-page="currentPaginationPage"
      :pagination-props="{
        pageSizes: [10, 25, 50, 100],
        background: true,
        layout: 'prev, pager, next, sizes, ->, total'}"
    >
      <el-table-column
        label="List Name"
        align="center"
      >
        <template v-slot="{ row }">
          <a :href="getShowHref(row.id)"><p>{{row.name}}</p></a>
        </template>
      </el-table-column>

      <el-table-column
        label="# of Athletes"
        align="center"
      >
        <template v-slot="{ row }">
          <p>{{row.player_count}}</p>
        </template>
      </el-table-column>

      <el-table-column
        label="Date Created"
        align="center"
      >
        <template v-slot="{ row }">
          <p>{{row.created_date}}</p>
        </template>
      </el-table-column>

      <el-table-column
        label="Last Edited"
        align="center"
      >
        <template v-slot="{ row }">
          <p>{{row.updated_date}}</p>
        </template>
      </el-table-column>

      <el-table-column v-if="!share" label="ACTIONS" align="center" :class-name="`actions-col ${getCount <= 2 ? 'single-entry-fix' : ''}`">
        <template v-slot="{ row }">
          <button
            type="button"
            class="btn dropdown-toggle waves-effect waves-light"
            data-toggle="dropdown"
            aria-expanded="false"
            style="
              border-color: rgb(225, 225, 225);
              border-width: 1;
              border-radius: 0;
              padding: 7.5px 30px;
              font-family: KairosSans;
              color: black !important;
            "
          >
            ACTIONS
            <span data-html2canvas-ignore><span class="caret"></span></span>
          </button>
          <div class="dropdown-menu">
            <a
              class="dropdown-item"
              href="javascript:"
              @click="handleSubmitShareSingleProspectlist(row.id, row.public_prospectlist_token)"
              data-toggle="modal"
              data-target="#shareModal"
            >
              Share
            </a>

            <span v-if="row.public_prospectlist_token === null">
              <a
                class="dropdown-item"
                href="javascript:"
                @click="togglePublicProfile(row.id)"
              >
                Generate Public Link
              </a>
            </span>

            <span v-else>
              <a :id="'prospectlist-public-token-url-'+row.id" href="javascript:" :data-copy="`${row.public_prospectlist_token.url}`" class="dropdown-item">Copy Public Link</a>
            </span>

            <a
              class="dropdown-item"
              :href="getEditHref(row.id)"
              >
              Edit
            </a>

            <a
              class="dropdown-item"
              @click.prevent="handleDeleteProspectlist(row.id)"
              >
              Delete
            </a>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        v-else
        fixed="right"
        label="Share"
        align="center"
      >
        <template v-slot="{ row }">
          <input
            class="form-check-input"
            :id="'prospectlist-selected-player-'+row.id"
            type="checkbox"
            :checked="isSelectedPlayer(row.id)"
            :name="'prospectlist-selected-player-'+row.id"
            :value="row.id"
            @click="handleProspectlistsSelection"
          />
        </template>
      </el-table-column>

    </data-tables-server>

    <share-interface
      :prospectlists="selected"
      :organization_name="organization_name"
      :coach_name="coach_name"
    ></share-interface>
  </div>
</template>

<script>
  import _ from 'lodash';
  import UserActionBar from "./UserActionBar";
  import ShareInterface from "../components/ShareInterface";
  import DataTablesServer from "../../../components/ui/DataTablesServer";
  import { buildQueryForVueDataTable } from "@lib/vue-data-tables-util";

  export default {
    components: { UserActionBar, ShareInterface, DataTablesServer },

    props: {
      organization_id: {type: String, default: '0', required: false},
      organization_name: {type: String, default: null, required: false},
      coach_name: {type: String, default: null, required: false},
      share: {type: Boolean, default: false, required: false},
    },

    data() {
  	  return {
        loading: false,
        filters: [
          { search_prop: 'full_name', value: '', },
          { search_prop: 'num_athletes', value: '',},
          { search_prop: 'date_created', value: '',},
          { search_prop: 'date_edited', value: '',},
        ],
        selected: {},
        apiData: [],
        previousQuery: null,
        disabledState: true,
        currentPaginationPage: JSON.parse(localStorage.getItem('prospectlistPage')) ? JSON.parse(localStorage.getItem('prospectlistPage')) : 1,
  	  }
    },

    computed: {
      getCount() {
        return _.get(this.apiData, 'meta.pagination.total', 0);
      },
      getData(){
        return _.get(this.apiData, 'data', []);
      },
    },

    methods: {
      togglePublicProfile(prospectlist_id, prospectlist_token) {
        if(!prospectlist_token){
          const id = prospectlist_id;

          axios.post(`/api/tokens`, {
            token_type: 3,
            model: 'prospectlist',
            id,
          }).then(response => {
            this.silentRefresh();
            setTimeout(function(){ $('#prospectlist-public-token-url-'+prospectlist_id).trigger('click'); }, 500);
          }).catch(error => console.log(error))
        } else {
          axios.post(`/api/tokens/${prospectlist_token.id}`, {
            _method: 'PUT',
          }).then(response => {
            this.silentRefresh();
          }).catch(error => console.log(error))
        }
      },
      isSelectedPlayer(playerID){
        return (typeof this.selected[playerID] !== 'undefined')
      },
      handleSubmitShareProspectlists(){
        // create share links if they don't exist already for each selected prospectlist
        Object.keys(this.selected).forEach((key) => {
          if(this.selected[key].public_prospectlist_token === null){
            this.createPublicProfile(this.selected[key]);
          }
        });
      },
      handleSubmitShareSingleProspectlist(prospectlist_id){
        this.selected = {};
        this.addProspectlistToSelection(prospectlist_id);
        this.handleSubmitShareProspectlists();
        this.silentRefresh();
      },
      addProspectlistToSelection(prospectlist_id){
        // add prospectlist item to selected container
        const clonedSelected = _.cloneDeep(this.selected);
        const prospectlist = _.filter(this.getData, {id: prospectlist_id});
        clonedSelected[prospectlist_id] = prospectlist[0];
        this.selected = clonedSelected;

        // set isChecked true for prosectlist item
        const clonedApiData = _.cloneDeep(this.apiData);
        Object.keys(clonedApiData.data).forEach(key => {
          if(clonedApiData.data[key].id === prospectlist_id) clonedApiData.data[key].prospectlistIsChecked = true;
        });
        this.apiData = clonedApiData;
      },
      removeProspectlistFromSelection(prospectlist_id){
        // remove prospectlist item from selected container
        this.selected = _.omit(this.selected, prospectlist_id);

        // set isChecked false for prosectlist item
        const clonedApiData = _.cloneDeep(this.apiData);
        Object.keys(clonedApiData.data).forEach(key => {
          if(clonedApiData.data[key].id === prospectlist_id) clonedApiData.data[key].prospectlistIsChecked = false;
        });
        this.apiData = clonedApiData;
      },
      async createPublicProfile(prospectlist){
        const id = prospectlist.id;

        const response = await axios.post(`/api/tokens`, {
          token_type: 3,
          model: 'prospectlist',
          id,
        });

        // update selected prospectlist data
        const clonedSelected = _.cloneDeep(this.selected);
        clonedSelected[id] = response.data.data;
        this.selected = clonedSelected;
      },
      handleProspectlistsSelection(event){
        const target = $(event.target);
        const isChecked = target.is(':checked');
        const prospectlist_id = JSON.parse(target.val());

        if(isChecked){
          this.addProspectlistToSelection(prospectlist_id);
        }
        else{
          this.removeProspectlistFromSelection(prospectlist_id);
        }

        this.setDisabledState()
      },
      setDisabledState(){
        // set disabled state: false if has selected container is not empty, else true
        this.disabledState = !(Object.keys(this.selected).length > 0);
      },
      async handleDeleteProspectlist(id){
        const url = "/api/prospectlists/"+id;

        Swal.fire({
          title: `Remove Prospectlist?`,
          text: `This will remove the prospectlist and any attributes associated with the prospectlist from your list.`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, remove prospectlist!",
          cancelButtonText: "No, cancel!",
          confirmButtonClass: "btn btn-confirm mt-2",
          cancelButtonClass: "btn btn-cancel ml-2 mt-2",
          reverseButtons: true
        }).then(result => {
          if (result.value) {
            $.ajax({
              url: url,
              type: "DELETE"
            }).done(response => {
              // response.id is the id of the record deleted
              if (response.id > 0) {
                window.vueInstance.$refs['prospectlist-screen'].$refs['prospectlist-table'].silentRefresh();
                Swal.fire({
                  title: "Removed!",
                  text: "The prospectlist has been removed.",
                  type: "success",
                  timer: 1700
                });
              } else {
                // ajax error
                Swal.fire({
                  title: "Error!",
                  text:
                    "There was an error removing the prospectlist from your list.",
                  type: "error"
                });
              }
            });
          } else if (result.dismiss === Swal.DismissReason.cancel) {
          }
        });
      },
      getEditHref(id){
        return "/organizations/"+this.organization_id+"/prospectlist/"+id+"/edit";
      },
      getShowHref(id){
        return "/organizations/"+this.organization_id+"/prospectlist/"+id;
      },
      handleQueryChange(query) {
        clearTimeout(timeout);
        timeout = setTimeout(() => this.loadData(query), 250);
      },
      handleSelectionChange(selected) {
        this.selected = selected.map(u => u.id)
      },
      async handleFilterChange(event) {
        var filterKey, params;

        if (typeof event.filterKey !== 'undefined') {
          params = {data: event.data}
          filterKey = event.filterKey
        }
        else{
          params = event.params
          filterKey = $(event.currentTarget).attr('id')
        }

        switch (filterKey) {
          case 'full_name_search': this.filters[0].value = params.data.dateRange; break
          case 'num_athletes_search': this.filters[1].value = params.data.dateRange; break
          case 'date_created_search': this.filters[2].value = params.data.dateRange; break
          case 'date_edited_search': this.filters[3].value = params.data.dateRange; break
        }
      },
      resetFilters(){
        for (const filter in this.filters) {
          this.filters[filter].value = ''
        }
        this.refreshInputs()
        this.resetCurrentPage(1)
      },
      refreshInputs() {
        const keys = $('[data-filter]').map((i, el) => $(el).data('filter')).toArray()

        keys.map((key, i) => {
          const $input = $(`[data-filter="${key}"]`)
          const { value } = this.filters.filter(f => f.search_prop === key)[0]
        })
      },
      async silentRefresh() {
        await this.loadData(this.previousQuery, true)
      },
      async loadData(query, silentReload = false) {
        // reset query-page to 1 if there is a change to any of the basic search fields
        if( (this.previousQuery !== null) && (!_.isEqual(this.previousQuery.filters, query.filters)) ){
          query.page = 1
          this.resetCurrentPage(query.page)
        }

        localStorage.setItem('prospectlistPage', JSON.stringify(query.page));
        this.currentPaginationPage = query.page

        this.previousQuery = _.cloneDeep(query)
        this.loading = !silentReload

        const apiUrl = '/api/organizations/'+this.organization_id+'/get-prospect-lists-table';
        const builder = buildQueryForVueDataTable(query)
        const url = `${apiUrl}?${builder.build()}&roster=${this.screen === 'roster'}`

        const response = await axios.get(url)
        this.apiData = response.data
        this.loading = false
        this.initializeSelectedItems();
      },
      initializeSelectedItems(){
        const clonedApiData = _.cloneDeep(this.apiData);
        Object.keys(clonedApiData.data).forEach(key => {
          if(this.selected[clonedApiData.data[key].id]) clonedApiData.data[key].prospectlistIsChecked = true;
        });
        this.apiData = clonedApiData;
      },
      setActiveUrl(){
        const target = $('#sidebar-menu ul li a[href$="/prospectlist"]');
        if(!target.hasClass("class-name")) target.addClass("active");
      },
      syncUpQueryFilters(){
        this.previousQuery.filters = _.cloneDeep(this.filters)
        window.EventBus.publish('sds_resetFilterInputs', this.filters)
      },
      saveCookies() {
        Cookies.set('prospect_lists_table', JSON.stringify({filters: this.filters}), { expires: Infinity })
        localStorage.setItem('prospectlist_selected', JSON.stringify(this.selected))
      },
      getOrResetCookies() {
        const { type } = performance.getEntriesByType('navigation')[0]
        const cookie = JSON.parse(Cookies.get('prospect_lists_table'))

        switch (type) {
          case 'reload':
          case 'back_forward':
            this.performReload(cookie)
          case 'navigate':
            const searchParams = new URLSearchParams(window.location.search)
            const saveFilters = (searchParams.has('retain_filters')) ? parseInt(searchParams.get('retain_filters')) : null;

            if(saveFilters){
              this.performReload(cookie)
            }
            else{
              this.resetCurrentPage(1)
              return Cookies.expire('prospect_lists_table')
            }
        }
      },
      performReload(cookie){
        this.selected = JSON.parse(localStorage.getItem('prospectlist_selected')) ? JSON.parse(localStorage.getItem('prospectlist_selected')) : {}
        this.setDisabledState()
        Object.keys(cookie).forEach(k => _.set(this, k, cookie[k])) && this.silentRefresh()
        return this.syncUpQueryFilters()
      },
      resetCurrentPage(page) {
        localStorage.setItem('prospectlistPage', JSON.stringify(page))
        this.currentPaginationPage = page
        this.selected = {}
        localStorage.setItem('prospectlist_selected', JSON.stringify(this.selected))
        this.setDisabledState()
        return this.syncUpQueryFilters()
      }
    },

	  created() {
      window.onbeforeunload = this.saveCookies;
      $(() => {
        this.getOrResetCookies();
        this.refreshInputs();
      })

      this.setActiveUrl();

      window.EventBus.subscribe('sds_handleFilterChange', (data) => {
        this.handleFilterChange(data.event);
      });
      window.EventBus.subscribe('sds_resetFilters', () => {
        this.resetFilters();
      });
	  },
  }
</script>

<style scoped>
  .dropdown-item {
    cursor: pointer;
  }
</style>
