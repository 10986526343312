<template>
  <div>
    <div id="advanced_search_criteria" :class="{'hidden': !this.visible}">
      <div class="form-inline search" id="local-searches" :style="{display: this.visible ? 'inline' : 'none'}">
        <select class="select2 form-control custom-select advanced" id="search_history">
          <option value="{}">Search History</option>
          <option v-for="saved in searchValue.slice().reverse()" :value="saved.search_date" :key="saved.search_date">{{ saved.search_date }}</option>
          <option value="" disabled v-if="searchValue.length === 0">No Search History</option>
        </select>
      </div>
      <span class="form-inline"  id="saved-searches" :style="{display: this.visible ? 'inline' : 'none', float: 'right'}">
        <button data-toggle="modal" data-target="#stats-search" class="btn waves-effect waves-light btn-info btn-font" v-if="['baseball','softball'].includes($parent.sport)">Stats Search</button>
        <input type="button" id="simple_search" value="Reset Search" class="btn waves-effect waves-light btn-primary btn-font" />
      </span>
      <div class="card-box m-b-20">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-2 col-sm-6 m-b-10">
              <label >Position 1</label>
              <el-select class="theme-select advanced d-block" placeholder="..." id="position_one" v-model="filters.global.position_one">
                <el-option :label="position" :value="code" :key="`${position}_1`" v-for="(position, code) in positions" />
              </el-select>
            </div>
            <div class="col-lg-2 col-sm-6 m-b-10">
              <label >Position 2</label>
              <el-select class="theme-select advanced d-block" placeholder="..." id="position_two" v-model="filters.global.position_two">
                <el-option :label="position" :value="code" :key="`${position}_2`" v-for="(position, code) in positions" />
              </el-select>
            </div>
            <div class="col-lg-2 col-sm-6 m-b-10">
              <label >City</label>
              <input type="text" class="form-control advanced" id="city" v-model="filters.global.city">
            </div>
            <div class="col-lg-2 col-sm-6">
              <label>State</label>
              <el-select class="theme-select advanced d-block" placeholder="..." id="state" v-model="filters.global.state">
                <el-option :value="state" :key="idx" v-for="(state, idx) in states" />
              </el-select>
            </div>
            <div class="col-lg-2 col-sm-6">
              <label>School</label>
              <select
                name="high_school"
                id="high_school"
                class="high_school form-control select2"
                v-model="filters.global.high_school"
                v-select2
              >
                <option
                  v-if="filters.global.high_school !== null"
                  :value="filters.global.high_school"
                >
                {{ filters.global.high_school }}
                </option>
              </select>
            </div>
            <div class="col-lg-2 col-sm-6">
              <label>Academy</label>
              <select
                name="academy"
                id="academy"
                class="academy form-control select2"
                v-model="filters.global.academy"
                v-select2
              >
              </select>
            </div>
            <div class="col-lg-2 col-sm-6">
              <label>Club</label>
              <select
                name="club"
                id="club"
                class="club form-control select2"
                v-model="filters.global.club"
                v-select2
              >
              </select>
            </div>
            <div class="col-lg-3 col-sm-6" v-if="['baseball'].includes($parent.sport)">
              <label>Draft Eligibility Year</label>
              <el-select id="draft_eligible_year" v-model="filters[$parent.sport].draft_eligible_year" class="theme-select advanced d-block" placeholder="..." multiple>
                <el-option v-for="i in 18" :key="`${i}_eligible_year`" :value="new Date().getFullYear() + i - 8" :label="new Date().getFullYear() + i - 8"/>
              </el-select>
            </div>
          </div>
          <div class="row" v-if="['mens_soccer','womens_soccer'].includes($parent.sport)">
            <div class="col-xl-2 col-lg-4 col-sm-6 m-b-10" v-if="['mens_soccer','womens_soccer'].includes($parent.sport)">
              <label>League</label>
              <el-select class="theme-select advanced d-block" placeholder="..." :data-type="$parent.sport" id="leagues" @change="checkLeague($event)" v-model="filters.soccer.leagues">
                <el-option v-for="({id, name}) in leagues" :label="name" :value="id" :key="name"/>
              </el-select>
            </div>
            <div class="col-xl-2 col-lg-4 col-sm-6 m-b-10" v-if="['mens_soccer','womens_soccer'].includes($parent.sport) && leagueStateSearch">
              <label>League State</label>
              <el-select class="theme-select advanced d-block" placeholder="ANY" name="league_state" id="league_state" v-model="filters.soccer.league_state">
                <el-option :value="state" :key="idx" v-for="(state, idx) in states" />
              </el-select>
            </div>
          </div>
          <h5 class="m-t-30">Physical Traits</h5>
          <div class="row">
            <div class="col-xl-2 col-lg-4 col-sm-6 m-b-10" id="height_fields">
              <label class="form-label">Height +</label>
              <div class="row">
                <div class="col-sm-6">
                  <el-select class="theme-select advanced d-block" placeholder="..." id="height_ft" v-model="filters.global.height_ft">
                    <el-option :key="i" v-for="i in 3" :value="i + 4" />
                  </el-select>
                </div>

                <div class="col-sm-6">
                  <el-select class="theme-select advanced d-block" placeholder="..." id="height_in" v-model="filters.global.height_in">
                    <el-option :key="i" v-for="i in 23" :value="i / 2" />
                  </el-select>
                </div>
              </div>
            </div>
            <div class="col-xl-2 col-lg-4 col-sm-6 m-b-10"  v-if="$parent.sport !== 'mens_soccer' && $parent.sport !== 'womens_soccer' ">
              <label class="form-label">Weight +</label>
              <el-select class="theme-select advanced d-block" placeholder="..." id="weight" v-model="filters.global.weight">
                <el-option :key="i" v-for="i in 31" :value="90 + (i * 10)" />
              </el-select>
            </div>
            <div class="col-xl-2 col-lg-4 col-sm-6 m-b-10" v-if="$parent.sport === 'football'">
              <label >40 Yard Dash -</label>
              <el-select class="theme-select advanced d-block" placeholder="..." :data-type="$parent.sport" id="forty_yard_dash" v-model="filters.football.forty_yard_dash">
                <el-option :key="i" v-for="i in 20" :value="((i / 2) - 10.5) * -1" />
              </el-select>
            </div>
            <div class="col-xl-2 col-lg-4 col-sm-6 m-b-10" v-if="$parent.sport === 'football'">
              <label >Shuttle -</label>
              <el-select class="theme-select advanced d-block" placeholder="..." :data-type="$parent.sport" id="shuttle" v-model="filters.football.shuttle">
                <el-option :key="i" v-for="i in 11" :value="((i - 11) * -1) + 1" />
              </el-select>
            </div>
            <div class="col-xl-2 col-lg-4 col-sm-6 m-b-10" v-if="$parent.sport === 'football'">
              <label >Vertical +</label>
              <el-select class="theme-select advanced d-block" placeholder="..." :data-type="$parent.sport" id="vertical" v-model="filters.football.vertical">
                <el-option :key="i" v-for="i in 101" :value="(i / 2) + 9.5 " />
              </el-select>
            </div>
            <div class="col-xl-2 col-lg-4 col-sm-6 m-b-10" v-if="$parent.sport === 'football'">
              <label >Bench +</label>
              <el-select class="theme-select advanced d-block" placeholder="..." :data-type="$parent.sport" id="bench" v-model="filters.football.bench">
                <el-option value="185" />
                <el-option value="225" />
              </el-select>
            </div>
            <div class="col-xl-2 col-lg-4 col-sm-6 m-b-10" v-if="['baseball','softball'].includes($parent.sport)">
              <label >Bats</label>
              <el-select class="theme-select advanced d-block" placeholder="..." :data-type="$parent.sport" id="bats" v-model="filters[$parent.sport].bats">
                <el-option value="left" />
                <el-option value="right" />
                <el-option value="switch" />
                <el-option v-if="$parent.sport === 'softball'" value="slapper" />
              </el-select>
            </div>
            <div class="col-xl-2 col-lg-4 col-sm-6 m-b-10" v-if="['baseball','softball'].includes($parent.sport)">
              <label >Throws</label>
              <el-select class="theme-select advanced d-block" placeholder="..." :data-type="$parent.sport" id="throws" v-model="filters[$parent.sport].throws">
                <el-option value="left" />
                <el-option value="right" />
              </el-select>
            </div>
          </div>

          <h5 class="m-t-30" v-if="['mens_soccer','womens_soccer'].includes($parent.sport)">Technical Testing</h5>
          <div class="row" v-if="['mens_soccer','womens_soccer'].includes($parent.sport)">
            <div class="col-xl-2 col-lg-4 col-sm-4 m-b-10">
              <label >5-10-5 Shuttle W/ Ball -</label>
              <el-select class="theme-select advanced d-block" placeholder="..." :data-type="$parent.sport" id="five_ten_shuttle_with_ball" v-model="filters.soccer.five_ten_shuttle_with_ball">
                <el-option :key="i" v-for="i in 11" :value="((i - 11) * -1) + 1 + ' seconds'" />
              </el-select>
            </div>
            <div class="col-xl-2 col-lg-4 col-sm-4 m-b-10">
              <label >5-10-5 Shuttle W/Out Ball -</label>
              <el-select class="theme-select advanced d-block" placeholder="..." :data-type="$parent.sport" id="five_ten_shuttle_without_ball" v-model="filters.soccer.five_ten_shuttle_without_ball">
                <el-option :key="i" v-for="i in 11" :value="((i - 11) * -1) + 1 + ' seconds'" />
              </el-select>
            </div>
            <div class="col-xl-2 col-lg-4 col-sm-4 m-b-10">
              <label >Fig-8 W/ Ball -</label>
              <el-select class="theme-select advanced d-block" placeholder="..." :data-type="$parent.sport" id="figure_eight_with_ball" v-model="filters.soccer.figure_eight_with_ball">
                <el-option :key="i" v-for="i in 11" :value="((i - 11) * -1) + 1 + ' seconds'" />
              </el-select>
            </div>
            <div class="col-xl-2 col-lg-4 col-sm-4 m-b-10">
              <label >Fig-8 W/Out Ball -</label>
              <el-select class="theme-select advanced d-block" placeholder="..." :data-type="$parent.sport" id="figure_eight_without_ball" v-model="filters.soccer.figure_eight_without_ball">
                <el-option :key="i" v-for="i in 11" :value="((i - 11) * -1) + 1 + ' seconds'" />
              </el-select>
            </div>
          </div>

          <h5 class="m-t-30" v-if="['mens_soccer','womens_soccer'].includes($parent.sport)">Fitness Testing</h5>
          <div class="row" v-if="['mens_soccer','womens_soccer'].includes($parent.sport)">
            <div class="col-xl-2 col-lg-4 col-sm-4 m-b-10">
              <label >Beep Test +</label>
              <el-select class="theme-select advanced d-block" placeholder="..." :data-type="$parent.sport" id="beep_test" v-model="filters.soccer.beep_test">
                <el-option :key="i" v-for="i in 25" :value="((i - 26) * -1)" />
              </el-select>
            </div>
            <div class="col-xl-2 col-lg-4 col-sm-4 m-b-10">
              <label >Yoyo Test +</label>
              <el-select class="theme-select advanced d-block" placeholder="..." :data-type="$parent.sport" id="yoyo_test" v-model="filters.soccer.yoyo_test">
                <el-option :key="i" v-for="i in 25" :value="((i - 26) * -1)" />
              </el-select>
            </div>
            <div class="col-xl-2 col-lg-4 col-sm-4 m-b-10">
              <label >Coopers Test -</label>
              <el-select class="theme-select advanced d-block" placeholder="..." :data-type="$parent.sport" id="coopers_test" v-model="filters.soccer.coopers_test">
                <el-option :key="i" v-for="i in 18" :value="((i - 19) * -60)" :label="((i - 18) * -1 + 1) + ' minutes'" />
              </el-select>
            </div>
            <div class="col-xl-2 col-lg-4 col-sm-4 m-b-10">
              <label >300M Shuttle -</label>
              <el-select class="theme-select advanced d-block" placeholder="..." :data-type="$parent.sport" id="three_hundred_m_shuttle" v-model="filters.soccer.three_hundred_m_shuttle">
                <el-option :key="i" v-for="i in (Array.from({length: 61}, (_, i) => i + 30))" :value="(i) + ' seconds'" />
              </el-select>
            </div>
            <div class="col-xl-2 col-lg-4 col-sm-4 m-b-10">
              <label >1Mi Time -</label>
              <el-select class="theme-select advanced d-block" placeholder="..." :data-type="$parent.sport" id="one_mile_time" v-model="filters.soccer.one_mile_time">
                <el-option :key="i" v-for="i in 9" :value="((i - 13) * -60)" :label="((i - 13) * -1) + ' minutes'" />
              </el-select>
            </div>
          </div>

          <h5 v-if="['baseball','softball'].includes($parent.sport)" class="m-t-30" >Pitching</h5>
          <div v-if="['baseball','softball'].includes($parent.sport)" class="row">
            <div class="col-lg-3 col-sm-6 m-b-10">
              <label>Pitch Types</label>
              <el-select id="pitch_types" v-model="filters[$parent.sport].pitch_types" class="theme-select advanced d-block" placeholder="..." multiple>
                <el-option v-for="({id, name}) in pitchTypes" :label="name" :value="id" :key="name"/>
              </el-select>
            </div>
            <div class="col-lg-3 col-sm-6 m-b-10">
              <label>Fastball Velocity</label>
              <el-select class="theme-select advanced d-block" placeholder="..." :data-type="$parent.sport" id="velocity" v-model="filters[$parent.sport].velocity">
                <el-option value="64" />
                <el-option value="74" />
                <el-option value="84" />
                <el-option value="94" />
              </el-select>
            </div>
          </div>

          <h5 v-if="['baseball','softball'].includes($parent.sport)" class="m-t-30" >Fielding Velocity</h5>
          <div v-if="['baseball','softball'].includes($parent.sport)" class="row">
            <div class="col-lg-3 col-sm-6 m-b-10">
              <label>Catcher To Second</label>
              <el-select class="theme-select advanced d-block" placeholder="..." :data-type="$parent.sport" id="catcher_to_second" v-model="filters[$parent.sport].catcher_to_second">
                <el-option value="64" />
                <el-option value="74" />
                <el-option value="84" />
                <el-option value="94" />
              </el-select>
            </div>
            <div class="col-lg-3 col-sm-6 m-b-10">
              <label>Third To First</label>
              <el-select class="theme-select advanced d-block" placeholder="..." :data-type="$parent.sport" id="third_to_first" v-model="filters[$parent.sport].third_to_first">
                <el-option value="64" />
                <el-option value="74" />
                <el-option value="84" />
                <el-option value="94" />
              </el-select>
            </div>
            <div class="col-lg-3 col-sm-6 m-b-10">
              <label>SS To First</label>
              <el-select class="theme-select advanced d-block" placeholder="..." :data-type="$parent.sport" id="ss_to_first" v-model="filters[$parent.sport].ss_to_first">
                <el-option value="64" />
                <el-option value="74" />
                <el-option value="84" />
                <el-option value="94" />
              </el-select>
            </div>
            <div class="col-lg-3 col-sm-6 m-b-10">
              <label>OF To Home</label>
              <el-select class="theme-select advanced d-block" placeholder="..." :data-type="$parent.sport" id="of_to_home" v-model="filters[$parent.sport].of_to_home">
                <el-option value="64" />
                <el-option value="74" />
                <el-option value="84" />
                <el-option value="94" />
              </el-select>
            </div>
          </div>

          <h5 class="m-t-30">Academics</h5>
          <div class="row">
            <div class="col-lg-3 col-sm-6 m-b-10">
              <label >ACT +</label>
              <el-select class="theme-select advanced d-block" placeholder="..." id="act" v-model="filters.global.act">
                <el-option :key="i" v-for="i in 36" :value="i" />
              </el-select>
            </div>
            <div class="col-lg-3 col-sm-6 m-b-10">
              <label >SAT +</label>
              <el-select class="theme-select advanced d-block" placeholder="..." v-model="filters.global.sat">
                <el-option :key="i" v-for="i in 60" :value="400 + (i * 20)" />
              </el-select>
            </div>
            <div class="col-lg-3 col-sm-6 m-b-10">
              <label >GPA +</label>
              <el-select class="theme-select advanced d-block" placeholder="..." id="unweighted_gpa" v-model="filters.global.unweighted_gpa">
                <el-option :key="i" v-for="i in 7" :value="((i / 2) + .5).toFixed(1)" />
              </el-select>
            </div>
            <div class="col-lg-3 col-sm-6 m-b-10">
              <label>Grad Year</label>
              <el-select id="grad_year" v-model="filters.global.grad_year" class="theme-select advanced d-block" placeholder="..." multiple>
                <el-option v-for="i in 8" :key="`${i}_year`" :value="new Date().getFullYear() + i - 1" :label="new Date().getFullYear() + i - 1"/>
              </el-select>
            </div>
            <div class="col-lg-3 col-sm-6 m-b-10">
              <label for="desired_major">Desired Major</label>
              <el-select name="desired_major" id="desired_major" class="theme-select advanced d-block" placeholder="ANY MAJOR" v-model="filters.global.desired_major">
                <el-option :key="i" v-for="(major, i) in majors" :value="major" />
              </el-select>
            </div>
            <div class="col-lg-3 col-sm-6 m-b-10">
              <label for="in_state_status">In State Status</label>
              <el-select class="theme-select advanced d-block" placeholder="ANY" name="in_state_status" id="in_state_status" v-model="filters.global.in_state_status">
                <el-option value="in" :label="'IN STATE'" />
                <el-option value="out" :label="'OUT OF STATE'" />
              </el-select>
            </div>
          </div>

          <div class="row m-t-30">
            <div class="col-md-12 col-lg-3" id="videoFilter" v-if="!isFan">
              <label for="only_proday"><input class="advanced" name="proday" id="only_proday" type="checkbox" v-model="filters.global.only_proday" />
                {{$parent.sport === 'football' ? 'Pro Day' : ($parent.sport === 'baseball' || $parent.sport === 'softball' ? 'Fundamentals' : ($parent.sport === 'mens_soccer' || $parent.sport === 'womens_soccer' ? 'Key Player Actions' : 'Fundamentals'))}} Film
              </label>
              <br>
              <label for="only_interviews"><input class="advanced" name="interview" id="only_interviews" type="checkbox" v-model="filters.global.only_interviews" /> Interview</label>
              <br>
              <label for="only_interested"><input type="checkbox" class="advanced" id="only_interested" v-model="filters.global.only_interested"/> Interested Players Only</label>
            </div>
            <div class="col-md-6 col-lg-3 offset-lg-3" v-if="!isFan">
              <label for="dateInput">Updated After</label>
              <div class="w-100">
                <input id="dateInput" type="date" name="dateInput" class="updated-after" v-model="filters.global.date">
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <label for="filter">Filter</label>
              <el-select class="theme-select advanced d-block" placeholder="ALL" id="filter" name="filterDropdown" v-model="filters.global.filter">
                <el-option value="Prospects" v-if="!isFan" />
                <el-option value="Recruits" v-if="!isFan" />
              </el-select>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-6 col-lg-3 order-2 order-lg-1 advanced-save-search-container" style="margin-bottom: 10px">
              <button id="save" type="button" class="btn btn-info waves-effect waves-light save-search-btn btn-font">Save As...</button>
            </div>
            <div class="col-12 col-lg-3 order-1 order-lg-2 m-b-10">
              <select id="saved_searches_list" class="custom-select select2 pull-left">
                <option value="{}">Saved Searches</option>
                <option v-for="saved in savedSearches" :value="saved.advanced_criteria">{{ saved.name }}</option>
                <option value="" disabled v-if="savedSearches.length === 0">No Saved Searches</option>
              </select>
            </div>
            <div class="col-6 col-lg-6 order-3 order-lg-3 advanced-search-submit-container">
              <button id="searchBtn" type="button" class="btn pull-right btn-primary waves-effect waves-light advanced_search_submit btn-font" @click="search">Search</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <stats-search ref="stats-search" v-if="['baseball','softball'].includes($parent.sport)"/>
  </div>

</template>

<script>
import StatsSearch from './StatsSearch'
import moment from 'moment'

export default {
  name: 'AdvancedSearch',
  components: { StatsSearch },
  props: {
    states: {type: Object, default: [], required: true},
    majors: {type: Object, default: [], required: true},
    savedSearches: {type: Array, default: [], required: true},
  },
  data() {
    return {
      isFan: JSON.parse(this.$parent.isFan),
      positions: this.getPositions(),
      visible: Cookies.get('advanced_mode') ? JSON.parse(Cookies.get('advanced_mode')) : false,
      searched: [],
      searchValue: JSON.parse(localStorage.getItem('searched')) ? JSON.parse(localStorage.getItem('searched')) : [],
      pitchTypes: [],
      leagues: [],
      statsSearch: false,
      leagueStateSearch: false,
      filters: {
        global: {
          position_one: "",
          position_two: "",
          city: "",
          state: "",
          high_school: null,
          height_ft: "",
          height_in: "",
          weight: "",
          act: "",
          sat: "",
          unweighted_gpa: "",
          grad_year: "",
          date: "",
          filter: "",
          desired_major: "",
          only_proday: null,
          only_interviews: null,
          in_state_status: null,
          only_interested: null,
          academy: null,
          club: null,
        },
        football: {
          forty_yard_dash: "",
          shuttle: "",
          bench: "",
          vertical: "",
        },
        baseball: {
          bats: "",
          throws: "",
          pitch_types: "",
          velocity: "",
          home_to_first: "",
          '60_yard_dash': "",
          strength: "",
          catcher_to_second: "",
          third_to_first: "",
          ss_to_first: "",
          of_to_home: "",
          draft_eligible_year: ""
        },
        softball: {
          bats: "",
          throws: "",
          pitch_types: "",
          velocity: "",
          home_to_first: "",
          '60_yard_dash': "",
          strength: "",
          catcher_to_second: "",
          third_to_first: "",
          ss_to_first: "",
          of_to_home: "",
          draft_eligible_year: ""
        },
        soccer: {
          leagues: "",
          league_state: "",
          five_ten_shuttle_with_ball: "",
          five_ten_shuttle_without_ball: "",
          figure_eight_with_ball: "",
          figure_eight_without_ball: "",
          beep_test: "",
          yoyo_test: "",
          coopers_test: "",
          three_hundred_m_shuttle: "",
          one_mile_time: "",
        }
      },
    }
  },
  watch: {
    visible(v) {
      if (v) {
        const {
          position_search: position_one, grad_year_search: grad_year
        } = this.$parent.$refs['basic-search'].filters
        this.filters.global.position_one = position_one
        this.filters.global.grad_year = grad_year
        this.refreshSelect2Instances()
        Cookies.set('advanced_mode', true, {expires: Infinity})
      } else {
        this.$parent.$refs['basic-search'].emitFilterChange()
      }
    }
  },
  computed: {
    selectedLeague() {
      if (true == false) {
        return true
      } else {
        return false
      }
    }
  },
  created() {
    window.onbeforeunload = this.saveSearchCriteriaToCookies
    $(() => {
      $('body')
        .on('select2:select', '#advanced_search_criteria', e => this.setFilter(e))
        .on('select2:select', '#saved_searches_list', e => this.populateAdvancedSearchFromSaved(e))
        .on('select2:select', '#velocity', e => this.handleVelocityChange(e))
        .on('select2:select', '#search_history', e => this.populateAdvancedSearchFromStorage(e))
        this.getOrResetCookies()
        this.moveResetButtonToLegacyLocation()
        this.moveSearchSelectToLegacyLocation()
        this.setupHighSchool()
        this.setupAcademies()
        this.setupClubs()
        if (['baseball','softball'].includes(this.$parent.sport)) {
          this.fetchPitchTypes()
        }
        if (['mens_soccer','womens_soccer'].includes(this.$parent.sport)) {
          this.setupLeagues()
        }
    });
  },
  methods: {
    getCookie(key) {
      return Cookies.get(key)
    },
    setCookie(key, value, opts = {}) {
      const defaultOpt = {expires: Infinity}
      Cookies.set(key, JSON.stringify(value), {...defaultOpt, ...opts})
    },
    setFilter({target, params}) {
      let type = 'global'
      const { id } = params.data
      const key = $(target).attr('id')
      type = $(target).data('type') && $(target).attr('data-type') || type

      if (this.filters[type][key] !== undefined) {
        this.filters[type][key] = id
      }
    },
    setupHighSchool() {
      $(".high_school").select2({
        allowClear: true,
        closeOnSelect: true,
        containerCssClass: 'HIGH_SCHOOL',
        placeholder: "Select School",
        minimumInputLength: 3,
        ajax: {
          url: "/api/highschool-autocomplete-ajax",
          dataType: "json",
          processResults: function (data, search) {
            // sort by school name relevance (first word in school name)
            const sorted = data.sort((a, b) => {
              const term = search.term.toLowerCase();
              const firstWordA = a.school_name.split(" ")[0].toLowerCase();
              const firstWordB = b.school_name.split(" ")[0].toLowerCase();
              const wordContainsSearch = (word, search) => ~word.indexOf(search) === 0 ? false : true;
              if (
                wordContainsSearch(firstWordA, term) ===
                wordContainsSearch(firstWordB, term)
              ) {
                return 0;
              } else if (
                wordContainsSearch(firstWordA, term) &&
                !wordContainsSearch(firstWordB, term)
              ) {
                return -1;
              } else if (wordContainsSearch(firstWordB, term)) {
                return 1;
              }
            });

            return {
              results: $.map(sorted, function (item) {
                return {
                  text: item.school_name + ", " + item.city + ", " + item.state,
                  id: item.school_name,
                };
              }),
            };
          },
          cache: true,
        },
      });
    },
    setupAcademies() {
      $(".academy").select2({
        allowClear: true,
        closeOnSelect: true,
        placeholder: "Select Academy",
        minimumInputLength: 3,
        ajax: {
          url: "/api/academy-autocomplete-ajax",
          dataType: "json",
          processResults: function (data, search) {
            const sorted = data.sort((a, b) => {
              const term = search.term.toLowerCase();
              const firstWordA = a.name.split(" ")[0].toLowerCase();
              const firstWordB = b.name.split(" ")[0].toLowerCase();
              const wordContainsSearch = (word, search) => ~word.indexOf(search) === 0 ? false : true;
              if (
                wordContainsSearch(firstWordA, term) ===
                wordContainsSearch(firstWordB, term)
              ) {
                return 0;
              } else if (
                wordContainsSearch(firstWordA, term) &&
                !wordContainsSearch(firstWordB, term)
              ) {
                return -1;
              } else if (wordContainsSearch(firstWordB, term)) {
                return 1;
              }
            });

            return {
              results: $.map(data, function (item) {
                return {
                  text: item.name + ", " + item.city + ", " + item.state,
                  id: item.id,
                };
              }),
            };
          },
          cache: true,
        },
      });
    },
    setupClubs() {
      $(".club").select2({
        allowClear: true,
        closeOnSelect: true,
        placeholder: "Select Club",
        minimumInputLength: 3,
        ajax: {
          url: "/api/club-autocomplete-ajax",
          dataType: "json",
          processResults: function (data, search) {
            const sorted = data.sort((a, b) => {
              const term = search.term.toLowerCase();
              const firstWordA = a.name.split(" ")[0].toLowerCase();
              const firstWordB = b.name.split(" ")[0].toLowerCase();
              const wordContainsSearch = (word, search) => ~word.indexOf(search) === 0 ? false : true;
              if (
                wordContainsSearch(firstWordA, term) ===
                wordContainsSearch(firstWordB, term)
              ) {
                return 0;
              } else if (
                wordContainsSearch(firstWordA, term) &&
                !wordContainsSearch(firstWordB, term)
              ) {
                return -1;
              } else if (wordContainsSearch(firstWordB, term)) {
                return 1;
              }
            });

            return {
              results: $.map(data, function (item) {
                return {
                  text: item.name + ", " + item.city + ", " + item.state,
                  id: item.id,
                };
              }),
            };
          },
          cache: true,
        },
      });
    },
    getPositions() {
      switch (this.$parent.sport) {
        case 'football': return window.signingDayConstants['user_positions']
        case 'baseball':
        case 'softball': return window.signingDayConstants['baseball_positions']
        case 'mens_soccer':
        case 'womens_soccer': return window.signingDayConstants['soccer_positions']
      }
    },
    getFilterKeysBySport() {
      const { global, baseball, football, softball, soccer } = this.filters
      switch (this.$parent.sport) {
        case 'football': return Object.keys({...global, ...football})
        case 'baseball': return Object.keys({...global, ...baseball})
        case 'softball': return Object.keys({...global, ...softball})
        case 'mens_soccer':
        case 'womens_soccer': return Object.keys({...global, ...soccer})
        default: return Object.keys(global)
      }
    },
    getFilterTypeByKey(key) {
      let type;
      Object.entries(this.filters)?.forEach(([filterType, filters]) => {
        if (filters.hasOwnProperty(key)) type = filterType
      })
      return type
    },
    buildGlobalFiltersForSearch() {
      const {
        position_one, position_two, city, state, high_school, height_in, height_ft, weight,
        act, sat, unweighted_gpa, grad_year, date, filter, desired_major,
        only_proday, only_interviews, in_state_status, only_interested, academy, club
      } = this.filters.global
      const height = height_ft ? ((height_ft * 12) + (height_in ? parseFloat(height_in) : 0)) : null
      return {
        position: position_one || position_two, position2: position_two,
        city, state, high_school, height_ft, height_in, weight, height, act, sat, unweighted_gpa,
        grad_year, date, filter, desired_major, only_proday, only_interviews, in_state_status,
        only_interested, academy, club
      }
    },
    buildFootballFiltersForSearch() {
      const { forty_yard_dash, shuttle, vertical, bench } = this.filters.football
      return { 'forty_yard_dash': forty_yard_dash, shuttle, vertical, bench }
    },
    buildBaseballFiltersForSearch() {
      return {...this.filters[this.$parent.sport]}
    },
    buildSoccerFiltersForSearch() {
      const { leagues, league_state, five_ten_shuttle_with_ball, five_ten_shuttle_without_ball, figure_eight_with_ball, figure_eight_without_ball, beep_test, yoyo_test, coopers_test, three_hundred_m_shuttle, one_mile_time  } = this.filters.soccer
      return { leagues, league_state, five_ten_shuttle_with_ball, five_ten_shuttle_without_ball, figure_eight_with_ball, figure_eight_without_ball, beep_test, yoyo_test, coopers_test, three_hundred_m_shuttle, one_mile_time }
    },
    buildAdvancedSearchObjectFromFilters() {
      const globals = this.buildGlobalFiltersForSearch()
      switch (this.$parent.sport) {
        case 'football': return {...globals, ...this.buildFootballFiltersForSearch()}
        case 'softball':
        case 'baseball': return {...globals, ...this.buildBaseballFiltersForSearch()}
        case 'mens_soccer':
        case 'womens_soccer': return {...globals, ...this.buildSoccerFiltersForSearch()}
        default: return globals
      }
    },
    saveSearchCriteriaToCookies() {
      const criteria = this.buildAdvancedSearchObjectFromFilters()
      this.setCookie('only_proday', this.filters.global.only_proday === true)
      this.setCookie('only_interviews', this.filters.global.only_interviews === true)
      this.setCookie('only_interested', this.filters.global.only_interested === true)
      this.setCookie('advanced_search', criteria)
      this.searched = this.buildAdvancedSearchObjectFromFilters();
      console.log('saving criteria: ', criteria)
      return criteria
    },
    populateAdvancedSearch(criteria) {
      this.getFilterKeysBySport().forEach(key => {
        const type = this.getFilterTypeByKey(key);
        if (this.filters[type][key] !== undefined && criteria[key] !== undefined) {
          this.filters[type][key] = criteria[key]
        } else {
          if (key === 'forty_yard_dash' && criteria['forty_yard_dash'] !== undefined)
            this.filters.football.forty_yard_dash = criteria['forty_yard_dash']
          else if (key === 'position_one' && criteria['position'] !== undefined)
            this.filters.global.position_one = criteria['position']
          else if (key === 'position_two' && criteria['position2'] !== undefined)
            this.filters.global.position_two = criteria['position2']
          else this.filters[type][key] = null
        }
      })
    },
    prePopulateAdvancedSearch() {
      if (Cookies.get('advanced_search')) {
        const criteria = JSON.parse(this.getCookie('advanced_search'))
        this.populateAdvancedSearch(criteria)
        this.refreshSelect2Instances()
      }
    },
    populateAdvancedSearchFromSaved(event) {
      const { id: data } = event.params.data
      this.populateAdvancedSearch(JSON.parse(data))
      this.refreshSelect2Instances()
    },
    refreshSelect2Instances() {
      Object.entries(this.filters)?.forEach(([type, filters]) => {
        for (const key in filters) {
          if (['grad_year', 'pitch_types'].includes(key)) continue
          const $input = $(`#${key}`)
          if ($input.prop('localName') === 'select') {
            $input.val(this.filters[type][key] || "").select2()
          } else {
            $input.val(this.filters[type][key] || "")
          }
        }
      })
      this.setupHighSchool();
      this.setupAcademies();
      this.setupClubs();
      this.setupLeagues();
    },
    resetSearch() {
      Object.entries(this.filters)?.forEach(([type, filters]) => {
        for (const key in filters) {
          this.filters[type][key] = null
        }
      })
      this.refreshSelect2Instances()
      this.expireSearchCookies()
      this.setupHighSchool()
      this.setupAcademies()
      this.setupClubs()
      this.setupLeagues()
      this.statsSearch = false
      this.visible = false
    },
    getOrResetCookies() {
      const { type } = performance.getEntriesByType('navigation')[0];
      switch (type) {
        case 'reload':
        case 'back_forward': return this.prePopulateAdvancedSearch()
        case 'navigate': return this.expireSearchCookies()
      }
    },
    expireSearchCookies() {
      Cookies.expire("advanced_search");
      Cookies.expire("only_proday");
      Cookies.expire("only_interviews");
      Cookies.expire("advanced_mode")
      Cookies.expire("stats_search")
    },
    scrollToTable() {
      $("html, body").animate({scrollTop: $("#recruit-table").offset().top - 80}, "slow");
    },
    moveResetButtonToLegacyLocation() {
      $('#rosterFilter').find('.col-lg-12').append($('#saved-searches'))
    },
    moveSearchSelectToLegacyLocation() {
      $('#rosterFilter').find('.col-lg-12').append($('#local-searches'))
    },
    dataForStorage() {
      const getSearch = JSON.parse(localStorage.getItem('searched')) || [];
      return getSearch.length >= 10 ?
        [...getSearch.splice(1,9), {...this.searched, searchSport: this.$parent.sport, search_date: moment(new Date()).format('MMMM Do YYYY, h:mm:ss a')}] :
        [...getSearch, {...this.searched, searchSport: this.$parent.sport, search_date: moment(new Date()).format('MMMM Do YYYY, h:mm:ss a')}];
    },
    populateAdvancedSearchFromStorage(event) {
      const data = this.searchValue.find(search => search.search_date === event.target.value);
      this.refreshSelect2Instances()
      this.populateAdvancedSearch(data)
    },
    search() {
      this.scrollToTable()
      this.$emit('search', this.saveSearchCriteriaToCookies())
      const data = this.dataForStorage();
      localStorage.setItem('searched', JSON.stringify(data));
      this.searchValue = data.filter(entry => entry.searchSport === this.$parent.sport);
      $('#search_history').select2();
    },
    handleVelocityChange({params: { data }}) {
      if (data?.id !== '' && parseInt(data?.id) > 0 && !this.filters[this.$parent.sport].pitch_types.includes(1)) {
        this.filters[this.$parent.sport].pitch_types.push(1)
      }
    },
    async fetchPitchTypes() {
      const { data: { data: pitches } } = await axios.get('/api/pitch-types')
      this.pitchTypes = pitches
    },
    async setupLeagues() {
      const leaguesResponse = await axios.get('/api/league/search?sport=' + this.$parent.sport)
      this.leagues = leaguesResponse.data;
      this.checkLeague(this.filters.soccer.leagues);
    },
    checkLeague(id) {
      var league = this.leagues.filter(l => l.id == id);
      if (league.length > 0) {
        this.leagueStateSearch = league[0].name.toLowerCase() == "state league";
      }
    }
  },
}
</script>

<style>
.updated-after {
  padding-left: 12px;
  text-transform: uppercase;
  font-family: KairosSans, serif;
  height: 46px;
  border: 1px solid #dedede;
  border-radius: 1px;
  width: 100%;
}
.btn-font {
  text-transform: uppercase;
  font-family: KairosSans, serif;
}
input#grad_year.el-input__inner {
  height: 45px !important;
  color: #000;
}
.search {
  margin-right: 10px;
  float: right;
}
#select2-high_school-container.select2-selection__arrow {
  right: 50px !important;
}
</style>
