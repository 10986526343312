<template>
  <Fragment>
    <div role="alert" class="Vue-Toastification__heading">{{ heading }}</div>
    <div role="alert" class="Vue-Toastification__message">{{ message }}</div>
    <button v-if="buttonLabel" class="Vue-Toastification__button" @click="$emit('onClick')">{{ buttonLabel }}</button>
  </Fragment>
</template>

<script>
  import CloseIcon from '@components/svg/CloseIcon';
  import { Fragment } from 'vue-frag';

  export default {
    name: 'ToastNotification',
    components: {
      CloseIcon,
      Fragment
    },
    props: {
      heading: {
        type: String,
      },
      message: {
        type: String,
      },
      buttonLabel: {
        type: String,
      }
    },
  }
</script>
