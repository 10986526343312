<template>
  <div :class="{'athlete-profile-link carousel-item col-md-3 nav-link table-section':true, 'active':isActive}">
    <div class="card">
      <div class="card-body">
        <a 
          class="athlete-link"
          :class="{'selected-athlete-link':isActive}" 
          :href="getProspectlistProfileUrl()"
          v-html="getContent(profile)" 
        >
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  const pathname = window.location.pathname;

  export default {
    props: {
      profile: {type: Object, default() {return {}}, required: false},
      athletelist: {type: Array, default() {return []}, required: false},
      screen: {type: String, default: 'public-prospectlist', required: false},
      getUrlWithProfileIndentifier: {type: Function, default() {return null}, required: false},
      getContent: {type: Function, default() {return null}, required: false},
    },

    data() {
      return {
        isActive: false,
      };
    },

    methods: {
      getProspectlistProfileUrl(){
        const url = this.getUrlWithProfileIndentifier(this.profile);
        this.setIsActive((url === pathname));
        return url;
      },
      setIsActive(isActive){
        this.isActive = isActive;
      },
    },
  };
</script>

<style scoped>
  .selected-athlete-link {
    text-decoration: underline;
  }
  .athlete-link {
    text-align: center;
  }
</style>
