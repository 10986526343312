<template>
  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 6.00103L0 1.00103L0.7 0.301025L5 4.60103L9.3 0.301025L10 1.00103L5 6.00103Z" fill="#161616"/>
  </svg>
</template>

<script>
  export default {
    name: "ChevronDown",
  }
</script>
