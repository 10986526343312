<template>
  <div id="weightRoomVerification">
    <div class="row">
        <div class="col-md-12 col-lg-12 col-xl-12">
            <div class="">
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a href="#health" data-toggle="tab" aria-expanded="false" :class="(this.showHealth ? 'active ' : '') + 'nav-link'" @click="switchTab('health')">
                            HEALTH
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#verification" data-toggle="tab" aria-expanded="true" :class="(!this.showHealth && !this.showMeasurements ? 'active ' : '') + 'nav-link'" @click="switchTab('verification')">
                            VERIFICATION
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#measurements" data-toggle="tab" aria-expanded="false" :class="(this.showMeasurements ? 'active ' : '') + 'nav-link'" @click="switchTab('measurements')">
                            MEASUREMENTS
                        </a>
                    </li>
                </ul>
                <div class="nav-border"></div>
                <div class="tab-content">
                    <div :class="(this.showHealth ? 'active ' : '') + 'tab-pane'" id="health">
                        <div class="row" v-if="showHealth">
                            <div class="col-lg-12 col-xs-12">
                              <health :organization_color="organization_color" ref="health"/>
                            </div>
                        </div>
                    </div>
                    <div :class="(!this.showHealth && !this.showMeasurements ? 'active ' : '') + 'tab-pane'" id="verification">
                        <div class="row ml-0 mr-0">
                            <div class="col-12 pl-0 pr-0">
                              <recently-viewed />
                            </div>
                            <div class="col-12 pl-0 pr-0">
                                <table class="table datatable table-bordered display sd-alt-table" id="organization-verification-table">
                                    <thead class="table-header">
                                        <tr>
                                            <th class="d-none"></th>
                                            <th>POS</th>
                                            <th>VERIFICATION</th>
                                            <th>PLAYER & ACTIVITY</th>
                                            <th>REPS & SET</th>
                                            <th>AGE</th>
                                            <th>Weight</th>
                                        </tr>
                                    </thead>
                                    <tbody id="verification-tbody" class="text-capitalize">
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div :class="(this.showMeasurements ? 'active ' : '') + 'tab-pane'" id="measurements">
                        <div class="row" v-if="showMeasurements">
                            <div class="col-lg-12 col-xs-12">
                              <a href="" class="add-measurement" data-dynamic-component="measurement-form" data-modal-title="Create Measurement" data-props="">
                                <span class="addBtn">+</span>
                                Add Measurement
                              </a>
                              <measurements />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
    import axios from 'axios'
    import Multiselect from 'vue-multiselect'
    import RecentlyViewed from './RecentlyViewed'
    import VerificationDetail from './VerificationDetail'

    export default {
        name: 'WeightRoom',
        props: {
          organization_color: String
        },
        data() {
            return {
              pending_measurements: [],
              showMeasurements: this.checkCookies('measurements'),
              showHealth: this.checkCookies('health')
            }
        },

        mounted: function() {
          this.initializeVerification()
        },

        methods: {

          initializeVerification() {
            this.createMeasurementsTable()
          },

          checkCookies(value) {
            if (Cookies.get('weight_room') != null) {
              if (Cookies.get('weight_room') == value) {
                return true
              }
            } else {
              Cookies.set('weight_room', 'verification', { expires: Infinity })
            }
            return false
          },

          createMeasurementsTable() {
            let self = this
            const measurementDt = $('#organization-verification-table').DataTable({
            processing: true,
            serverSide: true,
            responsive: true,
            searching: false,
            stateSave: true,
            dom: 'frt<"bottom"lp><"bottom2"i>',
            ajax: '/api/pending-measurements?datatable=true',
            rowId: 'id',
            language: {
              paginate: {
                previous: "<i class='fa fa-angle-left'>",
                next: "<i class='fa fa-angle-right'>",
              },
            },
            columns: [
              {
                data: 'id',
                name: 'id',
                visible: false,
              },
                            {
                data: null,
                name: null,
                className: 'align-middle text-center',
                orderable: false,
                render(data, type, row, meta) {
                  let positions = row.user.profile.positions.map(p => p.shortcode).join(', ');
                  return positions.length > 0 ? positions : '-';
                },
              },
              {
                data: null,
                name: null,
                className: 'align-middle text-center content-column',
                orderable: false,
                render(data, type, row, meta) {
                  let content = `<div style="max-width: 150px;position:relative;margin-left:auto;margin-right:auto;position relative;">`
                  if (self.getMeasurementVideo(row) != null) {
                    content += `
                      <video style="width:150px;height: 150px;background: #000;">
                        <source src="${self.getMeasurementVideo(row)}"></source>
                      </video>
                      <div class="play-btn"><i class="fa fa-play"></i></div>`
                  } else if (self.getMeasurementImage(row)) {
                    content += `<img class="dynamic-img" src="${self.getMeasurementImage(row)}" alt="Thumbnail">`
                  } else {
                    content += `
                      <div class="default-container org-bg">
                        <img class="default-img" src="/build/images/signing-day-sports-logo.png" alt="Default Image">
                      </div>`
                  }

                  content += `
                    <div class="preview"
                        data-modal-component="verification-detail"
                        data-props=":measurement_id='${row.id}'"></div>
                    </div>`
                  return content
                },
              },
              {
                data: null,
                name: null,
                className: 'align-middle text-center',
                orderable: false,
                render(data, type, row, meta) {
                  return `<h3>${row.user.name}<br>"${row.measurement.name}"</h3>`;
                },
              },
              {
                data: null,
                name: null,
                className: 'align-middle text-center',
                orderable: false,
                render(data, type, row, meta) {
                  let key = row.measurement.type.input_type + '_val';
                  return `<h3 class="gray-text">${self.getMeasurementString(row, row[key], row.measurement.type.input_type)}</h3>`;
                },
              },
              {
                data: null,
                name: null,
                className: 'align-middle text-center',
                orderable: false,
                render(data, type, row, meta) {
                  let cohort = self.getCohorts(row.user.profile.grad_year)
                  return `<h3 class="gray-text">${cohort}</h3>`;
                },
              },
              {
                data: null,
                name: null,
                className: 'align-middle text-center',
                orderable: false,
                render(data, type, row, meta) {
                  return `<h3 class="gray-text">${row.user.profile.weight}</h3>`;
                },
              },
            ],
          });

          measurementDt.draw()
          $.fn.DataTable.ext.pager.numbers_length = 5

          },

          getMeasurementImage(measurement) {
            if(measurement.photo != null) {
              return measurement.photo.url != null
                ? measurement.photo.url
                : measurement.photo.thumbnail_path
            }
            return null;
          },

          getMeasurementVideo(measurement) {
            return measurement.video != null
              ? measurement.video.file_upload.url
              : null
          },

          switchTab(value) {
            switch (value) {
              case 'health' :
                Cookies.set('weight_room', 'health', { expires: Infinity });
                this.showHealth = true
                this.showMeasurements = false
                break;
              case 'measurements' :
                Cookies.set('weight_room', 'measurements', { expires: Infinity });
                this.showMeasurements = true
                this.showHealth = false
                break;
              default:
                Cookies.set('weight_room', 'verification', { expires: Infinity });
                this.showMeasurements = false
                this.showHealth = false
                break;
            }

            this.resizeResponsiveElements()
          },

          getCohorts(year) {
            let date = (new Date).getMonth() > 5
              ? ((new Date).getFullYear() + 1)
              : (new Date).getFullYear()

            switch (parseInt(year)) {
                case date:
                    return 'SR'
                    break;
                case date + 1:
                    return 'JR'
                    break;
                case date + 2:
                    return 'SO'
                    break;
                case date + 3:
                    return 'FR'
                    break;
            }

            if (year > date + 4) {
                return 'FR';
            } else {
                return 'SR';
            }
          },

          getMeasurementString(measurement, value, type) {
            let measurementType = measurement.measurement.type
            let data = value.split('x')
            let unit = measurementType.unit.split('x')[0]
            let data1 = data[0] != null ? data[0] : ''
            let data2 = data[1] != null ? data[1] : ''

            let displayType = measurementType.display_unit != null
              ? measurementType.display_unit
              : ''

            let separator = measurementType.separator != null
              ? measurementType.separator
              : ''

            if (type != 'string') {
                return value + ' ' + displayType
            }

            if (measurementType.unit == 'feet x inches') {
                return data1 + "' " + data2 + '"'
            }

            if (measurementType.unit == "inches x half-inches") {
              return data1 + "." + (parseInt(data2)) + '"'
            }

            return data1 + ' ' + displayType
                + " " + separator + " " + data2
          },

          resizeResponsiveElements() {
            setTimeout(() => {
               $($.fn.dataTable.tables(true)).DataTable().columns.adjust().responsive.recalc()
            }, 200)

            if ($('#recentlyViewedSlider').length > 0 ) {
              setTimeout(() => {
                $('#recentlyViewedSlider').slick('setPosition')
                console.log($('#recentlyViewedSlider').length)
              }, 200)
            }
          }

        },

        watch: {

        }
    }
</script>
