<template>
  <div class="widget-user player">
    <div class="player-actions">
      <i class="mdi mdi-dots-horizontal" data-toggle="dropdown" aria-expanded="false"></i>
      <div class="dropdown-menu">
        <a :href="`/recruit/${player.id}`">View Profile</a>
        <a :href="`/recruits/${player.id}/message`">Message</a>
        <a :href="`/recruit/${player.id}#notes`" v-on:click.prevent="notes(player.id)">Notes</a>
      </div>
    </div>
    <div class="photo">
      <div v-if="index !== false" class="rank">#{{ index + 1 }}</div>
      <a :href="`/recruit/${player.id}`">
        <user-avatar :user="player" />
      </a>
    </div>
    <div class="info wid-u-info" v-if="player">
      <h5><a :href="`/recruit/${player.id}`">{{ player.first_name }} {{ player.last_name }}, {{ position }}</a></h5>
      {{ (this.high_school != 'null' ? this.high_school : '') + (this.high_school != 'null' && this.grad_year != 'null' ? ', ' : '') + (this.grad_year != 'null' ? this.grad_year : '') }}<br v-if="this.high_school != 'null' || this.grad_year != 'null'">
      {{ (this.city != 'null' ? this.city : '') }}<span v-if="this.city && this.state && this.city != 'null' && this.state != 'null'">, </span>{{ this.state != 'null' ? this.state : '' }}<br v-if="this.city != 'null' || this.state != 'null'">
      {{ (this.height != 'null' ? this.height : '') }}<span v-if="this.height && this.height != 'null' && this.weight && this.weight != 'null'"> &bull; </span>{{ this.weight != 'null' ? this.weight : '' }}<br>
    </div>
    <i :class="{ 'hide-show': true, 'fa fa-video-camera': ! active, 'fa fa-times': active }" @click="toggle(player)"></i>
  </div>
</template>

<script>
  import UserAvatar from '@components/UserAvatar';

  export default {
    name: 'PlayerList',
    props: ['player', 'toggle', 'index', 'active', 'position'],
    components: { UserAvatar },
    data() {
      return {
        high_school: this.player.profile.data.basics.data.high_school,
        grad_year: this.player.profile.data.basics.data.grad_year,
        city: this.player.profile.data.basics.data.city,
        state: this.player.profile.data.basics.data.state,
        height: this.player.profile.data.measurables.data.height,
        weight: this.player.profile.data.measurables.data.weight
      }
    },

    mounted() {
      //
    },

    methods: {
      notes: function(player) {
        this.$emit('notes_click', player)
        $('#notesModal').modal()
      }
    }
  }
</script>
