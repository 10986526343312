<template>
  <a
    :class="`btn waves-effect waves-light ${isDisabled ? 'disabled' : ''} ${className}`"
    :href="href"
    :title="title"
    v-bind="$attrs"
  >
    <i v-if="isLoading" class="fa fa-spinner fa-spin"></i>
    <slot v-else></slot>
  </a>
</template>

<script>
export default {
  name: 'IconLink',
  props: {
    href: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    }
  }
}
</script>

<style scoped lang="scss">
  a {
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline-flex;
    height: 2rem;
    justify-content: center;
    padding: 0;
    width: 2rem;

    &.disabled {
      background-color: transparent !important;
      cursor: not-allowed;

      svg {
        fill: #777;
      }
    }

    &:hover {
      background-color: rgba(125, 125, 125, 0.1);
    }

    svg {
      fill: #000;
    }
  }
</style>
