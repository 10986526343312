<template>
  <div :class="[!drag ? 'colorhover big-board-card' : 'big-board-card', position == '' ? 'spacing-card' : `${formation}-card`]">
    <div v-if="position !== ''" style="position:relative;">
      <h3 v-on:click="showYearsModal(position)" style="cursor:pointer">{{position.replace(/.+_/g, '')}}</h3>
      <draggable
        :list="players" v-bind="sortableOptions" :style="`width: 100%; min-height: ${drag ? '150%' : '0px'};`"
        :move="checkMove" @start="setDragState(true)"
        :animation="200" :group="'same'"
        @change="onChange" handle=".handle"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null" class="content-area" :id="position">
          <div class="player-card" v-for="(player, index) in players" :key="player.id" v-on:click="handlePress" :id="player.id">
            <template v-if="((bigBoardDepthLevelState.selectedId - 1 ) >= index) && (index < 5)">
              <p style="width:100%; margin-horizontal: 5px" :class="`player-card-button handle ${player.roster && 'in-roster'}`" data-html2canvas-ignore>
                <i :class="`fa fa-arrows ${player.roster && 'in-roster'}`"></i>
              </p>
              <p :class="`player-card-body has-tooltip ${player.roster && 'in-roster'}`" :data-player="player.id">
                {{fullName(player)}}
                <span class="sd-tooltip">
                  <p v-if="player.profile.height !== 'null null' || player.profile.weight">
                    <span v-if="player.profile.height !== 'null null'">Height {{ player.profile.height }}</span>
                    <span v-if="player.profile.height !== 'null null' && player.profile.weight"> • </span>
                    <span v-if="player.profile.weight">Weight {{ player.profile.weight }}</span>
                  </p>
                  <p v-if="player.profile.high_school || player.profile.city || player.profile.state">
                    <span v-if="player.profile.high_school">{{ player.profile.high_school }}</span>
                    <span v-if="player.profile.high_school && player.profile.city"> • </span>
                    <br>
                    <span v-if="player.profile.city && player.profile.state && player.profile.city.length > 0 && player.profile.state.length > 0">, </span>
                    <span v-else-if="player.profile.city && player.profile.city.length > 0">{{ player.profile.city }},</span>
                    <span v-else-if="player.profile.state">{{ player.profile.state }}</span>
                  </p>
                  <div class="tooltip-btn-group">
                    <a :href="`/recruit/${player.id}`" class="btn btn-primary btn-block profile-link">View Profile</a>
                    <a class="btn btn-primary notes-link" v-on:click="active = false; displayNotes(player.id);">Notes</a>
                  </div>
                </span>
              </p>
              <p style="width:100%" :class="`player-card-button ${player.roster && 'in-roster'}`" v-on:click="removePlayer(player.id)" data-html2canvas-ignore>
                <i :class="`fa fa-times ${player.roster && 'in-roster'}`"></i>
              </p>
            </template>
          </div>
        </transition-group>
      </draggable>
      <p class="more-players-add add-button" v-on:click="openPosition(position)"><span>+</span></p>
      <div v-if="players.length > 5" class="more-players2" v-on:click="openPosition(position)" data-html2canvas-ignore>
        <p class="more-text">{{players.length - 5}} MORE</p>
      </div>
    </div>
    <div v-if="active === true" class="big-board-modal">
      <div class="backdrop" v-on:click.self="active = false; overallPlayers = null">
        <button type="button" v-on:click.self="active = false; overallPlayers = null" aria-label="Close" class="close"><span v-on:click.self="active = false; overallPlayers = null" aria-hidden="true">×</span></button>
        <div class="modal-container">
          <h3 class="d-flex p-0 align-items-center justify-content-between">
            <span class="m-l-10" style="top: 3px; height: 40px">Players</span>
            <div id="pos-dropdown" class="btn-group" style="top: 3px">
              <button type="button" class="btn dropdown-toggle waves-effect waves-light dropdown-container" data-toggle="dropdown" aria-expanded="false">
                <span v-html="getRankDropdownTitleHtml()"></span><span data-html2canvas-ignore><span class="caret"></span></span>
              </button>
              <div class="dropdown-menu">
                <label class="dropdown-item modal-dropdown-item" v-for="pos in getPositionOptions()">
                  <input type="checkbox" v-model="positions" :value="pos"> {{ pos }}
                </label>
              </div>
            </div>
            <div class="btn-group" v-if="isHighSchool" style="top: 3px">
              <button class="btn dropdown-toggle waves-effect waves-light dropdown-container" data-toggle="dropdown" aria-expanded="false">
                <span>Roster Level</span><span data-html2canvas-ignore><span class="caret"></span></span>
              </button>
              <div class="dropdown-menu">
                <label class="dropdown-item modal-dropdown-item"><input type="checkbox" v-model="hsRosterLevels" value="all"> All</label>
                <label class="dropdown-item modal-dropdown-item" v-for="{ id, name } in bigBoardRosterLevelState.levels">
                  <input type="checkbox" :value="id" v-model="hsRosterLevels"> {{ name }}
                </label>
              </div>
            </div>
            <input type="text" style="height: 35px" class="form-control w-auto m-r-10" id="text-search" placeholder="Search..." v-model="search" @keyup="handlePlayerSearch">
          </h3>
          <template v-if="overallPlayers != null">
            <div class="player-card" v-for="(player, index) in getPlayersForDropdown()" :key="player.id" v-on:click="handlePress">
              <p class="player-card-body" v-on:click="handleAddPlayer(player)">#{{index + 1}} {{fullName(player)}} <template v-if="!isHighSchool">({{ player.profile.grad_year }})</template></p>
            </div>
          </template>
          <template v-else>
            <h1 style='text-align:center'>Loading...</h1>
          </template>
        </div>
        <div class="modal-container">
          <h3>BIG BOARD RANKINGS FOR {{ isHighSchool ? getSelectedRosterLevel().name : '' }} {{ position.replace('L_', 'Left ').replace('R_', 'Right ') }}</h3>
          <draggable :list="players" v-bind="sortableOptions" style="width: 100%"
            :move="checkMove" @start="setDragState(true)"
            :animation="200" group="overall" @change="onChange"
            handle=".handle"
          >
            <div class="player-card" v-for="(player, index) in players" :id="player.id" :key="player.id" v-on:click="handlePress">
              <p class="player-card-button handle"><i style="margin-left:10px; margin-right:10px" class="fa fa-arrows"></i></p>
              <p class="player-card-body" v-on:click="removePlayer(player.id)">#{{index + 1}} {{fullName(player)}}</p>
              <p style="padding-left:5px; padding-right:5px; margin-right: 2px;" class="player-card-button" v-on:click="active = false; displayNotes(player.id)">Notes</p>
              <p class="player-card-button" v-on:click="removePlayer(player.id)"><i style="margin-left:10px; margin-right:10px" class="fa fa-times"></i></p>
            </div>
          </draggable>
        </div>
      </div>
    </div>
    <div v-if="showYears === true" class="big-board-modal">
      <div class="backdrop" v-on:click.self="showYears = false; yearPlayers = null; yearLoading = false">
        <button type="button" v-on:click.self="showYears = false; yearPlayers = null; yearLoading = false" aria-label="Close" class="pos-close"><span v-on:click.self="showYears = false; yearPlayers = null; yearLoading = false" aria-hidden="true">×</span></button>
        <div class="year-container" v-if="!isHighSchool">
          <div class="year-column" v-for="year in yearPlayers">
            <h3>{{year.year}}</h3>
            <div v-if="yearLoading === false">
            <draggable
              :list="year.players" style="width: 100%" @start="setDragState(true)"
              :animation="200" group="overall" :move="(e) => checkMove(e, 'year')" @change="e => onChange(e, 'year')"
              handle=".handle" v-bind="sortableOptions"
            >
              <div class="player-card" v-for="(player, index) in year.players" :key="player.id" v-on:click="handlePress">
                <p class="player-card-button handle"><i class="fa fa-arrows"></i></p>
                <p class="player-card-body">#{{index + 1}} {{fullName(player)}}</p>
              </div>
            </draggable>
            </div>
          </div>
        </div>
        <div class="year-container" v-else>
          <div class="year-column" v-for="level in rosterLevelPlayers">
            <h3>{{ level.name }}</h3>
            <div v-if="yearLoading === false">
              <draggable
                :list="level.players" style="width: 100%" @start="setDragState(true)"
                :animation="200" group="overall" :move="checkMove" @change="e => onChange(e, 'year')" handle=".handle"
                v-bind="sortableOptions"
              >
                <div class="player-card" v-for="(player, index) in level.players" :key="player.id">
                  <p class="player-card-button handle"><i class="fa fa-arrows"></i></p>
                  <p class="player-card-body">#{{index + 1}} {{fullName(player)}}</p>
                </div>
              </draggable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Couple improvements to be made at some point... TODO
 *
 *  1 - Use the passed `players` prop instead of remaking as `players`
 *  2 - Make `userData` a computed instead of remaking in every method
 *  3 - $emit('positionUpdated') and handle on parent instead of passing `updatePositions` method
 */

  const sortableOptions = {
    /**
     * Enabling the builtin sortable plugin that reverts card to original location when
     * dropped somewhere invalid.
     *
     * This gets passed to the <draggable> component, which passes it through to it's Sortable()
     *
     * https://github.com/SortableJS/sortablejs/blob/6d47ac3721635df473aa6649dd047ad20557a82c/packages/plugins/on-spill/README.md#revertonspill-plugin
     */
    revertOnSpill: true,
    onSpill(evt) {
      toastr.error("Can't move player there, returned to their original position.");
    }
  };

  let timeout

  export default {
    name: 'PositionCard',
    inject: ['bigBoardDepthLevelState', 'bigBoardRosterLevelState'],
    data: function() {
      return {
        overallPlayers: null,
        drag: false,
        active: false,
        filterPositions: [],
        showYears: false,
        yearPlayers: [],
        yearLoading: false,
        positions: [],
        sortableOptions,
        search: "",
        searchedPlayers: [],
        hsRosterLevels: [],
        isHighSchool: this.$parent.isHighSchool,
        rosterLevelPlayers: [],
      }
    },
    props: [
      'position',
      'players',
      'currentYear',
      'updatePositions',
      'group_id',
      'displayNotes',
      'formation',
      'selectedYear',
    ],
    watch: {
      positions: function (newVal, oldVal) {
        this.openPosition(newVal)
      },
      filterPositions() {
        this.searchedPlayers = null
        this.search = null
      },
      hsRosterLevels: function (current, old) {
        this.openPosition(this.filterPositions)
      }
    },
    methods: {
      getPositionOptions: function () {
        return ["All", ...Object.keys(window.signingDayConstants.user_positions)]
      },
      playerActions: function(id) {
        playerActions(id)
      },
      updateYearPosition: function(players, event) {
        const userData = players.map(({ id }, index) => ({ id, index }))
        const player = event.clone
        const eventObject = {
          type: 'changed', id: player.id, to: event.newIndex+1, position: this.position
        }
        this.updatePositions(this.position, userData, eventObject, true, players)
      },
      checkMove: function(evt, type = 'position') {
        if (type === 'position') {
          if (evt.from.id === evt.to.id) return true;
          const hasDupes = evt.relatedContext.list.some(({ id }) => id === evt.draggedContext.element.id)
          return !hasDupes
        } else if (type === 'year') {
          return evt.from === evt.to
        }
      },
      addedPlayer: async function({ element: player, newIndex }, type = 'position') {
        const userData = this.buildUserDataObject(player, newIndex, type)
        const eventObject = this.buildEventObject('changed', player, newIndex, type)
        await this.updatePositions(this.position, userData, eventObject, false, player)
        this.$forceUpdate()
      },
      removedPlayer: async function({ element: player, newIndex }, type = 'position') {
        const userData = this.buildUserDataObject(player, newIndex, type)
        const eventObject = this.buildEventObject('removed', player, newIndex, type)
        await this.updatePositions(this.position, userData, eventObject, false, player)
        await this.$parent.reRender()
        this.$forceUpdate()
      },
      updatePosition: async function({ element: player, newIndex }, type = 'position') {
        const userData = this.buildUserDataObject(player, newIndex, type)
        const eventObject = this.buildEventObject('changed', player, newIndex, type)
        await this.updatePositions(this.position, userData, eventObject, type === 'year', player)
        this.$forceUpdate()
      },
      async onChange(e, type = 'position') {
        const key = Object.keys(e)[0]
        this.setDragState(false)
        switch (key) {
          case 'added':   await this.addedPlayer(e[key], type);    break
          case 'removed': await this.removedPlayer(e[key], type);  break
          case 'moved':   await this.updatePosition(e[key], type); break
        }
      },
      buildEventObject: function (type, player, index, updateType = 'position') {
        const roster_level_id = (() => {
          switch (true) {
            case this.$parent.isHighSchool && updateType === 'year':
              return player.level_id
            case this.$parent.isHighSchool:
              return this.$parent.currentRosterLevelId
            default:
              return null
          }
        })()
        return {
          type, id: player.id, to: index + 1, position: this.position, profileId: player.profile.id,
          roster_level_id, big_board_rank_id: player?.big_board_rank_id || null
        }
      },
      buildUserDataObject: function (player, index, updateType = 'position') {
        if (updateType === 'year') {
          return this.isHighSchool
            ? this.rosterLevelPlayers.filter(({ id: lId}) => lId === player.level_id)[0].players.map(({ id }, index) => ({ id, index }))
            : this.yearPlayers.filter(({ year }) => year === player.profile.grad_year)[0].players.map(({ id }, index) => ({ id, index }))
        } else {
          return this.players.map(({ id }, index) => ({ id, index }))
        }
      },
      setDragState: function(drag) {
        this.drag = drag
        $(".popover").popover("hide")
      },
      handlePress: function() {
        $(".popover").popover("hide");
        this.$forceUpdate()
      },
      openPosition: function(positions) {
        this.filterPositions = positions
        $(".popover").popover("hide");
        this.active = true

        $('body').toggleClass('freeze-bkgd')
        const params = {
          organization_id: this.group_id,
          positions: this.findParentRank(this.filterPositions),
          order_by: 'position_rank',
          unique: true,
          year: this.isHighSchool ? 'all' : this.selectedYear,
          roster_level_ids: this.hsRosterLevels
        }
        const qString = $.param(params)
        axios.get(`/api/big-board/get-players-by-position?${qString}`).then(response => {
          this.overallPlayers = response.data.data
        })
      },
      fullName: function(player) {
        return player.first_name + " " + player.last_name
      },
      findParentRank: function(rank) {
        const parentRanks = {
          'WR': ['L_WR', 'R_WR', 'SLOT'],
          'T': ['L_T', 'R_T'],
          'G': ['L_G', 'R_G'],
          'CB': ['L_CB', 'R_CB'],
          'DE': ['L_DE', 'R_DE'],
          'OL': ['L_OLB', 'R_OLB'],
          '3B': ['TB'],
        }

        for (let checkRank of Object.keys(parentRanks)) {
          if (parentRanks[checkRank].indexOf(rank) !== -1) {
            return checkRank
          }
        }

        return rank
      },
      handleAddPlayer: function (player) {
        if (!this.isHighSchool) {
          const sameGradYearAsThis = this.players.every(({ profile }) => profile.grad_year === player.profile.grad_year)
          if (!sameGradYearAsThis) {
            return toastr.error(`Can't move player there! They graduate in ${player.profile.grad_year}. You have ${this.$parent.selectedYear}'s Big Board selected.`)
          }
        }
        const exists = this.players.some(({ id }) => id === player.id)
        return !exists ? this.addPlayer(player) : toastr.error("Can't move player there!")
      },
      addPlayer: function(player) {
        this.players.push(player)
        this.overallPlayers[this.overallPlayers.findIndex(overallPlayer => overallPlayer.id === player.id )].position = this.position
        const userData = this.players.map((player, index) => ({ id: player.id, index: index }))
        this.overallPlayers[this.overallPlayers.findIndex(overallPlayer => overallPlayer.id === player.id )].rank = this.overallPlayers.length

        const eventObject = {
          type: 'changed',
          id: player.id,
          to: this.players.length,
          position: this.position,
          profileId: player.profile.id,
          roster_level_id: this.$parent.isHighSchool ? this.$parent.currentRosterLevelId : null,
          big_board_rank_id: null
        }

        window.vueInstance.$refs.big_board.players.push(player);

        this.updatePositions(this.position, userData, eventObject)
      },
      showYearsModal: async function(position) {
        this.showYears = true
        this.yearLoading = true
        this.isHighSchool
          ? this.getPlayersByRosterLevel(position)
          : await this.getPlayersByYear(position)
        this.yearLoading = false;
      },
      getPlayersByRosterLevel(position) {
        this.rosterLevelPlayers = []
        // get respective players for each roster level, that are assigned a roster level...
        this.bigBoardRosterLevelState.levels.forEach(({id, name, shortcode}) =>
          this.rosterLevelPlayers.push({
            name, shortcode, id,
            players: this.$parent.allPlayers.filter(({ level_id, position: pos }) => level_id === id && pos === position)
          })
        )
      },
      getSelectedRosterLevel() {
        if (this.$parent.currentRosterLevelId) {
          return this.bigBoardRosterLevelState.levels.filter((level) => level.id === this.$parent.currentRosterLevelId)[0]
        }
        return { name: '' }
      },
      async getPlayersByYear(position) {
        this.yearPlayers = []
        const years = Array.from(Array(4), (_, n) => Number(this.selectedYear - 1) + n)
        years.forEach(y => this.yearPlayers.push({year: String(y), players: []}))
        years.forEach(async (y, i) => {
          const year = y ? y : (new Date).getFullYear()
          const uri = `/api/big-board/get-players?organization_id=${this.group_id}&order_by=sub_position_rank&year=${year}`
          await axios.get(uri).then(response => {
            const { data: { data: players } } = response
            this.yearPlayers[i].players = players.filter(p => p.position === position).sort((a, b) => a.rank - b.rank)
            if (i === 2) this.yearLoading = false
          })
        })
      },
      removePlayer: function(id) {
        const player = this.players.filter(p => p.id === id)[0]
        this.players.splice(this.players.findIndex(player => player.id == id), 1)

        const userData = this.players.map((player, index) => {
          return {id: player.id, index: index, profileId: player.profile.id}
        })

        const eventObject = {
          type: 'removed', id, position: this.position, profileId: player.profile.id,
          roster_level_id: this.$parent.isHighSchool ? this.$parent.currentRosterLevelId : null,
          big_board_rank_id: player?.big_board_rank_id || null
        }

        if (this.overallPlayers && this.overallPlayers.findIndex(player => player.id == id) !== -1) {
          this.overallPlayers[this.overallPlayers.findIndex(player => player.id == id)].position = null
        }

        this.updatePositions(this.position, userData, eventObject)
      },
      getPlayersForDropdown() {
        const { searchedPlayers, overallPlayers } = this
        return searchedPlayers && searchedPlayers.length > 0 ? searchedPlayers : overallPlayers
      },
      getRankDropdownTitleHtml() {
        const { filterPositions } = this
        const text = this.getRankDropdownTitle()
        const splits = text.split('+')
        const selected = (typeof filterPositions === 'string') ? [filterPositions] : filterPositions
        const result = (splits.length === 1 ? text : splits[0])
          .replace('L_', 'Left ')
          .replace('R_', 'Right ')
          .toUpperCase()
        $('[data-toggle="tooltip"]').tooltip()
        return splits.length === 1
          ? result
          : `${result}<span data-toggle="tooltip" data-placement="bottom" data-original-title="${selected.join(', ')}"> + ${splits[1]}</span>`
      },
      getRankDropdownTitle() {
        const { position: rankPosition , filterPositions } = this
        const filters = (typeof filterPositions === 'string') ? [filterPositions] : filterPositions
        if (filters.includes('All')) return 'All'
        switch (filters.length) {
          case 0: return rankPosition
          case 1: return filters.toString()
          case 2: return filters.join(' & ')
          default: return filters.length === 1
            ? rankPosition
            : `${filters.slice(0, 2).join(', ').toString()} + ${filters.length - 2}`
        }
      },
      handlePlayerSearch() {
        clearTimeout(timeout);
        const handle = () => {
          const search = this.search
          if (search != null) {
            this.searchedPlayers = this.overallPlayers.filter(({ full_name }) => full_name.toLowerCase().includes(search.toLowerCase()))
          }
        }
        timeout = setTimeout(handle, 500);
      }
    },
  }
</script>
<style>
  .tooltip {
    z-index: 9999999;
  }
  .dropdown-container {
    border-radius:0;
    padding: 7.5px 10px;
    font-family: KairosSans, serif;
    font-size: 24px;
    color: rgb(231, 248, 25);
  }
  .modal-dropdown-item {
    height: 25px;
    line-height: 25px
  }
  .big-board-modal .btn:hover {
    color: #E7F816 !important;
  }
</style>
