<template>
  <fragment>
    <IconButton @onClick="toggleDrawer" title="Edit List">
      <PencilIcon />
    </IconButton>
    <Drawer
      heading="Edit List"
      :isOpen="isDrawerOpen"
      @onClose="handleCancel"
    >
      <EditInviteForm
        v-bind="{defaultValues, listId}"
        :name="formName"
        @onCancel="handleCancel"
        @onSuccess="onSuccess"
      />
    </Drawer>
  </fragment>
</template>

<script>
import { Fragment } from 'vue-frag';
import Drawer from '@components/Drawer';
import IconButton from '@components/IconButton';
import PencilIcon from '@components/svg/PencilIcon';
import EditInviteForm from "@screens/myinvites/InviteListDetails/InviteListDetailsHeader/components/EditInviteForm";

export default {
  name: "EditInviteSingleButton",
  components: { Drawer, IconButton, Fragment, EditInviteForm, PencilIcon },
  inject: ['setHeader'],
  props: ['name', 'summary', 'listId'],
  data() {
    return {
      isDrawerOpen: false,
      formName: 'editInviteForm',
      defaultValues: {
        name: this.name,
        summary: this.summary,
      },
    }
  },
  methods: {
    handleCancel() {
      this.$formulate.reset(this.formName, this.defaultValues);
      this.toggleDrawer();
    },
    onSuccess(data) {
      this.setHeader(data.data.data.name, data.data.data.summary);
      this.toggleDrawer();
    },
    toggleDrawer() {
      this.isDrawerOpen = !this.isDrawerOpen;
    },
  },
}
</script>
