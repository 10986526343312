<template>
  <div>
    <div class="share-interface">
      <!-- Button trigger COACH LOGIN modal -->
      <button hidden type="button" class="btn btn-primary" data-toggle="modal" data-target="#publicLoginModal">
        Launch Coach Login modal
      </button>

		  <div class="modal fade" id="publicLoginModal" role="dialog" aria-labelledby="publicLoginModalLabel" aria-hidden="true">
		    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
		      <div class="modal-content shadow-lg align-items-center" style="padding:0; margin:0; max-width: 75%;">

		        <div class="modal-header bg-primary d-flex justify-content-between" style="width:100%; height: 120px; align-items: center;">
		        	<slot name="header-logo"></slot>

		        	<slot name="header-title"></slot>

		          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
		            <span aria-hidden="true">&times;</span>
		          </button>
		        </div>

		        <div class="modal-body">
		        	<slot name="body"></slot>
		        </div>

		        <div class="modal-footer d-flex justify-content-center">
		          <slot name="footer"></slot>
		        </div>
		      </div>
		    </div>
		  </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
      login_errors: {type: String, default() {return 'false'}, required: false},
    },

    data() {
      return {

      }
    },

    methods: {
    	resetLogin(){
				$('#email').val('').trigger('change').removeClass('is-invalid');
				$('#password').val('').trigger('change').removeClass('is-invalid');
    	},
    },

    mounted() {
    	$(document).ready(() => {
	    	if(this.login_errors === 'true') $('#publicLoginModalButton').trigger('click');
				$("#publicLoginModal").on("hidden.bs.modal", () => this.resetLogin());
			});
    },
  }
</script>

<style>
  #publicLoginModal .modal-content {
    padding-top: 50px;
    padding-bottom: 10px;
  }

  #publicLoginModal .modal-header,
  #publicLoginModal .modal-content,
  #publicLoginModal .modal-dialog,
  #publicLoginModal .modal-body,
  #publicLoginModal .modal-footer {
    border: none;
    overflow: visible;
  }

  #publicLoginModal .modal-header,
  #publicLoginModal .modal-body,
  #publicLoginModal .modal-footer
   {
    margin: 0 auto;
    width: 90%;
  }

  #publicLoginModal .btn.btn-link {
    color: #A9A9A9 !important;
    font-size: 14px !important;
    font-weight: bold !important;
  }

  #publicLoginModal .btn {
    padding-left: 65px;
    padding-right: 65px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  #publicLoginModal .modal-body a,
  #publicLoginModal .modal-footer a
  {
    color: #FFFFFF !important;
  }

  #publicLoginModal .modal-body {
    padding-top: 20px;
    padding-bottom: 10px;
  }

  #publicLoginModal .close {
    background: none;
    border: none;
    top: -22px;
    right: 8px;
    font-size: 3em;
    color: #FFFFFF;
    font-weight: normal !important;
  }

  #publicLoginModalLabel span {
    font-size: .65em;
    color: #A9A9A9 !important;
  }

  #publicLoginModal .start-conversation {
    margin-bottom: 40px;
  }
</style>
