<template>
  <div class="player-container" style="background:white">
    <div class="verified-players-invite-body">
      <data-tables-server
          id="preview-invites-table"
          :data="data_rows"
          :loading="loading"
          :total="count"
          :current-page="currentPaginationPage"
          :pagination-props="{
            pageSizes: [10, 25, 50, 100],
            background: true,
            layout: 'prev, pager, next, sizes, ->, total'
          }"
          :table-props="{
            'row-key': 'email',
            'class': 'table datatable'
          }"
          @selection-change="selectionChanged"
      >
        <el-table-column label="Name" align="center">
          <template slot-scope="{ row }">
            <h5>{{ row.name }}</h5>
          </template>
        </el-table-column>
        <el-table-column label="Email" align="center">
          <template slot-scope="{ row }">
            <h5>{{ renderVariable(row.email) }}</h5>
          </template>
        </el-table-column>
        <el-table-column label="Parent Email" align="center">
          <template slot-scope="{ row }">
            <h5>{{ renderVariable(row.parent_email) }}</h5>
          </template>
        </el-table-column>
        <el-table-column label="Grad Year" align="center">
          <template slot-scope="{ row }">
            <h5>{{ renderVariable(row.grad_year) }}</h5>
          </template>
        </el-table-column>
        <el-table-column label="Phone" align="center">
          <template slot-scope="{ row }">
            <h5>{{ renderVariable(row.phone) }}</h5>
          </template>
        </el-table-column>

        <el-table-column
            align="center"
            type="selection"
            width="150"
            reserve-selection
        ></el-table-column>
      </data-tables-server>
    </div>
    <div class="verified-players-invite-footer">
      <button :disabled="selected.length == 0" @click="saveInvitedPlayers" class="btn btn-primary">Continue</button>
    </div>
  </div>

</template>

<script>
import DataTablesServer from '@components/ui/DataTablesServer';

export default {
  name: "PreviewInvitedPlayersGrid",
  components: { DataTablesServer },
  props: {
    data_rows: {type: Array, default: [], required: true},
  },
  data: () => ({
    loading: false,
    selected: [],
    filters: [],
    currentPaginationPage:1
  }),
  methods: {
    renderVariable(value){
      if(value){
        return value;
      }else{
        return "N/A";
      }
    },
    saveInvitedPlayers() {
      this.$emit('save_invited_players',this.selected);
    },
    selectionChanged(s)  {
      this.selected = s;
    }
  },
  computed: {
    count: function () {
      return this.data_rows.length;
    }
  },
}
</script>

<style scoped>
.player-container {
}

.verified-players-invite-body {
  margin-bottom: 2rem;
}

.verified-players-invite-footer {
  padding: 1rem 2rem;
  background: #f9f9f9;
  border-radius: 0 0 5px 5px;
  display: flex;
  justify-content: flex-end;
}

.btn {
  text-transform:uppercase;
  font-family: KairosSans,sans-serif;
}
</style>

<style>
.verified-players-invite-body .el-table__header .el-table-column--selection .el-checkbox::before {
  /* https://github.com/ElemeFE/element/issues/5314 */
  content: "Invite";
  color: white;
  padding-right: 5px;
}
.verified-players-invite-body .el-table__header .el-table-column--selection .el-checkbox {
  /* moves checkbox down to match same level as other column-header elements */
  position: relative;
  top:  4px;
}
</style>
