<template>
  <v-popover v-if="hasSchoolData" trigger="hover">
    <QuestionMarkIcon class="tooltip-icon" />
    <template slot="popover">
      <div>
        <p v-if="street || zip" class="tooltip-text">{{ `${street}${street && zip ? ', ' : ''}${zip}` }}</p>
        <p v-if="countyName" class="tooltip-text">{{ countyName }}</p>
        <p v-if="phone" class="tooltip-text">{{ phone }}</p>
        <a v-if="url" :href="url" class="tooltip-text-anchor">View School Website</a>
      </div>
    </template>
  </v-popover>
</template>

<script>
import QuestionMarkIcon from "@components/svg/QuestionMarkIcon";

export default {
  name: "SchoolDataTooltip",
  components: {QuestionMarkIcon},
  props: {
    street: {
      type: String,
      default: null,
      required: false
    },
    zip: {
      type: String,
      default: null,
      required: false
    },
    countyName: {
      type: String,
      default: null,
      required: false
    },
    phone: {
      type: String,
      default: null,
      required: false
    },
    url: {
      type: String,
      default: null,
      required: false
    },
  },
  data() {
    return {
      QuestionMarkIcon
    }
  },
  computed: {
    hasSchoolData() {
      return this.street
        || this.zip
        || this.countyName
        || this.phone
        || this.url
    }
  }
}
</script>

<style lang="scss" scoped>
.tooltip-icon {
  cursor: pointer;
  height: 20px;
  margin-bottom: 3px;
  width: 20px;
}

.tooltip-text {
  margin-bottom: 0 !important;
  text-transform: uppercase;
  width: 100%;

  &-anchor {
    color: #F4F4F4;
    text-decoration: underline;
    text-transform: uppercase;

    &:hover {
      color: #9398a0;
    }
  }
}
</style>
