<template>
  <fragment>
    <FormulateForm id="search-form">
      <FormInput
        name="search"
        type="text"
        placeholder="Search Players"
        validation="required"
        aria-label="Search Players"
        :disableErrors="true"
        :startIcon="searchInputStartIcon"
      />
      <Button :endIcon="endIcon">Add Player To List</Button>
    </FormulateForm>
  </fragment>
</template>

<script>
import { Fragment } from 'vue-frag';
import Button from '@components/Button';
import FormInput from '@components/FormInput';
import PlusIcon from '@components/svg/PlusIcon';
import MagnifyingGlassIcon from '@components/svg/MagnifyingGlassIcon';

export default {
  name: "InviteListDetailsPlayerSearch",
  components: { Button, Fragment, FormInput, MagnifyingGlassIcon },
  data() {
    return {
      searchInputStartIcon: MagnifyingGlassIcon,
      endIcon: PlusIcon
    }
  }
}
</script>

<style lang="scss">
  #search-form {
    display: flex;
    width: 550px;

    .formulate-input {
      flex: 1
    }
  }
</style>
