<template>
  <div>
    <div class="form-group">
      <h4>Sprinting</h4>
      <div class="row">
        <div class="stats col-sm-6">
          <div class="stat-item text-center">
            <div type="button" class="stat-key">
              <div class="stat-title">
                {{ displayTitles['sprint60'] }}
              </div>
              <div class="stat-value">
                {{ returnValueOrNA('sprint60') }}
              </div>
            </div>
          </div>
        </div>
        <div class="stats col-sm-6">
          <div class="stat-item text-center">
            <div type="button" class="stat-key">
              <div class="stat-title">
                {{ displayTitles['sprint81'] }}
              </div>
              <div class="stat-value">
                {{ returnValueOrNA('sprint81') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="hasValue('sprint_comments')">
        <div class="stats col-sm-12">
          Comments: {{ values['sprint_comments'] }}
        </div>
      </div>
    </div>
    <div class="form-group">
      <h4>Vertical</h4>
      <div class="row">
        <div class="stats col-sm-12">
          <div class="stat-item text-center">
            <div type="button" class="stat-key">
              <div class="stat-title">
                {{ displayTitles['vertical'] }}
              </div>
              <div class="stat-value">
                {{ returnValueOrNA('vertical') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="hasValue('vertical_comments')">
        <div class="stats col-sm-12">
          Comments: {{ values['vertical_comments'] }}
        </div>
      </div>
    </div>
    <div class="form-group">
      <h4>Grip Strength</h4>
      <div class="row">
        <div class="stats col-sm-6">
          <div class="stat-item text-center">
            <div type="button" class="stat-key">
              <div class="stat-title">
                {{ displayTitles['grip_left'] }}
              </div>
              <div class="stat-value">
                {{ returnValueOrNA('grip_left') }}
              </div>
            </div>
          </div>
        </div>
        <div class="stats col-sm-6">
          <div class="stat-item text-center">
            <div type="button" class="stat-key">
              <div class="stat-title">
                {{ displayTitles['grip_right'] }}
              </div>
              <div class="stat-value">
                {{ returnValueOrNA('grip_right') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="hasValue('grip_comments')">
        <div class="stats col-sm-12">
          Comments: {{ values['grip_comments'] }}
        </div>
      </div>
    </div>
    <div class="form-group">
      <h4>Directional Speed</h4>
      <div class="row">
        <div class="stats col-sm-4">
          <div class="stat-item text-center">
            <div type="button" class="stat-key">
              <div class="stat-title">
                {{ displayTitles['directional_speed_left'] }}
              </div>
              <div class="stat-value">
                {{ returnValueOrNA('directional_speed_left') }}
              </div>
            </div>
          </div>
        </div>
        <div class="stats col-sm-4">
          <div class="stat-item text-center">
            <div type="button" class="stat-key">
              <div class="stat-title">
                {{ displayTitles['directional_speed_center'] }}
              </div>
              <div class="stat-value">
                {{ returnValueOrNA('directional_speed_center') }}
              </div>
            </div>
          </div>
        </div>
        <div class="stats col-sm-4">
          <div class="stat-item text-center">
            <div type="button" class="stat-key">
              <div class="stat-title">
                {{ displayTitles['directional_speed_right'] }}
              </div>
              <div class="stat-value">
                {{ returnValueOrNA('directional_speed_right') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="hasValue('directional_speed_comments')">
        <div class="stats col-sm-12">
          Comments: {{ values['directional_speed_comments'] }}
        </div>
      </div>
    </div>
    <div class="form-group">
      <h4>Internal Rotation</h4>
      <div class="row">
        <div class="stats col-sm-6">
          <div class="stat-item text-center">
            <div type="button" class="stat-key">
              <div class="stat-title">
                {{ displayTitles['internal_rotation_left'] }}
              </div>
              <div class="stat-value">
                {{ returnValueOrNA('internal_rotation_left') }}
              </div>
            </div>
          </div>
        </div>
        <div class="stats col-sm-6">
          <div class="stat-item text-center">
            <div type="button" class="stat-key">
              <div class="stat-title">
                {{ displayTitles['internal_rotation_right'] }}
              </div>
              <div class="stat-value">
                {{ returnValueOrNA('internal_rotation_right') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="hasValue('internal_rotation_comments')">
        <div class="stats col-sm-12">
          Comments: {{ values['internal_rotation_comments'] }}
        </div>
      </div>
    </div>
    <div class="form-group">
      <h4>External Rotation</h4>
      <div class="row">
        <div class="stats col-sm-6">
          <div class="stat-item text-center">
            <div type="button" class="stat-key">
              <div class="stat-title">
                {{ displayTitles['external_rotation_left'] }}
              </div>
              <div class="stat-value">
                {{ returnValueOrNA('external_rotation_left') }}
              </div>
            </div>
          </div>
        </div>
        <div class="stats col-sm-6">
          <div class="stat-item text-center">
            <div type="button" class="stat-key">
              <div class="stat-title">
                {{ displayTitles['external_rotation_right'] }}
              </div>
              <div class="stat-value">
                {{ returnValueOrNA('external_rotation_right') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="values['external_rotation_comments'] !== null && values['external_rotation_comments'] !== ''">
        <div class="stats col-sm-12">
          Comments: {{ values['external_rotation_comments'] }}
        </div>
      </div>
    </div>
    <div class="form-group">
      <h4>Velocity</h4>
      <div class="row">
        <div class="stats col-sm-6">
          <div class="stat-item text-center">
            <div type="button" class="stat-key">
              <div class="stat-title">
                {{ displayTitles['raw_exit_velocity'] }}
              </div>
              <div class="stat-value">
                {{ returnValueOrNA('raw_exit_velocity') }}
              </div>
            </div>
          </div>
        </div>
        <div class="stats col-sm-6">
          <div class="stat-item text-center">
            <div type="button" class="stat-key">
              <div class="stat-title">
                {{ displayTitles['batted_exit_velocity'] }}
              </div>
              <div class="stat-value">
                {{ returnValueOrNA('batted_exit_velocity') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="hasValue('raw_exit_velocity_comments') || hasValue('batted_exit_velocity_comments')">
        <div class="stats col-sm-6" v-if="hasValue('raw_exit_velocity_comments')">
          Comments: {{ values['raw_exit_velocity_comments'] }}
        </div>
        <div class="stats col-sm-6" v-if="hasValue('batted_exit_velocity_comments')">
          Comments: {{ values['batted_exit_velocity_comments'] }}
        </div>
      </div>
    </div>
    <div class="form-group">
      <h4>Pitching</h4>
      <div class="row">
        <div class="stats col-sm-4">
          <div class="stat-item text-center">
            <div type="button" class="stat-key">
              <div class="stat-title">
                {{ displayTitles['pitching_velocity_fastball'] }}
              </div>
              <div class="stat-value">
                {{ returnValueOrNA('pitching_velocity_fastball') }}
              </div>
            </div>
          </div>
        </div>
        <div class="stats col-sm-4">
          <div class="stat-item text-center">
            <div type="button" class="stat-key">
              <div class="stat-title">
                {{ displayTitles['pitching_velocity_changeup'] }}
              </div>
              <div class="stat-value">
                {{ returnValueOrNA('pitching_velocity_changeup') }}
              </div>
            </div>
          </div>
        </div>
        <div class="stats col-sm-4">
          <div class="stat-item text-center">
            <div type="button" class="stat-key">
              <div class="stat-title">
                {{ displayTitles['pitching_velocity_choice'] }}
              </div>
              <div class="stat-value">
                {{ returnValueOrNA('raw_exit_velocity') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="hasValue('pitching_velocity_comments')">
        <div class="stats col-sm-12">
          Comments: {{ values['pitching_velocity_comments'] }}
        </div>
      </div>
    </div>
    <div class="form-group">
      <h4>Velocity</h4>
      <div class="row">
        <div class="stats col-sm-2">
          <div class="stat-item text-center">
            <div type="button" class="stat-key">
              <div class="stat-title">
                {{ displayTitles['wrist_mobility_left'] }}
              </div>
              <div class="stat-value">
                {{ returnValueOrNA('wrist_mobility_left') }}
              </div>
            </div>
          </div>
        </div>
        <div class="stats col-sm-2">
          <div class="stat-item text-center">
            <div type="button" class="stat-key">
              <div class="stat-title">
                {{ displayTitles['wrist_mobility_right'] }}
              </div>
              <div class="stat-value">
                {{ returnValueOrNA('wrist_mobility_right') }}
              </div>
            </div>
          </div>
        </div>
        <div class="stats col-sm-2">
          <div class="stat-item text-center">
            <div type="button" class="stat-key">
              <div class="stat-title">
                {{ displayTitles['supination_left'] }}
              </div>
              <div class="stat-value">
                {{ returnValueOrNA('supination_left') }}
              </div>
            </div>
          </div>
        </div>
        <div class="stats col-sm-2">
          <div class="stat-item text-center">
            <div type="button" class="stat-key">
              <div class="stat-title">
                {{ displayTitles['supination_right'] }}
              </div>
              <div class="stat-value">
                {{ returnValueOrNA('supination_right') }}
              </div>
            </div>
          </div>
        </div>
        <div class="stats col-sm-2">
          <div class="stat-item text-center">
            <div type="button" class="stat-key">
              <div class="stat-title">
                {{ displayTitles['pronation_left'] }}
              </div>
              <div class="stat-value">
                {{ returnValueOrNA('pronation_left') }}
              </div>
            </div>
          </div>
        </div>
        <div class="stats col-sm-2">
          <div class="stat-item text-center">
            <div type="button" class="stat-key">
              <div class="stat-title">
                {{ displayTitles['pronation_right'] }}
              </div>
              <div class="stat-value">
                {{ returnValueOrNA('pronation_right') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="hasValue('mobility_comments')">
        <div class="stats col-sm-4">
          Comments: {{ values['mobility_comments'] }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    displayTitles: {type: Object, required: false},
    values: {type: Object, required: false},
  },
  methods: {
    returnValueOrNA(key) {
      if (this.hasValue(key)) {
        return this.values[key];
      }
      return 'N/A';
    },
    hasValue(key) {
      if (this.values[key] !== null && this.values[key] !== '' && this.values[key] !== '0.00') {
        return true;
      }
      return false;
    }
  }
}
</script>
