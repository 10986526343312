<template>
  <div v-if="rowData" class="details-row">
    <component class="details-icon" v-if="startIcon" :is="startIcon"></component>
    <span class="details-data">{{ rowData }}</span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "DetailsContainerRow",
  props: {
    rowData: {
      type: String,
      default: null,
    },
    startIcon: {
      type: Object,
      default: null,
    },
  }
}
</script>
