<template>
  <div :class="['my-invites-wrapper', sport_name]">
    <div class="input-group">
      <div class="has-search">
        <img alt="" src="/build/images/icon_magnifying_glass.svg">
        <input
          id="searchList"
          v-model="params.search"
          class="form-control"
          placeholder="Search player or list"
          type="search"
          @keyup="retrieveInvites(1)"
        />
      </div>

      <div class="input-group-append invite-button-group">
        <button class="btn importPlayers" @click="showModal()">
          Import Invite List
          <img alt="" src="/build/images/icon_plus_black.svg">
        </button>
<!--        TODO: Will implement in next release SDS-1454-->
<!--        <button id="invitePlayer" class="btn">-->
<!--          Invite Player-->
<!--          <img alt="" src="/build/images/icon_plus_white.svg">-->
<!--        </button>-->
        <right-blade-upload
          :closeModal="closeModal"
          :isOpen="isOpen"
          :retrieveInvites="retrieveInvites"
        />
      </div>
    </div>

    <div class="cta-container">
      <table class="table">
        <thead>
        <tr>
          <th></th>
          <th @click="invites.length > 1 && orderBy('name')">Invite Name
            <i v-if="params.orderby === 'name'" :class="`fa fa-angle-${params.sort === 'asc' ? 'up' : 'down'}`"></i>
          </th>
          <th @click="invites.length > 1 && orderBy('player_count')">Total Players
            <i v-if="params.orderby === 'player_count'" :class="`fa fa-angle-${params.sort === 'asc' ? 'up' : 'down'}`"></i>
          </th>
          <th @click="invites.length > 1 && orderBy('organization_id')">Players Invited
            <i v-if="params.orderby === 'organization_id'" :class="`fa fa-angle-${params.sort === 'asc' ? 'up' : 'down'}`"></i>
          </th>
          <th @click="invites.length > 1 && orderBy('sport_id')">Profiles Created
            <i v-if="params.orderby === 'sport_id'" :class="`fa fa-angle-${params.sort === 'asc' ? 'up' : 'down'}`"></i>
          </th>
          <th>Invite Actions</th>
        </tr>
        </thead>
        <tbody v-for="invite in invites" v-if="invites.length > 0">
        <tr :class="`${activeRow === invite.id && 'active-row'}`">
          <td class="cursor-pointer-td" @click="setActiveRow(invite.id)">
            <img :src="`/build/images/icon_chevron_${activeRow === invite.id ? 'up' : 'down'}.svg`" alt="">
          </td>
          <td><a :href="`/my-invites/${invite.id}`">{{ invite.name }}</a></td>
          <td>{{ invite.player_count }}</td>
          <td>{{ invite.invited_count }}</td>
          <td>{{ invite.profiles_created }}</td>
          <action-items
            :close-popup="closePopup"
            :hovered="hovered"
            :hovered-two="hoveredTwo"
            :invite="invite"
            :set-hovered="setHovered"
            :set-hovered-two="setHoveredTwo"
          />
        </tr>
        <tr v-if="activeRow === invite.id">
          <expanded-row-body :invite="invite"/>
        </tr>
        </tbody>
        <pagination
          :colSpan="6"
          :on-retrieve-resource="retrieveInvites"
          :pagination="pagination"
          :params="params"
          :resource-count="invites.length"
        />
      </table>
      <div v-if="invites.length === 0" class="table-content">
        <p>You currently Have No Lists Imported.</p>
        <button class="btn importPlayers" @click="showModal()">
          Import Invite List
          <img alt="" src="/build/images/icon_plus_black.svg">
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import Pagination from './components/Pagination'
import ActionItems from "./components/ActionItems";
import ExpandedRowBody from "./components/ExpandedRowBody";
import RightBladeUpload from "./components/RightBladeUpload";

export default {
  name: 'MyInvitesLists',
  components: {Pagination, ActionItems, ExpandedRowBody, RightBladeUpload},
  props: {
    sport_name: {type: String, default: 'football', required: false},
  },
  data() {
    return {
      invites: [],
      column: '',
      activeRow: [],
      hovered: 0,
      hoveredTwo: 0,
      pagination: {},
      list: {},
      listUploaded: false,
      params: {
        page: 1,
        limit: 50,
        search: '',
        orderby: 'created_at',
        sort: 'desc',
      },
      query: new URLSearchParams(window.location.search),
      isOpen: false,
    }
  },
  mounted() {
    for (let param in this.params) {
      this.params[param] = this.query.get(param) || this.params[param]
    }
    this.retrieveInvites(this.params.page)
  },
  provide() {
    return {
      params: this.params,
    }
  },
  methods: {
    fileChosen(file) {
      this.$emit('file_chosen', file);
    },
    retrieveInvites(page) {
      this.params.page = page
      let query = Object.keys(this.params).map(key => key + '=' + this.params[key]).join('&');
      const controller = new AbortController();
      axios.get(
        'api/player-invites?' + query,
        {
          signal: controller.signal
        }
      ).then(response => {
        this.invites = response.data.data
        this.pagination = response.data.meta
        this.activeRow = null
      })
        .catch((err) => {
          console.error(err)
        })
    },
    orderBy(column) {
      this.params.sort = this.params.sort === 'desc' ? 'asc' : 'desc'
      if (column !== this.params.orderby) {
        this.params.sort = 'asc'
        this.params.orderby = column
      }
      this.retrieveInvites(this.params.page)
    },
    setActiveRow(id) {
      this.activeRow = this.activeRow === id ? 0 : id
    },
    setHovered(id) {
      this.hovered = id
      this.hoveredTwo = 0
    },
    setHoveredTwo(id) {
      this.hoveredTwo = id
      this.hovered = 0
    },
    closePopup() {
      this.hovered = 0
      this.hoveredTwo = 0
    },
    showModal() {
      this.isOpen = true
      document.querySelector('#wrapper').style.overflow = 'hidden';
      document.querySelector('#wrapper').style.position = 'fixed';
    },
    closeModal() {
      this.isOpen = false
      document.querySelector('#wrapper').style.overflow = 'revert';
      document.querySelector('#wrapper').style.position = 'revert';
    },
  }
}
</script>

<style lang="scss" scoped>
.my-invites-wrapper {
  width: 100%;

  & .input-group {
    margin-bottom: 3px;
  }
}


.has-search {
  width: 30%;

  & .form-control {
    background: #F4F4F4;
    border: 0;
    color: #A8A8A8;
    padding-left: 2.5rem;
  }

  & .form-control-feedback {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: black;
  }

  & img {
    position: absolute;
    top: 25%;
    left: 10px;
    transform: translateY(25%);
  }
}


#searchList {
  box-shadow: inset 0px -1px 0px #8D8D8D;
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.16px;
}

.invite-button-group {
  flex: 25%;
  justify-content: flex-end;
  font-family: KairosSans, sans-serif;

  & button {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    height: 32px;
    align-self: flex-end;
    letter-spacing: 0.16px;

    svg {
      position: relative;
      top: -1px;
    }
  }

  & #invitePlayer {
    background-color: black;
    color: white;
  }
}

.importPlayers {
  background-color: #E7F816;
  color: black;

  &:last-child {
    margin-bottom: 17px;
  }
}

.cta-container {
  text-align: center;
}

h2 {
  margin-bottom: 2rem;
  color: white;
}

.table {
  font-family: 'DINPro', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0;

  tr {
    border-bottom: 0 !important;
  }

  .cursor-pointer-td {
    cursor: pointer;
  }
}

.active-row {
  background: #DFDFDF;
  z-index: 1;
}

th {
  border-top: 0 !important;
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
}

th, td {
  text-align: center !important;
}

td {
  border-top: 0;
  position: relative;
}

td img {
  cursor: pointer;
}

.table-content {
  background-color: #f1f1f1;
  font-family: KairosSans, sans-serif;
  padding: 0 !important;
  text-transform: uppercase;
  width: 100%;
}

.table-content p {
  color: #ABABAB;
  font-size: 14px;
  letter-spacing: 0.16px;
  line-height: 18px;
  padding: 13px 0 0;
  width: 100%;
}
</style>
