<template>
  <div class="video-title-upload">
    <div v-if=" ! file ">
      <div v-if="this.relationship === 'profileImage' && this.role !== 'player'">
         <div class="card-box pp_card-box background-image" :style="{backgroundImage: 'url(' + this.imageUrl +')', backgroundSize: 'cover', backgroundPosition: 'center'}">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-xl-4 m-0-auto text-center">
              <div class="p-20 pb-0">
                <div class="form-group">
                  <div class="thumb-xl member-thumb m-b-10 center-block">
                    <img v-bind:src="this.imageUrl" class="rounded-circle img-thumbnail profile-pic-lg" alt="profile-image">
                  </div>
                  <div class="profile-photo__edit text-center" v-if="this.role !== 'viewonly'">
                    <button type="button" @click="upload()" class="btn-primary">Upload School Logo</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="this.relationship === 'profileImage' && this.role === 'player' && this.cropper === true">
        <div style="padding: 45px;">
        <!-- <div class="card-box pp_card-box background-image" :style="`background: url(${this.imageUrl}); background-size: cover !important; background-position: center !important;`"> -->
          <div class="row">
            <div class="col-sm-12 col-md-12 col-xl-4 m-0-auto text-center">
              <div class=" pb-0">
                <div class="form-group mb-0">
                  <div class="thumb-xl member-thumb m-b-10 center-block">
                      <img v-bind:src="this.imageUrl" class="rounded-circle img-thumbnail profile-pic-lg" alt="profile-image">
                  </div>
                  <div class="profile-photo__edit text-center">
                    <form id="fileUploadForm" enctype="multipart/form-data">
                      <input style="border: none; display: none;" type="file" class="form-control" id="fileUpload" name="image" accept="image/*" />
                      <button type="button" class="btn-primary upload-profile-image">Upload Profile Image</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <!-- </div> -->
        </div>
      </div>
      <div v-else-if="this.relationship === 'profileImage' && this.role === 'player' && this.cropper !== true">
        <div class="card-box pp_card-box background-image" :style="`background: url(${this.imageUrl}); background-size: cover !important; background-position: center !important;`">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-xl-4 m-0-auto text-center">
              <div class="p-20 pb-0">
                <div class="form-group">
                  <div class="thumb-xl member-thumb m-b-10 center-block">
                      <img v-bind:src="this.imageUrl" class="rounded-circle img-thumbnail profile-pic-lg" alt="profile-image">
                  </div>
                  <div class="profile-photo__edit text-center">
                    <button type="button" @click="uploadProfilePhoto()" class="btn-primary">Upload Profile Image</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="this.role === 'player' || this.role === 'admin'">
        <div class="upload-video__wrap">
          <div class="upload-video__inner">
            <i style="font-size: 25px;" class="fa fa-upload"></i>
            <p>Upload <strong>{{ title }}</strong> here.</p>
            <div v-if="this.relationship === 'coachVideo'">
              <button type="button" class="btn btn-primary waves-effect waves-light btn-lg" @click="uploadUserFile()">
                Upload
              </button>
            </div>
            <div v-else>
              <button type="button" class="btn btn-primary waves-effect waves-light btn-lg" @click="upload()">Upload
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="text-center">
          <p>There is no <strong>{{ title }}</strong> at this time.</p>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="this.relationship === 'profileImage'">
        <div class="card-box pp_card-box background-image" :style="{ 'background-image': 'url(' + file.url + ')'}">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-xl-4 m-0-auto text-center">
              <div class="p-20 pb-0">
                <div class="form-group">
                  <div class="thumb-xl member-thumb m-b-10 center-block">
                    <img :src="file.url" class="rounded-circle img-thumbnail profile-pic-lg" alt="profile-image"  style="box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);">
                  </div>
                  <div class="profile-photo__edit text-center" v-if="this.role !== 'viewonly'">
                    <button type="button" @click="upload()" class="btn-primary" style="margin-bottom: 10px; border-radius: 3px box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)">Edit</button>
                    <div v-if="this.role === 'admin'">
                      <button type="button" @click="removeOrganizationFile()" class="btn-danger" style="border-radius: 3px   box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                      ">Remove</button>
                    </div>
                    <div v-else>
                      <button v-if="this.organization !== undefined && this.role === 'admin'" type="button" @click="removeOrganizationFile()"
                        class="btn-danger" style="border-radius: 3px box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                      ">Remove</button>
                      <button v-if="this.role === 'player' " type="button" @click="deleteUserFile()"
                        class="btn-danger" style="border-radius: 3px box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                      ">Remove</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="this.relationship === 'coachVideo'">
          <p class="pull-right delete-file"><a href="javascript:;" @click="deleteUserFile()">Delete <i class="fa fa-minus"></i></a></p>
          <p class="pull-right update-file"><a href="javascript:;" @click="uploadUserFile()">Update <i class="fa fa-plus"></i></a></p>
        </div>
        <div v-else>
          <p class="pull-right delete-file"><a href="javascript:;" @click="removeOrganizationFile()">Delete <i class="fa fa-minus"></i></a></p>
          <p class="pull-right update-file"><a href="javascript:;" @click="upload()">Update <i class="fa fa-plus"></i></a></p>
        </div>
        <div class="clearfix"></div>
        <video @click="removeOverlay()" class="video-js" data-setup='{"fluid": true, "controls": true, "autoplay": false, "preload": "auto"}'>
          <source :src="file.url" type="video/mp4">
        </video>
        <div v-if="this.relationship === 'recruitmentVideo' || this.relationship === 'hypeVideo'">
        <p class="pull-left video-title">University {{ title }} (:30)</p>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
    import axios from 'axios'

    export default {
        name: 'Uploader',
        props: {
          organization: Object,
          profile: Object,
          relationship: String,
          user: Object,
          title: String,
          role: String,
          cropper: Boolean,
          image: String,
          sport: String
        },
        data() {
          return {
            imageUrl: this.organization
              ? this.getProfileImageUrlOfOrganization()
              : this.getProfileImageUrlOfUser(),
            file: this.determineIfOrganizationOrProfile()
              ? this.getProfileImageFileOfUser()
              : this.getProfileImageFileOfOrganization(this.sport)
          }
        },

        mounted() {
          let title = this.title
          let base_url = window.location.origin
          $('video', this.$el).each(function() {
            let player = videojs(this);
            let src = player.src();
            let full_url = base_url + src;
            let el = $(this)
            let videoWidth = el.width()
            let videoContainer = el.closest('.video-js')



            // Add Custom classes for video overlay
            $('.vjs-text-track-display').addClass('org-vid__overlay');
            $('.vjs-big-play-button').addClass('org-vid__play-btn');

            // Share
            player.share({
              socials: ['fb', 'tw', 'reddit', 'gp', 'messenger', 'linkedin'],
              url: full_url,
              title: title,
              embedCode : `<iframe src=${full_url} width='560' height='315' frameborder='0' allowfullscreen></iframe>`
              // description: 'Description here?',
              // image: 'https://dummyimage.com/1200x630',
              // App Secret Code (required for facebook and messenger)
              // fbAppId: '12345',
              // redirectUri: window.location.href + '#close',

            });

            // Video brand logo
            // player.brand({
            //   image: "/build/images/logo.png",
            //   title: "Logo Title",
            //   destination: base_url,
            //   destinationTarget: "_top"
            // })

            // Fix inner share display if video is certain width
            if(videoWidth < 530){
              videoContainer.addClass('share-resize');
            } else {
              if(videoContainer.hasClass('share-resize')){
                videoContainer.removeClass('share-resize');
              }
            }

            // track width on window resize
            $(window).resize(function(){
                if(videoWidth < 530){
                  videoContainer.addClass('share-resize');

                } else {
                  if(videoContainer.hasClass('share-resize')){
                    videoContainer.removeClass('share-resize');
                  }
                }
            })

          })

          // Remove Overlay if Play button is clicked
          $('.vjs-big-play-button').on('click', function(){
            $(this).closest('.video-js').find('.vjs-text-track-display').remove();
          });

        },

        methods: {
          // Upload Organization Files
          upload() {
            let self = this

            Swal.fire({
              title: this.title + ' Upload',
              text: `Upload your ${this.title} here!`,
              html: `<form id="fileUploadForm" enctype="multipart/form-data"><input type="file" class="form-control" id="fileUpload" name="file" /><input type="hidden" name="sport" value="${this.sport}"/></form><p>Max file size: 100 MB</p>`,
              showCancelButton: true,
              confirmButtonText: 'Upload',
              cancelButtonText: 'Cancel',
              confirmButtonClass: 'btn btn-confirm mt-2',
              cancelButtonClass: 'btn btn-cancel ml-2 mt-2',
              reverseButtons: true
            }).then(result => {
              if (result.value) {
                const formData = new FormData($('#fileUploadForm')[0]);

                formData.append('_method', 'POST');
                toastr.info('One moment, your file will be available shortly.', 'File Uploading')
                axios.post(`/api/organizations/${this.organization.id}/upload/${this.relationship}`, formData).then((response) => {
                  this.file = response.data.data.file
                  Swal.fire({
                    title: 'Success!',
                    type: 'success',
                    text: `Your ${this.title} has uploaded successfully!`,
                    timer: 1700,
                    confirmButtonClass: 'btn btn-confirm mt-2'
                  })
                }).catch((error) => {
                  Swal.fire({
                    title: error.response.data.errors.file[0],
                    type: 'error',
                    text: `Please try uploading your ${this.title} again.`,
                    timer: 40000,
                    confirmButtonClass: 'btn btn-confirm mt-2'
                  })
                }).then(() => {
                  let self = this
                  $('video', this.$el).each(function() {
                    let vid = this
                    videojs(vid).src(self.file.url)
                  })
                  $('.vjs-text-track-display').addClass('org-vid__overlay');
                  $('.vjs-big-play-button').addClass('org-vid__play-btn');
                  this.$forceUpdate();
                })
              } else {
                Swal.fire({
                  title: 'Canceled!',
                  text: 'You have canceled your file upload',
                  timer: 1700,
                  confirmButtonClass: 'btn btn-confirm mt-2',
                })
              }
            })
          },

          // upload Profile Photo
          uploadProfilePhoto() {
            let self = this

            Swal.fire({
              title: this.title + ' Upload',
              text: `Upload your ${this.title} here!`,
              html: '<form id="fileUploadForm" enctype="multipart/form-data"><input type="file" class="form-control" id="fileUpload" name="image" /></form>',
              showCancelButton: true,
              confirmButtonText: 'Upload',
              cancelButtonText: 'Cancel',
              confirmButtonClass: 'btn btn-confirm mt-2',
              cancelButtonClass: 'btn btn-cancel ml-2 mt-2',
              reverseButtons: true
            }).then(result => {
              if (result.value) {
                const formData = new FormData($('#fileUploadForm')[0]);

                formData.append('_method', 'POST');
                // axios.post(`api/coach/${this.profile.id}/upload/${this.relationship}`, formData).then((response) => {
                toastr.info('One moment, your file will be available shortly.', 'File Uploading')
                axios.post(`api/user/upload-profile-image`, formData).then((response) => {
                  this.file = response.data.data.file
                  Swal.fire({
                    title: 'Success!',
                    type: 'success',
                    text: `Your ${this.title} has uploaded successfully!`,
                    timer: 1700,
                    confirmButtonClass: 'btn btn-confirm mt-2'
                  })
                }).catch((error) => {
                  console.log(error);
                  console.log(error.response);
                  Swal.fire({
                    title: error.response.data.data.response[0],
                    type: 'error',
                    text: `Please try uploading your ${this.title} again.`,
                    timer: 4000
                  })
                }).then(() => {
                  let self = this
                })
              } else {
                Swal.fire({
                  title: 'Canceled!',
                  text: 'You have canceled your file upload',
                  timer: 1700
                })
              }
            })
          },

          // Upload User Files
          uploadUserFile() {
            let self = this

            Swal.fire({
              title: this.title + ' Upload',
              text: `Upload your ${this.title} here!`,
              html: '<form id="fileUploadForm" class="coach-video" enctype="multipart/form-data"><input type="file" class="form-control" id="fileUpload" name="file" /></form><p>Max file size: 100 MB</p>',
              showCancelButton: true,
              confirmButtonText: 'Upload',
              cancelButtonText: 'Cancel',
              confirmButtonClass: 'btn btn-confirm mt-2',
              cancelButtonClass: 'btn btn-cancel ml-2 mt-2',
              reverseButtons: true

            }).then(result => {
              if (result.value) {
                const formData = new FormData($('#fileUploadForm.coach-video')[0]);

                formData.append('_method', 'POST');
                toastr.info('One moment, your file will be available shortly.', 'File Uploading')
                axios.post(`/api/coach/${this.profile.id}/upload/${this.relationship}`, formData).then((response) => {
                  this.file = response.data.data.file
                  Swal.fire({
                    title: 'Success!',
                    type: 'success',
                    text: `Your ${this.title} has uploaded successfully!`,
                    timer: 1700,
                    confirmButtonClass: 'btn btn-confirm mt-2'
                  })
                }).catch((error) => {
                  Swal.fire({
                    title: error.response.data.errors.file[0],
                    type: 'error',
                    text: `Please try uploading your ${this.title} again.`,
                    timer: 4000
                  })
                }).then(() => {
                  let self = this

                  $('video', this.$el).each(function() {
                    let vid = this

                    videojs(vid).src(self.file.url)
                  })

                })
              } else {
                Swal.fire({
                  title: 'Canceled!',
                  text: 'You have canceled your file upload',
                  timer: 1700
                })
              }
            })
          },

          // Remove Organization Files
          removeOrganizationFile() {
            let self = this

            Swal.fire({
              title: `Are you sure you want to delete your ${this.title}?`,
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Yes, delete.',
              confirmButtonClass: 'btn btn-confirm mt-2',
              cancelButtonClass: 'btn btn-cancel ml-2 mt-2',
              reverseButtons: true
            }).then(result => {
              if(result.value) {
                  axios.delete(`/api/organizations/${this.organization.id}/delete/${this.relationship}/${this.file.id}`).then((response) => {
                    this.video = false
                    if(this.relationship != "profileImage"){
                      videojs($('video', this.$el)[0]).dispose();
                    } else {
                      $('.profile-pic-lg').attr('src', '/build/images/default-profile.png');
                      $('.pp_card-box').css({'background-image': "url('/build/images/default-profile.png')"});
                    }

                    Swal.fire({
                      title: 'Success!',
                      type: 'success',
                      text: `You have deleted your ${this.title}.`,
                      timer: 1700,
                      confirmButtonClass: 'btn btn-confirm mt-2'
                    })
                  }).then(() => {
                    let self = this
                    let el = this.$el

                    if(this.relationship != "profileImage"){
                      $(el).append(`<div class="deleted-file__wrap"><span class="deleted-file__txt">Your ${this.title} has been deleted.</span></div>`);
                      $(el).find('.update-file, .delete-file, .video-title').remove();
                    }
                    this.file = undefined
                    this.$forceUpdate();
                  })
              } else {
                Swal.fire({
                  title: 'Canceled!',
                  text: `You have canceled deleting your ${this.title}.`,
                  timer: 1700,
                  confirmButtonClass: 'btn btn-confirm mt-2'
                })
              }
            })
          },

          // Delete User Files
          deleteUserFile() {
            let self = this

            Swal.fire({
              title: `Are you sure you want to delete your ${this.title}?`,
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Yes, delete.',
              confirmButtonClass: 'btn btn-confirm mt-2',
              cancelButtonClass: 'btn btn-cancel ml-2 mt-2',
              reverseButtons: true
            }).then(result => {
              if(result.value) {

                  axios.delete(`/api/coach/${this.profile.id}/delete/${this.relationship}`).then((response) => {
                    this.video = false
                    if(this.relationship != "profileImage"){
                      videojs($('video', this.$el)[0]).dispose();
                    } else {
                      $('.profile-pic-lg').attr('src', '/build/images/default-profile.png');
                      $('.pp_card-box').css({'background-image': "url('/build/images/default-profile.png')"});
                    }

                    Swal.fire({
                      title: 'Success!',
                      type: 'success',
                      text: `You have deleted your ${this.title}.`,
                      timer: 1700,
                      confirmButtonClass: 'btn btn-confirm mt-2'
                    })
                  }).then(() => {
                    let self = this
                    let el = this.$el

                    if(this.relationship != "profileImage"){
                      $(el).append(`<div class="deleted-file__wrap"><span class="deleted-file__txt">Your ${this.title} has been deleted.</span></div>`);
                      $(el).find('.update-file, .delete-file, .video-title').remove();
                    }
                    this.file = undefined
                    this.$forceUpdate();
                  })
              } else {
                Swal.fire({
                  title: 'Canceled!',
                  text: `You have canceled deleting your ${this.title}.`,
                  timer: 1700
                })
              }
            })
          },

          // Remove Overlays
          removeOverlay() {
            $(this.$el).each(function() {
              $(this).find('.video-js').find('.vjs-text-track-display').remove();
            })
          },

          // Initializer Helper functions
          determineIfProfileImageExistsForUser() {
            if(this.determineIfProfileExists()) {
              return this.profile[this.title.replace(' ', '_').toLowerCase()]
                && this.profile[this.title.replace(' ', '_').toLowerCase()] !== null
                && this.profile[this.title.replace(' ', '_').toLowerCase()] !== undefined
                && this.profile[this.title.replace(' ', '_').toLowerCase()].url
            }
            return false
          },

          determineIfOrganizationOrProfile() {
            return ( this.relationship === "coachVideo" || this.relationship === 'profileImage' )
              && this.determineIfProfileExists()
          },

          determineIfProfileExists()
          {
            return this.profile && this.profile != 'undefined' && this.profile != undefined
          },

          getProfileImageUrlOfUser() {
            return this.determineIfProfileImageExistsForUser()
              ? this.profile[this.title.replace(' ', '_').toLowerCase()].url
              : this.image
          },

          getProfileImageUrlOfOrganization() {
            return this.organization.profile_image.length > 0
              ? this.organization.profile_image[0].url
              : this.getDefaultImagePerSport()
          },

          getProfileImageFileOfUser() {
            return this.profile[this.title.replace(' ', '_').toLowerCase()]
              ? this.profile[this.title.replace(' ', '_').toLowerCase()][0]
              : false
          },

          getProfileImageFileOfOrganization(sport) {
            let fileArray = this.organization[this.title.replace(' ', '_').toLowerCase()]
            for (let i=0; i < fileArray.length; i++) {
              if ((fileArray[i].sport == null ? 'football' : fileArray[i].sport)  === sport) {
                return fileArray[i]
              }
            }

          },

          getDefaultImagePerSport() {
            let sport = this.sport
            switch (true) {
              case sport === 'baseball':
                return '/build/images/default-baseball-profile.png'
              case sport === 'softball':
                return '/build/images/default-softball-profile.png'
              default:
                return '/build/images/default-profile.png'
            }
          }

        }
    }


</script>
