<template>
  <form @submit.prevent="save()" data-parsley-validate id="measurementForm">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Name</label>
          <input type="text" name="name" class="form-control" v-model="name" required>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Input Type</label>
          <select name="type_id" class="form-control select2" v-select2 v-if="!edit">
            <option v-if="measurement" :value="this.measurement.type.data.unit" selected></option>
            <option v-for="type in this.input_types" :key="type.id" :value="type.id">{{type.unit}}</option>
          </select>
          <input type="text" name="name" class="form-control" v-if="this.measurement != false && edit" v-model="this.measurement.type.unit" disabled>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label>Description</label>
          <textarea name="description" class="summernote"></textarea>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <input value="1" id="player_input" name="player_input" type="checkbox" v-model="player_input">
          <label for="player_input">Players can submit measurement</label>
        </div>
        <div class="form-group">
          <input value="1" id="require_verification" name="require_verification" type="checkbox" v-model="require_verification">
          <label for="require_verification">Measurement must be verified by coach</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <input value="1" id="has_video" name="has_video" type="checkbox" v-model="has_video" v-if="!edit">
          <label for="has_video"><i :class="'fa ' + (this.has_video == 1 ? 'fa-check' : 'fa-close')" v-if="edit"></i> Require video</label>
        </div>
        <div class="form-group">
          <input value="1" id="has_photo" name="has_photo" type="checkbox" v-model="has_photo" v-if="!edit">
          <label for="has_photo"><i :class="'fa ' + (this.has_photo == 1 ? 'fa-check' : 'fa-close')" v-if="edit"></i> Require photo</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <button type="submit" class="btn btn-primary add-player-modal-btn" :disabled="loading" v-if="!edit">{{ loading ? 'Saving...' : 'Save' }}</button>
        <button type="submit" class="btn btn-primary add-player-modal-btn" :disabled="loading" v-else>{{ loading ? 'Updating...' : 'Update' }}</button>
      </div>
    </div>
  </form>
</template>

<script>
    import axios from 'axios'

    export default {

        name: 'MeasurementForm',
        props: {
          edit: {
            type: Boolean,
            default: false
          },
          measurement_id: Number
        },

        data: function() {
          return {
            loading: false,
            input_types: [],
            name: '',
            player_input: 0,
            require_verification: 0,
            has_video: 0,
            has_photo: 0,
            measurement: false
          }
        },

        mounted() {
          $('[data-parsley-validate]').parsley()

          $('.summernote').summernote({
            toolbar: false
          })

          if (! this.edit) {
            this.getInputTypes()
          } else {
            this.getMeasurement()
          }
        },

        methods: {
          save() {
            this.loading = true
            if (!this.edit) {
             this.saveMeasurement()
            } else {
              this.updateMeasurement()
            }
          },

          getMeasurement() {
            axios.get(`/api/measurements/${this.measurement_id}?include=type`).then((response) => {
              this.measurement = response.data.data
            }).then(() => {
              this.name = this.measurement.name
              this.has_photo = this.measurement.has_photo
              this.has_video = this.measurement.has_video
              this.player_input = this.measurement.player_input
              this.require_verification = this.measurement.require_verification
            }).then(() => {
              $('.summernote').summernote('code', this.measurement.description)
            }).catch((error) => {
              this.loading = false
            })
          },

          getInputTypes() {
            axios.get('/api/measurement/units').then((response) => {
              this.input_types = response.data.data
            }).then(() => {
              $('.select2').select2();
              this.loading = false
            }).then(() => {
              $('.select2').val('1').trigger('change');
            }).catch((error) => {
              console.log(error)
            })
          },

          saveMeasurement() {
            axios.post(`/api/measurements`, {
              name: this.name,
              type_id: $('[name="type_id"]').select2().val(),
              description: $('.summernote').summernote('code').replace(/<\/?[^>]+>/gi,""),
              player_input: this.player_input,
              require_verification: this.require_verification,
              has_video: this.has_video,
              has_photo: this.has_photo 
            }).then((response) => {
              $('#dynamic-component').modal('hide')
            }).then((response) => {
              this.updateTables()
              this.notifyUserOfSubmission()
            }).catch((error) => {
              console.log(error)
              this.loading = false
            })
          },

          updateMeasurement() {
            axios.put(`/api/measurements/${this.measurement_id}`, {
              name: this.name,
              player_input: this.player_input,
              require_verification: this.require_verification,
              description: $('.summernote').summernote('code').replace(/<\/?[^>]+>/gi,"")
            }).then((response) => {
              $('#dynamic-component').modal('hide')
            }).then((response) => {
              this.updateTables()
              this.notifyUserOfSubmission()
            }).catch((error) => {
              console.log(error)
              this.loading = false
            })
          },

          updateTables() {
            $('#measurements-table').DataTable().ajax.reload()
          },

          notifyUserOfSubmission() {
            let message = `A measurement has been ${this.edit ? 'updated' : 'created'} for your organization`;
            toastr.success(message, `Measurement ${this.edit ? 'Updated' : 'Created'}`);
          }
        }
    }
</script>
