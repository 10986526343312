<template>
  <div>
    <div class="form-inline left">
      <div class="clear-filter-wrapper">
        <button id="clearFilters" class="btn btn-primary waves-effect waves-light">Clear Filters</button>
      </div>
      <select id="sport" class="form-control form-control-sm" v-model="filters[1].value"/>
      <input type="text" id="organization-search" class="form-control m-l-5" placeholder="Search..." @keyup="handleFilterChange">
    </div>
    <data-tables-server id="organizations-table"
      :data="organizations"
      :total="count"
      :loading="loading"
      :pagination-props="{
        pageSizes: [10, 25, 50, 100],
        background: true,
        layout: 'prev, pager, next, sizes, ->, total'
      }"
      :filters="filters"
      class="table"
      @query-change="loadData"
    >
      <el-table-column label="Photo" align="center" width="150">
        <template slot-scope="{ row: { active, id, profile_image, sports } }">
          <a :href="`/organization/${id}`" class="img-container">
            <span class="badge" title="Active within the last 7 days" v-if="active"/>
            <img
              :src="getOrganizationImage(profile_image, sports)"
              alt="profile image" width="80" height="80"
              class="rounded-circle dynamic"
            >
          </a>
        </template>
      </el-table-column>
      <el-table-column label="Organization" align="center" width="150" sortable sort-by="name">
        <template slot-scope="{ row: { id, name } }">
          <a :href="`/organization/${id}`">
            <h4 class="player-name">{{ name }}</h4>
          </a>
        </template>
      </el-table-column>
      <el-table-column label="Email" align="center" width="210" sortable sort-by="email">
        <template slot-scope="{ row: { email } }">{{ email }}</template>
      </el-table-column>
      <el-table-column label="Phone" align="center" sortable sort-by="phone">
        <template slot-scope="{ row: { phone } }">{{ phone }}</template>
      </el-table-column>
      <el-table-column label="Address" align="center" sortable sort-by="address">
        <template slot-scope="{ row: { address } }">{{ address }}</template>
      </el-table-column>
      <el-table-column label="City" align="center" sortable sort-by="city">
        <template slot-scope="{ row: { city } }">{{ city }}</template>
      </el-table-column>
      <el-table-column label="State" align="center" width="80" sortable sort-by="state">
        <template slot-scope="{ row: { state } }">{{ state }}</template>
      </el-table-column>
      <el-table-column label="Zip" align="center" width="80" sortable sort-by="zip">
        <template slot-scope="{ row: { zip } }">{{ zip }}</template>
      </el-table-column>
      <el-table-column label="Legitimate" align="center" width="100" sortable sort-by="legitimate">
        <template slot-scope="{ row: { legitimate } }">{{ legitimate ? "Yes" : "No" }}</template>
      </el-table-column>
      <el-table-column label="" :class-name="`actions-col ${count === 1 ? 'single-entry-fix' : ''}`" width="200" align="center">
        <template slot-scope="{ row }">
          <button aria-expanded="false" class="btn dropdown-toggle waves-effect waves-light" data-toggle="dropdown" type="button">
            ACTIONS
            <span data-html2canvas-ignore>
            <span class="caret"></span>
          </span>
          </button>
          <div class="dropdown-menu">
            <a :href="`/organization/${row.id}`" class="dropdown-item">View</a>
            <a :href="`/organization/${row.id}/edit`" class="dropdown-item">Edit</a>
            <span class="dropdown-item" @click="() => confirm(row)">Delete</span>
          </div>
        </template>
      </el-table-column>
    </data-tables-server>
  </div>
</template>

<script>
let timeout
import DataTablesServer from '@components/ui/DataTablesServer'
export default {
  name: 'OrganizationsTable',
  components: { DataTablesServer },
  props: {},
  data() {
    return {
      url: '/api/organizations',
      previousQuery: null,
      apiData: null,
      loading: false,
      filters: [
        { prop: 'search', value: "" },
        { prop: 'sport', value: "" }
      ]
    }
  },
  computed: {
    organizations: v => _.get(v, 'apiData.data', []),
    count: v => _.get(v, 'apiData.meta.pagination.total', 0)
  },
  methods: {
    async loadData(query) {
      this.previousQuery = query
      this.loading = true

      const { page, pageSize: length, filters } = query
      const { value: search } = filters[0]
      const { value: sport } = filters[1]
      const data = {page, length, search, sport}

      const url = `${this.url}?${new URLSearchParams(data).toString()}`
      const response = await axios.get(url)
      const difRes = await axios.get(`${this.url}/defaults`)
      this.apiData = response.data
      this.loading = false
    },
    async confirm({ id }) {
      const deleteFn = () => this.delete(id)
      return Swal.fire(this.getSwalProps()).then(async ({ value }) => {
        if (value) await deleteFn()
      })
    },
    async delete(id) {
      await axios.delete(`/api/organizations/${id}`).then(({ data }) => this.deleteCallback(data))
      await this.loadData(this.previousQuery)
    },
    deleteCallback({ data: { response } }) {
      return response === 'success'
        ? Swal.fire({ title: 'Removed!', text: 'The organization has been removed.', type: 'success', timer: 1700 })
        : Swal.fire({ title: 'Error!', text: 'There was an error removing the organization.' })
    },
    getSwalProps() {
      return {
        title: "Remove organization from system?",
        text: "This will remove the organization and any associated attributes from the system.",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, remove organization!",
        cancelButtonText: "No, cancel!",
        confirmButtonClass: "btn btn-confirm mt-2",
        cancelButtonClass: "btn btn-cancel ml-2 mt-2",
        reverseButtons: true,
      }
    },
    handleFilterChange(e) {
      const value = $(e.target).val()
      const setFilter = () => this.filters[0].value = value
      clearTimeout(timeout)
      timeout = setTimeout(setFilter, 300)
    },
    getOrganizationImage(profile_image, sports) {
      if (profile_image.length > 0) {
         return profile_image[0].url
      } else if (sports.length > 0) {
        return this.getDefaultImagePerSport(sports[0].name)
      }
      return null;

    },
    getDefaultImagePerSport(name) {
      switch (name) {
        case 'baseball':
          return '/build/images/default-baseball-profile.png'
        case 'softball':
          return '/build/images/default-softball-profile.png'
        default:
          return '/build/images/default-profile.png'
      }
    }
  }
}
</script>

<style scoped>
  .actions-col {
    font-family: KairosSans, serif;
  }
  .actions-col button {
    font-family: KairosSans, serif;
    border-color: rgb(225,225,225);
    border-width: 1px;
    border-radius: 0;
    padding: 7.5px 30px;
    color: black !important;
  }
  .actions-col .dropdown-item {
    cursor: pointer;
  }
  .dropdown-menu {
    z-index: 2;
  }
  .btn-font {
    font-family: KairosSans, serif;
  }
  .dynamic {
    width: 50%;
    height: 50%;
  }

</style>
