<template>
  <div  v-bind:id="'comment-wrapper' + comment.id" class="comment" :class="filteredComment ? 'highlight' : ''" v-if="comment.comment !== '[deleted]' || comment.total_replies > 0" >
    <div>
      <img v-bind:src="comment.comment != '[deleted]' ? comment.author_pic : '/build/images/default-profile.png'" class="comment-photo" />
      <strong>{{ comment.comment !== '[deleted]' ? comment.author_name : '[deleted]' }}</strong>
      <div class="btn-group" v-if="comment.comment !== '[deleted]'">
        <a href="javascript:void(0)" class="dropdown-toggle waves-effect waves-light" data-toggle="dropdown" aria-expanded="false"><span class="caret"></span></a>
        <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 38px, 0px); top: 0px; left: 0px; will-change: transform;">
          <a href="javascript:void(0)" class="dropdown-item" v-on:click="follow" v-if="authuser != comment.author && comment.following === false">Turn on Alerts</a>
          <a href="javascript:void(0)" class="dropdown-item" v-on:click="follow" v-if="authuser != comment.author && comment.following === true">Turn off Alerts</a>
          <a v-if="authuser == comment.author" href="javascript:void(0)" class="dropdown-item" v-on:click="edit">Edit</a>
          <a v-if="authuser == comment.author" href="javascript:void(0)" class="dropdown-item" v-on:click="remove">Delete</a>
        </div>
      </div>
      <div v-bind:title="comment.timestamp" style="margin-top: -15px; font-size: 12px"> {{ comment.created }}</div>
    </div>
    <div v-if="comment.tags.length > 0 || comment.mentions.length > 0" style="margin-bottom: .3rem;">
      <div class="small" v-if="comment.tags.length > 0">Tags: <span class="tags">{{comment.tags.map(tag => tag.name).join(', ')}}</span></div>
      <div class="small" v-if="comment.mentions.length > 0">Mentions: <span class="tags">{{ comment.mentions.map(mention => mention.first_name + ' ' + mention.last_name).join(', ') }}</span></div>
    </div>
    <div class="body" v-bind:id="'comment' + comment.id">{{ comment.comment }}</div>
    <div class="body" v-bind:id="'edit' + comment.id" style="display:none;">
      <textarea placeholder="Type your note here..."  rows="5" maxlength="25000" class="form-control">{{ comment.comment }}</textarea>
      <button class="btn-sm btn-primary waves-effect waves-light" v-on:click="update" style="z-index: 99;">Update</button>
      <button class="btn-sm btn-secondary waves-effect waves-light" v-on:click="edit" style="z-index: 99;">Cancel</button>
    </div>
    <div class="actions" v-bind:id="'actions' + comment.id" v-if="comment.comment != '[deleted]' || comment.total_replies > 0">
      <a href="javascript:void(0)" v-on:click="likeComment" :class="comment.liked === 1 ? 'liked':''" v-model="like"> {{comment.liked === 1 ? 'Liked':'Like &nbsp;'}}</a>
      <a href="javascript:void(0)" v-on:click="commentReplyClick"> Comment</a>
      <a v-if="comment.total_replies > 0" href="javascript:void(0)" v-on:click="handleReplies">{{this.showReplies ? 'Hide' : 'View'}} Replies</a>
      <span v-if="comment.total_likes > 0"> {{ comment.total_likes == 1 ? '1 like' : comment.total_likes + ' likes' }} </span>
    </div>
    <div class="new-comment" v-if="visible">
      <textarea type="text" maxlength="25000" rows="5" v-model="reply" name="new-note" placeholder="Type your note here..." class="form-control"></textarea>
      <button class="btn btn-primary waves-effect waves-light m-t-10" v-on:click="commentReply">Add</button>
    </div>
      <player-video-comment v-if="showReplies" v-for="reply in replies" :key="reply.id" :getComments="getComments" :comment="reply" :authuser="authuser"/>
  </div>
</template>

<style>
  .comment {
    margin-left: 55px;
    margin-bottom: 10px;
  }

  .comment-photo { 
    position: relative !important;
    top: 15px;
  }

  .swal2-container{
    z-index: 100000!important;
  }
</style>

<script>

  Vue.directive('focus', {
      inserted: function (el) {
          el.focus();
      },
      update: function (el) {
          Vue.nextTick(function() {
                el.focus();
          })
      }
  })

  export default {
    name: 'PlayerVideoComment',
    props: {
      comment: Object,
      getComments: Function,
      authuser: String
    },
    data() {
      return {
        replies: [],
        like: '',
        visible: false,
        filteredComment: false,
        selectedtags: [],
        selectedcoaches: [],
        reply: '',
        deleted: false,
        showReplies: false
      }
    },
    methods: {
      likeComment: function() {
        axios.post('/api/like', {
          likeable_type: 'Comment', 
          likeable_id: this.comment.id
        }).then((response) => {
          if ( response.data.data.attached > 0 ) {
            this.comment.total_likes = this.comment.total_likes + 1
            this.comment.liked = 1
          }
          if ( response.data.data.detached > 0 ) {
            this.comment.total_likes = this.comment.total_likes - 1
            this.comment.liked = 0
          }
        })
      },
      handleReplies: function() {
        if(!this.showReplies) {
          if(this.replies.length == 0) {
            this.getReplies()
          }
        }
        this.showReplies = this.showReplies ? false : true
      },
      getReplies: function() {
        axios.get(`/api/comments/${this.comment.id}/replies`,).then((response) => {
          this.replies = response.data.data.concat(this.replies)
        }).catch((error) => {
          console.log(error)
        })
      },
      follow: function() {
        axios.post('/api/alert-subscription', {id: this.comment.id, type: 'App\\Comment'}).then((response) => {
            this.comment.following = response.data
            if (response.data === true) {
              toastr.success('You will be alerted of new replies to this comment.', '');
            } else {
              toastr.success('Alerts for this comment are now off.', '');
            }
        }).catch((error) => {
            console.log(error.response.data.message)
            toastr.error('Something went wrong: Unable to follow', '');
        })
      },
      subscribe: function() {
        axios.post('/api/subscribe', {}).then((response) => {

        }).catch((error) => {
            console.log(error.response.data.message)
            toastr.error('Something went wrong: Unable to subscribe', '');
        })
      },
      edit: function() {
        $('#edit' + this.comment.id).toggle();
        $('#comment' + this.comment.id).toggle();
        $('#edit' + this.comment.id + '>textarea').focus();
      },
      update: function() {
        let edited_comment = $('#edit' + this.comment.id + '>textarea').val();

        axios.patch('/api/comments/' + this.comment.id, {id: this.comment.id, comment: edited_comment }).then((response) => {
          // comment updated, show it on page
          $('#comment' + this.comment.id).html(edited_comment);
          $('#edit' + this.comment.id).toggle();
          $('#comment' + this.comment.id).toggle();
        }).catch((error) => {
            // comment not updated
            console.log(error.response.data.message)
            toastr.error('Error: ' + error.response.data.message, '');
        })
      },
      remove: function() {
        Swal.fire({
          title: 'Delete comment?',
          text: "You cannot undo this action",
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete!',
          cancelButtonText: 'No, cancel!',
          confirmButtonClass: 'btn btn-confirm mt-2',
          cancelButtonClass: 'btn btn-cancel ml-2 mt-2',
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            axios.delete('/api/comments/' + this.comment.id).then((response) => {
              // replace content with [deleted];
              $('#comment' + this.comment.id).html('[deleted]');
              $('#actions' + this.comment.id).toggle();
              toastr.warning('Comment has been deleted', 'Deleted');
              this.deleted = true
            }).catch((error) => {
                console.log(error)
                toastr.error('Error: ' + error.response.data.message, '');
            })
          } else if (result.dismiss === Swal.DismissReason.cancel) {}
        })
      },
      commentReply: function() {
        const comment = this.reply
        const parentId = this.comment.id;
        const selectedtags = this.selectedtags
        const selectedcoaches = this.selectedcoaches
        const parentType = 'Comment';
        this.reply = '';
        axios.get(`/api/comments/${this.comment.id}/replies`,).then((response) => {
          if(this.replies.length == 0) {
            this.replies = response.data.data.concat(this.replies)
          }
        }).then(() => {
          axios.post('/api/comments', {commentable_type: parentType, commentable_id: parentId, comment: comment, tags: selectedtags, mentions: selectedcoaches }).then((response) => {
            this.replies = [response.data.data].concat(this.replies)
            this.visible = false
            this.showReplies = true
            this.comment.total_replies += 1
          }).catch((error) => {
              console.log(error)
          })
        }).catch((error) => {
          console.log(error)
        })
      },
      commentReplyClick: function() {
        this.visible = !this.visible
      }
    }
  }
</script>
