require('./theme')
require('./helpers')

window.SigningDay = {
    init: () => {
        $(function() {
            $('.select2').select2()
            $.applyDataMask()
            $('form').parsley()
            $('.summernote').summernote({
                height: 350,
                minHeight: null,
                maxHeight: null,
                focus: false
            });
            $(".summernote").on("summernote.change", function (e) {
                if($(this).summernote('code').replace(/<\/?[^>]+>/gi,"").length > 0) {
                    $(this).val($(this).summernote('code'));
                }
                else {
                    $(this).val('');
                }
            });
            $('.selection-datatable').DataTable({
                select: {
                    style: 'multi'
                }
            });
            let $masonry = $('.masonry').masonry({
                itemSelector: '.masonry-item'
            });
            $('[data-toggle="tooltip"]').tooltip()

            if ($('.theme-elements')[0]) {
                Theme.init()
            }

            $('[data-global-search]').on('submit', function(e) {
              e.preventDefault()
              Cookies.set('defaultSearchText', $('input', this).val(), { expires: Infinity })
              window.location.href = '/recruit'
            })
            $('[data-global-search] a').on('click', function(e) {
              e.preventDefault()
              $(this).parent().trigger('submit')
            })

            Helpers.init()
        })

        toastr.options = {
          "progressBar": true,
        }
    }
}
