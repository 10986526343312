<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.7955 13.0884C13.8443 13.0396 13.8443 12.9604 13.7955 12.9116L10.9653 10.0815C11.7947 9.05776 12.2917 7.75343 12.2917 6.33333C12.2917 3.04264 9.62403 0.375 6.33333 0.375C3.04264 0.375 0.375 3.04264 0.375 6.33333C0.375 9.62403 3.04264 12.2917 6.33333 12.2917C7.75343 12.2917 9.05776 11.7947 10.0815 10.9653L12.9116 13.7955C12.9604 13.8443 13.0396 13.8443 13.0884 13.7955L13.7955 13.0884ZM1.625 6.33333C1.625 3.73299 3.73299 1.625 6.33333 1.625C8.93367 1.625 11.0417 3.73299 11.0417 6.33333C11.0417 8.93367 8.93367 11.0417 6.33333 11.0417C3.73299 11.0417 1.625 8.93367 1.625 6.33333Z" fill="black" stroke="black" stroke-width="0.25" stroke-linecap="square" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: "MagnifyingGlassIcon"
  }
</script>
