<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="left side-menu" :class="this.routeName === 'film-room' ? 'stuck' : ''">
      <div class="sidebar-inner">
<!--        TODO: move header info into separate component -->
        <!--- Divider -->
        <div id="sidebar-menu">
          <ul v-if="this.routeName === 'film-room'" class="inline-list">
            <li class="dropdown">
              <a class="nav-link waves-effect waves-light nav-user" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                  <img v-bind:src="profileImg" alt="user" class="rounded-circle">
              </a>
              <div class="dropdown-menu dropdown-menu-right profile-dropdown " aria-labelledby="Preview">
                <div class="dropdown-item noti-title">
                  <h5 class="text-overflow"><small>Welcome {{ getUserFName }}</small> </h5>
                </div>

                <a v-bind:href="accountUrl" class="dropdown-item notify-item">
                  <i class="mdi mdi-settings"></i> <span>Account</span>
                </a>

                <a v-bind:href="logOutUrl" onclick="event.preventDefault(); document.getElementById('logout-form').submit();" class="dropdown-item notify-item">
                    <i class="mdi mdi-logout"></i> <span>Logout</span>
                </a>
                <form id="logout-form" v-bind:action="logOutUrl" method="POST" style="display: none;">
                  <input type="hidden" name="_token" v-bind:value="csrf" />
                </form>
              </div>
            </li>
            <li v-if="!isPlayer">
              <a class="nav-link waves-light waves-effect" href="#" id="btn-fullscreen">
                <i class="mdi mdi-crop-free"></i>
              </a>
            </li>
            <li v-if="(!isPlayer && isOrganization)">
              <a class="nav-link right-bar-toggle waves-light waves-effect" href="#">
                <i class="ion-arrow-graph-up-right"></i>
              </a>
            </li>
            <span v-if="!isPlayer" id="alerts" class="film-room-notifications">
              <alerts />
            </span>
          </ul>
          <NonCollegeMenu :return-active-class="returnActiveClass" :attributes="this.$attrs"></NonCollegeMenu>
          <college-menu :return-active-class="returnActiveClass" :attributes="this.$attrs"></college-menu>
        <div class="clearfix"></div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
  <!-- Left Sidebar End -->

</template>

<script>

  import CollegeMenu from "./side-menu/CollegeMenu";
  import NonCollegeMenu from "./side-menu/NonCollegeMenu";
  export default {
    name: 'LeftMenu',
    components: {NonCollegeMenu, CollegeMenu},
    created: async function () {
      await this.$store.dispatch('fetchUnreadTotal')
    },
    computed: {
      user: (store) => store.$store.state.user,
      profileImg: (attrs) => attrs.$attrs.profileimg,
      isPlayer: (store) => store.$store.state.user.role === "player",
      isOrganization: (attrs) => attrs.$attrs.isorganization,
      routeName: (attrs) => attrs.$attrs.routename,
      accountUrl: (attrs) => attrs.$attrs.accounturl,
      logOutUrl: (attrs) => attrs.$attrs.logouturl,
      csrf: (attrs) => attrs.$attrs.csrf,
      getUserFName: function () {
        return this.user ? this.user.first_name : '';
      }
    },
    methods: {
      returnActiveClass(route) {
        return 'waves-effect waves-primary ' +  (this.routeName.includes(route) ? 'active-menu' : '')
      },
    }
  }
</script>
<style>
  .active-menu {
    background: #EDECEC !important;
    border-left: 2px solid #000 !important;
  }
</style>
