<template>
  <PlayerStatTilesPanel>
    <div v-for="sluggerScienceStat in sluggerScienceStats">
      <PlayerStatTile
        :stat-name="sluggerScienceStat.name"
        :stat-value="sluggerScienceStat.value"
        :thumbnail-image="sluggerScienceStat.thumbnailImage"
        :video="sluggerScienceStat.video"
      />
    </div>
  </PlayerStatTilesPanel>
</template>

<script>
import PlayerStatTile from '@components/PlayerProfile/PublicProfile/components/PlayerStatTile';
import PlayerStatTilesPanel from '@components/PlayerProfile/PublicProfile/components/PlayerStatTilesPanel';

export default {
  name: "SluggerSciencePanel",
  components: { PlayerStatTilesPanel, PlayerStatTile },
  inject: ['player'],
  computed: {
    sluggerScienceStats() {
      return Object.values(this.player?.slugger_science).map((stat) => {
        return {
          name: stat.name,
          value: stat.value ?? 'N/A',
          thumbnailImage: stat.attribute?.file_upload?.thumbnail_path,
          video: stat.attribute?.file_upload?.path
        }
      })
    }
  },
}
</script>
