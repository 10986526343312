<template>
  <div class="btn back-btn" @click="$emit('back')">Back</div>
</template>

<script>
export default {
  name: "BackButton"
}
</script>

<style scoped>
.back-btn {
  background: black;
  color: white;
  font-family: KairosSans,sans-serif;
  text-transform:uppercase;
}
</style>
