<template>
   <div class="col-sm-12 col-md-4 card-box-custom-container defensive-recruits">
        <div v-on:click="go(stat.url)" :class="'clickable card-box card-box-' + stat.background">
            <div class="row">
                <div class="col-4 col-md-4 icon-data text-center">
                    <svg viewBox="0 0 36 36" class="circular-chart">
                      <path class="circle" style="z-index:0;"
                        stroke-dasharray="100, 100"
                        d="M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831"/>
                    </svg>
                    <svg viewBox="0 0 36 36" class="circular-chart">
                        <path :class="'circle circle-' + stat.circle" style="z-index:100;"
                            :stroke-dasharray="stat.percentage + ', 100'"
                            d="M18 2.0845
                              a 15.9155 15.9155 0 0 1 0 31.831
                              a 15.9155 15.9155 0 0 1 0 -31.831"/>
                    </svg>
                     <div class="circle-percentage circle-percentage-yellow">
                        <h4 class="mb-0">{{stat.percentage}}%</h4>
                    </div>
                </div>
                <div class="col-8 col-md-8 text-center pr-0">
                    <div class="mtb-9">
                        <h3 class="data-total">{{stat.completed}}</h3>
                        <p>{{stat.message}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'StatCard',
        props: ['stat', 'organization'],

        methods: {
          go(url) {
            window.location.href = url
          }
        }
    }
</script>
