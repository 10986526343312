<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.33337 6.66671C5.33337 5.19395 6.52728 4.00004 8.00004 4.00004C9.4728 4.00004 10.6667 5.19395 10.6667 6.66671C10.6667 7.90927 9.81686 8.95333 8.66671 9.24936V9.67504H7.33337V8.00004H8.00004C8.73642 8.00004 9.33337 7.40309 9.33337 6.66671C9.33337 5.93033 8.73642 5.33337 8.00004 5.33337C7.26366 5.33337 6.66671 5.93033 6.66671 6.66671H5.33337Z" fill="black"/>
    <path d="M8.66671 12V10.6667H7.33337V12H8.66671Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004ZM8.00004 2.66671C5.05452 2.66671 2.66671 5.05452 2.66671 8.00004C2.66671 10.9456 5.05452 13.3334 8.00004 13.3334C10.9456 13.3334 13.3334 10.9456 13.3334 8.00004C13.3334 5.05452 10.9456 2.66671 8.00004 2.66671Z" fill="black"/>
  </svg>


</template>

<script>
export default {
  name: 'QuestionMarkIcon'
}
</script>
