<template>
  <div class="my-navbar">
    <div class="container-fluid row">
      <div class="w-25 mx-auto org-bg">
        <div class="athlete-profile-link col nav-link table-section" 
          v-for="(profile, index) in athletelist" v-if="isProspectlistProfileUrl(profile)"
        >
          <div class="card">
            <div class="card-body">
              <a class="current-athlete-view" :href="getProspectlistProfileUrl(profile)" style="text-align: center;" v-html="getContent(profile)">
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id="carouselExample" class="carousel slide w-75 mx-auto" data-ride="carousel" data-interval="0">
        <div class="carousel-inner" role="listbox">
          <athlete-profile-link v-for="(profile, index) in athletelist"
            :key="index"
            :profile="profile"
            :athletelist="athletelist"
            :screen="screen"
            :getUrlWithProfileIndentifier="getUrlWithProfileIndentifier"
            :getContent="getContent"
          ></athlete-profile-link>
        </div>
        <a class="carousel-control-prev" href="#carouselExample" role="button" data-slide="prev" @click="getPreviousUrl">
            <i class="fa fa-chevron-left fa-lg text-muted"></i>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next text-faded" href="#carouselExample" role="button" data-slide="next" @click="getNextUrl">
            <i class="fa fa-chevron-right fa-lg text-muted"></i>
            <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import AthleteProfileLink from './AthleteProfileLink'
  const pathname = window.location.pathname;

  export default {
    components: { AthleteProfileLink },

    props: {
      prospectlist: {type: Object, default() {return {}}, required: false},
      screen: {type: String, default: 'public-prospectlist', required: false}, // options: {'public-prospectlist', 'coaches-prospectlist', 'organizations'}
    },

    data() {
      return {
        // enter data here
      };
    },
    computed: {
      prospectlist_id() {
        return this.prospectlist.data.id;
      },
      athletelist() {
        return this.prospectlist.data.playerprofiles.data;
      },
    },
    methods: {
      getProspectlistProfileUrl(profile){
        return this.getUrlWithProfileIndentifier(profile);
      },
      isProspectlistProfileUrl(profile){
        const url = this.getUrlWithProfileIndentifier(profile);
        return (url === pathname);
      },
      getUrlWithProfileIndentifier(profile){
        const path = pathname.split("/").slice(1);
        var url = '/prospectlists/'+this.prospectlist_id+'/profiles/' + profile.id;

        // add head to url path: {public-prospectlist/token, coaches-prospectlist/token, or organizations/'orgID'}
        url = '/'+path[0]+'/'+path[1]+url;
        return url;
      },
      getPreviousUrl(){
        const profilelinks = $('.athlete-profile-link');
        const indexLimit = profilelinks.length-1;

        // get previous profilelink and redirect user 
        if(false) // turning off by setting to false (ie. leaving this here in case we decide to use 'redirect')
          profilelinks.each(function(index){
            // if not beginning of links, then progress to previous link, else go to end of linklist
            if($(this).hasClass( "active" )){
              if(index > 0){
                window.location.href = $(profilelinks[index-1]).find('.card-body a:first').attr('href');
              }
              else if(index <= 0){
                window.location.href = $(profilelinks[indexLimit]).find('.card-body a:first').attr('href');
              }
            }
          });
      },
      getNextUrl(){
        const profilelinks = $('.athlete-profile-link');
        const indexLimit = profilelinks.length-1;

        // get next profilelink and redirect user 
        if(false) // turning off by setting to false (ie. leaving this here in case we decide to use 'redirect')
          profilelinks.each(function(index){
            // if not end of links, then progress to next link, else go to beginning of linklist
            if($(this).hasClass( "active" )){
              if(index < indexLimit){
                window.location.href = $(profilelinks[index+1]).find('.card-body a:first').attr('href');
              }
              else if(index >= indexLimit){
                window.location.href = $(profilelinks[0]).find('.card-body a:first').attr('href');
              }
            }
          });
      },
      getContent(profile){
        const full_name = "<span class='text-uppercase' style='font-size:large'>"+profile.basics.data.first_name+" "+profile.basics.data.last_name+"</span>"
        const position = (profile.basics.data.primary_position_name) ? "<br /><span style='font-size:smaller;'>"+profile.basics.data.primary_position_name+"</span>" : ''
        return full_name+position
      },
    },
  };
</script>

<style scoped>
  .my-navbar {
    width: 100%;
    background: black;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 10px;
    padding-right: 0;
    padding-left: 0;
  }

  @media (min-width: 768px) {

    /* show 3 items */
    .carousel-inner .active,
    .carousel-inner .active + .carousel-item,
    .carousel-inner .active + .carousel-item + .carousel-item,
    .carousel-inner .active + .carousel-item + .carousel-item  + .carousel-item {
        display: block;
    }
    
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item,
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item, 
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item {
        transition: none;
    }
    
    .carousel-inner .carousel-item-next,
    .carousel-inner .carousel-item-prev {
      position: relative;
      transform: translate3d(0, 0, 0);
    }
    
    .carousel-inner .active.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
        position: absolute;
        top: 0;
        right: -25%;
        z-index: -1;
        display: block;
        visibility: visible;
    }
    
    /* left or forward direction */
    .active.carousel-item-left + .carousel-item-next.carousel-item-left,
    .carousel-item-next.carousel-item-left + .carousel-item,
    .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item,
    .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item, 
    .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
        position: relative;
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }
    
    /* farthest right hidden item must be abso position for animations */
    .carousel-inner .carousel-item-prev.carousel-item-right {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        display: block;
        visibility: visible;
    }
    
    /* right or prev direction */
    .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
    .carousel-item-prev.carousel-item-right + .carousel-item,
    .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item,
    .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item, 
    .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
        position: relative;
        transform: translate3d(100%, 0, 0);
        visibility: visible;
        display: block;
        visibility: visible;
    }

  }

  .carousel-control-next, .carousel-control-prev {
    width: 5%;
  }

  .carousel-inner .carousel-item {
      margin-right: inherit;
  }

  .my-navbar .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }

  .current-athlete-view {
    color: black !important;
  }
</style>

<style>
  .athlete-profile-link .card {
    border: none;
    background: none;
  }

  .athlete-profile-link .card-body {
    padding: 0;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
  }

  .athlete-profile-link .card-body a {
    color: white;
    font-size: 1.15em;
  }
</style>
