<template>
  <Modal
    :isOpen="isOpen"
    heading="File Errors"
    subHeading="Import Player List"
    @onClose="onClose"
  >
    <ErrorsTable v-bind="{errors}" />
    <template v-slot:footer>
      <Button @onClick="downloadErrors">Save</Button>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@components/Modal';
  import Button from '@components/Button';
  import ErrorsTable from '@screens/myinvites/components/ErrorsTable';

  export default {
    name: "CsvErrorModal",
    components: { Modal, Button, ErrorsTable },
    props: {
      isOpen: false,
      errors: [],
      csvErrors: '',
      fileName: '',
      onClose: function() {},
    },
    methods: {
      downloadErrors() {
        window.exportData(this.csvErrors, `${this.fileName}_errors.csv`);
      }
    }
  }
</script>
