<template>
  <div class="modal fade" id="players-to-import" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-full" role="document">
      <div class="modal-content">

        <div class="modal-header">
          <div class="header-container">
            <h3 class="modal-title float-left">
              {{playerCount}} Players to Import
            </h3>

            <div class="button-wrapper">
              <button type="button" class="btn btn-outline-success btn-sm float-right" v-on:click.stop.prevent="addPlayerData">Add Player</button>
              <button type="button" class="btn btn-outline-info btn-sm float-right" v-on:click.stop.prevent="saveAllPlayerData">Save All Players</button>
            </div>
          </div>

          <button decline-players type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <slot name="sds-modal-body-title"></slot>

          <roster-import-form></roster-import-form>
        </div>

        <div class="modal-footer">
          <div class="button-wrapper">
              <button decline-players onclick="javascript:$('#players-to-import').modal('hide');" class="btn btn-danger">Decline</button>
              
              <button v-if="isSaving" type="submit" class="top-save btn btn-secondary" :disabled="isSaving"><span class="spinner-border spinner-border-sm mr-1"></span>Importing...</button>
              <button v-else accept-players class="btn btn-primary float-right" @click="handleSubmitPlayerImportForm">Import Players</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import RosterImportForm from "./RosterImportForm";

  export default {
    components: {
      RosterImportForm
    },
    data() {
      return {
        saving: false,
        playerCount: 0,
      }
    },
    computed: {
      isSaving(){
        return this.saving;
      },
    },
    methods: {
      toggleSavingMode(saveMode){
        this.saving = saveMode;
      },
      handleSubmitPlayerImportForm(){
        window.EventBus.publish('sds_handleSubmitPlayerImportForm');
      },
      addPlayerData(){
        window.EventBus.publish('sds_addPlayerData');
      },
      saveAllPlayerData(){
        window.EventBus.publish('sds_saveAllPlayerData');
      },
    },
    created() {
      window.EventBus.subscribe('sds_toggleImportMode', (data) => {
        this.toggleSavingMode(data.saveMode);
      });

      window.EventBus.subscribe('sds_updatePlayerCount', (data) => {
        this.playerCount = data.playerCount;
      });
    },
  }
</script>

<style>
.header-container {
  margin-top: 20px;
}

.modal-body {
  max-height: 75vh; 
  overflow: scroll;
}
</style>
