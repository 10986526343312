<template>
  <FormulateForm
    class="form-wrapper"
    id="nameSummaryForm"
    :name="formName"
    v-model="formValues"
    @submit="handleSubmit"
    #default="{ hasErrors, isLoading }"
  >
    <div class="form-body">
      <div class="form-group">
        <FormInput
          id="name-input"
          name="name"
          type="text"
          label="Name"
          placeholder="Name"
          validation="required"
          :dark="true"
        />
      </div>
      <div class="form-group">
        <FormInput
          id="email"
          name="email"
          type="text"
          label="Email"
          placeholder="Email"
          validation="required"
          :dark="true"
        />
      </div>
      <div class="form-group">
        <FormInput
          id="position"
          name="position"
          type="select"
          :options="positionOptions"
          label="Position"
          placeholder="Position"
          validation="required"
          :dark="true"
        />
      </div>
    </div>
    <div class="form-footer">
      <div class="action-buttons">
        <Button
          type="submit"
          :isLoading="isLoading"
          :isDisabled="hasErrors || isLoading"
          :endIcon="submitEndIcon"
        >
          Submit
        </Button>
        <Button
          type="button"
          color="secondary"
          @onClick="$emit('onCancel')"
          :isDisabled="isLoading"
        >
          Cancel
        </Button>
      </div>
    </div>
  </FormulateForm>
</template>

<script>
  import Button from '@components/Button';
  import FormInput from '@components/FormInput';
  import CircleCheckMark from '@components/svg/CircleCheckMark';

  export default {
    name: 'EditPlayerInfoForm',
    components: { Button, FormInput, CircleCheckMark },
    props: ['defaultValues', 'formName', 'playerId'],
    data() {
      return {
        submitEndIcon: CircleCheckMark,
        formValues: this.defaultValues,
        isDrawerOpen: false,
      }
    },
    computed: {
      positionOptions: function () { return window.signingDayConstants.user_positions},
    },
    methods: {
      async handleSubmit(values) {
        const controller = new AbortController();

        try {
          await axios.post(
            `/api/invited-players/${this.playerId}/update`,
            values,
            {
              signal: controller.signal
            }
          );

          this.$emit('onSuccess');
          this.toaster({
            type: 'success',
            heading: 'Success',
            message: 'Player updated successfully',
          });
        } catch (error) {
          this.toaster({
            type: 'error',
            heading: 'Error',
            message: 'Failed to update player',
          });

          console.error(error);
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
.form-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.form-footer {
  margin-top: 2rem;
}
.action-buttons {
  display: flex;
  justify-content: space-between;
}
.action-buttons button {
  flex: 0 0 calc(50% - 8px);
}
</style>
