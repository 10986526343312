<template>
  <div class="infiput">
    <draggable :list="infiputs" :animation="200" v-cloak>
      <transition-group>
        <component v-for="(infiput, index) in infiputs" :is="type" :key="index+1" :index="index" :remove="(index) => { remove(index) }" :data="infiputs[index]" :props="JSON.parse(props)" />
      </transition-group>
    </draggable>
    <button type="button" class="btn btn-success" @click="add">Add {{ type.replace('-', ' ') }}</button>
  </div>
</template>

<script>
  export default {
    name: 'infiput',
    props: ['type', 'props', 'data'],

    data() {
      return {
        infiputs: this.data ? JSON.parse(this.data) : []
      }
    },

    updated() {
      $('.masonry').masonry('layout')
    },

    methods: {
      add() {
        this.infiputs.push({})
        $('.masonry').masonry('layout')
      },

      remove(index) {
        this.infiputs.splice(index, 1)
        setTimeout(() => {
          $('.masonry').masonry('layout')
        }, 100)
      }
    }
  }
</script>
