<template>
  <svg width="16" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.58829 13.4703L5.58838 13.4704L5.59153 13.4731L5.59166 13.4732L5.5926 13.474L5.59281 13.4742L5.5929 13.4742L5.59312 13.4744L5.59323 13.4745L5.59413 13.4753L5.91863 13.7534L5.99998 13.8232L6.08133 13.7534L6.40583 13.4753L6.40672 13.4745L6.40683 13.4744L6.40706 13.4742L6.40715 13.4742L6.40733 13.474L6.40828 13.4732L6.40843 13.4731L6.41159 13.4704L6.41166 13.4703L6.50163 13.3932L6.50071 13.3921C6.53313 13.3633 6.5734 13.3272 6.62069 13.2843L6.53665 13.1918L6.62069 13.2843C6.75316 13.164 6.94098 12.9895 7.16573 12.7702C7.61446 12.3321 8.21424 11.7111 8.81577 10.9807C9.41577 10.2521 10.0278 9.40225 10.4923 8.50645C10.954 7.61606 11.2916 6.63883 11.2916 5.66667C11.2916 2.74416 8.92249 0.375 5.99998 0.375C3.07747 0.375 0.708313 2.74416 0.708313 5.66667C0.708313 6.63883 1.04595 7.61606 1.50763 8.50645C1.97212 9.40225 2.58419 10.2521 3.18419 10.9807C3.78571 11.7111 4.3855 12.3321 4.83423 12.7702C5.05898 12.9895 5.2468 13.164 5.37927 13.2843C5.42656 13.3272 5.46683 13.3633 5.49925 13.3921L5.49833 13.3932L5.58829 13.4703ZM5.99998 3.375C4.73433 3.375 3.70831 4.40101 3.70831 5.66667C3.70831 6.93232 4.73433 7.95833 5.99998 7.95833C7.26563 7.95833 8.29165 6.93232 8.29165 5.66667C8.29165 4.40101 7.26563 3.375 5.99998 3.375ZM4.95831 5.66667C4.95831 5.09137 5.42468 4.625 5.99998 4.625C6.57528 4.625 7.04165 5.09137 7.04165 5.66667C7.04165 6.24196 6.57528 6.70833 5.99998 6.70833C5.42468 6.70833 4.95831 6.24196 4.95831 5.66667ZM6.29256 11.8757C6.18507 11.9806 6.08677 12.0744 5.99998 12.1559C5.91319 12.0744 5.81488 11.9806 5.7074 11.8757C5.28113 11.4596 4.71424 10.8723 4.1491 10.186C3.58243 9.49792 3.02783 8.72275 2.61733 7.93105C2.20401 7.13393 1.95831 6.36117 1.95831 5.66667C1.95831 3.43452 3.76783 1.625 5.99998 1.625C8.23213 1.625 10.0416 3.43452 10.0416 5.66667C10.0416 6.36117 9.79595 7.13393 9.38263 7.93105C8.97212 8.72275 8.41752 9.49792 7.85086 10.186C7.28571 10.8723 6.71883 11.4596 6.29256 11.8757Z" fill="#454545" stroke="#454545" stroke-width="0.25"/>
  </svg>
</template>

<script>
export default {
  name: "LocationIcon"
}
</script>
