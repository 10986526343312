<template>
  <div>
    <div id="note-filter" v-if="this.authuser !== null">
      <div class="btn-group" v-if="ready">
        <button type="button" class="dropdown-toggle btn btn-secondary waves-effect waves-light" data-toggle="dropdown"><i class="fa fa-search"></i></button>
        <div class="dropdown-menu" style="padding-top: 6px; min-width: 230px; max-height: 500px; overflow: auto;">
          <div v-for="tag in tags" style="padding: 5px 10px;">
            <h5>{{ tag.type }}</h5>
            <div class="checkbox checkbox-primary" v-for="item in tag.tags" onclick="event.stopPropagation()">
              <input type="checkbox" :id="`filter-${item.id}`" v-bind:value="item.id" v-on:click="updatefilter(item.id, item.name)">
              <label :for="`filter-${item.id}`">{{ item.name }}</label>
              <i v-if="tag.type === 'custom'" @click="removeTag(item.id)" class="fa fa-close float-right text-danger" style="padding: 3px; cursor: pointer;"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-group pull-right" v-if="ready">
        <button class="btn btn-secondary waves-effect waves-light" v-on:click="follow" v-show="player.following !== true">Subscribe</button>
        <button class="btn btn-secondary waves-effect waves-light" v-on:click="follow" v-show="player.following === true">Unsubscribe</button>
      </div>
      <div class="m-t-20 new-note">
        <create-note :id="id" v-if="this.authuser !== null" v-on:new_note="getComments" :tags="tags" :coaches="coaches" :embed_refs="this.embed_refs"/>
      </div>
    </div>
    <div id="filter-status" style="display:none;">Highlighted: <span></span></div>
    <div class="comment-length"> {{ commentLength }} Comment<span v-if="commentLength > 1">s</span></div>
    <comment v-if="ready" v-for="(comment, index) in comments" :key="comment.id" :tags="tags" :coaches="coaches" :getComments="getComments" :filter="filter_ids" :comment="comment" :authuser="authuser" :alertableid="alertableid" :showChildren="false"/>
    <div v-if="this.moreComments" class="load-more">
      <div class="col-12 text-center load-button">
        <span v-on:click="getComments" class="link show-more">Load More Comments</span>
      </div>
    </div>
    <div v-if="ready && ! this.commentsExist()">No notes found for this player</div>
    <div v-if="! ready" class="spinner-border text-primary m-2"></div>
  </div>
</template>

<script>
import axios from 'axios'
import Comment from './Comment'
import Multiselect from 'vue-multiselect'

export default {

  components: { Multiselect },
  name: 'Notes',
  props: {
    type: String,
    id: Number,
    authuser: String,
    organizationid: String,
    alertableid: String,
    player: Object,
    embed_refs: {
        type: String,
        default: 'false'
      }
  },
  data() {
    return {
      ready: false,
      comments: false,
      tags: [],
      coaches: [],
      filter_ids:[],
      filter_names:[],
      reply: '',
      moreComments: true,
      commentLength: 0
    }
  },

  mounted: function() {
    this.getComments()
    this.getTags()
    this.getCoaches()
    this.clearUnread()
    this.$root.$on('updateTags', () => {
      this.getTags()
    })
  },

  methods: {
    getComments (filter_ids = []) {
      axios.get('/api/comments', {
        params: {
          type: this.type,
          id: this.id,
          filter: JSON.stringify(filter_ids),
          page: Math.floor(this.comments.length / 25) + 1 || 1
        }
      }).then((response) => {
        let currentPage = response.data?.meta?.pagination?.current_page  || 1
        let totalPages = response.data?.meta?.pagination?.total_pages || 1
        if(currentPage <= totalPages) {
          if(this.comments != false) {
            this.comments = this.comments.concat(response.data.data)
            console.log(this.comments)
          }
          else {
            this.comments = response.data.data
            this.comments.map((comment) => {
              if (comment.comment !== '[deleted]') {
                this.commentLength++
              }
            })
          }
        }
        if(currentPage == totalPages) {
          this.moreComments = false
        }
        this.ready = true
      }).catch((error) => {
        console.log(error)
      })
    },
    getTags: function() {
      axios.get('/api/tags').then((response) => {
        let filtered = []
        response.data.map((type) => {
          if (this.player.current_sport === 'football' && (type.type === 'football_position' || type.type === 'characteristics' || (type.type === 'custom' && type.tags.length > 0))) {
            filtered.push(type)
          } if ((this.player.current_sport === 'baseball' || this.player.current_sport === 'softball') && (type.type === 'baseball_position' || type.type === 'pitching' || type.type === 'batting' || type.type === 'fielding' || (type.type === 'custom' && type.tags.length > 0))) {
            filtered.push(type)}
        })
        this.tags = filtered
      }).catch((error) => {
        console.log(error)
      })
    },
    getCoaches: function() {
      axios.get('/api/organizations/' + this.organizationid + '/coaches').then((response) => {
        this.coaches = response.data
      }).catch((error) => {
        console.log(error)
      })
    },
    clearUnread() {
      axios.get(`/api/comments/read/${this.id}`).catch(error => {
        console.log(error)
      })
    },
    commentsExist () {
      if (this.comments.length === 0) {
        return false
      }
      for(var i = 0; i < this.comments.length; i++) {
        if (this.comments[i].comment !== '[deleted]' || this.comments[i].total_replies > 0) {
          return true
        }
      }
    },
    follow: function() {
      axios.post('/api/alert-subscription', {id: this.id, type: 'App\\User'}).then((response) => {
        if (response.data === true) {
          this.player = Object.assign({}, this.player, {following: true });
          toastr.success('You will be alerted of new notes for this player.', 'Subscribed');
        } else {
          this.player = Object.assign({}, this.player, {following: false });
          toastr.warning('Unsubscribed from this player.', 'Unsubscribed!');
        }
      }).catch((error) => {
        console.log(error.response.data.message)
        toastr.error('Something went wrong. Please try again.', 'Error!');
      })
    },
    select (option) {
      option.selected = !option.selected
    },
    updatefilter (tag_id, tag_name) {
      // ids
      var index = this.filter_ids.indexOf(tag_id);

      if (index > -1) {
        this.filter_ids.splice(index, 1)
      } else {
        this.filter_ids.push(tag_id);
      }

      // names
      var index = this.filter_names.indexOf(tag_name);
      if (index > -1) {
        this.filter_names.splice(index, 1)
      } else {
        this.filter_names.push(tag_name);
      }

      this.$emit('filter')
      // this.getComments(this.filter_ids);

      $('#filter-status>span').html(this.filter_names.join(', '));
      if (this.filter_names.length > 0) {
        $('#filter-status').show();
      } else {
        $('#filter-status').hide();
      }
      // add tag_id to array of tags
      // or remove tag_id from list if it exists
      // pass this list into getComments() to filter results
    },
    removeTag(tag) {
      bootbox.confirm("Are you sure you want to delete this tag?", (confirmed) => {
        if (! confirmed) {
          return
        }

        axios.delete(`/api/tags/${tag}`).then((response) => {
          this.getTags()
        })
      })
    }
  }
}
</script>
