export default class Attributes {
  constructor(profile) {
    this.measurables = profile.measurables.data;
    this.testing = profile.testing.data;
    this.basics = profile.basics.data;

    this.base = {
      height: this.measurables.height?.split(' '),
      weight: this.measurables.weight?.split(' '),
      hand_size: this.measurables.hand_size?.split(' '),
      wing_span: this.measurables.wing_span?.split(' '),
      l_drill: this.testing.l_drill?.split('.'),
      'ten_yard_dash': this.testing['ten_yard_dash']?.split('.'),
      'one_hundred_yard_dash': this.testing['one_hundred_yard_dash']?.split('.'),
      'forty_yard_dash': this.testing['forty_yard_dash']?.split('.'),
      shuttle: this.testing.shuttle?.split('.'),
      standing_broad_jump: this.testing.standing_broad_jump?.split(' '),
      bench: this.testing.bench?.split('x'),
      dob: this.basics.dob?.split('-')
    };
  }

  get() {
    return {
      // Height
      height_feet: this.base.height?.[0] || '',
      height_inches: this.base.height?.[1] || '',

      // Weight
      weight: `${this.base.weight?.[0]} lbs` || '',

      // Had Size
      hand_size_inches: (this.base?.hand_size.length > 1 ? `${this.base.hand_size?.[0]}"` : this.base.hand_size?.[0]) || '',
      hand_size_fraction: this.base?.hand_size.length > 1 ? this.base.hand_size?.[1] : '',

      // Wing Span
      wing_span_inches: (this.base?.wing_span.length > 1 ? `${this.base.wing_span?.[0]}"` : this.base.wing_span?.[0]) || '',
      wing_span_fraction: (this.base?.wing_span.length > 1) ? this.base.wing_span?.[1] : '',

      // L Drill
      l_drill_seconds: this.base.l_drill?.[0] || '',
      l_drill_milliseconds: `.${this.base.l_drill?.[1]}` || '',

      // 10 yd Dash
      'ten_yard_dash_seconds': this.base['ten_yard_dash']?.[0] || '',
      'ten_yard_dash_milliseconds': `.${this.base['ten_yard_dash']?.[1]}` || '',

      // 100 yd Dash
      'one_hundred_yard_dash_seconds': this.base['one_hundred_yard_dash']?.[0] || '',
      'one_hundred_yard_dash_milliseconds': `.${this.base['one_hundred_yard_dash']?.[1]}` || '',

      // 40 Yard Dash
      'forty_yard_dash_seconds': this.base['forty_yard_dash']?.[0] || '',
      'forty_yard_dash_milliseconds': `.${this.base['forty_yard_dash']?.[1]}` || '',

      // Short Shuttle
      shuttle_seconds: this.base.shuttle?.[0] || '',
      shuttle_milliseconds: `.${this.base.shuttle?.[1]}` || '',

      // Standing Broad Jump
      standing_broad_jump_feet: this.base.standing_broad_jump?.[0] || '',
      standing_broad_jump_inches: this.base.standing_broad_jump?.[1] || '',

      // Nike Rating
      nike_football_rating: this.basics.sportBasics.data.nike_football_rating,

      // Bench
      bench_lbs: this.base.bench?.[0] || '',
      bench_reps: this.base.bench?.[1] || '',

      // Squat
      squat: this.testing.squat,

      // Date of Birth
      dob_year: this.base.dob?.[2] || '',
      dob_month: (this.base.dob?.[0] < 10 ? this.base.dob?.[0].substring(1) : this.base.dob?.[0]) || '',
      dob_day: (this.base.dob?.[1] < 10 ? this.base.dob?.[1].substring(1) : this.base.dob?.[1]) || '',
    };
  }
}
