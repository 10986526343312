<template>
  <div class="form-control">
    <span class="filter-title">{{title}}</span>
    <el-input-number
      v-model="minVal" 
      controls-position="right" 
      @change="handleChangeMin" 
      :min="0" 
      :max="999"
      size="mini"
      :disabled="isMinDisabled"
    ></el-input-number>
    <span> - </span>
    <el-input-number
      v-model="maxVal" 
      controls-position="right" 
      @change="handleChangeMax" 
      :min="0" 
      :max="999"
      size="mini"
      :disabled="isMaxDisabled"
    ></el-input-number>
  </div>
</template>

<script>
  export default {
    props: [
      'title', 'id', 'handleDataChange',
    ],

    data() {
      return {
        minVal: 0,
        maxVal: 999,
        minDisable: false,
        maxDisable: true,
      };
    },

    computed: {
      isMinDisabled(){
        return this.minDisable;
      },
      isMaxDisabled(){
        return this.maxDisable;
      },
      getMinValue(){
        return this.minVal;
      },
    },

    methods: {
      handleChangeMin(value) {
        this.minVal = value;
        this.handlChange();
        this.maxDisable = false;
      },
      handleChangeMax(value) {
        this.maxVal = value;
        this.handlChange();
      },
      handlChange(){
        let data = [this.minVal, this.maxVal];
        this.handleDataChange(data, this.id);
      },
      resetFilterInputs(){
        this.minVal = 0;
        this.maxVal = 999;
        this.maxDisable = true;
      },
    },
  };
</script>

<style scoped>
  .form-control {
    padding: 6px 15px;
    margin-bottom: 10px;
  }

  .filter-title {
    margin-right: 5px;
  }

  .el-input-number--small {
    width: 100px;
  }

  .el-input-number--mini {
    width: 90px;
  }
</style>
