const options = {
  height_feet: [],
  height_inches: [],
  weight: [],
  hand_size_inches: [],
  eight_fractions: ['0', '1/8', '1/4', '3/8', '1/2', '5/8', '3/4', '7/8'],
  wing_span_inches: [],
  four_fractions: ['0', '1/4', '1/2', '3/4'],
  milliseconds: [],
  l_drill_seconds: [],
  'ten_yard_dash_seconds': [],
  'one_hundred_yard_dash_seconds': [],
  'forty_yard_dash_seconds': [],
  shuttle_seconds: [],
  standing_broad_jump_feet: [],
  standing_broad_jump_inches: [],
  half_decimal: ['0', '5'],
  nike_football_rating: [],
  bench_lbs: ['185', '225'],
  bench_reps: [],
  squat: [],
  months: {
    1: 'January', 2: 'February', 3: 'March', 4: 'April', 5: 'May', 6: 'June', 7: 'July', 8: 'August', 9: 'September', 10: 'October', 11: 'November', 12: 'December',
  },
  states: ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Minor Outlying Islands', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'U.S. Virgin Islands', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'],
  years: [],
};

/**
 * Generate the select options
 */

// Height
for (let i = 4; i <= 8; i++) {
  options.height_feet.push(i);
}
for (let i = 0; i < 12; i++) {
  options.height_inches.push(i);
  options.height_inches.push(`${i}.5`);
}
// Weight
for (let i = 100; i <= 400; i++) {
  options.weight.push(i);
}
// Hand Size
for (let i = 6; i <= 12; i++) {
  options.hand_size_inches.push(i);
}
// Milliseconds
for (let i = 0; i <= 99; i++) {
  options.milliseconds.push(i < 10 ? `0${i}` : i);
}
// Wing Span
for (let i = 48; i <= 90; i++) {
  options.wing_span_inches.push(i);
}
// L Drill
for (let i = 0; i <= 20; i++) {
  options['l_drill_seconds'].push(i);
}
// 10 yd Dash
for (let i = 19; i <= 50; i++) {
  options['ten_yard_dash_seconds'].push(i);
}
// 100 yd Dash
for (let i = 40; i <= 120; i++) {
  options['one_hundred_yard_dash_seconds'].push(i);
}
// 100 Meter Dash & Short Shut
for (let i = 0; i <= 10; i++) {
  options['forty_yard_dash_seconds'].push(i);
  options.shuttle_seconds.push(i);
}
// Standing Broad Jump
for (let i = 10; i <= 60; i++) {
  options.standing_broad_jump_inches.push(i);
}
for (let i = 4; i <= 8; i++) {
  options.standing_broad_jump_feet.push(i);
}
for (let i = 0; i < 12; i++) {
  options.standing_broad_jump_inches.push(i);
  options.standing_broad_jump_inches.push(`${i}.5`);
}
// Nike Rating
for (let i = 40; i <= 170; i++) {
  options.nike_football_rating.push(i);
}
//
for (let i = 1; i <= 40; i++) {
  options.bench_reps.push(i);
}
// Squat
for (let i = 35; i <= 1000; i++) {
  options.squat.push(i);
}
// Years
for (let i = 1900; i <= 2200; i++) {
  options.years.push(i);
}

export default options;
