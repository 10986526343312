<template>
  <div>
    <a href="#" @click="logOut" class="dropdown-item notify-item">
      <i class="mdi mdi-logout"></i> <span>Logout </span>
    </a>
  </div>
</template>
<script>
export default {
  methods: {
    logOut() {
      const res = this.$store.dispatch("ClearState");
    },
  },
};
</script>