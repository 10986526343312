<template>
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="17.0324" cy="17.0488" r="16.2475" fill="#EB2727"/>
    <path d="M22.9643 22.7745H11.6669C10.1553 22.7745 8.93744 21.4376 8.93744 19.7933V14.4592C8.93744 12.8082 10.1614 11.478 11.6669 11.478H22.9643C24.4759 11.478 25.6938 12.8149 25.6938 14.4592V19.7933C25.6999 21.4443 24.4759 22.7745 22.9643 22.7745Z" fill="white"/>
    <path d="M20.2799 17.0428L15.5273 14.3022V19.7834L20.2799 17.0428Z" fill="#EB2727"/>
  </svg>
</template>

<script>
export default {
  name: "YoutubeIcon"
}
</script>
