export const positionsLayouts = {
  football: {
    offense: [
      ['L_WR', 'spacer', 'L_T', 'L_G', 'C', 'R_G', 'R_T', 'spacer', 'TE', 'spacer', 'R_WR'],
      ['', 'spacer', 'RB', '', 'QB', '', '', 'spacer', 'SR', 'spacer', ''],
    ],
    defense: [
      ['L_CB', '', 'L_DE', 'DT', 'N', 'R_DE', '', 'R_CB'],
      ['', '', 'L_OLB', '', 'ILB', '', 'R_OLB', '', ''],
      [],
      [],
      ['', '', '', 'SS', '', 'FS', '', '', ''],
    ],
    kick_return: [
      ['L','LF','M','RF','R'],
      ['LE','LU','RU','RE'],
      ['','LR','','RR','']
    ],
    kick_off: [
      [],[],
      ['L1','L2','L3','L4','L5','','R5','R4','R3','R2','R1'],
      ['','','','','K','','','','']
    ],
    field_goal: [
      ['','LE','LT','LG','LS','RG','RT','RE',''],
      ['LW','','','','','','','','RW'],
      ['','','','','H','','','',''],
      ['','','','K','','','','',''],
    ],
    punt: [
      ['GL','','','LT','LG','LS','RG','RT','','','GR'],
      ['','','LW','','','','','','RW','',''],
      ['','','','','','','PP','','','','','',''],
      ['','','','','','P','','','','','','','']
    ],
    defend_field_goal : [
      [],[],
      ['ER','RE','RM','RG','R','','L','LG','LM','LE','EL'],
      ['','','','','M','','','','']
    ],
  },
  baseball: {
    offense: [
      ['','','','','CF','','','','',''],
      ['','LF','','','','','','RF','','',''],
      ['','','','SS','','2B','','','','',''],
      ['','','3B','','P','','1B','','','',''],
      ['','','','','spacer','','','','','',''],
      ['','','','','C','','','','','',''],
    ],
  },
  softball: {
    offense: [
      ['','','','','CF','','','','',''],
      ['','LF','','','','','','RF','','',''],
      ['','','','SS','','2B','','','','',''],
      ['','','3B','','P','','1B','','','',''],
      ['','','','','spacer','','','','','',''],
      ['','','','','C','','','','','',''],
    ]
  },
  soccer: {},
  hockey: {}
}
