export default () => {
  $(document).ready(() => {

    $("body").tooltip({ selector: '[data-toggle=tooltip]' });

    initializeRoleSelect();
    initializeSportSelect();
    initializeOrganizationSelect();

    function initializeRoleSelect() {
      $('#add-role-role').select2({
        placeholder: 'User Type',
        minimumResultsForSearch: 4,
        ajax: {
          url: '/api/role-autocomplete-ajax?urc-setup=true',
          dataType: 'json',
          processResults: function (data) {
            return {
              results:  $.map(data, function (item) {
                return {
                  text: item.name,
                  id: item.id
                }
              })
            };
          },
          cache: true
        }
      });
    }

    function initializeSportSelect() {
      $('#add-role-sport').select2({
        placeholder: 'Sport',
        ajax: {
          url: '/api/sport-autocomplete-ajax',
          dataType: 'json',
          processResults: function (data) {
            return {
              results: $.map(data, function (item) {
                return {
                  text: item.pretty_name, 
                  id: item.id
                }
              })
            };
          },
          cache: true
        }
      });
    }

    function initializeOrganizationSelect() {
      $("#add-role-organization").select2({
        placeholder: "Organization",
        minimumInputLength: 3,
        ajax: {
          url: "/api/organization-autocomplete-ajax",
          dataType: "json",
          processResults: function (data) {
            return {
              results: $.map(data, function (item) {
                return {
                  text: item.name,
                  id: item.id,
                };
              }),
            };
          },
          cache: true,
        },
      });
    }

  });

  $('#add-role-role').on('select2:select', (e) => {
    const { id } = e.params.data
    window.vueInstance.$refs['roles-table'].addRole = id
  });

  $('#add-role-sport').on('select2:select', (e) => {
    const { id } = e.params.data
    window.vueInstance.$refs['roles-table'].addSport = id
  });

  $('#add-role-organization').on('select2:select', (e) => {
    const { id } = e.params.data
    window.vueInstance.$refs['roles-table'].addOrganization = id
  });

  $('.el-pagination').css("display", "none");
}
