<template>
  <form id="file-upload" @submit.prevent="upload()">
    <input type="file" name="file" />
    <div v-if="percent" class="loading" style="margin: 0 auto; width: 64px;">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <span style="color: #333">{{ percent }}%</span>
    </div>
    <input type="hidden" name="method" value="POST" />
    <input type="hidden" name="model" :value="fileable_type" />
    <input type="hidden" name="id" :value="fileable_id" />
    <input type="hidden" name="minify" value="true" />
    <button type="submit" class="btn btn-primary pull-right">Save</button>
  </form>
</template>

<script>
import axios from "axios";

export default {
  name: "FileUpload",
  props: {
    fileable_type: String,
    fileable_id: String,
  },

  data() {
    return {
      percent: false,
    };
  },

  mounted() {
    //
  },

  methods: {
    upload() {
      let data = new FormData($("#file-upload")[0]);

      axios({
        method: "post",
        url: "/api/upload-file",
        data: data,
        onUploadProgress: (progress) => {
          this.percent = Math.floor((progress.loaded * 100) / progress.total);
        },
      }).then((response) => {
        this.perfect = false;
        $(".profile-pic").attr("src", response.data.data.url);
        $("#dynamic-component").modal("hide");
      });
    },
  },
};
</script>
