<template>
    <div class="card-box">
        <form @submit.prevent="save()" id="announcement-form">
            <span style="float:right;display:inline-block;">
                <select class="select2 form-control" style="padding-right:25px" id="team-group">
                    <option value="All">All</option>
                    <option value="Offense">Offense</option>
                    <option value="Defense">Defense</option>
                    <option value="Special Teams">Special Teams</option>
                </select>

                <div style="width: 200px;" class="text-center row">
                    <label class="col-6">
                        <input type="checkbox" name="email" value="email" style="margin-right: 5px;">
                        Email
                    </label>
                    <label class="col-6">
                        <input type="checkbox" name="sms" value="sms" style="margin-right: 5px;">
                        SMS
                    </label>
                </div>
            </span>

            <textarea class="form-control" placeholder="Message"></textarea>
            <div style="margin-top:20px;" class="col-12 text-right pr-0">
                <button class="btn waves-effect waves-light btn-danger org-text" style="color: #000;font-family: KairosSans;text-transform: uppercase;padding: 10px; margin-left: 7px;">{{ this.saving ? 'Saving' : 'Save as Draft' }}</button>
                <button class="btn waves-effect waves-light org-bg org-text" style="color: #000;font-family: KairosSans;text-transform: uppercase;padding: 10px; margin-left: 7px;">{{ this.saving ? 'Sending' : 'Send' }}</button>
            </div>
        </form>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {

        name: 'Announcements',
        props: {

        },
        data() {
            return {
                saving: false
            }
        },

        mounted() {

        },

        methods: {
            send() {
                const data = $('#announcement-form').serialize()
                axios.post('/api/announcements', data).then((response) => {
                    console.log(response)
                }).catch((error) => {
                    console.log(error)
                });
            }
        }
    }
</script>
