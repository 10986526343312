<template>
  <FormulateForm
    class="form-wrapper"
    id="nameSummaryForm"
    v-bind={name}
    v-model="formValues"
    @submit="updateNameAndSummary"
    #default="{ hasErrors, isLoading }"
  >
    <div class="form-body">
      <div class="form-group">
        <FormInput
          id="name-input"
          name="name"
          type="text"
          label="Name"
          placeholder="Name"
          validation="required"
          :dark="true"
        />
      </div>
      <div class="form-group">
        <FormInput
          id="summary-input"
          name="summary"
          type="textarea"
          label="Summary"
          placeholder="Summary"
          rows="10"
          :dark="true"
        />
      </div>
    </div>
    <div class="form-footer">
      <div class="action-buttons">
        <Button
          type="submit"
          :isLoading="isLoading"
          :isDisabled="hasErrors || isLoading"
          :endIcon="submitEndIcon"
        >
          Submit
        </Button>
        <Button
          type="button"
          color="secondary"
          @onClick="$emit('onCancel')"
          :isDisabled="isLoading"
        >
          Cancel
        </Button>
      </div>
    </div>
  </FormulateForm>
</template>

<script>
import Button from '@components/Button';
import FormInput from '@components/FormInput';
import CircleCheckMark from '@components/svg/CircleCheckMark';

export default {
  name: 'EditInviteFrom',
  props: ['defaultValues', 'listId', 'name'],
  components: { FormInput, Button, CircleCheckMark },
  data() {
    return {
      submitEndIcon: CircleCheckMark,
      formValues: this.defaultValues,
    }
  },
  methods: {
    async updateNameAndSummary() {
      let formData = new FormData($("#nameSummaryForm")[0]);
      const controller = new AbortController();

      await axios.post(
        `/api/player-invites/${this.listId}/update`,
        formData,
        {signal: controller.signal}
      )
      .then((response) => {
        this.$emit('onSuccess', response);
        this.toaster({
          type: 'success',
          heading: 'Success',
          message: 'Invite updated.'
        });
      })
      .catch((error) => {
        this.toaster({
          type: 'error',
          heading: 'Error',
          message: 'Failed to update invite.'
        });
      });
    },
  }
}
</script>



<style lang="scss" scoped>
.form-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.form-footer {
  margin-top: 2rem;
}
.action-buttons {
  display: flex;
  justify-content: space-between;
}
.action-buttons button {
  flex: 0 0 calc(50% - 8px);
}
</style>
