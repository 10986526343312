<template>
  <div id="weightRoomMeasurements" class="row">
    <div class="col-12">
      <table class="table datatable table-bordered display sd-alt-table" id="measurements-table">
          <thead class="table-header">
              <tr>
                  <th class="d-none"></th>
                  <th>Measurement</th>
                  <th>Type</th>
                  <th>Player Submit</th>
                  <th>Verification Request</th>
                  <th>Actions</th>
              </tr>
          </thead>
          <tbody id="measurements-tbody" class="text-capitalize">
          </tbody>
      </table>
    </div>
  </div>
</template>

<script>
    import axios from 'axios'
    import Multiselect from 'vue-multiselect'

    export default {
        name: 'measurements',
        props: {
        },

        mounted: function() {
          this.initializeMeasurements()
        },

        methods: {
          
          initializeMeasurements() {
            this.createMeasurementsTable()
          },

          createMeasurementsTable() {
            let self = this
            const measurementDt = $('#measurements-table').DataTable({
            processing: true,
            serverSide: true,
            responsive: true,
            searching: false,
            stateSave: true,
            dom: 'frt<"bottom"lp><"bottom2"i>',
            ajax: '/api/measurements?datatable=true',
            rowId: 'id',
            language: {
              paginate: {
                previous: "<i class='fa fa-angle-left'>",
                next: "<i class='fa fa-angle-right'>",
              },
            },
            columns: [
              {
                data: 'id',
                name: 'id',
                visible: false,
              },
              {
                data: null,
                name: null,
                className: 'align-middle text-center',
                orderable: false,
                render(data, type, row, meta) {
                  return `<h3>${row.name}</h3>`;
                },
              },
              {
                data: null,
                name: null,
                className: 'align-middle text-center',
                orderable: false,
                render(data, type, row, meta) {
                  return `<h3 class="gray-text">${row.type.unit}</h3>`;
                },
              },
              {
                data: null,
                name: null,
                className: 'align-middle text-center',
                orderable: false,
                render(data, type, row, meta) {
                  return `<h3 class="gray-text">${row.player_input == '1' ? 'Yes' : 'No'}</h3>`;
                },
              },
              {
                data: null,
                name: null,
                className: 'align-middle text-center',
                orderable: false,
                render(data, type, row, meta) {
                  let message = "";
                  
                  if (row.has_video == "1") {
                    message = "Video"
                  }

                  if (row.has_photo == "1") {
                    message = "Photo"
                    if (row.has_video == "1") {
                      message = "Photo & Video"
                    }
                  }

                  return `<h3 class="gray-text">${message.length > 0 ? message : '-'}</h3>`
                },
              },
              {
                data: null,
                name: null,
                className: 'align-middle text-center',
                orderable: false,
                render(data, type, row, meta) {
                  return `<a onclick="deleteMeasurement('',${row.id})" style="font-size:24px;cursor:pointer;"><i class="fa fa-ban" style="color: red;"></i></a>
                          <a data-dynamic-component="measurement-form" data-modal-title="Edit Measurement" data-props=":edit='true' :measurement_id='${row.id}'" style="font-size:24px;cursor:pointer;margin-left:10px;"><i class="fa fa-edit"></i></a>`;
                },
              },
            ],
          });

          measurementDt.draw()
          $.fn.DataTable.ext.pager.numbers_length = 5

        }
      }
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
