<template>
  <div class="details-row">
    <SocialLinkIcon class="details-icon"/>
    <IconLink v-if="player.profile.hudl_link" className="social-icon" :href="player.profile.hudl_link" title="hudl link">
      <HudlIcon />
    </IconLink>
    <IconLink v-if="player.profile.youtube" className="social-icon" :href="player.profile.youtube" title="youtube link">
      <YoutubeIcon />
    </IconLink>
    <IconLink v-if="player.profile.twitter" className="social-icon" :href="player.profile.twitter" title="twitter link">
      <TwitterIcon />
    </IconLink>
    <IconLink v-if="player.profile.instagram" className="social-icon public-instagram" :href="player.profile.instagram" title="instagram link">
      <InstagramIcon />
    </IconLink>
    <IconLink v-if="player.profile.facebook" className="social-icon" :href="player.profile.facebook" title="facebook link">
      <FacebookIcon />
    </IconLink>
    <IconLink v-if="player.profile.tiktok" className="social-icon" :href="player.profile.tiktok" title="tiktok link">
      <TikTokIcon />
    </IconLink>
    <IconLink v-if="player.email" className="social-icon" :href="`mailto:${ player.email }`" title="email">
      <MailIcon />
    </IconLink>
  </div>
</template>

<script>
import IconLink from "@components/IconLink";
import MailIcon from "@components/svg/MailIcon";
import HudlIcon from "@components/svg/HudlIcon";
import TikTokIcon from "@components/svg/TikTokIcon";
import TwitterIcon from "@components/svg/TwitterIcon";
import YoutubeIcon from "@components/svg/YoutubeIcon";
import FacebookIcon from "@components/svg/FacebookIcon";
import InstagramIcon from "@components/svg/InstagramIcon";
import SocialLinkIcon from "@components/svg/SocialLinkIcon";

export default {
  name: "SocialMediaContainer",
  components: {
    IconLink,
    SocialLinkIcon,
    HudlIcon,
    YoutubeIcon,
    TwitterIcon,
    InstagramIcon,
    MailIcon,
    FacebookIcon,
    TikTokIcon
  },
  inject: ['player']
}
</script>
