<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_156_176592)">
        <path d="M8 1.375C4.34111 1.375 1.375 4.34111 1.375 8C1.375 11.6589 4.34111 14.625 8 14.625C11.6589 14.625 14.625 11.6589 14.625 8C14.625 4.34111 11.6589 1.375 8 1.375ZM2.625 8C2.625 5.03147 5.03147 2.625 8 2.625C10.9685 2.625 13.375 5.03147 13.375 8C13.375 10.9685 10.9685 13.375 8 13.375C5.03147 13.375 2.625 10.9685 2.625 8Z" fill="black" stroke="black" stroke-width="0.25"/>
        <path d="M10.9691 6.79663L11.0577 6.70837L10.9694 6.61985L10.2633 5.91174L10.175 5.82322L10.0865 5.91149L7.1819 8.80782L5.79436 7.42423L5.70584 7.33597L5.61758 7.42449L4.91149 8.1326L4.82322 8.22112L4.91174 8.30938L7.09364 10.4851L7.1819 10.5731L7.27016 10.4851L10.9691 6.79663Z" fill="black" stroke="black" stroke-width="0.25" stroke-linecap="square"/>
    </g>
    <defs>
        <clipPath id="clip0_156_176592">
            <rect width="16" height="16" fill="white"/>
        </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'CircleCheckMark',
};
</script>
