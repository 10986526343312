<template>
  <PillButton
    :className="`pill-button${!isExpanded ? '-inverted' : ''}`"
    @onClick="toggleIsExpanded"
    :button-text="`${isExpanded ? 'HIDE' : 'EXPAND'} FULL PROFILE`"
    :end-icon="ChevronUpIcon"
  />
</template>

<script>
import PillButton from "@components/PillButton";
import ChevronUpIcon from "@components/svg/ChevronUpIcon";

export default {
  name: "ExpandButton",
  components: {PillButton, ChevronUpIcon},
  inject: ['toggleIsExpanded', 'isExpanded'],
  data() {
    return {
      ChevronUpIcon
    }
  }
}
</script>
