<template>
  <college-roster-table></college-roster-table>
</template>

<script>

import CollegeRosterTable from './CollegeProspectsTable'

export default {
  components: { CollegeRosterTable },
  props: {
  },
  computed: {
  },
}
</script>

<style>
</style>
