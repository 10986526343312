<template>
  <div>
    <div id="users-filter" class="form-inline left">
      <div class="clear-filter-wrapper">
        <button id="clearFilters" class="btn btn-primary waves-effect waves-light">Clear Filters</button>
      </div>
      <select id="organization" class="form-control form-control-sm" v-model="filters[0].value"/>
      <select id="sport" class="form-control form-control-sm" v-model="filters[4].value"/>
      <select id="role" class="form-control form-control-sm" v-model="filters[1].value"/>
      <input type="text" id="users-search" class="form-control m-l-5" placeholder="Search by Name" v-model="filters[2].value">
      <input type="text" id="email-search" class="form-control m-l-5" placeholder="Search by Email" v-model="filters[3].value">
    </div>
    <data-tables-server
      id="users-table"
      :data="users"
      :loading="loading"
      :filters="filters"
      :pagination-props="{
        pageSizes: [10, 25, 50, 100],
        background: true,
        layout: 'prev, pager, next, sizes, ->, total'
      }"
      :table-props="{
        'row-key': 'id',
        'class': 'table datatable'
      }"
      :total="count"
      class="table datatable"
      @query-change="handleQueryChange"
      @selection-change="(s) => this.selected = s.map(r => r.id)"
    >
      <el-table-column label="Photo" align="center" width="150">
        <template slot-scope="{ row }">
          <user-avatar :user="row" />
        </template>
      </el-table-column>
      <el-table-column label="Name" sortable sort-by="full_name">
        <template slot-scope="{ row }">
          <div>
            <a :href="`/user/${row.id}/edit`">
              <h4 class="player-name">{{ row.full_name }}</h4>
            </a>
            <div class="roster-city-state">
              {{ row.email }}
              <br/>
              {{ renderRoles(row) }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Organization" align="center">
        <template slot-scope="{ row }">
          <div>{{ renderOrganizationName(row) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="Statuses" align="center">
        <template slot-scope="{ row }" class="align-middle text-center">
          <div v-html="renderStatus(row)"/>
        </template>
      </el-table-column>
      <el-table-column label="Sports" align="center" v-if="isAdmin()">
        <template slot-scope="{ row }" class="align-middle text-center">
          <div v-html="renderSports(row)"/>
        </template>
      </el-table-column>
      <el-table-column label="Legitimate" align="center" width="100">
        <template slot-scope="{ row: { legitimate } }" class="align-middle text-center">
          <div>{{ legitimate ? "Yes" : "No" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="Actions" align="center">
        <template slot-scope="{ row }">
          <a :href="`/user/${row.id}/edit`" class="edit" :style="[row.subscribed && { 'margin-right': '38px' }]"><i class="mdi mdi-lead-pencil"></i></a>
          <span v-if="!row.subscribed" @click="deleteUser(row.id)" id="remove-player"><i class="mdi mdi-delete-forever red"></i></span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="Message"
        type="selection"
        width="150"
        reserve-selection
      ></el-table-column>
    </data-tables-server>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import DataTablesServer from '@components/ui/DataTablesServer'
import { buildQueryForVueDataTable } from '@lib/vue-data-tables-util'
import UserAvatar from '@components/UserAvatar'

let timeout

export default {
  components: { DataTablesServer, UserAvatar },
  name: 'UsersTable',
  props: {},
  data() {
    return {
      url: '/api/users?include=organization_names',
      previousQuery: null,
      initialQuery: true,
      loading: false,
      apiData: null,
      selected: [],
      cancelToken: axios.CancelToken.source(),
      filters: [
        { search_prop: 'organization', value: '' },
        { search_prop: 'role', value: '' },
        { search_prop: 'full_name', value: '' },
        { search_prop: 'email', value: '' },
        { search_prop: 'sport', value: '' }
      ]
    }
  },
  computed: {
    users: (u) => _.get(u, 'apiData.data', []),
    count: (u) => _.get(u, 'apiData.meta.total', 0)
  },
  methods: {
    hasProfile(row) {
      return row.profile || false
    },
    isAdmin() {
      if(this.$store.state.user) {
        return this.$store.state.user.role === "super admin"
      }
      return false
    },
    hasOrganization(row) {
      return row.organization_names && row.organization_names.data.length > 0 || false
    },
    renderImage(row) {
      return this.hasProfile(row)
        ? row.profile.data.photo
        : '/build/images/default-profile.png'
    },
    renderRoles(row) {
      return row.roles ? row.roles.join(', ') : 'N/A';
    },
    renderOrganizationName(row) {
      return this.hasOrganization(row)
        ? row.organization_names.data.map(o => o.name).join(', ')
        : 'N/A'
    },
    renderSports(row) {
      return row.sports ? row.sports.map(function(s){return s.replace('_', ' ')}).join(', <br />') : 'N/A';
    },
    renderStatus(row) {
      const verified = row.email_verified_at !== null;
      const player = row.role === 'player';
      const { subscribed } = row;
      return `<div class="text-center account-info">
        <div class="inner-info">
          <div
          class="round m-2 ${verified ? 'org-bg' : ''}"
          data-toggle="tooltip"
          data-original-title="${verified ? 'Account ' : 'Not '} Verified"
          >
           <i class="fa fa-check"></i>
          </div>
          ${player ?
            `<div
            class="round m-2 ${subscribed ? 'org-bg' : ''}"
            data-toggle="tooltip"
            data-original-title="${subscribed ? 'Has Active Subscription' : 'Is Not Subscribed'}"
            >
              <i class="fa fa-dollar"></i>
            </div>`
            : ``
          }
        </div>
      </div>`
    },
    deleteUser(id) {
      return window.deleteUser(id)
    },
    handleQueryChange(query) {

      if (this.initialQuery) {
        this.initialQuery = false
      } else {
        this.cancelToken.cancel();
        this.cancelToken = axios.CancelToken.source();
      }

      clearTimeout(timeout);
      timeout = setTimeout(() => this.loadData(query), 250);
    },
    async loadData(query) {
      this.previousQuery = query
      this.loading = true
      const builder = buildQueryForVueDataTable(query)
      const url = `${this.url}&${builder.build()}`

      await axios.get(url, {
        cancelToken: this.cancelToken.token
      }).then(response => {

        this.apiData = response.data
        this.loading = false
      }).catch((error) => {

        if (axios.isCancel(error)) {
          console.log("Previous Request canceled");
        }
      })
    }
  }
}
</script>

<style scoped>
  #roster-search {
    font-family: 'KairosSans', serif;
  };
</style>
