<template>
  <ul v-if="!isCollegeFootballCoach">
    <li v-if="isPlayer">
      <a v-bind:href="accountUrl" class="waves-effect waves-primary">
        <i class="ti-id-badge"></i><span>Account</span>
      </a>
    </li>
    <li v-if="isFan">
      <a v-bind:href="recruitsUrl" class="waves-effect waves-primary">
        <i class="ti-agenda"></i><span>Recruits</span>
      </a>
    </li>
    <li v-if="isSuper">
      <a v-bind:href="usersUrl" :class="returnActiveClass('user')">
        <i class="ti-id-badge"></i><span>Users</span>
      </a>
    </li>
    <li v-if="isSuper">
      <a v-bind:href="organizationsUrl" :class="returnActiveClass('organization')">
        <i class="ti-agenda"></i><span>Organizations</span>
      </a>
    </li>
    <li v-if="isSportAdmin || isOrgAdmin">
      <a v-bind:href="myOrganizationUrl" :class="returnActiveClass('organization')">
        <i class="ti-agenda"></i><span>My Organization</span>
      </a>
    </li>
    <li v-if="isSuper && isSupport">
      <a v-bind:href="scriptsUrl" :class="returnActiveClass('script')">
        <i class="ti-book"></i><span>Scripts</span>
      </a>
    </li>
    <li v-if="isSuper">
      <a v-bind:href="calendarUrl" :class="returnActiveClass('calendar')">
        <i class="ti-calendar"></i><span>Calendars</span>
      </a>
    </li>
    <li v-if="showRecruitsProspects">
      <a v-bind:href="recruitsUrl" :class="returnActiveClass('recruit')">
        <i class="ti-search"></i><span>Search Recruits</span>
      </a>
    </li>
    <li v-if="showRecruitsProspects">
      <a v-bind:href="prospectsUrl" :class="returnActiveClass('prospect')">
        <i class="ti-id-badge"></i><span>Prospects</span>
      </a>
    </li>
    <li v-if="(isCoach || isOrgAdmin || isSportAdmin) && isHigherEd && showVerifiedPlayers">
      <a v-bind:href="verifiedPlayersUrl" :class="returnActiveClass('verified')">
        <i class="ti-check-box"></i><span>Verified Players</span>
      </a>
    </li>
    <li v-if="(isCoach || isOrgAdmin || isSportAdmin) && isHigherEd && showMyInvites">
      <a v-bind:href="myInvitesUrl" :class="returnActiveClass('my-invites')">
        <i class="ti-check-box"></i><span>My Invites</span>
      </a>
    </li>
    <li v-if="!isHigherEd && !isSuper" id="sb-roster-btn">
      <a v-bind:href="rosterUrl" :class="returnActiveClass('roster')">
        <i class="ti-user"></i><span>Roster</span>
      </a>
    </li>
    <li v-if="showAdminNotHigher" id="sb-roster-btn">
      <a v-bind:href="rosterQueueUrl" :class="returnActiveClass('queue')">
        <i class="ti-arrow-circle-right"></i><span>Roster Queue</span>
      </a>
    </li>
    <li v-if="showAdminNotHigher && showProspectsList" id="sb-roster-btn">
      <a v-bind:href="prospectsListUrl" :class="returnActiveClass('list')">
        <i class="ti-layers-alt"></i><span>Prospect List</span>
      </a>
    </li>
    <li v-if="showAdminNotHigher">
      <a v-bind:href="teamFeedUrl" :class="returnActiveClass('team')">
        <i class="fa fa-rss"></i><span>Team Feed</span>
      </a>
    </li>
    <li v-if="showAdminNotHigher">
      <a v-bind:href="announcementsUrl" :class="returnActiveClass('announcements')">
        <i class="fa fa-bullhorn"></i><span>Announcements</span>
      </a>
    </li>
    <li v-if="(isCoach || isOrgAdmin || isSportAdmin) && isOrganization && !isAcademy">
      <a v-bind:href="bigBoardUrl" :class="returnActiveClass('board')">
        <i class="ti-blackboard"></i><span>{{ bigBoardText }}</span>
      </a>
    </li>
    <li v-if="(isCoach || isOrgAdmin || isSportAdmin) && isOrganization && !isAcademy">
      <a v-bind:href="filmRoomUrl" :class="returnActiveClass('film')">
        <i class="ti-video-clapper"></i><span>Film Room</span>
      </a>
    </li>
    <li v-if="isCoach || isOrgAdmin || isFan || isSuper || isSportAdmin">
      <a v-bind:href="messagesUrl" :class="returnActiveClass('messages')">
        <i class="mdi mdi-message-text"></i><span>Messages</span>
        <span v-if="(messageTotal > 0)" class="badge badge-pink noti-icon-badge">{{ messageTotal }}</span>
      </a>
    </li>
    <li v-if="isSuper">
      <a :href="schoolImportUrl">
        <i class="mdi mdi-upload"></i>
        <span>Import Schools</span>
      </a>
    </li>
  </ul>
</template>

<script>
import Roles from '@consts/roles'
import OrgTypes from '@consts/organization-types'

export default  {
  name: 'NonCollegeMenu',
  props: {
    returnActiveClass: {type: function (){}},
    attributes: Object,
  },
  computed: {
    user: (store) => store.$store.state.user,
    isPlayer: (store) => store.$store.state.user.role === Roles.PLAYER,
    isFan: (store) => store.$store.state.user.role === Roles.FAN,
    isCoach: (store) => store.$store.state.user.role === Roles.COACH,
    isSuper: (store) => store.$store.state.user.role === Roles.SUPER_ADMIN,
    isSportAdmin: (store) => store.$store.state.user.role === Roles.SPORT_ADMIN,
    isOrgAdmin: (store) => store.$store.state.user.role === Roles.ORGANIZATION_ADMIN,
    isHigherEd: ({ attributes }) => attributes.organizationtype === OrgTypes.COLLEGE,
    isAcademy: ({ attributes }) => attributes.organizationtype === OrgTypes.ACADEMY,
    isOrganization: ({ attributes }) => attributes.isorganization,
    showMyInvites: ({ attributes }) => attributes.myinvitesflag,
    showVerifiedPlayers: ({ attributes }) => attributes.verifiedplayersflag,
    showProspectsList: ({ attributes }) => attributes.prospectslistflag,
    bigBoardText: ({ attributes }) => attributes.bigboardtext,
    accountUrl: ({ attributes }) => attributes.accounturl,
    organizationsUrl: ({ attributes }) => attributes.organizationsurl,
    usersUrl: ({ attributes }) => attributes.usersurl,
    myOrganizationUrl: ({ attributes }) => attributes.myorganizationurl,
    scriptsUrl: ({ attributes }) => attributes.scriptsurl,
    calendarUrl: ({ attributes }) => attributes.calendarurl,
    rosterUrl: ({ attributes }) => attributes.rosterurl,
    rosterQueueUrl: ({ attributes }) => attributes.rosterqueueurl,
    myInvitesUrl: ({ attributes }) => attributes.myinvitesurl,
    verifiedPlayersUrl: ({ attributes }) => attributes.verifiedplayersurl,
    prospectsListUrl: ({ attributes }) => attributes.prospectslisturl,
    prospectsUrl: ({ attributes }) => attributes.prospectsurl,
    teamFeedUrl: ({ attributes }) => attributes.teamfeedurl,
    announcementsUrl: ({ attributes }) => attributes.announcementsurl,
    recruitsUrl: ({ attributes }) => attributes.recruitsurl,
    bigBoardUrl: ({ attributes }) => attributes.bigboardurl,
    filmRoomUrl: ({ attributes }) => attributes.filmroomurl,
    messagesUrl: ({ attributes }) => attributes.messagesurl,
    schoolImportUrl: ({ attributes }) => attributes.schoolimporturl,
    messageTotal: (store) => store.$store.state.messageTotal,
    isCollegeFootballCoach: function () {
      return this.isCoach && this.isHigherEd && this.user.current_sport === 'football'
    },
    showRecruitsProspects: function () {
      return ((this.isCoach || this.isOrgAdmin || this.isSportAdmin) && !this.isSuper && this.isOrganization && this.isHigherEd)
    },
    isSupport: function () {
      return this.user.email.replace('@','$').replace('.','&') === 'support$buildmidwestern&com'
    },
    showAdminNotHigher: function () {
      return ((this.isCoach || this.isOrgAdmin || this.isSportAdmin) && this.isOrganization && !this.isHigherEd)
    },
  },
}
</script>
