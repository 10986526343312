<template>
  <div
    :class="`public-player-profile-header-content ${hasHeaderImage ? 'has-header-image' : ''} ${hasHeaderImage && isExpanded ? 'expanded' : ''}`"
  >
    <user-avatar :user="player" :className="hasHeaderImage ? 'with-negative-margin' : ''" />
    <h3 class="player-name">{{ player.full_name }}</h3>
    <DetailsContainer />
    <ExpandButton />
  </div>
</template>

<script>
import UserAvatar from "@components/UserAvatar";
import PillButton from "@components/PillButton";
import ChevronUpIcon from "@components/svg/ChevronUpIcon";
import ExpandButton from "@components/PlayerProfile/PublicProfile/components/ExpandButton";
import DetailsContainer from "@components/PlayerProfile/PublicProfile/components/DetailsContainer";

export default {
  name: "PlayerDetails",
  props: ['hasHeaderImage'],
  components: {ExpandButton, PillButton, DetailsContainer, UserAvatar, ChevronUpIcon},
  inject: ['isExpanded', 'toggleIsExpanded', 'player'],
}
</script>
