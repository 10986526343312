<template>
  <button
    @click="$emit('onClick')"
    :class="`${buttonColors[color]} btn waves-effect waves-light ${className}`"
    :disabled="isDisabled"
    :title="title"
    :type="type"
  >
    <i v-if="isLoading" class="fa fa-spinner fa-spin"></i>
    <span v-else>
      <component v-if="startIcon" :is="startIcon" class="start-icon"></component>
      <slot></slot>
      <component v-if="endIcon" :is="endIcon"></component>
    </span>
  </button>
</template>

<script>
export default {
  name: "Button",
  created() {
    this.buttonColors = {
      primary: 'btn-primary',
      secondary: 'btn-dark',
      danger: 'btn-danger',
    };
  },
  props: {
    className: {
      type: String,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: 'primary',
    },
    title: {
      type: String,
      default: null
    },
    startIcon: {
      type: Object,
      default: null,
    },
    endIcon: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: 'button',
    }
  }
}
</script>

<style scoped lang="scss">
button {
  border-radius: 0;
  font-family: 'KairosSans', 'sans-serif';
  line-height: 1;
  min-width: 150px;
  padding-top: 0.5rem;
  text-transform: uppercase;
  border-radius: 0;

  &:disabled {
    cursor: not-allowed;
  }

  .start-icon {
    margin-right: 8px;
  }
}
</style>
