<template>
    <fragment>
        <UsersListFilters/>
        <table id="users-table" class="table">
            <UsersListHeader/>
            <tbody>
                <UsersListRow v-for="user in users" :user="user" :key="user.id" />
            </tbody>
        </table>
        <div v-if="loading" class="text-center loading-overlay">
            <!-- <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i> -->
            <img src="build/images/signing-day-sports-logo.png" alt="loading..." title="loading..." class="rotate-image"/>
        </div>
        <UsersListPaginator/>
    </fragment>
</template>

<script>
    import { Fragment } from 'vue-frag';
    import UsersListRow from './components/UsersListRow.vue';
    import UsersListPaginator from './components/UsersListPaginator.vue';
    import UsersListHeader from './components/UsersListHeader.vue';
    import UsersListFilters from './components/UsersListFilters.vue';

    export default {
        name: 'UsersTable',
        components: {
            Fragment,
            UsersListRow,
            UsersListPaginator,
            UsersListHeader,
            UsersListFilters
        },
        props: {
            users: {
                type: Array,
                required: true,
            },
            loading: {
                type: Boolean,
                required: true,
            },
        }
    }

</script>

<style lang="scss" scoped>
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9999;
}
.loading-overlay img {
  width: 50px;
  height: 50px;
}
.rotate-image {
  animation: rotation 2s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@media (prefers-reduced-motion) {
    .rotate-image {
        animation: none;
    }
}
.table {
    position: relative;
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    border-collapse: collapse;
    border: 1px solid #f3f3f3;
}
.table th,
.table td {
    padding: 0.5rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}
.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
    border-top: 2px solid #dee2e6;
}
tbody tr:hover, tbody tr:nth-of-type(odd):hover {
    background-color: rgba(70, 70, 42, 0.03);
}
</style>