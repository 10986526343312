var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"player-container",staticStyle:{"background":"white"}},[_c('div',{staticClass:"verified-players-invite-body"},[_c('data-tables-server',{attrs:{"id":"preview-invites-table","data":_vm.data_rows,"loading":_vm.loading,"total":_vm.count,"current-page":_vm.currentPaginationPage,"pagination-props":{
          pageSizes: [10, 25, 50, 100],
          background: true,
          layout: 'prev, pager, next, sizes, ->, total'
        },"table-props":{
          'row-key': 'email',
          'class': 'table datatable'
        }},on:{"selection-change":_vm.selectionChanged}},[_c('el-table-column',{attrs:{"label":"Name","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('h5',[_vm._v(_vm._s(row.name))])]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"Email","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('h5',[_vm._v(_vm._s(_vm.renderVariable(row.email)))])]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"Parent Email","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('h5',[_vm._v(_vm._s(_vm.renderVariable(row.parent_email)))])]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"Grad Year","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('h5',[_vm._v(_vm._s(_vm.renderVariable(row.grad_year)))])]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"Phone","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('h5',[_vm._v(_vm._s(_vm.renderVariable(row.phone)))])]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"align":"center","type":"selection","width":"150","reserve-selection":""}})],1)],1),_vm._v(" "),_c('div',{staticClass:"verified-players-invite-footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.selected.length == 0},on:{"click":_vm.saveInvitedPlayers}},[_vm._v("Continue")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }