<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.20036 1.50098C6.63555 1.50098 5.36702 2.7695 5.36702 4.33431C5.36702 5.89912 6.63555 7.16764 8.20036 7.16764C9.76516 7.16764 11.0337 5.89912 11.0337 4.33431C11.0337 2.7695 9.76516 1.50098 8.20036 1.50098ZM6.36702 4.33431C6.36702 3.32179 7.18784 2.50098 8.20036 2.50098C9.21288 2.50098 10.0337 3.32179 10.0337 4.33431C10.0337 5.34683 9.21288 6.16764 8.20036 6.16764C7.18784 6.16764 6.36702 5.34683 6.36702 4.33431Z"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.20036 8.50098C5.34689 8.50098 3.03369 10.8142 3.03369 13.6676V14.501H13.367V13.6676C13.367 10.8142 11.0538 8.50098 8.20036 8.50098ZM8.20036 9.50098C10.4457 9.50098 12.2763 11.2771 12.3638 13.501H4.03696C4.12445 11.2771 5.95499 9.50098 8.20036 9.50098Z"/>
</svg>
</template>

<script>
export default {
  name: 'EmailIcon',
};
</script>
