<template>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
         <select name="player_id" class="form-control player-add" v-model="player_id" v-select2></select>
      </div>
    </div>
    <div class="col-12">
      <button class="pull-right btn btn-primary" @click="getPlayer()">Add</button>
    </div>
  </div>
</template>

<script>
    import axios from 'axios'
    import Multiselect from 'vue-multiselect'

    export default {
        name: 'search-roster',
        data() {
            return {
              player_id: false,
            }
        },

        mounted: function() {
          $('.select2').select2()

          $('.player-add').select2({
            placeholder: 'Select Player',
            minimumInputLength: 3,
            ajax: {
              url: '/api/search-roster',
              dataType: 'json',
              processResults: function (data) {
                return {
                  results:  $.map(data, function (item) {
                    return {
                        text: item.name,
                        id: item.id
                    }
                  })
                };
              },
              cache: true
            }
          })
        },

        methods: {
          getPlayer() {
            axios.get(`/api/get-roster-player/${this.player_id}`).then((response) => {
              window.vueInstance.$refs.verification.$refs.health.players.push(response.data.data)
              $('.modal').modal('hide')
            }).catch((error) => {
              console.log(error)
            })
          }
        }

    }
</script>
