<template>
  <fragment>
    <FormulateInput
      :input-class="`form-control ${startIcon ? 'with-icon' : ''} ${dark ? 'form-control-dark' : ''}`"
      :id="id"
      :name="name"
      :type="type"
      :label="label"
      :placeholder="placeholder"
      :validation="validation"
      :disableErrors="disableErrors"
      v-bind="$attrs"
    >
      <template v-if="startIcon" v-slot:help>
        <component v-if="startIcon" :is="startIcon" class="form-control-start-icon"></component>
      </template>
    </FormulateInput>
  </fragment>
</template>

<script>
import { Fragment } from 'vue-frag';

export default {
  name: 'FormInput',
  props: [
    'name',
    'label',
    'placeholder',
    'validation',
    'id',
    'type',
    'startIcon',
    'dark',
    'disableErrors'
  ],
  components: { Fragment },
};
</script>
