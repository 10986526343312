<template>
  <roster-table :screen="screen" ref="roster-table" :sport="sport" :download-flag="downloadFlag"></roster-table>
</template>

<script>

import RosterTable from "./RosterTable";
import legacyCode from "./utils";

export default {
  components: { RosterTable },
  props: {
    screen: {type: String, default: null, required: false},
    hs: {type: String, default: false, required: false},
    ac: {type: String, default: false, required: false},
    club: {type: String, default: false, required: false},
    sport: {type: String, default: 'football', required: false},
    downloadFlag: String
  },
  computed: {
    isHighSchool: ({ hs }) => JSON.parse(hs),
    isAcademy: ({ ac }) => JSON.parse(ac),
    isClub: ({ club }) => JSON.parse(club),
  },
  mounted() {
    legacyCode();
  }
}
</script>

<style>
  .el-table__header thead tr th {
    vertical-align: middle;
  }
</style>
