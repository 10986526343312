<template>
  <div class="container-fluid">
    <span
      class="form-inline pull-right"
      id="roster-filter"
    >
      <label class="announcement-checkbox"
        ><el-checkbox
          v-model="filters[0].value"
          true-label="true"
          false-label=""
          email
          >Email</el-checkbox
        ></label
      >
      <label class="announcement-checkbox"
        ><el-checkbox
          sms
          v-model="filters[1].value"
          true-label="true"
          false-label=""
          >SMS</el-checkbox
        ></label
      >

      <select
        class="form-control custom-select"
        style="padding-right: 25px"
        v-model="filters[2].value"
        id="team-group"
      >
        <option value="All">All</option>
        <option value="Offense">Offense</option>
        <option value="Defense">Defense</option>
        <option value="Special Teams">Special Teams</option>
      </select>
    </span>

    <data-tables-server
      class="table"
      id="announcements-table"
      :table-props="{ 'header-row-class-name': 'tester' }"
      :loading="loading"
      :data="records"
      :total="count"
      :filters="filters"
      @query-change="loadData"
      :pagination-props="{
        pageSizes: [10, 25, 50, 100],
        background: true,
        layout: 'prev, pager, next, sizes, ->, total'}"
      >
      <el-table-column
        class="grad_year"
        label="Message"
        prop="message"
        align="left"
      >
        <template v-slot="{ row }">
          <p v-html="trimMessage(row.message)"></p>
        </template>
      </el-table-column>
      <el-table-column
        label="Created By"
        prop="user.data.full_name"
        align="center"
      >
      </el-table-column>

      <el-table-column class="text-center" label="Platform" align="center">
        <template v-slot="{ row }">
          <span v-if="row.email && row.sms"> Email and SMS </span>
          <span v-else-if="row.email == '1'"> Email </span>
          <span v-else-if="row.sms == '1'"> SMS </span>
          <span v-else> - </span>
        </template>
      </el-table-column>

      <el-table-column class="text-center" label="Status" align="center">
        <template v-slot="{ row }">
          <span v-if="row.sent == '1'"> Sent </span>
          <span v-else-if="row.sent == '0'"> Draft </span>
        </template>
      </el-table-column>

      <el-table-column label="ACTIONS" align="center">
        <template v-slot="{ row }">
          <button
            type="button"
            class="btn dropdown-toggle waves-effect waves-light"
            data-toggle="dropdown"
            aria-expanded="false"
            style="
              border-color: rgb(225, 225, 225);
              border-width: 1;
              border-radius: 0;
              padding: 7.5px 30px;
              font-family: KairosSans;
              color: black !important;
            "
          >
            ACTIONS
            <span data-html2canvas-ignore><span class="caret"></span></span>
          </button>
          <div class="dropdown-menu">
            <a
              class="dropdown-item"
              :onclick="`window.sendAnnouncement(${row.id}, ${row.sent}, ${
                row.email == '1' || row.sms == '1' ? 'true' : 'false'
              })`"
            >
              {{ row.sent == "1" ? "Re-Send" : "Send" }}
            </a>
            <a class="dropdown-item" :href="`/announcement/${row.id}`">View</a>
            <a
              v-if="row.sent"
              class="dropdown-item"
              :href="`/announcement/${row.id}/edit`"
              >Edit</a
            >
          </div>
        </template>
      </el-table-column>
    </data-tables-server>
  </div>
</template>


<script>
import axios from "axios";
import _ from "lodash";

import DataTablesServer from "./ui/DataTablesServer";

import { buildQueryForVueDataTable } from "../lib/vue-data-tables-util";

import { FilterBuilder, SearchFilter } from "../lib/url-filter";

export const ActiveLinkedIcon = {
  name: "ActiveLinkedIcon",
  props: ["url", "status", "title", "iconClass"],
  template: `
        <a :href="url">
          <div class="round m-2"
              :class="{'org-bg org-text': status}"
              :data-original-title="title"
              data-toggle="tooltip"
            >
            <i class="fas" :class="[iconClass]"></i>
            <slot></slot>
          </div>
        </a>`,
};

export default {
  components: { DataTablesServer, ActiveLinkedIcon },
  data() {
    return {
      url: "/api/announcements?include=user",
      filters: [
        {
          search_prop: "email",
          value: "",
        },
        {
          search_prop: "sms",
          value: "",
        },
        {
          search_prop: "group",
          value: "",
        },
      ],
      loading: false,
      apiData: null,
    };
  },
  computed: {
    records: (v) => _.get(v, "apiData.data", []),
    count: (v) => _.get(v, "apiData.meta.pagination.total", 0),
  },
  methods: {
    trimMessage(message) {
      let data = message.replace(/<\/?[^>]+>/gi, "");
      return data.length > 150 ? data.substring(0, 149) + "..." : data;
    },
    async loadData(query) {
      this.loading = true;
      const builder = buildQueryForVueDataTable(query);
      const url = this.url + "&" + builder.build();

      const response = await axios.get(url);
      this.apiData = response.data;
      this.loading = false;
    },
  },
  created() {
    $(() => {
      $('body').on('select2:select', '#team-group', ({ params: { data: { id: value } } }) => {
        this.filters[2].value = value
      })
    })
  },
};
</script>


<style scoped>
.dropdown-item {
  cursor: pointer;
}
</style>
