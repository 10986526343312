<template>
  <span>
    <h2>Custom Tags</h2>
    <table class="datatable table table-bordered display" id="tags_table" v-for="tag in tags" v-if="tag.type == 'custom'">
      <thead class="table-header">
        <tr>
          <th>Tag</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="a_tag in tag.tags">
          <td :id="'tag_' + a_tag.id">
            <span>
              {{ a_tag.name }}
            </span>
            <span style="display:none" >
              <input type="text" :name="'tag_edit_' + a_tag.id" :value="a_tag.name">
              <button v-on:click="save(a_tag.id)" class="btn btn-outline-secondary btn-sm waves-effect waves-light">Save</button>
            </span>
          </td>
          <td>
            <button v-on:click="edit(a_tag.id)" class="btn btn-info waves-effect waves-light">Edit</button>
            <button v-on:click="remove(a_tag.id)" class="btn btn-danger waves-effect waves-light">Delete</button>
          </td>
        </tr>
      </tbody>
    </table>
    <button v-on:click="create()" class="btn btn-primary waves-effect waves-light">New Tag</button>
    <div id="new_tag" style="display: none;">
      <input type="text" />
      <button v-on:click="store()" class="btn btn-primary waves-effect waves-light">Save</button>
    </div>
  </span>
</template>

<script>
  export default {
    name: 'TagView',
    data() {
      return {
        tags: [],
      }
    },
    mounted: function() {
      this.getTags();
    },
    methods: {
      getTags: function() {
        axios.get('/api/tags').then((response) => {
          this.tags = response.data;
        }).catch((error) => {
          console.log(error)
        })
      },
      edit: function(id) {
        $('#tag_' + id + '>span:first-child').hide()
        $('#tag_' + id + '>span:last-child').show()
      },
      save: function(id) {
        let updatedTagName = $('#tag_' + id + '>span:last-child>input').val()
        axios.put('/api/tags/' + id, { name: updatedTagName }).then((response) => {
          if (response.data.name === updatedTagName) {
            $('#tag_' + id + '>span:first-child').text(updatedTagName)
            $('#tag_' + id + '>span:first-child').show()
            $('#tag_' + id + '>span:last-child').hide()
          } else {
            toastr.error('Something went wrong and your tag could not be updated. Please try again.', '');
          }
        })
      },
      remove: function(id) {
        Swal.fire({
          title: 'Delete this tag?',
          text: "You cannot undo this action",
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete tag!',
          cancelButtonText: 'No, cancel!',
          confirmButtonClass: 'btn btn-confirm mt-2',
          cancelButtonClass: 'btn btn-cancel ml-2 mt-2',
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            axios.delete('api/tags/' + id).then((response) => {
          this.getTags()
        })
          } else if (result.dismiss === Swal.DismissReason.cancel) {}
        })
      },
      create: function() {
        $('#new_tag').show()
      },
      store: function() {
        let newTagName = $('#new_tag>input').val()
        axios.post('/api/tags', { name: newTagName, type: 'custom' }).then((response) => {
          this.getTags()
          $('#new_tag>input').val('')
          $('#new_tag').hide()
        })
      }
    }
  }
</script>
