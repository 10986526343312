<template>
  <form id="player-status-form" @submit.prevent="collegeSave()">
    <input
      hidden
      type="text"
      name="id"
      class="form-control"
      v-model="checked_player_id"
    />
    <input
      hidden
      type="text"
      name="organization"
      class="form-control"
      v-model="checked_organization"
    />
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>First Name <span style="color: red">*</span></label>
          <input
            type="text"
            name="first_name"
            class="form-control"
            v-model="first_name"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Last Name <span style="color: red">*</span></label>
          <input
            type="text"
            name="last_name"
            class="form-control"
            v-model="last_name"
            required
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            class="form-control"
            v-model="email"
            @keyup="checkEmail"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Cell Phone</label>
          <input
            type="tel"
            name="phone"
            class="form-control"
            v-model="phone"
          />
        </div>
      </div>
    </div>
    <div class="row" >
      <div class="col-md-6">
        <div class="form-group">
          <label>Position</label>
          <select
            name="positions[]"
            class="form-control select2"
            v-model="positions"
            v-select2
            multiple="multiple"
          >
            <option v-for="(position, key) in all_positions" :value="key">
              {{ position }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group grad-year">
          <label>Class</label>
          <select
            name="grad_year"
            class="select2 form-control grad-years"
            v-select2
            v-model="grad_year"
            required
          >
            <option :value="getYearWithCohort('SR')">SR</option>
            <option :value="getYearWithCohort('JR')">JR</option>
            <option :value="getYearWithCohort('SO')">SO</option>
            <option :value="getYearWithCohort('FR')">FR</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <button
          type="submit"
          class="btn btn-primary add-player-modal-btn"
          :disabled="loading"
        >{{ loading ? 'Saving...' : 'Save' }}</button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";

export default {
  name: "CheckPlayerStatus",
  props: {
    checked_player_id: {type: String, default: ''},
    checked_player_first_name: {type: String, default: ''},
    checked_player_last_name: {type: String, default: ''},
    checked_player_email: {type: String, default: ''},
    checked_player_phone: {type: String, default: ''},
    checked_player_positions: {type: String, default: ''},
    checked_player_grad_year: {type: String, default: ''},
    checked_organization: {type: String, default: ''},
  },

  data: function () {
    return {
      all_positions: window.signingDayConstants.user_positions,
      loading: true,
      player: {},
      profileResponse: null,
      i: 0,
      emailFormatter: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,

  };
  },

  computed: {
    first_name: {
      get() {
        return this.checked_player_first_name
      },
      set(newValue) {
        this.i++
        if (this.i > 100) {
          this.first_name = newValue
        }
      }
    },
    last_name: {
      get() {
        return this.checked_player_last_name
      },
      set(newValue) {
        this.i++
        if (this.i > 100) {
          this.last_name = newValue
        }
      }
    },
    email: {
      get() {
        return this.checked_player_email
      },
      set(newValue) {
        this.i++
        if (this.i > 100) {
          return this.email = newValue
        }
      }
    },
    phone: {
      get() {
        return this.checked_player_phone
      },
      set(newValue) {
        this.i++
        if (this.i > 100) {
          return this.phone = newValue
        }
      }
    },
    positions: {
      get() {
        return this.checked_player_positions.split(',')
      },
      set(newValue) {
        this.i++
        if (this.i > 100) {
          this.positions = newValue
        }
      }
    },
    grad_year: {
      get() {
        return this.checked_player_grad_year
      },
      set(newValue) {
        this.i++
        if (this.i > 100) {
          this.grad_year = newValue
        }
      }
    },
  },

  mounted() {
    $(".select2").select2();

    $("#player-status-button")
      .removeClass('d-none')
      .prop('disabled', this.email === '')
      .bind('click', this.checkStatus)

    this.loading = false;
  },

  methods: {
    createErrorTypeAlert(title, text) {
      Swal.fire({
        title: title,
        type: "error",
        html: text,
      });
    },
    getYearWithCohort(cohort) {
      switch (cohort) {
        case "SR":
          return new Date().getMonth() > 5
            ? parseInt(new Date().getFullYear()) + 1
            : parseInt(new Date().getFullYear());
          break;
        case "JR":
          return new Date().getMonth() > 5
            ? parseInt(new Date().getFullYear()) + 2
            : parseInt(new Date().getFullYear() + 1);
          break;
        case "SO":
          return new Date().getMonth() > 5
            ? parseInt(new Date().getFullYear()) + 3
            : parseInt(new Date().getFullYear() + 2);
          break;
        case "FR":
          return new Date().getMonth() > 5
            ? parseInt(new Date().getFullYear()) + 4
            : parseInt(new Date().getFullYear() + 3);
          break;
      }
    },
    collegeSave() {
      this.player = new FormData($('#player-status-form')[0]);
      this.loading = true;
      if (this.player.get('email') !== '') {
        axios
          .post("/api/college-prospects/check-profile-exists", this.player)
          .then((response) => {
            if (response.data.has_profile) {
              Swal.fire({
                title: "Could not update prospect",
                type: "error",
                html: `A unique profile already exists for this player. Please try again with a different email.`,
              });
              this.loading = false;
            } else {
              this.updateProspect(this.player)
            }
          })
      } else {
        this.updateProspect(this.player)
      }
    },
    updateProspect(player) {
      axios
        .post(
          "/api/organizations/" + this.checked_organization + "/college-prospects",
          player
        )
        .then((response) => {
            this.loading = false;
            window.location.reload();
            Swal.fire({
              title: "Prospect updated",
              type: "success",
              html: "Prospect has been successfully updated, " + player.get('first_name') + ' ' + player.get('last_name'),
            });
          }
        )
        .catch((error) => {
          let title = "Problem updating player";
          let text = `Please try again.`;
          this.createErrorTypeAlert(title, text);
          this.loading = false;
        });
    },
    checkStatus() {
      let currentEmail = $("input[name = 'email']")[0].value;
      let data = {
        email: currentEmail,
        id: this.checked_player_id
      };
      axios
        .post("/api/college-prospects/check-profile-exists", data)
        .then((response) => {
          if (!response.data?.has_profile) {
            Swal.fire({
              title: 'We could not find this athlete in our database.',
              type: "error",
              html: `Please try again with a different email.`,
            });
            this.loading = false;
          } else {
            this.loading = false;
            window.open("/recruit/" + response.data.recruit_id, '_blank');
            window.location.reload();
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "Problem updating player",
            type: "error",
            html: `Please try again.`,
          });
          this.loading = false;
        });
    },
    checkEmail() {
      let currentEmail = $("input[name = 'email']")[0].value;
      if (currentEmail.match(this.emailFormatter) !== null) {
        $("#player-status-button")
          .prop('disabled', false)
          .unbind()
          .bind('click', this.checkStatus)
      } else {
        $("#player-status-button").prop('disabled', true);
      }
    },
  },
};
</script>
<style scoped>
label {
  text-align: start !important;
}
input {
  text-transform: capitalize;
}

#player-status-button {
  border: 1px solid #2E2E2E;
  border-radius: 0;
  position: absolute;
  right: 20px;
  top: 1rem;
}
</style>
