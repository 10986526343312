export default () => {
    $.ajaxSetup({
        headers: {
            "X-CSRF-TOKEN": $("meta[name='csrf-token']").attr("content"),
        }
    });
}

window.addPlayerToProspects = async(data, position) => {
    $.ajax({
        url: `/roster/add/${data}/position/${position}`,
        type: "POST"
    }).done(d => {
        if (d.attached.length > 0) {
            toastr.success("Player added to prospects list", "Added Player");
        } else {
            toastr.error("Player could not be added to prospects list", "Error!");
        }
    });
}

window.exportData = async(data, fileName) => {
  /*
  * Make CSV downloadable
  */
  const downloadLink = document.createElement("a");
  const blob = new Blob([data], {type: 'text/csv'})
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = fileName;

  /*
  * Actually download CSV
  */
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}
