<template>
    <div>
    <template v-if="loading === true">
          <h5 style="color: rgb(255,255,255);text-align: center;">loading</h5>
    </template>
    <div v-for="activity in liveActivities" class="time-item">
        <div class="item-info live-activity">
            <small class="text-muted">{{activity.created_at}}</small>
            <p v-html="activity.activity"></p>
        </div>
    </div>
    <div v-if="alert" class="activity-alert" v-on:click="scrollDown">
        <i class="ti-alert"></i>
    </div>
</div>
</template>

<script>
    import Pusher from 'pusher-js'
    import axios from 'axios'

    export default {
      name: 'ActivityBar',
      props: ['organization_id', 'sport_id'],
      data() {
        const date = new Date()
        return {
          liveActivities: [],
          currentPage: 1,
          total_pages: 1,
          loading: true,
          alert: false,
          datetime: date.getUTCFullYear() + '-' + String(date.getUTCMonth()).padStart(2, 0) + '-' + String(date.getUTCDay()).padStart(2, 0) + ' ' + String(date.getUTCHours()).padStart(2, 0) +  ':' + String(date.getUTCMinutes()).padStart(2, 0) + ':' + String(date.getUTCSeconds()).padStart(2, 0)
        }
      },
      updated: function() {
        if ($('.right-bar')[0].scrollTop + $('.right-bar').innerHeight() + 200 >= $('.right-bar')[0].scrollHeight) {
          $('.right-bar').animate({
            scrollTop: $('.right-bar')[0].scrollHeight
          }, 1000);
          this.alert = false;
        }
      },
      mounted: function() {
        window.SDSPusher.getPusherInfo().then(() => {
          window.SDSPusher.createPusherChannel('/api/activities/authorize-pusher', `private-activities-${this.organization_id}`)
          window.SDSPusher.bindChannelEvent('new_activity', (res) => this.liveActivities.push(res.data.data))
        });
        axios.get('/api/activities?datetime=' + this.datetime).then(response => {
          this.loading = false;
          this.liveActivities = response.data.data.filter(activity => activity.sport_id == this.sport_id);
          this.total_pages = response.data.meta.pagination.total_pages;

          $('.right-bar').on('scroll', () => {
            if ($('.right-bar')[0].scrollTop === 0 && (this.currentPage + 1 < this.total_pages)) {
              this.loadMore()
            } else if ($('.right-bar')[0].scrollTop + $('.right-bar').innerHeight() + 200 >= $('.right-bar')[0].scrollHeight) {
              this.alert = false
            }
          })

          setTimeout(function() {$('.right-bar')[0].scrollTop = $('.right-bar')[0].scrollHeight}, 0);
        })
      },
      methods: {
        loadMore: function() {
          if (this.loading === false) {
            this.loading = true;
            const oldHeight = $('.right-bar')[0].scrollHeight;
            axios.get('/api/activities?page=' + (this.currentPage + 1) + '&datetime=' + this.datetime).then(response => {
              setTimeout(function() {$('.right-bar')[0].scrollTop = Math.abs($('.right-bar')[0].scrollHeight - oldHeight)}, 10);
              const updates = response.data.data.filter(activity => activity.sport_id == this.sport_id);
              this.loading = false;
              this.liveActivities = [...updates, ...this.liveActivities];
              this.currentPage++;
            });
          }
        },
        scrollDown: function() {
          $('.right-bar').animate({
            scrollTop: $('.right-bar')[0].scrollHeight
          }, 1000);
        }
      }
    }
</script>
