<template>
  <div class="row fields">
    <input type="hidden" :name="`infiputs[${props.type}Styles][${index}][type]`" v-model="data.type || props.type">
    <input type="hidden" :name="`infiputs[${props.type}Styles][${index}][sport]`" v-model="data.sport || props.sport">
    <div class="col-md-6">
      <div class="form-group">
        <div class="control-label">Position</div>
        <select :name="`infiputs[${props.type}Styles][${index}][position]`" v-model="data.position" class="form-control" required>
          <option value="" selected>...</option>
          <option v-for="(value, key) in props.positions" :value="key">{{ value }}</option>
        </select>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <div class="control-label">Style</div>
        <input type="text" :name="`infiputs[${props.type}Styles][${index}][style]`" v-model="data.style" class="form-control" required>
      </div>
    </div>
    <button type="button" class="btn btn-danger remove" @click="remove(index)">X</button>
  </div>
</template>

<script>
  export default {
    name: 'position-style',
    props: ['index', 'remove', 'data', 'props']
  }
</script>
