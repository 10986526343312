<template>
  <div class="modal fade" id="stats-search" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title d-inline">Stats Search</h4>
          <button v-if="!saved" class="btn btn-info btn-sm pull-right" @click="saveStatsSearchCookie">APPLY</button>
          <button v-else class="btn btn-danger btn-sm pull-right" @click="clearStatsSearchCookie">CLEAR</button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col">
              <h6>Refine your search further by searching player statistic ranges.</h6>
            </div>
          </div>
          <div class="row" style="padding: 15px">
            <div class="col-6">
              <h4>Batting</h4>
              <div class="row justify-content-between" style="margin-left: 72px; margin-right: 12px"><h6>min</h6><h6>max</h6></div>
              <div style="padding-right: 10px" class="row align-items-center justify-content-between" v-for="({abbrev, name, id: key, max, min, step, precision}) in statData.filter(({ type: t }) => t === 'B')">
                <span style="width: 45px; cursor: default" data-toggle="tooltip" data-placement="left" :title="name">{{abbrev}}:</span>
                <el-input-number :value="statRanges[key][0]" size="small" class="pull-left" @change="value => setRange(key, 0, value)"
                   :min="min" :max="max" :step="step" :precision="precision" :controls="false"
                />
                <el-input-number :value="statRanges[key][1]" size="small" class="pull-right" @change="value => setRange(key, 1, value)"
                   :min="min" :max="max" :step="step" :precision="precision" :controls="false"
                />
              </div>
            </div>
            <div class="col-6">
              <h4>Pitching</h4>
              <div class="row justify-content-between" style="margin-left: 72px; margin-right: 12px"><h6>min</h6><h6>max</h6></div>
              <div style="padding-right: 10px" class="row align-items-center justify-content-between" v-for="({abbrev, name, id: key, max, min, step, precision}) in statData.filter(({ type: t }) => t === 'P')">
                <span style="width: 45px; cursor: default" data-toggle="tooltip" data-placement="left" :title="name">{{abbrev}}:</span>
                <el-input-number :value="statRanges[key][0]" size="small" class="pull-left" @change="value => setRange(key, 0, value)"
                  :min="min" :max="max" :step="step" :precision="precision" :controls="false"
                />
                <el-input-number :value="statRanges[key][1]" size="small" class="pull-right" @change="value => setRange(key, 1, value)"
                  :min="min" :max="max" :step="step" :precision="precision" :controls="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import stats, { buildObservableObj } from '../../lib/baseball-stats'
import Vue from 'vue'
let sliderTimeout;

const initialObs = buildObservableObj()
const renderKeys = Vue.observable(initialObs?.keys)
const ranges = Vue.observable(initialObs?.ranges)

export default {
  name: 'StatsSearch',
  props: {},
  data() {
    return {
      saved: false,
      sliderKeys: renderKeys,
      statData: stats,
      statRanges: ranges,
    }
  },
  watch: {
    statRanges: {
      handler(val, old) {
        if (this.saved) {
          Cookies.expire('stats_search')
          this.saved = false
        }
      },
      deep: true
    }
  },
  methods: {
    setRange(key, index, value) {
      this.sliderKeys[key] += Math.random()
      this.statRanges[key][index] = value
      this.$nextTick(this.$forceUpdate())
    },
    getSearchedFields() {
      let data = {}
      Object.entries(this.statRanges)?.filter(([key, range]) => range[1] > 0)
        ?.forEach(([key, range]) => data[key] = range)
      return data
    },
    saveStatsSearchCookie() {
      this.$parent.setCookie('stats_search', this.getSearchedFields())
      this.$parent.statsSearch = true
      this.saved = true
      $('#stats-search').modal('hide')
    },
    clearStatsSearchCookie() {
      Cookies.expire('stats_search')
      $('#stats-search').modal('hide')
      Object.keys(this.statRanges)?.forEach(key => this.statRanges[key] = [0, 0])
      this.$parent.statsSearch = false
      this.saved = false
    }
  }
}
</script>
