<template>
  <span v-if="isFiltersValid"
  >
    <button class="btn waves-effect waves-light btn-danger reset-btn" v-if="filters.some(f => f.value !== '')" @click="resetFilters">Clear</button>

    <input-number-range 
      ref="num-athletes-search"
      title="#Athletes" 
      id="num_athletes_search" 
      data-filter="num_athletes" 
      :handleDataChange="handleDataChange"
    ></input-number-range>

    <date-picker 
      ref="date-created-search"
      title="Created" 
      id="date_created_search" 
      data-filter="date_created" 
      :handleDataChange="handleDataChange"
    ></date-picker>

    <date-picker 
      ref="date-edited-search"
      title="Edited" 
      id="date_edited_search" 
      data-filter="date_edited" 
      :handleDataChange="handleDataChange"
    ></date-picker>

    <input
      ref="full-name-search"
      id="full_name_search"
      v-model="full_name"
      class="form-control"
      placeholder="Search..."
      type="text"
      data-filter="full_name"
      @keyup="handleChange"
    />
  </span>
</template>

<script>
  import DatePicker from "./DatePicker";
  import InputNumberRange from "./InputNumberRange";

  export default {
    components: { DatePicker, InputNumberRange },

    props: {
      filters: {type: Array, default() {return []}, required: false},
    },

    data() {
  	  return {
  	  	positions: window.signingDayConstants['user_positions'],
        year: new Date().getFullYear(),
        roster_levels: window.signingDayConstants['roster_levels'],
        full_name: '',
        num_athletes: '',
        date_created: '',
        date_edited: '',
  	  }
    },

    computed: {
      isFiltersValid(){
        return (this.filters.length > 0);
      },
    },

    methods: {
      handleChange(data){
        this.handleDataChange(this.full_name, 'full_name_search');
      },
      handleDataChange(data, id) {
        const event = {
          filterKey: id,
          data: {
            dateRange: data
          },
        };

        this.handleFilterChange(event);
      },
      async handleFilterChange(event) {
        window.EventBus.publish('sds_handleFilterChange', {event: event});
      },
      resetFilterInputs(filters){
        // get filter values
        this.full_name = filters[0].value;
        this.num_athletes = filters[1].value;
        this.date_created = filters[2].value;
        this.date_edited = filters[3].value;

        // update name search
        this.$refs['full-name-search'].value = (this.full_name.length > 0) ? this.full_name : ''
        // update number athletes search
        this.$refs['num-athletes-search'].minVal = (this.num_athletes[0]) ? this.num_athletes[0] : 0
        this.$refs['num-athletes-search'].maxVal = (this.num_athletes[1]) ? this.num_athletes[1] : 999
        this.$refs['num-athletes-search'].maxDisable = (this.num_athletes[0]) ? false : true

        // update date created and edited searches
        this.$refs['date-created-search'].dateRange = this.date_created
        this.$refs['date-edited-search'].dateRange = this.date_edited
      },
    	resetFilters(){
        window.EventBus.publish('sds_resetFilters', {});
    	},
      registerListeners() {
        $('body').on('select2:select', '#player_position, #grad_year_search, #roster_level_search', this.handleFilterChange)
      },
    },
    
	  created() {
	    $(() => {
        this.registerListeners()
	    });

      window.EventBus.subscribe('sds_resetFilterInputs', (filters) => {
        this.resetFilterInputs(filters);
      });
	  },
  }
</script>

<style scoped>
  .reset-btn {
    font-family: KairosSans,serif;
    text-transform: uppercase;
  }
</style>
