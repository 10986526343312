
<template>
  <div id="film-room" class="noselect">
    <!--     <a class="nav-link waves-effect waves-light toggle-side-menu">
            <i class="fa fa-plus"></i>
        </a> -->
    <div class="make-selection" v-if="active.length <= 0">Please select a player for review.</div>
    <div :class="{'row players film-room-card-container': true, 'overlaid': isOverlaid}" :style="`width: ${this.size}%`">
      <div class="col-md-12 m-b-20" v-if="common.length > 0 && active.length > 1">
        <div class="input-group common-scripts">
          <button class="no-text-wrap toggle-script" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ script ? script : common[0] }} <i class=" mdi mdi-chevron-down"></i></button>
          <div class="dropdown-menu">
            <a v-for="(film, index) in common" @click="changeScript(film)" :class="{'dropdown-item': true, 'active': film == script}">{{ film }}</a>
          </div>
        </div>
      </div>
      <player v-for="player in active" :key="player.id" :player="player" :toggle="toggle" :overlaid="isOverlaid" :playing="(status) => playing(status)" :count="active.length" :changeScript="(script) => changeScript(script)" :selectedPosition="current.position" />
    </div>
    <div class="collapse-column" data-activate="body"></div>
    <div class="player-list">
      <div class="position row sorting-btns">
        <div class="btn-group">
          <button type="button" class="dropdown-toggle gray-back" data-toggle="dropdown" aria-expanded="false">{{ positions[current.position] }} <span class="caret"></span></button>
          <div class="dropdown-menu">
            <a v-for="(position, key) in positions" @click="change('position', key)">{{ position }}</a>
          </div>
        </div>
        <div class="btn-group">
          <div id="grad_year_select" v-if="!high_school">
            <div class="btn-group">
              <multiselect
                v-if="organizationType !== 'club'"
                v-model="grad_year"
                :options="years"
                :multiple="true"
                :close-on-select="false"
                :preserve-search="true"
                placeholder="Select Year(s)"
                :searchable="false"
                :show-labels="true"
                selectedLabel=""
                selectLabel=""
                deselectLabel=""
                @close="change('grad_year', grad_year)"
              >
                            <!-- <template slot="beforeList" @click="handleSelect">
                    <li class="multiselect__element"><span data-select data-selected data-deselect class="multiselect__option"><span>ALL YEARS</span></span> </li>
                </template> -->
                <template slot="tag">{{ '' }}</template>

                <template slot="selection" slot-scope="{ values, search, isOpen }">

                  <!-- dropdown placeholder text with one or more options selected -->
                  <span style="text-transform: uppercase;" v-if="values.length > 0">
                    {{ values.length }} Year<span v-if="values.length > 1">s</span> Selected
                  </span>

                  <!-- dropdown placeholder default text (no options selected) -->
                  <span style="text-transform: uppercase;" v-if="isOpen && values.length < 1">
                    Select Year(s)
                  </span>

                  <!-- selection actions buttons for accept and clear selected options -->
                  <span class="gray-back select_action_btn" v-if="isOpen && values.length >= 0">
                    <i class="fa fa-lg fa-check" aria-hidden="true" @click="confirmSelectedYears"></i>
                  </span>
                  <span class="gray-back select_action_btn" v-if="isOpen && values.length > 0">
                    <i class="fa fa-lg fa-trash-o" aria-hidden="true" @click="clearSelectedYears" ></i>
                  </span>

                </template>
                <template slot="caret">{{ '' }}</template>
              </multiselect>
              <multiselect v-else
                v-model="team_levels"
                label="name"
                track-by="id"
                :options="mappedLevels"
                :multiple="true"
                :close-on-select="false"
                :preserve-search="true"
                placeholder="Team Level(s)"
                :searchable="false"
                :show-labels="true"
                selectedLabel=""
                selectLabel=""
                deselectLabel=""
                @close="change('team_levels', team_levels)"
              >

              <template slot="tag">{{ '' }}</template>

                <template slot="selection" slot-scope="{ values, isOpen }">

                  <!-- dropdown placeholder text with one or more options selected -->
                  <span style="text-transform: uppercase;" v-if="values.length > 0">
                    {{ values.length }} Level<span v-if="values.length > 1">s</span> Selected
                  </span>

                  <!-- dropdown placeholder default text (no options selected) -->
                  <span style="text-transform: uppercase;" v-if="isOpen && values.length < 1">
                    Team Level(s)
                  </span>

                  <!-- selection actions buttons for accept and clear selected options -->
                  <span class="gray-back select_action_btn" v-if="isOpen && values.length >= 0">
                    <i class="fa fa-lg fa-check" aria-hidden="true" @click="confirmSelectedYears"></i>
                  </span>
                  <span class="gray-back select_action_btn" v-if="isOpen && values.length > 0">
                    <i class="fa fa-lg fa-trash-o" aria-hidden="true" @click="clearSelectedTeamLevels" ></i>
                  </span>

                </template>
                <template slot="caret">{{ '' }}</template>


              </multiselect>
            </div>
          </div>
          <span v-else>
              <button type="button" class="dropdown-toggle dark-gray-back" data-toggle="dropdown" aria-expanded="false">{{ ! high_school ? current.grad_year : getCohortWithCurrentYear(current.grad_year) }} <span class="caret"></span></button>
              <div class="dropdown-menu">
                <a @click="change('grad_year', getYearWithCohort('SR'))">SR</a>
                <a @click="change('grad_year', getYearWithCohort('JR'))">JR</a>
                <a @click="change('grad_year', getYearWithCohort('SO'))">SO</a>
                <a @click="change('grad_year', getYearWithCohort('FR'))">FR</a>
              </div>
          </span>
        </div>

        <div class="btn-group" v-if="active.length > 0">
          <button type="button" class="gray-back" @click=" clearActivePlayers"><i class="mdi mdi-close"></i></button>
        </div>
      </div>
      <div v-if="! loading">
        <transition-group>
          <player-list v-for="(player, index) in players" :key="player.id" :index="index" :player="player" :toggle="toggle" :active="includes(player, active, true)" :position="current.position" v-on:notes_click="displayNotes(player.id)" />
        </transition-group>
        <div v-if="players.length == 0" class="player" v-cloak><p>No players available for this position and year, add players to your <span v-if="! high_school">prospects</span><span v-else>roster</span> first.</p>
          <a href="/recruit" class="btn btn-primary">View <span v-if="! high_school">Recruits</span><span v-else>Roster</span></a>
        </div>
      </div>
      <div v-else>
        <div class="loading">
          <span>
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div><br>
            Loading...
          </span>
        </div>
      </div>
      <div class="unlisted" v-if="unlisted.length > 0">
        <h4>For Comparison</h4>
        <player-list v-for="(player, index) in unlisted" :key="player.id" :index="false" :player="player" :toggle="toggle" :active="includes(player, active, true)" :position="current.position" v-on:notes_click="displayNotes(player.id)" />
      </div>
    </div>
    <div class="global-controls input-group" v-show="active.length && activeHasFilm()">
      <div class="btn-group">
        <button class="btn btn-primary"  style="font-size: 14px; border-bottom-left-radius: 0" @click="stop" data-toggle="tooltip" data-placement="top" title="Stop"><i class="mdi mdi-stop"></i></button>
        <button class="btn btn-primary"  style="font-size: 14px" @click="play"><i v-if="isPlaying" class="ion-pause"></i><i v-else class="ion-play"></i></button>
        <button class="btn btn-primary" style="font-size: 14px"  @click="restart" data-toggle="tooltip" data-placement="top" title="Restart"><i class="mdi mdi-restart"></i></button>
        <button class="btn btn-primary" style="font-size: 14px"  @click="slow">{{ isSlow ? 'Normal' : 'Slow' }}</button>
        <button v-show="active.length > 1 && allActiveHasFilm()" class="btn btn-primary" @click="overlay">{{ isOverlaid ? 'Side by Side' : 'Overlay' }}</button>
        <button class="btn btn-primary" style="font-size: 16px" @click="resize(size - 5)"><i class="fa fa-search-minus"></i></button>
        <button class="btn btn-primary" style="font-size: 16px; border-bottom-right-radius: 0" @click="resize(+size + +5)"><i class="fa fa-search-plus"></i></button>
      </div>

      <button v-if="active.length > 1" @click="linked = ! linked" :class="{ 'link': true, 'linked': linked }"><i :class="{mdi: true, 'mdi-link': ! linked, 'mdi-link-off': linked}"></i></button>
      <button v-else @click="linked = ! linked" :class="{ 'link': true, 'linked': linked }"><i :class="{mdi: true, 'mdi-link': ! linked, 'mdi-link-off': linked}"></i></button>

      <div v-if="linked" class="seek">
        <div class="gradient"></div>
      </div>
      <div v-if="! linked && active.length > 1" class="seek" v-for="player in active" :data-player="player.id">
        <div class="gradient"></div>
      </div>
      <div v-if="! linked && active.length == 1" class="seek" v-for="side in ['left', 'right']" :data-side="side">
        <div class="gradient"></div>
      </div>
    </div>

    <!-- Notes Modal -->
    <div class="modal fade" id="notesModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Notes for {{ selectedPlayerName }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" id="notes">
            <notes type="User" :id="this.selectedPlayerId" :key="this.selectedPlayerId" :organizationid="this.organizationid" :authuser="this.authuser" alertableid="0" :player="this.player" ref="notes" embed_refs="film_room"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
#grad_year_select {
  cursor: pointer;
  padding: 8px 0px 0 8px;
  font-size: 14px;
  display: inline-block;
  color: #fff;
}
.multiselect__tags::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}
.multiselect__single {
  text-transform: uppercase;
  font-size: 14px;
  color: #fff;
  background: transparent;
  padding-right: 20px;
}
.multiselect__placeholder {
  text-transform: uppercase;
  font-size: 14px;
  color: #fff;
  padding-top: 0px;
}
.multiselect {
  font-size: 14px;
  color: #aaa;
}
.multiselect__tags {
  padding: 8px 0px 0 8px;
  background: transparent;
  border: none;
  color: #fff;
}
.multiselect__content {
  background-color: #333;
  border: none;
}
.multiselect__option--highlight {
  background-color: white;
  color: black
}
.multiselect__content-wrapper {
  position: absolute;
  border: none;
  border-radius: 0;
  left: -155px;
  float: left;
  width: 345px;
}
.multiselect__option--highlight {
  background-color: #282828;
  color: #fff;
}
.multiselect__option--highlight.multiselect__option--selected {
  background-color: #282828;
}
.multiselect__option--selected {
  background-color: #282828;
  color: white;
}

.multiselect__option--highlight:after {
  content: "\f067"; /* plus_sign icon */
  font-family: FontAwesome;
}
.multiselect__option--selected:after {
  content: "\f00c";  /* check_mark icon */
  font-family: FontAwesome;
}

.multiselect__option--highlight.multiselect__option--selected:after {
  content: "\f00d";  /* x_mark icon */
  font-family: FontAwesome;
  font-size: larger
}
.select_action_btn .fa-trash-o {
  color: red;
  font-size: larger;
}
.select_action_btn .fa-check {
  color: green;
  font-size: larger;
}
</style>

<script>
import Player from './Player'
import Multiselect from 'vue-multiselect'

export default {
  name: 'FilmRoom',
  props: {
    positions: Object,
    years: Array,
    levels: Object,
    authuser: String,
    organizationid: String,
    organizationType: String,
    high_school: Boolean
  },
  components: { Player, Multiselect },

  data() {
    return {
      _mounted: false,
      loading: false,

      players: [],
      active: [],
      unlisted: [],
      common: [],
      startTimes: [],
      script: false,
      player: {},
      size: 100,

      current: {
        position: Cookies.get('advanced_search') != null && JSON.parse(Cookies.get('advanced_search'))['position'] !== '' ? JSON.parse(Cookies.get('advanced_search'))['position'] : Object.keys(this.positions)[0],
        grad_year: Cookies.get('advanced_search') != null && JSON.parse(Cookies.get('advanced_search'))['grad_year'] !== '' ? JSON.parse(Cookies.get('advanced_search'))['grad_year'] : new Date().getFullYear(),
      },

      isMobile: true,
      drag: false,
      seeker: false,
      toSeek: false,
      linked: false,
      isSeeking: false,
      isPlaying: false,
      isSlow: false,
      isOverlaid: false,
      seekStart: 0,
      startingPosition: 0,
      selectedPlayerId: 0,
      selectedPlayerName: 'Unknown',
      grad_year: [],
      team_levels: [],
      current_request: 0,
    }
  },

  mounted: function() {
    this.isMobile = jQuery.browser.mobile

    // Calculate the starting size
    if (Cookies.get('film_room_size') != null && Cookies.get('film_room_size') !== '') {
      this.size = Cookies.get('film_room_size')
    } else {
      let width = $('.content-page').width() - 90
      let height = $(window).height() - 180

      let percentage = (height/width * 100).toFixed(0)

      this.size = 5 * Math.round(percentage/5)
      Cookies.set('film_room_size', this.size, { expires: Infinity })
    }

    this.current['grad_year'] = this?.years

    // Get all players
    this.getPlayers()

    // Handle Seek
    let self = this
    $('body').on('mousedown', '.seek', function(e) {
      let event = e
      self.startTimes = []
      self.seeker = $(this)
      self.toSeek = self.setToSeek()

      self.toSeek.each((i, e) => {
        self.startTimes.push(e.currentTime)
      })
      self.seekStart = event.pageX
      self.isSeeking = true
    })
    $(document).on('mouseup', (e) => {
      if (self.isSeeking) {
        self.isSeeking = false
      }
    })
    $(document).on('mousemove', (e) => {
      if (self.isSeeking) {
        self.seek(e.pageX)
      }
    })

    // Handle Player List Collapse
    $('body').on('mousedown', '.collapse-column', function(e) {
      let current = 'not';
    })
  },
  computed: {
      mappedLevels() {
          return Object.entries(this.levels).map(([id, name]) => ({id, name}))
      }
  },
  methods: {
    /**
     * All encompassing inclusion methods
     * Can be used as es5 `Array.inArray` or es2015 `includes`
     *
     * @param  object|string|int  needle    This is the value to search for
     * @param  array              haystack  The array to check in
     * @param  boolean            objects   Whether to check for the id of an object or not
     * @return boolean
     */
    includes(needle, haystack, objects = false) {
      for (let i = 0; i < haystack.length; i++) {
        if ((! objects && needle == haystack[i]) || (objects && needle.id == haystack[i].id)) {
          return true
        }
      }
      return false
    },

    handleSelect(event) {
      if (parseInt(event.value) === 0) {

      }
    },

    /**
     * Get the list of players for selected position and year
     */
    async getPlayers() {

      if(this.current['grad_year'] === undefined || this.current['grad_year'].length === 0) {
        this.current['grad_year'] = this?.years
      } else {
        this.current['team_levels'] = (typeof this?.levels === 'object') ? [] : this?.levels;
      }

      if (this.organizationType === "club") {
        delete this.current?.grad_year
      }

      this.loading = true
      this.current.search_token = Math.floor(Date.now()/3);
      await axios.get('api/film-room?', {params: this.current}).
      then(response => {
        // if return search_token equals the current.search_token, then process data for display
        // else ignore response
        if(this.current.search_token === parseInt(response.data['search_token'])){
          this.players = response?.data['players']?.data || []
          this.loading = false
        }
      }).then(() => {
        if (! this._mounted) {
          this.getComparisons()
        }
        this._mounted = true
      }).catch(error => {
        toastr.warning('We had an issue getting players for those parameters, please try again.', '', {positionClass: "toast-bottom-right"})
      })
    },

    /**
     * Change the ID for notes to display notes for the selected player
     */
    displayNotes(player) {
      let thisPlayer = this.players.find(o => o.id === player)
      if (thisPlayer == undefined) {
        thisPlayer = this.unlisted.find(o => o.id === player)
      }
      this.selectedPlayerId = player
      this.player = thisPlayer
      this.selectedPlayerName = thisPlayer.first_name + ' ' + thisPlayer.last_name
    },

    /**
     * Check to see if there are user defined comparisons in the request
     */
    getComparisons() {
      const urlParams = new URLSearchParams(window.location.search)
      const compare = urlParams.get('compare')
      if (compare) {
        let ids = compare.split(',').slice(0, 2)
        ids.map((id) => {
          axios.get(`api/players/${id}`).then(response => {
            let player = response.data.data
            this.active.push(player)
            if (! this.includes(player, this.players, true)) {
              this.unlisted.push(player)
            }
            $('[data-toggle="tooltip"]').tooltip()
          }).catch(error => {
            toastr.warning('We had an issue getting players for those parameters, please try again.', '', {positionClass: "toast-bottom-right"})
          })
        })
      }
    },

    /**
     * Set what elemnts should be seekable
     */
    setToSeek() {
      if (this.linked) {
        return $('.film video:visible')
      }
      if (this.active.length == 1) {
        return $('.film-' + this.seeker.data('side') + ' video:visible')
      } else {
        return $('#player-' + this.seeker.data('player') + ' .film video:visible')
      }
    },

    /**
     * Update Rank
     */
    async updateRank(drag) {
      this.drag = drag

      if (this.drag === false) {
        let data = this.players.map((player, index) => {
          this.players[index].position_rank = index + 1
          return {id: player.id, rank: index + 1}
        })
        await axios.patch('/api/players/rank', {'players': data, 'position': this.current.position}).then(response => {
          toastr.success('Player ranks updated successfully', '', {positionClass: "toast-bottom-right"})
          this.getPlayers()
        }).catch(error => {
          toastr.warning('We had an issue updating the player rank, please try again.', '', {positionClass: "toast-bottom-right"})
        })
      }
    },

    /**
     * Set the status of isPlaying
     */
    playing(status) {
      this.isPlaying = status
    },

    /**
     * Toggle a state paramter in players list
     */
    async change(parameter, value) {
      if (value == 'anyyear') {
        value = this.years
      }

      switch (parameter) {
        case 'team_levels':
          this.current[parameter] = value.map(({ id }) => id); break
        default:
          this.current[parameter] = value; break
      }

      await this.getPlayers()

      /* Ignore cookie if changing grad_year */
      if (parameter != 'grad_year') {
        let advanced_search = {}
        if (! Cookies.get('advanced_search')) {
          Cookies.set('advanced_search', "{}", { expires: Infinity })
        }
        advanced_search = JSON.parse(Cookies.get('advanced_search'))
        advanced_search[parameter] = value
        advanced_search = JSON.stringify(advanced_search)
        Cookies.set('advanced_search', advanced_search, { expires: Infinity })
      }
    },

    /**
     * Reset Multiselected years
     */
    clearSelectedYears() {
      this.change('grad_year', this.grad_year=[]);
    },

    /**
     * Reset Multiselected Team Levels
     */
    clearSelectedTeamLevels() {
      this.change('team_levels', this.team_levels=[]);
    },

    /**
     * Closes the vue-multiselect component and triggers the @close="change()" method
     */
    confirmSelectedYears (event) {
      // note: this is a workaround to close the vue-multiselect component by clicking the confirm button
      // if the user clicks the confirm selection button,
      // removes focus from any focused element (i.e the vue-multiselect component)
      // setting off the onBlur event of element, thus triggering the close method call for component
      // that initiates the change() method
      if (document.activeElement) {
        document.activeElement.blur();
      }
    },

    /**
     * Check if active players have any film
     */
    activeHasFilm() {
      for (let player in this.active) {
        if (this.active[player].footage.film.length > 0) {
          return true
        }
      }
      return false
    },

    allActiveHasFilm() {
      for (let player in this.active) {
        if (this.active[player].footage.film.length == 0) {
          return false
        }
      }
      return true
    },

    /**
     * Hide or show players for comparison
     */
    toggle(player) {
      this.stop()

      if (this.includes(player, this.active, true)) {
        this.active.filter((active, index) => {
          if (active.id == player.id) {
            this.active.splice(index, 1)
          }
        })
        if (this.active.length <= 1) {
          this.isOverlaid = false
        }

        return
      }

      if (this.active.length >= 2) {
        toastr.warning('Only two players may be viewed at a time.<br>Please remove a player first.', '')

        return
      }

      if (this.active.length == 1) {
        let hashset = this.active[0].footage.film.length > 0
          ? this.active[0].footage.film.map((film) => {
            if (film?.owner?.position === this.current.position || (this.current.position === 'P' && film?.attribute_name?.includes('pitch'))) {
              return (!film?.owner?.name ? film?.attribute_name : film?.owner?.name)
            }})
          : []
        let comparison = player.footage.film.length > 0
          ? player.footage.film.filter((film) => {
            if (film?.owner?.position === this.current.position || (this.current.position === 'P' && film?.attribute_name?.includes('pitch'))) {
              return this.includes((!film?.owner?.name ? film?.attribute_name : film?.owner?.name), hashset)
            }})
          : []
        this.common = comparison.length > 0
          ? comparison.map((film) => {
            return film?.attribute_name?.includes('pitch') ? film?.attribute_name?.replace(/sb_|_pitch_video/g, '').replaceAll('_', ' ') : (!film?.owner?.name ? film?.attribute_name : film?.owner?.name)
          })
          : []

        if (this.common == 0) {
          toastr.warning('These players do not have any scripts in common and and cannot be compared.', '')
        } else {
          this.active.push(player)
          this.changeScript(this.common[0])
        }

      } else {
        this.active.push(player)
      }
      $('[data-toggle="tooltip"]').tooltip()
    },

    /**
     * Change the common script
     */
    changeScript(script) {
      this.script = script
      this.$root.$emit('activateFilm', script)
    },

    /**
     * Clear all active players
     */
    clearActivePlayers() {
      this.active = []
    },

    /**
     * Manages the play and pause of vidisble videos
     */
    play() {
      let self = this
      $('.film video:visible').each((i, e) => {
        if (! self.isPlaying) {
          e.play()
          return
        }

        e.pause()
      })
      this.isPlaying = ! this.isPlaying
    },

    /**
     * Stop all visible videos
     */
    stop() {
      let self = this
      $('.film video').each((i, e) => {
        e.pause()
        self.isPlaying = false

        let $start = $(e).parent().next('.start-time').find('input')
        let start = $start[0] ? $start.val() : 0
        videojs(e).currentTime(start)
      })
    },

    /**
     * Restart all videos
     */
    restart() {
      this.stop()
      this.play()
    },

    /**
     * Slow all videos
     */
    slow() {
      let self = this
      $('.film video').each((i, e) => {
        if (! self.isSlow) {
          e.playbackRate = 0.07
          $('.slow').text('normal')

          return
        }

        e.playbackRate = 1
        $('.slow').text('slow')
      })
      this.isSlow = ! this.isSlow
    },

    /**
     * Resize the container element
     */
    resize(size) {
      this.size = size
      Cookies.set('film_room_size', size, { expires: Infinity })
    },

    /**
     * Set toggle overlaid
     */
    overlay() {
      this.isOverlaid = ! this.isOverlaid
    },

    /**
     * Scrub all videos at once
     */
    seek(x) {
      let self = this

      self.toSeek.each((i, e) => {
        if (! self.isPlaying) {
          e.pause()
          self.isPlaying = false
        }
        let diff = (x - self.seekStart) / (self.isSlow ? 1000 : 100)
        let offset = (Math.sign(diff) === -1)
          ? (this.startTimes[i] + Math.abs(diff))
          : (this.startTimes[i] - Math.abs(diff))

        e.currentTime = offset >= e.duration ? e.duration - 0.001 : offset
        self.seeker.css({ backgroundPosition: diff * (self.isSlow ? 1000 : 100) })
      })
    },

    getCohortWithCurrentYear(year) {
      let date = (new Date).getMonth() > 5
        ? (parseInt((new Date).getFullYear()) + 1)
        : parseInt((new Date).getFullYear())

      switch (year) {
        case date:
          return 'SR';
          break;
        case date + 1:
          return 'JR';
          break;
        case date + 2:
          return 'SO';
          break;
        case date + 3:
          return 'FR';
          break;
      }

      if (year > date + 4) {
        return 'FR';
      } else {
        return 'SR';
      }
    },

    getYearWithCohort(cohort) {
      switch (cohort) {
        case 'SR':
          return (new Date).getMonth() > 5
            ? (parseInt((new Date).getFullYear()) + 1)
            : parseInt((new Date).getFullYear())
          break;
        case 'JR':
          return (new Date).getMonth() > 5
            ? (parseInt((new Date).getFullYear()) + 2)
            : parseInt((new Date).getFullYear() + 1)
          break;
        case 'SO':
          return (new Date).getMonth() > 5
            ? (parseInt((new Date).getFullYear()) + 3)
            : parseInt((new Date).getFullYear() + 2)
          break;
        case 'FR':
          return (new Date).getMonth() > 5
            ? (parseInt((new Date).getFullYear()) + 4)
            : parseInt((new Date).getFullYear() + 3)
          break;
      }
    }
  }
}
</script>
