<template>
  <div :class="`player-stat-tile ${video ? 'player-stat-tile__has-video' : ''}`" :key="video">
    <img
      v-if="thumbnail"
      :src="thumbnail"
      alt="Thumbnail image"
      @error="handleThumbnailLoadingError"
      class="player-stat-tile-thumbnail"
    />
    <div class="player-stat-tile-text-container">
      <IconButton v-if="video" class-name="video-player-button" title="Video Player Button" @onClick="handlePlayVideo(video)">
        <component :is="evaluateIcon"></component>
      </IconButton>
      <component :is="evaluateIcon" v-else></component>
      <h4 :class="`stat-value ${thumbnail ? 'light' : ''}`">{{ statValue }}</h4>
      <span :class="`stat-name ${thumbnail ? 'light' : ''}`">{{ statName }}</span>
    </div>
  </div>
</template>

<script>
import IconButton from "@components/IconButton";
import CircleXIcon from '@components/svg/CircleXIcon';
import CircleCheckMarkBlack from '@components/svg/CircleCheckMarkBlack';
import YellowVideoCameraIcon from '@components/svg/YellowVideoCameraIcon';

export default {
  name: "PlayerStatTile",
  components: { IconButton, CircleXIcon, CircleCheckMarkBlack, YellowVideoCameraIcon },
  inject: ['handleToggleModal', 'isModalOpen'],
  props: {
    statName: {
      type: String,
      default: null,
      required: false
    },
    statValue: {
      type: String,
      default: 'N/A',
      required: false
    },
    thumbnailImage: {
      type: String,
      default: null,
      required: false
    },
    video: {
      type: String,
      default: null,
      required: false
    },
    statQuestion: {
      type: String,
      default: null,
      required: false
    }
  },
  data() {
    return {
      CircleXIcon,
      CircleCheckMarkBlack,
      YellowVideoCameraIcon,
      selectedVideo: null,
      thumbnail: this.thumbnailImage
    }
  },
  computed: {
    evaluateIcon() {
      switch (true) {
        case this.video != null:
          return YellowVideoCameraIcon;
        case this.statValue === '':
          return CircleXIcon;
        case this.statValue !== 'N/A':
          return CircleCheckMarkBlack;
        default:
          return CircleXIcon;
      }
    },
  },
  methods: {
    handlePlayVideo(video) {
      this.handleToggleModal(video, this.statQuestion);
    },
    handleThumbnailLoadingError(error) {
      console.log(error);
      this.thumbnail = null;
    }
  },
}
</script>
