<template>
  <div class="prospectlist-comment">
    <h4>Comments</h4>
    <div class="card">
      <div class="card-body">
        <p class="card-text show-white-space">{{comments}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      comments: {type: String, default() {return null}, required: false},
    },
  }
</script>

<style scoped>
  .show-white-space {
      white-space: pre-wrap;
  }
</style>
