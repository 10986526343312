<template>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <textarea 
          v-model="input_data.comments"
          class="form-control player-add" 
          id="prospectlist-athlete-comment-input" 
          rows="3"
          placeholder="Add Comments" 
          maxlength="240"
        ></textarea>
      </div>
    </div>
    <div class="col-12">
      <button class="pull-right btn btn-primary" @click="handleChange">Add Comments</button>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['player_id', 'player_comments'],

    data() {
        return {
          input_data: {
            comments: ''
          },
        }
    },

    methods: {
      handleChange() {
        window.EventBus.publish('sds_handlePlayerCommentsChange', {
          player_id: this.player_id,
          input_data: this.input_data,
        });

        $("#dynamic-component").modal("hide");
      },
      handleSetPlayerInputData(data){
        this.input_data.comments = data.comments;
      },
    },
    
    created() {
      window.EventBus.subscribe('sds_handleSetPlayerInputData'+this.player_id, (data) => {
        this.handleSetPlayerInputData(data);
      });
    },
  }
</script>
