<template>
  <button
    :class="`pill-button ${className}`"
    @click="$emit('onClick')"
  >
    <component v-if="startIcon" :is="startIcon"></component>
    {{ buttonText }}
    <component v-if="endIcon" :is="endIcon"></component>
  </button>
</template>

<script>
export default {
  name: "PillButton",
  props: {
    buttonText: {
      type: String,
      default: "",
      required: true
    },
    className: {
      type: String,
      default: "",
      required: false
    },
    startIcon: {
      type: Object,
      default: null,
    },
    endIcon: {
      type: Object,
      default: null,
    },
  }
}
</script>
