<template>
  <div :class="isOpen ? 'blade-modal open-modal ' : 'blade-modal'">
    <div :class="isOpen ? 'right-card open-right-card ' : 'right-card'">
      <button class="exit-button" @click="closeModal()">
        <img alt="" src="/build/images/icon_x.svg">
      </button>
      <div class="right-card-content">
        <form id="file-input-form" action="" enctype="multipart/form-data">
          <div class="first-section">
            <h4>Import Invite List</h4>
            <p>Upload your spreadsheets from camps, visits or your own custom spreadsheet. Once your players are uploaded and invited, you can easily connect with them, add them to your planning boards and view their verified content.</p>
          </div>
          <div class="second-section">
            <h5>Only Excel (.xls, .xlsx) and Comma Separated files (.csv) are supported. </h5>
            <p>Please make sure your spreadsheet’s first row has a name of the columns like “First Name”, “Last Name”, “Email”, etc.</p>
          </div>
          <label class="import-button btn btn-primary waves-effect waves-light">
            <span>Import List &nbsp;</span>
            <img alt="" src="/build/images/icon_import.svg">
            <input ref="file" accept=".xls, .xlsx, .csv" name="file" type="file" @change="previewFile">
          </label>
          <div v-if="fileIsOpen" class="file-exists-div">
            <div class="file-input-div">
              <input :value="file.name" class="file-input" disabled type="text">
              <button @click="resetFile">
                <img alt="" src="/build/images/icon_x_small.svg">
              </button>
            </div>
            <span>Invite List Name (ex. 2024 Quarterbacks)</span>
            <input :placeholder="parsedString(file.name)" class="parsed-input" name="name" type="text">
            <div class="listSummaryDiv">
              <span>List Summary</span>
              <span>{{ message.length }}/100</span>
            </div>
            <textarea v-model="message" class="parsed-input" maxlength="100" name="summary" placeholder="Type a description of this list" rows="4"></textarea>
            <div class="save-items-div">
              <Button :end-icon="saveIcon" @onClick="uploadFile(false)">Save List</Button>
              <Button :end-icon="emailIcon" @onClick="uploadFile(true)">Save & Send Invites</Button>
            </div>
          </div>
        </form>
        <CsvErrorModal
          :isOpen="isModalOpen"
          :onClose="toggleModal"
          :fileName="this.file?.name"
          v-bind="{errors, csvErrors}"
        />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Button from '@components/Button';
import SaveIcon from '@components/svg/SaveIcon';
import EmailIcon from '@components/svg/EmailIcon';
import CsvErrorModal from '@screens/myinvites/components/CsvErrorModal';

export default {
  name: "RightBladeUpload",
  components: {Button, SaveIcon, EmailIcon, CsvErrorModal},
  props: {
    closeModal: {
      type: function () {
      }
    },
    retrieveInvites: {
      type: function () {
      }
    },
    isOpen: {type: Boolean, default: false},
  },
  data() {
    return {
      file: null,
      fileIsOpen: false,
      message: '',
      errors: [],
      saveIcon: SaveIcon,
      emailIcon: EmailIcon,
      csvErrors: '',
      isModalOpen: false,
    }
  },
  methods: {
    toggleModal() {
      this.isModalOpen = !this.isModalOpen;
    },
    previewFile(event) {
      this.file = event.target.files[0]
      this.fileIsOpen = true
    },
    parsedString(string) {
      return string.match("^[^.]*").toString().replaceAll(/[_-]/g, ' ')
    },
    resetFile() {
      this.file = null;
      this.fileIsOpen = false;
      this.$refs.file.value = null;
      this.message = '';
      this.errors = [];
    },
    uploadFile(sendInvite) {
      let data = new FormData($("#file-input-form")[0]);
      data.set('is_email_enabled', +sendInvite);

      const controller = new AbortController();
      axios
        .post(
          "/api/player-invites/import",
          data,
          {
            signal: controller.signal
          }
        )
        .then((res) => {
          const message = sendInvite
            ? `You’ve just imported the "${res.data?.name}" invitation list with ${res.data?.player_count} players invited to the Signing Day Sports’ platform.`
            : `The import of your list "${res.data?.name}" with ${res.data?.player_count} players is successful.`;

          this.closeModal();
          this.retrieveInvites(1);
          this.resetFile();
          this.toaster({
            type: 'success',
            heading: 'Success',
            message,
          });
        })
        .catch((error) => {
          if (error.response?.data?.errors?.length) {
            this.errors = error.response?.data?.errors;
            this.csvErrors = error.response?.data?.parsedErrors;
          }
          this.toaster({
            type: 'error',
            heading: 'File Errors',
            message: 'Your file could not be imported.',
            buttonLabel: 'See Errors',
            onClick: () => this.toggleModal(),
          });
        })
    },
    downloadErrors() {
      window.exportData(this.parsedErrors, `${this.parsedString(this.file?.name)} errors.csv`);
    }
  }
}
</script>

<style lang="scss" scoped>
.blade-modal {
  background: transparent;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transition: 250ms;
  width: 100vw !important;
  z-index: -5;
}

.open-modal {
  background: rgba(0, 0, 0, 0.7) no-repeat center;
  background-blend-mode: darken;
  background-size: cover;
  z-index: 1000;
}

.right-card {
  background: #141414;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding: 33px 37px 0;
  position: absolute;
  right: -100%;
  transition: 350ms;
  width: 41%;

  .exit-button {
    align-items: flex-start;
    background: transparent;
    border: 0;
    display: flex;
    position: relative;
    width: fit-content;
  }
}

.open-right-card {
  right: 0;
}

.right-card-content {
  padding: 118px 89px 94px 67px;
  text-align: start;
  width: 100%;

  .first-section {
    margin-bottom: 101px;
  }

  label {
    margin-bottom: 0;
  }

  h4 {
    color: #fff;
    font-family: KairosSans, sans-serif;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 8px;
  }

  p {
    color: #A5A5A5;
    font-family: DINPro, sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.16px;
    line-height: 18px;
  }

  h5 {
    color: #F4F4F4;
    font-family: KairosSans, sans-serif;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
  }

  .import-button {
    padding: 11px 16px;

    span {
      color: #000;
      font-family: KairosSans, sans-serif;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.16px;
      line-height: 18px;
      margin-bottom: 8px;
      text-transform: uppercase;
    }
  }

  input[type='file'] {
    display: none;
  }

  .file-exists-div {
    font-family: DINPro, sans-serif;
    margin: 16px 0 0;

    span {
      color: #C6C6C6;
      display: flex;
      font-size: 12px;
      letter-spacing: 0.32px;
      line-height: 16px;
      margin-bottom: 8px;
    }

    textarea {
      color: #F4F4F4;
      letter-spacing: 0.16px;
      margin-bottom: 15px;

      &::placeholder {
        color: #6F6F6F;
      }
    }
  }

  .file-input-div {
    align-items: center;
    display: flex;
    margin-bottom: 32px;
    position: relative;
    width: 68.8%;

    .file-input {
      background: #262626;
      border: 0;
      border-radius: 0;
      color: #F4F4F4;
      font-family: DINPro, sans-serif;
      font-size: 14px;
      line-height: 18px;
      max-width: 100%;
      padding: 11px 16px;
      text-overflow: ellipsis;
      width: 100%;
    }

    button {
      align-items: center;
      background: transparent;
      border: 0;
      display: flex;
      position: absolute;
      right: 16px;
    }
  }

  .parsed-input {
    background: #262626;
    border: 0;
    border-bottom: 1px solid #6F6F6F;
    border-radius: 0;
    color: #F4F4F4;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 23px;
    padding: 11px 16px;
    width: 100%;

    &::placeholder {
      color: #6F6F6F;
    }
  }

  .listSummaryDiv {
    display: flex;
    justify-content: space-between;
  }

  .save-items-div {
    display: flex;

    button {
      .non-flex-span {
        color: #000;
        display: revert;
      }

      &:first-child {
        margin-right: 31px;
      }
    }
  }

  .errors {
    margin-top: 23px;

    p, ul {
      color: #D71B1B;
    }
  }
}
</style>
