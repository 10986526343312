<template>
  <div>
    <div class="page-header">
      <h3 class="page-title">Player Overview</h3>
      <div>
        <button
          v-if="showClear"
          @click="resetFilters"
          class="btn waves-effect waves-light btn-danger reset-btn"
        >
          Clear
        </button>
        <el-select id="date_filter" class="theme-select"
                   v-model="date"
                   placeholder="INVITE DATE"
                   clearable
                   v-if="!inviteId"
        >
          <el-option v-for="date in dates"
                     :key="date"
                     :label="date"
                     :value="date"
          >{{ date }}</el-option>
        </el-select>
        <el-select id="position" class="theme-select"
                   v-model="position"
                   placeholder="Positions"
                   clearable
        >
          <el-option v-for="(position, code) in positions"
                     :key="position"
                     :label="position"
                     :value="code"
          >{{ position }}</el-option>
        </el-select>
      </div>
    </div>
    <data-tables-server
        id="player-table"
        :data="players"
        :loading="loading"
        :total="pagination.total"
        :filters="filters"
        :pager-count="pagination.count"
        :current-page="currentPaginationPage"
        @query-change="handleQueryChange"
        :pagination-props="{
          pageSizes: [10, 25, 50, 100],
          background: true,
          layout: 'prev, pager, next, sizes, ->, total'
        }"
    >
      <el-table-column label="Add" align="center" width="50">
        <template slot-scope="{ row }">
          <AddButton :player="row" @addToProspects="addToProspects(row)"></AddButton>
        </template>
      </el-table-column>

      <el-table-column label="Player" align-v="center" align="center">
        <template slot-scope="{ row }">
          <img
            :src=row.profile_image
            class="rounded-circle"
            height="90"
            style="padding: 7px"
            width="90"
          />
        </template>
      </el-table-column>

      <el-table-column v-if="players" label="Name" align="left">
        <template slot-scope="{ row }" >
          <div v-html="renderPlayerATag(row)"></div>
        </template>
      </el-table-column>

      <el-table-column label="Date Invited" align="left" prop="date">
        <template slot-scope="{ row }">
          {{ row.date }}
        </template>
      </el-table-column>

      <el-table-column label="Invite Name" align="left">
        <template slot-scope="{ row: { invite_name } }">
          {{ invite_name }}
        </template>
      </el-table-column>

      <el-table-column label="HT / WT" align="center">
        <template slot-scope="{ row }">
          {{ renderHeightWeight(row) }}
        </template>
      </el-table-column>

      <el-table-column label="Academics" align="center" sortable="custom" prop="academics">
        <template slot-scope="{ row }">
          <div v-if="row.user_id">
            <button
              @mouseenter="setHeightOfDropdown"
              @click="focusSelectedButton"
              @blur="blurSelectedButton"
              type="button"
              data-toggle="dropdown"
              aria-expanded="false"
              class="btn dropdown-toggle waves-effect waves-light hover-button"
            >
              {{ renderProgress(row.academic_progress) }}
            </button>
            <div class="dropdown-menu status-dropdown">
              <div v-for="(item, index) in statusDropdown(row, 'academics')" :key="item.key" >
                <div class="dropdown-row">
                  <p class="row-label">{{item.pretty_text}}</p>
                  <p class="row-value">{{item.value}}</p>
                </div>
                <hr v-if="index+1 < statusDropdown(row, 'academics').length" class="status-hr" style="margin: 0.2rem 5px;"/>
              </div>
            </div>
          </div>
          <p class="placeholder" v-else>-</p>
        </template>
      </el-table-column>

      <el-table-column label="Testing" align="center" prop="testing" sortable="custom">
        <template slot-scope="{ row }">
          <div v-if="row.user_id">
            <button
              @mouseenter="setHeightOfDropdown"
              @click="focusSelectedButton"
              @blur="blurSelectedButton"
              type="button"
              data-toggle="dropdown"
              aria-expanded="false"
              class="btn dropdown-toggle waves-effect waves-light hover-button"
            >
              {{ renderProgress(row.testing_progress) }}
            </button>
            <div class="dropdown-menu status-dropdown">
              <div v-for="(item, index) in statusDropdown(row, 'testing')" :key="item.key" >
                <div class="dropdown-row">
                  <p class="row-label">{{item.pretty_text}}</p>
                  <p class="row-value">{{item.value}}</p>
                </div>
                <hr v-if="index+1 < statusDropdown(row, 'testing').length" class="status-hr" style="margin: 0.2rem 5px;"/>
              </div>
            </div>
          </div>
          <p class="placeholder" v-else>-</p>
        </template>
      </el-table-column>

      <el-table-column
        label="Status"
        prop="total_progress"
        align="center"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          <div v-if="row.user_id">
            <button
              @mouseenter="setHeightOfDropdown"
              @click="focusSelectedButton"
              @blur="blurSelectedButton"
              type="button"
              data-toggle="dropdown"
              aria-expanded="false"
              class="btn dropdown-toggle waves-effect waves-light hover-button"
              >
                {{ renderProgress(row.total_progress) }}
            </button>
            <div class="dropdown-menu status-dropdown">
              <div v-for="(item, index) in statusDropdown(row)" :key="item.key" >
                <div class="dropdown-row">
                  <p class="row-label">{{item.pretty_text}}</p>
                  <p class="row-value">{{item.value}}</p>
                </div>
                <hr v-if="index+1 < statusDropdown(row).length" class="status-hr" style="margin: 0.2rem 5px;"/>
              </div>
            </div>
          </div>
          <p v-else class="placeholder">Invited</p>
        </template>
      </el-table-column>
    </data-tables-server>
  </div>
</template>

<script>
import DataTablesServer from '@components/ui/DataTablesServer';
import AddButton from './components/AddButton';

import { buildQueryForVueDataTable } from '@lib/vue-data-tables-util'

let timeout

export default {
  name: "PlayerView",
  components: { DataTablesServer, AddButton },
  props: {
    invite: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    loading: false,
    currentPaginationPage: JSON.parse(localStorage.getItem('playerPage')) ?? 1,
    url: '/api/invited-players',
    players: [],
    dates: [],
    date: '',
    position: '',
    positions: window.signingDayConstants['user_positions'],
    previousQuery: null,
    pagination: {},
    isFocused: false,
  }),
  methods: {
    renderPlayerATag(row) {
      return row.email_verified_at ? `<a target="_blank" href="/recruits/${row.user_id}">${row.name}</a>` : row.name;
    },
    renderName(row){
      return `${row.firstname} ${row.lastname}`;
    },
    statusDropdown(row, type = null) {
      let data = _.cloneDeep(row)
      let html_pretty_text = row.player_invite_attr['html_pretty_text']

      // remove 'html_pretty_text' from attributes
      delete data.player_invite_attr['html_pretty_text']

      // return completed state for each attribute
      const keys = type ? data.player_invite_attr[type] : Object.values(data.player_invite_attr).flat();
      return keys.map((key) => {
        if(key === 'sat' || key === 'act' || key === 'unweighted_gpa'){
          return {key, value: data.attr && data.attr[key] && data.attr[key+'_file'] ? 'Completed' : 'N/A', pretty_text: html_pretty_text[key]}
        }
        else{
          return {key, value: data.attr && data.attr[key] ? 'Completed' : 'N/A', pretty_text: html_pretty_text[key]}
        }
      })
    },
    renderHeightWeight(player) {
      const height = player.attr?.height
      const weight = player.attr?.weight
      if (height && weight) {
        return `${height} / ${weight}`
      }
      else if (height === null && weight){
        return `- / ${weight}`
      }
      else if (height && weight === null) {
        return `${height} / -`
      }
      else {
        return '- / -'
      }
    },
    renderVariable(value){
      if(value){
        return value;
      }else{
        return "N/A";
      }
    },
    renderProgress(value) {
      if (value === 100) return 'Completed'
      if(value != null) return `${value}%`
      return 'Invited'
    },
    async addToProspects(data) {
      await window.addPlayerToProspects(target, data.user_id, data.attr.position || 'QB')
    },
    handleQueryChange(query) {
      clearTimeout(timeout)
      timeout = setTimeout(() => this.loadData(query), 250);
    },
    async loadData(query, silentReload = false) {
      localStorage.setItem('playerPage', JSON.stringify(query.page));
      this.previousQuery = query
      this.loading = !silentReload
      const builder = buildQueryForVueDataTable(query)

      const url = `${this.url}?${builder.build()}`

      const res = await axios.get(url)

      this.pagination = res.data.meta.pagination
      this.players = res.data.data

      this.loading = false
    },
    async getDates() {
      await axios.post('/api/player-invites/dates').then(({ data }) => this.dates = data)
    },
    resetFilters() {
      this.date = ''
      this.position = ''
      this.inviteId = null
    },
    setHeightOfDropdown({ target }) {
      const row_count = $(target).closest('#player-table table > tbody').children().length
      const status_dropdown = $(target).siblings('.status-dropdown')
      let row_height = $(target).closest('.el-table__row').height()-10

      // set height of dropdown depending on # of rows in table
      if(row_count <= 1) {
        row_height = row_height*1.0
      }
      else if(row_count == 2){
        row_height = row_height*1.35
      }
      else{
        row_height = row_height*2.0
      }
      status_dropdown.css({"maxHeight":row_height+"px"})
    },
    blurSelectedButton({ target }){
      $(target).removeClass("btn-secondary")
      this.isFocused = false
    },
    focusSelectedButton({ target }){
      if(!this.isFocused){
        $(target).addClass("btn-secondary")
        this.isFocused = true

      }
      else{
        $(target).removeClass("btn-secondary")
        this.isFocused = false
      }
    },
  },
  computed: {
    console: () => console,
    window: () => window,
    inviteId: {
      get: function() {
        return this.invite
      },
      set: function() {
        this.$emit('reset_invite')
      }
    },
    count() {
      return this.players.length || 0;
    },
    showClear: ({ filters }) => filters.some(filter => !!filter.value),
    filters() {
      return [
        { search_prop: 'date', value: this.date },
        { search_prop: 'position', value: this.position },
        { search_prop: 'inviteId', value: this.inviteId }
      ]
    }
  },
  created() {
    $('[data-toggle="tooltip"]').tooltip()
    this.getDates()
  },
  updated() {
    this.currentPaginationPage = JSON.parse(localStorage.getItem('playerPage'));
  }
}
</script>
<style scoped>
.status-dropdown {
  max-height: 300px;
  min-width: 250px;
  overflow: auto;
}

.dropdown-row {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.dropdown-row > p {
  margin: 0;
}

.row-label {
  font-size: 80%;
}

.row-value {
  font-size: 70%;
}

.placeholder:hover {
  cursor: pointer;
}

.page-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.hover-button {
  min-width: 50px;
}
</style>
