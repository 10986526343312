<template>
  <div class="form-control">
    <span class="filter-title">{{title}}</span>
    <el-date-picker        
      v-model="dateRange"
      type="daterange"
      align="right"
      unlink-panels
      range-separator="-"
      start-placeholder="START DATE"
      end-placeholder="END DATE"
      format="MM/dd/yyyy"
      value-format="MM-dd-yyyy"
      size="mini"
      @change="handlChange"
      :picker-options="pickerOptions">
    </el-date-picker>
  </div>
</template>

<script>
  export default {
    props: [
      'title', 'id', 'handleDataChange',
    ],

    data() {
      return {
        pickerOptions: {
          shortcuts: [{
            text: 'Last week',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: 'Last month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: 'Last 3 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        dateRange: '',
      };
    },

    methods: {
      handlChange(data){
        this.handleDataChange(data, this.id);
      },
      resetFilterInputs(){
        this.dateRange= '';
      },
    },
  };
</script>

<style scoped>
  .form-control {
    padding: 6px 15px;
    margin-bottom: 10px;
  }

  .filter-title {
    margin-right: 5px;
  }

  .el-date-editor--daterange.el-input__inner {
    width: 200px;
  }
</style>
