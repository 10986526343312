<template>
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.06518 0.307402L8 0.267569L7.93482 0.307402L0.975956 4.56004L0.801422 4.6667L0.975956 4.77336L4.04166 6.64685V11.1434V11.2135L4.10148 11.2501L7.93481 13.5927L7.99999 13.6325L8.06517 13.5927L11.8985 11.2501L11.9583 11.2135V11.1434V6.64686L13.375 5.78112V8.33337V8.45837H13.5H14.5H14.625V8.33337V5.01723L15.024 4.77336L15.1986 4.6667L15.024 4.56004L8.06518 0.307402ZM7.93482 9.026L8 9.06583L8.06518 9.026L10.7083 7.41075V10.5125L7.99999 12.1676L5.29166 10.5125V7.41074L7.93482 9.026ZM8 7.6009L3.19858 4.6667L8 1.7325L12.8014 4.6667L8 7.6009Z" fill="#454545" stroke="#454545" stroke-width="0.25"/>
  </svg>
</template>

<script>
export default {
  name: "GradCapIcon"
}
</script>
