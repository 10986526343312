<template>
  <fragment>
    <tr>
      <td>
        <IconButton
          @onClick="toggleAdditionalInfo"
          title="Toggle Additional Info"
          :className="`additional-info-toggle ${activeAdditionalInfoId === player.id ? 'active' : ''}`"
        >
          <ChevronDown />
        </IconButton>
      </td>
      <td>
        <a v-if="player.profile" :href="`/recruit/${ player.user_id }`">{{  player.name }}</a>
        <span v-else>{{  player.name }}</span>
      </td>
      <td><a :href="`mailto:${ player.email }`">{{  player.email }}</a></td>
      <td>{{  allPositions[player.position] || "---" }}</td>
      <td>{{  player.invited_at || "---" }}</td>
      <td :style="{color: statusColors[player.status]}">{{ inviteStatusLabels[player.status] }}</td>
      <td>
        <div class="row-actions">
          <v-popover class="tooltip-wrapper" trigger="hover">
            <EditPlayerInfoButton
              :name="player.name"
              :email="player.email"
              :position="player.position"
              :playerId="player.id"
              :disabled="(player.status === inviteStatusNames.existing) || areActionsDisabled"
            />
            <template slot="popover">
              <div>
                Edit Player Info
              </div>
            </template>
          </v-popover>
          <v-popover class="tooltip-wrapper" trigger="hover">
            <IconButton
              title="Send Invite Email"
              class="action-button"
              @onClick="sendInvite(player)"
              :disabled="areActionsDisabled"
            >
              <EmailIcon />
            </IconButton>
            <template slot="popover">
              <div>
                Send Invitation
              </div>
            </template>
          </v-popover>
          <v-popover class="tooltip-wrapper" trigger="hover">
            <IconButton
                title="Delete Invitation"
                class="action-button"
                :disabled="areActionsDisabled"
            >
              <DeleteIcon />
            </IconButton>
            <template slot="popover">
              <div>
                <p class="dinpro">Remove this player from the list? *Note: This does not remove the player from the database.</p>
                <Button @onClick="handleDelete(player.id)">Remove Player</Button>
              </div>
            </template>
          </v-popover>
          <v-popover class="tooltip-wrapper" trigger="hover">
            <IconLink
              :href="`/recruit/${player.user_id}`"
              :isDisabled="!player.profile"
              title="View Player Profile"
              class="action-button"
              :disabled="areActionsDisabled"
            >
              <UserIcon />
            </IconLink>
            <template slot="popover">
                <div>
                  View Profile
                </div>
            </template>
          </v-popover>
        </div>
      </td>
    </tr>
    <tr class="additional-info">
      <td class="padding-cell" colspan="1"></td>
      <td class="main-cell" colspan="6">
        <div :class="`wrapper ${activeAdditionalInfoId === player.id ? '' : 'wrapper--closed'}`">
          <div class="content">
            <div class="field-group">
              <span class="label">Additional Info: </span>
              <EditPlayerAdditionalInfoButton v-bind="{player}" />
            </div>
            <div class="field-group">
              <span class="label">Parent Name: </span>
              <span class="value">
                {{ player.parent_name || '---' }}
              </span>
            </div>
            <div class="field-group">
              <span class="label">Parent Email: </span>
              <span class="value">
                {{ player.parent_email || '---' }}
              </span>
            </div>
            <div class="field-group">
              <span class="label">Parent Phone: </span>
              <span class="value">
                {{ player.parent_phone || '---' }}
              </span>
            </div>
            <div class="field-group">
              <span class="label">Athlete Phone: </span>
              <span class="value">
                {{ player.phone || '---' }}
              </span>
            </div>
            <!-- These field-groups are intentionally commented out. Will be coming in future stories. -->
            <!-- <div class="field-group">
              <span class="label">Jersey #: </span>
              <span class="value">
                {{ player.jersey_numer || '---' }}
              </span>
            </div>
            <div class="field-group">
              <span class="label">GPA: </span>
              <span class="value">
                {{ player.gpa || '---' }}
              </span>
            </div> -->
          </div>
        </div>
      </td>
    </tr>
  </fragment>
</template>

<script>
import { Fragment } from 'vue-frag';
import Button from '@components/Button';
import IconLink from '@components/IconLink';
import FormInput from '@components/FormInput';
import UserIcon from '@components/svg/UserIcon';
import IconButton from '@components/IconButton';
import EmailIcon from '@components/svg/EmailIcon';
import PencilIcon from '@components/svg/PencilIcon';
import DeleteIcon from '@components/svg/DeleteIcon';
import inviteStatusNames from '@consts/invite-status-names';
import inviteStatusLabels from '@consts/invite-status-labels';
import ChevronDown from '@components/svg/ChevronDown';
import statusColors from '@consts/invite-status-font-colors';
import EditPlayerAdditionalInfoButton from '@screens/myinvites/InviteListDetails/InviteListDetailsTable/components/EditPlayerAdditionalInfoButton';
import EditPlayerInfoButton from '@screens/myinvites/InviteListDetails/InviteListDetailsTable/components/PlayerRow/components/EditPlayerInfoButton';

export default {
  name: "PlayerRow",
  components: {
    Fragment,
    IconLink,
    Button,
    IconButton,
    EmailIcon,
    PencilIcon,
    DeleteIcon,
    UserIcon,
    FormInput,
    ChevronDown,
    EditPlayerInfoButton,
    EditPlayerAdditionalInfoButton
  },
  created() {
    this.statusColors = statusColors;
    this.inviteStatusNames = inviteStatusNames;
    this.inviteStatusLabels = inviteStatusLabels;
  },
  props: ['player', 'activeAdditionalInfoId', 'setActiveAdditionalInfoId'],
  inject: ['getPlayers'],
  data() {
    return {
      allPositions: window.signingDayConstants.user_positions,
      areActionsDisabled: false,
    }
  },
  methods: {
    toggleAdditionalInfo() {
      this.setActiveAdditionalInfoId(this.player.id);
    },
    handleDelete(id) {
      const controller = new AbortController();
      this.areActionsDisabled = true;
      try {
        axios.delete(
          `/api/invited-players/${this.player.id}/delete`,
          {
            data: {
              id
            },
            signal: controller.signal
          },
        ).then(() => {
          this.toaster({
            type: 'success',
            heading: 'Success',
            message: 'Player deleted successfully.',
          });
          this.getPlayers();
        });
      } catch (error) {
        this.toaster(
          {
            type: 'error',
            heading: 'Error',
            message: 'Failed to delete player.'
          }
        );
        console.error(error);
      }
      this.areActionsDisabled = false;
    },
    sendInvite(player) {
      const controller = new AbortController();
      this.areActionsDisabled = true;

      axios.post(
        `/api/player-invites/players/${player.id}/send-invite`,
        { player_email: player.email },
        { signal: controller.signal }
      )
      .then((_) => {
        this.toaster({
          type: 'success',
          heading: 'Success!',
          message: 'Player invite sent successfully.'
        });

        this.getPlayers();

      }).catch((error) => {
        if(!axios.isCancel(error)) {
          this.toaster({
            type: 'error',
            heading: 'Error',
            message: 'Failed to send player invite.',
          });
        }
      }).finally(() => {
        this.areActionsDisabled = false;
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.additional-info-toggle {
  margin-left: 15px;

  &.active {
    transform: rotate(180deg);
  }
}

.additional-info {
  height: 0;

  .wrapper {
    overflow: hidden;
    transition: 0.5s;

    &--closed {
      height: 0;
    }
  }
  .padding-cell {
    background-color: #f2f2f2;
    padding: 0;
  }
  .main-cell {
    padding: 0;
  }
  .content {
    background-color: #F2F2F2;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 1rem 1rem 1rem 0;
  }
  .field-group {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    &:first-of-type {
      margin-bottom: 2px;
    }
  }
  .label {
    color: #747474;
    font-size: 0.825rem;
    font-family: KairosSans, sans-serif;
    font-weight: 700;
    margin-right: 0.5rem;
    text-transform: uppercase;
  }
  .value {
    font-family: DINPro, sans-serif;
    margin-bottom: 0.25rem;
  }
}

.row-actions {

  .tooltip-wrapper {
    display: inline-block;
  }
}

</style>
