<template>
  <div class="col-12" v-if="measurements.length > 0">
    <h4 class="header-text">Recently Viewed</h4>
    <div id="recentlyViewedSlider" class="table display">
        <div class="slide" v-for="(measurement, key) in measurements">
          <video v-if="measurement.video">
            <source :src="getMeasurementVideo(measurement)"></source>
          </video>
          <div class="play-btn" v-if="measurement.video"><i class="fa fa-play"></i></div>
          <img v-else-if="measurement.photo" :src="getMeasurementImage(measurement)" alt="Thumbnail">
          <div v-else class="default-container org-bg">
            <img class="default-img" src="/build/images/signing-day-sports-logo.png" alt="Default Image">
          </div>
          <div class="slide-inner" data-modal-component="verification-detail" data-modal-title="" :data-props="`:measurement_id='${measurement.id}'`">
            <div class="banner">
              <h4 class="text-center">{{measurement.user.name}} - {{measurement.measurement.name}}</h4>
              <div class="banner-color org-bg"></div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
    import axios from 'axios'
    import Multiselect from 'vue-multiselect'
    import VerificationDetail from './VerificationDetail'

    export default {
        name: 'recently-viewed',
        props: {
        },
        data() {
            return {
              measurements: [],
              slides: 8
            }
        },

        mounted: function() {
          this.getMeasurements()
        },

        methods: {
          getMeasurements() {
            axios.get('/api/recent-measurements').then((response) => {
                this.measurements = response.data.data
            }).then(() => {
                this.initializeSlider()
            });
          },

          initializeSlider() {
            let el = $('#recentlyViewedSlider').children()
            let slideNum = this.slides
            $('#recentlyViewedSlider').slick({
                speed: 300,
                slidesToShow: slideNum,
                slidesToScroll: slideNum,
                draggable: false,
                responsive: this.getResponsiveAttributes(),
                prevArrow: "<i class='fa fa-caret-down arrow-left'></i>",
                nextArrow: "<i class='fa fa-caret-down arrow-right'></i>"
            });
          },

          getMeasurementImage(measurement) {
            if(measurement.photo != null) {
              return measurement.photo.url != null 
                ? measurement.photo.url
                : measurement.photo.thumbnail_path
            }
            return null;
          },

          getMeasurementVideo(measurement) {
            return measurement.video != null
              ? measurement.video.file_upload.url
              : null
          },

          getResponsiveAttributes() {
            return [
                {
                  breakpoint: 2050,
                  settings: {
                    slidesToShow: this.slides,
                    slidesToScroll: this.slides,
                  }
                },
                {
                  breakpoint: 1850,
                  settings: {
                    slidesToShow: this.slides - 1,
                    slidesToScroll: this.slides - 1,
                  }
                },
                {
                  breakpoint: 1650,
                  settings: {
                    slidesToShow: this.slides - 2,
                    slidesToScroll: this.slides - 2,
                  }
                },
                {
                  breakpoint: 1450,
                  settings: {
                    slidesToShow: this.slides - 3,
                    slidesToScroll: this.slides - 3,
                  }
                },
                {
                  breakpoint: 1250,
                  settings: {
                    slidesToShow: this.slides - 4,
                    slidesToScroll: this.slides - 4,
                  }
                },
                {
                  breakpoint: 950,
                  settings: {
                    slidesToShow: this.slides - 5,
                    slidesToScroll: this.slides - 5,
                  }
                },
                {
                  breakpoint: 750,
                  settings: {
                    slidesToShow: this.slides - 6,
                    slidesToScroll: this.slides - 6,
                  }
                },
                {
                  breakpoint: 550,
                  settings: {
                    slidesToShow: this.slides - 7,
                    slidesToScroll: this.slides - 7,
                  }
                },
              ]
          }
        },
    }

    $(() => {
       $('#recentlyViewedSlider .slide-inner').hover(() => {
        $(this).find('.banner').attr('style', 'top');
       });
    });
</script>
