<template>
  <div id="status-filter-button">
    <button
      id="filter-icon"
      :style="{ background: (isStatusFilterVisible || params.status)  ? '#393939' : 'transparent' }"
      title="Toggle status filter list"
      @click="toggleShowStatuses"
    >
      <FilterIcon :fill="statusColors[params.status]" />
    </button>
    <div :class="`status-list ${isStatusFilterVisible ? 'status-list--visible': ''}`">
      <button
        v-for="status in availableStatuses"
        :key="status"
        :class="`status-button ${params.status === status ? 'status-button--selected' : ''}`"
        :style="{color: statusColors[status]}"
        @click="handleChange(status)"
      >
        {{  statusNames[status]  }}
      </button>
    </div>
  </div>
</template>

<script>
import FilterIcon from '@components/svg/FilterIcon';
import statusNames from '@consts/invite-status-names';
import statusColors from '@consts/invite-status-colors';

export default {
  name: 'InviteListDetailsStatusFilterButton',
  props: ['params'],
  components: {FilterIcon},
  data() {
    return {
      availableStatuses: [],
      isAvailableStatusLoading: true,
      isStatusFilterVisible: false,
    }
  },
  created() {
    this.statusColors = statusColors;
    this.statusNames = statusNames;
  },
  inject: ['players', 'setParams'],
  watch: {
    players() {
      if (this.isAvailableStatusLoading) {
        this.availableStatuses = this.getAvailableStatuses();
        this.isAvailableStatusLoading = false;
      }
    }
  },
  methods: {
    getAvailableStatuses() {
      return [...new Set(this.players.map((player) => player.status))];
    },
    toggleShowStatuses() {
      this.isStatusFilterVisible = !this.isStatusFilterVisible;
    },
    handleChange(value) {
      let newParams = {
        ...this.params,
        status: this.params.status === value ? '' : value
      };

      this.setParams(newParams);
    }
  }
}
</script>

<style lang="scss" scoped>
#status-filter-button {
  position: relative;

  #filter-icon {
    border: 0;
    height: 48px;
    margin-left: 0.5rem;
    width: 48px;
  }

  .status-list {
    background: #393939;
    display: none;
    list-style-type: none;
    padding-left: 0;
    position: absolute;
    right: 0;
    top: 100%;
    width: 180px;
    z-index: 100;

    &--visible {
      display: block;
    }

    .status-button {
      background: transparent;
      border: none;
      color: #fff;
      font-family: DINPro;
      padding: 0.75rem 1rem;
      text-transform: capitalize;
      text-align: left;
      width: 100%;

      &--selected {
        background-color: #505050;
      }
    }
  }
}
</style>
