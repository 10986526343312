<template>
  <div class="public-player-profile-highlight-video">
    <h5 class="section-title">HIGHLIGHT VIDEO</h5>
    <div class="iframe-container">
      <iframe
        class="iframe"
        :src="player.highlight_url"
        allow="autoplay; encrypted-media; picture-in-picture"
        allowfullscreen
        allowtransparency
        frameborder="0"
        title="Highlight Video"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "HighlightVideo",
  inject: ['player'],
}
</script>
