<template>
    <div class='col-sm-12 col-md-6 col-lg-6 col-xl-4 card-box-container player-box masonry-item'>
        <div v-if="loading" style="position: absolute; z-index: 1000; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(255,255,255,0.85); text-align: center; padding-top:50px; font-size: 24px"><span>One Moment...</span></div>
        <div class='card-box'>
            <div class='row player-info'>
                <div class='col-3 col-sm-3 col-md-3 profile-pic-container text-center'>
                    <a :href="'recruit/' + player.id"><img :src="player.photo" class='rounded-circle profile-pic-sm'></a>
                </div>
                <div class='col-9 col-sm-9 col-md-9'>
                    <h4 class='mb-0 player-name-pos'><a :href="'recruit/' + player.id">{{ player.first_name }} {{ player.last_name }}, {{ player.profile.data.basics.position }} - {{ player.profile.data.basics.grad_year }}</a></h4>
                    <p class='mb-0 gray-text f-size-13 player-city-state pb-5'>
                      {{ player.profile.data.basics.city }}<span v-if="player.profile.data.basics.city && player.profile.data.basics.state">, </span>{{ player.profile.data.basics.state }} <span v-if="player.profile.data.basics.city || player.profile.data.basics.state" style='font-size:10px;'>&#9679;</span> {{ player.profile.data.basics.high_school }}
                    </p>
                    <!-- <p class='mb-0 f-size-13'><strong>Signing Day Sports Rating:</strong> <span class='gray-text'>{{ this.$vnode.key + 1 }}</span></p> -->
                    <div class='row'>
                        <div class='col-5 col-sm-4 col-md-5' v-if="player.profile.data.measurables.height">
                            <p class='mb-7 f-size-13'><strong>Height: </strong><span class='gray-text'>{{ player.profile.data.measurables.height }}</span></p>
                        </div>
                        <div class='col-7 col-sm-8 col-md-7' v-if="player.profile.data.measurables.weight">
                            <p class='mb-7 f-size-13'><strong>Weight: </strong><span class='gray-text'>{{ player.profile.data.measurables.weight }}</span></p>
                        </div>
                        <div class='col-sm-12 player-btns pt-10'>
                            <a :href="'recruit/' + player.id" class='btn btn-dark gray-text player-btn fw-light mr-10'>View Player</a>
                            <!-- <a @click="deletePlayer()" class='btn btn-primary player-btn fw-light rm-player-btn'>Remove Player</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'PlayerCard',
        props: ['player', 'update'],

        data() {
            return {
                loading: false
            }
        },

        methods: {
            deletePlayer() {
                Swal.fire({
                    title: 'Are you sure you want to remove this player?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, remove player!',
                    cancelButtonText: 'No, cancel!',
                    confirmButtonClass: 'btn btn-confirm mt-2',
                    cancelButtonClass: 'btn btn-cancel ml-2 mt-2',
                    reverseButtons: true
                }).then(result => {
                    if (result.value) {
                        this.loading = true
                        axios.delete(`/roster/delete/${this.player.id}`).then(response => {
                            this.update().then(() => {
                                this.loading = false
                            })
                        }).done(
                             Swal.fire({
                                title: 'Removed!',
                                text: 'The player has been removed.',
                                type: 'success',
                                timer: 1700,
                            })
                        )
                    } else {
                        Swal.fire({
                            title: 'Canceled!',
                            text: 'You have canceled remove player.',
                            timer: 1700,
                        })
                    }
                })
            }
        }
    }
</script>
