const totalFormatProps = { min: 0, max: 99999, step: 1 }
const calcInputProps = { min: 0, max: 99.999, step: .001, precision: 3, input: false }
const stats = [
  {abbrev: 'G', name: 'Games Played', id: 'games_played', type: 'B', ...totalFormatProps},
  {abbrev: 'AB', name: 'At Bats', id: 'at_bats', type: 'B', ...totalFormatProps},
  {abbrev: 'PA', name: 'Plate Appearances', id: 'plate_appearances', type: 'B', ...totalFormatProps},
  {abbrev: 'AVG', name: 'Batting Average', id: 'batting_average', type: 'B', ...calcInputProps, input: false, max: 1},
  {abbrev: 'H', name: 'Hits', id: 'hits', type: 'B', ...totalFormatProps},
  {abbrev: '1B', name: 'Singles', id: 'singles', type: 'B', ...totalFormatProps},
  {abbrev: '2B', name: 'Doubles', id: 'doubles', type: 'B', ...totalFormatProps},
  {abbrev: '3B', name: 'Triples', id: 'triples', type: 'B', ...totalFormatProps},
  {abbrev: 'HR', name: 'Home Runs', id: 'home_runs', type: 'B', ...totalFormatProps},
  {abbrev: 'RBI', name: 'Runs Batted In', id: 'runs_batted_in', type: 'B', ...totalFormatProps},
  {abbrev: 'BB', name: 'Walks', id: 'walks', type: 'B', ...totalFormatProps},
  {abbrev: 'K', name: 'Strikeouts', id: 'strikeouts', type: 'B', ...totalFormatProps},
  {abbrev: 'SF', name: 'Sacrifice Flies', id: 'sac_flies', type: 'B', ...totalFormatProps},
  {abbrev: 'SH', name: 'Sacrifice Hits', id: 'sac_hits', type: 'B', ...totalFormatProps},
  {abbrev: 'SB', name: 'Stolen Bases', id: 'stolen_bases', type: 'B', ...totalFormatProps},
  {abbrev: 'HBP', name: 'Hit By Pitch', id: 'hit_by_pitch', type: 'B', ...totalFormatProps},
  {abbrev: 'OBP', name: 'On Base Percentage', id: 'on_base_percentage', type: 'B', ...calcInputProps, max: 1},
  {abbrev: 'SLG', name: 'Slugging Percentage', id: 'slugging_percentage', type: 'B', ...calcInputProps, max: 1},
  {abbrev: 'OPS', name: 'On Base Plus Slugging', id: 'on_base_plus_slugging', type: 'B', ...calcInputProps, max: 2},
  {abbrev: 'G', name: 'Games Pitched', id: 'games_pitched', type: 'P', ...totalFormatProps},
  {abbrev: 'GS', name: 'Games Started', id: 'games_started', type: 'P', ...totalFormatProps},
  {abbrev: 'CG', name: 'Complete Games', id: 'games_finished', type: 'P', ...totalFormatProps},
  {abbrev: 'SV', name: 'Game Saves', id: 'games_saved', type: 'P', ...totalFormatProps},
  {abbrev: 'SVO', name: 'Save Opportunities', id: 'save_opportunities', type: 'P', ...totalFormatProps},
  {abbrev: 'IP', name: 'Innings Pitched', id: 'innings_pitched', type: 'P', ...totalFormatProps, max: 9999.9, step: .1, precision: 1},
  {abbrev: 'BF', name: 'Batters Pitched', id: 'batters_pitched', type: 'P', ...totalFormatProps},
  {abbrev: 'HBP', name: 'Hit Batsmen', id: 'batters_hit_by_pitch', type: 'P', ...totalFormatProps},
  {abbrev: 'K', name: 'Strikeouts', id: 'strikeouts_pitched', type: 'P', ...totalFormatProps},
  {abbrev: 'BB', name: 'Walks', id: 'walks_pitched', type: 'P', ...totalFormatProps},
  {abbrev: 'HITS', name: 'Hits', id: 'hits_pitched', type: 'P', ...totalFormatProps},
  {abbrev: 'R', name: 'Runs', id: 'runs_pitched', type: 'P', ...totalFormatProps},
  {abbrev: 'SF', name: 'Sacrifice Flies', id: 'sac_flies_pitched', type: 'P', ...totalFormatProps},
  {abbrev: 'SH', name: 'Sacrifice Hits', id: 'sac_hits_pitched', type: 'P', ...totalFormatProps},
  {abbrev: 'ER', name: 'Earned Runs', id: 'earned_runs_pitched', type: 'P', ...totalFormatProps},
  {abbrev: 'HR', name: 'Home Runs', id: 'home_runs_pitched', type: 'P', ...totalFormatProps},
  {abbrev: 'ERA', name: 'Earned Run Average', id: 'earned_run_average', type: 'P', ...calcInputProps, max: 20},
  {abbrev: 'WHIP', name: 'Walks Hits Per Innings Pitched', id: 'walks_hits_per_innings_pitched', type: 'P', ...calcInputProps, max: 20},
  {abbrev: 'BAA', name: 'Batting Average Against', id: 'batting_average_against', type: 'P', ...calcInputProps, max: 1},
  {abbrev: 'K/BB', name: 'Strikeout Walk Ratio', id: 'strikeout_walk_ratio', type: 'P', ...calcInputProps, max: 20},
  // {abbrev: 'CINT', name: 'Catcher Interference', id: 'catcher_interference', type: 'P', ...totalFormatProps},
]

export const buildObservableObj = () => {
  let vals = {}, ranges = {}, keys = {}
  stats.forEach(({id: key}) => {
    vals[key] = { low: 0, hi: 0 }
    ranges[key] = [0, 0]
    keys[key] = Math.random() * 1000
  })
  return {values: vals, ranges, keys}
}

export default stats
