<template>
  <Fragment>
    <div class="lite-profile-tile-wrapper">
      <PlayerStatTile
        :stat-name="this.heightStatTile.label"
        :stat-value="this.heightStatTile.value"
        :thumbnail-image="this.heightStatTile.imageUrl"
        :video="this.heightStatTile.video"
      />
    </div>
    <LiteProfileBanner />
  </Fragment>
</template>

<script>
  import { Fragment } from 'vue-frag';
  import PlayerStatTile from "@components/PlayerProfile/PublicProfile/components/PlayerStatTile";
  import LiteProfileBanner from "@components/PlayerProfile/PublicProfile/components/LiteProfileBanner";

  export default {
    name: 'LiteProfileBody',
    components: { Fragment, LiteProfileBanner, PlayerStatTile },
    inject: ['player'],
    data() {
      return {
        heightStatTile: {
          label: this.player.profile.measurables?.height?.name,
          value: this.player.profile.measurables?.height?.value,
          imageUrl: this.player.profile.measurables?.height?.attributes?.file_upload?.thumbnail_path,
          video: this.player.profile.measurables?.height?.attribute?.file_upload?.path,
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .lite-profile-tile-wrapper {
    display: flex;
    margin: 1rem;
    justify-content: center;

    .player-stat-tile {
      flex: 1;
    }
  }
</style>
