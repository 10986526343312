/**
 * This is specifically for use with https://www.npmjs.com/package/vue-data-tables
 */
import { CurrentPageFilter, FilterBuilder, ItemsPerPageFilter, SearchFilter, Sort, SortFilter } from "./url-filter";

export function mapSort(sort) {
    switch (sort) {
        case 'ascending':
          // return 'asc';
            return Sort.Ascending;
        case 'descending':
            return Sort.Descending;
    }
    return Sort.None;
}

/**
 * It takes the "query-info" event data (https://www.njleonzhang.com/vue-data-tables/#/en-us/event?id=query-info),
 * and converts it to URL params for use with our API. Handles pageination, sorting, and filtering.
 *
 * @param {*} queryInfo
 */
export function buildQueryForVueDataTable(queryInfo) {
    const builder = new FilterBuilder();
    builder.addFilter(new ItemsPerPageFilter(queryInfo.pageSize, queryInfo.pageKey));
    builder.addFilter(new CurrentPageFilter(queryInfo.page));
    if (queryInfo.sort && queryInfo.sort.prop && queryInfo.sort.order) {
        const order = mapSort(queryInfo.sort.order);
        builder.addFilter(new SearchFilter('by', queryInfo.sort.prop, 'sort'));
        builder.addFilter(new SortFilter('order', order, 'sort'));
    }
    if (queryInfo.filters) {
        queryInfo.filters.map(filter => builder.addFilter(new SearchFilter(filter.search_prop, filter.value)));
    }

    return builder;
}
