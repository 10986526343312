<template>
  <div class="row">
    <div class="col-12 justify-content-between flex-column-reverse">
      <div class="btn-group float-right mb-3">
        <button
          class="btn btn-primary waves-effect waves-light"
          v-if="this.su"
          v-on:click="showAdvancedSearch()"
        >
          Advanced Search
        </button>
      </div>
      <div id="advanced-search-buttons" class="invisible d-flex">
        <div class="clear-filter-wrapper">
          <button
            id="clearFilters"
            class="btn btn-primary waves-effect waves-light"
            v-on:click="clearFilter()"
          >
            Clear Filters
          </button>
        </div>
        <div class="ml-3">
          <select
            name="organizations"
            id="organization"
            v-model="organizationName"
            v-select2
          ></select>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <select
          name="player_ids"
          id="player-select"
          class="form-control search-player"
          v-model="selectedPlayers"
          v-select2
          multiple
        ></select>
      </div>
    </div>
    <div class="col-12">
      <div class="chat-input">
        <div
          class="misc-button"
          style="margin-left: 15px"
          v-on:click="sendCoachContactInformation"
        >
          <i
            data-toggle="tooltip"
            title="Send an automated message containing your phone and email"
            class="fa fa-address-book-o btn-primary"
          ></i>
        </div>
        <textarea
          name="message-input"
          id="message-input"
          placeholder="Type a message..."
          v-model="currentMessage"
          rows="1"
        ></textarea>
        <div class="send-button" v-on:click="createMessage()">
          <i class="fa fa-send btn-primary"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "../store";

export default {
  name: "start-group-conversation",
  props: ["fan", "su", "coachname", "coachemail", "coachphone"],
  data() {
    return {
      currentMessage: "",
      selectedPlayers: [],
      organizationName: "",
      advancedSearching: false,
    };
  },

  mounted: function () {
    $(document).ready(() => {
      $(".select2").select2();

      this.initializePlayerFilter();
      this.initializeOrganizationFilter();

      $("#organizations").on("change", () => {
        this.initializePlayerFilter();
      });

    });
  },
  updated() {},
  methods: {
    showAdvancedSearch() {
      this.advancedSearching = !this.advancedSearching;
      if (this.advancedSearching) {
        $("#advanced-search-buttons").removeClass("invisible");
      } else {
        $("#advanced-search-buttons").addClass("invisible");
        this.clearFilter();
      }
    },
    getPlayer() {
      window.location = "/recruits/" + this.player_id + "/message";
    },
    async createMessage() {
      if (this.selectedPlayers.length > 0) {
        // Makes sure the message isn't blank
        if (this.currentMessage != "") {
          const message = this.currentMessage;
          this.currentMessage = "";

          let body = {};

          body = {
            message: message,
            user_ids: this.selectedPlayers,
          };

          // Pushes a new group, and changes to it, or pushes a new message to the group
          const call = await axios.post(
            "/api/messages/group-message?include=messages,users.profile",
            body
          );
          await store.dispatch("fetchUserMessage");
          toastr.success("", "Group message queued for processing!");
          window.location.reload();
        } else {
          Swal.fire({
            title: "Please provide a message",
          });
        }
      } else {
        Swal.fire({
          title: "Please select user/s",
        });
      }
    },
    sendCoachContactInformation() {
      Swal.fire({
        title: "Send these players your contact information?",
        text:
          "This will send an automated message to the players, containing your email and phone number.",
        type: "question",
        showCancelButton: true,
        confirmButtonText: "Send contact information!",
        cancelButtonText: "Cancel!",
        confirmButtonClass: "btn btn-confirm mt-2",
        cancelButtonClass: "btn btn-cancel ml-2 mt-2",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          var oldMessage = this.currentMessage;

          this.currentMessage =
            this.coachname +
            " has chosen to send you their contact information: \nEmail: " +
            this.coachemail +
            (!!this.coachphone ? "\nPhone: " + this.coachphone : "");

          this.createMessage();

          this.currentMessage = oldMessage;
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
    },
    initializePlayerFilter() {
      const self = this;
      $(".search-player").select2({
        placeholder: "Select Player",
        minimumInputLength: 3,
        ajax: {
          url: function () {
            return `/api/recruits/search-group?organization_name=${self.organizationName}`;
          },
          dataType: "json",
          processResults: function (data) {
            return {
              results: $.map(data, function (item) {
                return {
                  text: item.first_name + ", " + item.last_name,
                  id: item.id,
                };
              }),
            };
          },
          cache: true,
        },
      });
    },
    initializeOrganizationFilter() {
      $("#organization").select2({
        placeholder: "Organization",
        minimumInputLength: 3,
        ajax: {
          url: "/api/organization-autocomplete-ajax",
          dataType: "json",
          processResults: function (data) {
            return {
              results: $.map(data, function (item) {
                return {
                  text: item.name,
                  id: item.name,
                };
              }),
            };
          },
          cache: true,
        },
      });
    },
    resetPlaceholder() {
      $("#organization").val("").trigger("change.select2");
      this.organizationName = "";
    },
    clearFilter() {
      this.resetPlaceholder();
      this.initializeOrganizationFilter();
    },
  },
};
</script>
