<template>
  <svg width="14" height="13" viewBox="0 0 14 13" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.466309 0.16748L4.58349 6.15611V11.006L8.91682 12.7393V6.15611L13.034 0.167481L0.466309 0.16748ZM2.36734 1.16748L11.133 1.16748L7.91682 5.84552L7.91682 11.2623L5.58349 10.329L5.58349 5.84552L2.36734 1.16748Z"/>
  </svg>

</template>

<script>
  export default {
    name: 'FilterIcon',
    props: {
      fill: {
        type: String,
        default: '#fff',
      }
    }
  }
</script>
