<template>
  <div id="position-filter-button">
    <button
      id="filter-icon"
      :style="{ background: (isPositionsFilterVisible || params.positions.length)  ? '#393939' : 'transparent' }"
      title="Toggle position filter list"
      @click="toggleShowPositions"
    >
      <FilterIcon/>
    </button>
    <div :class="`positions-list ${isPositionsFilterVisible ? 'positions-list--visible': ''}`">
      <FormulateForm @input="handleChange">
        <FormInput :options="availablePositions" name="selectedPositions" type="checkbox"/>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import FormInput from '@components/FormInput';
import FilterIcon from '@components/svg/FilterIcon';

export default {
  name: 'PositionFilterButton',
  props: ['params'],
  components: {FilterIcon, FormInput},
  data() {
    return {
      availablePositions: [],
      isPositionsFilterVisible: false,
      isAvailablePositionsLoading: true,
    }
  },
  inject: ['players', 'setParams'],
  watch: {
    players() {
      if (this.isAvailablePositionsLoading) {
        this.availablePositions = this.getAvailablePositions();
        this.isAvailablePositionsLoading = false;
      }
    }
  },
  methods: {
    getAvailablePositions() {
      const allPositions = window.signingDayConstants.user_positions;
      allPositions.unassigned = '---'; // not great

      const playerPositionKeys = this.players.reduce((acc, player) => {
        const position = player.position || 'unassigned';

        if(!acc.includes(position)) {
          acc.push(position);
        };
        return acc;
      }, []);

      return Object.keys(allPositions)
        .filter(key => playerPositionKeys.includes(key))
        .reduce((obj, key) => {
          obj[key] = allPositions[key];
          return obj;
        }, {});
    },
    toggleShowPositions() {
      this.isPositionsFilterVisible = !this.isPositionsFilterVisible;
    },
    handleChange(value) {
      const newParams = {...this.params, positions: value.selectedPositions};

      this.setParams(newParams);
    },
  }
}
</script>

<style lang="scss" scoped>
#position-filter-button {
  position: relative;

  #filter-icon {
    border: 0;
    height: 48px;
    margin-left: 0.5rem;
    width: 48px;
  }

  .positions-list {
    background: #393939;
    display: none;
    list-style-type: none;
    padding-left: 0;
    position: absolute;
    right: 0;
    top: 100%;
    width: 180px;
    z-index: 100;

    &--visible {
      display: block;
    }
  }
}
</style>
