<template>
  <form @submit.prevent="is_college ? collegeSave() : save()">
    <div class="row one">
      <div class="col-md-6">
        <div class="form-group">
          <label>Email <span v-if="!this.roster && !is_college" style="color: red">*</span></label>
          <input type="email" name="email" class="form-control" v-model="player.email" :required="(!this.roster && !is_college) ?? null"/>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Cell Phone</label>
          <input type="tel" name="phone" class="form-control" v-model="player.phone" />
        </div>
      </div>
    </div>
    <div class="row two">
      <div class="col-md-6">
        <div class="form-group">
          <label>First Name <span style="color: red">*</span></label>
          <input
            type="text"
            name="first_name"
            class="form-control"
            v-model="player.first_name"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Last Name <span style="color: red">*</span></label>
          <input
            type="text"
            name="last_name"
            class="form-control"
            v-model="player.last_name"
            required
          />
        </div>
      </div>
    </div>
    <div class="row" >
      <div class="col-md-6" v-if="this.roster || is_college">
        <div class="form-group">
          <label>Position</label>
          <select
            class="form-control select2"
            v-model="player.positions"
            v-select2
            :required="!is_college"
            multiple="multiple"
          >
            <option v-for="(position, key) in positions" :value="key">{{ position }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group grad-year">
          <label v-if="organization_type != 1 && !is_college">Graduation Year</label>
          <label v-else>Class</label>
          <select
            v-if="organization_type != 1 && !is_college"
            name="grad_year"
            class="select2 form-control grad-years"
            v-select2
            v-model="player.grad_year"
            :required="this.roster ?? null"
          >
            <option v-for="index in 8" :key="index">{{ (new Date).getFullYear() + (index - 1) }}</option>
          </select>
          <select
            v-else
            name="grad_year"
            class="select2 form-control grad-years"
            v-select2
            v-model="player.grad_year"
          >
            <option :value="getYearWithCohort('SR')">SR</option>
            <option :value="getYearWithCohort('JR')">JR</option>
            <option :value="getYearWithCohort('SO')">SO</option>
            <option :value="getYearWithCohort('FR')">FR</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <button
          type="submit"
          class="btn btn-primary add-player-modal-btn"
          :disabled="loading"
        >{{ loading ? 'Saving...' : 'Save' }}</button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";

export default {
  name: "AddPlayer",
  props: {
    organization: Number,
    roster: Number,
    organization_name: String,
    organization_city: String,
    organization_state: String,
    organization_type: Number,
    is_college: {type: Number, default: 0, required: false},
  },

  data: function () {
    return {
      loading: true,
      positions: window.signingDayConstants.user_positions,
      player: {
        positions: [],
        roster: this.roster,
        is_college: this.is_college
      },
    };
  },

  mounted() {
    $(".select2").select2();
    $("#player-status-button").addClass('d-none');

    this.loading = false;

    $(".grad-years").select2({
      placeholder:
        this.organization_type != 1 && !this.is_college ? "Select Graduation Year" : "Select Class",
    });
    if (this.is_college) {
      $(".row.one").insertAfter(".row.two");
    }
  },

  methods: {
    save() {
      this.loading = true;
      let profileExists = false;

      axios
        .post(
          "/api/organizations/" + this.organization + "/players/check-profile",
          this.player
        )
        .then((response) => {
          profileExists = response.data;
        })
        .then(() => {
          if (!profileExists.player) {
            this.is_college ? this.saveCollegeProspect() : this.savePlayer();
          } else if (!profileExists.organization) {
              let title = this.is_college
                ? "Could not create new player."
                : "A player already exists with that email.";
              let text = this.is_college
                ? "This email is already being used for a player’s profile."
                : "Would you like to create a unique profile for this player?";

            if (['high_school', 'academy', 'club'].includes(profileExists.type)) {
              this.uniqueProfileAlert(title, text, false);
            } else {
              this.uniqueProfileAlert(title, text, true);
            }
          } else if (
            !profileExists.organization_user &&
            profileExists.organization
          ) {

            if (['high_school', 'academy', 'club'].includes(profileExists.type)
            ) {
              this.savePlayer();
            } else {
              Swal.fire({
                title: `This player was previously removed from your roster.`,
                text: `Would you like to recover this profile and add the player to your roster?`,
                showCancelButton: true,
                cancelButtonText: "Cancel",
                confirmButtonText: "Create",
                confirmButtonClass: "btn btn-confirm mt-2",
                cancelButtonClass: "btn btn-cancel ml-2 mt-2",
                reverseButtons: true,
              }).then((result) => {
                if (result.value) {
                  this.savePlayer();
                } else {
                  this.loading = false;
                }
              });
            }
          } else if (
            ['high_school', 'academy', 'club'].includes(profileExists.type) &&
            profileExists.transfer_status
          ) {
            this.savePlayer();
          } else {
            let title = "Could not create new player.";
            let collegeText = 'This email is already being used for a player’s profile.'
            let text = `A unique profile already exists for this player. Please try again with a different email.`;
            this.createErrorTypeAlert(title, this.is_college ? collegeText : text);
            this.loading = false;
          }
        })
        .catch((error) => {
          let title = "Could not create unique profile for this user.";
          let text = `Please try again with a different email.`;
          this.createErrorTypeAlert(title, text);
          this.loading = false;
        });
    },
    savePlayer() {
      axios
        .post(
          "/api/organizations/" + this.organization + "/players",
          this.player
        )
        .then((response) => {
          if (response.data.data == "failure") {
            this.loading = false;
            Swal.fire({
              title: "This player belongs to another " + response.data.orgType + ".",
              text: `Would you like to send a request to this player asking to join your roster?`,
              showCancelButton: true,
              cancelButtonText: "No",
              confirmButtonText: "Yes",
              confirmButtonClass: "btn btn-confirm mt-2",
              cancelButtonClass: "btn btn-cancel ml-2 mt-2",
              reverseButtons: true,
            }).then((result) => {
              if (result.value) {
                this.sendJoinRequest();
              } else {
                this.loading = false;
              }
            });
          } else {
            window.location.reload();
          }
        })
        .catch((error) => {
          let title = "Could not create new player.";
          let text = `A unique profile already exists for this player. Please try again with a different email.`;
          this.createErrorTypeAlert(title, text);
          this.loading = false;
        });
    },
    sendJoinRequest() {
      axios
        .post(
          "/api/organizations/" + this.organization + "/players/join",
          this.player
        )
        .then((response) => {
          Swal.fire({
            title: "A transfer request has been sent!",
            type: "success",
            text: `Your organization will be notified when ${
              this.player.first_name + " " + this.player.last_name
            } approves the request.`,
            timer: 4000,
          });
        })
        .then(() => {
          $(".modal").modal("hide");
        })
        .catch((error) => {
          let title = error.response.data.title || "Could not create unique profile for this use";
          let text = error.response.data.text || "Please try again with a different email.";
          this.createErrorTypeAlert(title, text);
        });
    },
    uniqueProfileAlert(title, text, footer) {
      axios.get("/api/get-player-id/" + this.player.email).then((response) => {
        if (this.is_college) {
          Swal.fire({
            title: title,
            text: text,
            type: "error",
            icon: "error",
            showCancelButton: true,
            cancelButtonText: "Close",
            confirmButtonText: "View",
            confirmButtonClass: "btn btn-confirm mt-2",
            cancelButtonClass: "btn btn-cancel ml-2 mt-2",
            reverseButtons: true,
          }).then((result) => {
            if (result.value) {
              window.open("/recruit/" + response.data, '_blank');
              $(".modal").modal("hide");
            } else {
              this.loading = false;
            }
          });
        } else {
          if (footer) {
            let player_id = response.data;
            var link = document.createElement("a");
            link.href = "/recruit/" + player_id;
            link.innerHTML = "View Existing Player";
          }

          Swal.fire({
            title: title,
            text: text,
            footer: link,
            showCancelButton: true,
            cancelButtonText: "Cancel",
            confirmButtonText: "Create",
            confirmButtonClass: "btn btn-confirm mt-2",
            cancelButtonClass: "btn btn-cancel ml-2 mt-2",
            reverseButtons: true,
          }).then((result) => {
            if (result.value) {
              this.savePlayer();
            } else {
              this.loading = false;
            }
          });
        }
      });
    },
    createErrorTypeAlert(title, text) {
      Swal.fire({
        title: title,
        type: "error",
        html: text,
        icon: 'error'
      });
    },
    getYearWithCohort(cohort) {
      switch (cohort) {
        case "SR":
          return new Date().getMonth() > 5
            ? parseInt(new Date().getFullYear()) + 1
            : parseInt(new Date().getFullYear());
          break;
        case "JR":
          return new Date().getMonth() > 5
            ? parseInt(new Date().getFullYear()) + 2
            : parseInt(new Date().getFullYear() + 1);
          break;
        case "SO":
          return new Date().getMonth() > 5
            ? parseInt(new Date().getFullYear()) + 3
            : parseInt(new Date().getFullYear() + 2);
          break;
        case "FR":
          return new Date().getMonth() > 5
            ? parseInt(new Date().getFullYear()) + 4
            : parseInt(new Date().getFullYear() + 3);
          break;
      }
    },
    collegeSave() {
      if (this.player.email) {
        this.save()
      } else {
        this.saveCollegeProspect()
      }
    },
    saveCollegeProspect() {
      this.loading = true;
      this.player.id = '';
      const controller = new AbortController();
      axios
        .post(
          "/api/organizations/" + this.organization + "/college-prospects",
          this.player,
          {
            signal: controller.signal
          }
        )
        .then((response) => {
          $('.modal').hide();
          Swal.fire({
            title: "Player has been added.",
            type: "success",
            text: `${
              this.player.first_name + " " + this.player.last_name
            } has been added to your prospects list.`,
            timer: 4000,
          });
          this.loading = false;
          window.location.reload();
          }
        )
        .catch(function (error) {
          if (!axios.isCancel(error)) {
            this.createErrorTypeAlert(
              "Problem adding player to prospects.",
              'Please try again.');
            this.loading = false;
          }
        });
    }
  },
};
</script>
