<template>
  <form @submit.prevent="save()" id="profile-form">
    <div class="row" v-if="player">
      <button v-if="saving" type="submit" class="top-save btn btn-secondary" :disabled="saving"><span class="spinner-border spinner-border-sm mr-1"></span> Saving...</button>
      <button v-else type="submit" class="top-save btn btn-primary">Save</button>
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-12">
            <h4>Measurables</h4>
          </div>
          <div class="col-lg-12 col-xl-2">
            <div class="row">
              <div class="col-sm-12">
                <label>Height</label>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select name="height_feet" class="form-control select2" v-model="attributes.height_feet" data-field="height" v-select2>
                    <option value="null">...</option>
                    <option v-for="option in options.height_feet" :value="option + '\''">{{ option + "'" }}</option>
                  </select>
                  <label class="label-small">Feet</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select name="height_inches" class="form-control select2" v-model="attributes.height_inches" data-field="height" v-select2>
                    <option value="null">...</option>
                    <option v-for="option in options.height_inches" :value='option + "\""'>{{ option + '"' }}</option>
                  </select>
                  <label class="label-small">Inches</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-xl-2">
            <div class="form-group">
              <label>Weight</label>
              <select name="weight" class="form-control select2" v-model="attributes.weight" data-field="weight" v-select2>
                <option value="">...</option>
                <option v-for="option in options.weight" :value="option + ' lbs'">{{ option + ' lbs' }}</option>
              </select>
            </div>
          </div>
          <div class="col-lg-12 col-xl-3">
            <div class="row">
              <div class="col-sm-12">
                <label>Hand Size</label>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select name="hand_size_inches" class="form-control select2" v-model="attributes.hand_size_inches" data-field="hand_size" v-select2>
                    <option value="null">...</option>
                    <option v-for="option in options.hand_size_inches" :value='option + "\""'>{{ option + '"' }}</option>
                  </select>
                  <label class="label-small">Inches</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select name="hand_size_fraction" class="form-control select2" v-model="attributes.hand_size_fraction" data-field="hand_size" v-select2>
                    <option value="null">...</option>
                    <option v-for="option in options.eight_fractions" :value='option + "\""'>{{ option + '"' }}</option>
                  </select>
                  <label class="label-small">Inches</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-xl-3">
            <div class="row">
              <div class="col-sm-12">
                <label>Wingspan</label>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select name="wing_span_inches" class="form-control select2" v-model="attributes.wing_span_inches" data-field="wing_span" v-select2>
                    <option value="null">...</option>
                    <option v-for="option in options.wing_span_inches" :value='option + "\""'>{{ option + '"' }}</option>
                  </select>
                  <label class="label-small">Inches</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select name="wing_span_fraction" class="form-control select2" v-model="attributes.wing_span_fraction" data-field="wing_span" v-select2>
                    <option value="null">...</option>
                    <option v-for="option in options.four_fractions" :value='option + "\""'>{{ option + '"' }}</option>
                  </select>
                  <label class="label-small">Inches</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr>


        <div class="row">
          <div class="col-sm-12">
            <h4>Testing</h4>
          </div>
          <div class="col-lg-12 col-xl-4">
            <div class="row">
              <div class="col-sm-12">
                <label>L Drill</label>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select name="l_drill_seconds" class="form-control select2" v-model="attributes['l_drill_seconds']" data-field="l_drill" v-select2>
                    <option value="null">...</option>
                    <option v-for="option in options['l_drill_seconds']" :value="option">{{ option }}</option>
                  </select>
                  <label class="label-small">Seconds</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select name="l_drill_milliseconds" class="form-control select2" v-model="attributes['l_drill_milliseconds']" data-field="l_drill" v-select2>
                    <option value="null">...</option>
                    <option v-for="option in options.milliseconds" :value='"." + option'>{{ "." + option }}</option>
                  </select>
                  <label class="label-small">Milliseconds</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-xl-4">
            <div class="row">
              <div class="col-sm-12">
                <label>10 Yard Dash</label>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select name="ten_yard_dash_seconds" class="form-control select2" v-model="attributes['ten_yard_dash_seconds']" data-field="ten_yard_dash" v-select2>
                    <option value="null">...</option>
                    <option v-for="option in options['ten_yard_dash_seconds']" :value="option">{{ option }}</option>
                  </select>
                  <label class="label-small">Seconds</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select name="ten_yard_dash_milliseconds" class="form-control select2" v-model="attributes['ten_yard_dash_milliseconds']" data-field="ten_yard_dash" v-select2>
                    <option value="null">...</option>
                    <option v-for="option in options.milliseconds" :value='"." + option'>{{ "." + option }}</option>
                  </select>
                  <label class="label-small">Milliseconds</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-xl-4">
            <div class="row">
              <div class="col-sm-12">
                <label>100 Yard Dash</label>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select name="one_hundred_yard_dash_seconds" class="form-control select2" v-model="attributes['one_hundred_yard_dash_seconds']" data-field="one_hundred_yard_dash" v-select2>
                    <option value="null">...</option>
                    <option v-for="option in options['one_hundred_yard_dash_seconds']" :value="option">{{ option }}</option>
                  </select>
                  <label class="label-small">Seconds</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select name="one_hundred_yard_dash_milliseconds" class="form-control select2" v-model="attributes['one_hundred_yard_dash_milliseconds']" data-field="one_hundred_yard_dash" v-select2>
                    <option value="null">...</option>
                    <option v-for="option in options.milliseconds" :value='"." + option'>{{ "." + option }}</option>
                  </select>
                  <label class="label-small">Milliseconds</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-xl-4">
            <div class="row">
              <div class="col-sm-12">
                <label>40 Yard Dash</label>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select name="forty_yard_dash_seconds" class="form-control select2" v-model="attributes['forty_yard_dash_seconds']" data-field="forty_yard_dash" v-select2>
                    <option value="null">...</option>
                    <option v-for="option in options['forty_yard_dash_seconds']" :value="option">{{ option }}</option>
                  </select>
                  <label class="label-small">Seconds</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select name="forty_yard_dash_milliseconds" class="form-control select2" v-model="attributes['forty_yard_dash_milliseconds']" data-field="forty_yard_dash" v-select2>
                    <option value="null">...</option>
                    <option v-for="option in options.milliseconds" :value='"." + option'>{{ "." + option }}</option>
                  </select>
                  <label class="label-small">Milliseconds</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-xl-4">
            <div class="row">
              <div class="col-sm-12">
                <label>Short Shuttle</label>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select name="shuttle_seconds" class="form-control select2" v-model="attributes.shuttle_seconds" data-field="shuttle" v-select2>
                    <option value="null">...</option>
                    <option v-for="option in options.shuttle_seconds" :value="option">{{ option }}</option>
                  </select>
                  <label class="label-small">Seconds</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select name="shuttle_milliseconds" class="form-control select2" v-model="attributes.shuttle_milliseconds" data-field="shuttle" v-select2>
                    <option value="null">...</option>
                    <option v-for="option in options.milliseconds" :value='"." + option'>{{ "." + option }}</option>
                  </select>
                  <label class="label-small">Milliseconds</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-xl-2">
            <div class="row">
              <div class="col-sm-12">
                <label>Standing Broad Jump</label>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select name="standing_broad_jump_feet" class="form-control select2" v-model="attributes.standing_broad_jump_feet" data-field="height" v-select2>
                    <option value="null">...</option>
                    <option v-for="option in options.standing_broad_jump_feet" :value="option + '\''">{{ option + "'" }}</option>
                  </select>
                  <label class="label-small">Feet</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select name="standing_broad_jump_inches" class="form-control select2" v-model="attributes.standing_broad_jump_inches" data-field="height" v-select2>
                    <option value="null">...</option>
                    <option v-for="option in options.standing_broad_jump_inches" :value='option + "\""'>{{ option + '"' }}</option>
                  </select>
                  <label class="label-small">Inches</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-xl-2">
            <div class="form-group">
              <label>Nike Rating</label>
              <select name="nike_football_rating" class="form-control select2" v-model="attributes.nike_football_rating" data-field="nike_football_rating" v-select2>
                <option value="null">...</option>
                <option v-for="option in options.nike_football_rating" :value='option'>{{ option }}</option>
              </select>
            </div>
          </div>
          <div class="col-lg-12 col-xl-4">
            <div class="row">
              <div class="col-sm-12">
                <label>Bench</label>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select name="bench_lbs" class="form-control select2" v-model="attributes.bench_lbs" data-field="bench" v-select2>
                    <option value="null">...</option>
                    <option v-for="option in options.bench_lbs" :value='option'>{{ option }}</option>
                  </select>
                  <label class="label-small">Lbs</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select name="bench_reps" class="form-control select2" v-model="attributes.bench_reps" data-field="bench" v-select2>
                    <option value="null">...</option>
                    <option v-for="option in options.bench_reps" :value='option'>{{ option }}</option>
                  </select>
                  <label class="label-small">Reps</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-xl-2">
            <div class="form-group">
              <label>Max Squat</label>
              <select name="squat" class="form-control select2" v-model="attributes.squat" data-field="squat" v-select2>
                <option value="null">...</option>
                <option v-for="option in options.squat" :value='option + " lbs"'>{{ option + ' lbs' }}</option>
              </select>
            </div>
          </div>
        </div>
        <hr>


        <div class="row">
          <div class="col-sm-12">
            <h4>Academics</h4>
          </div>
          <div class="col-lg-12 col-xl-3">
            <div class="form-group">
              <label>Unweighted GPA</label>
              <input type="text" name="unweighted_gpa" class="form-control" v-model="player.profile.data.academics.data.unweighted_gpa" data-field="unweighted_gpa">
            </div>
          </div>
          <div class="col-lg-12 col-xl-3">
            <div class="form-group">
              <label>ACT</label>
              <input type="text" name="act" class="form-control" v-model="player.profile.data.academics.data.act" data-field="act">
            </div>
          </div>
          <div class="col-lg-12 col-xl-3">
            <div class="form-group">
              <label>SAT</label>
              <input type="text" name="sat" class="form-control" v-model="player.profile.data.academics.data.sat" data-field="sat">
            </div>
          </div>
          <div class="col-lg-12 col-xl-3">
            <div class="form-group">
              <label>NCAA ID</label>
              <input type="number" min="0" max="9999999999" maxlength="10" name="ncaa_id" class="form-control" v-model="player.profile.data.academics.data.ncaa_id" data-field="ncaa_id">
            </div>
          </div>
        </div>
        <hr>


        <div class="row">
          <div class="col-sm-12">
            <h4>Player Information</h4>
          </div>
          <div class="col-lg-12 col-xl-5">
            <div class="row">
              <div class="col-sm-12">
                <label>Name</label>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input type="text" name="first_name" class="form-control" v-model="player.first_name" data-field="first_name">
                  <label class="label-small">First</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input type="text" name="last_name" class="form-control" v-model="player.last_name" data-field="last_name">
                  <label class="label-small">Last</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-xl-4">
            <div class="form-group" high-school>
              <label class="col-12 pl-0 pr-0">School <a href="javascript::void(0);"style="float:right;" class="high_school_edit_toggle" @click="editPlayerHighSchool()
              ">{{ editHighSchool ? 'Cancel' : 'Edit' }}</a></label>
              <input type="text" name="high_school" :class="editHighSchool ? 'd-none' : 'form-control'" v-model="player.profile.data.basics.data.high_school" data-field="high_school" disabled>
              <div :class="editHighSchool ? 'col-12 pl-0 pr-0': 'col-12 d-none'">
                <select name="high_school_select" class="high_school_select form-control select2" v-select2 v-model="player.profile.data.basics.data.high_school" data-field="high_school">
                </select>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-xl-3">
            <div class="form-group" v-if="high_school != 1">
              <label>Grad Year</label>
              <select name="grad_year" class="form-control select2 grad-years" v-select2 v-model="player.profile.data.basics.data.grad_year" required data-field="grad_year">
                <option v-for="index in 8" :key="index">{{ (new Date).getFullYear() + (index - 1) }}</option>
              </select>
            </div>
            <div class="form-group" v-else>
              <label>Class</label>
              <select name="grad_year" class="form-control select2 grad-years" v-select2 v-model="player.profile.data.basics.data.grad_year" required data-field="grad_year">
                <option :value="getYearWithCohort('SR')">SR</option>
                <option :value="getYearWithCohort('JR')">JR</option>
                <option :value="getYearWithCohort('SO')">SO</option>
                <option :value="getYearWithCohort('FR')">FR</option>
              </select>
            </div>
          </div>
          <div class="col-lg-12 col-xl-6">
            <div class="row">
              <div class="col-sm-12">
                <label>Date of Birth</label>
              </div>
              <div class="col-lg-12 col-xl-6">
                <div class="form-group">
                  <select name="dob_month" class="form-control select2" v-model="attributes.dob_month" v-select2 data-field="dob">
                    <option value="null">...</option>
                    <option v-for="(month, index) in options.months" :value="index">{{ month }}</option>
                  </select>
                  <label>Month</label>
                </div>
              </div>
              <div class="col-lg-12 col-xl-3">
                <div class="form-group">
                  <select name="dob_day" class="form-control select2" v-model="attributes.dob_day" v-select2 data-field="dob">
                    <option value="null">...</option>
                    <option v-for="option in 31" :value="option">{{ option }}</option>
                  </select>
                  <label>Day</label>
                </div>
              </div>
              <div class="col-lg-12 col-xl-3">
                <div class="form-group">
                  <select name="dob_year" class="form-control select2" v-model="attributes.dob_year" v-select2 data-field="dob">
                    <option value="null">...</option>
                    <option v-for="option in options.years" :value="option">{{ option }}</option>
                  </select>
                  <label>Year</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-xl-3" v-if="positions" id="positionContainer">
            <div class="form-group">
              <label>Positions</label>
              <select id="positionsSelect" class="form-control select2" v-model="player.profile.data.positions.data" v-select2 required multiple="multiple">
                <option v-for="(position, key) in positions" :value="position">{{ position.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-lg-12 col-xl-3">
            <div class="form-group">
              <label>Jersey Number</label>
              <input type="number" name="jersey_num" class="form-control" v-model="player.profile.data.basics.data.jersey_num" data-field="jersey_num">
            </div>
          </div>
          <div class="col-lg-12 col-xl-4">
            <div class="form-group">
              <label>Hudl Profile Link</label>
              <input type="text" name="hudl_link" class="form-control" v-model="player.profile.data.basics.data.sportBasics.data.hudl_link" data-field="hudl_link">
            </div>
          </div>
          <div class="col-lg-12 col-xl-4">
            <div class="form-group">
              <label>Phone</label>
              <input type="text" name="phone" class="form-control" v-mask="'(###) ###-####'" placeholder="(###) ###-####" v-model="player.profile.data.basics.data.phone" data-field="phone">
            </div>
          </div>
          <div class="col-lg-12 col-xl-4">
            <div class="form-group">
              <label>Email</label>
              <input type="email" name="email" class="form-control" v-model="player.profile.data.basics.data.email" data-field="email">
            </div>
          </div>
        </div>
        <hr>


        <div class="row">
          <div class="col-sm-12">
            <h4>Address</h4>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-sm-12">
                <label>Address</label>
              </div>
              <div class="col-lg-12 col-xl-4">
                <div class="form-group">
                  <input type="text" name="address" class="form-control" v-model="player.profile.data.basics.data.address" data-field="address">
                  <label>Street</label>
                </div>
              </div>
              <div class="col-lg-12 col-xl-3">
                <div class="form-group">
                  <input type="text" name="city" class="form-control" v-model="player.profile.data.basics.data.city" data-field="city">
                  <label>City</label>
                </div>
              </div>
              <div class="col-lg-12 col-xl-3">
                <div class="form-group">
                  <select name="state" class="form-control select2" v-model="player.profile.data.basics.data.state" v-select2 data-field="state">
                    <option value="null">...</option>
                    <option v-for="option in options.states" :value="option">{{ option }}</option>
                  </select>
                  <label>State</label>
                </div>
              </div>
              <div class="col-lg-12 col-xl-2">
                <div class="form-group">
                  <input type="text" name="zip" class="form-control" v-model="player.profile.data.basics.data.zip" data-field="zip">
                  <label>Zip</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr>


        <div class="row">
          <div class="col-sm-12">
            <h4>HS Head Coach</h4>
          </div>
          <div class="col-lg-12 col-xl-4">
            <div class="form-group">
              <label>Coach Name</label>
              <input type="text" name="coach_name" class="form-control" v-model="player.profile.data.basics.data.coach_name" data-field="coach_name">
            </div>
          </div>
          <div class="col-lg-12 col-xl-4">
            <div class="form-group">
              <label>Coach Phone</label>
              <input type="text" name="coach_phone" class="form-control" v-mask="'(###) ###-####'" placeholder="(###) ###-####" v-model="player.profile.data.basics.data.coach_phone" data-field="coach_phone">
            </div>
          </div>
          <div class="col-lg-12 col-xl-4">
            <div class="form-group">
              <label>Coach Email</label>
              <input type="email" name="coach_email" class="form-control" v-model="player.profile.data.basics.data.coach_email" data-field="coach_email">
            </div>
          </div>
        </div>
        <hr>


        <div class="row">
          <div class="col-sm-12">
            <h4>Social Media</h4>
          </div>
          <div class="col-lg-12 col-xl-3">
            <div class="form-group">
              <label>Twitter Username</label>
              <input type="text" name="twitter" class="form-control" v-model="player.profile.data.basics.data.twitter" data-field="twitter">
            </div>
          </div>
          <div class="col-lg-12 col-xl-3">
            <div class="form-group">
              <label>Instagram Username</label>
              <input type="text" name="instagram" class="form-control" v-model="player.profile.data.basics.data.instagram" data-field="instagram">
            </div>
          </div>
          <div class="col-lg-12 col-xl-3">
            <div class="form-group">
              <label>TikTok Username</label>
              <input type="text" name="tiktok" class="form-control" v-model="player.profile.data.basics.data.tiktok" data-field="tiktok">
            </div>
          </div>
        </div>
        <hr>


        <div class="row">
          <div class="col-sm-12">
            <h4 class="pull-left">Parent Information</h4>
            <a href="javascript:void(0)" class="btn btn-sm btn-primary pull-right" @click="same">Use Player Address</a>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-xl-4">
            <div class="form-group">
              <label>Parent Name</label>
              <input type="text" name="parent_name" class="form-control" v-model="player.profile.data.basics.data.parent_name" data-field="parent_name">
            </div>
          </div>
          <div class="col-lg-12 col-xl-4">
            <div class="form-group">
              <label>Parent Phone</label>
              <input type="text" name="parent_phone" class="form-control" v-mask="'(###) ###-####'" placeholder="(###) ###-####" v-model="player.profile.data.basics.data.parent_phone" data-field="parent_phone">
            </div>
          </div>
          <div class="col-lg-12 col-xl-4">
            <div class="form-group">
              <label>Parent Email</label>
              <input type="email" name="parent_email" class="form-control" v-model="player.profile.data.basics.data.parent_email" data-field="parent_email">
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-sm-12">
                <label>Address</label>
              </div>
              <div class="col-lg-12 col-xl-4">
                <div class="form-group">
                  <input type="text" name="parent_address" class="form-control" v-model="player.profile.data.basics.data.parent_address" data-field="parent_address">
                  <label>Street</label>
                </div>
              </div>
              <div class="col-lg-12 col-xl-3">
                <div class="form-group">
                  <input type="text" name="parent_city" class="form-control" v-model="player.profile.data.basics.data.parent_city" data-field="parent_city">
                  <label>City</label>
                </div>
              </div>
              <div class="col-lg-12 col-xl-3">
                <div class="form-group">
                  <select name="parent_state" id="parentState" class="form-control select2" v-model="player.profile.data.basics.data.parent_state" v-select2 data-field="parent_state">
                    <option value="null">...</option>
                    <option v-for="option in options.states" :value="option">{{ option }}</option>
                  </select>
                  <label>State</label>
                </div>
              </div>
              <div class="col-lg-12 col-xl-2">
                <div class="form-group">
                  <input type="text" name="parent_zip" class="form-control" v-model="player.profile.data.basics.data.parent_zip" data-field="parent_zip">
                  <label>Zip</label>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-sm-12">
            <button v-if="saving" type="submit" class="btn btn-secondary" :disabled="saving"><span class="spinner-border spinner-border-sm mr-1"></span> Saving...</button>
            <button v-else type="submit" class="btn btn-primary">Save</button>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="spinner-border text-primary m-2"></div>
  </form>
</template>

<script>
import axios from 'axios'
import { default as options } from '../profile/options.js'
import { default as Attributes } from '../profile/attributes.js'

export default {
  name: 'UpdateProfile',
  props: {
    playerId: Number,
    high_school: Number
  },

  /**
   * Compute the final values for attributes update
   */
  computed: {
    profile: function() {
      let basics = this.player.profile.data.basics.data
      return {
        // Measurables
        height: this.attributes.height_feet + " " + this.attributes.height_inches,
        weight: this.attributes.weight,
        hand_size: this.attributes.hand_size_inches.slice(0, -1) + ' ' + this.attributes.hand_size_fraction,
        wing_span: this.attributes.wing_span_inches.slice(0, -1) + ' ' + this.attributes.wing_span_fraction,

        // Testing
        l_drill: this.attributes['l_drill_seconds'] + this.attributes['l_drill_milliseconds'],
        "ten_yard_dash": this.attributes['ten_yard_dash_seconds'] + this.attributes['ten_yard_dash_milliseconds'],
        "one_hundred_yard_dash": this.attributes['one_hundred_yard_dash_seconds'] + this.attributes['one_hundred_yard_dash_milliseconds'],
        "forty_yard_dash": this.attributes['forty_yard_dash_seconds'] + this.attributes['forty_yard_dash_milliseconds'],
        shuttle: this.attributes.shuttle_seconds + this.attributes.shuttle_milliseconds,
        standing_broad_jump: this.attributes.standing_broad_jump_feet + ' ' + this.attributes.standing_broad_jump_inches,
        nike_football_rating: this.attributes.nike_football_rating,
        bench: this.attributes.bench_lbs + 'x' + this.attributes.bench_reps,
        squat: this.attributes.squat,

        // Academics
        unweighted_gpa: this.player.profile.data.academics.data.unweighted_gpa,
        act: this.player.profile.data.academics.data.act,
        sat: this.player.profile.data.academics.data.sat,
        ncaa_id: this.player.profile.data.academics.data.ncaa_id,

        // Player Information
        first_name: this.player.first_name,
        last_name: this.player.last_name,
        high_school: basics.high_school,
        grad_year: basics.grad_year,
        dob: this.attributes.dob_year + '-' + (this.attributes.dob_month < 10 ? '0' + this.attributes.dob_month : this.attributes.dob_month) + '-' + (this.attributes.dob_day < 10 ? '0' + this.attributes.dob_day : this.attributes.dob_day),
        positions: this.player.profile.data.positions.data.map((item) => item.id),
        jersey_num: basics.jersey_num,
        hudl_link: basics.hudl_link,
        phone: basics.phone,
        email: basics.email,

        // Address
        address: basics.address,
        city: basics.city,
        state: basics.state,
        zip: basics.zip,

        // High School Head Coach
        coach_name: basics.coach_name,
        coach_phone: basics.coach_phone,
        coach_email: basics.coach_email,

        // Social Media
        twitter: basics.twitter,
        instagram: basics.instagram,
        tiktok: basics.tiktok,

        // Parent Information
        parent_name: basics.parent_name,
        parent_phone: basics.parent_phone,
        parent_email: basics.parent_email,
        parent_address: basics.parent_address,
        parent_city: basics.parent_city,
        parent_state: basics.parent_state,
        parent_zip: basics.parent_zip,
      }
    }
  },
  data: function() {
    return {
      player: false,
      saving: false,
      options: options,
      attributes: {},
      positions: false,
      editHighSchool: false,
      ncaaIDMaxLength: 10
    }
  },
  watch: {
    'player.profile.data.academics.data.ncaa_id': function (val) {
      this.player.profile.data.academics.data.ncaa_id = String(val).slice(0, this.ncaaIDMaxLength);
    }
  },
  mounted() {
    axios.get('/api/players/' + this.playerId).then(response => {
      this.player = response.data.data
      this.$forceUpdate()
    }).then(() => {
      this.attributes = new Attributes(this.player.profile.data).get()
    }).then(() => {
      axios.get('/api/positions?sport_id=1').then((positions) => {
        this.positions = positions.data.data
      }).then(() => {
        $('.select2').select2();
      }).then(() => {
        this.initializeHighSchool()
      })

      // $('#profile-form').on('change', '[data-field]', (e) => {
      //   if ($(e.target).is('select') && $(e.target).val() != this.attributes[$(e.target).attr('name')]) {
      //     return
      //   }

      //   let name = $(e.target).data('field')
      //   let data = {
      //     [name]: this.profile[name]
      //   }
      //   this.save(data)
      // })

    }).then(() => {
      this.selectPositions()
    })
  },

  methods: {
    same() {
      let basics = this.player.profile.data.basics.data
      // basics.parent_phone = basics.phone
      // basics.parent_email = basics.email
      basics.parent_address = basics.address
      basics.parent_city = basics.city
      basics.parent_state = basics.state
      $('#parentState').val(basics.parent_state)
      $('#parentState').change()
      basics.parent_zip = basics.zip
    },

    save(data = false) {
      this.saving = true
      axios.put('/api/organizations/' + this.player.profile.data.organization_id + '/players/' + this.player.id, data || this.profile).then(response => {
        this.saving = false
      }).then(() => {
        this.$root.$emit('update')
      }).catch((error) => {
        let defaultMsg = 'Something went wrong!!!';
        if (error.response?.status === 422) {
          const errorMsg = Object.values(error.response?.data?.errors ?? {})[0]?.[0]
          toastr.error(errorMsg ?? '', error.response?.data?.message ?? defaultMsg)
        } else {
          toastr.error('', defaultMsg)
        }
        this.saving = false
      });
    },

    selectPositions() {
      let positions = this.player.profile.data.positions.data
      let shortcodes = positions.map((item) => item.shortcode)
      $('#positionsSelect').val(shortcodes).trigger('change')
    },

    initializeHighSchool() {

      $('.high_school_select').select2({
        placeholder: 'Select School',
        minimumInputLength: 3,
        ajax: {
          url: '/api/highschool-autocomplete-ajax',
          dataType: 'json',
          processResults: function (data) {
            return {
              results:  $.map(data, function (item) {
                return {
                  text: item.school_name + ', ' + item.city + ', ' + item.state,
                  id: item.school_name
                }
              })
            };
          },
          cache: true
        }
      })

    },

    editPlayerHighSchool() {
      this.editHighSchool = this.editHighSchool ? false : true
    },

    getYearWithCohort(cohort) {
      switch (cohort) {
        case 'SR':
          return (new Date).getMonth() > 5
            ? (parseInt((new Date).getFullYear()) + 1)
            : parseInt((new Date).getFullYear())
          break;
        case 'JR':
          return (new Date).getMonth() > 5
            ? (parseInt((new Date).getFullYear()) + 2)
            : parseInt((new Date).getFullYear() + 1)
          break;
        case 'SO':
          return (new Date).getMonth() > 5
            ? (parseInt((new Date).getFullYear()) + 3)
            : parseInt((new Date).getFullYear() + 2)
          break;
        case 'FR':
          return (new Date).getMonth() > 5
            ? (parseInt((new Date).getFullYear()) + 4)
            : parseInt((new Date).getFullYear() + 3)
          break;
      }
    }
  }
}
</script>
