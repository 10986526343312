<template>
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="17.0324" cy="17.0488" r="16.2475" fill="url(#paint0_linear_3222_145596)"/>
    <path d="M22.1107 24.8015H11.4594C9.9875 24.8015 8.78503 23.599 8.78503 22.1272V11.4758C8.78503 10.004 9.9875 8.80151 11.4594 8.80151H22.1107C23.5826 8.80151 24.785 10.004 24.785 11.4758V22.1272C24.785 23.6056 23.5891 24.8015 22.1107 24.8015Z" stroke="white" stroke-width="2"/>
    <path d="M13.8837 19.7091C14.6591 20.4844 15.6907 20.9115 16.788 20.9115C17.8854 20.9115 18.9104 20.4844 19.6924 19.7091C20.4677 18.9337 20.8948 17.9021 20.8948 16.8048C20.8948 15.7074 20.4677 14.6758 19.6924 13.9005C18.917 13.1251 17.8854 12.698 16.788 12.698C15.6907 12.698 14.6591 13.1251 13.8837 13.9005C13.1084 14.6758 12.6813 15.7074 12.6813 16.8048C12.6813 17.9021 13.1084 18.9337 13.8837 19.7091Z" stroke="white" stroke-width="2"/>
    <path d="M21.6956 12.5629C22.1311 12.5629 22.4841 12.2098 22.4841 11.7744C22.4841 11.3389 22.1311 10.9858 21.6956 10.9858C21.2601 10.9858 20.9071 11.3389 20.9071 11.7744C20.9071 12.2098 21.2601 12.5629 21.6956 12.5629Z" stroke="white"/>
    <defs>
      <linearGradient id="paint0_linear_3222_145596" x1="17.0324" y1="0.80127" x2="17.0324" y2="33.2963" gradientUnits="userSpaceOnUse">
        <stop stop-color="#A92FE2"/>
        <stop offset="0.53125" stop-color="#E22F65"/>
        <stop offset="1" stop-color="#E2852F"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "InstagramIcon"
}
</script>
