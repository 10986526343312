<template>
  <div class="player-profile row" v-if="player"> <!-- is this player object not null? -->
    <div class="col-12 col-md-12 col-lg-5 col-xl-3">
      <div class="profile-card">
        <div subscription-status data-toggle="tooltip" v-if="isAuthorized && player.subscribed && role !== 'fan'"> <!-- What is subscription status? subscribed and not fan -->
          <i class="sd-subscription-icon fa fa-check subscribed"></i>
        </div>
        <div class="profile-card-section">
          <div class="text-center d-flex justify-content-center">
            <i class="rank g-icon numbered-icon" v-if="inRoster && player.position_rank">#{{ player.position_rank }}</i> <!-- show rank if roster and rank -->
            <user-avatar :user="player" />
            <!-- if for the current user's org show upload component -->
            <a href="" class="photo-icon g-icon numbered-icon" v-if="organizationid === player.profile.data.organization_id" data-dynamic-component="file-upload" data-modal-title="Update Player Photo" :data-props="'fileable_type=Profile fileable_id=' + player.profile.data.id"><i class="fa fa-photo"></i></a>
          </div>
          <div class="text-center mt-10">
            <h4 class="mb-0"> {{ player.first_name }} {{ player.last_name }}</h4>
            <p v-if="schoolData.name" class="mb-0">{{ schoolData.name }}</p>
            <p>{{ basics.city }}<span v-if="basics.city && basics.state">, </span>{{ basics.state }}</p>
            <p v-if="player.interest" style="margin-bottom: 0px">Interested in your organization</p> <!-- show if player interests -->
            <button class="org-bg btn-primary org-text see-why-btn" v-if="player.interest_reasoning !== ''" v-on:click="showInterest(player.interest_reasoning)">See Why</button> <!-- show if interest reasoning click to see why -->
          </div>
          <ul class="player-menu">
            <li v-if="isAuthorized && !high_school && role !== 'fan'" id="toggle_add_prospect"> <!-- not highschool and not fan -->
              <button id="prospect-button" @click="toggleProspect()" class="col-sm-6 col-md-6 f-size-6" style="padding-left: 5px; padding-right: 5px;">
                <span :class="{'mr-10': true, 'add-player-icon': true, 'addToProspects': ! inProspects, 'removeFromProspects': inProspects}"  style="margin-right: 5px !important"> <!-- set classes based on permissions -->
                {{ inProspects ? '-' : '+' }}</span>{{ inProspects ? 'Remove Player From' : 'Add Player to' }} Prospects
              </button>
            </li>
          </ul>
          <div v-if="isAuthorized" class="actions-dropdown text-center">
            <el-select class="theme-select" placeholder="Actions" value="">
              <el-option value="" style="padding: 0 !important;">
                <a :href="'/recruits/' + player.id + '/message'" class="actions-dropdown-item"><span class="far fa-comment-alt mr-10 message-btn" style="margin-left: 10px;"></span>Message</a>
              </el-option>
              <el-option v-if="!high_school || role !== 'fan'" value="" style="padding: 0 !important;"> <!-- show if not a hs or a role besides fan -->
                <a @click="sendContactInformation()" href="javascript:void(0)" class="actions-dropdown-item"><span class="fa fa-address-book-o mr-10" style="margin-left: 10px;"></span>Send Contact Information</a>
              </el-option>
              <el-option v-if="this.playercardflag === 'true' && !high_school || this.playercardflag === 'true' && role !== 'fan'" value="" style="padding: 0 !important;"> <!-- show if player card and not HS or player card and not fan -->
                <a :href="'/player-card/' + player.id + '?download'" class="actions-dropdown-item"><span class="fa fa-download mr-10 message-btn" style="margin-left: 10px;"></span>Download Player Card</a>
              </el-option>
            </el-select>
          </div>
          <div class="player-biography container row mt-10">
              <p class="col-6 col-sm-6 col-md-5 col-lg-4 text-right">About Me:</p>
              <p class="col-6 col-sm-6 col-md-7 col-lg-8 text-left">{{ basics.biography }} </p>
          </div>
          <div class="text-center player-progress d-flex flex-column" style="padding-top: 10px" v-if="role !== 'fan'"> <!-- show to not fans -->
            <div :class="player.profile && progress_interview && progress_videos ? 'inner-progress full-progress org-bg' : 'inner-progress'">
              <div :class="player.profile ? 'org-bg org-text round m-2' :'round m-2'" data-toggle="tooltip" data-original-title="Profile">
                <i class="fas fa-user "></i>
              </div>
              <div :class="progress_interview ? 'org-bg org-text round m-2' :'round m-2'" data-toggle="tooltip" data-original-title="Interview">
                <i class="fas fa-info"></i>
              </div>
              <div :class="progress_videos ? 'org-bg org-text round m-2' :'round m-2'" data-toggle="tooltip" data-original-title="Videos">
                <i class="fas fa-film "></i>
              </div>
            </div>
            <div class="mt-2" v-if="player.thsca_showcase === 1">
              <p>THSCA Showcase Attended</p>
            </div>
          </div>
        </div>


        <div class="profile-card-section container">
          <div class="row">
            <p class="col-6 col-sm-6 col-md-5 col-lg-4 text-right">Full Name:</p>
            <h5 class="col-6 col-sm-6 col-md-7 col-lg-8 text-left">{{ player.first_name }} {{ player.last_name }}</h5>

            <p v-if="parent_email" class="col-6 col-sm-6 col-md-5 col-lg-4 text-right">Parent Email:</p>
            <h5 v-if="parent_email" class="col-6 col-sm-6 col-md-7 col-lg-8 text-left">{{ parent_email ? parent_email : 'N/A' }}</h5>

            <p class="col-6 col-sm-6 col-md-5 col-lg-4 text-right">Primary Position:</p>
            <h5 class="col-6 col-sm-6 col-md-7 col-lg-8 text-left">{{sportBasics.primary_position}}</h5>
            <p class="col-6 col-sm-6 col-md-5 col-lg-4 text-right">Secondary Positions:</p>
            <h5 class="col-6 col-sm-6 col-md-7 col-lg-8 text-left">{{ player.profile.data.positions.data.filter(({shortcode}) => shortcode !== sportBasics.primary_position).map(p => p.shortcode).join(', ') }}</h5>

            <p v-if="high_school !== 1" class="col-6 col-sm-6 col-md-5 col-lg-4 text-right">Grad Year:</p>
            <h5 v-if="high_school !== 1" class="col-6 col-sm-6 col-md-7 col-lg-8 text-left">{{ basics.grad_year }}</h5>
            <p v-if="high_school === 1" class="col-6 col-sm-6 col-md-5 col-lg-4 text-right">Class:</p>
            <h5 v-if="high_school === 1" class="col-6 col-sm-6 col-md-7 col-lg-8 text-left">{{ getCohortWithYear(basics.grad_year) }}</h5>

            <p class="col-6 col-sm-6 col-md-5 col-lg-4 text-right">Height:</p>
            <h5 class="col-6 col-sm-6 col-md-7 col-lg-8 text-left">{{ player.profile.data.measurables.data.height || 'N/A' }}</h5>

            <p v-if="sport !== 'softball' && sport !== 'mens_soccer' && sport !== 'womens_soccer'" class="col-6 col-sm-6 col-md-5 col-lg-4 text-right">Weight:</p>
            <h5 v-if="sport !== 'softball' && sport !== 'mens_soccer' && sport !== 'womens_soccer'" class="col-6 col-sm-6 col-md-7 col-lg-8 text-left">{{ player.profile.data.measurables.data.weight || 'N/A' }}</h5>

            <p class="col-6 col-sm-6 col-md-5 col-lg-4 text-right">Hometown:</p>
            <h5 class="col-6 col-sm-6 col-md-7 col-lg-8 text-left">{{ basics.city }}<span v-if="basics.city && basics.state">, </span><span v-else>N/A</span>{{ basics.state }}</h5>

            <p class="col-6 col-sm-6 col-md-5 col-lg-4 text-right">School:</p>
            <div class="d-flex col-6 col-sm-6 col-md-7 col-lg-8">

              <h5 class="text-left" style="margin-right: 3px">{{ schoolData.name || 'N/A' }}</h5>
              <SchoolDataTooltip
                :street="schoolData.street"
                :zip="schoolData.zip"
                :countyName="schoolData.countyName"
                :phone="schoolData.phone"
                :url="schoolData.url"
              />

            </div>

            <p v-if="sport.includes('soccer')" class="col-6 col-sm-6 col-md-5 col-lg-4 text-right">League:</p>
            <h5 v-if="sport.includes('soccer')" class="col-6 col-sm-6 col-md-7 col-lg-8 text-left">{{ sportBasics.league_id ? sportBasics.leagues_list.filter(league => league.id === sportBasics.league_id)[0].name : 'N/A' }}</h5>
            <p v-if="sportBasics.league_state" class="col-6 col-sm-6 col-md-5 col-lg-4 text-right">League State:</p>
            <h5 v-if="sportBasics.league_state" class="col-6 col-sm-6 col-md-7 col-lg-8 text-left">{{ sportBasics.league_state }}</h5>

            <p class="col-6 col-sm-6 col-md-5 col-lg-4 text-right">Jersey #:</p>
            <h5 class="col-6 col-sm-6 col-md-7 col-lg-8 text-left">{{ sportBasics.jersey_num || 'N/A' }}</h5>

            <p v-if="sport.includes('softball')" class="col-6 col-sm-6 col-md-5 col-lg-4 text-right">Tournaments:</p>
            <h5 v-if="sport.includes('softball')" class="col-6 col-sm-6 col-md-7 col-lg-8 text-left">{{ sportBasics.tournaments || 'N/A' }}</h5>

            <p v-if="sport.includes('softball')" class="col-6 col-sm-6 col-md-5 col-lg-4 text-right">Previous Affiliates:</p>
            <h5 v-if="sport.includes('softball')" class="col-6 col-sm-6 col-md-7 col-lg-8 text-left">{{ sportBasics.previous_affiliates || 'N/A' }}</h5>

            <p v-if="sport.includes('softball')" class="col-6 col-sm-6 col-md-5 col-lg-4 text-right">Current Affiliates:</p>
            <h5 v-if="sport.includes('softball')" class="col-6 col-sm-6 col-md-7 col-lg-8 text-left">{{ sportBasics.current_affiliates || 'N/A' }}</h5>

            <p v-if="role !== 'fan' && isAuthorized" class="col-6 col-sm-6 col-md-5 col-lg-4 text-right">Public Link:</p>
            <div v-if="role !== 'fan' && isAuthorized" class="col-6 col-sm-6 col-md-7 col-lg-8 text-left">
              <div>
                <div v-if="player.is_public">
                  <h5 class="d-inline m-r-10" :data-copy="getShareLink()" style="cursor:pointer;">Copy</h5>
                  <h5 class="d-inline text-left">
                    <a v-bind:href="getShareLink()" target="_blank">
                      Go to Profile
                    </a>
                  </h5>
                </div>
                <div v-if="!player.is_public">
                  <h5><i class="fas fa-lock "></i> Private Profile</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="profile-card-section text-center border-bottom-0">
          <a v-if="sportBasics.hudl_link" :href="'//'+ sportBasics.hudl_link" class="btn btn-dark text-white" target="_blank"><i class="fas fa-football-ball mr-10"></i>View Hudl</a>
          <a v-if="sportBasics.highlight_first_url" :href="'//'+ sportBasics.highlight_first_url" class="btn btn-dark text-white" target="_blank"><i class="ti-video-clapper mr-10"></i>View Highlight 1</a>
          <a v-if="sportBasics.highlight_second_url" :href="'//'+ sportBasics.highlight_second_url" class="btn btn-dark text-white" target="_blank"><i class="ti-video-clapper mr-10"></i>View Highlight 2</a>
          <a v-if="sportBasics.onthree_url" :href="'//'+ sportBasics.onthree_url" class="btn btn-dark text-white" target="_blank"><i class="fas fa-football-ball mr-10"></i>View On3</a>
          <a v-if="player.footage.data.film.length > 0 && role !== 'fan'" :href="`/film-room?compare=${player.id}`" class="btn btn-dark text-white"><i class="ti-video-clapper mr-10"></i>Film Room</a>
          <div class="soc-media p-b-10" style="padding-top: 25px;">
            <ul class="profile__social text-center">
              <li>
                <a class="twitter" v-if="basics.twitter" :href="'https://twitter.com/' + basics.twitter" target="_blank"><i class="fa fa-twitter"></i></a>
              </li>
              <li>
                <a class="instagram" v-if="basics.instagram" :href="'https://instagram.com/' + basics.instagram.replace('@','')" target="_blank"><i class="fa fa-instagram"></i></a>
              </li>
              <li>
                <a class="tiktok" v-if="basics.tiktok" :href="'https://tiktok.com/' + basics.tiktok" target="_blank"><i class="fab fa-tiktok"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <PlayerProfileContent
      v-bind="{
        player,
        role,
        comments,
        attributeTables,
        authuser,
        alertableid,
        selected_position,
        sport,
        profileIsLoading,
        organizationid,
        endorsements,
        updating,
        high_school,
        pitchTypes,
        selectedVideo,
        baseballStats,
        statsFromDay,
        statsFromDaysInMonth,
        statsFromMonth,
        statsFromYear,
        StatsFromDate,
        statsToDay,
        statsToDaysInMonth,
        statsToMonth,
        statsToYear,
        statsToDate,
        submitStatsFilter,
        statsFiltered,
        getDisplayName,
        getFilteredStats,
        isFilterDisabled,
        getStatDataForType,
        getSluggerScienceData
      }"
      :isAuthorized="authorized"
    />
  </div>
</template>

<script>
  import axios from 'axios';
  import Player from '@lib/Player';
  import stats from '@lib/baseball-stats.js';
  import UserAvatar from '@components/UserAvatar';
  import PlayerVideo from '@components/PlayerVideo';
  import PlayerImage from '@components/PlayerImage';
  import SchoolDataTooltip from "@components/SchoolDataTooltip";
  import SluggerScienceData from "@components/SluggerScienceData";
  import QuestionMarkIcon from '@components/svg/QuestionMarkIcon';
  import PlayerProfileContent from '@components/PlayerProfile/components/PlayerProfileContent';

  export default {
    components: {SchoolDataTooltip, SluggerScienceData, PlayerVideo, PlayerImage, UserAvatar, PlayerProfileContent, QuestionMarkIcon },
    name: 'PlayerProfile',
    props: ['playerid', 'role','authuser','organizationid', 'high_school', 'alertableid','coachname','coachphone','coachemail', 'playercardflag', 'comments', 'sportid', 'parent_email', 'publicprofile', 'authorized'],
    data() {
      return {
        player: false,
        openInfo: false,
        openSelect: false,
        selectedOption: 'Attributes',
        selected_position: "",
        hash: 'attr',
        inRoster: false,
        inProspects: false,
        updating: false,
        addingFilm: false,
        displayTitles: null,
        progress_profile: false,
        progress_videos: false,
        progress_interview: false,
        profileIsLoading: true,
        activeProfile: 'player',
        sport: 'football',
        showProDay: false,
        showInterviews: false,
        showAdditionalFootage: false,
        showSluggerScienceData: false,
        showNotes: false,
        showEvents: false,
        selectedVideo: null,
        profileToken: null,
        statData: stats,
        baseballStats: {},
        pitchTypes: [],
        statsFromDay: null,
        statsFromDaysInMonth: 31,
        statsFromMonth: null,
        statsFromYear: null,
        StatsFromDate: null,
        statsToDay: null,
        statsToDaysInMonth: 31,
        statsToMonth: null,
        statsToYear: null,
        statsToDate: null,
        submitStatsFilter: false,
        statsFiltered: false,
        endorsements: null,
        values: null,
        sluggerScienceData: [],
        addToProspectsBeforeSubscriptionEnded: null,
        QuestionMarkIcon,
        SchoolDataTooltip
      }
    },
    mounted: function() {
      this.getProspectValue()
      this.getRecruit()
      this.getEndorsements()
      this.setRank()

      this.$root.$on('update', async () => {
        this.updating = false
        await this.getRecruit()
      })

      // Add Film Button Toggle
      $('body').on('click', '.add-film', function (e) {
        e.preventDefault();
        $(this).hide()
        $('.add-footage').show()
        $('.success').hide();
        $('.initial-position').hide();
      });

      // Interview Questions Toggle
      $('body').on('click', '.int-questions_btn', function(e) {
        e.preventDefault();
        $(this).hide();
        $('.add-questions').show();
        $('.int-question_success').hide();
      });

      $('body').on('click', '.add-footage_btn', function(e) {
        e.preventDefault();
        $(this).hide();
        $('.additional-footage_wrap').show();
        $('.add-footage_success').hide();
      });
      // this.loadTabs();
    },
    watch: {
        statsToMonth: function() {
          this.statsToDaysInMonth = new Date(this.statsToYear, this.statsToMonth, 0).getDate()
          if (this.statsToDaysInMonth < this.statsToDay) {
            this.statsToDay = this.statsToDaysInMonth
          }
        },
        statsFromMonth: function() {
          this.statsFromDaysInMonth = new Date(this.statsFromYear, this.statsFromMonth, 0).getDate()
          if (this.statsFromDaysInMonth < this.statsFromDay) {
            this.statsFromDay = this.statsFromDaysInMonth
          }
        }
    },
    computed: {
      basics() {
        return this.player?.profile?.data?.basics?.data;
      },
      sportBasics() {
        return this.player?.profile?.data?.basics?.data?.sportBasics?.data;
      },
      isAuthorized() {
        return this.authorized;
      },
      attributeTables() {
        const sport = this.sport;
        var profile = this.player.profile.data;
        const playerObj = new Player({ player: this.player, sport: this.sport })
        var altProfile = profile;
        var useDefault = true;
        // @TODO REDO THIS SUCKER
        switch(sport) {
          case 'mens_soccer':
          case 'womens_soccer': return [
            {
              key: 'measurables',
              data: {
                height: profile.measurables.data.height,
                dominant_foot: profile.basics.data.sportBasics.data.dominant_foot,
              }
            },
            {
              key: 'technical testing',
              data: {
                figure_eight_with_ball: profile.basics.data.sportBasics.data.figure_eight_with_ball,
                figure_eight_without_ball: profile.basics.data.sportBasics.data.figure_eight_without_ball,
                five_ten_shuttle_with_ball: profile.basics.data.sportBasics.data.five_ten_shuttle_with_ball,
                five_ten_shuttle_without_ball: profile.basics.data.sportBasics.data.five_ten_shuttle_without_ball,
              }
            },
            {
              key: 'gk technical assessment',
              data: {
                four_by_four_foundation_box_footwork: profile.basics.data.sportBasics.data.four_by_four_foundation_box_footwork,
                four_by_four_foundation_box_handling: profile.basics.data.sportBasics.data.four_by_four_foundation_box_handling,
                gk_low_dive: profile.basics.data.sportBasics.data.gk_low_dive,
                gk_collapse_dive: profile.basics.data.sportBasics.data.gk_collapse_dive,
                three_way_shot_stopping: profile.basics.data.sportBasics.data.three_way_shot_stopping,
              }
            },
            {
              key: 'fitness testing',
              data: {
                the_forty_without_ball: profile.basics.data.sportBasics.data.the_forty_without_ball,
                beep_test: profile.basics.data.sportBasics.data.beep_test,
                yoyo_test: profile.basics.data.sportBasics.data.yoyo_test,
                coopers_test: profile.basics.data.sportBasics.data.coopers_test,
                three_hundred_m_shuttle: profile.basics.data.sportBasics.data.three_hundred_m_shuttle,
                one_mile_time: profile.basics.data.sportBasics.data.one_mile_time,
              }
            },
            {
              key: 'additional testing',
              data: {
                power_shot: profile.basics.data.sportBasics.data.power_shot,
                placement_shot: profile.basics.data.sportBasics.data.placement_shot,
                precision_shot: profile.basics.data.sportBasics.data.precision_shot,
                vertical_jump: profile.basics.data.sportBasics.data.vertical_jump,
              }
            },
            {
              key: 'academics',
              data: {
                unweighted_gpa: profile.academics.data.unweighted_gpa,
                desired_major: profile.academics.data.desired_major,
                act: profile.academics.data.act,
                sat: profile.academics.data.sat,
                ncaa_id: profile.academics.data.ncaa_id,
                transcript: profile?.academics?.data?.transcript?.uri,
              }
            },
          ];
          case 'softball': {
              altProfile = [
                {
                  key: 'measurables',
                  data: {
                    height: profile.measurables.data.height,
                    bats: profile.basics.data.sportBasics.data.bats,
                    throws: profile.basics.data.sportBasics.data.throws,
                    wingspan: profile.measurables.data.wing_span,
                    handsize: profile.measurables.data.hand_size,
                  }
                },
                {
                  key: 'pitching',
                  data: {
                    pitch_types: playerObj.getTypesForRender()?.pitches,
                    pitch_velocity: playerObj.getVelocitiesForRender()?.pitches + ' mph',
                  }
                },
                {
                  key: 'fielding velocity',
                  data: {
                    catcher_to_second: profile.basics.data.sportBasics.data.catcher_to_second + ' mph',
                    catcher_to_second_pop_up_time: profile.basics.data.sportBasics.data.catcher_to_second_pop_up_time + ' seconds',
                    third_to_first: profile.basics.data.sportBasics.data.third_to_first + ' mph',
                    ss_to_first: profile.basics.data.sportBasics.data.ss_to_first + ' mph',
                    of_to_home: profile.basics.data.sportBasics.data.of_to_home + ' mph',
                  }
                },
                {
                  key: 'testing',
                  data: {
                    home_to_home: profile.basics.data.sportBasics.data.home_to_home + ' seconds',
                    second_to_home: profile.basics.data.sportBasics.data.second_to_home + ' seconds',
                    strength: profile.basics.data.sportBasics.data.strength,
                  }
                },
                {
                  key: 'academics',
                  data: {
                    unweighted_gpa: profile.academics.data.unweighted_gpa,
                    act: profile.academics.data.act,
                    sat: profile.academics.data.sat,
                    ncaa_id: profile.academics.data.ncaa_id,
                    transcript: profile?.academics?.data?.transcript?.uri,
                  }
                },
              ];
              useDefault = false;
              break;
            }
          case 'baseball': {
            altProfile = [
              {
                key: 'measurables',
                data: {
                  height: profile.measurables.data.height,
                  weight: profile.measurables.data.weight,
                  bats: profile.basics.data.sportBasics.data.bats,
                  throws: profile.basics.data.sportBasics.data.throws,
                  wingspan: profile.measurables.data.wing_span,
                  handsize: profile.measurables.data.hand_size,
                }
              },
              {
                key: 'pitching',
                data: {
                  pitch_types: playerObj.getTypesForRender()?.pitches,
                  pitch_velocity: playerObj.getVelocitiesForRender()?.pitches + ' mph',
                }
              },
              {
                key: 'fielding velocity',
                data: {
                  catcher_to_second: profile.basics.data.sportBasics.data.catcher_to_second + ' mph',
                  third_to_first: profile.basics.data.sportBasics.data.third_to_first + ' mph',
                  ss_to_first: profile.basics.data.sportBasics.data.ss_to_first + ' mph',
                  of_to_home: profile.basics.data.sportBasics.data.of_to_home + ' mph',
                }
              },
              {
                key: 'testing',
                data: {
                  home_to_first: profile.basics.data.sportBasics.data.home_to_first + ' seconds',
                  sixty_yard_dash: profile.basics.data.sportBasics.data.sixty_yard_dash + ' seconds',
                  strength: profile.basics.data.sportBasics.data.strength,
                }
              },
              {
                key: 'academics',
                data: {
                  unweighted_gpa: profile.academics.data.unweighted_gpa,
                  act: profile.academics.data.act,
                  sat: profile.academics.data.sat,
                  ncaa_id: profile.academics.data.ncaa_id,
                  transcript: profile?.academics?.data?.transcript?.uri,
                }
              },
            ];
            useDefault = false;
            break;
          }
          default:
          {
            // default, football is currently a default, use the normal profile
            break;
          }
        }

        if (useDefault){
          return profile;
        }else{
          // additional checks
          const isFielding = (element) => element.key === 'fielding velocity';
          const isPitching = (element) => element.key === 'pitching';
          // if just a pitcher (only pitchers will also have batter) then don't show fielding
          // updated to <= from === because a pitcher is not guaranteed to have hitter
          if (profile.positions.data.length <= 2 &&
              profile.positions.data.filter(attribute => attribute.name === 'pitcher')){
            altProfile.splice(altProfile.findIndex(isFielding),1);
          }

          // if not a pitcher don't show pitching
          if (this.player.profile.data.positions.data.filter(element =>
              element.name === "Pitcher").length === 0) {
            altProfile.splice(altProfile.findIndex(isPitching),1);
          }
          return altProfile;
        }
      },
      schoolData() {
        const baseSchoolData = this.basics.school?.data;
        return {
          name: baseSchoolData?.name,
          street: baseSchoolData?.street,
          zip: baseSchoolData?.zip,
          countyName: baseSchoolData?.data?.countyname,
          phone: baseSchoolData?.phone,
          url: baseSchoolData?.url,
        };
      }
    },
    methods: {
      getPlayerUrl() {
        var pub = this.publicprofile ? '/public' : '';
        var url = '/api/players/' + this.playerid + pub + '?include=footage,profile.positions,profile.revisions,profile.basics.school';
        if (this.sportid > 0) { url = url + '&sport=' + this.sportid; }
        return url;
      },
      isFilterDisabled() {
        if (this.statsFromDay != null && this.statsFromMonth != null && this.statsFromYear != null && this.statsToDay != null && this.statsToMonth != null && this.statsToYear != null) {
          return false
        } else {
          return true
        }
      },
      async getSluggerScienceData() {
        const response = await axios.get(`/api/user/${this.player.id}/slugger-science-profile`);
        this.setSluggerScienceData(response.data.data);
      },
      setSluggerScienceData(data) {
        this.sluggerScienceData = data;
        this.showSluggerScienceData = true;
      },
      setActiveProfile() {
        this.activeProfile = this.player.profile.data.organization_id ? 'org' : 'player';
      },
      setInProspects(value){
        this.inProspects = value;

        window.EventBus.publish('sds_handleUpdateInProspects', {
          inProspects: this.inProspects,
        });
      },
      async getRecruit() {
        await axios.get(this.getPlayerUrl()).then(response => {
          this.player = response.data.data
          this.inRoster = this.player.inRoster
          this.sport = this.player.current_sport
          this.addedToProspectsAt = this.player.addedToProspectsAt
          this.addToProspectsBeforeSubscriptionEnded = this.player.added_to_prospects_before_supscription_ended
          this.profileToken = this.player.public_profile_token
          this.$nextTick(function () {
              this.visibleTab()
          })
        }).then(async () => {
          this.profileIsLoading = false;
          this.setActiveProfile();
          this.getProgress();
          this.getVideos();
          this.displayTitles = this.sportBasics.displayTitles
          if (this.$store.state.user.role === 'super admin' || this.$store.state.user.role === 'player') {
            $('[subscription-status], [data-toggle="tooltip"]').tooltip();
          }
          if (['softball','baseball'].includes(this.sport)) {
            await this.getStats()
            await this.fetchPitchGrips();
          }
        }).catch(error => {
          console.log(error)
          toastr.error('We encountered an error retrieving player information.', 'Error!');
        })
      },
      getVideos() {
        axios.get(this.getPlayerUrl()).then(response => {
          const progressFootage = response.data.data.footage.data
          this.progress_videos = progressFootage.film.length
          this.progress_interview = progressFootage.interviews.length
        })
      },
      getProgress() {
        if (this.isAuthorized) {
          axios.get('/api/players/' + this.playerid + '/progress').then(response => {
            this.progress_profile = response.data.progress_profile ? 1 : 0
            this.progress_videos = response.data.progress_videos ? 1 : 0
            this.progress_interview = response.data.progress_interview ? 1 : 0
          });
        }
      },
      async getStats() {
        if (this.isAuthorized) {
          const { id } = this.player.profile.data
          const { data } = await axios.get(`/api/profile/${id}/stats/total`)
          this.baseballStats = { ...data }
        }
      },
      async getFilteredStats() {
        const { id } = this.player.profile.data
        let fromDate = `${this.statsFromDay}-${this.statsFromMonth}-${this.statsFromYear}`
        let toDate = `${this.statsToDay}-${this.statsToMonth}-${this.statsToYear}`
        if(new Date(fromDate).getTime() > new Date(toDate).getTime() ) {
          bootbox.alert('From date must be before to date')
        } else {
          const { data } = await axios.post(`/api/profile/${id}/stats/filter`, {
            startDate: fromDate,
            endDate: toDate
          })
          this.baseballStats = { ...data }
          this.statsFiltered = true

          fromDate = new Date(this.statsFromYear, this.statsFromMonth - 1, this.statsFromDay).toDateString()
          fromDate = fromDate.split(' ')
          this.StatsFromDate = `${fromDate[1]} ${fromDate[2]}, ${fromDate[3]}`

          toDate = new Date(this.statsToYear, this.statsToMonth - 1, this.statsToDay).toDateString()
          toDate = toDate.split(' ')
          this.statsToDate = `${toDate[1]} ${toDate[2]}, ${toDate[3]}`
          this.closeFilterModal()
        }
      },
      closeFilterModal() {
        document.getElementById('closeFilterButton')?.click()
      },
      getShareLink() {
        return 'https://'+ window.location.host + '/public-profile/user/'+ this.player.id +'/sport/'+ this.player.sport_id
      },
      getStatDataForType(type) {
        return this.statData.filter(({ type: t }) => type === 'batting' ? t === 'B' : t === 'P' )
      },
      executeProspectToggle() {
          let data = {
            player_id: this.player.id,
            email: this.player.email,
            inProspects: this.inProspects,
          }
          axios
            .post("/api/college-prospects/" + this.player.id + "/toggle-prospect", data)
            .then(response => {
            if (response.data) {
              toastr.success('Player added to prospects list', 'Added Player');
              this.setInProspects(true)
            } else {
              toastr.warning(`Player removed from prospects list${this.inRoster ? ' and roster' : ''}`, 'Removed Player');
              this.setInProspects(false)
              this.inRoster = false
            }
          })
      },
      getProspectValue() {
        axios.get("/api/college-prospects/" + this.playerid + "/get-in-prospect-value")
          .then(response => {
            this.inProspects = response.data
          })
      },
      toggleProspect() {
        if (this.inProspects) {
          Swal.fire({
            title: 'Remove from prospects list?',
            text: `This will remove the player and any attributes associated with the player from your prospects list${this.inRoster ? ' and roster' : ''}.`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, remove player!',
            cancelButtonText: 'No, cancel!',
            confirmButtonClass: 'btn btn-confirm mt-2',
            cancelButtonClass: 'btn btn-cancel ml-2 mt-2',
            reverseButtons: true
          }).then((result) => {
            if (result.value) {
              this.executeProspectToggle()
              $('#home').tab('show')
            } else null
          })
        } else {
          this.executeProspectToggle()
        }
      },
      async fetchPitchGrips() {
        if (this.isAuthorized) {
          await axios.get('/api/pitch-types?user_id=' + this.player.id).then(response => {
            this.pitchTypes = response.data.data.filter(pitchType => pitchType.hasOwnProperty('user-videos'));
          })
        }
      },
      sendContactInformation() {
        Swal.fire({
          title: 'Send this player your contact information?',
          text: "This will send an automated message to the player, and send you to that players messages.",
          type: 'question',
          showCancelButton: true,
          confirmButtonText: 'Send contact information!',
          cancelButtonText: 'Cancel!',
          confirmButtonClass: 'btn btn-confirm mt-2',
          cancelButtonClass: 'btn btn-cancel ml-2 mt-2',
          reverseButtons: true
        }).then((result) => {
          if (result.value) {

            const message =
            this.coachname + ' has chosen to send you their contact information: \nEmail: ' + this.coachemail + (!!this.coachphone ? '\nPhone: ' + this.coachphone : '')

            const body = {
              message: message,
              user_id: this.player.id
            }

            return axios.post('/api/messages?include=messages,users.profile', body).then(response => {
              Swal.fire('Information Sent!', 'Your contact information has been sent to the player.', 'success')
              setTimeout(() => window.location = '/recruits/' + this.player.id + '/message', 800)
            })
          }
          else if (result.dismiss === Swal.DismissReason.cancel) { }
          })
      },
      setRank() {
        if (this.player.position_rank) {
          $('.rank').text('#' + this.player.position_rank)
        } else {
          $('.rank').hide()
        }
      },
      visibleTab() {
        this.hash = $(location).attr('hash').length > 0 ? $(location).attr('hash').split('#')[1] : this.hash
        $(`#nav-${this.hash}`).trigger('click')

        if (!this.showInterviews && this.hash === "int") {
          Swal.fire({
            title: 'No interviews available'
          }).then((result) => {
            if (result.value) {
              $(`#nav-attr`).trigger('click')
            }
          });
        }
        // this.loadTabs();
      },
      showInterest(interest) {
        bootbox.alert(`'${interest}'`);
      },
      getCohortWithYear(year) {
        let date = (new Date).getMonth() > 5
          ? (parseInt((new Date).getFullYear()) + 1)
          : parseInt((new Date).getFullYear())

        switch (parseInt(year)) {
            case date:
                return 'SR';
                break;
            case date + 1:
                return 'JR';
                break;
            case date + 2:
                return 'SO';
                break;
            case date + 3:
                return 'FR';
                break;
        }

        if (year > date + 4) {
            return 'FR';
        } else {
            return 'SR';
        }
      },
      setEvents() {
        this.showEvents = true
        this.$nextTick(() => {
          this?.$refs['events']?.$refs['event-schedule']?.$refs['full-calendar']?.getApi()?.render()
        })
      },
      getEndorsements() {
        if (this.isAuthorized) {
          axios.get('/api/endorsements/' + this.playerid + '?include=file_upload').then(response => {
            this.endorsements = response.data.data;
          }).catch(error => console.log('error -->', error));
        }
      },
      getDisplayName(attributeKey){
        if(this.displayTitles){
          if(Object.keys(this.displayTitles).includes(attributeKey)){
            return this.displayTitles[attributeKey]?.toUpperCase()
          }else{
            return attributeKey.replace(/_/g, ' ').toUpperCase();
          }
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .stat-abbrev {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
  }

  .stats-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .stats-filter-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .stats-dropdown-filter {
    border-top: none;
    border-left: none;
    border-right: none;
  }

  .stats-row-testing {
    flex-basis: 25% !important;
  }

  @media screen and (max-width: 813px) {
    .stats-row-testing {
      flex-basis: 0 !important;
    }
  }

  .actions-dropdown-item {
    display: block;
    height: 100%;
    padding-right: 10px;
  }

  .slugger-img {
    position: absolute;
    top: 10px;
  }

  .slugger-title {
    margin-left: 50px;
  }

  .el-scrollbar__bar {
    z-index: -1;
  }
  #prospect-button {
    background: transparent;
    border: 0;
    padding-left: 5px;
    padding-right: 5px;
    text-align: start;
    min-width: 100%;
  }
</style>
