<template>
  <data-tables-server id="PlayerTable" :class="{'d-none': !visible}"
    :data="players"
    :total="count"
    :pagination-props="{
      pageSizes: [10, 25, 50, 100],
      background: true,
      layout: 'prev, pager, next, sizes, ->, total'
    }"
    class="table"
    @query-change="loadData"
  >
    <el-table-column label="Name" sort-by="name" sortable>
      <template slot-scope="{ row: { name, id } }">
        <span class="coach-name">
          <a :href="`/recruit/${id}`">{{ name }}</a>
        </span>
      </template>
    </el-table-column>
    <el-table-column label="Email" sort-by="email" sortable v-if="canUpdate">
      <template slot-scope="{ row: { email } }">{{ email }}</template>
    </el-table-column>
    <el-table-column label="Actions" v-if="canUpdate" align="right">
      <template slot-scope="{ row: { id } }">
        <a :href="`/recruit/${id}`" class="edit">
          <i class="mdi mdi-eye"></i>
        </a>
      </template>
    </el-table-column>
  </data-tables-server>
</template>
<script>
import DataTablesServer from '@components/ui/DataTablesServer'

export default {
  name: 'PlayersTable',
  components: { DataTablesServer },
  props: {
    role: {type: String, default: "", required: true},
    organizationId: {type: String, default: "", required: true},
    canUpdateOrg: {type: String, default: "", required: true}
  },
  data() {
    return {
      visible: false,
      url: `/api/organizations/${this.organizationId}/get-players`,
      previousQuery: null,
      apiData: null,
      loading: false,
      sportname: null,
    }
  },
  computed: {
    players: v => _.get(v, 'apiData.data', []),
    count: v => _.get(v, 'apiData.meta.pagination.total', 0),
    canUpdate: v => ['coach','sport admin','super admin'].includes(v.role)
  },
  methods: {
    async loadData(query) {
      this.previousQuery = query
      this.loading = true
      const { page, pageSize: length } = query
      const sportname = this.sportname;
      const data = { page, length, sportname }

      const url = `${this.url}?${new URLSearchParams(data).toString()}`
      const response = await axios.get(url)
      this.apiData = response.data
      this.loading = false
    },
  }, 
  created() {
    window.EventBus.subscribe('sds_handleAdminSportTabChange', (data) => {
      this.sportname = data.sportname;
      this.loadData(this.previousQuery);
    });
  }
}
</script>

<style scoped>

</style>
