<template>
  <div class="error-container">
    <div class="error-modal">
      <div class="error-header">
        <h5 class="header-text">Error sending invitation</h5>
      </div>
      <div class="error-body">
        <h1>Your invitations have not been sent</h1>
        <p>{{ error_message }}</p>
      </div>
      <div class="error-footer">
        <button @click="$emit('invitation_complete')" class="btn btn-primary">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import { BContainer, BRow, BCol } from "bootstrap-vue";
export default {
  name: "ErrorMessage",
  components: {BContainer,BRow,BCol},
  props: {
    error_message: { type: String, required: true }
  },
  data: () => ({

  })
}
</script>
<style scoped>
.error-container {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-header {
  background:black;
  color:white;
  font-family: KairosSans,sans-serif;
  text-transform:uppercase;
  border-top:5px solid #E7F818;
  border-radius:5px 5px 0px 0px;
  padding: 1rem 2rem;
}

.header-text {
  color: white;
  position: relative;
  top: 5px;
}

.error-body {
  padding: 2rem;
  background:#f0f0f0;
  text-align: center;
}

.error-footer {
  padding: 1rem 2rem;
  background: #f9f9f9;
  border-bottom: 5px #646464;
  border-radius: 0 0 5px 5px;
  display: flex;
  justify-content: flex-end;
}

.btn {
  font-family: KairosSans,sans-serif;
  text-transform:uppercase;
}
</style>
