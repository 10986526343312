<template>
  <fragment>
    <div :class="`drawer-background ${isOpen ? 'drawer-background--open ' : ''}`" @click="$emit('onClose')" />
    <div :class="`drawer-body ${isOpen ? 'drawer-body--open ' : ''}`">
      <IconButton class="drawer-close" @onClick="$emit('onClose')" title="Close Drawer">
        <CloseIcon />
      </IconButton>
      <div class="drawer-header">
        <h2 class="drawer-title">{{ heading }}</h2>
        <p v-if="description" class="drawer-description">{{ description }}</p>
      </div>
      <slot></slot>
    </div>
  </fragment>
</template>

<script>
  import { Fragment } from 'vue-frag';
  import IconButton from '@components/IconButton';
  import CloseIcon from "@components/svg/CloseIcon";

  export default {
    name: 'Drawer',
    props: {
      isOpen: false,
      heading: null,
      description: null,
    },
    components: { IconButton, CloseIcon, Fragment },
    mounted() {
      window.addEventListener('keydown', (event) => {
        if (this.isOpen && event.key === 'Escape') {
          this.$emit('onClose');
        }
      });
    },
    beforeDestroy() {
      window.removeEventListener('keydown', (event) => {
        if (this.isOpen && event.key === 'Escape') {
          this.$emit('onClose');
        }
      });
    }
  }
</script>

<style scoped lang="scss">
.drawer-background {
  background: transparent;
  height: 100vh !important;
  left: 0;
  position: fixed;
  transition: 250ms;
  top: 0;
  width: 100vw !important;
  z-index: -5;

  &.drawer-background--open {
    background: rgba(0, 0, 0, 0.7) no-repeat center;
    background-blend-mode: darken;
    background-size: cover;
    transition: 250ms;
    z-index: 9999;
  }
}

.drawer-body {
  background: #141414;
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 450px;
  overflow-y: auto;
  padding: 50px;
  position: fixed;
  right: -100%;
  top: 0;
  transition: 350ms;
  width: 100%;
  z-index: 9999;

  &.drawer-body--open {
    right: 0;
  }
}
.drawer-close {
  background: transparent;
  border: 0;
  position: absolute;
  top: 16px;
  left: 16px;
  font-size: 2rem;
  line-height: 1;
  color: #fff;
}
.drawer-header {
  margin-bottom: 2rem;
}
.drawer-title {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}
.drawer-description {
  color: #A5A5A5;
  font-size: 14px;
  font-weight: 400;
  font-family: DINPro, sans-serif;
}
</style>
