<template>
  <div class="coaches-connect-bar">
      <h3 v-if="!is_owner" class="float-right">
        <a 
          type="button" 
          class="btn pl-4 pr-4" 
          v-bind:class="[!getInProspects ? 'btn-primary' : 'btn-danger']" 
          @click="handleAddProspect"
          v-text="(!getInProspects) ? 'Add To Prospects' : 'Remove From Prospects'" 
        ></a>
        <a type="button" class="btn btn-secondary pl-4 pr-4" :href="'/recruits/' + playerid + '/message'">Message</a>
      </h3>
      <h3 v-else  class="float-right">
        <a type="button" class="btn btn-secondary pl-4 pr-4" :href="'/organizations/'+ organization_id +'/prospectlist/'+ prospectlist.data.id +'/edit'">Edit Prospect List</a>
      </h3>
  </div>
</template>

<script>
  export default {
    props: {
      high_school: {type: Number, default: null, required: false},
      role: {type: String, default: '', required: false},
      playerid: {type: String, default: '', required: false},
      is_owner: {type: String, default: 'false', required: false},
      organization_id: {type: String, default: '', required: false},
      prospectlist: {type: Object, default() {return {}}, required: false},
    },

    data() {
      return {
        inProspects: false,
      };
    },

    computed: {
      getInProspects: ({inProspects}) => {return inProspects},
    },

    methods: {
      handleAddProspect(){
        $('#toggle_add_prospect a')[0].click();
      },
      handleUpdateInProspects(data){
        this.inProspects = data.inProspects;
      },
    },
    created() {
      window.EventBus.subscribe('sds_handleUpdateInProspects', (data) => {
        this.handleUpdateInProspects(data);
      });
    },
  };
</script>

<style scoped>

</style>
