<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.4235 1.05294L10.3568 0.986249L10.2901 1.05294L6.16533 5.17773L6.09864 5.24442L6.16533 5.31111L6.87244 6.01822L6.93913 6.08491L7.00582 6.01822L10.3568 2.66722L13.2876 5.59797L9.93657 8.94897L9.86988 9.01566L9.93657 9.08235L10.6437 9.78945L10.7104 9.85614L10.7771 9.78945L14.9018 5.66466L14.9685 5.59797L14.9018 5.53128L10.4235 1.05294ZM1.09774 10.2453L1.03105 10.312L1.09774 10.3787L5.57608 14.857L5.64277 14.9237L5.70946 14.857L9.83425 10.7323L9.90094 10.6656L9.83425 10.5989L9.12714 9.89177L9.06045 9.82508L8.99376 9.89177L5.64277 13.2428L2.71202 10.312L6.06302 6.96102L6.12971 6.89433L6.06302 6.82764L5.35591 6.12053L5.28922 6.05384L5.22253 6.12053L1.09774 10.2453ZM9.95211 5.29556L9.88542 5.22887L9.81872 5.29556L5.34038 9.7739L5.27369 9.84059L5.34038 9.90728L6.04749 10.6144L6.11418 10.6811L6.18087 10.6144L10.6592 6.13605L10.7259 6.06936L10.6592 6.00267L9.95211 5.29556Z" fill="#454545" stroke="#454545" stroke-width="0.188628" stroke-linecap="square"/>
  </svg>
</template>

<script>
export default {
  name: "SocialLinkIcon"
}
</script>
