<template>
  <span class="form-inline pull-right" id="basic-filters" v-if="showFilters">
    <button
      v-if="showClear"
      @click="resetFilters"
      class="btn waves-effect waves-light btn-danger reset-btn"
    >Clear</button>
    <el-select id="position_search" class="theme-select m-l-5 m-r-5"
      v-model="filters.position_search"
      placeholder="ANY POSITION"
      filterable
      @change="emitFilterChange"
    >
      <el-option v-for="(position, code) in positions"
        :key="position"
        :label="position"
        :value="code"
      >{{ position }}</el-option>
    </el-select>
    <el-select id="grad_year_search" class="theme-select m-r-5" multiple placeholder="ANY YEAR" filterable
      v-model="filters.grad_year_search"
      @change="emitFilterChange"
    >
      <el-option v-for="i in 8"
        :key="`year_${i}`"
        :value="year - 1 + i"
        :label="`${year - 1 + i}`"
        class="theme-font"
      />
    </el-select>
    <input type="text" id="name" class="form-control m-r-5" placeholder="Search..." v-model="filters.name" v-on:keyup="onNameChange"/>
    <!-- TODO: SDS-1693 will implement future implementation -->
    <!-- <input type="button" id="advanced_searchBtn" value="Advanced" class="btn waves-effect waves-light btn-primary" @click="toggleAdvancedSearch"/> -->
  </span>
</template>

<script>
let timeout
export default {
  name: 'BasicSearch',
  props: {},
  data() {
    return {
      positions: window.signingDayConstants['user_positions'],
      year: new Date().getFullYear(),
      filters: {
        position_search: '',
        grad_year_search: [],
        name: "",
      }
    }
  },
  created() {
    window.onbeforeunload = this.saveBasicSearchFiltersToCookies
    $(() => {
      $('body').on('select2:select', '#position_search', this.handleFilterChange)
      this.getOrResetCookies()
      this.moveToLegacyLocation()
    })
  },
  watch: {
    showFilters(v) {
      this.$nextTick(() => {
        this.prePopulateBasicFilters()
        this.moveToLegacyLocation()
      })
    }
  },
  computed: {
    showClear: ({ filters }) => Object.keys(filters).some((key, i) => filters[key].length > 0),
    showFilters: v => !v.$parent.$refs['advanced-search'].visible
  },
  methods: {
    toggleAdvancedSearch() {
      window.toggleAdvancedSearch(true)
    },
    handleFilterChange(event) {
      const { params: { data: { id: value } }, currentTarget } = event
      const filterKey = $(currentTarget).attr('id')
      this.filters[filterKey] = value
      this.emitFilterChange()
    },
    onNameChange(event) {
      clearTimeout(timeout);
      timeout = setTimeout(this.emitFilterChange, 500);
    },
    moveToLegacyLocation() {
      $('#rosterFilter').find('.col-lg-12').append($('#basic-filters'))
    },
    emitFilterChange() {
      this.saveBasicSearchFiltersToCookies()
      this.$emit('filter-change', this.getFilters())
    },
    saveBasicSearchFiltersToCookies() {
      Cookies.set('basic_search', JSON.stringify(this.filters), {expires: Infinity})
    },
    prePopulateBasicFilters() {
      if (Cookies.get('basic_search')) {
        const criteria = JSON.parse(Cookies.get('basic_search'))
        for (const key in this.filters) {
          if (criteria[key]) this.filters[key] = criteria[key]
        }
      }
    },
    getOrResetCookies() {
      const { type } = performance.getEntriesByType('navigation')[0]
      switch (type) {
        case 'reload':
        case 'back_forward' : return this.prePopulateBasicFilters()
        case 'navigate': return Cookies.expire('basic_search')
      }
    },
    getFilters() {
      const { position_search: position, grad_year_search: grad_years, name } = this.filters
      return [
        { search_prop: 'position', value: position },
        { search_prop: 'grad_year', value: grad_years },
        { search_prop: 'full_name', value: name }
      ]
    },
    resetFilters() {
      for (const filter in this.filters) {
        this.filters[filter] = ""
      }
      this.refreshInputs()
      this.emitFilterChange()
    },
    refreshInputs() {
      const keys = Object.keys(this.filters)
      keys.map((key, i) => {
        const $input = $(`#${key}`)
        const value = this.filters[key]
        $input.val(value || "")
      })
    }
  }
}
</script>

<style scoped>
#name, #advanced_searchBtn, .reset-btn, .el-input__inner, .el-select-dropdown__item {
  font-family: KairosSans,serif !important;
  text-transform: uppercase !important;
}
#advanced_searchBtn {
  font-size: 16px;
  height: 45px;
}
input#grad_year_search.el-input__inner, input#position_search.el-input__inner {
  height: 45px !important;
  color: #000;
}

</style>
