/**
 * These are for use with data filtering/sorting/etc and their URL parameters
 *
 * Why is this needed? Because we currently use multiple packages for Datatables and Eloquent on
 * both the front and back end, and they have similar but varying param syntax. These helpers
 * will make it easy to use a set of different params for different tables.
 *
 *
 */
import _ from 'lodash';

export class SearchFilter {
    constructor(prop, target, arg = 'filters') {
        this.prop = prop;
        this.target = target;
        this.arg = arg;
    }
    apply(builder) {
        return { key: this.arg + '[' + this.prop + ']', value: this.target };
    }
}

export var Sort;

(function (Sort) {
    Sort[Sort["None"] = 0] = "None";
    Sort[Sort["Ascending"] = 1] = "Ascending";
    Sort[Sort["Descending"] = 2] = "Descending";
})(Sort || (Sort = {}));


export class SortFilter {
    constructor(prop, sort, arg = 'order') {
        this.prop = prop;
        this.sort = sort;
        this.arg = arg;
    }
    apply(builder) {
        let order;
        switch (this.sort) {
            case Sort.None:
                order = '';
                break;
            case Sort.Ascending:
                order = 'asc';
                break;
            case Sort.Descending:
                order = 'desc';
                break;
        }
        return { key: this.arg + '[' + this.prop + ']', value: order };
    }
}

export class CurrentPageFilter {
    constructor(page) {
        this.page = page;
    }
    apply(builder) {
        return { key: 'page', value: this.page + '' };
    }
}

export class ItemsPerPageFilter {
    constructor(numberItems, key = 'length') {
        this.numberItem = numberItems;
        this.key = key;
    }
    apply(builder) {
        return { key: this.key, value: this.numberItem + '' };
    }
}

export class FilterBuilder {
    constructor() {
        this.filters = [];
    }
    addFilter(filter) {
        this.filters.push(filter);
        return this;
    }
    build() {
        let result = [];
        this.filters.forEach((f) => {
            const o = f.apply(this);
            o.key = _.trim(o.key);
            o.value = _.trim(o.value);
            result.push(o);
        });
        let payload = [];
        _.each(_.groupBy(result, (e) => {
            return e.key;
        }), (value, key) => {
            if (value.length == 1) {
                let o = value[0];
                payload.push(key + '=' + o.value);
            }
            else {
                if (key.endsWith('[]')) {
                    value.forEach((r) => {
                        payload.push(key + '=' + r.value);
                    });
                }
                else if (key.endsWith(']')) {
                    throw new Error('problematic end matching');
                }
                else {
                    value.forEach((r) => {
                        payload.push(key + '[]=' + r.value);
                    });
                }
            }
        });
        return payload.join('&');
    }
}
