<template>
  <div class="col-12">
    <div id="new-note">
      <textarea type="text" maxlength="25000" rows="1" name="message" placeholder="Type your post here..." class="form-control post-box"></textarea>
      <button class="btn waves-effect waves-light upload-btn post-btn btn-dark text-white" :disabled="isUploading" media-upload><i class="mdi-set mdi-attachment rotate"></i></button>
      <button class="btn btn-primary waves-effect waves-light submit-btn post-btn" v-on:click="makePost" :disabled="isUploading"><i class="icon ion-ios7-paperplane"></i></button>
      <div class="actions-bar post-box__actions-bar js-post-box__actions d-none" media-btns>
        <button class="btn btn-primary waves-effect waves-light btn-dark text-white media-upload-btn" add-image><i class="icon ion-images actions-bar__icons"></i></button>
        <button class="btn btn-primary waves-effect waves-light btn-dark text-white media-upload-btn" add-video><i class="icon ion-ios7-videocam actions-bar__icons"></i></button>
      </div>
      <input type="file" name="image" id="imageUpload" class="d-none" accept=".png, .jpg, .jpeg, .gif" multiple @change="previewImages">
      <input type="file" name="video" id="videoUpload" class="d-none" accept=".mp4, .ogg, .webm" @change="previewVideo">
        <div v-if="this.images.length > 0 || this.videos.length > 0" id="uploadPreview">
            <div class="post-box-preview">
                <div v-if="this.images.length > 0" class="row col-sm-12 pl-0 pr-0 ml-0 mr-0">
                  <div v-for="image in images" :id="image.name.replace('.', '_')" class="post-box-preview__item preview-image" :style="`background-image: url('${createFileUrl(image)}'); opacity: 1; z-index:0;`">
                    <span :id="image.name.replace('.', '_') + '_remove'" class="post-box-preview__remove" v-on:click="removeImage(image)"><i class="icon ion-ios7-close"></i></span>
                  </div>
                  <div class="post-box-preview__item post-box-preview__add js-image-upload-trigger" id="uploadTrigger">
                      <span class="post-box-preview__add-more">+</span>
                  </div>
                </div>
                <div v-for="video in videos" class="col-sm-12 pr-0 pl-0 video-preview">
                  <span class="post-box-preview__remove" id="removeVideo" v-on:click="removeVideo(video)"><i class="icon ion-ios7-close"></i></span>
                  <video controls><source :src="createFileUrl(video)" :type="video.type"></source></video>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Multiselect from 'vue-multiselect'

  export default {
    components: { Multiselect },
    name: 'CreatePost',
    props: {
      user_id: String,
      organization_id: String,
      role: String
    },
    data() {
      return {
        isUploading: false,
        images: [],
        videos: []
      }
    },

    methods: {
      makePost: function() {
        if (this.isUploading === false) {
          if(this.images.length > 0 || this.videos.length > 0 || $('.post-box[name="message"]').val().length > 0) {
            axios.post('/api/posts', this.makePostData()).then((response) => {
              window.vueInstance.$refs['post'].posts.unshift(response.data.data)
              this.$emit('new_post')
              this.isUploading = true
              let images = this.images
              for(let x=0; x<images.length; x++) {
                this.uploadImage(images[x], x, response.data.data.id)
              }
              let videos = this.videos
              for(let x=0; x<videos.length; x++) {
                this.uploadVideo(videos[x], x, response.data.data.id)
              }
              this.isUploading = false
              this.images = []
              this.videos = []
            }).catch((error) => {
              this.isUploading = false
              console.log(error)
            })
          }
          else {
            Swal.fire({
              title: 'Error!',
              text: 'Cannot create an empty post.',
              type: 'error',
            })
          }
        }
        $('.post-box[name="message"]').val('')
      },
      makePostData: function() {
        const data = new FormData();
        data.append('user_id', this.user_id)
        data.append('organization_id', this.organization_id)
        data.append('message', ($('[name="message"]').val().length > 0) ? $('[name="message"]').val() : '') 
        return data
      },
      previewImages: function(e) {
        if (e.target.files.length > 0) {
          $('#uploadTrigger').show()
          $('#uploadPreview').show()
          let images = e.target.files
          for (let i = 0; i < images.length; i += 1) {
            let alreadyUploaded = false;
            for (let j = 0; j < this.images.length; j += 1) {
              if (this.images[j].name === images[i].name) {
                alreadyUploaded = true;
              }
            }
            if (!alreadyUploaded) {
              if(images[i].size <= 100000000) {
                this.images.push(images[i])
              }
              else {
                Swal.fire({
                  title: 'Error!',
                  text: 'The file exceeds the maximum size of 100MB.',
                  type: 'error',
                })
              }
            }
          }
        }
      },
      removeImage: function(image) {
        this.images.pop(image)
        $('[name="image"]').val('')
      },
      removeVideo: function(video) {
        this.videos.pop(video)
        $('[name="video"]').val('')
      },
      createFileUrl: function(image) {
        return (window.URL || window.webkitURL).createObjectURL(image)
      },
      uploadImage: function(image, index, id) {
        const data = new FormData()
        data.append('file', image)
        data.append('type', 'Image')
        data.append('id', id)
        axios.post('/api/posts/upload-file', data).then((response) => {
          let refs = window.vueInstance.$refs.post.posts
          refs[0].images.push(response.data.data.file)
        }).catch((error) => {
          console.log(error)
        });
      },
      previewVideo: function(e) {
        if(e.target.files.length > 0) {
          $('#uploadPreview').show()
          let videos = e.target.files
          for(let x=0; x<videos.length; x++) {
            let alreadyUploaded = false;
            if(videos[x].size <= 100000000) {
              this.videos.push(videos[x])
              $('#uploadTrigger').hide()
              $('.js-post-box__show-actions').hide()
            }
            else {
              Swal.fire({
                title: 'Error!',
                text: 'The file exceeds the maximum size of 100MB.',
                type: 'error',
              })
            }
          }
        }
      },
      uploadVideo: function(video, index, id) {
        const data = new FormData()
        data.append('file', video)
        data.append('type', 'Video')
        data.append('id', id)
        axios.post('/api/posts/upload-file', data).then((response) => {
          let refs = window.vueInstance.$refs.post.posts
          refs[0].videos.push(response.data.data.file)
        }).catch((error) => {
          console.log(error)
        });
      },
    }
  }

  // Media Button Events
  $('body').on('click', '[media-upload]', function() {
    $('[media-btns]').toggleClass('d-none')
  })

  $('body').on('click', '[add-image], #uploadTrigger', function() {
    $('[media-btns]').addClass('d-none')
    $('#imageUpload').click()
  })

  $('body').on('click', '[add-video]', function() {
    $('[media-btns]').addClass('d-none')
    $('#videoUpload').click()
  })

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
