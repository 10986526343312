<template>
  <div class="public-player-profile">
    <img
      v-if="player.profile.header_image"
      :src="player.profile.header_image"
      alt="header image"
      class="public-player-profile-header-image"
    />
    <div class="public-player-profile-content-container">
      <PlayerDetails :has-header-image="player.profile.header_image" />
      <!-- <div v-if="player.hasCurrentSubscription"> -->
        <HighlightVideo v-if="player.highlight_url" />
        <PlayerStatsUploads />
      <!-- </div>
      <div v-else>
        <LiteProfileBody />
      </div> -->
    </div>
    <VideoPlayerModal
      v-if="isModalOpen"
      :videoUrl="videoUrl"
      :videoTitle="videoTitle"
      :isOpen="isModalOpen"
      @onClose="handleToggleModal"
    />
  </div>
</template>

<script>
import { computed } from "vue";
import PlayerDetails from "@components/PlayerProfile/PublicProfile/components/PlayerDetails";
import HighlightVideo from "@components/PlayerProfile/PublicProfile/components/HighlightVideo";
import LiteProfileBody from "@components/PlayerProfile/PublicProfile/components/LiteProfileBody";
import VideoPlayerModal from "@components/PlayerProfile/PublicProfile/components/VideoPlayerModal";
import PlayerStatsUploads from "@components/PlayerProfile/PublicProfile/components/PlayerStatsUploads";

export default {
  name: 'PublicPlayerProfile',
  components: { PlayerStatsUploads, HighlightVideo, PlayerDetails, VideoPlayerModal, LiteProfileBody },
  props: ['player'],
  data() {
    return {
      isExpanded: true,
      isModalOpen: false,
      videoUrl: null,
      videoTitle: null,
    }
  },
  mounted() {
    if (Cookies.get('isExpanded')) {
      this.isExpanded = JSON.parse(Cookies.get('isExpanded'));
    }
  },
  watch: {
    isExpanded(newValue) {
      Cookies.set('isExpanded', newValue);
    }
  },
  provide() {
    return {
      player: this.player,
      toggleIsExpanded: this.toggleIsExpanded,
      handleToggleModal: this.handleToggleModal,
      isExpanded: computed(() => this.isExpanded),
      isModalOpen: computed(() => this.isModalOpen)
    }
  },
  methods: {
    handleToggleModal(video, videoTitle = null) {
      this.toggleModal();
      this.videoUrl = this.isModalOpen ? video : null;
      this.videoTitle = videoTitle;
    },
    toggleIsExpanded() {
      this.isExpanded = !this.isExpanded
    },
    toggleModal() {
      this.isModalOpen = !this.isModalOpen;
    },
  }
}
</script>


