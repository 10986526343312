<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0335 6.83431V11.8343H9.03353V6.83431H10.0335Z" fill="black"/>
    <path d="M7.36686 11.8343V6.83431H6.36686L6.36686 11.8343H7.36686Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3669 1.50098H5.03353V4.16764H2.2002V5.16764H3.7002V14.501H12.7002V5.16764H14.2002V4.16764H11.3669V1.50098ZM10.3669 4.16764V2.50098H6.03353V4.16764H10.3669ZM4.7002 5.16764V13.501H11.7002V5.16764H4.7002Z" fill="black"/>
</svg>
</template>

<script>
export default {
  name: 'TrashIcon',
};
</script>
