<template>
  <div>
    <span v-if="!share">
      <a href="prospectlists/share/lists" class="btn btn-primary waves-effect waves-light pull-right ml-2">Share</a>
      <a href="prospectlist/create" class="btn btn-primary waves-effect waves-light pull-right ml-2">New Prospect List</a>
    </span>
    <span v-else>
      <a v-if="disabledState" href="javascript:" class="btn btn-secondary waves-effect waves-light pull-right ml-2">Select Lists to Share</a>
      <a v-else href="javascript:" class="btn btn-primary waves-effect waves-light pull-right ml-2" @click="handleSubmitShareProspectlists" data-toggle="modal" data-target="#shareModal">Share Selected Lists</a>
    </span>
  </div>
</template>

<script>
  export default {
    props: {
      share: {type: Boolean, default: false, required: false},
      disabledState: {type: Boolean, default: false, required: false},
      handleSubmitShareProspectlists: {type: Function, default() {return null}, required: false},
    },
  }
</script>
