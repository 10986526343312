<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <top-navigation @navigation_changed="navigate" @new_invite="newInvite" :set-nav="view" @export="exportData"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <list-view @show_players="showPlayers" v-if="view === 'list'"></list-view>
        <player-view :invite="invite" @reset_invite="resetInvite" v-if="view === 'player'"></player-view>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { BContainer, BRow, BCol } from "bootstrap-vue";
import TopNavigation from "./components/TopNavigation";
import PlayerView from "./PlayerView";
import DashboardView from "./DashboardView";
import ListView from "./ListView";
export default {
  name: 'InvitationOverview',
  components: { TopNavigation, BRow, BContainer, BCol, ListView,PlayerView,DashboardView},
  data: () => ({
    view: 'list',
    set_nav: null,
    invite: null,
  }),
  methods: {
    navigate(view) {
      this.view = view;
    },
    showPlayers(id) {
      this.invite= id
      this.set_nav = 'player'
      this.view = 'player'
    },
    newInvite(file) {
      this.$emit('new_invite', file)
    },
    resetInvite() {
      this.invite = null
    },
    exportData() {
      window.exportData()
    }
  }
}
</script>
