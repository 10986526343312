<template>
  <form id="addFootageForm" v-on:submit.prevent="submit()" enctype="multipart/form-data">
    <input type="hidden" name="user_id" :value="playerId">
    <input type="hidden" name="organization_id" :value="organizationId">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Title</label>
            <input name="name" type="text" class="form-control" required>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Video</label>
            <input type="file" name="video" class="form-control" accept="video/*" required>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button type="submit" class="btn btn-primary">{{ saving ? 'Saving...' : 'Save' }}</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>

import axios from 'axios'

export default {
  name: 'AdditionalFootage',
  props: {
    playerId: Number,
    organizationId: String,
  },

  data: function() {
    return {
        saving: false
    }
  },

  methods: {
     submit() {
      this.saving = true
      let formData = new FormData($('#addFootageForm')[0])

      axios.post('/api/add-footage', formData).then(response => {
        $('.additional-footage_wrap').hide();
        $('.no-add-footage').hide();
        $('.add-footage_success').show();
        this.saving = false
      }).catch(error => {
        console.log(error)
        this.saving = false
      })

    }
  }
}

</script>
