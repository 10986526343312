<template>
  <div class="container-fluid">
    <span href="javascript:" data-toggle="modal" data-target="#publicLoginModal"
      id="roster-filter"
      class="form-inline"
      style="display: inline-block; float: right"
    >
      <button class="btn waves-effect waves-light btn-danger reset-btn" v-if="filters.some(f => f.value !== '')" @click="resetFilters">Clear</button>
      <select
        id="player_position"
        v-model="filters[1].value"
        class="form-control custom-select"
        data-filter="position"
        :disabled="!is_member"
      >
        <option value="">Any Position</option>
        <option v-for="(position, code) in positions"
          :key="position"
          :label="position"
          :value="code"
        >
          {{ position }}
        </option>
      </select>
      <span v-if="is_owner">
        <select
          v-if="isHighSchoolTable"
          id="grad_year_search"
          v-model="filters[2].value"
          class="form-control custom-select"
          style="padding-right: 25px"
          data-filter="grad_year"
          :disabled="!is_member"
        >
          <option value="">Any Class</option>
          <option
            v-for="(year, code) in user_class_grad_years"
            :key="year"
            :value="year"
          >{{ code }}</option>
        </select>
        <select
          v-else
          id="grad_year_search"
          v-model="filters[2].value"
          class="form-control custom-select"
          style="padding-right: 25px"
          data-filter="grad_year"
          :disabled="!is_member"
        >
          <option value="">Any Year</option>
          <option
            v-for="i in 8"
            :key="`year_${i}`"
            :value="year - 1 + i"
          >{{ year - 1 + i}}</option>
        </select>
      </span>
      <input-gpa-level
        ref="gpa-level-search"
        id="gpa_level_search"
        v-model="filters[3].value"
        class="form-control"
        style="padding-right: 25px"
        data-filter="gpa_level"
        title="GPA"
        :min="0"
        :max="4"
        :onChange="handleGpaFilterChange"
        :disabled="!is_member"
      ></input-gpa-level>

      <input
        v-if="is_owner"
        id="full_name"
        v-model="filters[0].value"
        class="form-control"
        placeholder="Search..."
        type="text"
        data-filter="full_name"
        :disabled="!is_member"
      />
    </span>

    <data-tables-server
      ref="table"
      :id="`${this.screen}-table`"
      :data="players"
      :filters="filters"
      :loading="loading"
      :current-page="currentPaginationPage"
      :pagination-props="{
        pageSizes: [10, 25, 50, 100],
        background: true,
        layout: 'prev, pager, next, sizes, ->, total'
      }"
      :table-props="{
        'row-key': 'id'
      }"
      :total="count"
      class="table"
      @query-change="handleQueryChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column fixed min-width="110" align-v="center" align="center" label="Player">
        <template slot-scope="scope">
          <a :href="getProspectlistProfileUrl(scope.row.profile.data.id, scope.row.id, true)" target="_blank">
            <img
              :src="scope.row.profile.data.photo"
              class="rounded-circle"
              height="90"
              style="padding: 7px"
              width="90"
            />
          </a>
        </template>
      </el-table-column>
      <el-table-column fixed id="roster-player" align-v="left" width="225">
        <template slot-scope="scope">
          <div>
            <a :href="getProspectlistProfileUrl(scope.row.profile.data.id, scope.row.id, true)" target="_blank">
              <h4 class="player-name">{{ scope.row.full_name }}</h4>
            </a>
            <div class="roster-city-state">
              <template v-if="!!scope.row.profile.data.basics.data.high_school">
                <!-- using v-html here because some schools have names that've been escaped (ie - O'Hanahan) -->
                <span v-html="scope.row.profile.data.basics.data.high_school"></span>
                <span v-if="!!scope.row.profile.data.basics.data.grad_year">, {{ scope.row.profile.data.basics.data.grad_year }}</span>
                <br/>
              </template>
              <template v-if="is_member && !!scope.row.profile.data.basics.data.state">
                <span v-html="(scope.row.profile.data.basics.data.city+', '+scope.row.profile.data.basics.data.state)"></span>,
              </template>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" align-v="center" min-width="100" label="Positions">
        <template slot-scope="scope">
          {{ scope.row.profile.data.basics.data.positions_string }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="is_owner"
        align="center"
        align-v="center"
        class="grad_year"
        label="Grad Year"
        prop="profile.data.basics.data.grad_year"
        sortable
        width="120"
      ></el-table-column>
      <el-table-column
        v-if="sport_name !== 'softball' && sport_name !== 'mens_soccer' && sport_name !== 'womens_soccer'"
        align="center"
        align-v="center"
        label="HT/WT"
        prop="profile.data.height_weight"
      >
        <template slot-scope="{ row: { profile: { data }} }">
          {{ getWeightAndHeightString(data) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="sport_name === 'softball' || sport_name === 'mens_soccer' || sport_name === 'womens_soccer'"
        align="center"
        align-v="center"
        label="HEIGHT"
        prop="profile.data.height"
      >
        <template slot-scope="{ row: { profile: { data }} }">
          {{ getWeightAndHeightString(data) }}
        </template>
      </el-table-column>
      <el-table-column v-if="sport_name === 'football'" align="center" align-v="center" min-width="100" label="Jersey #">
        <template slot-scope="scope">
          {{ scope.row.profile.data.basics.data.sportBasics.data.jersey_num }}
        </template>
      </el-table-column>
      <el-table-column v-if="sport_name === 'baseball' || sport_name === 'softball' || sport_name === 'mens_soccer' || sport_name === 'womens_soccer'" align-v="center" min-width="110" label="H/T" sortable :sort-method="(a,b) => sortMethod(a, b, true)">
        <template slot-scope="{ row }">
          {{ getBatThrowString(row) }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        align-v="center"
        class="unweighted_gpa"
        label="GPA"
        prop="profile.data.academics.data.unweighted_gpa"
        sortable
        width="120"
      >
        <template slot-scope="{ row }">
          {{ getUnweightedGpa(row) }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        align-v="center"
        class="text-center"
        label="Progress"
        min-width="125"
      >
        <template slot-scope="scope">
          <div class="text-center player-progress">
            <div v-bind:class="
              [
                {
                  'full-progress org-bg': (
                    scope.row.progress.data.progress_profile &&
                    scope.row.progress.data.progress_interview &&
                    scope.row.progress.data.progress_videos
                  )
                },
                'inner-progress'
              ]"
            >
              <span v-if="is_member" style="display:flex;">
                <active-linked-icon
                  :status="scope.row.progress.data.progress_profile"
                  :url="getProspectlistProfileUrl(scope.row.profile.data.id, scope.row.id, true)+''"
                  target="_blank"
                  icon-class="fa-user"
                  title="Profile"></active-linked-icon>
                <active-linked-icon
                  :status="scope.row.progress.data.progress_interview"
                  :url="getProspectlistProfileUrl(scope.row.profile.data.id, scope.row.id, true)+'#int'"
                  target="_blank"
                  icon-class="fa-info"
                  title="Interview"></active-linked-icon>
                <active-linked-icon
                  :status="scope.row.progress.data.progress_videos"
                  :url="getProspectlistProfileUrl(scope.row.profile.data.id, scope.row.id, true)+'#foot'"
                  target="_blank"
                  icon-class="fa-film"
                  title="Videos"></active-linked-icon>
              </span>
              <a v-else href="javascript:" data-toggle="modal" data-target="#publicLoginModal" style="display:flex;">
                <active-linked-icon
                  icon-class="fa-user"
                  title="Profile"></active-linked-icon>
                <active-linked-icon
                  icon-class="fa-info"
                  title="Interview"></active-linked-icon>
                <active-linked-icon
                  icon-class="fa-film"
                  title="Videos"></active-linked-icon>
              </a>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        align-v="center"
        label="Public/Private"
        prop="profile.data.academics.data.unweighted_gpa"
        sortable
        width="160"
      >
        <template slot-scope="{ row }">
          {{getPublicStatus(row).toUpperCase()}}
        </template>
      </el-table-column>
<!--       <el-table-column
        fixed="right"
        label="Add"
        align="center"
      >
        <template v-slot="{ row }">
          <input
            class="form-check-input"
            :id="'prospectlist-selected-player-'+row.id"
            type="checkbox"
            :checked="row.prospectlistIsChecked"
            :name="'prospectlist-selected-player-'+row.id"
            :value="row.id"
            @click="getSelectedPlayers"
          />
        </template>
      </el-table-column> -->
      <el-table-column
        fixed="right"
        label=""
        align="center"
        width="120"
      >
        <template slot-scope="scope">
          <a :href="getProspectlistProfileUrl(scope.row.profile.data.id, scope.row.id, true)" target="_blank">
            <button type="button" class="btn btn-primary btn-sm btn-block">View</button>
          </a>
        </template>
      </el-table-column>
    </data-tables-server>
  </div>
</template>

<script>
import DataTablesServer from '@components/ui/DataTablesServer'
import InputGpaLevel from "./InputGpaLevel";

import { buildQueryForVueDataTable } from '@lib/vue-data-tables-util'
import _ from "lodash";

const PUBLIC = 'Public'
const PRIVATE = 'Private'
let timeout

export const ActiveLinkedIcon = {
  name: 'ActiveLinkedIcon',
  props: ['url', 'status', 'title', 'iconClass'],
  template: `
    <a :href="url">
    <div class="round m-2"
         :class="{'org-bg org-text': status}"
         :data-original-title="title"
         data-toggle="tooltip"
    >
      <i class="fas" :class="[iconClass]"></i>
      <slot></slot>
    </div>
    </a>`
}

export default {
  components: {InputGpaLevel, DataTablesServer, ActiveLinkedIcon},
  props: {
    screen: {type: String, default: null, required: false},
    sport_name: {type: String, default: 'football', required: false},
    prospectlist_id: {type: String, default: '', required: false},
    organization_id: {type: String, default: '', required: false},
    handlePlayerSelect: {type: Function, default() {return null}, required: false},
    profiles: {type: Object, default() {return {}}, required: false},
    handlePopulateProspectlist: {type: Function, default() {return null}, required: false},
    isInputsUpdated: {type: Boolean, default() {return false}, required: false},
    prospectlist: {type: Object, default() {return {}}, required: false},
    is_owner: {type: Boolean, default() {return false}, required: false},
    is_member: {type: Boolean, default() {return false}, required: false},
  },
  data() {
    return {
      isHighSchoolTable: this.$parent.isHighSchool,
      filters: [
        { search_prop: 'full_name', value: '', },
        { search_prop: 'position', value: '', },
        { search_prop: 'grad_year', value: '', },
        { search_prop: 'gpa_level', value: '', },
      ],
      currentPaginationPage: JSON.parse(localStorage.getItem('prospectPage')) ? JSON.parse(localStorage.getItem('prospectPage')) : 1,
      year: new Date().getFullYear(),
      loading: false,
      apiData: null,
      previousQuery: null,
      positions: window.signingDayConstants['user_positions'],
      user_class_grad_years:
        window.signingDayConstants['user_class_grad_years'],
      selected: [],
    }
  },
  computed: {
    players: (v) => _.get(v, 'apiData.data', []),
    count: (v) => _.get(v, 'apiData.meta.pagination.total', 0),
    url() {
      // set either the private table url or the public table url
      return (this.is_member)
        ? '/api/prospectlists/'+this.prospectlist_id+'/get-table'
        : '/api/prospectlists/'+this.prospectlist_id+'/get-public-table';
    },
  },
  updated() {
    this.currentPaginationPage = JSON.parse(localStorage.getItem('prospectPage'));
  },
  methods: {
    getPublicStatus(row){
      return (row.is_public) ? PUBLIC : PRIVATE
    },
    getProspectlistProfileUrl(profileID, playerID, isChecked){
      let url = '';
      if(this.is_owner){
        // owner of prospectlist
        url = (isChecked === true)
          ? '/organizations/'+this.organization_id+'/prospectlists/'+this.prospectlist_id+'/profiles/' + profileID
          : '/recruit/' + playerID;
      }
      else if(this.is_member){
        // member, but not the owner
        url = '/coaches-prospectlist/'+this.prospectlist.data.public_prospectlist_token.token+'/prospectlists/'+this.prospectlist_id+'/profiles/' + profileID;
      }
      else{
        // non-member
        url = '/public-prospectlist/'+this.prospectlist.data.public_prospectlist_token.token+'/prospectlists/'+this.prospectlist_id+'/profiles/' + profileID;
      }

      return url;
    },
    getSelectedPlayers(event){
      const target = $(event.target);
      const isChecked = target.is(':checked');
      const playerID = JSON.parse(target.val());
      const player = _.filter(this.players, {id: playerID});
      this.handlePlayerSelect(isChecked, player[0]);
    },
    async handleGpaFilterChange(data){
      this.filters[3].value = data;
    },
    async handleFilterChange(event) {
      const { params, currentTarget } = event
      const filterKey = $(currentTarget).attr('id')

      switch (filterKey) {
        case 'player_position': this.filters[1].value = params.data.id; break
        case 'grad_year_search': this.filters[2].value = params.data.id; break
      }
    },
    handleQueryChange(query) {
      clearTimeout(timeout);
      timeout = setTimeout(() => this.loadData(query), 250);
    },
    async loadData(query, silentReload = false) {
      // reset query-page to 1 if there is a change to any of the basic search fields
      if( (this.previousQuery !== null) && (!_.isEqual(this.previousQuery.filters, query.filters)) ){
        query.page = 1
        this.resetCurrentPage(query.page)
      }

      localStorage.setItem('prospectPage', JSON.stringify(query.page));
      this.currentPaginationPage = query.page

      this.previousQuery = _.cloneDeep(query)
      this.loading = !silentReload
      const builder = buildQueryForVueDataTable(query)
      const url = `${this.url}?${builder.build()}&roster=${this.screen === 'roster'}`

      const response = await axios.get(url)

      this.apiData = response.data
      this.loading = false
      this.syncProspeclistWithRoster({profiles: this.profiles});
    },
    syncProspeclistWithRoster(data){
      // this marks the checkbox for each player displayed that is in profiles
      // if both profiles and apiData have data, then process sync
      if(((Object.keys(data.profiles)).length > 0) && (this.apiData !== null)){
        const clonedApiData = _.cloneDeep(this.apiData);

        // add 'isChecked' prop to each player: isChecked means they are in the prospectlist selection
        // and the checkbox will be 'checked', then collect addedPlayers
        const profiles = data.profiles;
        const addedPlayers = {};
        clonedApiData.data.forEach((player, index) => {
          var isChecked = false;
          if(profiles[player.id]){
            isChecked = true;
          }
          player.prospectlistIsChecked = isChecked;

          if(isChecked) addedPlayers[player.id] = player;
        });

        // save apiData and populate the prospectlist table
        this.apiData = clonedApiData
        this.handlePopulateProspectlist(addedPlayers, this.isInputsUpdated);
      }
    },
    handleSelectionChange(selected) {
      this.selected = selected.map(u => u.id)
    },
    moveFilterToLegacyLocation() {
      $('#rosterSearchInfo').find('.col-lg-12').append($('#roster-filter'))
    },
    registerListeners() {
      $('body').on('select2:select', '#player_position, #grad_year_search', this.handleFilterChange)
    },
    getStatusString({ offered, status }) {
      return status === null ? offered ? 'Made Offer' : '...' : status
    },
    getUnweightedGpa(row){
      const unweighted_gpa = row.profile.data.academics.data.unweighted_gpa
      return (!this.is_member && this.getPublicStatus(row) === PRIVATE) ? PRIVATE.toUpperCase() : unweighted_gpa;
    },
    getWeightAndHeightString({ measurables: { data } }) {
      const { height, weight } = data
      const result = { h, w }
      result.h = (height === null || height.includes('null')) ? 'N/A' : height
      result.w = (weight === null || weight.includes('null')) ? 'N/A' : weight
      const { h, w } = result
      if (this.sport_name !== 'softball') {
        return h === 'N/A' && w === 'N/A' ? 'N/A' : `${h} / ${w}`
      } else {
        return h === 'N/A' ? 'N/A' : `${h}`
      }
    },
    getBatThrowString({ profile: { data: { basics: { data: { sportBasics } } } } }) {
      const bats = sportBasics?.data?.bats ? sportBasics.data.bats.slice(0, 1)?.toUpperCase() : 'N/A '
      const throws = sportBasics?.data?.throws ? sportBasics.data.throws.slice(0, 1).toUpperCase() : ' N/A'
      return `${bats}/${throws}`;
    },
    getStatusSort(row, interest) {
      const value = interest ? this.getInterestString(row) : this.getStatusString(row)
      switch (value) {
        case 'Committed':
        case 'High':
          return 5
        case 'Top 5':
        case 'Medium':
          return 4
        case 'Top 10':
        case 'Draft Eligible':
        case 'Low':
          return 3
        case 'Made Offer': return 2
        case 'Out': return 1
        default: return 0
      }
    },
    sortMethod(a, b, interest = false) {
      return this.getStatusSort(a, interest) >= this.getStatusSort(b, interest) ? 1 : -1
    },
    getInterestString({ interest }) {
      return interest === null ? '...' : interest
    },
    resetFilters() {
      for (const filter in this.filters) {
        this.filters[filter].value = ''
      }
      this.refreshInputs()
      this.resetCurrentPage(1)
    },
    refreshInputs() {
      const keys = $('[data-filter]').map((i, el) => $(el).data('filter')).toArray()
      keys.map((key, i) => {
        const $input = $(`[data-filter="${key}"]`)
        const { value } = this.filters.filter(f => f.search_prop === key)[0]

        if ($input.prop('localName') === 'select') {
          $input.val(value || "").select2()
        } else {
          $input.val(value || "")
        }
      });
    },
    resetFilterInputs(){
      this.$refs['gpa-level-search'].minVal = (this.filters[3].value[0]) ? this.filters[3].value[0] : this.$refs['gpa-level-search'].min
      this.$refs['gpa-level-search'].maxVal = (this.filters[3].value[1]) ? this.filters[3].value[1] : this.$refs['gpa-level-search'].max
      this.$refs['gpa-level-search'].maxDisable = (this.filters[3].value !== '') ? false : true
    },
    async silentRefresh() {
      await this.loadData(this.previousQuery, true)
    },
    saveCookies() {
      Cookies.set('prospectlist_table', JSON.stringify({filters: this.filters, selected: this.selected}), { expires: Infinity })
      Cookies.set('prospectlist_screen', JSON.stringify({currentScreen: this.screen}), {expires: Infinity});
    },
    getOrResetCookies() {
      const { type } = performance.getEntriesByType('navigation')[0]
      const cookie = JSON.parse(Cookies.get('prospectlist_table'))
      const { currentScreen } = JSON.parse(Cookies.get('prospectlist_screen'))

      // this is to determine if we are on either the 'roster' or 'prospect' table
      // if we go from roster to prospect or viceversa, then set type to navigate
      if(currentScreen !== this.screen) {
        type = 'navigate'
      }

      switch (type) {
        case 'reload':
        case 'back_forward':
          this.performReload(cookie)
        case 'navigate':
          const searchParams = new URLSearchParams(window.location.search)
          const saveFilters = (searchParams.has('retain_filters')) ? parseInt(searchParams.get('retain_filters')) : null;

          if(saveFilters){
            this.performReload(cookie)
          }
          else{
            this.resetCurrentPage(1)
            return Cookies.expire('prospectlist_table')
          }
      }
    },
    performReload(cookie){
      Object.keys(cookie).forEach(k => _.set(this, k, cookie[k])) && this.silentRefresh()
      return this.resetFilterInputs()
    },
    resetCurrentPage(page) {
      Cookies.set('prospectlist_screen', JSON.stringify({currentScreen: this.screen}), {expires: Infinity})
      localStorage.setItem('prospectPage', JSON.stringify(page))
      this.currentPaginationPage = page
      this.selected = []
      return this.resetFilterInputs()
    }
  },
  created() {
    window.addEventListener('beforeunload', (event) => {
      this.saveCookies();
    });

    $(() => {
      this.moveFilterToLegacyLocation()
      this.registerListeners()
      this.getOrResetCookies()
      this.refreshInputs()
    })

    window.EventBus.subscribe('sds_syncProspeclistWithRoster', (data) => {
      this.syncProspeclistWithRoster(data);
    });
  }
}
</script>


<style scoped>
.el-table__header .el-table-column--selection .el-checkbox::before {
  /* https://github.com/ElemeFE/element/issues/5314 */
  content: "Message";
  color: white;
  padding-right: 5px;
}
.status-hr {
  margin-top: 0.4rem;
  margin-bottom: 0;
}
.interest-dropdown > span.dropdown-item, .status-dropdown > span.dropdown-item {
  cursor: pointer;
}
.reset-btn {
  font-family: KairosSans,serif;
  text-transform: uppercase;
}
.el-checkbox-button__inner:hover {
  color: #E7F816 !important;
}
.el-checkbox-button.is-focus .el-checkbox-button__inner {
  border-color: #E7F816 !important;
}

</style>
