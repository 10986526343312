<template>
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16.54" cy="16.5565" r="15.7552" fill="#0BB3E8"/>
    <path d="M9.03003 22.1358C10.7791 22.2342 12.3085 21.8253 13.8001 20.7426C12.2934 20.4852 11.2485 19.7961 10.6806 18.3727C11.1198 18.3121 11.5059 18.4257 11.9602 18.244C10.4762 17.5928 9.51461 16.6161 9.47675 14.9276C9.94619 14.9655 10.3021 15.2759 10.8624 15.2305C9.49947 13.8979 9.08303 12.4441 9.93862 10.68C11.3621 12.3154 13.0278 13.4284 15.0646 13.9509C15.1782 13.9812 15.2842 14.0115 15.3977 14.0342C15.9202 14.1553 16.541 14.4127 16.8591 14.3749C17.4042 14.3067 16.8591 13.6783 16.9953 12.8606C17.4269 10.3165 20.2284 9.15807 22.303 10.6497C22.9087 11.0888 23.3858 11.0813 23.9839 10.8162C24.2943 10.68 24.6124 10.5437 24.9834 10.3847C24.9001 11.1115 24.3398 11.5128 23.8931 12.0504C24.4004 12.164 24.8092 12.0201 25.2787 11.8687C25.1196 12.3911 24.7259 12.6864 24.3701 12.9741C23.9991 13.2694 23.8552 13.5799 23.84 14.0645C23.6129 21.4013 15.2766 27.0724 9.57519 22.5598C9.02246 22.1206 9.56004 22.5598 9.03003 22.1358Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "TwitterIcon"
}
</script>
