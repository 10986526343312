<template>
  <div class="prospectlist-name-input">
    <el-card class="box-card org-border">
      <div slot="header" class="clearfix">
        <span class="input-title">Prospect List Name</span>
      </div>
      <div class="text item">
        <el-input
          :value="listname"
          placeholder="Please enter a Prospect List Name"
          @input="handleChange"
          maxlength="240"
          clearable>
        </el-input>
      </div>
    </el-card>
  </div>
</template>

<script>
  export default {
    props: {
      listname: {type: String, default: null, required: false},
      handleChange: {type: Function, default() {return null}, required: false},
    },
  }
</script>

<style scoped>
  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

  .box-card {
    width: 100%;
    border-radius: 0;
    height: 173px;
    margin: 0;
    padding: 0;
    box-shadow: none !important;

  }

  span.input-title {
    font: Quantico;
    font-weight: 700;
    font-size: 19px;
    font-style: italic;
    line-height: 27px;
  }
</style>

<style>
  .prospectlist-name-input {
    width: 100%;
    /*flex comes in to play*/
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .box-card .el-input__inner::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #C0C4CC !important;
    opacity: 1; /* Firefox */
  }

  .box-card .el-input__inner:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #C0C4CC !important;
  }

  .box-card .el-input__inner::-ms-input-placeholder { /* Microsoft Edge */
    color: #C0C4CC !important;
  }

  .el-card__header  {
    border: none;
    padding: 38px 36px 0px 36px;
  }

  .prospectlist-name-input div .el-card__body  {
    border: none;
    padding: 20px 36px 38px 36px;
  }
</style>