<template>
<div>
  <div class="player-profile row">
    <div class="col-4">
      <div class="profile-card">
        <div class="profile-card-section">
          <div class="player-photo" align="center">
            <user-avatar :user="player" />
          </div>
          <div class="mt-10" align="center">
            <h4 class="player-name mb-0"> {{ player.first_name }} {{ player.last_name }}</h4>
            <p class="mb-0">
              {{ player.profile.data.basics.data.high_school }}
              <span v-if="player.profile.data.basics.data.high_school && player.profile.data.basics.data.state">, </span>
              {{ player.profile.data.basics.data.state }}
            </p>
            <p>{{ player.profile.data.basics.data.city }}
              <span v-if="player.profile.data.basics.data.city && player.profile.data.basics.data.state">, </span>
              {{ player.profile.data.basics.data.state }}
            </p>
          </div>
          <div>
            <p class="about-me"> {{ player.profile.data.basics.data.biography }} </p>
          </div>
          <div class="info-box">
            <div class="info-icons-container">
              <div style="min-width: 40px; max-width: 45px">
                <div style="justify-content: center" :class="Object.values(player.profile.data.measurables.data).length >= 1 ? 'info-icons-has-data' : 'info-icons'"><i style="color: black; margin-left: 5px;" class="fa fa-user"></i></div>
                <p class="info-text">Attributes</p>
              </div>
              <div style="min-width: 40px; max-width: 45px">
                <div style="justify-content: center" :class="player.footage.data.interviews.length >= 1 ? 'info-icons-has-data' : 'info-icons'"><i style="color: black; margin-left: 5px;" class="fa fa-microphone"></i></div>
                <p class="info-text">Interview</p>
              </div>
              <div style="min-width: 40px; max-width: 45px">
                <div style="justify-content: center" :class="player.footage.data.additionalFootage >= 1 ? 'info-icons-has-data' : 'info-icons'"><i style="color: black; margin-left: 2px;" class="fa fa-video-camera"></i></div>
                <p class="info-text">Video</p>
              </div>
            </div>
          </div>
        </div>
        <div :class="openInfo ? 'profile-card-athlete-info-open' : 'profile-card-athlete-info'" @click="toggleOpenInfo">
          <div class="athlete-info-title">
            <h4>Athlete Info</h4>
            <div style="padding-right: 20px"><i :class="openInfo ? 'fa fa-chevron-down fa-lg fa-flip-vertical' : 'fa fa-chevron-down fa-lg'" aria-hidden="true"></i></div>
          </div>
          <div class="athlete-info-data">
            <div class="profile-card-section container">
              <div class="row">
                <p class="col-6 col-sm-6 col-md-5 col-lg-4 athlete-info-key">Full Name:</p>
                <h5 class="col-6 col-sm-6 col-md-7 col-lg-8 athlete-info-value">{{ player.first_name }} {{ player.last_name }}</h5>

                <p class="col-6 col-sm-6 col-md-5 col-lg-4 athlete-info-key">Positions:</p>
                <h5 class="col-6 col-sm-6 col-md-7 col-lg-8 athlete-info-value">{{ player.profile.data.positions.data.map(p => p.shortcode).join(', ') }}</h5>

                <p class="col-6 col-sm-6 col-md-5 col-lg-4 athlete-info-key">Height:</p>
                <h5 class="col-6 col-sm-6 col-md-7 col-lg-8 athlete-info-value">{{ player.profile.data.measurables.data.height || 'N/A' }}</h5>

                <p class="col-6 col-sm-6 col-md-5 col-lg-4 athlete-info-key">Weight:</p>
                <h5 class="col-6 col-sm-6 col-md-7 col-lg-8 athlete-info-value">{{ player.profile.data.measurables.data.weight || 'N/A' }}</h5>

                <p class="col-6 col-sm-6 col-md-5 col-lg-4 athlete-info-key">Hometown:</p>
                <h5 class="col-6 col-sm-6 col-md-7 col-lg-8 athlete-info-value">{{ player.profile.data.basics.data.city }}<span v-if="player.profile.data.basics.data.city && player.profile.data.basics.data.state">, </span><span v-else>N/A</span>{{ player.profile.data.basics.data.state }}</h5>

                <p class="col-6 col-sm-6 col-md-5 col-lg-4 athlete-info-key">School:</p>
                <h5 class="col-6 col-sm-6 col-md-7 col-lg-8 athlete-info-value">{{ player.profile.data.basics.data.high_school || 'N/A' }}</h5>

                <p v-if="sport.includes('soccer')" class="col-6 col-sm-6 col-md-5 col-lg-4 athlete-info-key">League:</p>
                <h5 v-if="sport.includes('soccer')" class="col-6 col-sm-6 col-md-7 col-lg-8 athlete-info-value">{{ player.profile.data.basics.data.sportBasics.data.league_id ? player.profile.data.basics.data.sportBasics.data.leagues_list.filter(league => league.id === player.profile.data.basics.data.sportBasics.data.league_id)[0].name : 'N/A' }}</h5>
                <p v-if="player.profile.data.basics.data.sportBasics.data.league_state" class="col-6 col-sm-6 col-md-5 col-lg-4 athlete-info-key">League State:</p>
                <h5 v-if="player.profile.data.basics.data.sportBasics.data.league_state" class="col-6 col-sm-6 col-md-7 col-lg-8 athlete-info-value">{{ player.profile.data.basics.data.sportBasics.data.league_state }}</h5>

                <p class="col-6 col-sm-6 col-md-5 col-lg-4 athlete-info-key">Jersey #:</p>
                <h5 class="col-6 col-sm-6 col-md-7 col-lg-8 athlete-info-value">{{ player.profile.data.basics.data.sportBasics.data.jersey_num || 'N/A' }}</h5>

                <p class="col-6 col-sm-6 col-md-5 col-lg-4 athlete-info-key">Status:</p>
                <h5 class="col-6 col-sm-6 col-md-7 col-lg-8 athlete-info-value">{{ player.status || 'N/A' }}</h5>
              </div>
            </div>
            <div class="profile-card-section text-center border-bottom-0">
              <a v-if="player.profile.data.basics.data.sportBasics.data.hudl_link" :href="'//'+ player.profile.data.basics.data.sportBasics.data.hudl_link" class="btn btn-dark text-white" target="_blank"><i class="fas fa-football-ball mr-10"></i>View Hudl</a>
              <a v-if="player.profile.data.basics.data.sportBasics.data.highlight_first_url" :href="'//'+ player.profile.data.basics.data.sportBasics.data.highlight_first_url" class="btn btn-dark text-white" target="_blank"><i class="ti-video-clapper mr-10"></i>View Highlight 1</a>
              <a v-if="player.profile.data.basics.data.sportBasics.data.highlight_second_url" :href="'//'+ player.profile.data.basics.data.sportBasics.data.highlight_second_url" class="btn btn-dark text-white" target="_blank"><i class="ti-video-clapper mr-10"></i>View Highlight 2</a>
              <a v-if="player.profile.data.basics.data.sportBasics.data.onthree_url" :href="'//'+ player.profile.data.basics.data.sportBasics.data.onthree_url" class="btn btn-dark text-white" target="_blank"><i class="fas fa-football-ball mr-10"></i>View On3</a>
              <div class="soc-media p-b-10" style="padding-top: 25px;">
                <ul class="profile__social text-center">
                  <li>
                    <a class="twitter" v-if="player.profile.data.basics.data.twitter" :href="'https://twitter.com/' + player.profile.data.basics.data.twitter" target="_blank"><i class="fa fa-twitter"></i></a>
                  </li>
                  <li>
                    <a class="instagram" v-if="player.profile.data.basics.data.instagram" :href="'https://instagram.com/' + player.profile.data.basics.data.instagram.replace('@','')" target="_blank"><i class="fa fa-instagram"></i></a>
                  </li>
                  <li>
                    <a class="tiktok" v-if="player.profile.data.basics.data.tiktok" :href="'https://tiktok.com/' + player.profile.data.basics.data.tiktok" target="_blank"><i class="fab fa-tiktok"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-8">
      <div class="col-12">
        <div class="profile-card">
          <!-- Web View -->
          <div class="web-nav">
            <ul class="nav nav-tabs tabs-bordered">
              <li class="nav-item">
                <a href="#home" data-toggle="tab" aria-expanded="true" id="nav-attr" class="nav-link text-uppercase table-section active">
                  Attributes
                </a>
              </li>
              <li class="nav-item" >
                <a href="#pro" data-toggle="tab" aria-expanded="false" id="nav-pro" class="nav-link text-uppercase">
                {{sport === 'football' ? 'Pro Day Videos' : (sport === 'baseball' || sport === 'softball' ? 'Fundamentals' : (sport === 'mens_soccer' || sport === 'womens_soccer' ? 'Key Player Actions' : 'Fundamentals'))}}
                </a>
              </li>
              <li class="nav-item" v-if="player.footage.data.interviews.length > 0">
                <a href="#interview" data-toggle="tab" aria-expanded="false" id="nav-int" class="nav-link text-uppercase">
                  Interviews
                </a>
              </li>
              <li class="nav-item table-section">
                <a href="#additional-footage" data-toggle="tab" aria-expanded="false" id="nav-foot" class="nav-link text-uppercase">
                  Footage
                </a>
              </li>
            </ul>
          </div>
          <!-- Mobile -->
          <div :class="openSelect ? 'dropdown-active' : 'dropdownB'" @click="openSelect = !openSelect">
            <div :class="openSelect ? 'menu-dropdown-active' : 'menu-dropdown'">
              <h4 class="selected-dropdown">{{ selectedOption }}</h4>
              <div style="padding: 15px 10px"><i :class="openSelect ? 'fa fa-chevron-down fa-lg fa-flip-vertical' : 'fa fa-chevron-down fa-lg'" aria-hidden="true"></i></div>
            </div>
            <div class="dropdown-options">
              <ul v-show="openSelect" transition="slide-in-out" class="nav-options">
                <li class="nav-item">
                  <a href="#home" data-toggle="tab" @click="selectedOption = 'Attributes'" aria-expanded="true" id="nav-attr" :class="selectedOption === 'Attributes'? 'active-nav-link text-uppercase table-section active' : 'nav-link text-uppercase table-section'">
                    Attributes
                  </a>
                </li>
                <li class="nav-item" >
                  <a href="#pro" data-toggle="tab" @click="selectedOption = `${sport === 'football' ? 'Pro Day Videos' : 'Fundamentals'}`" aria-expanded="false" id="nav-pro" :class="selectedOption === 'Pro Day Videos' || selectedOption === 'Fundamentals' ? 'active-nav-link text-uppercase active' : 'nav-link text-uppercase'">
                    {{sport === 'football' ? 'Pro Day Videos' : (sport === 'baseball' || sport === 'softball' ? 'Fundamentals' : (sport === 'mens_soccer' || sport === 'womens_soccer' ? 'Key Player Actions' : 'Fundamentals'))}}
                  </a>
                </li>
                <li class="nav-item" v-if="player.footage.data.interviews.length > 0">
                  <a href="#interview" data-toggle="tab" @click="selectedOption = 'Interviews'" aria-expanded="false" id="nav-int" :class="selectedOption === 'Interviews' ? 'active-nav-link text-uppercase active' : 'nav-link text-uppercase'">
                    Interviews
                  </a>
                </li>
                <!-- <li class="nav-item table-section">
                  <a href="#additional-footage" data-toggle="tab" @click="selectedOption = 'Footage'" aria-expanded="false" id="nav-foot" :class="selectedOption === 'Footage' ? 'active-nav-link text-uppercase active' : 'nav-link text-uppercase'">
                    Footage
                  </a>
                </li> -->
                <li class="nav-item table-section">
                  <a href="#notes" data-toggle="tab" @click="selectedOption = 'Notes'" aria-expanded="false" id="nav-notes" :class="selectedOption === 'Notes' ? 'active-nav-link text-uppercase active' : 'nav-link text-uppercase'">
                    Notes
                  </a>
                </li>
                <li class="nav-item table-section" v-show="sport !== 'football'">
                  <a href="#events" data-toggle="tab" @click="selectedOption = 'Events'" aria-expanded="false" id="nav-events" :class="selectedOption === 'Events' ? 'active-nav-link text-uppercase active' : 'nav-link text-uppercase'">
                    Events
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <!-- tabs -->
          <div class="tab-content">
            <div class="tab-pane show active" id="home" role="tabpanel" aria-labelledby="home">
              <div class="recruit-info-container">
                <div class="recruit-info" v-if="sport === 'football'">
                  <h4>Measurables</h4>
                  <div class="row">
                    <div class="stats col-sm-12">
                      <div :key="key" class="stat-item text-center" v-for="(value, key) in player.profile.data.measurables.data">
                        <div class="stat-key">
                          <div class="stat-title">
                            {{ getDisplayName(key) }}
                          </div>
                        </div>
                        <div class="stat-value">
                          <p class="text-value">{{ value && value != 'null null' ? value : 'N/A' }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="recruit-info" v-if="sport === 'football'">
                  <h4>Testing</h4>
                  <div class="row">
                    <div class="stats col-sm-12">
                      <div :key="key" class="stat-item text-center stats-row-testing" v-for="(value, key) in player.profile.data.testing.data">
                        <div class="stat-key">
                          <div class="stat-title">
                            {{ getDisplayName(key) }}
                          </div>
                        </div>
                        <div class="stat-value">
                          <p class="text-value">{{ value && value != 'null null' ? value : 'N/A' }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="recruit-info" v-if="sport === 'football'">
                  <h4>Academics</h4>
                  <div class="row">
                    <div class="stats col-sm-12">
                      <div class="stat-item text-center" v-for="(value, key) in player.profile.data.academics.data">
                        <div class="stat-key">
                          <div class="stat-title">
                            <span v-if="key === 'unweighted_gpa'">UNWEIGHTED GPA</span>
                            <span v-else-if="key === 'sat'">SAT</span>
                            <span v-else-if="key === 'act'">ACT</span>
                            <span v-else-if="key === 'ncaa_id'">NCAA ID</span>
                            <span v-else-if="key === 'desired_major'">DESIRED MAJOR</span>
                            <span v-else-if="key === 'transcript'">TRANSCRIPT</span>
                          </div>
                        </div>
                        <div class="stat-value">
                            <template v-if="key !== 'transcript'">
                              <p class="text-value">{{ value && value != 'null null' ? value : 'N/A' }}</p>
                            </template>
                            <div v-if="key === 'transcript'" class="stat-value">
                              <a class="btn btn-primary modal-log-btn" type="button" data-toggle="modal" data-target="#publicLoginModal">View</a>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="sport !== 'football'">
                  <div v-for="rows in attributeTables">
                    <div class="recruit-info" v-if="rows.key !== 'gk technical assessment' && rows.key !== 'technical testing'">
                      <h4>{{ rows.key }}</h4>
                      <div class="row">
                        <div class="stats col-sm-12">
                          <div class="stat-item text-center" :key="key" v-for="(value, key) in rows.data">
                            <div class="stat-key">
                              <div class="stat-title">
                                {{ getDisplayName(key) }}
                              </div>
                            </div>
                            <div class="stat-value">
                              <template v-if="key !== 'transcript'">
                                <p class="text-value">{{ value && value != 'null null' ? value : 'N/A' }}</p>
                              </template>
                              <div v-if="key === 'transcript'" class="stat-value">
                                <a class="btn btn-primary" type="button" data-toggle="modal" data-target="#publicLoginModal">View</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!---------------
                    Baseball Stats
                  ---------------->
                  <div class="recruit-info">
                    <div class="stats-header">
                      <h4>Stats</h4>
                    </div>
                    <div class="row plr-10 justify-content-center text-center pb-15">
                      <a class="btn btn-primary" type="button" data-toggle="modal" data-target="#publicLoginModal">Login to View</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!------------
             Pro Day Videos
            -------------->
            <div class="tab-pane fade" id="pro" role="tabpanel" aria-labelledby="pro">
              <div class="row plr-10 justify-content-center text-center pb-15">
                <a class="btn btn-primary" type="button" data-toggle="modal" data-target="#publicLoginModal">Login to View</a>
              </div>
            </div>
            <!------------
            Interview
           -------------->
            <div class="tab-pane fade" id="interview" role="tabpanel" aria-labelledby="interview">
              <div class="row plr-10 justify-content-center text-center pb-15">
                <a class="btn btn-primary" type="button" data-toggle="modal" data-target="#publicLoginModal">Login to View</a>
              </div>
            </div>
            <!------------
             Additional Footage
            -------------->
            <div class="tab-pane fade" id="additional-footage" role="tabpanel" aria-labelledby="additional-footage">
              <div class="row plr-10 justify-content-center text-center pb-15">
                <a class="btn btn-primary" type="button" data-toggle="modal" data-target="#publicLoginModal">Login to View</a>
              </div>
            </div>
            <!------------
              Notes
            -------------->
            <div class="tab-pane" id="notes" role="tabpanel" aria-labelledby="notes">
              <div class="row plr-10 justify-content-center text-center pb-15">
                <a class="btn btn-primary" type="button" data-toggle="modal" data-target="#publicLoginModal">Login to View</a>
              </div>
            </div>
            <!------
            Events / Game Schedule
            -------->
            <div class="tab-pane" id="events" v-if="sport !== 'football'" role="tabpanel" aria-labelledby="events">
              <div class="row plr-10 justify-content-center text-center pb-15">
                <a class="btn btn-primary" type="button" data-toggle="modal" data-target="#publicLoginModal">Login to View</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import pitchTypes from '../../lib/pitch-types'
import Player from "../../lib/Player";
import UserAvatar from '@components/UserAvatar';

export default {
    name: "PublicPlayerProfile",
    components: { UserAvatar },
    props: [
      'playerJson',
      'sport',
      'logo',
      'comments',
    ],
    data() {
        return {
          displayTitles: null,
          player: null,
          openInfo: false,
          openSelect: false,
          selectedOption: 'Attributes',
          selected_position: "",
          years: Array.from(Array(new Date().getFullYear() - 2015), (_, i) => (i + 2016).toString()),
          statsFromDay: null,
          statsFromDaysInMonth: 31,
          statsFromMonth: null,
          statsFromYear: null,
          StatsFromDate: null,
          statsToDay: null,
          statsToDaysInMonth: 31,
          statsToMonth: null,
          statsToYear: null,
          statsToDate: null,
          submitStatsFilter: false,
          statsFiltered: false,
          months: [
            {text: 'January', value: 1},
            {text: 'February', value: 2},
            {text: 'March', value: 3},
            {text: 'April', value: 4},
            {text: 'May', value: 5},
            {text: 'June', value: 6},
            {text: 'July', value: 7},
            {text: 'August', value: 8},
            {text: 'September', value: 9},
            {text: 'October', value: 10},
            {text: 'November', value: 11},
            {text: 'December', value: 12}
          ]
        };
    },
    created() {
      this.player = this.playerJson.data
      this.player.footage.data.customFilm = this.player.footage.data.film.filter(film => film.owner.user_id != null)
    },
    watch: {
      selected_position: function () {
        this.getScripts()
      },
    },
    mounted() {
      this.selected_position = this.player.profile.data.positions.data.length > 0 ? this.player.profile.data.positions.data[0].id : '0';
    },
    computed: {
      attributeTables() {
        const sport = this.sport;
        const profile = this.player.profile.data;
        const playerObj = new Player({ player: this.player, sport: this.sport })
        this.displayTitles = this.player.profile.data.basics.data.sportBasics.data.displayTitles

        switch(sport) {
          case 'football': return profile;
          case 'softball': return [
            {
              key: 'measurables',
              data: {
                height: profile.measurables.data.height,
                bats: profile.basics.data.sportBasics.data.bats,
                throws: profile.basics.data.sportBasics.data.throws,
              }
            },
            {
              key: 'pitching',
              data: {
                pitch_types: playerObj.getTypesForRender()?.pitches,
                pitch_velocity: playerObj.getVelocitiesForRender()?.pitches + ' mph',
              }
            },
            {
              key: 'fielding velocity',
              data: {
                catcher_to_second: profile.basics.data.sportBasics.data.catcher_to_second !== null ? profile.basics.data.sportBasics.data.catcher_to_second + ' mph' : null,
                catcher_to_second_pop_up_time: profile.basics.data.sportBasics.data.catcher_to_second_pop_up_time !== null ? profile.basics.data.sportBasics.data.catcher_to_second_pop_up_time + ' seconds' : null,
                third_to_first: profile.basics.data.sportBasics.data.third_to_first !== null ? profile.basics.data.sportBasics.data.third_to_first + ' mph' : null,
                ss_to_first: profile.basics.data.sportBasics.data.ss_to_first !== null ? profile.basics.data.sportBasics.data.ss_to_first + ' mph' : null,
                of_to_home: profile.basics.data.sportBasics.data.of_to_home !== null ? profile.basics.data.sportBasics.data.of_to_home + ' mph' : null,
              }
            },
            {
              key: 'testing',
              data: {
                home_to_home: profile.basics.data.sportBasics.data.home_to_home,
                second_to_home: profile.basics.data.sportBasics.data.second_to_home,
                strength: profile.basics.data.sportBasics.data.strength !== null ? profile.basics.data.sportBasics.data.strength + ' seconds' : null,
              }
            },
            {
              key: 'academics',
              data: {
                unweighted_gpa: profile.academics.data.unweighted_gpa,
                transcript: profile?.academics?.data?.transcript?.uri,
                act: profile.academics.data.act,
                sat: profile.academics.data.sat,
                ncaa_id: profile.academics.data.ncaa_id,
              }
            },
          ];
          case 'baseball': return [
            {
              key: 'measurables',
              data: {
                height: profile.measurables.data.height,
                weight: profile.measurables.data.weight,
                bats: profile.basics.data.sportBasics.data.bats,
                throws: profile.basics.data.sportBasics.data.throws,
              }
            },
            {
              key: 'pitching',
              data: {
                pitch_types: playerObj.getTypesForRender()?.pitches,
                pitch_velocity: playerObj.getVelocitiesForRender()?.pitches + ' mph',
              }
            },
            {
              key: 'fielding',
              data: {
                catcher_to_second: profile.basics.data.sportBasics.data.catcher_to_second,
                third_to_first: profile.basics.data.sportBasics.data.third_to_first,
                ss_to_first: profile.basics.data.sportBasics.data.ss_to_first,
                of_to_home: profile.basics.data.sportBasics.data.of_to_home,
              }
            },
            {
              key: 'testing',
              data: {
                home_to_first: profile.basics.data.sportBasics.data.home_to_first,
                sixty_yard_dash: profile.basics.data.sportBasics.data.sixty_yard_dash,
                strength: profile.basics.data.sportBasics.data.strength,
              }
            },
            {
              key: 'academics',
              data: {
                unweighted_gpa: profile.academics.data.unweighted_gpa,
                transcript: profile?.academics?.data?.transcript?.uri,
                act: profile.academics.data.act,
                sat: profile.academics.data.sat,
                ncaa_id: profile.academics.data.ncaa_id,
              }
            },
          ];
          case 'mens_soccer':
          case 'womens_soccer': return [
            {
              key: 'measurables',
              data: {
                height: profile.measurables.data.height,
                dominant_foot: profile.basics.data.sportBasics.data.dominant_foot,
              }
            },
            {
              key: 'technical testing',
              data: {
                figure_eight_with_ball: profile.basics.data.sportBasics.data.figure_eight_with_ball,
                figure_eight_without_ball: profile.basics.data.sportBasics.data.figure_eight_without_ball,
                five_ten_shuttle_with_ball: profile.basics.data.sportBasics.data.five_ten_shuttle_with_ball,
                five_ten_shuttle_without_ball: profile.basics.data.sportBasics.data.five_ten_shuttle_without_ball,
              }
            },
            {
              key: 'gk technical assessment',
              data: {
                four_by_four_foundation_box_footwork: profile.basics.data.sportBasics.data.four_by_four_foundation_box_footwork,
                four_by_four_foundation_box_handling: profile.basics.data.sportBasics.data.four_by_four_foundation_box_handling,
                gk_low_dive: profile.basics.data.sportBasics.data.gk_low_dive,
                gk_collapse_dive: profile.basics.data.sportBasics.data.gk_collapse_dive,
                three_way_shot_stopping: profile.basics.data.sportBasics.data.three_way_shot_stopping,
              }
            },
            {
              key: 'fitness testing',
              data: {
                the_forty_without_ball: profile.basics.data.sportBasics.data.the_forty_without_ball,
                beep_test: profile.basics.data.sportBasics.data.beep_test,
                yoyo_test: profile.basics.data.sportBasics.data.yoyo_test,
                coopers_test: profile.basics.data.sportBasics.data.coopers_test,
                three_hundred_m_shuttle: profile.basics.data.sportBasics.data.three_hundred_m_shuttle,
                one_mile_time: profile.basics.data.sportBasics.data.one_mile_time,
              }
            },
            {
              key: 'additional testing',
              data: {
                power_shot: profile.basics.data.sportBasics.data.power_shot,
                placement_shot: profile.basics.data.sportBasics.data.placement_shot,
                precision_shot: profile.basics.data.sportBasics.data.precision_shot,
                vertical_jump: profile.basics.data.sportBasics.data.vertical_jump,
              }
            },
            {
              key: 'academics',
              data: {
                unweighted_gpa: profile.academics.data.unweighted_gpa,
                desired_major: profile.academics.data.desired_major,
                transcript: profile?.academics?.data?.transcript?.uri,
                act: profile.academics.data.act,
                sat: profile.academics.data.sat,
                ncaa_id: profile.academics.data.ncaa_id,
              }
            },
          ];
        }
      }
    },
    methods: {
      getDisplayName(attributeKey){
        if(this.displayTitles){
          if(Object.keys(this.displayTitles).includes(attributeKey)){
            return this.displayTitles[attributeKey]?.toUpperCase()
          }else{
            return attributeKey.replace(/_/g, ' ').toUpperCase();
          }
        }else{
          return attributeKey.replace(/_/g, ' ').toUpperCase();
        }
      },
      getScripts() {
        this.player.footage.data.filmSorted = this.player.footage.data.film.filter(script => script.owner.position_id === this.selected_position);
      },
      toggleOpenInfo () {
        this.openInfo = !this.openInfo;
      }
    }
}


</script>

<style scoped>

  .stats-row-testing {
    flex-basis: 25% !important;
  }

@media only screen and (max-device-width: 812px) {
  .modal-log-btn {
    margin-top: -12px;
    margin-bottom: -9px;
    padding-top: 4px;
  }
}
  @media screen and (max-width: 813px) {
    .stats-row-testing {
      flex-basis: 0 !important;
    }
  }

</style>
