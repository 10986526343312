<template>
  <fragment>
    <IconButton title="Edit Player Info" class="action-button" @onClick="toggleDrawer" v-bind="$attrs">
      <PencilIcon />
    </IconButton>
    <Drawer
      :isOpen="isDrawerOpen"
      heading="Edit Player Info"
      @onClose="handleCancel"
    >
      <EditPlayerInfoForm
        v-bind="{defaultValues, playerId, formName}"
        @onCancel="handleCancel"
        @onSuccess="handleSuccess"
      />
    </Drawer>
  </fragment>
</template>

<script>
  import { Fragment } from 'vue-frag';
  import Drawer from '@components/Drawer.vue'
  import IconButton from '@components/IconButton';
  import PencilIcon from '@components/svg/PencilIcon';
  import EditPlayerInfoForm from '@screens/myinvites/InviteListDetails/InviteListDetailsTable/components/PlayerRow/components/EditPlayerInfoForm';

  export default {
    name: 'EditPlayerInfoButton',
    components: { Drawer, IconButton, PencilIcon, EditPlayerInfoForm, Fragment},
    props: ['name', 'email', 'position', 'playerId', 'buttonProps'],
    inject: ['getPlayers'],
    data() {
      return {
        isDrawerOpen: false,
        formName: `editPlayerInfoForm-${this.playerId}`,
        defaultValues: {
          name: this.name,
          email: this.email,
          position: this.position,
        },
      }
    },
    methods: {
      handleCancel() {
        this.$formulate.reset(this.formName, this.defaultValues);
        this.toggleDrawer();
      },
      handleSuccess() {
        this.getPlayers();
        this.toggleDrawer();
      },
      toggleDrawer() {
        this.isDrawerOpen = !this.isDrawerOpen;
      }
    }
  }
</script>
