<template>
  <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.458008" width="40" height="40" rx="20" fill="black"/>
    <path d="M20 18.5859L13.636 12.2219L12.2218 13.6361L18.5858 20.0001L12.2218 26.3641L13.636 27.7783L20 21.4143L26.364 27.7783L27.7782 26.3641L21.4142 20.0001L27.7782 13.6361L26.364 12.2219L20 18.5859Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "CircleXIcon"
}
</script>
