<template>
  <div id="prospectlists-show">

    <div v-if="is_owner" class="row" style="margin-bottom: 15px;">
      <div class="col-lg-12" style="display:flex; flex-direction:column;">
        <h3><a :href="getProspectlistsIndexUrl()"><i class="fa fa-chevron-left fa-sm"></i> <span class="text-muted ml-3">Prospect Lists</span></a></h3>
      </div>
    </div>

    <div v-if="is_owner" class="row" style="margin-bottom: 15px;">
      <div class="col-lg-12" style="display:flex; flex-direction: row; justify-content: space-between;">
        <h4 class="page-title">{{plist.data.name}}</h4>

        <div style="display: flex; flex-direction: row; gap: 5px;">
          <span v-if="prospectlistToken">
            <a id="prospectlist-public-token-url" href="javascript:" :data-copy="`${this.prospectlistToken.url}`" class="btn btn-light waves-effect waves-light">Copy</a>
          </span>
          <span v-if="prospectlistToken">
            <a href="javascript:" @click="togglePublicProfile()" class="btn btn-light waves-effect waves-light">Revoke&nbspPublic&nbspLink</a>
          </span>
          <span v-if="prospectlistToken">
            <a class="btn btn-primary waves-effect waves-light" data-toggle="modal" data-target="#shareModal">Share</a>
          </span>
          <span v-if="!prospectlistToken">
            <a href="javascript:" @click="togglePublicProfile()" class="btn btn-primary waves-effect waves-light">Generate Public Link</a>
          </span>
          <span>
            <a :href="getProspectlistsEditUrl()" class="btn btn-secondary waves-effect waves-light">Edit&nbspList</a>
          </span>
        </div>
      </div>

      <share-interface
        :prospectlists="prospectlists"
        :organization_name="plist.data.organization.name"
        :coach_name="coach_name"
      ></share-interface>
    </div>

    <roster-table
      ref="roster-table" 
      :screen="screen"
      :sport_name="sport.name"
      :prospectlist_id="plist.data.id.toString()"
      :organization_id="plist.data.organization.id.toString()"
      :prospectlist="plist"
      :is_owner="is_owner"
      :is_member="is_member"
    ></roster-table>
  </div>
</template>

<script>
  import RosterTable from './RosterTable'
  import ShareInterface from '../components/ShareInterface'
  import utilityCode from '../utils'

  export default {
    components: { RosterTable, ShareInterface },

    props: {
      screen: {type: String, default: 'roster', required: false},
      hs: {type: String, default: false, required: false},
      plist: {type: Object, default() {return {}}, required: false},
      sport: {type: Object, default() {return {}}, required: false},
      coach_name: {type: String, default: null, required: false},
      is_owner: {type: Boolean, default() {return false}, required: false},
      is_member: {type: Boolean, default() {return false}, required: false},
    },

    data() {
      return {
        prospectlist: null,
      }
    },

    computed: {
      isHighSchool: ({ hs }) => JSON.parse(hs),
      prospectlists: ({prospectlist}) => { return (prospectlist) ? {prospectlist} : {};},
      prospectlistToken: ({prospectlist}) => { return (prospectlist) ? prospectlist.public_prospectlist_token : null;},
    },

    methods: {
      getProspectlistsIndexUrl(){
        return '/organizations/'+this.plist.data.organization.id+'/prospectlist?retain_filters=1';
      },
      getProspectlistsEditUrl(){
        return this.plist.data.id+'/edit';
      },
      setActiveUrl(){
        const target = $('#sidebar-menu ul li a[href$="/prospectlist"]');
        if(!target.hasClass("class-name")) target.addClass("active");
      },
      togglePublicProfile() {
        if (this.prospectlistToken === null) {
          const id = this.plist.data.id;

          axios.post(`/api/tokens`, {
            token_type: 3,
            model: 'prospectlist',
            id,
          }).then(response => {
            this.prospectlist = response.data.data;
            setTimeout(function(){ $('#prospectlist-public-token-url').trigger('click'); }, 500);
          }).catch(error => console.log(error))
        } else {
          axios.post(`/api/tokens/${this.prospectlistToken.id}`, {
            _method: 'PUT',
          }).then(response => {
            this.prospectlist = response.data.data;
          }).catch(error => console.log(error))
        }
      },
      async loadData() {
        this.prospectlist = this.plist.data;
      },
    },

    created() {
      this.setActiveUrl();
      this.loadData();
    },
  }
</script>

<style scoped>
</style>
