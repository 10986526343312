<template>
  <div>
    <div v-show="! loaded" class="loading" v-cloak>
      <span>
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div><br>
        Download/Processing Video
      </span>
    </div>
    <video class="video-js" data-setup='{"fluid": true, "controls": true, "autoplay": false, "muted": false, "preload": "auto"}'>
      <source :src="film.file_upload.data ? film.file_upload.data.optimized_url : film.file_upload.optimized_url"></source>
    </video>
    <div class="input-group start-time" style="padding-top: 15px"  v-show="! overlaid && loaded">
      <input type="number" step=".01" class="form-control" placeholder="Start Time (in seconds)" v-model="start_time">
      <div class="input-group-prepend">
        <button class="btn btn-primary" type="button">Set Start to Current</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .video-js .vjs-play-progress .vjs-time-tooltip {
    color: rgba(255, 255, 255, 0) !important;
    background-color: rgba(155,155,155,0) !important;
  }

  .video-js .mwi-time-tooltip {
      color: #000;
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 0.3em;
      color: #000;
      float: right;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 1em;
      padding: 6px 8px 8px 8px;
      pointer-events: none;
      position: absolute;
      top: -3.4em;
      visibility: hidden;
      z-index: 1;
  }

  .video-js .vjs-progress-control:hover .mwi-time-tooltip,
  .video-js .vjs-progress-control:hover .vjs-progress-holder:focus .mwi-time-tooltip {
      display: block;
      font-size: 0.6em;
      visibility: visible;
  }
</style>

<script scoped>
  import { setTimeout, clearTimeout } from 'timers-browserify';
import { parse } from 'url';
  export default {
    name: 'Film',
    props: ['film', 'playing', 'overlaid'],

    data() {
      return {
        loaded: false,
        start_time: this.film?.start_time || 0,
        timeChanging: false,
        timer: null,
      }
    },

    mounted() {
      let $video = $('video', this.$el)
      let $start = $('.start-time input', this.$el)
      let $setBtn = $('.start-time button', this.$el)

      if (this.film?.user_start_time && this.film?.user_start_time.length === 1) {
        this.start_time = this.film?.user_start_time[0]?.pivot?.start_time
      }

      let self = this
      videojs($video[0])?.ready(function() {
        let video = this

        video?.tech_?.off('dblclick')

        video.on('loadedmetadata', () => {
          self.loaded = true
        })

        video.currentTime(self.start_time)
        video.on('ended', () => {
          video.currentTime(self.start_time)
          self.playing(false)
        })
        $start.on('keyup change', () => {
          video.currentTime($start.val())
          self.updateStartTime($start.val())
        })
        $setBtn.on('click', () => {
          self.updateStartTime(video.currentTime().toFixed(2))
        })

        const $currentTimeToolTip = $('.vjs-play-progress .vjs-time-tooltip', self.$el).first();
        $currentTimeToolTip.after(`<span class="mwi-time-tooltip"></span>`);
        const $timeLeft = $('.vjs-remaining-time-display', self.$el).first();
        const $mwiTimeToolTip = $('.mwi-time-tooltip', self.$el).first();

        setInterval(function() {
          let fullLength = video.duration();
          var whereYouAt = video.currentTime().toFixed(2);
          $timeLeft.html(`<span>${(fullLength - whereYouAt).toFixed(2)}</span>`);
          $mwiTimeToolTip.text(whereYouAt);
          $mwiTimeToolTip.css('right', $currentTimeToolTip.css('right'));
        }, 100);

        $(window).trigger('resize')
      })
    },

    methods: {
      updateStartTime(value) {
        this.start_time = value
        this.film.start_time = value
        if (this.film?.user_start_time.length === 1) {
          this.film.user_start_time[0].pivot.start_time = value
        }

        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          axios.put('/api/videos/' + this.film.id, {start_time: value}).then(response => {
            toastr.success('Start time updated', '');
          }).catch(error => {
            toastr.error('Error modifying start time. Please try again.', '');
          })
        }, 750)
      },
    }
  }
</script>
