<template>
  <div class="row align-items-center justify-content-between">
    <h3 class="d-inline-flex">Schedule</h3>
    <div class="d-inline-flex">
      <span class="game-dot"></span>
      <h4 class="d-inline">GAME</h4>
      <span class="practice-dot" style="margin-left: 2em;"></span>
      <h4 class="d-inline" style="color: #B0D0CE">PRACTICE</h4>
    </div>
    <FullCalendar :options="{...calendarOptions, events}" ref="full-calendar"/>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'

export default {
  name: 'EventSchedule',
  components: {
    FullCalendar
  },
  props: {
    events: { type: Array, default: null, required: true }
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin],
        editable: false,
        initialView: 'dayGridMonth',
        height: 'auto',
        headerToolbar: {
          start: 'prev',
          center: 'title',
          end: 'next'
        },
        eventDidMount: function (arg) {
          const type = arg?.event?._def?.title
          const { el } = arg
          const $dot = $(el).find('.fc-daygrid-event-dot')
          switch (type) {
            case 'GAME':
              $dot.addClass('game-dot')
              break;
            case 'PRACTICE':
              $dot.addClass('practice-dot')
              break;
          }
        },
        eventClick: function(info) {
          const eventObj = info.event;
          const description = eventObj.extendedProps.description || ''
          const el = document.querySelector('.eventDisplay');
          el.innerHTML =`<h3>Selected Event</h3>
            <div class='card-header pb-10'><h4>${eventObj.title}</h4></div>
            <div class='pb-10 pt-2'><h5>When: </h5>${eventObj.start}</div>
            <div class='pb-12'><h5>Where: </h5>${eventObj.extendedProps.address}, ${eventObj.extendedProps.city}, ${eventObj.extendedProps.state} ${eventObj.extendedProps.zip}</div>
            <div class='pb-10 pt-2'><h5>Description: </h5>${description}</div>`
          return el

        },
      }
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
