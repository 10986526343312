<template>
  <button @click="$emit('onClick')" :class="`nav-link text-uppercase table-section ${active ? 'active' : ''}`">
    <slot></slot>
  </button>
</template>

<script>
  export default {
    name: 'TabControl',
    props: ['active']
  }
</script>
