window.Helpers = {
  init: () => {
    /**
     * Toggle the active class of an element
     *
     */
    $('body').on('click', '[data-activate]', function(e) {
      e.preventDefault()
      $($(this).data('activate')).toggleClass('active');
      $(window).trigger('resize')
    })

    /**
     * Toggle visibility of an element
     *
     */
    $('body').on('click', '[data-show]', function(e) {
      e.preventDefault()

      $($(this).data('show')).toggle();
      $(window).trigger('resize')
    })

    /**
     * Render a dynamic component modal
     *
     */
    $('body').on('click', '[data-dynamic-component]', function(e) {
      e.preventDefault();

      let props = $(this).data('props') !== undefined ? $(this).data('props') : ''

      $('#dynamic-component .modal-title').text($(this).data('modal-title'));
      $('#dynamic-component .modal-body').html('<'+$(this).data('dynamic-component')+' '+props+' />');

      const player = new Vue({
        el: '#dynamic-component'
      })

      $('#dynamic-component').modal()

    })

    /**
     * Copy the data-copy text to clipboard
     *
     */
    $('body').on('click', '[data-copy]', function(e) {
      e.preventDefault();

      copy($(this).data('copy'));

      toastr.info('', 'Copied to clip board')
    })

    /**
     * Confirm the user wants to visit the url in href
     *
     */
    $('body').on('click', '[data-confirm]', function(e) {
      e.preventDefault();

      bootbox.confirm($(this).data('confirm'), (result) => {
        if (result) {
          window.location.href = $(this).attr('href')
        }
      })
    })

    /**
     * Ajax call for User Guidance - Steps
     *
     */
    $('body').on('click', '[data-ajax]', function(e) {
      e.preventDefault();

      $.ajax({
        type: "get",
        url: $(this).attr('href'),
        success: function(response) {
          if(response.message == "skipped") {
            window.location.reload();
          } else if (response.message == "done") {
            window.location.reload();
          } else {
            window.location.href = response;
          }
        }
      })
    })
  },
  measurements: {
    formatDecimal: (value) => {
      if (value) {
        let leftValue = value.split('.')[0]
        let rightValue = window.Helpers.measurements.inchesDecimalToFraction(value.split('.')[1])

        return leftValue + rightValue
      }
      return 'N/A'
    },
    inchesDecimalToFraction: (decimal) => {
      switch (decimal) {
        case '125':
          return ' 1/8"'
        case '25':
          return ' 1/4"'
        case '375':
          return ' 3/8"'
        case '5':
          return ' 1/2"'
        case '625':
          return ' 5/8"'
        case '75':
          return ' 3/4"'
        case '875':
          return ' 7/8"'
        default:
          return '"'
      }
    },
    formatMeasurementRevision: (key, value) => {
      let newValue = value

      switch (key) {
        case 'height':
          let heightFeet = Math.floor(value / 12)
          let heightInch = value % 12

          newValue = heightFeet + "' " + heightInch + '"'
          break
        case 'weight':
        case 'squat':
          newValue += ' lbs'
          break
        case 'wing_span':
          newValue = window.Helpers.measurements.formatDecimal(newValue)
          break
        case 'hand_size':
          newValue = window.Helpers.measurements.formatDecimal(newValue)
          break
        case 'standing_broad_jump':
          let standingBroadJumpFeet = Math.floor(value / 12)
          let standingBroadJumpInch = value % 12

          newValue = standingBroadJumpFeet + "' " + standingBroadJumpInch + '"'
      }

      return newValue
    }
  }
}
