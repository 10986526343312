<template>
  <div :class="isDropdownActive ? 'dropdown-active' : 'dropdownB'" @click="isDropdownActive = !isDropdownActive">
    <div :class="isDropdownActive ? 'menu-dropdown-active' : 'menu-dropdown'">
      <h4 class="selected-dropdown">{{ tabLabels[activeTabKey] }}</h4>
      <div style="padding: 15px 10px"><i :class="isDropdownActive ? 'fa fa-chevron-down fa-lg fa-flip-vertical' : 'fa fa-chevron-down fa-lg'" aria-hidden="true"></i></div>
    </div>
    <div class="dropdown-options">
      <ul v-show="isDropdownActive" transition="slide-in-out" class="nav-options">
        <li class="nav-item">
          <button
            @click="handleTabChange(tabKeys.attributes)"
            :class="`nav-link text-uppercase table-section ${activeTabKey === tabKeys.attributes? 'active' : ''}`"
          >
            Attributes
          </button>
        </li>
        <li class="nav-item" >
          <button
            @click="handleTabChange(
              tabKeys.proDayVideos,
              videoTabLabel
            )"
            :class="`nav-link text-uppercase ${activeTabKey === tabKeys.proDayVideos || activeTabKey === tabKeys.fundamentals ? 'active' : ''}`"
          >
            {{  videoTabLabel }}
          </button>
        </li>
        <li class="nav-item" v-if="player.footage.data.interviews.length > 0">
          <button
            @click="handleTabChange(tabKeys.interview)"
            :class="activeTabKey === tabKeys.interview ? 'nav-link text-uppercase active' : 'nav-link text-uppercase'"
          >
            Interviews
          </button>
        </li>
        <li class="nav-item table-section">
          <button
            @click="handleTabChange(tabKeys.notes)"
            :class="activeTabKey === tabKeys.notes ? 'nav-link text-uppercase active' : 'nav-link text-uppercase'"
          >
            Notes
          </button>
        </li>
        <li class="nav-item table-section" v-if="['baseball','softball'].includes(sport) && isAuthorized">
          <button
            @click="handleTabChange(tabKeys.events)"
            :class="activeTabKey === tabKeys.events ? 'nav-link text-uppercase active' : 'nav-link text-uppercase'"
          >
            Events
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'MobileNav',
    props: ['activeTabKey', 'tabKeys', 'sport', 'handleTabChange', 'player', 'isAuthorized'],
    data() {
      return {
        isDropdownActive: false,
        activeTabLabel: 'Attributes',
      }
    },
    computed: {
      tabLabels() {
        return {
          notes: 'Notes',
          events: 'Events',
          interview: 'Interview',
          attributes: 'Attributes',
          proDayVideos: 'Pro Day Videos',
          additionalFootage: 'Additional Footage',
          sluggerScience: 'Slugger Science Data',
        }
      },
      videoTabLabel() {
        switch (this.sport) {
          case 'football':
            return 'Pro Day Videos';
          case ('baseball' || 'softball'):
            return 'Fundamentals';
          case ('mens_soccer' || 'womens_soccer'):
            return 'Key Player Actions';
        }
      }
    }
  }
</script>

<style lang="scss">
  .dropdown-options {
    .nav-item {
      .nav-link {
        border: none;
        text-align: left;
        width: 100%;

        &.active {
          background-color: #000;
          color: #fff;
        }
      }
    }
  }
</style>
