<template>
  <span>
    <h2 v-if="defaultSearch">START A CONVERSATION</h2>
    <div v-if="defaultSearch" class="row text-row">
      <div class="col-12">
        <p>Search by Organization</p>
      </div>
    </div>
    <div v-if="coachOnlySearch" class="row text-row">
      <div class="col-12">
        <h4><label>Search by Organization</label></h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <select name="organization_id" class="form-control search-organization" v-model="organization_id" v-select2></select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h4>OR</h4>
      </div>
    </div>
    <div class="row text-row">
      <div v-if="defaultSearch" class="col-12">
        <p v-if="this.user.organization_type === 'college'">Search by player or coach to message</p>
        <p v-else>Select a player on your roster or a coach to message</p>
      </div>
      <div v-if="coachOnlySearch" class="col-12">
        <h4><label>Search by Coach</label></h4>
      </div>
    </div>
    <div v-if="defaultSearch" class="row">
      <div class="col-6">
        <div class="form-group">
          <select name="player_id" class="form-control search-player" v-model="player_id" v-select2></select>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <select name="coach_id" class="form-control search-coach" v-model="coach_id" v-select2></select>
        </div>
      </div>
    </div>
    <div v-else-if="coachOnlySearch" class="row">
      <div class="col-12">
        <div class="form-group">
          <select name="coach_id" class="form-control search-coach" v-model="coach_id" v-select2 @change="onCoachChangeHandler(coach_id)"></select>
        </div>
      </div>
    </div>

    <div v-if="defaultSearch"  class="row">
      <div class="col-6 warning">
        <p v-show="!canSelectOrgPlayer">Can't message players on external rosters!</p>
      </div>
      <div class="col-6">
        <button class="pull-right btn btn-primary" @click="getPlayer()" :disabled="isdisabled">Chat</button>
      </div>
    </div>
  </span>
</template>

<style>
  .select2-selection__clear {
    margin-right: 20px;
    font-size: 24px;
  }

  .text-row {
    margin-bottom: -10px;
  }

  .warning {
    margin-top: -10px;
    color: red;
    font-size: 12px;
  }
</style>

<script>
    export default {
        name: 'start-conversation',
        props: {
              onCoachChangeHandler: {type: Function, default() {return null}, required: false},
              screen: {type: String, default() {return 'default'}, required: false},  // options {'default', 'coaches'}
        },
        data() {
            return {
              player_id: false,
              coach_id: false,
              organization_id: false,
              user: {},
              canSelectOrgPlayer: true,
            }
        },

        computed: {
              isdisabled: ({player_id, coach_id}) => !(player_id || coach_id),
              hasCoachChangeHandler: ({onCoachChangeHandler}) => (onCoachChangeHandler !== null),
              defaultSearch: ({screen}) => screen==='default',
              coachOnlySearch: ({screen}) => screen==='coaches',
        },

        watch: {
          player_id: function (newVal) {
            if (newVal !== false) {
              this.coach_id = false
              $('.search-coach').select2().val(null).trigger('change')
              this.initializeCoachSelect()
            }
          },
          coach_id: function (newVal) {
            if (newVal !== false) {
              this.player_id = false
              $('.search-player').select2().val(null).trigger('change')
              this.initializePlayerSelect()
            }
          },
          organization_id: function () {
            this.coach_id = false
            this.player_id = false
            $('.search-coach').select2().val(null).trigger('change')
            $('.search-player').select2().val(null).trigger('change')
            this.initializeCoachSelect()
            if ((this.user.organization_type === 'college') || (this.user.organization_id == this.organization_id) || (this.organization_id === undefined)) {
              this.canSelectOrgPlayer = true
              this.initializePlayerSelect()
            }
            else {
              this.canSelectOrgPlayer = false
              $('.search-player').select2({
                disabled: true
              })
            }
          }
        },

        mounted: function() {
          $(document).ready(() => {
            axios.get('/api/user').then(response => {
              this.user = response.data.data
            }).then(() => {
              this.initializeCoachSelect()
              this.initializePlayerSelect()
              this.initializeOrganizationSelect()
            });
            $('.select2').select2()
          });
        },

        methods: {
          initializeCoachSelect() {
            let requestUrl = this.organization_id ? ('/api/coaches/messaging-search?org=' + this.organization_id) : '/api/coaches/messaging-search'
            $('.search-coach').select2({
              placeholder: 'Select Coach',
              ajax: {
                url: requestUrl,
                dataType: 'json',
                processResults: function (data) {
                  const sortedData = data.sort(function(a, b) {
                    const nameA = a.first_name.toUpperCase();
                    const nameB = b.first_name.toUpperCase();
                    return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
                  });
                  return {
                    results:  $.map(sortedData, function (item) {
                      return {
                        text: item.first_name + ' ' + item.last_name + ' - ' + item.organization_name + ', ' + item.city + ' ' + item.state,
                        id: item.id
                      }
                    })
                  };
                },
                cache: true
              }
            })
          },
          initializePlayerSelect() {
            let requestUrl = this.organization_id ? ('/api/recruits/search?org=' + this.organization_id) : '/api/recruits/search'
            $('.search-player').select2({
              placeholder: 'Select Player',
              disabled: false,
              // minimumInputLength: 3,
              ajax: {
                url: requestUrl,
                dataType: 'json',
                processResults: function (data) {
                  const sortedData = data.sort(function(a, b) {
                    const nameA = a.first_name.toUpperCase();
                    const nameB = b.first_name.toUpperCase();
                    return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
                  });
                  return {
                    results:  $.map(sortedData, function (item) {
                      return {
                        text: item.first_name + ' ' + item.last_name,
                        id: item.id
                      }
                    })
                  };
                },
                cache: true
              }
            })
          },
          initializeOrganizationSelect() {
            let vm = this
            let requestUrl = (vm.user.organization_type === 'college')
              ? '/api/organizations/get-by-type?type[]=high_school&type[]=academy&type[]=club'
              : '/api/organizations/get-by-type?type[]=college'
            $('.search-organization').select2({
              placeholder: 'Select Organization',
              allowClear: true,
              ajax: {
                url: requestUrl,
                dataType: 'json',
                processResults: function (data) {
                  var j = data
                  j.unshift({id: vm.user.organization_id, name: 'My Organization'})
                  return {
                    results: $.map(j, function (item){
                      return {
                        text: item.name,
                        id: item.id
                      }
                    })
                  };
                },
                cache: true
              }
            })
          },
          getPlayer() {
            window.location = '/recruits/' + (this.player_id ? this.player_id : this.coach_id) + '/message';
          }
        }

    }
</script>
