<template>
  <div :class="`details-container ${isExpanded ? 'show' : ''}`">
    <DetailsContainerRow
      v-if="city || state"
      :row-data="`${city}${city && state ? ', ' : ''}${state}`"
      :start-icon="LocationIcon"/>
    <DetailsContainerRow
      v-if="schoolData.name"
      :row-data="`${schoolData.name}${schoolData.name && gradYear ? ' - ' : '' }${gradYear}`"
      :start-icon="GradCapIcon">
      <SchoolDataTooltip
        :street="schoolData.street"
        :zip="schoolData.zip"
        :countyName="schoolData.countyName"
        :phone="schoolData.phone"
        :url="schoolData.url"
      />
    </DetailsContainerRow>
    <DetailsContainerRow
      v-if="positions"
      :row-data="`Position - ${positions}`"
      :start-icon="PublicUserIcon"/>
    <DetailsContainerRow
      v-if="jerseyNumber"
      :row-data="`Jersey # ${jerseyNumber}`"
      :start-icon="PublicUserIcon"/>
    <SocialMediaContainer />
    <Button v-if="hasTranscript" @onClick="toggleTranscriptModal" color="none" :startIcon="fileIcon">View Transcript</Button>
    <TranscriptRequestModal :isOpen="isTranscriptModalOpen" @onClose="toggleTranscriptModal" />
  </div>
</template>

<script>
import Button from '@components/Button';
import FileIcon from "@components/svg/FileIcon";
import GradCapIcon from "@components/svg/GradCapIcon";
import LocationIcon from "@components/svg/LocationIcon";
import PublicUserIcon from "@components/svg/PublicUserIcon";
import SchoolDataTooltip from "@components/SchoolDataTooltip";
import DetailsContainerRow from "@components/PlayerProfile/PublicProfile/components/DetailsContainerRow";
import SocialMediaContainer from "@components/PlayerProfile/PublicProfile/components/SocialMediaContainer";
import TranscriptRequestModal from '@components/PlayerProfile/PublicProfile/components/TranscriptRequestModal';

export default {
  name: "PublicDetailsContainer",
  components: {DetailsContainerRow, SchoolDataTooltip, SocialMediaContainer, LocationIcon, GradCapIcon, PublicUserIcon, Button, TranscriptRequestModal, FileIcon},
  inject: ['player', 'isExpanded'],
  data() {
    return {
      LocationIcon,
      GradCapIcon,
      PublicUserIcon,
      isTranscriptModalOpen: false,
      fileIcon: FileIcon,
    }
  },
  methods: {
    toggleTranscriptModal: function() {
      this.isTranscriptModalOpen = !this.isTranscriptModalOpen;
    },
  },
  computed: {
    schoolData() {
      const baseSchoolData = this.player.profile.school;
      return {
        name: baseSchoolData?.name,
        street: baseSchoolData?.street,
        zip: baseSchoolData?.zip,
        countyName: baseSchoolData?.countyName,
        phone: baseSchoolData?.phone,
        url: baseSchoolData?.url,
      };
    },
    city() {
      return this.player?.profile?.city ?? ''
    },
    state() {
      return this.player?.profile?.state ?? ''
    },
    gradYear() {
      return this.player?.profile?.grad_year ?? ''
    },
    positions() {
      return this.player?.profile?.positions ?? ''
    },
    jerseyNumber() {
      return this.player?.profile?.jersey_num ?? ''
    },
    hasTranscript() {
      return this.player.profile.academics.hasTranscript
    }
  },
}
</script>
