<template>
  <div class="success-container">
    <div class="success-modal">
      <div class="success-header">
        <h5 class="header-text">Invite successfully sent</h5>
      </div>
      <div class="success-body">
        <h1>Your invitations have been sent</h1>
        <p>Invitation successfully sent to {{ invite_data.invited_players.length }} players, keep track of their filled info on this tab.</p>
      </div>
      <div class="success-footer">
        <button @click="$emit('invitation_complete')" class="btn btn-primary">Close</button>
      </div>
    </div>
  </div>
<!--  <b-container>-->
<!--    <b-row class="header">-->
<!--      <b-col class="headerCol" style="text-align: right;">-->
<!--        &nbsp;-->
<!--      </b-col>-->
<!--    </b-row>-->
<!--    <b-row sm="6" offset-sm="3" md="3" offset-md="4" class="dataRow">-->
<!--      <b-col>-->
<!--        <h1 class="successTitle">Your invitations have been sent</h1>-->
<!--        <p>-->
<!--          Invitation successfully sent to {{ invite_data.invited_players.length }} players, keep track of their filled in info on this tab.-->
<!--        </p>-->
<!--      </b-col>-->
<!--    </b-row>-->
<!--    <b-row class="continueFooter">-->
<!--      <b-col>-->
<!--        <button @click="$emit('invitation_complete')" type="button" class="btn btn-primary" style="text-transform:uppercase;padding: 7px 30px; font-family: KairosSans,sans-serif;">Return to Grid</button>-->
<!--      </b-col>-->
<!--    </b-row>-->
<!--  </b-container>-->
</template>
<script>
import { BContainer, BRow, BCol } from "bootstrap-vue";
export default {
  name: "SuccessMessage",
  components: {BContainer,BRow,BCol},
  props: {
    count: { type:Number, required:true },
    invite_data: { type: Object, required: true }
  },
  data: () => ({

  })
}
</script>
<style scoped>
.success-container {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success-header {
  background:black;
  color:white;
  font-family: KairosSans,sans-serif;
  text-transform:uppercase;
  border-top:5px solid #E7F818;
  border-radius:5px 5px 0px 0px;
  padding: 1rem 2rem;
}

.header-text {
  color: white;
  position: relative;
  top: 5px;
}

.success-body {
  padding: 2rem;
  background:#f0f0f0;
  text-align: center;
}

.success-footer {
  padding: 1rem 2rem;
  background: #f9f9f9;
  border-bottom: 5px #646464;
  border-radius: 0 0 5px 5px;
  display: flex;
  justify-content: flex-end;
}

.btn {
  font-family: KairosSans,sans-serif;
  text-transform:uppercase;
}
</style>
