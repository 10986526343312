// we can either use an api call to fetch these from the DB or use this hardcoded map
// pulled shortcodes from https://www.mlb.com/glossary/pitch-types
const pitchTypes = [
  { id: 1, shortcode: 'FA', name: '4 Seam Fastball' },
  { id: 2, shortcode: 'SI', name: '2 Seam Sinker' },
  { id: 3, shortcode: 'FC', name: '2 Seam Cutter' },
  { id: 4, shortcode: 'FS', name: 'Split' },
  { id: 5, shortcode: 'CH', name: 'Changeup' },
  { id: 6, shortcode: 'SL', name: 'Slider' },
  { id: 7, shortcode: 'CU', name: 'Curveball' },
  { id: 8, shortcode: 'KN', name: 'Knuckleball' },
  { id: 9, shortcode: 'SL', name: 'Slurve'}
];


export default pitchTypes;
