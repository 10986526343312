<template>
  <PlayerStatTilesPanel>
    <div v-for="positionDrill in positionDrills">
      <PlayerStatTile
        :stat-name="positionDrill.name"
        :stat-value="positionDrill.value"
        :thumbnail-image="positionDrill.thumbnailImage"
        :video="positionDrill.video"
      />
    </div>
  </PlayerStatTilesPanel>
</template>

<script>
import PlayerStatTile from '@components/PlayerProfile/PublicProfile/components/PlayerStatTile';
import PlayerStatTilesPanel from '@components/PlayerProfile/PublicProfile/components/PlayerStatTilesPanel';

export default {
  name: "PositionDrillsPanel",
  components: { PlayerStatTilesPanel, PlayerStatTile },
  inject: ['player'],
  computed: {
    positionDrills() {
      return Object.values(this.player?.position_drills).map((stat) => {
        return {
          name: stat.name,
          value: stat.value,
          thumbnailImage: stat.attribute?.file_upload?.thumbnail_path,
          video: stat.attribute?.file_upload?.path
        }
      })
    }
  }
}
</script>
