<template>
    <div>
        <div :id="`post_${this.post.id}`" class="sl-item feed-item">
            <div class="sl-left">
                <user-avatar
                  :user="this.post.user.data"
                  :class="'comment-photo'"
                  width="3rem"
                  height="3rem"
                  fontSize="1.5rem"
                />
            </div>
            <div class="sl-right">
                <div>
                    <a :href="this.post.user.data.role == 'player' ? '/user/' + this.post.user.data.id : 'javascript:void(0);'" class="link font-weight-bold">{{post.user.data.full_name}}</a>
                    <span class="sl-date" style="float: right;">{{post.created}}
                        <div class="btn-group" v-if="this.post.editable">
                            <button class="btn btn-primary btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            </button>
                            <div class="dropdown-menu" v-if="this.post.editable">
                                <a class="dropdown-item" v-on:click="editPost">Edit</a>
                                <a class="dropdown-item" v-on:click="deletePost">Delete</a>
                            </div>
                        </div>
                    </span>
                </div>
                <div v-if="this.editing" class="content">
                    <form class="form-material">
                        <textarea name="content" class="post-box__textarea form-control post-edit" data-expanding-textarea>{{this.post.message}}</textarea>
                        <div class="button-group" style="margin-top: 5px; text-align: right;">
                            <button v-on:click="updatePost" type="button" class="btn waves-effect waves-light btn-xs btn-primary">Update</button>
                            <button v-on:click="cancelUpdatePost" type="button" class="btn waves-effect waves-light btn-xs btn-danger">Cancel</button>
                        </div>
                    </form>
                </div>
                <div v-else class="content" v-html="linkText(this.post.message)"></div>
                <!-- Images -->
                <div v-if="this.post.images.length > 0">
                     <div v-if="this.post.images.length == 1" class="one-image">
                        <img :src="this.post.images[0].url" class="post-image" v-on:click="showGallery(0)">
                    </div>
                    <div v-else class="multi-images">
                        <div class="img-responsive-wrap" v-for="(image, index) in this.post.images">
                            <span class="img-responsive" :style="`background-image: url('${image.url}');`" v-on:click="showGallery(index)">
                            </span>
                        </div>
                    </div>
                </div>
                <!-- Videos -->
                <div v-if="this.post.videos.length > 0">
                    <div class="fitVids" v-for="video in this.post.videos">
                        <video controls><source :src="video.url"></source></video>
                    </div>
                </div>
            </div>
            <div class="like-comm">
                <span class="link m-r-10 cursor-pointer">
                    <i v-on:click="like" v-bind:class="{'icon ion-ios7-heart': this.post.liked, 'icon ion-ios7-heart-outline' : !this.post.liked}"></i>
                    <span>{{this.post.like_count}}</span>
                </span>
                <span class="link m-r-10 cursor-pointer" v-on:click="viewComments" >
                    <i class="fa fa-comment-o"></i> <span>{{this.post.comment_count}}</span>
                </span>
            </div>
            <div v-if="this.commentsVisible" class="post-comments">
                <div v-if="this.moreComments" class="comment row">
                    <div class="col-12 text-center">
                        <span v-on:click="getComments" class="link show-more">Show More Comments</span>
                    </div>
                </div>
                <post-comment v-for="(item, key) in this.post.comments.data"  :key="key" :comment="item" v-on:update_comments="updateComments(item)"/>
                <div class="comment row">
                    <div class="col-12 post-box__actions relative">
                        <textarea placeholder="Add Comment" create-comment auto-hyperlink class="post-box__textarea post-edit comment-box" data-expanding-textarea></textarea>
                        <button class="btn btn-primary waves-effect waves-light submit-btn post-btn comment-submit" v-on:click="makeComment" :disabled="isUploading"><i class="icon ion-ios7-paperplane"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="post-border"></div>
    </div>
</template>
<script>
    import axios from 'axios'
    import PostComment from './PostComment'
    import UserAvatar from '@components/UserAvatar';

    export default {
        name: 'PostCardComponent',
        props: {
          user_id: String,
          post: Object
        },
        components: { UserAvatar },
        data() {
            return {
                editing: false,
                commentsVisible: false,
                commentEditing: false,
                isUploading: false,
                moreComments: false,
                likes: []
            }
        },
        mounted(){
            this.showMoreComments()
        },
        methods: {
            editPost: function(e) {
                this.editing = true
            },
            updatePost: function(e) {
                this.post.message = $('.post-edit').val()
                axios.put('/api/posts/' + this.post.id, {message: this.post.message}).then((response) => {
                    Swal.fire({
                        title: 'Updated!',
                        text: 'The post has been successfully updated.',
                        type: 'success',
                        timer: 1700,
                        onClose: () => {
                        }
                    })
                    this.editing = false
                }).catch((error) => {
                    console.log(error)
                });
            },
            cancelUpdatePost: function(e) {
                this.editing = false
            },
            deletePost: function(e) {
                Swal.fire({
                  title: 'Are you sure?',
                  text: "This will remove the post from the system.",
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, remove Post!',
                  cancelButtonText: 'No, cancel!',
                  confirmButtonClass: 'btn btn-confirm mt-2',
                  cancelButtonClass: 'btn btn-cancel ml-2 mt-2',
                  reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        axios.delete('/api/posts/' + this.post.id).then((response) => {
                            let post_id = this.post.id
                            $.each(window.vueInstance.$refs.post.posts, function(index, val) {
                                if(post_id == val.id) {
                                    window.vueInstance.$refs.post.posts.splice(index,1)
                                    return false
                                }
                            });
                            this.$emit('update_posts')
                            Swal.fire({
                                title: 'Removed!',
                                text: 'The post has been removed.',
                                type: 'success',
                                timer: 1700,
                                onClose: () => {
                                }
                            })
                        }).catch((error) => {
                            console.log(error)
                            Swal.fire({
                                title: 'Error!',
                                text: 'There was an error removing the post.',
                                type: 'error',
                            })
                        })
                    }
                    else if (result.dismiss === Swal.DismissReason.cancel) {}
                });
            },
            like: function(e) {
                this.post.liked = (!this.post.liked) ? true : false
                axios.post('/api/like', {
                    'likeable_type': 'Post',
                    'likeable_id': this.post.id
                }).then((response) => {
                    (response.data.data.attached == true)
                        ? this.post.like_count++ : this.post.like_count--
                }).catch((error) => {
                    console.log(error)
                })
            },
            makeComment: function() {
                axios.post('/api/comments', {
                    'comment': $(`#post_${this.post.id} [create-comment]`).val(),
                    'commentable_id': this.post.id,
                    'commentable_type': 'Post',
                    'filter': 'single',
                }).then((response) => {
                    $('[create-comment]').val('')
                    this.post.comment_count++
                    this.post.comments.data.push(response.data.data)
                }).catch((error) => {
                    console.log(error)
                })
            },
            viewComments: function(e) {
                if (!this.commentsVisible) {
                    this.commentsVisible = true
                }
                else {
                    this.commentsVisible = false
                }
            },
            getComments: function(e) {
                axios.get(`/api/comments?id=${this.post.id}&type=Post&filter=posts`, {
                        params: {
                            page: Math.floor(this.post.comments.data.length / 4) + 1,
                        }
                    }).then((response) => {
                    if(this.post.comments.data.length > 0) {
                        for(let x = 0; x < response.data.data.length; x++) {
                            this.post.comments.data = [response.data.data[x]].concat(this.post.comments.data)
                        }
                    }
                    else {
                        this.post.comments.data = response.data.data.reverse()
                    }
                    this.showMoreComments()
                    this.commentsVisible = true
                })
            },
            linkText: function(e) {
                 return linkifyStr(e);
            },
            editComment: function(comment) {
                this.commentEditing = (!this.commentEditing) ? true : false
            },
            updateComments: function(comment) {
                for(let x = 0; x<this.post.comments.data.length; x++) {
                    if(comment.id == this.post.comments.data[x].id) {
                        this.post.comments.data.splice(x,1)
                    }
                }
                this.post.comment_count = this.post.comments.data.length
                this.$forceUpdate
            },
            showMoreComments: function() {
                this.moreComments = (this.post.comment_count > this.post.comments.data.length)
            },
            showGallery: function(index) {
                let imagesArr = []
                for (let i = 0;i < this.post.images.length; i++) {
                    imagesArr.push({'src': this.post.images[i].url})
                }
                $('.lightgallery').lightGallery({
                    dynamic: true,
                    index: index,
                    dynamicEl: imagesArr
                })
                $('.lightgallery').one("onCloseAfter.lg", function() {
                    $('.lightgallery').data('lightGallery').destroy(true)
                })
            }
        }
    }
</script>
