<template>
  <div>
    <div class="share-interface">
      <!-- Button trigger SHARE PROSPECTLIST modal -->
      <button hidden type="button" class="btn btn-primary" data-toggle="modal" data-target="#shareModal">
        Launch Share modal
      </button>

      <!-- Modal -->
      <div class="modal fade" id="shareModal" role="dialog" aria-labelledby="shareModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content shadow-lg">

            <div class="modal-header">
              <h2 v-if="interface_state==='emailer'" class="modal-title" id="shareModalLabel">
                Share Via Email <span>or <a class="text-primary" href="javascript:" @click="updateInterfaceState('messenger')">Message</a></span>
              </h2>
              <h2 v-else-if="interface_state==='messenger'" class="modal-title" id="shareModalLabel">
                Share Via Message <span>or <a class="text-primary" href="javascript:" @click="updateInterfaceState('emailer')">Email</a></span>
              </h2>
              <h2 v-else class="modal-title d-flex justify-content-center" id="shareModalLabel">
                How would you like to share?
              </h2>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="updateInterfaceState('default')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <div v-if="interface_state==='emailer' || interface_state==='messenger'">
                <div v-if="interface_state==='emailer'">
                  <div class="form-group">
                    <h4><label for="exampleFormControlInput1">Email address</label></h4>
                    <input type="email" class="form-control" id="exampleFormControlInput1" :value="currentEmail" @input="handleEmailChange">
                  </div>
                </div>

                <div v-else-if="interface_state==='messenger'">
                  <div class="start-conversation form-group">
                    <start-conversation
                      screen="coaches"
                      :onCoachChangeHandler="onCoachChangeHandler"
                    />
                  </div>
                </div>

                <div class="form-group">
                  <h4><label for="exampleFormControlTextarea1">Message</label></h4>
                  <textarea class="form-control" id="exampleFormControlTextarea1" rows="5" maxlength="240" :value="currentMessage" @input="handleMessageChange"></textarea>
                </div>

                <div class="mt-4 mb-3">
                  <h5 align="center">Prospect Lists You Are Sharing</h5>
                  <div class="d-flex justify-content-around flex-wrap">
                    <div v-for="(prospectlist, index) in prospectlists"
                      :key="index"
                      :prospectlist="prospectlist"
                    >
                      <span v-text="prospectlist.name"></span>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else class="d-flex justify-content-center mt-4 mb-4">
                <h3>
                  <a type="button" class="btn btn-primary pt-3 pb-3" @click="updateInterfaceState('emailer')">Email</a>
                  <a type="button" class="btn btn-secondary pt-3 pb-3" @click="updateInterfaceState('messenger')">Message</a>
                </h3>
              </div>
            </div>

            <div class="modal-footer d-flex justify-content-center">
              <h5>
                <a type="button" class="btn btn-link" data-dismiss="modal" @click="updateInterfaceState('default')">Cancel</a>
                <a v-if="interface_state==='emailer'" type="button" class="btn btn-primary" @click="handleEmailProspectlist()">Send</a>
                <a v-else-if="interface_state==='messenger'" type="button" class="btn btn-primary" @click="createMessage()">Send</a>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {  },

    props: {
      prospectlists: {type: Object, default() {return {}}, required: false},
      organization_name: {type: String, default: null, required: false},
      coach_name: {type: String, default: null, required: false},
    },

    data() {
      return {
        screen: 'prospectlists',
        currentMessage: '',
        currentEmail: '',
        selectedCoaches: [],
        interface_state: 'default', // options {default, emailer, messenger}
      }
    },

    methods: {
      onCoachChangeHandler(coach_id){
        this.selectedCoaches = [coach_id];
      },
      getSubjectText(){
        let prospectlist_str = ''
        let counter = 1
        let count = Object.keys(this.prospectlists).length
        let listLimit = 2

        // if count is less than/equal to listLimit, then display all prospectlist items
        // else only display the allowed prospectlist listLimit and show '...' for remaining items
        if(count <= listLimit){
          Object.keys(this.prospectlists).forEach((key) => {
            prospectlist_str += (count === 1 || counter === count) ? this.prospectlists[key].name : this.prospectlists[key].name+', '
            counter++
          })
        }
        else{
          Object.keys(this.prospectlists).forEach((key) => {
            if(counter <= listLimit){
              prospectlist_str += this.prospectlists[key].name+', '
            }
            else if(counter === listLimit+1){
              prospectlist_str += '...'
            }
            counter++
          })
        }
        let pluralize = (count > 1) ? 's' : '';
        return 'Coach '+this.coach_name+' Shared Their Prospect List'+pluralize+' - ['+prospectlist_str+']';
      },
      getHeaderText(mssgType){
        let linkType = (mssgType === 'email') ? 'button' : 'link';  // mssgType => {'email' or 'message'}
        let determiner = (Object.keys(this.prospectlists).length > 1) ? 'a' : 'the';
        return 'Coach '+this.coach_name+' from '+this.organization_name+' has sent you a list of prospects from their roster! Please click '+determiner+' '+linkType+' below to view the Prospect List.';
      },
      handleMessageChange(e){
        this.currentMessage = $(e.target).val();
      },
      handleEmailChange(e){
        this.currentEmail = $(e.target).val();
      },
      updateInterfaceState(state){
        this.resetInputs();
        this.interface_state = state; // options {default, emailer, messenger}
      },
      async handleEmailProspectlist(){
        if(this.currentEmail !== ''){
          if(this.currentMessage !== ''){
            const url = '/api/prospectlists/email';
            const body = {
              email: this.currentEmail,
              subject: this.getSubjectText(),
              prospectlists: JSON.stringify(this.prospectlists),
              header_text: this.getHeaderText('email'),
              custom_message: this.currentMessage,
            };

            // validation of email is handled in backend
            const response = await axios.post(url, body)
            .then(response => {
              $('#shareModal').modal('hide');
              toastr.success("Email queued for processing!");
              this.resetInputs();
            })
            .catch(error => {
              if(!error.response.data.errors){
                Swal.fire({
                  title: "Error!",
                  text: "There was an error. Please try again.",
                  type: "error"
                });
                console.log(error);
              }
              else if(error.response.data.errors.email){
                Swal.fire({
                  title: "Error! \n Invalid Email",
                  text: "Please provide a valid email address.",
                  type: "error"
                });
              }
            });
          }
          else{
            Swal.fire({
              title: "Error!",
              text: "Please provide a message.",
              type: "error"
            });
          }
        }
        else{
          Swal.fire({
            title: "Error! \ Missing Email",
            text: "Please provide an email address.",
            type: "error"
          });
        }
      },
      async createMessage() {
        if (this.selectedCoaches.length > 0) {
          // Makes sure the message isn't blank
          if (this.currentMessage != "") {
            let prospectlist_str = this.getHeaderText('message');
            Object.keys(this.prospectlists).forEach((key) => {
              const prospectlist = this.prospectlists[key];
              prospectlist_str += '\n\n'+prospectlist.name+': '+prospectlist.public_prospectlist_token.url;
            });

            let body = {};
            body = {
              message: this.currentMessage +'\n\n'+ prospectlist_str,
              user_ids: this.selectedCoaches,
            };

            // Pushes a new group, and changes to it, or pushes a new message to the group
            const call = await axios.post(
              "/api/messages/group-message?include=messages,users.profile",
              body
            );

            $('#shareModal').modal('hide');
            const msgStr = (body.user_ids.length > 1) ? 'Messages' : 'Message';
            toastr.success("", msgStr+" queued for processing!");
            this.resetInputs();
          } else {
            Swal.fire({
              title: "Error!",
              text: "Please provide a message.",
              type: "error"
            });
          }
        } else {
          Swal.fire({
            title: "Error!",
            text: "Please select a coach to message.",
            type: "error"
          });
        }
      },
      resetInputs(){
        this.currentMessage = '';
        this.currentEmail = '';
        this.interface_state = 'default';
        this.selectedCoaches = [];
      },
    },

    created() {

    },
  }
</script>

<style scoped>
  #shareModal .modal-content {
    padding-top: 50px;
    padding-bottom: 10px;
  }

  #shareModal .modal-header,
  #shareModal .modal-content,
  #shareModal .modal-dialog,
  #shareModal .modal-body,
  #shareModal .modal-footer {
    border: none;
    overflow: visible;
  }

  #shareModal .modal-header,
  #shareModal .modal-body,
  #shareModal .modal-footer
   {
    margin: 0 auto;
    width: 90%;
  }

  #shareModal .btn.btn-link {
    color: #A9A9A9 !important;
    font-size: 14px !important;
    font-weight: bold !important;
  }

  #shareModal .btn {
    padding-left: 65px;
    padding-right: 65px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  #shareModal .modal-body a,
  #shareModal .modal-footer a
  {
    color: #FFFFFF !important;
  }

  #shareModal .modal-body {
    padding-top: 20px;
    padding-bottom: 10px;
  }

  #shareModal .close {
    background: none;
    border: none;
    top: -22px;
    right: 8px;
    font-size: 3em;
    color: #FFFFFF;
    font-weight: normal !important;
  }

  #shareModalLabel span {
    font-size: .65em; 
    color: #A9A9A9 !important;
  }

  #shareModal .start-conversation {
    margin-bottom: 40px;
  }
</style>
