<template>
  <div class="form-control">
    <span class="filter-title">{{title}}</span>
    <el-input-number
      v-model="minVal" 
      controls-position="right" 
      @change="handleMinChange" 
      :min="min" 
      :max="max"
      size="mini"
      :disabled="minDisable"
    ></el-input-number>
    <span> - </span>
    <el-input-number
      v-model="maxVal" 
      controls-position="right" 
      @change="handleMaxChange" 
      :min="min" 
      :max="max"
      size="mini"
      :disabled="maxDisable"
    ></el-input-number>
  </div>
</template>

<script>
  export default {
    props: {
      min: {type: Number, default() {return 0}, required: false},
      max: {type: Number, default() {return 999}, required: false},
      title: {type: String, default: 'Range', required: false},
      onChange: {type: Function, default() {return null}, required: false},
    },

    data() {
      return {
        minVal: '',
        maxVal: '',
        minDisable: false,
        maxDisable: true,
      };
    },

    methods: {
      resetFilterInputs(){
        this.minVal = this.min;
        this.maxVal = this.max;
        this.maxDisable = true;
      },
      handleMinChange(value) {
        this.minVal = value;
        this.maxDisable = false;
        this.handleChange();
      },
      handleMaxChange(value) {
        this.maxVal = value;
        this.handleChange();
      },
      handleChange(){
        var minVal, maxVal, data;

        // make sure minVal has min value and maxVal has max value
        if(this.minVal <= this.maxVal){
          minVal = this.minVal;
          maxVal = this.maxVal;
        }
        else{
          minVal = this.maxVal;
          maxVal = this.minVal;
        }

        // if the selected values equal the default values, then set data to empty string, else set to range array
        data = [minVal, maxVal];

        this.onChange(data);
      },
    },
  };
</script>

<style scoped>
  .form-control {
    padding: 6px 15px;
    margin-bottom: 10px;
  }

  .filter-title {
    margin-right: 5px;
  }

  .el-input-number--small {
    width: 100px;
  }

  .el-input-number--mini {
    width: 90px;
  }
</style>
