<template>
  <organizations-table ref='organizations-table'></organizations-table>
</template>

<script>
import legacyCode from './utils'
import OrganizationsTable from './OrganizationsTable'
export default {
  name: 'OrganizationsScreen',
  components: { OrganizationsTable },
  props: {},
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
    legacyCode()
  },
  methods: {}
}
</script>

<style scoped>

</style>
