<template>
  <div class="container-fluid">
    <span
      id="roster-filter"
      class="form-inline"
      style="display: inline-block; float: right"
    >
      <button class="btn btn-primary waves-effect waves-light" v-if="filters[4].value" v-on:click="addPlayersToSelectedRoster()">Add Players to This Roster</button>
      <button class="btn waves-effect waves-light btn-danger reset-btn" v-if="filters.some(f => f.value !== '')" @click="resetFilters">Clear</button>

      <select
        id="player_position"
        v-model="filters[1].value"
        class="form-control custom-select"
        data-filter="position"
      >
        <option value="">Any Position</option>
        <option v-for="(position, code) in positions"
          :key="position"
          :label="position"
          :value="code"
        >
          {{ position }}
        </option>
      </select>

      <select
        v-if="isHighSchoolTable"
        id="grad_year_search"
        v-model="filters[2].value"
        class="form-control custom-select"
        style="padding-right: 25px"
        data-filter="grad_year"
      >
        <option value="">Any Class</option>
        <option
          v-for="(year, code) in user_class_grad_years"
          :key="year"
          :value="year"
        >{{ code }}</option>
      </select>
      <select
        v-else
        id="grad_year_search"
        v-model="filters[2].value"
        class="form-control custom-select"
        style="padding-right: 25px"
        data-filter="grad_year"
      >
        <option value="">Any Year</option>
        <option
          v-for="i in 8"
          :key="`year_${i}`"
          :value="year - 1 + i"
        >{{ year - 1 + i}}</option>
      </select>
      <select v-if="isHighSchoolTable"
        id="roster_level_search"
        class="theme-select form-control custom-select"
        v-model="filters[3].value"
        data-filter="roster_level"
      >
        <option value="">Any Level</option>
        <option
          v-for="{ id, name } in hsRosterLevels"
          :value="id"
          :key="name"
          class="theme-font"
        >{{ name }}</option>
        <option value="unranked">Unranked</option>
      </select>
      <select v-if="isClubTable"
        id="team_roster_level_search"
        class="theme-select form-control custom-select"
        v-model="filters[4].value"
        data-filter="team_roster_level"
      >
        <option value="">Any Level</option>
        <option
          v-for="{ id, name } in clubTeamRosterLevels"
          :value=id
          :key="name"
          class="theme-font"
        >{{ name }}</option>
        <option value=0>No Roster Assigned</option>
      </select>
      <input
        id="full_name"
        v-model="filters[0].value"
        class="form-control"
        placeholder="Search..."
        type="text"
        data-filter="full_name"
      />
    </span>

    <data-tables-server
      ref="table"
      :id="`${this.screen}-table`"
      :data="players"
      :filters="filters"
      :loading="loading"
      :current-page="currentPaginationPage"
      :pagination-props="{
        pageSizes: [10, 25, 50, 100],
        background: true,
        layout: 'prev, pager, next, sizes, ->, total'
      }"
      :table-props="{
        'row-key': 'id'
      }"
      :total="count"
      class="table"
      @query-change="handleQueryChange"
    >
      <el-table-column min-width="110" align-v="center" align="center" fixed="left">
        <template slot-scope="scope">
          <a :href="'/recruit/' + scope.row.id" target="_blank" class="d-flex justify-content-center">
            <user-avatar :user="scope.row" />
          </a>
        </template>
      </el-table-column>
      <el-table-column id="roster-player" align-v="center" label="Player" width="225" fixed="left">
        <template slot-scope="scope">
          <div>
            <a :href="'/recruit/' + scope.row.id" target="_blank">
              <h4 class="player-name">{{ scope.row.full_name }}</h4>
            </a>
            <div class="roster-city-state">
              <template v-if="!!scope.row.profile.data.basics.data.high_school">
                <!-- using v-html here because some schools have names that've been escaped (ie - O'Hanahan) -->
                <span v-html="scope.row.profile.data.basics.data.high_school"></span>,
                {{ scope.row.profile.data.basics.data.grad_year }}
                <br/>
              </template>
              <template v-if="!!scope.row.profile.data.basics.data.state">
                <span v-html="scope.row.profile.data.basics.data.city"></span>,
                {{ scope.row.profile.data.basics.data.state }}
              </template>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" align-v="center" min-width="100">
        <template slot-scope="scope" v-if="scope.row.has_slugger_science_profile">
          <img src="/build/images/SluggerScience.png" alt="" width="50" height="50">
        </template>
      </el-table-column>
      <el-table-column align="center" align-v="center" min-width="100" label="Positions">
        <template slot-scope="scope">
          {{ scope.row.profile.data.basics.data.positions_string }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        align-v="center"
        class="grad_year"
        label="Grad Year"
        prop="profile.data.basics.data.grad_year"
        sortable
        width="120"
      ></el-table-column>
      <el-table-column
        v-if="sport === 'football' || sport === 'baseball'"
        align="center"
        align-v="center"
        label="HT/WT"
        prop="profile.data.height_weight"
      >
        <template slot-scope="{ row: { profile: { data }} }">
          {{ getWeightAndHeightString(data) }}
        </template>
      </el-table-column>
        <el-table-column
          v-if="sport === 'softball' || sport === 'mens_soccer' || sport === 'womens_soccer'"
          align="center"
          align-v="center"
          label="HEIGHT"
          prop="profile.data.height"
        >
        <template slot-scope="{ row: { profile: { data }} }">
          {{ getWeightAndHeightString(data) }}
        </template>
      </el-table-column>
      <el-table-column v-if="isHighSchoolTable" align-v="center" align="center" label="Team Level" width="250">
        <template slot-scope="{ row }">
          <el-checkbox-group v-model="row.roster_level_ids" fill="#E7F816" size="mini" text-color="black">
            <el-checkbox-button v-for="level in roster_levels" :key="level.id" :label="level.id" :title="level.name"
              @change="checked => handleRosterLevelChange(row, row.profile.data, checked, level)"
              :ref="`${level.id}_${row.profile.data.id}_level_button`"
            >
              {{ level.shortcode }}
            </el-checkbox-button>
          </el-checkbox-group>
        </template>
      </el-table-column>
      <el-table-column v-if="isClubTable" align-v="center" align="center" label="Assigned Rosters" width="250">
        <template slot-scope="scope">
          {{ scope.row.profile.data.basics.data.team_roster_levels }}
        </template>
      </el-table-column>
      <el-table-column v-if="screen === 'prospects'" align-v="center" min-width="120" label="Status" sortable :sort-method="sortMethod">
        <template slot-scope="{ row }">
          <button
            type="button"
            data-toggle="dropdown"
            aria-expanded="false"
            class="btn dropdown-toggle waves-effect waves-light"
          >
            <span :style="{color: getStatusColor(row)}">{{ getStatusString(row) }}</span>
            <span data-html2canvas-ignore></span>
          </button>
          <div class="dropdown-menu status-dropdown">
            <span
              class="dropdown-item w-auto"
              @click="() => handleOfferChange(row)"
            >{{ row.offered ? 'Pull' : 'Extend'  }} Offer</span>
            <hr class="status-hr">
            <span v-for="status in statuses"
                  @click="() => handleStatusChange(row, status)"
                  :key="status"
                  class="dropdown-item w-auto"
            >{{ status }}</span>
            <hr class="status-hr">
            <span class="dropdown-item w-auto" @click="() => handleStatusChange(row, 'clear')">Clear Status</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="screen === 'prospects'" align-v="center" min-width="110" label="Interest" sortable :sort-method="(a,b) => sortMethod(a, b, true)">
        <template slot-scope="{ row }">
          <button type="button"
                  class="btn dropdown-toggle waves-effect waves-light"
                  data-toggle="dropdown"
                  aria-expanded="false"
          >
            <span :style="{color: getStatusColor(row, true)}">{{ getInterestString(row) }}</span>
            <span data-html2canvas-ignore></span>
          </button>
          <div class="dropdown-menu interest-dropdown">
            <span v-for="interest in ['Low', 'Medium', 'High', 'Clear']"
                  @click="() => handleInterestChange(row, interest)"
                  :key="interest"
                  class="dropdown-item w-auto"
            >{{ interest !== 'Clear' ? `Interest: ${interest}` : `${interest} Interest` }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="sport === 'baseball'" align-v="center" min-width="110" label="B/T" sortable :sort-method="(a,b) => sortMethod(a, b, true)">
        <template slot-scope="{ row }">
          {{ getBatThrowString(row) }}
        </template>
      </el-table-column>
      <el-table-column v-if="sport === 'baseball'" align-v="center" label="Verified">
        <template slot-scope="{ row }">
          <div v-if="row.rapsodo_verified"><img src="/build/images/rapsodo-logo.png" alt="" width="30" height="30"></div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        align-v="center"
        class="text-center"
        label="Progress"
        min-width="125"
      >
        <template slot-scope="scope">
          <div class="text-center player-progress">
            <div v-bind:class="[{ 'full-progress org-bg': (
          scope.row.profile &&
          scope.row.has_interviews &&
          scope.row.has_film
        ) }, 'inner-progress']">
              <active-linked-icon
                :status="scope.row.profile"
                :url="'/recruit/' + scope.row.id"
                target="_blank"
                icon-class="fa-user"
                title="Profile"></active-linked-icon>
              <active-linked-icon
                :status="scope.row.has_interviews"
                :url="'/recruit/' + scope.row.id + '#int'"
                target="_blank"
                icon-class="fa-info"
                title="Interview"></active-linked-icon>
              <active-linked-icon
                :status="scope.row.has_film"
                :url="'/recruit/' + scope.row.id + '#foot'"
                target="_blank"
                icon-class="fa-film"
                title="Videos"></active-linked-icon>
            </div>
          </div>
          <div class="mt-2" v-if="scope.row.thsca_showcase === 1">
            <p>THSCA Showcase Attended</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" align-v="center" label="ACTIONS" :class-name="`actions-col ${count === 1 ? 'single-entry-fix' : ''}`" min-width="180" fixed="right">
        <template v-slot="{ row }">
          <button aria-expanded="false" class="btn dropdown-toggle waves-effect waves-light" data-toggle="dropdown"
                  style="border-color: rgb(225,225,225);border-width: 1;border-radius:0;padding:7.5px 30px;font-family: KairosSans; color: black !important"
                  type="button">
            ACTIONS <span data-html2canvas-ignore><span class="caret"></span></span></button>
          <div class="dropdown-menu">
            <a :href="`/recruit/${row.id}`" target="_blank" class="dropdown-item">View</a>
            <a :href="`/messages?user=${row.id}`" class="dropdown-item">Message</a>
            <span v-if="(!isAcademyTable && !isHighSchoolTable && !isClubTable) || (screen === 'prospects' && !isHighSchoolTable) || (isAcademyTable && screen !== 'roster')"
                  @click="() => handleOfferChange(row)"
                  class="dropdown-item">
                  {{ row.offered ? 'Pull Offer' : 'Extend Offer' }}
            </span>

            <a v-if="downloadFlag === 'true'" :href="`/player-card/${row.id}?download`" class="dropdown-item">Download Player Card</a>
            <span :onclick="`window.deleteRecruitFromRoster(${row.id})`" class="dropdown-item">Remove Player</span>
            <span v-if="isClubTable" @click="removeFromRosterLevel(row.profile.data.id)" class="dropdown-item">Remove From Selected Roster</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="Message"
        align="center"
      >
        <template v-slot="{ row }">
          <input
            class="form-check-input"
            :id="screen+'prospectlist-selected-player-'+row.id"
            type="checkbox"
            :checked="isSelectedPlayer(row.id)"
            :name="screen+'prospectlist-selected-player-'+row.id"
            :value="row.id"
            @click="handleSelectedPlayers"
          />
        </template>
      </el-table-column>
    </data-tables-server>
    <div class="modal fade" id="players-to-add-roster-level" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">
          <div class="modal-content">
              <div class="modal-header">
                <h3 class="modal-title">Players to Add</h3>
              </div>
              <div class="modal-body"> <!-- style="max-height: 80vh; overflow: scroll;"> -->
                <add-roster-level-player v-if="this.displayAddRosterLevelPlayers" :teamrosterlevelid="this.filters[4].value" :players="this.rosterEligiblePlayers" />
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'

import DataTablesServer from '@components/ui/DataTablesServer'

import {buildQueryForVueDataTable} from '@lib/vue-data-tables-util'

import AddRosterLevelPlayer from '../../../js/components/AddRosterLevelPlayer.vue'

import UserAvatar from '@components/UserAvatar';


let timeout

export const ActiveLinkedIcon = {
  name: 'ActiveLinkedIcon',
  props: ['url', 'status', 'title', 'iconClass'],
  template: `
    <a :href="url">
    <div class="round m-2"
         :class="{'org-bg org-text': status}"
         :data-original-title="title"
         data-toggle="tooltip"
    >
      <i class="fas" :class="[iconClass]"></i>
      <slot></slot>
    </div>
    </a>`
}

export default {
  components: {DataTablesServer, ActiveLinkedIcon, AddRosterLevelPlayer, UserAvatar},
  props: {
    screen: {type: String, default: null, required: false},
    sport: {type: String, default: 'football', required: false},
    downloadFlag: String
  },
  data() {
    return {
      url: '/api/get-'+ window.location?.pathname.substring(1),
      isHighSchoolTable: this.$parent.isHighSchool,
      isAcademyTable: this.$parent.isAcademy,
      isClubTable: this.$parent.isClub,
      filters: [
        { search_prop: 'full_name', value: '', },
        { search_prop: 'position', value: '', },
        { search_prop: 'grad_year', value: '', },
        { search_prop: 'roster_level', value: '',},
        { search_prop: 'team_roster_level', value: '', }
      ],
      currentPaginationPage: JSON.parse(localStorage.getItem('rosterPage')) ? JSON.parse(localStorage.getItem('rosterPage')) : 1,
      year: new Date().getFullYear(),
      loading: false,
      apiData: null,
      previousQuery: null,
      positions: window.signingDayConstants['user_positions'],
      roster_levels: window.signingDayConstants['roster_levels'],
      user_class_grad_years:
        window.signingDayConstants['user_class_grad_years'],
      selected: [],
      statusColorMap: {
        offered: '#000', committed: '#18ba5b', out: '#ec4d4d', 'top 5': '#49baf9',
        high: '#18ba5b', medium: '#d7d256', low: '#ec4d4d',
      },
      hsRosterLevels: window.signingDayConstants['roster_levels'],
      clubTeamRosterLevels: window.signingDayConstants['team_roster_levels'],
      displayAddRosterLevelPlayers: false,
      rosterEligiblePlayers: null,
    }
  },
  computed: {
    players: (v) => _.get(v, 'apiData.data', []),
    count: (v) => _.get(v, 'apiData.meta.pagination.total', 0),
    statuses: (v) => {
      const allStatuses = ['Committed', 'Out', 'Top 10', 'Top 5'];

      if (['baseball'].includes(v.sport)) {
        allStatuses.push('Draft Eligible');
      }

      return allStatuses
    }
  },
  updated() {
    this.currentPaginationPage = JSON.parse(localStorage.getItem('rosterPage'));
  },
  methods: {
    async handleRosterLevelChange(player, profile, checked, level) {
      await this.confirmRosterLevelChange(checked, level, player).then(async ({value}) => {
        if (value) {
          const { id: profile_id, basics } = profile
          const { roster_level_ids: levelIds } = player
          const positionShortcodes = basics.data.positions_string.split(',')
          const res = await axios.post(`/api/profile/${profile_id}/update-roster-levels`, {
            roster_level_ids: levelIds || [], positions: positionShortcodes, detach: !checked
          })
          Swal.fire('Success', 'Roster level updated successfully!', 'success')
        }
        await this.loadData(this.previousQuery, true)
      })
    },
    async handleFilterChange(event) {
      const { params, currentTarget } = event
      const filterKey = $(currentTarget).attr('id')

      switch (filterKey) {
        case 'player_position': this.filters[1].value = params.data.id; break
        case 'grad_year_search': this.filters[2].value = params.data.id; break
        case 'roster_level_search': this.filters[3].value = params.data.id; break
        case 'team_roster_level_search': this.filters[4].value = params.data.id; break
      }
    },
    async handleOfferChange({ id, profile, offered }) {
      const codes = profile.data.positions.data.map(({ shortcode: c }) => c).join(',')
      await window.updateOffer(id, codes, offered)
      await this.loadData(this.previousQuery, true)
    },
    async handleInterestChange({ id }, type) {
      switch (type) {
        case 'Clear':
          await window.clearInterest(id); break
        default:
          await window.updateInterest(id, type); break
      }
      await this.loadData(this.previousQuery, true)
    },
    async handleStatusChange({ id }, type) {
      switch (type) {
        case 'clear':
          await window.clearStatus(id); break
        default:
          await window.updateStatus(id, type); break
      }
      await this.loadData(this.previousQuery, true)
    },
    handleQueryChange(query) {
      clearTimeout(timeout);
      timeout = setTimeout(() => this.loadData(query), 250);
    },
    async loadData(query, silentReload = false) {
      localStorage.setItem('rosterPage', JSON.stringify(query.page));

      // reset query-page to 1 if there is a change to any of the basic search fields
      if( (this.previousQuery !== null) && (!_.isEqual(this.previousQuery.filters, query.filters)) ){
        query.page = 1
        this.resetCurrentPage(query.page)
      }

      this.previousQuery = _.cloneDeep(query)
      this.loading = !silentReload
      const builder = buildQueryForVueDataTable(query)
      const url = `${this.url}?${builder.build()}`

      const response = await axios.get(url)
      this.apiData = response.data

      this.loading = false
    },
    handleSelectedPlayers(event){
      const target = $(event.target);
      const isChecked = target.is(':checked');
      const playerID = JSON.parse(target.val());

      let selected = _.cloneDeep(this.selected)

      if(playerID > 0)
        if(isChecked){
          // add to selected
          selected.push(playerID)
        }
        else{
          // remove from selected
          var i = 0;
          while (i < selected.length) {
            if (selected[i] === playerID) {
              selected.splice(i, 1);
            } else {
              ++i;
            }
          }
        }

      this.selected = _.cloneDeep(selected)
    },
    isSelectedPlayer(playerID){
      return (this.selected.indexOf(playerID) > -1)
    },
    async confirmRosterLevelChange(checked, level, player) {
      const action = checked ? ['add','to'] : ['remove','from']
      const positions = player.profile.data.basics.data.positions_string
      const text = `Are you sure you want to ${action[0]} ${player.full_name} ${action[1]} the ${level.name} Depth Chart?
        <br><br> Positions to ${action[0]} player ${action[1]}: ${positions}
      `
      return await Swal.fire({
        title: 'Confirm Team Level Change',
        html: text,
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Yes!',
        reverseButtons: true,
        cancelButtonClass: 'btn btn-confirm',
        confirmButtonClass: 'btn btn-cancel',
      })
    },
    addPlayersToSelectedRoster() {
      $('#players-to-add-roster-level').modal();
      axios.get(`api/players-to-add/${this.filters[4].value}`).then((response) => { this.rosterEligiblePlayers = response.data});
      this.displayAddRosterLevelPlayers = true;
    },
    removeFromRosterLevel(profileID) {
      if (this.filters[4].value == '') {
        Swal.fire({
          title: 'Roster Level Not Selected',
          type: 'error',
          text: 'Please select a club roster level first.'
        })
      } else {
        Swal.fire({
            title: 'Are you sure you want to remove this player from ' + this.filters[4].name + ' ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, remove player!',
            cancelButtonText: 'No, cancel!',
            confirmButtonClass: 'btn btn-confirm mt-2',
            cancelButtonClass: 'btn btn-cancel ml-2 mt-2',
            reverseButtons: true
        }).then(result => {
            if (result.value) {
                this.loading = true
                axios.delete(`api/drop-player-from-roster/${this.filters[4].value}`, { data: { profile_id: profileID }}).then(
                  Swal.fire({
                    title: 'Removed!',
                    text: 'The player has been removed.',
                    type: 'success',
                    timer: 1700,
                  })
                )
                this.silentRefresh();
            } else {
                Swal.fire({
                    title: 'Canceled!',
                    text: 'You have canceled remove player.',
                    timer: 1700,
                })
            }
        })
      }
    },
    moveFilterToLegacyLocation() {
      $('#rosterSearchInfo').find('.col-lg-12').append($('#roster-filter'))
    },
    registerListeners() {
      $('body').on('select2:select', '#player_position, #grad_year_search, #roster_level_search, #team_roster_level_search', this.handleFilterChange)
    },
    getStatusString({ offered, status }) {
      return status === null ? offered ? 'Made Offer' : '...' : status
    },
    getWeightAndHeightString({ measurables: { data } }) {
      const { height, weight } = data
      const result = { h, w }
      result.h = (height === null || height.includes('null')) ? 'N/A' : height
      result.w = (weight === null || weight.includes('null')) ? 'N/A' : weight
      if (this.sport === 'football' || this.sport === 'baseball') {
        return h === 'N/A' && w === 'N/A' ? 'N/A' : `${result.h} / ${result.w}`
      } else {
        return `${result.h}`
      }
    },
    getBatThrowString({ profile: { data: { basics: { data: { sportBasics } } } } }) {
      const bats = sportBasics?.data?.bats ? sportBasics.data.bats.slice(0, 1)?.toUpperCase() : 'N/A '
      const throws = sportBasics?.data?.throws ? sportBasics.data.throws.slice(0, 1).toUpperCase() : ' N/A'
      return `${bats}/${throws}`;
    },
    getStatusSort(row, interest) {
      const value = interest ? this.getInterestString(row) : this.getStatusString(row)
      switch (value) {
        case 'Committed':
        case 'High':
          return 5
        case 'Top 5':
        case 'Medium':
          return 4
        case 'Top 10':
        case 'Draft Eligible':
        case 'Low':
          return 3
        case 'Made Offer': return 2
        case 'Out': return 1
        default: return 0
      }
    },
    sortMethod(a, b, interest = false) {
      return this.getStatusSort(a, interest) >= this.getStatusSort(b, interest) ? 1 : -1
    },
    getStatusColor(row, interest) {
      const key = interest ? this.getInterestString(row) : this.getStatusString(row)
      return this.statusColorMap[key.toLowerCase()] || '#000'
    },
    getInterestString({ interest }) {
      return interest === null ? '...' : interest
    },
    getNikeRating({ profile }) {
      if (profile.data && profile.data.nike_football_rating !== undefined)
        return profile.data.nike_football_rating
      return '-'
    },
    get40Dash({ profile }) {
      if (profile.data && profile.data['forty_yard_dash'] !== undefined)
        return profile.data['forty_yard_dash']
      return '-'
    },
    getBenchMax({ profile }) {
      if (profile.data && profile.data['bench'] !== undefined) {
        const split = profile.data.bench.split('x')
        return `${split[0]} lbs x ${split[1]}`
      }
      return '-'
    },
    resetFilters() {
      for (const filter in this.filters) {
        this.filters[filter].value = ''
      }
      this.refreshInputs()
      this.resetCurrentPage(1)
    },
    refreshInputs() {
      const keys = $('[data-filter]').map((i, el) => $(el).data('filter')).toArray()
      keys.map((key, i) => {
        const $input = $(`[data-filter="${key}"]`)
        const { value } = this.filters.filter(f => f.search_prop === key)[0]

        if($input.prop('localName') === 'select'){
          $input.val(value || "").select2()
        } else {
          $input.val(value || "")
        }
      });
    },
    async silentRefresh() {
      await this.loadData(this.previousQuery, true)
    },
    getIsRapsodoVerified(row) {
      const { data: files } = row?.profile?.data?.attribute_files
      const rapsodoFile = files?.filter(({ attribute_name: n }) => n === 'rapsodo_fastball_url')[0]
      return Boolean(rapsodoFile?.file_upload?.data?.url)
    },
    saveCookies() {
      Cookies.set('roster_table', JSON.stringify({filters: this.filters, selected: this.selected}), { expires: Infinity })
      Cookies.set('roster_screen', JSON.stringify({currentScreen: this.screen}), {expires: Infinity});
    },
    getOrResetCookies() {
      let { type } = performance.getEntriesByType('navigation')[0]
      const { cookie } = JSON.parse(Cookies.get('roster_table'))
      const { currentScreen } = JSON.parse(Cookies.get('roster_screen'))

      // this is to determine if we are on either the 'roster' or 'prospect' table
      // if we go from roster to prospect or viceversa, then set type to navigate
      if(currentScreen !== this.screen) {
        type = 'navigate'
      }

      switch (type) {
        case 'reload':
        case 'back_forward':
          if(cookie != null) return Object.keys(cookie).forEach(k => _.set(this, k, cookie[k])) && this.silentRefresh()
        case 'navigate':
          this.resetCurrentPage(1)
          return Cookies.expire('roster_table')
      }
    },
    resetCurrentPage(page) {
      Cookies.set('roster_screen', JSON.stringify({currentScreen: this.screen}), {expires: Infinity})
      localStorage.setItem('rosterPage', JSON.stringify(page))
      this.currentPaginationPage = page
      this.selected = []
    },
    renderProgress(value) {
      if (value === 100) return 'Completed'
      if(value != null) return `${value}%`
    },
    setHeightOfDropdown({ target }) {
      const row_count = $(target).closest('#'+this.screen+'-table table > tbody').children().length
      const status_dropdown = $(target).siblings('.status-dropdown')
      let row_height = $(target).closest('.el-table__row').height()-10

      // set height of dropdown depending on # of rows in table
      if(row_count <= 1) {
        row_height = row_height*1.0
      }
      else if(row_count == 2){
        row_height = row_height*1.35
      }
      else{
        row_height = row_height*1.5
      }
      status_dropdown.css({"maxHeight":row_height+"px"})
    },
    blurSelectedButton({ target }){
      $(target).removeClass("btn-secondary")
      this.isFocused = false
    },
    focusSelectedButton({ target }){
      // if the button is not focus, then give it focus and add button class
      // else, remove focus on button and remove button class
      if(!this.isFocused){
        $(target).addClass("btn-secondary")
        this.isFocused = true

        // if there is only one row,
        // then adjust position of status_dropdown to be within the table-row
        const row_count = $(target).closest('#'+this.screen+'-table table > tbody').children().length
        if(row_count <= 1){
          // adjust position of status_dropdown
          this.adjustDropdownPosition(target)

          // set scroll event listener:
          // if main_window is scrolled, then maintain position of status_dropdown
          $(window).scroll(() => { this.adjustDropdownPosition(target) });
        }
      }
      else{
        $(target).removeClass("btn-secondary")
        this.isFocused = false
      }
    },
    adjustDropdownPosition(target){
      setTimeout(()=>{
        const row_height = $(target).closest('.el-table__row').height()-10
        const status_dropdown = $(target).siblings('.status-dropdown')
        status_dropdown.css({"maxHeight":row_height+"px","transform":"translate3d(2px, 0px, 0px)"})
      }, 0)
    },
  },
  created() {
    window.onbeforeunload = this.saveCookies
    $(() => {
      this.moveFilterToLegacyLocation()
      this.registerListeners()
      this.getOrResetCookies()
      this.refreshInputs()
    })
  }
}
</script>


<style scoped>
.el-table__header .el-table-column--selection .el-checkbox::before {
  /* https://github.com/ElemeFE/element/issues/5314 */
  content: "Message";
  color: white;
  padding-right: 5px;
}
.status-hr {
  margin-top: 0.4rem;
  margin-bottom: 0;
}
.interest-dropdown > span.dropdown-item, .status-dropdown > span.dropdown-item {
  cursor: pointer;
}
.reset-btn {
  font-family: KairosSans,serif;
  text-transform: uppercase;
}
.el-checkbox-button__inner:hover {
  color: #E7F816 !important;
}
.el-checkbox-button.is-focus .el-checkbox-button__inner {
  border-color: #E7F816 !important;
}
.dropdown-menu {
  z-index: 2;
}
.status-dropdown {
  max-height: 300px;
  min-width: 188px;
  overflow: auto;
  margin-bottom: 2px;
}
.dropdown-row {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
.dropdown-row > p {
  margin: 0;
}
.row-label {
  font-size: 80%;
}
.row-value {
  font-size: 70%;
}
.placeholder:hover {
  cursor: pointer;
}
.hover-button {
  min-width: 50px;
}
</style>

<style>
  #prospects-table label.el-checkbox,
  #roster-table label.el-checkbox {
    margin-bottom: 0rem !important;
  }
</style>
