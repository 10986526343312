<template>
  <fragment>
    <InviteListDetailsHeader :key="listId" v-bind="{listId, name, summary}" />
    <InviteListDetailsTable v-bind="{players, params, pagination, getPlayers}" />
  </fragment>
</template>

<script>
import { computed } from "vue";
import { Fragment } from "vue-frag";
import InviteListDetailsTable from "@screens/myinvites/InviteListDetails/InviteListDetailsTable/InviteListDetailsTable";
import InviteListDetailsHeader from "@screens/myinvites/InviteListDetails/InviteListDetailsHeader/InviteListDetailsHeader";
export default {
  name: 'MyInvitesIndividualList',
  props: ['list'],
  components: {InviteListDetailsHeader, InviteListDetailsTable, Fragment},
  data() {
    return {
      name: this.list.name,
      summary: this.list.summary,
      listId: this.list.id,
      players: [],
      params: {
        page: 1,
        limit: 10,
        search: '',
        orderby: 'created_at',
        sort: 'desc',
        positions: [],
      },
      query: new URLSearchParams(window.location.search),
      pagination: {},
    }
  },
  provide() {
    return {
      setHeader: this.setHeader,
      players: computed(() => this.players),
      getPlayers: this.getPlayers,
      params: this.params,
      setParams: this.setParams,
    }
  },
  watch: {
    params: {
      handler(newObject) {
        const positionString = this.stringifyPositions(newObject.positions);
        const formattedParams = {...newObject, positions: positionString};
        this.getPlayers(formattedParams);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setHeader(name, summary) {
      this.name = name;
      this.summary = summary;
    },
    setParams(newParams) {
      this.params = newParams;
    },
    getPlayers(params = this.params) {
      const query = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      const controller = new AbortController();
      axios.get(`/api/player-invites/${this.listId}/players?${query}`, { signal: controller.signal })
        .then((response) => {
          this.players = response.data.data;
          this.pagination = response.data.meta;
        })
        .catch((error) => {
          if(!axios.isCancel(error)) {
            toastr.error('Failed to fetch players', 'Error');
          }
        });
    },
    stringifyPositions(positions) {
      return positions.reduce((string, currentValue) => {
        return string ? `${currentValue},${string}` : currentValue;
      }, '');
    }
  },
}
</script>
