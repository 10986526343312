<template>
  <div id="verificationDetail" v-if="this.loading" class="row">
    <div class="col-12 col-md-7 pr-0 pl-0 video-container" v-if="video || photo">
      <verification-detail-video v-if="this.video" v-show="this.showVideo" :video="this.video"/>
      <div v-if="this.photo" class="photoBox" v-show="this.showPhoto" :style="`background: url(${this.photo}); background-size: cover !important; background-position: center !important; background-repeat: no-repeat !important;height:100%;width:100%;`">
        <div id="lightGallery">
          <a :href="this.photo">
            <img :src="this.photo" alt="" class="d-none">
          </a>
        </div>
        <button class="org-bg btn waves-effect waves-light full-screen-switch" @click="makeImageFullScreen()">
          <i class="mdi mdi-crop-free noti-icon"></i>
        </button>
      </div>
      <div class="media-switchers" v-if="this.video && this.photo">
        <button class="org-bg btn waves-effect waves-light pull-right" @click="setShowVideo(true)" :disabled="this.showVideo">Video</button>
        <button class="btn-danger btn waves-effect waves-light pull-right" @click="setShowPhoto(true)" :disabled="this.showPhoto">Photo</button>
      </div>
    </div>
    <div class="col-12 col-md-5 info-container verification-card">
      <h3>{{measurement.user.data.full_name}} - {{measurement.measurement.data.name}}</h3>
      <h2 class="gray-text measurement-value" v-text="measurement[measurement.measurement.data.type.input_type + '_display']"></h2>
      <h3 class="gray-text" v-text="'Positions - ' + getPlayerPositions()"></h3>
      <h3 class="gray-text" v-text="'Weight - ' + measurement.user.data.profile.data.measurables.data.weight"></h3>
      <h3 class="gray-text" v-text="'Age - ' + measurement.user.data.profile.data.class_name"></h3>
      <div class="col-12 pl-0 options">
        <i class="fa fa-check confirm" @click="confirmVerification()"></i>
        <i class="fa fa-close deny" @click="denyVerification()"></i>
      </div>
    </div>
    <div class="col-12 col-md-7 pr-0 verification-outer" v-if="! video && ! photo">
      <div class="col-12 data-content player-verifications verification-card no-content" style="margin-top: 0px; height: 100%;">
        <div class="row ml-0 mr-0">
            <div class="verification-title col-12">
              <h3>Player's other verifications</h3>
            </div>
            <div class="col-12">
              <player-verifications :player_id="measurement.user.data.id" :measurement_id="measurement.id" :max_slides="2" v-on:update-measurement="updateMeasurement"/>
            </div>
        </div>
      </div>
    </div>
    <div class="col-12 reason-box verification-card">
      <div>
        <h3>{{this.reasonTitle}} player verification</h3>
        <textarea name="reason" class="summernote col-12" v-model="this.reason"></textarea>
        <button class="org-bg btn waves-effect waves-light pull-right" @click="sendUserMeasurement((reasonTitle == 'Approve' ? 'approve' : 'decline' ))">Submit</button>
        <button class="btn-danger btn waves-effect waves-light pull-right" @click="closeReasonBox()">Cancel</button>
      </div>
    </div>
    <div class="col-12 data-content player-verifications" style="background: #fff; padding: 36px;" v-if="photo || video">
      <div class="row ml-0 mr-0">
          <div class="col-12">
            <div class="verification-title">
              <h3>Player's other verifications</h3>
            </div>
            <player-verifications :player_id="measurement.user.data.id" :measurement_id="measurement.id" v-on:update-measurement="updateMeasurement"/>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
    import axios from 'axios'
    import VerificationDetailVideo from './VerificationDetailVideo'

    export default {

        name: 'verification-detail',
        props: ['measurement_id'],

        data: function() {
          return {
            loading: false,
            measurement: false,
            video: false,
            photo: false,
            verifications: false,
            reasonTitle: '',
            showVideo: true,
            showPhoto: false,
            showHealth: false,
            reason: '',
            reasonSent: true,
          }
        },

        mounted() {
          this.getMeasurementData()
        },

        methods: {
          getMeasurementData(id = this.measurement_id) {
            axios.get(`/api/pending-measurements?id=${id}&include=measurement,video,photo,user`).then((response) => {
              this.measurement = response.data.data
              this.video = this.measurement.video != null ? this.measurement.video.data : false
              this.photo = this.getMeasurementImage(this.measurement)
              this.showVideo = this.video ? true : false

              if (! this.showVideo) {
                this.showPhoto = this.photo ? true : false
              } else {
                this.showPhoto = false
              }

            }).then(() => {
              this.loading = true
              this.setRecentlyViewed()
            }).then(() => {
              if (this.photo) {
                this.loadImage()
              }

              this.loadSummerNote()
            }).catch(error => {
              console.log(error)
            })
          },

          getPlayerPositions() {
            let positions = this.measurement.user.data.profile.data.positions.data
            return positions.length > 0 ? positions.map(p => p.shortcode).join(', ') : '-'
          },

          getMeasurementImage(measurement) {
            if(measurement.photo != null) {
              return measurement.photo.data.url != null 
                ? measurement.photo.data.url
                : measurement.photo.data.thumbnail_path
            }
            return false;
          },

          setRecentlyViewed() {
            axios.post(`/api/measurement/recently-viewed`, {
              id: this.measurement_id
            }).catch(error => {
              console.log(error)
            })
          },

          setShowVideo(value = true) {
            this.showVideo = value
            this.showPhoto = ! value
          },

          setShowPhoto(value = true) {
            this.showPhoto = value
            this.showVideo = ! value
          },

          loadSummerNote() {
            $('.summernote').summernote({
              placeholder: 'Optional Reason...',
              toolbar: false
            })
          },

          loadImage() {
            let self = this
            let $lightGallery = $('#lightGallery').lightGallery()
            $lightGallery.on('onCloseAfter.lg', function (event, index, fromTouch, fromThumb) {
              $('#modal-component').modal()
            }) 
          },

          makeImageFullScreen() {
            $('#modal-component').modal('hide')
            $('#verificationDetail #lightGallery a').click()
          },

          updateMeasurement(id) {
            this.videoLoaded = false
            this.getMeasurementData(id)
          },

          confirmVerification() {
            this.reasonTitle = 'Approve'
            $('.reason-box').addClass('active')
          },

          denyVerification() {
            this.reasonTitle = 'Deny'
            $('.reason-box').addClass('active')
          },

          sendUserMeasurement(action, message) {
            axios.put(`/api/measurement-user/${this.measurement.id}`, {
              action: action,
              reason: this.reason
            }).then((response) => {
              $('#modal-component').modal('hide')
              let message = this.measurement.user.data.full_name + "'s verification has been " + action + 'd'
              toastr.success(message, 'Verification Updated');
              $('#organization-verification-table').DataTable().ajax.reload()
            }).catch((error) => {
              console.log(error)
            })
          },

          closeReasonBox() {
            this.reasonTitle = 'Deny'
            $('.reason-box').removeClass('active')
          }
        }
    }
</script>
