export default class PlayerToolKit {
  playerMapping = {};
  positionMap = {};

  constructor () {
    this.playerMapping = {
      graduationyear: {
        validate: (data, required) => {
          // check that the string has exactly 4 numbers
          if(!(/^\d{4}$/.exec(data))) var eMessage = "Must be exactly four digits.";
          return eMessage;
        },
        fieldMap: (target) => ( (target.indexOf("grad") > -1) ),
      },
      jersey: {
        validate: (data, required) => {
          // check that data has a most 4 numbers
          if(!(/^[0-9]\d{0,3}/.exec(data))) var eMessage = "Please provide a valid jersey. Can be a maximum of four digits.";
          return eMessage;
        },
        fieldMap: (target) => ( (target.indexOf("js") > -1) || (target.indexOf("je") > -1) || (target.indexOf("jers") > -1) || (target.indexOf("jersey") > -1) ),
      },
      firstname: {
        validate: (data, required) => {
          // check that data has at least one char
          if(!(/^[a-zA-Z][a-zA-Z]{0}/.exec(data))) var eMessage = "First Must be at least one character long.";
          return eMessage;
        },
        fieldMap: (target) => ( ((target.indexOf("name") > -1) || (target.indexOf("nm") > -1)) && ((target.indexOf("first") > -1) || (target.indexOf("f") > -1)) ),
      },
      lastname: {
        validate: (data, required) => {
          // check that data has at least one char
          if(!(/^[a-zA-Z][a-zA-Z]{0}/.exec(data))) var eMessage = "Last Must be at least one character long.";
          return eMessage;
        },
        fieldMap: (target) => ( ((target.indexOf("name") > -1) || (target.indexOf("nm") > -1)) && ((target.indexOf("last") > -1) || (target.indexOf("l") > -1)) ),
      },
      highschool: {
        validate: (data, required) => {
          // check that data has at least two chars
          if(!(/^[a-zA-Z][a-zA-Z]{1}/.exec(data))) var eMessage = "Must be at least two characters long.";
          return eMessage;
        },
        fieldMap: (target) => ( (target.indexOf("highschool") > -1) || (target.indexOf("hs") > -1) || (target.indexOf("high") > -1) || (target.indexOf("school") > -1) || (target.indexOf("hsc") > -1) ),
      },
      password: {
        validate: (data, required) => {
          // check that data has at least six chars and/or digits
          if(!(data.length >= 6)) var eMessage = "Must be at least six characters long.";
          return eMessage;
        },
        fieldMap: (target) => ( (target.indexOf("password") > -1) || (target.indexOf("pwd") > -1) || (target.indexOf("word") > -1) || (target.indexOf("pass") > -1) || (target.indexOf("paswd") > -1) || (target.indexOf("pswrd") > -1) || (target.indexOf("p/w") > -1) || (target.indexOf("secret") > -1) || (target.indexOf("sec") > -1) || (target.indexOf("scrt") > -1) ),
      },
      weight: {
        validate: (data, required) => {
          // check that data is greater than or equal to one
          if(!(parseFloat(data) >= 1)) var eMessage = "Please provide a value greater than or equal to 1.";
          return eMessage;
        },
        fieldMap: (target) => ( (target.indexOf("weight") > -1) || (target.indexOf("wt") > -1) || (target.indexOf("wgt") > -1) || (target.indexOf("pound") > -1) || (target.indexOf("lb") > -1) ),
      },
      height: {
        validate: (data, required) => {
          // check that data is greater than or equal to one
          var eMessage;

          // if data is required, check to make sure a value exists, if not then set eMessage
          if(required && ((data.feet === '') && (data.inch === ''))){
            eMessage = "Please provide the required height values";
          }
          // if either feet or inches has a value, then validate data
          else if((data.feet !== '') || (data.inch !== '')){
            // if feet is blank or not greater than or equal to one, then set eMessage
            if((data.feet === '') || !(parseInt(data.feet) >= 1)) eMessage = "Please provide a feet value greater than 1";
            // if inch is blank or not between 0 and 12, then set eMessage
            if((data.inch === '') || !((parseInt(data.inch) >= 0) && (parseInt(data.inch) < 12))) (typeof eMessage === 'undefined') ? eMessage = "Please provide an inch value between 0 and 12" : eMessage += " and an inch value between 0 and 12";

            if(typeof eMessage !== 'undefined') eMessage += ".";
          }

          return eMessage;
        },
        fieldMap: (target) => ( (target.indexOf("height") > -1) || (target.indexOf("ht") > -1) || (target.indexOf("hgt") > -1) ),
      },
      cellphone: {
        validate: (data, required) => {
          // check that the string has the cellphone requirements
          var eMessage;

          // if cellphone is required then validate cellphone
          if( (required) && ((data.areacode === '') && (data.exchangecode === '') && (data.extensioncode === '')) ){
            eMessage = "Please provide the required cellphone values.";
          }
          // else, if either areacode, exchangecode, or extensioncode has a value, then validate cellphone
          else if( ((data.areacode !== '') || (data.exchangecode !== '') || (data.extensioncode !== '')) ){
            // check if area has 3 digits, exchange has 3 digits, and extension has 4 digits
            const isAreacodeValid = ((/^\d{3}$/).exec(data.areacode)) ? true : false;
            const isExchangeCodeValid = ((/^\d{3}$/).exec(data.exchangecode)) ? true : false;
            const isExtensionCodeValid = ((/^\d{4}$/).exec(data.extensioncode)) ? true : false;

            // validate areacode
            if((data.areacode === '') || !isAreacodeValid) eMessage = "Please provide a valid areacode";
            // validate exchangecode
            if((data.exchangecode === '') || !isExchangeCodeValid) (typeof eMessage === 'undefined') ? eMessage = "Please provide a valid exchangecode" : eMessage += ", exchangecode";
            // validate extensioncode
            if((data.extensioncode === '') || !isExtensionCodeValid) (typeof eMessage === 'undefined') ? eMessage = "Please provide a valid extensioncode" : eMessage += ", extensioncode";

            if(typeof eMessage !== 'undefined') eMessage += ".";
          }

          return eMessage;
        },
        fieldMap:(target) => ( (target.indexOf("phone") > -1) || (target.indexOf("ph") > -1) || (target.indexOf("cell") > -1) || (target.indexOf("cp") > -1) ),
      },
      positions: {
        validate: null,
        fieldMap: (target) => ( (target.indexOf("position") > -1) || (target.indexOf("pos") > -1) || (target.indexOf("ps") > -1) ),
      },
      email: {
        validate: (playerData, emailValidationResponseHandler) => {
          const formData = new FormData();
          formData.append("players", JSON.stringify([playerData]));

          // set api endpoint for validating player login data
          const previewUrl = '/api/organizations/validate-player-membership';

          const successCallback = (response) => {
            if(emailValidationResponseHandler)
              emailValidationResponseHandler(response.data.playerData[0]);
          };

          axios
          .post(previewUrl, formData)
          .then(response => {
            successCallback(response);
          });
        },
        fieldMap: (target) => ( (target.indexOf("email") > -1) ),
      },
    };

    this.positionMap = {
      football: {
        'QB': (target) => ( (target === "qb") || ((target.indexOf("quarter") > -1) && (target.indexOf("back") > -1)) ),
        'RB': (target) => ( (target === "rb") || ((target.indexOf("running") > -1) && (target.indexOf("back") > -1)) ),
        'WR': (target) => ( (target === "wr") || ((target.indexOf("wide") > -1) && (target.indexOf("receiver") > -1)) ),
        'SR': (target) => ( (target === "sr") || ((target.indexOf("slot") > -1) && (target.indexOf("receiver") > -1)) ),
        'TE': (target) => ( (target === "te") || ((target.indexOf("tignt") > -1) && (target.indexOf("end") > -1)) ),
        'OT': (target) => ( (target === "ot") || ((target.indexOf("offensive") > -1) && (target.indexOf("tackle") > -1)) ),
        'G': (target) => ( (target === "g") || (target.indexOf("guard") > -1) ),
        'C': (target) => ( (target === "c") || (target.indexOf("center") > -1) ),
        'DT': (target) => ( (target === "dt") || ((target.indexOf("defensive") > -1) && (target.indexOf("tackle") > -1)) ),
        'DE': (target) => ( (target === "de") || ((target.indexOf("defensive") > -1) && (target.indexOf("end") > -1)) ),
        'ILB': (target) => ( (target === "ilb") || (target === 'mlb') ||  ((target.indexOf("inside") > -1) && (target.indexOf("linebacker") > -1)) ),
        'OLB': (target) => ( (target === "olb") || ((target.indexOf("outside") > -1) && (target.indexOf("linebacker") > -1)) ),
        'CB': (target) => ( (target === "cb") || ((target.indexOf("corner") > -1) && (target.indexOf("back") > -1)) ),
        'FS': (target) => ( (target === "fs") || ((target.indexOf("free") > -1) && (target.indexOf("safety") > -1)) ),
        'SS': (target) => ( (target === "ss") || ((target.indexOf("strong") > -1) && (target.indexOf("safety") > -1)) ),
        'P': (target) => ( (target === "p") || (target.indexOf("punt") > -1) ),
        'K': (target) => ( (target === "k") || (target.indexOf("kick") > -1) ),
      },

      baseball: {
        'P': (target) => ( (target === "p") || (target.indexOf("pitch") > -1) ),
        'H': (target) => ( (target === "b") || (target.indexOf("bat") > -1) ),
        'C': (target) => ( (target === "c") || (target.indexOf("catch") > -1) ),
        '1B': (target) => ( (target === "1b") || ( ((target.indexOf("first") > -1) || (target.indexOf("1st") > -1) || (target.indexOf("1") > -1)) && (target.indexOf("base") > -1)) ),
        '2B': (target) => ( (target === "2b") || ( ((target.indexOf("second") > -1) || (target.indexOf("2nd") > -1) || (target.indexOf("2") > -1)) && (target.indexOf("base") > -1)) ),
        '3B': (target) => ( (target === "3b") || ( ((target.indexOf("third") > -1) || (target.indexOf("3rd") > -1) || (target.indexOf("3") > -1)) && (target.indexOf("base") > -1)) ),
        'SS': (target) => ( (target === "ss") || ( (target.indexOf("short") > -1) &&  (target.indexOf("stop") > -1)) ),
        'LF': (target) => ( (target === "lf") || ( ((target.indexOf("left") > -1) || (target.indexOf("lft") > -1) || (target.indexOf("lt") > -1)) && (target.indexOf("field") > -1)) ),
        'CF': (target) => ( (target === "cf") || ( ((target.indexOf("center") > -1) || (target.indexOf("ctr") > -1) || (target.indexOf("ct") > -1)) && (target.indexOf("field") > -1)) ),
        'RF': (target) => ( (target === "rf") || ( ((target.indexOf("right") > -1) || (target.indexOf("rgt") > -1) || (target.indexOf("rt") > -1)) && (target.indexOf("field") > -1)) ),
      },

      softball: {
        'P': (target) => ( (target === "p") || (target.indexOf("pitch") > -1) ),
        'H': (target) => ( (target === "b") || (target.indexOf("bat") > -1)),
        'C': (target) => ( (target === "c") || (target.indexOf("catch") > -1) ),
        '1B': (target) => ( (target === "1b") || ( ((target.indexOf("first") > -1) || (target.indexOf("1st") > -1) || (target.indexOf("1") > -1)) && (target.indexOf("base") > -1)) ),
        '2B': (target) => ( (target === "2b") || ( ((target.indexOf("second") > -1) || (target.indexOf("2nd") > -1) || (target.indexOf("2") > -1)) && (target.indexOf("base") > -1)) ),
        '3B': (target) => ( (target === "3b") || ( ((target.indexOf("third") > -1) || (target.indexOf("3rd") > -1) || (target.indexOf("3") > -1)) && (target.indexOf("base") > -1)) ),
        'SS': (target) => ( (target === "ss") || ( (target.indexOf("short") > -1) &&  (target.indexOf("stop") > -1)) ),
        'LF': (target) => ( (target === "lf") || ( ((target.indexOf("left") > -1) || (target.indexOf("lft") > -1) || (target.indexOf("lt") > -1)) && (target.indexOf("field") > -1)) ),
        'CF': (target) => ( (target === "cf") || ( ((target.indexOf("center") > -1) || (target.indexOf("ctr") > -1) || (target.indexOf("ct") > -1)) && (target.indexOf("field") > -1)) ),
        'RF': (target) => ( (target === "rf") || ( ((target.indexOf("right") > -1) || (target.indexOf("rgt") > -1) || (target.indexOf("rt") > -1)) && (target.indexOf("field") > -1)) ),
      },

      mens_soccer: {
        'GK': (target) => ( (target == "gk") || (target.indexOf("goalkeeper") > -1) || ((target.indexOf("goal") > -1) && (target.indexOf("keeper") > -1))),
        'RB': (target) => ( (target == "rb") || ((target.indexOf("right") > -1) && (target.indexOf("back") > -1)) ),
        'LB': (target) => ( (target == "lb") || ((target.indexOf("left") > -1) && (target.indexOf("back") > -1)) ),
        'CB': (target) => ( (target == "cb") || ((target.indexOf("center") > -1) && (target.indexOf("back") > -1)) ),
        'DM': (target) => ( (target == "dm") || ((target.indexOf("defensive") > -1) && (target.indexOf("midfielder") > -1)) ),
        'CM': (target) => ( (target == "cm") || ((target.indexOf("central") > -1) && (target.indexOf("midfielder") > -1)) ),
        'AM': (target) => ( (target == "am") || ((target.indexOf("attacking") > -1) && (target.indexOf("midfielder") > -1)) ),
        'RW': (target) => ( (target == "rw") || ((target.indexOf("right") > -1) && (target.indexOf("wing") > -1)) ),
        'LW': (target) => ( (target == "lw") || ((target.indexOf("left") > -1) && (target.indexOf("wing") > -1)) ),
        'ST': (target) => ( (target == "st") || (target.indexOf("striker") > -1) ),
        'RM': (target) => ( (target == "rm") || ((target.indexOf("right") > -1) && (target.indexOf("mid") > -1)) ),
        'LM': (target) => ( (target == "lm") || ((target.indexOf("left") > -1) && (target.indexOf("mid") > -1)) ),
      },

      womens_soccer: {
        'GK': (target) => ( (target == "gk") || (target.indexOf("goalkeeper") > -1) || ((target.indexOf("goal") > -1) && (target.indexOf("keeper") > -1))),
        'RB': (target) => ( (target == "rb") || ((target.indexOf("right") > -1) && (target.indexOf("back") > -1)) ),
        'LB': (target) => ( (target == "lb") || ((target.indexOf("left") > -1) && (target.indexOf("back") > -1)) ),
        'CB': (target) => ( (target == "cb") || ((target.indexOf("center") > -1) && (target.indexOf("back") > -1)) ),
        'DM': (target) => ( (target == "dm") || ((target.indexOf("defensive") > -1) && (target.indexOf("midfielder") > -1)) ),
        'CM': (target) => ( (target == "cm") || ((target.indexOf("central") > -1) && (target.indexOf("midfielder") > -1)) ),
        'AM': (target) => ( (target == "am") || ((target.indexOf("attacking") > -1) && (target.indexOf("midfielder") > -1)) ),
        'RW': (target) => ( (target == "rw") || ((target.indexOf("right") > -1) && (target.indexOf("wing") > -1)) ),
        'LW': (target) => ( (target == "lw") || ((target.indexOf("left") > -1) && (target.indexOf("wing") > -1)) ),
        'ST': (target) => ( (target == "st") || (target.indexOf("striker") > -1) ),
        'RM': (target) => ( (target == "rm") || ((target.indexOf("right") > -1) && (target.indexOf("mid") > -1)) ),
        'LM': (target) => ( (target == "lm") || ((target.indexOf("left") > -1) && (target.indexOf("mid") > -1)) ),
      },
    };
  }

  /**
   * isValidHandler definition
   * @param {string} field
   * @param {string/object} data
   * @param {boolean} required
   * @param {function} isValid
   * @returns {string} error
   */
  isValidHandler (field, data, required, isValid) {
    const isDefined = (typeof data !== 'undefined') && (data !== '') && (data !== null);
    var error;

    // if data is required and value does not exist,then set error
    if(required && !isDefined){
      error = "The "+field+" field is required.";
    }
    // else if data is required and/or a value does exist, check to make sure the data is valid
    else if((required && isDefined) || isDefined){
      // check data against isValid, if invalid then set error
      error = isValid(data, required);
    }

    return error;
  }

  /**
   * isMatchHandler definition
   * @param {string} target
   * @param {function} isMatch
   * @returns {boolean} result
   */
  isMatchHandler (target, isMatch) {
    const isDefined = (typeof target !== 'undefined') && (target !== '') && (target !== null);
    var result = false;

    // if target isDefined, is a match, and hasn't already been assigned
    if (isDefined && isMatch(target.toLowerCase())) {
      result = true;
    }

    return result;
  }

  getFieldMap () {
    return this.playerMapping;
  }

  getPositionMap(sportType) {
    return this.positionMap[sportType];
  }

  getPlayerValidationMap () {
    return this.playerMapping;
  }
}
