<template>
  <span>
      <a class="nav-link dropdown-toggle waves-effect waves-light nav-user" data-toggle="dropdown" href="#" role="button"
         aria-haspopup="false" aria-expanded="false">
          <img src="" alt="selected-player" class="rounded-circle" id="playerActions-Image" style="border: 0px solid #777; box-shadow: 1px 1px 5px 1px #ddd;">
      </a>
      <div class="dropdown-menu dropdown-menu-right profile-dropdown " aria-labelledby="Preview">
          <div class="dropdown-item noti-title">
              <h5 class="text-overflow"><small id="playerActions-Name"></small> </h5>
          </div>

          <a href="#" class="dropdown-item notify-item" id="playerActions-ViewNotes">
              <i class="mdi mdi-settings"></i> <span>View Notes</span>
          </a>

          <a href="#" class="dropdown-item notify-item" id="playerActions-ViewProfile">
              <i class="mdi mdi-settings"></i> <span>View Profile</span>
          </a>

          <a href="#" class="dropdown-item notify-item" id="playerActions-something">
              <i class="mdi mdi-settings"></i> <span>Something Else</span>
          </a>
      </div>
  </span>
</template>

<script>
    import axios from 'axios'

    export default {

        name: 'PlayerActions',

        mounted: function() {
        },

        methods: {
        }
    }
</script>
