<template>
<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.1667 1.49902L14.7022 5.03456L6.18837 13.5484H2.65283V10.0129L11.1667 1.49902ZM11.1667 2.91324L9.98231 4.09764L12.1036 6.21896L13.288 5.03456L11.1667 2.91324ZM11.3965 6.92607L9.2752 4.80475L3.65283 10.4271V12.5484H5.77415L11.3965 6.92607Z"/>
</svg>
</template>

<script>
export default {
  name: 'PencilIcon',
};
</script>
