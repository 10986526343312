<template>
  <fragment>
    <UsersTable v-bind="{users, loading}" />
  </fragment>
</template>

<script>
import { computed, provide } from "vue";
import UsersTable from './UsersList/UsersListTable.vue';
import { Fragment } from "vue-frag";
import axios from "axios";

export default {
  name: 'Users',
  components: { UsersTable, Fragment },
  data() {
    return {
      loading: false,
      users: [],
      preloadedUsers: [],
      params: {
        page: 1,
        per_page: 10,
        search: '',
        sort: 'created_at',
        sort_direction: 'desc',
        positions: [],
        include: 'nativeSubscriptions,roles,sports,profile.profileImage',
      },
      pagination: {},
      endpoint: 'api/v2/users',
    }
  },
  provide() {
    const pagination = {};
    Object.defineProperty(pagination, 'links', {
      get: () => this.pagination.links,
      enumerable: true,
    });
    Object.defineProperty(pagination, 'meta', {
      get: () => this.pagination,
      enumerable: true,
    });
    return {
      pagination,
      getUsers: this.getUsers,
    }
  },
  created() {
    const query = Object.keys(this.params).map(key => key + '=' + this.params[key]).join('&');
    const url = this.endpoint + '?' + query;
    this.getUsers(url)
  },
  methods: {
    buildUrlString() {
      const query = Object.keys(this.params).map(key => key + '=' + this.params[key]).join('&');
      return this.endpoint + '?' + query;
    },
    /**
     * Get users from the provided url and update state with the response
     * @param {string} url - the url to get users from
     * @param {boolean} forceReload - force a new request even if the page is preloaded
     * @returns {void}
     */
    getUsers(url, forceReload = false) {
      this.loading = true

      if (forceReload) {
        this.preloadedUsers = []
      } else {
        const page = new URLSearchParams(url).get('page');
        if (this.getPreloadedUsers(page)) {
          console.log('using cached data')
          return
        }
      }

      const controller = new AbortController();
      axios.get(url, { signal: controller.signal })
        .then(response => {
          this.users = response.data.data
          this.pagination = response.data.meta
          this.preloadAdditionalPages(response.data.meta.links)
        })
        .catch(error => {
          console.error('v2 users endpoint error:', error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    orderBy(column) {
      if (this.params.sort === column) {
        this.params.sort_direction = this.params.sort_direction === 'asc' ? 'desc' : 'asc'
      } else {
        this.params.sort = column
        this.params.sort_direction = 'asc'
      }
    },
    /**
     * Get preloaded users if they exist
     * @param {string|number} page
     * @returns {boolean}
     */
    getPreloadedUsers(page) {
      if (this.preloadedUsers['p'+page]) {
        console.log('using preloaded users', page)
        this.users = this.preloadedUsers['p'+page].data
        this.pagination = this.preloadedUsers['p'+page].meta
        this.loading = false
        return true
      }
      return false
    },
    /**
     * Load data from the provided links to decrease load time when switching pages
     * @experimental
     * @param {array} links
     * @returns {void}
     */
    preloadAdditionalPages(links) {
      links.forEach(link => {
        if (link.url && link.active === false) {
          axios.get(link.url).then(response => {
            this.preloadedUsers['p'+response.data.meta.current_page] = response.data
          })
        }
      })
    }
  },
}
</script>
