<template>
  <div>
    <video class="video-js" data-setup='{"controls": true, "fluid": true, "autoplay": false, "preload": "auto"}'>
      <source :src="interview.file_upload.optimized_url"></source>
    </video>
  </div>
</template>

<script>
  export default {
    name: 'Interview',
    props: ['interview'],

    mounted() {
      let $video = $('video', this.$el)
      videojs($video[0]).ready(() => {
        $(window).trigger('resize')
      })
    },

    methods: {
      //
    }
  }
</script>
