<template>
  <td class="row-expanded-body" colspan="6">
    <h5>COMMUNICATION LOG</h5>
    <span class="block-span">{{ invite.created_at }} - <span class="date-span">List Added</span></span>
  </td>
</template>

<script>
export default {
  name: 'ActionItems',
  props: {
    invite: Object,
  }
}
</script>

<style scoped lang="scss">
.row-expanded-body {
  padding: 16px 64px;
  text-align: start !important;
  width: 100%;

  h5 {
    color: #525252;
    font-weight: 400;
    letter-spacing: 0.16px;
    line-height: 24px;
    margin-bottom: 0;
  }

  .block-span {
    color: #525252;
    display: block;
    letter-spacing: 0.16px;
    line-height: 24px;
  }

  .date-span {
    font-weight: 600;
  }
}
</style>
