<template>
  <div class="table-wrapper">
    <table class="table errors-table">
      <thead class="errors-header">
      <tr class="errors-header-tr">
        <th class="errors-header-th">Row</th>
        <th class="errors-header-th">Attribute</th>
        <th class="errors-header-th">Error</th>
      </tr>
      </thead>
      <tbody class="errors-body">
      <tr v-for="error in errors" class="errors-body-tr">
        <td class="errors-body-td">{{ error.row - 1 }}</td>
        <td class="errors-body-td">{{ error.attribute }}</td>
        <td class="errors-body-td">{{ error.errors[0] }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'ErrorsTable',
  props: ['errors']
}
</script>

<style lang="scss">
.table-wrapper {
  height: 12.6rem;
  overflow: auto;
  width: 100%;
}
.errors-table {
  background: #fff;
  margin-bottom: 0;

  .errors-header-th {
    background: #fff;
    border-top: 0;
    color: #161616;
    font-family: KairosSans, sans-serif;
  }

  .errors-body-td {
    color: #525252;
    font-family: DINPro, sans-serif;
  }
}
</style>
