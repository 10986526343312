export default class SDSPusher {

  async getPusherInfo() {
    await axios.get('/api/app-info').then(res => {
      this.pusherInfo = res.data
    })
  }

  createPusherChannel(authEndpoint, channelName) {
    const { pusher_key: key, pusher_cluster: cluster, } = this.pusherInfo
    const auth = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
      }
    }

    this.pusher = new Pusher(key, {cluster, authEndpoint, auth}).subscribe(channelName)
  }

  bindChannelEvent(eventName, callbackFn) {
    this.pusher.bind(eventName, res => callbackFn(res))
  }
}
