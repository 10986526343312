
<template></template>
<script>
export default {
  created() {
    if ((this.$store.state.message = [])) {
      this.$worker
        .run((arg) => {
          return true;
        })
        .then((data) => {
          this.$store.dispatch("fetchUser");
          this.$store.dispatch("fetchUserMessage");
        })
        .catch((e) => {
          console.error(e);
        });
    }
  },
};
</script>




