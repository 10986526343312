<template>
  <div class="col-12" id="playerVerifications" :player-id="this.player_id">
    <div class="verifications-wrapper" v-if="this.measurementsLoaded && this.measurements.length > 0">
      <div id="slider" class="table display">
          <div class="slide" v-for="(measurement, key) in measurements" >
            <video v-if="measurement.video">
              <source :src="getMeasurementVideo(measurement)"></source>
            </video>
            <div class="play-btn" v-if="measurement.video"><i class="fa fa-play"></i></div>
            <img v-else-if="measurement.photo" :src="getMeasurementImage(measurement)" alt="Thumbnail">
            <div v-else class="default-container org-bg">
              <img class="default-img" src="/build/images/signing-day-sports-logo.png" alt="Default Image">
            </div>
            <div class="slide-inner" @click="switchMeasurement(measurement.id)">
                <div class="banner">
                    <h4 class="text-center">{{measurement.measurement.data.name}}</h4>
                    <div class="banner-color org-bg"></div>
                </div>
            </div>
          </div>
      </div>
    </div>
    <div class="text-center col-12" v-else-if="this.measurementsLoaded && this.measurements.length == 0">
      <p class="no-verifications">This player has no other verifications.</p>
    </div>
    <div class="text-center col-12 loader-container" v-else-if="! this.measurementsLoaded">
      <div class="loading">
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div><br>
      </div>
    </div>
  </div>
</template>

<script>
    import axios from 'axios'
    import Multiselect from 'vue-multiselect'
    import VerificationDetail from './VerificationDetail'

    export default {
        name: 'player-verifications',
        props: ['player_id', 'measurement_id', 'max_slides'],

        data() {
            return {
              measurements: [],
              slides: 6,
              measurementsLoaded: false
            }
        },

        mounted: function() {
          this.getMeasurements()
        },

        methods: {
          getMeasurements(exclude_id = this.measurement_id) {
            axios.get(`/api/pending-measurements?user_id=${this.player_id}&exclude_id=${exclude_id}&include=measurement,video,photo`).then((response) => {
                this.measurements = response.data.data
            }).then(() => {
                this.measurementsLoaded = true
            }).then(() => {
                this.initializeSlider()
            });
          },

          initializeSlider() {
            let self = this

            $(`[player-id="${this.player_id}"] #slider`).slick({
                infinite: false,
                speed: 300,
                slidesToShow: self.max_slides > 0 ? self.max_slides : self.slides,
                slidesToScroll: self.max_slides > 0 ? self.max_slides : self.slides,
                draggable: false,
                responsive: self.getResponsiveAttributes(),
                prevArrow: "<i class='fa fa-caret-down arrow-left'></i>",
                nextArrow: "<i class='fa fa-caret-down arrow-right'></i>"
            });
          },

          resetSlider() {
            let slider = $(`[player-id="${this.player_id}"] #slider`)

            if (slider.hasClass('slick-initialized')) {
              slider.slick('unslick');
            }
          },

          switchMeasurement(id) {
            this.measurementsLoaded = false
            this.resetSlider()
            this.getMeasurements(id)
            this.$emit('update-measurement', id)
          },

          getMeasurementImage(measurement) {
            if(measurement.photo != null) {
              return measurement.photo.data.url != null 
                ? measurement.photo.data.url
                : measurement.photo.data.thumbnail_path
            }
            return null;
          },

          getMeasurementVideo(measurement) {
            return measurement.hasOwnProperty('video')
              ? measurement.video.data.file_upload.data.url
              : null
          },

          getResponsiveAttributes() {
            return [
                {
                  breakpoint: 1250,
                  settings: {
                    slidesToShow: this.max_slides <= 4 ? 2 : this.slides - 2,
                    slidesToScroll: this.max_slides <= 4 ? 2 : this.slides - 2,
                  }
                },
                {
                  breakpoint: 1100,
                  settings: {
                    slidesToShow: this.max_slides <= 3 ? 2 : this.slides - 3,
                    slidesToScroll: this.max_slides <= 3 ? 2 : this.slides - 3,
                  }
                },
                {
                  breakpoint: 950,
                  settings: {
                    slidesToShow: this.max_slides <= 4 ? 2 : this.slides - 4,
                    slidesToScroll: this.max_slides <= 4 ? 2 : this.slides - 4,
                  }
                },
                {
                  breakpoint: 550,
                  settings: {
                    slidesToShow: this.max_slides <= 5 ? 1 : this.slides - 5,
                    slidesToScroll: this.max_slides <= 5 ? 1 : this.slides - 5,
                  }
                },
              ]
          }
        },
    }
</script>
