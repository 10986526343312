<template>
  <tr :id="'user' + user.id">
    <td class="profile-image-cell">
      <a :href="`/user/${user.id}/edit`" target="_blank"><user-avatar :user="user" /></a>
    </td>
    <td class="user">
      <a :href="`/user/${user.id}/edit`" target="_blank">
        <h4>
          {{ this.name }}
        </h4>
      </a>
      <p> {{ user.email }} </p>
      <p> {{ this.roles }} </p>
    </td>
    <td>
      <img :src="this.subscription.plan.store_logo"
        height="24px"
        :alt="this.subscription.plan.platform"
        :title="this.subscription.plan.platform"
      />&nbsp;{{ this.subscription.plan.name }}
    </td>
    <td>{{ user.subscription?.status }}</td>
    <td>{{ this.sports }}</td>
    <td>
      <a :href="`/user/${user.id}/edit`" class="edit btn">
        <span class="mdi mdi-lead-pencil">Edit</span>
      </a>
      <button v-if="!user.subscription && !deleting" @click="deleteUser(user.id)" id="remove-player" class="btn red-text">
        <span class="mdi mdi-delete-forever">Delete</span>
      </button>
      <button v-if="deleting" class="btn red-text" disabled>
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Deleting...
      </button>
      <a v-if="transcriptUrl" :href="this.transcriptUrl" class="btn">
        <span class="mdi mdi-download">Download Transcript</span>
      </a>
    </td>
    <td>{{ this.createdAt }}</td>
    <td>{{ this.lastLogin }}</td>
  </tr>
</template>

<script>
  import { computed } from 'vue';
import axios from 'axios';

  export default {
    name: 'UsersListRow',
    props: {
      user: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        name: this.user.first_name + ' ' + this.user.last_name,
        subscription: {
          'plan': {
            'platform': this.setSubscriptionPlatform(),
            'store_logo': this.setStoreLogo(),
            'name': this.user.subscription?.plan,
          }
        },
        sports: computed(() => {
          return this.user.sports.map(sport => {
            return sport.name;
          }).join(', ');
        }),
        roles: computed(() => {
          return this.user.roles.map(role => {
            return role.name;
          }).join(', ');
        }),
        deleting: false,
        transcriptUrl: this.user.profile?.transcript?.data[0]?.url ?? null,
      }
    },
    computed: {
      createdAt() {
        return new Date(this.user.created_at).toLocaleString('en-Us');
      },
      lastLogin() {
        if (this.user.last_login_at === null) {
          return 'Unknown';
        }
        return new Date(this.user.last_login_at).toLocaleString('en-Us');
      },
    },
    methods: {
      /**
       * Set the subscription status
       * @returns {string}
       */
      setSubscriptionStatus() {
        if (this.user.subscription === null) {
          return 'None';
        }
        if (this.user.subscription?.ends_at) {
          let endsAt = new Date(this.user.subscription.ends_at);
          let currentDate = new Date();
          if (endsAt > currentDate) {
            return 'Active';
          }
        } else if (this.user.subscription?.renewing_at) {
          let renewingAt = new Date(this.user.subscription.renewing_at);
          let currentDate = new Date();
          if (renewingAt > currentDate) {
            return 'Active';
          }
        }

        return 'Expired';
      },
      /**
       * Set the subscription platform
       * @returns {string}
       */
      setSubscriptionPlatform() {
        switch (this.user.subscription?.platform) {
          case 'ios':
            return 'Apple';
          case 'android':
            return 'Google';
          case 'external':
            return this.user.subscription?.subscription_data?.event_type === 'army' ? 'Army Bowl' : 'Other';
          default:
            return this.user.subscription?.platform;
        }
      },
      /**
       * Set the subscription store logo
       * @returns {string}
       */
      setStoreLogo() {
        switch (this.user.subscription?.platform) {
          case 'ios':
            return '/build/images/apple-store-logo.svg';
          case 'android':
            return '/build/images/google-play-logo.svg';
          case 'external':
            return this.user.subscription?.subscription_data?.event_type === 'army' ? '/build/images/army-bowl-logo.jpg' : null;
          default:
            return '';
        }
      },
      /**
       * Open modal to confirm deletion of user
       * @param {number} id
       */
      deleteUser(id) {
        if (confirm('Are you sure you want to delete this user? This will remove the user from the system.')) {
          this.deleting = true;
          axios.delete(`/api/users/${id}`)
            .then(() => {
              document.getElementById('user'+id).remove();
              toastr.success('User deleted successfully');
            })
            .catch(() => {
              this.deleting = false;
              toastr.error('Error deleting user');
              console.log('Error deleting user');
            });
        }
      },
    }
  }

</script>

<style lang="scss" scoped>
.user {
  h4, p {
    margin-bottom: 0;
    margin-top: 0;
  }
}

#remove-player {
  vertical-align: unset;
}

.btn {
  white-space: nowrap;
}
.red-text {
  color: red;
}

.profile-image-cell {
  width: 7rem;
}
</style>
