<template>
  <div class="name-container">
    <div class="name-modal">
      <div class="name-header">
        <h5 class="header-text">Name your invite</h5>
      </div>
      <div class="name-body">
        <input v-model="name" class="name-input" placeholder="Invite name"></input>
      </div>
      <div class="name-footer">
        <back-button @back="$emit('back')"></back-button>
        <button @click="saveInviteName" class="btn btn-primary">Continue</button>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from './BackButton'

export default {
  name: "InviteName",
  components: { BackButton },
  data: () => ({
    name: ''
  }),
  methods: {
    saveInviteName() {
      this.$emit('saveInviteName', this.name)
    }
  }
}
</script>

<style scoped>
.name-container {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.name-header {
  background:black;
  color:white;
  font-family: KairosSans,sans-serif;
  text-transform:uppercase;
  border-top:5px solid #E7F818;
  border-radius:5px 5px 0px 0px;
  padding: 1rem 2rem;
}

.header-text {
  color: white;
  position: relative;
  top: 5px;
}

.name-body {
  padding: 2rem;
  background:#f0f0f0;
}

.name-input {
  min-width: 70ch;
  padding: .5rem .75rem;
}

.name-footer {
  padding: 1rem 2rem;
  background: #f9f9f9;
  border-bottom: 5px #646464;
  border-radius: 0 0 5px 5px;
  display: flex;
  justify-content: space-between;
}

.btn {
  font-family: KairosSans,sans-serif;
  text-transform:uppercase;
}
</style>
