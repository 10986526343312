<template>
  <div>
    <h3>Upcoming Events</h3>
    <div class="row" v-if="mappedEvents.length > 0">
      <div class="col-sm-4" v-for="({date, time, location, type}) in mappedEvents" style="padding-top: 20px">
        <event-card :date="date" :time="time" :location="location" :type="type"/>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-sm-4">
        <h6>No upcoming events for player</h6>
      </div>
    </div>
  </div>
</template>

<script>
import EventCard from './EventCard'
export default {
  name: 'UpcomingEvents',
  components: { EventCard },
  props: {events: {type: Array, default: [], required: false}},
  computed: {
    mappedEvents: (v) => v.events.map(event => {
      const dateSplits = event?.event_time_string?.split(' ')[0]?.split('-')
      const timeSplit = event?.event_time_string?.split(' ')[1]?.split(':')
      const date = new Date(new Date().setFullYear(dateSplits[0], dateSplits[1] - 1, dateSplits[2])).toLocaleDateString()
      const time = new Date(new Date().setHours(timeSplit[0], timeSplit[1], timeSplit[2])).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})
      const location = `${event.address}, ${event.city}, ${event.state}`
      return { date, time, location, type: event.type }
    }),
  },
  data() {
    return {
      today: + new Date()
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {}
}
</script>

<style scoped>

</style>
