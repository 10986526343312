<script>
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

// TODO Move this elsewhere and don't import everything.
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
Vue.use(ElementUI);
locale.use(lang);

import { DataTablesServer } from "vue-data-tables";

export default {
  extends: DataTablesServer,
};
</script>


<style>
.el-table__header thead tr th {
  background-color: #000 !important;
  color: #fff;
  border-top: solid 4px #e7f816;
  border-left: none;
  border-right: none;
  border-bottom: none;
  padding-top: 16px;
}
.el-table .cell {
  word-break: break-word;
}
td.el-table__expanded-cell {
  padding: 0 !important;
}

.el-date-editor .el-range-separator {
  padding: 0 !important;
  width: auto !important;
}
</style>
