<template>
  <data-tables-server
    :data="data"
    :loading="loading"
    :total="pagination.total"
    :current-page="currentPaginationPage"
    :filters="filters"
    row-key="id"
    default-expand-all
    :pager-count="pagination.count"
    @query-change="handleQueryChange"
    :pagination-props="{
      pageSizes: [10, 25, 50, 100],
      background: true,
      layout: 'prev, pager, next, sizes, ->, total'
    }"
  >
    <el-table-column v-if="type === 'checkbox'" v-for="({ type, width }) in columnDefs" :type="getType(type)" :width="width ? width : null" :key=`${type}_checkbox`/>
    <el-table-column v-if="type !== 'checkbox'" v-for="({ type, label, width }) in columnDefs" :type="getType(type)" :label="label" :width="width ? width : null" :key=`${type}`>
      <template v-slot="{ row }" v-if="type === 'accordion'">
        <div class="invite-data">
          <div class="attributes-container">
            <h4>Requested Attributes:</h4>
            {{ row.name }}
            <attribute-list heading="Measurables" :category="row.readable_attributes.measurables"></attribute-list>
            <attribute-list heading="Testing" :category="row.readable_attributes.testing"></attribute-list>
            <attribute-list heading="Academics" :category="row.readable_attributes.academics"></attribute-list>
            <attribute-list heading="Additional" :category="row.readable_attributes.additional"></attribute-list>
          </div>
        </div>
      </template>
      <template v-slot="{ row }" v-else-if="type === 'checkbox'">
        <div>
          {{ row.name }}
        </div>
      </template>
      <template v-slot="{ row }" v-else>
        <div>
          {{ row.name }}
        </div>
      </template>
    </el-table-column>
  </data-tables-server>
</template>

<script>
import DataTablesServer from '@components/ui/DataTablesServer';
import allPlayerAllListView from "../tables/columnDefs/allPlayerAllListView";
import allInvitationOverview from "../tables/columnDefs/allInvitationOverview";
import AttributeList from "../../screens/myinvites/components/AttributeList";

import { buildQueryForVueDataTable } from '@lib/vue-data-tables-util'


// TYPE CONSTANTS -- TODO MOVE TO DEDICATED FILE/DIRECTORY
const ACCORDION = 'accordion';
const CHECKBOX = 'checkbox';

let timeout;

export default {

name: 'BaseTable',
components: { DataTablesServer, AttributeList },
  props: {
    api: String,
  },
  data() {
    return {
      data: [],
      loading: false,
      rangeDates: [],
      filters: [],
      pagination: {},
      previousQuery: null,
    }
  },
  computed: {
    console: () => console,
    window: () => window,
    columnDefs() {
      switch(this.api) {
        case '/api/verified-players': return allPlayerAllListView;
        case '/api/my-invites': return allPlayerAllListView;
        case '/api/player-invites': return allInvitationOverview;
        default: return [];
      }
    },
  },
  methods: {
    getType(type) {
      switch (type) {
        case 'accordion': return 'expand'
        case 'checkbox': return 'selection'
        default: return type || ''
      }
    },
    handleQueryChange(query) {
      clearTimeout()
      timeout = setTimeout(() => this.loadData(query), 250);
    },
    async loadData(query, silentReload = false) {
      this.previousQuery = query
      this.loading = !silentReload
      const builder = buildQueryForVueDataTable(query)

      const url = `${this.api}?${builder.build()}`

      const res = await axios.get(url)
      this.data = res.data.data
      this.pagination = res.data.meta.pagination

      this.loading = false
    },
    async getData() {
      const { data: { data, meta } } = await axios.get(this.api)

      this.data = data
    }
  },
  async created() {
    await this.getData()
  },
}
</script>

<style>

.invite-data {
  padding: 1.5rem 1.5rem;
}

.attributes-container {
  margin-top: 1rem;
  margin-left: 2rem;
  display: flex;
  gap: 2rem;
}

</style>
