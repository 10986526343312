/**
 * This was mostly autogenerated from existing auto-registerd components.
 * Breaking it out into this file to start being more explicit.
 * So some of these need reorganized, renamed, etc.
 *
 * TODO - review this list, group/rename as needed
 */
import Vue from "vue";

function loadComponent(name) {
  /**
   * ATM this is just a wrapper for require().default.
   * Down the road, we can replace the line with the following
   * to enable async loading and webpack splits:
   *
   *    return () => import(`@components/${component}`);
   */

  return require(`@components/${name}`).default;
}

function loadScreen(name) {
  return require(`@screens/${name}`).default;
}

Vue.component("ActivityBar", loadComponent("ActivityBar.vue"));
Vue.component("AddFootage", loadComponent("AddFootage.vue"));
Vue.component("AddPlayer", loadComponent("AddPlayer.vue"));
Vue.component("AddQuestions", loadComponent("AddQuestions.vue"));
Vue.component("AdditionalFootage", loadComponent("AdditionalFootage.vue"));
Vue.component("Alert", loadComponent("Alert.vue"));
Vue.component("Alerts", loadComponent("Alerts.vue"));
Vue.component("AnnouncementsTable", loadComponent("AnnouncementsTable.vue"));
Vue.component("AttributeMedia", loadComponent("AttributeMedia.vue"));
Vue.component(
  "AttributeMediaGallery",
  loadComponent("AttributeMediaGallery.vue")
);
Vue.component("BigBoard", loadComponent("BigBoard.vue"));
Vue.component("CheckPlayerStatus", loadComponent("CheckPlayerStatus.vue"));
Vue.component("FileUpload", loadComponent("FileUpload.vue"));
Vue.component("GroupMessage", loadComponent("GroupMessage.vue"));
Vue.component("Messages", loadComponent("Messages.vue"));
Vue.component("PlayerActions", loadComponent("PlayerActions.vue"));
Vue.component("PlayerCard", loadComponent("PlayerCard.vue"));
Vue.component("PlayerProfile", loadComponent("PlayerProfile/PlayerProfile.vue"));
Vue.component("PublicPlayerProfile", loadComponent("PlayerProfile/PublicProfile/PublicPlayerProfile.vue"));
Vue.component("PlayerVideo", loadComponent("PlayerVideo.vue"));
Vue.component("PlayerImage", loadComponent("PlayerImage.vue"));
Vue.component("PositionCard", loadComponent("PositionCard.vue"));
Vue.component("StartConversation", loadComponent("StartConversation.vue"));
Vue.component(
  "StartGroupConversation",
  loadComponent("StartGroupConversation.vue")
);
Vue.component("StatCard", loadComponent("StatCard.vue"));
Vue.component("Stats", loadComponent("Stats.vue"));
Vue.component("TagView", loadComponent("TagView.vue"));
Vue.component("UpdateProfile", loadComponent("UpdateProfile.vue"));
Vue.component("Uploader", loadComponent("Uploader.vue"));
Vue.component("LeftMenu", loadComponent("LeftMenu.vue"));
Vue.component(
  "Announcements",
  loadComponent("announcements/Announcements.vue")
);
Vue.component("CreatePost", loadComponent("feed/CreatePost.vue"));
Vue.component("Post", loadComponent("feed/Post.vue"));
Vue.component("PostCardComponent", loadComponent("feed/PostCardComponent.vue"));
Vue.component("PostComment", loadComponent("feed/PostComment.vue"));
Vue.component("Film", loadComponent("filmroom/Film.vue"));
Vue.component("FilmRoom", loadComponent("filmroom/FilmRoom.vue"));
Vue.component("Interview", loadComponent("filmroom/Interview.vue"));
Vue.component("Player", loadComponent("filmroom/Player.vue"));
Vue.component("PlayerList", loadComponent("filmroom/PlayerList.vue"));
Vue.component("Infiput", loadComponent("infiputs/Infiput.vue"));
Vue.component("PositionStyle", loadComponent("infiputs/PositionStyle.vue"));
Vue.component("Comment", loadComponent("notes/Comment.vue"));
Vue.component("CreateNote", loadComponent("notes/CreateNote.vue"));
Vue.component("Notes", loadComponent("notes/Notes.vue"));
Vue.component(
  "PrivatePlayerProfile",
  loadComponent("public/PrivatePlayerProfile.vue")
);
Vue.component("initstate", loadComponent("vuex/initstate.vue"));
Vue.component("logout", loadComponent("vuex/logout.vue"));
Vue.component("Health", loadComponent("weight-room/Health.vue"));
Vue.component(
  "MeasurementForm",
  loadComponent("weight-room/MeasurementForm.vue")
);
Vue.component("Measurements", loadComponent("weight-room/Measurements.vue"));
Vue.component(
  "PlayerVerifications",
  loadComponent("weight-room/PlayerVerifications.vue")
);
Vue.component(
  "RecentlyViewed",
  loadComponent("weight-room/RecentlyViewed.vue")
);
Vue.component("SearchRoster", loadComponent("weight-room/SearchRoster.vue"));
Vue.component(
  "VerificationDetail",
  loadComponent("weight-room/VerificationDetail.vue")
);
Vue.component(
  "VerificationDetailVideo",
  loadComponent("weight-room/VerificationDetailVideo.vue")
);
Vue.component("WeightRoom", loadComponent("weight-room/WeightRoom.vue"));
Vue.component("verification", loadComponent("weight-room/verification.vue"));
Vue.component("CalendarsTable", loadComponent("calendars/CalendarsTable.vue"));
Vue.component("CoachesTable", loadComponent("organizations/CoachesTable.vue"));
Vue.component("PlayersTable", loadComponent("organizations/PlayersTable.vue"));
Vue.component("TeamRosterLevelTable", loadComponent("organizations/TeamRosterLevelTable.vue"));
Vue.component("AddRosterLevelPlayer", loadComponent("AddRosterLevelPlayer.vue"))
Vue.component("RolesTable", loadComponent("user/RolesTable.vue"));
Vue.component('Events', loadComponent('events/Events.vue'));
Vue.component('EventSchedule', loadComponent('events/EventSchedule.vue'));
Vue.component('UpcomingEvents', loadComponent('events/UpcomingEvents.vue'));

// Screens
Vue.component("screen-roster", loadScreen("roster/Roster.vue"));
Vue.component("screen-organizations", loadScreen("organizations/OrganizationsScreen.vue"));
Vue.component("screen-users", loadScreen("users/Users.vue"))
Vue.component("screen-recruits", loadScreen("recruits/Recruits.vue"))
Vue.component("screen-prospects", loadScreen("prospects/Prospects.vue"))
Vue.component("screen-verified-players", loadScreen("verifiedplayers/VerifiedPlayersScreen.vue"));
Vue.component("screen-myinvites", loadScreen("myinvites/MyInvites.vue"));
Vue.component("screen-myinvitesindividuallist", loadScreen("myinvites/InviteListDetails/MyInvitesIndividualList.vue"));
// Roster/Player import components
Vue.component("import-players-option", loadScreen("roster/ImportPlayersOption.vue"));
Vue.component("screen-roster-import", loadScreen("roster/RosterImportModal.vue"));

// Prospect Lists
Vue.component("prospectlists-index", loadScreen("prospectlists/index/ProspectlistsIndex.vue"));
Vue.component("prospectlists-create", loadScreen("prospectlists/create/ProspectlistsCreate.vue"));
Vue.component("ProspectlistAddAthleteComments", loadScreen("prospectlists/create/ProspectlistAddAthleteComments.vue"));
Vue.component("prospectlist-athlete-comments", loadScreen("prospectlists/profile/ProspectlistAthleteComments.vue"));
Vue.component("prospectlist-athlete-navbar", loadScreen("prospectlists/profile/ProspectlistAthleteNavbar.vue"));
Vue.component("prospectlist-show", loadScreen("prospectlists/show/ProspectlistShow.vue"));
Vue.component("coaches-connect-bar", loadScreen("prospectlists/profile/CoachesConnectBar.vue"));
Vue.component("user-profile-card", loadScreen("prospectlists/profile/UserProfileCard.vue"));

// User Login Modal
Vue.component("UserLoginModal", loadComponent("auth/UserLoginModal.vue"));
