import inviteStatusNames from '@consts/invite-status-names';

export default {
  [inviteStatusNames.profileCreated]: 'Profile Created',
  [inviteStatusNames.existing]: 'Existing Athlete',
  [inviteStatusNames.returned]: 'Returned',
  [inviteStatusNames.opened]: 'Opened',
  [inviteStatusNames.notSent]: 'Not Sent',
  [inviteStatusNames.notOpened]: 'Not Opened',
  [inviteStatusNames.invited]: 'Invited',
}
