<template>
	<div class="show dropright" @mouseover="setIsAdmin()">
		<div v-if="isAdmin">
			<a class="dropdown-item dropdown-toggle" href="javascript:void(0);" @click.stop.prevent="toggleDisplaySportsMenu" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				Import Players
			</a>
			<div :class="{'dropdown-menu':true, 'show':showSportsMenu}" id="import-dropdown-sports-type" aria-labelledby="dropdownMenuLink">
				<input type="file" id="sdsPlayerImportFile" name="import_players" @change.prevent="import_players" class="d-none" accept=".csv,.xlsx">
				<slot name="sds-sport-types-admin"></slot>
			</div>
		</div>

		<div v-else>
            <input type="file" id="sdsPlayerImportFile" name="import_players" @change.prevent="import_players" class="d-none" accept=".csv,.xlsx">
            <a class="dropdown-item" href="javascript:void(0);" @click.prevent="handleSportSelection" v-text="getImportBtnText"></a>	
            <slot name="sds-sport-types"></slot>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isAdmin: false,
				showSportsMenu: false,
				sport: {
					id: null,
					name: null,
				},
				importBtnText: 'Import Players',
			}
		},
		computed: {
			getImportBtnText(){
				if(this.sport.name !== null){
					// make first letter of sport name capitalized
					const sportName = this.sport.name.charAt(0).toUpperCase() + this.sport.name.slice(1);
					this.importBtnText = "Import "+sportName+" Players";
				}
				else{
					this.importBtnText = "Import Players";
				}

				return this.importBtnText;
			},
		},
		methods: {
			setIsAdmin(){
				// if passengerSportType data is undefined, then the user isAdmin
				this.isAdmin = (typeof $('#passengerSportType').attr("data-sportID") === 'undefined' && typeof $('#passengerSportType').attr("data-sportName") === 'undefined');
			},
			import_players(event){
				const playerImportData = event.target;
				window.EventBus.publish('sds_extractPlayerImportData', {
					playerImportData: playerImportData, 
					sport: this.sport
				});
			},
			toggleDisplaySportsMenu(){
				this.showSportsMenu = !this.showSportsMenu;
			},
			intializeSportType(event){
				this.setIsAdmin();

				if(!this.isAdmin){
					this.sport.id = $('#passengerSportType').attr("data-sportID");
					this.sport.name = $('#passengerSportType').attr("data-sportName");
				}
			},
			handleSportSelection(event){
				if(this.isAdmin){
					const target = event.target;
					this.sport.id = target.getAttribute("data-sportID");
					this.sport.name = target.getAttribute("data-sportName");
				}
				else{
					this.sport.id = $('#passengerSportType').attr("data-sportID");
					this.sport.name = $('#passengerSportType').attr("data-sportName");
				}

				this.toggleDisplaySportsMenu();
				$('#sdsPlayerImportFile').click();
			},
		},
		created() {
			window.EventBus.subscribe('sds_handleSportSelection', (data) => {
				this.handleSportSelection(data.event);
			});

			window.EventBus.subscribe('sds_intializeSportType', (data) => {
				this.intializeSportType(data.event);
			});
		},
	}
</script>

<style>

</style>
