export default () => {

    $.ajaxSetup({
        headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
        }
    });

  window.SDSPusher.getPusherInfo().then(() => {
        const authId = $('#authId').val()
        window.SDSPusher.createPusherChannel('/api/user/authorize-pusher', `private-user_channel-${authId}`)
        window.SDSPusher.bindChannelEvent('mass_message', () => {
            window.vueInstance.$emit('fetchUserMessage');
            toastr.success('', 'Mass message sent successfully!');
        })
    })

  window.addRecruitToRoster = async(data, e, position) => {
        $(e).css({
            "pointer-events": "none"
        });
    $.ajax({
        url: `/roster/add/${data}/position/${position}`,
        type: "POST"
    }).done(d => {
        $(e).css({
            "pointer-events": "initial"
        });
    if (d.attached.length > 0) {
        toastr.success("Player added to prospects list", "Added Player");
        e.innerHTML = "&mdash;";
        e.className = "removeBtn";
    } else {
        toastr.error("Player could not be added to prospects list", "Error!");
    }
    });
    }

    window.removeFromRoster = async(data, e, position, list) => {
        Swal.fire({
            title: `Remove from ${list == 1 ? "Roster" : "Prospects list"}?`,
            text: `This will remove the player and any attributes associated with the player from your ${
                list == 0 ? "prospects list" : "roster"
            }.`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, remove player!",
            cancelButtonText: "No, cancel!",
            confirmButtonClass: "btn btn-confirm mt-2",
            cancelButtonClass: "btn btn-cancel ml-2 mt-2",
            reverseButtons: true
        }).then(result => {
            if (result.value) {
                $.ajax({
                    url: `/roster/delete/${data}`,
                    type: "DELETE"
                }).done(d => {
                    if (d > 0) {
                        e.innerHTML = "+";
                        e.className = "addBtn";
                        toastr.warning(`Player removed from ${list == 0 ? "prospects list" : "roster"}`, "Removed Player");
                    } else {
                        toastr.error(`Player was not removed from ${ list == 0 ? "prospects list" : "roster" }`, "Error!");
                    }
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    }

    window.toggleAdvancedSearch = (visible) => toggleAdvancedSearch(visible)

    function toggleAdvancedSearch(visible)
    {
        const $basic = $("#basic-filters")
        const $saved = $('#saved-searches')
        const $title = $('#page-title')
        window.vueInstance.$refs['screen-recruits'].$refs['advanced-search'].visible = visible
        return visible
        ? $title.text('Advanced Search') && $basic.hide() && $saved.show()
        : $title.text('Search') && $basic.show() && $saved.hide()
    }

    $(document).ready(() => {
        Cookies.expire("defaultSearchText");
        $("#advanced_searchBtn").on("click", () => {
            toggleAdvancedSearch(true);
        });

    $("#simple_search").on("click", () => {
        window.vueInstance.$refs['screen-recruits'].$refs['advanced-search'].resetSearch()
        toggleAdvancedSearch(false);
      });

    $("#save").on("click", () => {
        Swal.fire({
            title: "Save this search?",
            input: "text",
            inputPlaceholder: "Enter a name for this search",
            type: "question",
            showCancelButton: true,
            confirmButtonText: "Save",
            cancelButtonText: "Cancel",
            confirmButtonClass: "btn btn-confirm mt-2",
            cancelButtonClass: "btn btn-cancel ml-2 mt-2",
            reverseButtons: true
        }).then(result => {
            if (result.value) {
                const advancedSearchInstance = window.vueInstance.$refs['screen-recruits'].$refs['advanced-search']
                const criteria = advancedSearchInstance.saveSearchCriteriaToCookies();
                axios.post("/api/savedsearches", {
                    name: result.value,
                    advanced_criteria: JSON.stringify(criteria)
                })
                .then(response => {
                    if (response.data.data.advanced_criteria) {
                        toastr.success("", "Your search has been saved");
                        $("#saved_searches_list").append(
                            $("<option>", {
                                value: JSON.stringify(criteria),
                                text: result.value
                            })
                        );
                    } else {
                        toastr.error("", "Unable to save search: Review search criteria and try again.");
                    }
                })
                .catch(error => {
                      toastr.error("", "Something went wrong: Unable to save search");
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    });
    });
}
