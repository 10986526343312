<template>
    <div id="team-feed" class="col-sm-12">
        <div class="card-box col-sm-12" v-if="canCreatePost()">
            <div class="card-body">
                <create-post :user_id="user_id" :organization_id="organization_id" v-on:new_post="updatePosts" ref="create_post"/>
            </div>
        </div>
        <div class="card-box col-sm-12" >
            <div class="card-body feed-main" id="main">
                <div class="profiletimeline recent-activity" id="feed">
                    <div v-if="this.ready">
                        <post-card-component v-for="(item,key) in posts" :post="item" :key="key" :user_id="user_id" v-on:update_posts="updatePosts"/>
                        <div v-if="this.no_results" class="text-center col-sm-12 no-results">
                            <p>There are currently no posts at this time.</p>
                        </div>
                    </div>
                    <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler"></infinite-loading>
                </div>
                <div class="lightgallery">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CreatePost from './CreatePost'
import InfiniteLoading from 'vue-infinite-loading';

Vue.use(InfiniteLoading, {
    slots: {
        'noMore': '',
        'noResults': ''
    }
})

export default {
        name: 'Post',
        props: {
            user_id: String,
            organization_id: String,
            role: String,
            sport: String,
        },
        data() {
            return {
                ready: false,
                no_results: false,
                posts: [],
                infiniteId: +new Date()
            }
        },
        methods: {
            getPosts() {
                axios.get('/api/posts').then((response) => {
                    this.posts = response.data.data
                    this.ready = true
                }).catch((error) => {
                    console.log(error)
                })
            },
            updatePosts() {
                this.checkResults()
                this.$forceUpdate
            },
            infiniteHandler($state) {
                axios.get('/api/posts', {
                    params: {
                        page: Math.floor(this.posts.length / 10) + 1,
                    },
                }).then((response) => {
                    if (undefined !== response.data.meta) {
                        for (let i = 0; i < response.data.data.length; i += 1) {
                            if (this.posts.filter(post => post.id == response.data.data[i].id).length == 0 && response.data.data[i].user.data.current_sport === this.sport) {
                                this.posts.push(response.data.data[i])
                            }
                        }
                        if (response.data.meta.pagination.current_page == response.data.meta.pagination.total_pages) {
                            $state.complete()
                            if (response.data.meta.pagination.total == 0 && response.data.meta.pagination.current_page == 1 && window.location.pathname != "/") {
                                $('#noActivity').show()
                                $('#vue').hide()
                            }
                        } else {
                            $state.loaded()
                        }
                    } else {
                        this.posts = this.posts.concat(response.data.data);
                        $state.complete();
                    }
                    this.checkResults()
                    this.ready = true
                })
            },
            canCreatePost() {
                if(this.role != 'user') {
                    return true
                }
                else {
                    return false
                }
            },
            checkResults() {
                (this.posts.length == 0) ? this.no_results = true : this.no_results = false
            }
        }
    }
</script>
