<template>
  <div id="big-board-canvas" class="big-board">
    <div class="row">
      <div class="col-md-12" style="padding-bottom: 2.5vh">
        <h1 style="display: inline-block; padding-left: 8px">{{ this.trans.get('__JSON__.big board') }}</h1>
        <h1 class="formationYearHeader" style="display: none; float: right">{{currentFormationName.replace('_', ' ').toUpperCase()}}, {{selectedYear}}</h1>
        <div style="float: right; display: inline-block;" class="sort-and-save">
          <div class="btn-group save-current-button">
            <button type="button" class="generate-image btn waves-effect waves-light" v-on:click="generateImage()" data-html2canvas-ignore>{{generatingImage ? 'Saving' : 'Save'}}</button>
          </div>
          <div class="btn-group" data-html2canvas-ignore>
            <el-select
              :placeholder="`${isHighSchool ? 'Depth Chart' : 'Big Board'} History`"
              @visible-change="onHistoryVisibleChange"
              @change="onHistoryChange"
              :value="null"
            >
              <el-option v-if="loadingPrevious" label="Loading..." value disabled/>
              <el-option v-if="previousCaptures.length === 0 && !loadingPrevious" :label="'No Screenshots'" value disabled/>
              <el-option v-for="(capture, idx) in previousCaptures"
                :label="capture.title.replace('_', ' ').toUpperCase()"
                :class="capture.title.toLowerCase().indexOf(currentFormation.toLowerCase()) !== -1"
                :value="capture.id"
                :key="`cap_drop_${capture.id || idx}`"
              />
            </el-select>
          </div>
          <div class="btn-group" data-html2canvas-ignore>
            <el-select placeholder="Chart Type" class="theme-select" v-model="selectedChartType" ref="chart-type-select" v-if="sport === 'football'">
              <el-option v-for="type in getChartTypes()"
              :key="`type_key_${type}`"
              class="theme-font"
              :label="`${type.replace(`${sport}_`, '').replace('_', ' ').toUpperCase()}`"
              :value="type"
              ></el-option>
            </el-select>
          </div>
          <div class="btn-group" data-html2canvas-ignore v-if="isHighSchool">
            <el-select v-model="bigBoardRosterLevelState.selectedId" class="theme-select">
              <el-option
                v-for="{id, name} in bigBoardRosterLevelState.levels"
                :key="id"
                :label="name"
                class="theme-font"
                :value="id">
              </el-option>
            </el-select>
          </div>
          <div class="btn-group" data-html2canvas-ignore v-else>
            <el-select v-model="year" class="theme-select" @change="updateYear" ref="year-select">
              <el-option v-for="year in years"
                :key="`year_drop_${year}`"
                class="theme-font"
                :value="year"
                :label="year"
              />
            </el-select>
          </div>
          <div class="btn-group" data-html2canvas-ignore v-if="isHighSchool">
            <el-select v-model="bigBoardDepthLevelState.selectedId" placeholder="Depth Level" class="theme-select" :clearable="bigBoardDepthLevelState.selectedId !== 999" @clear="bigBoardDepthLevelState.selectedId = 999" ref="class-select">
              <el-option
                v-for="{id, name} in bigBoardDepthLevelState.levels"
                :key="id"
                class="theme-font"
                :label="name"
                :value="id">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <div v-if="ready" :key="renderKey">
      <div v-for="row in layout" :class="['big-board-row', `${currentFormation}-row`]" style="margin-bottom: 2.5vh">
        <template v-for="position in row">
          <div v-if="position === 'spacer'" class="big-board-spacer"></div>
          <template v-else>
          <PositionCard
            :position='position'
            :key="position.id"
            :currentYear='currentYear'
            :selectedYear="selectedYear"
            :players='getPlayersByPosition(position)'
            :updatePositions='updatePositions'
            :group_id="group_id"
            :displayNotes="(playerid)=>displayNotes(playerid)"
            :formation="currentFormation"
          />
          </template>
        </template>
      </div>
    </div>
    <div v-else>
      <h1 style='text-align:center'>Loading...</h1>
    </div>
    <div v-if="showPrevious !== null" class="big-board-modal">
      <div class="backdrop" v-on:click.self="showPrevious = null">
        <div class="modal-container previous-screenshot">
          <h3>Previous Screen Captures</h3>
          <h5>{{showPrevious.title}}</h5>
          <hr/>
          <img :src="showPrevious.image" v-on:click="window.open(showPrevious.image)"/>
        </div>
      </div>
    </div>
    <div v-if="generatingImage === true" class="big-board-modal">
      <div class="backdrop" style="background-color: rgba(0, 0, 0, 0.90);">
        <div class="modal-container previous-screenshot">
          <h3 style="text-align: center">SAVING DONT LEAVE PAGE</h3>
        </div>
      </div>
    </div>

    <!-- Notes Modal -->
    <div class="modal fade" id="notesModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Notes for {{ selectedPlayerName }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" id="notes">
            <notes v-if="this.displayPlayerNotes" type="User" :id="this.selectedPlayerId" :key="this.selectedPlayerId" :organizationid="this.organizationid" :authuser="this.authuser" alertableid="0" :player="selectedPlayer" ref="notes" embed_refs="big_board"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import _ from 'lodash';
  import Pusher from 'pusher-js'
  import html2canvas from 'html2canvas'
  import { positionsLayouts } from '../lib/big-board-layouts'

  /**
   * Big Board Shared State
   * ----------------------
   *
   * Big Boards data definitely needs to be in Vuex, but it's not right now. Until then, I'm
   * definiing some key bits of state here. We'll use provide/inject to avoid manually wiring/@listening
   * through x-number of children until a proper vuex store is in place.
   */
  const bigBoardDepthLevelState = Vue.observable({
    selectedId: 999,
    levels: [
      { name: 'All', id: 999 }, // 999 as shortcut bc nobody will have more than 999 players in roster, but the integer saves us from doing extra checks against null/NaN/etc :)
      { name: 'Starters', id: 1 },
      { name: '2 Deep', id: 2 },
      { name: '3 Deep', id: 3 },
    ],
  });

  const bigBoardRosterLevelState = Vue.observable({
    selectedId: 1,
    levels: window.signingDayConstants['roster_levels'],
  });

  export default {
    name: 'BigBoard',
    provide() {
      return {
        bigBoardDepthLevelState,
        bigBoardRosterLevelState,
      }
    },
    data: function() {
      return {
        ready: false,
        year: undefined,
        positionLayouts: positionsLayouts[this.sport],
        currentFormation: Cookies.get('formation') || 'offense',
        // @TODO -- really need to cleanup / consolidate the currentYear, selectedYear, and year data variables used here...
        currentYear: new Date().getFullYear(),
        selectedYear: this.isHighSchool ? 'all' : Cookies.get('advanced_search') && JSON.parse(Cookies.get('advanced_search'))['grad_year'] != '' ? JSON.parse(Cookies.get('advanced_search'))['grad_year'] : new Date().getFullYear(),
        allPlayers: [],
        events: [],
        generatingImage: false,
        loadingPrevious: false,
        previousCaptures: [],
        showPrevious: null,
        window: window,
        selectedPlayer: {},
        selectedPlayerId: 0,
        selectedPlayerName: 'Unknown',
        displayPlayerNotes: false,
        bigBoardRosterLevelState,
        bigBoardDepthLevelState,
        selectedChartType: null,
        renderKey: (Math.random() * 10000)
      }
    },
    props: ['group_id', 'authuser', 'organizationid', 'high_school', 'sport'],
    watch: {
      selectedChartType(selected) {
        this.updateFormation(selected)
      }
    },
    created: function() {
      window.onbeforeunload = this.saveCookies;
      this.fetchPlayers()

      let channel = new Pusher('66c905f1f24810379d43', {
          cluster: 'US2',
          authEndpoint: '/api/big-board/authorize-pusher',
          auth: {headers: {
              "X-Requested-With": "XMLHttpRequest",
              "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
          }}
      }).subscribe('private-bigboard-' + 1)

      $(() => {
        this.getOrResetCookies()
        this.populateDropdowns()
      })
    },
    methods: {
      getChartTypes() {
        return Object.keys(positionsLayouts[this.sport])
      },
      updateFormation: function(formation) {
        if (formation !== this.currentFormation) {
          this.currentFormation = formation
          Cookies.set('formation', formation, { expires: Infinity })

          $(".tooltip").tooltip("hide")
          this.fetchPlayers()
        }
      },
      updateYear: function(year) {
        if (this.selectedYear !== year) {
          this.selectedYear = year

          let advanced_search = {}
          advanced_search = Cookies.get('advanced_search') ? JSON.parse(Cookies.get('advanced_search')) : {}
          advanced_search['grad_year'] = year
          advanced_search = JSON.stringify(advanced_search)

          Cookies.set('advanced_search', advanced_search, { expires: Infinity })

          $(".tooltip").tooltip("hide")
          this.fetchPlayers()
        }
      },
      fetchPlayers: async function (silent = false) {
        const data = { only_ranked: true, order_by: 'rank' }

        if (!this.isHighSchool) {
          data.year = this.selectedYear
        }

        this.ready = silent

        await axios.get('/api/big-board/get-players', { params: { ...data } }).then(response => {
          const { data: { data: players } } = response
          this.allPlayers = players
          this.ready = true
        })
      },
      updatePositions: async function(position, userData, event, yearUpdate = false, playerObject) {
        let users = new Set();
        const hasDuplicates = userData.some(function(currentObject) {
            return users.size === users.add(currentObject.id).size;
        });

        if (hasDuplicates) {
          return false;
        }

        for (let player of userData) {
          if (this.players.some(({ id, position: p}) => id === player.id && p === position)) {
            const index = this.players.findIndex(p => p.id === player.id && p.position === position)
            this.players[index].rank = player.index + 1
          } else {
            this.players.push({ ...playerObject, id: player.id, position: position, rank: player.index + 1})
          }
        }

        for (let washedPlayer of this.players) {
          if (washedPlayer.position === position && userData.map(u => u.id).indexOf(washedPlayer.id) === -1) {
            washedPlayer.position = ''
          }
        }

        event.positionIndex = this.buildPositionIndexes(position, yearUpdate, userData)

        this.events.push(event)

        const positions = this.events.map(event => event.position)
          .filter(
            (value, index, self) => self.indexOf(value) === index
          )

        const players = (() => {
          let teamObject = {}
          for (let position of positions) {
            teamObject[position] = teamObject[position] || []

            const positionPlayers = this.players.filter(({position: p}) => p === position)
              .sort((a, b) => a.rank - b.rank)
              .map((p, i) => ({ id: p.id, index: i + 1, roster_level_id: this.isHighSchool ? this.currentRosterLevelId : null }))

            teamObject[position].push(positionPlayers)
          }
          return teamObject
        })()

        clearTimeout(this.updateBigBoardTimer)
        this.updateBigBoardTimer = setTimeout(async () => {
          const events = this.events
          this.events = []
          await axios.post('/api/update-big-board', {events: events, positions: players}).then(async () => {
            const params = { organization_id: this.group_id, order_by: 'rank' }
            if (!this.isHighSchool) params.year = this.selectedYear || (new Date).getFullYear()
            const url = `/api/big-board/get-players?${new URLSearchParams(params).toString()}`
            const { data: { data: players } } = await axios.get(url)
            this.allPlayers = players
          })
        }, 500)
      },
      generateImage() {
        const $canvas = $("#big-board-canvas")
        $('#user-guidance').hide()
        $('.formationYearHeader').show()
        .css('padding-top', 50)
        $canvas.css('padding-bottom', 50)
        $canvas.css('padding-left', 50)
        if (this.generatingImage === false) {
          this.generatingImage = true
          html2canvas(document.getElementsByClassName('container-fluid')[0], {width: 2400}).then((canvas) => {
            let context = canvas.getContext('2d')
            context.scale(2,2)

            axios.post('/api/big-board/upload-image', {
              image: canvas.toDataURL('image/png'),
              year: this.selectedYear,
              formationName: this.currentFormation[0].toUpperCase() + this.currentFormation.slice(1)}).then(() =>
              {
                this.generatingImage = false
            })
            $('.formationYearHeader').hide()
            $canvas.css('padding-top', 0)
            $canvas.css('padding-bottom', 0)
            $canvas.css('padding-left', 0)
            $('#user-guidance').show()
          });
        }
      },
      async loadPrevious() {
        const formation = this.currentFormation[0].toUpperCase() + this.currentFormation.slice(1).toLowerCase()
        await axios.get('/api/organizations/' + this.group_id + '?include=screenshots').then(response => {
          const { data: { data: { screenshots: { data: captures } } } } = response
          this.previousCaptures = captures.reverse()
            .filter(({ title }) => title.toLowerCase().indexOf(formation.toLowerCase()) !== -1)
            .filter(({ title }) => title.toLowerCase().indexOf(` - ${this.selectedYear}`) !== -1)
            .map(({ title, url: image, id }) => ({ title, image, formationName: formation, id }))
        })
      },
      getCohortWithCurrentYear(year) {
        let date = (new Date).getMonth() > 5
          ? (parseInt((new Date).getFullYear()) + 1)
          : parseInt((new Date).getFullYear())

        switch (year) {
          case date:
            return 'SR';
          case date + 1:
            return 'JR';
          case date + 2:
            return 'SO';
          case date + 3:
            return 'FR';
          case 'all':
            return 'ALL';
        }

        if (year > date + 4) {
            return 'FR';
        } else {
            return 'SR';
        }
      },
      getYearWithCohort(cohort) {
        switch (cohort) {
            case 'SR':
                return (new Date).getMonth() > 5
                  ? (parseInt((new Date).getFullYear()) + 1)
                  : parseInt((new Date).getFullYear())
            case 'JR':
                return (new Date).getMonth() > 5
                  ? (parseInt((new Date).getFullYear()) + 2)
                  : parseInt((new Date).getFullYear() + 1)
            case 'SO':
                return (new Date).getMonth() > 5
                  ? (parseInt((new Date).getFullYear()) + 3)
                  : parseInt((new Date).getFullYear() + 2)
            case 'FR':
                return (new Date).getMonth() > 5
                  ? (parseInt((new Date).getFullYear()) + 4)
                  : parseInt((new Date).getFullYear() + 3)
        }
      },
      displayNotes(player) {
        let thisPlayer = this.players.find(o => o.id === player)
        this.selectedPlayer = thisPlayer
        this.selectedPlayerId = player
        this.selectedPlayerName = thisPlayer.first_name + ' ' + thisPlayer.last_name
        $('.big-board-modal').modal('hide')
        $('#notesModal').modal()
        this.displayPlayerNotes = true
      },
      async onHistoryVisibleChange(showing) {
        if (showing) {
          this.previousCaptures = []
          this.loadingPrevious = true
          await this.loadPrevious()
          this.loadingPrevious = false
        }
      },
      onHistoryChange(value) {
        this.showPrevious = this.previousCaptures.filter(({ id }) => id === value)[0]
      },
      populateDropdowns() {
        const yearDropdown = this.$refs['year-select'] || this.$refs['class-select']
        const chartTypeDropdown = this.$refs['chart-type-select']

        if (this.isHighSchool) {
          chartTypeDropdown.selected = { currentLabel: this.currentFormationName , value: this.currentFormation }
        } else {
          yearDropdown.selected = { currentLabel: this.selectedYear, value: this.selectedYear }
          chartTypeDropdown.selected = { currentLabel: this.currentFormation, value: this.currentFormation }
        }
        yearDropdown.selectedLabel = yearDropdown.selected.currentLabel
        chartTypeDropdown.selectedLabel = chartTypeDropdown.selected.currentLabel.replace(`${this.sport}_`, '').replace('_', ' ').toUpperCase()
        this.reRender()
      },
      reRender() {
        this.renderKey += Math.random()
      },
      getPlayersByPosition(position) {
        return _.groupBy(this.players, 'position')[position] || [];
      },
      saveCookies() {
        const fields = this.isHighSchool
          ? ['currentFormationName', 'currentFormation', 'bigBoardRosterLevelState.selectedId', 'bigBoardDepthLevelState.selectedId']
          : ['selectedYear', 'currentFormation']
        const cookie = {}
        fields.map(key => cookie[key] = _.get(this, key))
        Cookies.set('big_board', JSON.stringify(cookie), { expires: Infinity })
      },
      getOrResetCookies() {
        // clear deprecated cookie
        Cookies.expire('formation')
        const { type } = performance.getEntriesByType('navigation')[0]
        const cookie = JSON.parse(Cookies.get('big_board'))
        switch (type) {
          case 'reload': return Object.keys(cookie).forEach(k => _.set(this, k, cookie[k]))
          case 'navigate': return Cookies.expire('big_board')
        }
      },
      buildPositionIndexes(position, yearUpdate = false, userData) {
        switch (true) {
          case this.isHighSchool && yearUpdate:
          case this.isHighSchool:
            return userData.map(({ id }, index) => ({ id, index: index + 1 }))
          default:
            return this.players.filter(player => player.position === position)
              .sort((a, b) => a.rank - b.rank)
              .map((p, i) => ({id: p.id, index: i + 1}))
        }
      }
    },
    computed: {
      currentRosterLevelId: ({ bigBoardRosterLevelState: { selectedId }}) => selectedId,
      currentCollegeGradYear: ({ selectedYear }) => selectedYear,
      layout: ({ positionLayouts, currentFormation }) => positionLayouts[currentFormation],
      currentFormationName: ({ currentFormation }) => currentFormation[0].toUpperCase() + currentFormation.slice(1).toUpperCase(),
      players: ({ allPlayers, isHighSchool, currentRosterLevelId, currentCollegeGradYear }) => {
        let players = allPlayers
        // Filter by selected roster level (if any, HS only)
        if (isHighSchool && !!currentRosterLevelId) {
          players = allPlayers.filter(({ level_id: id }) => id === currentRosterLevelId)
        }
        // Filter by selected grad year
        if (!!currentCollegeGradYear && !isHighSchool) {
          players = allPlayers.filter(({ profile: { grad_year } }) => grad_year)
        }
        return players.sort((a, b) => a.rank - b.rank)
      },
      years: function() {
        let yearArray = []

        for (let i = -1; i <= 8; i++) {
          yearArray.push(new Date().getFullYear() + i)
        }

        return yearArray
      },
      isHighSchool() {
        return JSON.parse(this.high_school)
      },
    },
  }
</script>
<style>
.el-input__inner {
  border-radius: 0px !important;
}
.el-input__inner::placeholder {
  color: #000 !important;
}
</style>
