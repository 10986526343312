<template>
  <div id="prospectlists-create">
      <div class="prospectlist-pick-containter">
        <div class="prospectlist-pick-head">
          <h4 class="page-title" v-text="getTitle"></h4>
          
          <prospectlist-name-input
            :listname="listname"
            :handleChange="handleListnameChange"
          >
          </prospectlist-name-input>
        </div>

        <div class="prospectlist-pick-table">
          <roster-table 
            :screen="screen" 
            ref="roster-table" 
            :sport_name="sport_name"
            :prospectlist_id="prospectlist_id"
            :handlePlayerSelect="handlePlayerSelect"
            :profiles="profiles"
            :handlePopulateProspectlist="handlePopulateProspectlist"
            :isInputsUpdated="isInputsUpdated"
          ></roster-table>
        </div>

      <div id="prospectlist-athlete-picks" class="sidenav">
        <prospectlist-athlete-picks
          :players="players"
          :listname="listname"
          :organization_id="organization_id"
          :sport_id="sport_id"
          :sport_name="sport_name"
          :handlePlayerCommentsChange="handlePlayerCommentsChange"
          :handlePlayerRemove="handlePlayerRemove"
          :handleProfilelistSave="handleProfilelistSave"
          :handleProfilelistUpdate="handleProfilelistUpdate"
          :isEditor="isEditor"
          :isInputsUpdated="isInputsUpdated"
        ></prospectlist-athlete-picks>
      </div>
    </div>
  </div>
</template>

<script>
  import ProspectlistNameInput from './ProspectlistNameInput'
  import RosterTable from './RosterTable'
  import ProspectlistAthletePicks from './ProspectlistAthletePicks'
  import utilityCode from '../utils'

  export default {
    components: { ProspectlistNameInput, RosterTable, ProspectlistAthletePicks },

    props: {
      screen: {type: String, default: 'roster', required: false},
      hs: {type: String, default: false, required: false},
      sport_id: {type: String, default: '1', required: false},
      sport_name: {type: String, default: 'football', required: false},
      organization_id: {type: String, default: '', required: false},
      prospectlist_id: {type: String, default: null, required: false},
    },

    data() {
      return {
        players: {},
        listname: '',
        profiles: {},
        comments: {},
        isInputsUpdated: false,
        filters: [
          { search_prop: 'full_name', value: '', },
          { search_prop: 'num_athletes', value: '',},
          { search_prop: 'date_created', value: '',},
          { search_prop: 'date_edited', value: '',},
        ],
      }
    },

    computed: {
      isHighSchool: ({ hs }) => JSON.parse(hs),
      isEditor: ({ prospectlist_id }) => (prospectlist_id !== null),
      getTitle: ({ isEditor }) => (isEditor) ? 'Edit Prospect List' : 'New Prospect List',
    },

    methods: {
      handlePlayerSelect(isChecked, player){
        (isChecked)
          ? this.addPlayerToLists(player)
          : this.removePlayerFromLists(player.id);
      },
      handlePlayerCommentsChange(data, next){
        // add comment to player in playerlist containter
        const clonedPlayers = _.cloneDeep(this.players);
        clonedPlayers[data.player_id].prospectlist_comments = data.input_data.comments;
        this.players = clonedPlayers;

        // add comment to profile in profilelist containter
        const clonedProfiles = _.cloneDeep(this.profiles);
        clonedProfiles[data.player_id].prospectlist_comments = data.input_data.comments;
        this.profiles = clonedProfiles;

        // add comment to commentlist containter
        this.getOrResetCookies();
        const clonedComments = _.cloneDeep(this.comments);
        clonedComments[data.player_id] = data.input_data.comments;
        this.comments = clonedComments;

        this.setIsUpdated(true);
        this.saveCookies();

        // setting timeout here to allow dynamic-component 'prospectlist-add-athlete-comments' modal to generate
        // this sets the default data to be displayed when the modal disappears
        if(next)
          setTimeout(() => next(), 500);
      },
      handlePlayerRemove(playerID){
        // if player is visible in RosterTable, then trigger click to uncheck rosterTable checkbox and remove player from list containers
        // else execute remove manually
        ($('input#prospectlist-selected-player-'+playerID).length > 0)
          ? $('input#prospectlist-selected-player-'+playerID).trigger('click')
          : this.removePlayerFromLists(playerID);
      },
      addPlayerToLists(player){
        // add player to playerlist container
        const clonedPlayers = _.cloneDeep(this.players);
        clonedPlayers[player.id] = player;
        this.players = clonedPlayers;

        // add player to profilelist container
        const clonedProfiles = _.cloneDeep(this.profiles);
        clonedProfiles[player.id] = player;
        this.profiles = clonedProfiles;

        this.setIsUpdated(true);
        this.saveCookies();
      },
      removePlayerFromLists(playerID){
        // remove player from playerlist containter
        this.players = _.omit(this.players, playerID);

        // remove player from prospectlist container
        this.profiles = _.omit(this.profiles, playerID);

        // remove comments from commentlist container
        this.comments = _.omit(this.comments, playerID);

        this.setIsUpdated(true);
        this.saveCookies();
      },
      async handleProfilelistSave(players){
        const validationResult = this.validateDataInput();

        if(validationResult.isValid){
          const url = '/api/prospectlists';
          const body = {
            organization_id: this.organization_id,
            sport_id: this.sport_id, 
            name: this.listname, 
            players: players, 
          };
          const response = await axios.post(url, body);

          Swal.fire({
            title: "Success!",
            text: "Prospectlist saved successfully!",
            type: "success",
            timer: 3000,
            onClose: () => {
              // redirect user to prospectlist homepage
              window.location = "/organizations/"+this.organization_id+"/prospectlist";
            },
          });
        }
        else{
          Swal.fire({
            title: "Error!",
            text:
              validationResult.errorMessage,
            type: "error"
          });
        }
      },
      async handleProfilelistUpdate(players){
        const validationResult = this.validateDataInput();

        if(validationResult.isValid){
          const url = '/api/prospectlists/'+this.prospectlist_id;
          const body = {
            organization_id: this.organization_id,
            sport_id: this.sport_id, 
            name: this.listname, 
            players: players, 
          };
          const response = await axios.put(url, body);
          this.setIsUpdated(false);
          Swal.fire({
            title: "Success!",
            text: "Prospectlist updated successfully!",
            type: "success",
            timer: 3000,
            onClose: () => {
              // redirect user to prospectlist homepage
              window.location = "/organizations/"+this.organization_id+"/prospectlist";
            },
          });
        }
        else{
          Swal.fire({
            title: "Error!",
            text:
              validationResult.errorMessage,
            type: "error"
          });
        }
      },
      async loadProfilelist(){
        $('#loading').show();

        const response = await axios.get('/api/prospectlists/'+this.prospectlist_id);
        const prospectlist = response.data.prospectlist;
        const playerlist = response.data.playerlist.data;
        const playerlistContainer = {};

        // add each player to playerlist container object
        playerlist.forEach((player)=>{
          playerlistContainer[player.id] = player;
        });
        // load playerlist with prospectlist->comments for each player
        prospectlist.data.profiles.forEach((profile, index)=>{
          // if playerlistContainer[user_id] is undefined, 
          // that means that the profile with user_id is no longer in the organization's roster any longer for some reason,
          // then skip over them and only work with those still in the roster
          if(typeof playerlistContainer[profile.user_id] !== undefined && typeof playerlistContainer[profile.user_id] !== 'undefined') {
            playerlistContainer[profile.user_id].prospectlist_comments = profile.pivot.comments
          }
        });

        // update listname and profiles displayed in selected-athlete-list on UI
        this.listname = prospectlist.data.name;
        this.profiles = playerlistContainer;
        window.EventBus.publish('sds_syncProspeclistWithRoster', {profiles: this.profiles});

        $('#loading').hide();
      },
      handlePopulateProspectlist(addedPlayers, isInputsUpdated){
        // add players to prospectlist that are not displayed on rosterTable
        // due to # of players displayed (i.e. 10,25,50,100,...), there may be some
        // that were not marked as 'checked' because of this, so we are adding them to profiles
        // manually
        const isChecked = true;
        const comments = {};

        // collect current prospectlist.player.comments into a comments container
        // then add players to playerlist containter not in addedPlayers list
        Object.keys(this.profiles).forEach((key, index) => {
          // if this.comments is not empty, then this profile comments may have been changed and not saved in UI editor
          // so we will use the updated comments values, rather than default comments values
          // else, use default comment values (ie. do nothing)
          if(!_.isEmpty(this.comments)) this.profiles[key].prospectlist_comments = this.comments[key];
          
          // collect current value of profile comments
          comments[key] = this.profiles[key].prospectlist_comments;

          // check to see if this profile has already been added
          // if not, then add profile to playlist container
          // else, update addedplayer profile's comments with current value
          (!addedPlayers[key])
            ? this.handlePlayerSelect(isChecked, this.profiles[key])
            : addedPlayers[key].prospectlist_comments = comments[key];
        });

        // add ALL players to prospectlist container
        this.players = {...this.players, ...addedPlayers};

        // add comments to commentlist container
        this.comments = comments;
        
        // set var that states whether or not input values have changed and save cookie
        this.setIsUpdated(isInputsUpdated);
        this.saveCookies();
      },
      handleListnameChange(listname){
        this.listname = listname;
        this.setIsUpdated(true);
      },
      validateDataInput(){
        const result = {
          isValid: true,
          errorMessage: '',
        };

        if (this.listname.length === 0) {
          result.isValid = false;
          result.errorMessage = 'Please provide a list name. ';
          return result;
        }

        const isEmpty = Object.keys(this.players);
        if (isEmpty.length === 0) {
          result.isValid = false;
          result.errorMessage = 'Please add players to list.';
          return result;
        }

        return result;
      },
      saveCookies() {
        Cookies.set('prospectlist_table', JSON.stringify({
          filters: this.filters, 
          comments: this.comments,
          isInputsUpdated: this.isInputsUpdated,

        }), { expires: Infinity })
      },
      getOrResetCookies() {
        const { type } = performance.getEntriesByType('navigation')[0]
        const cookie = JSON.parse(Cookies.get('prospectlist_table'))
        switch (type) {
          case 'reload': return Object.keys(cookie).forEach(k => _.set(this, k, cookie[k])) && this.silentRefresh()
          case 'navigate': return Cookies.expire('prospectlist_table')
        }
      },
      setActiveUrl(){
        const target = $('#sidebar-menu ul li a[href$="/prospectlist"]');
        if(!target.hasClass("class-name")) target.addClass("active");
      },
      setIsUpdated(value){
        this.isInputsUpdated = value;
      },
    },

    mounted(){
      utilityCode();
    },

    created() {
      window.addEventListener('beforeunload', (event) => {
        this.saveCookies();
      });

      if(this.isEditor) this.loadProfilelist();
      this.setActiveUrl();
    },
  }
</script>

<style scoped>
  #prospectlists-create {
    padding: 0px;
    margin: -21px -21px 0px 0px;
    min-height: 100%;

    display: flex;
    flex-direction: row;
  }

  .sidenav {
    z-index: 1; /* Stay on top */
    position: fixed; /* Fixed Sidebar (stay in place on scroll) */
    top: 0; /* Stay at the top */
    right: 0;
    bottom: 0; /* Stay at the bottom */
    background-color: #FBFBFB; /* Black */
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 0;
    box-shadow: -5px 0px 4px rgb(0,0,0,0.25);
  }

  .prospectlist-pick-containter {
    padding-top: 35px;
    width: 70% !important;
    /*width: 797px !important;*/
    /*flex comes in to play*/
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .prospectlist-pick-containter, #prospectlist-athlete-picks {
    padding-right: 0;
    padding-left: 0;
  }

  .page-title {
    width: 100%;
  }

  h4.page-title {
    font: Quantico;
    font-size: 31px;
    font-style: italic;
    line-height: 44.33px;
    margin-left: 20px;
  }

  .prospectlist-pick-head {
    padding-left: 16px;
    padding-right: 16px;
  }

  .prospectlist-pick-head {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
</style>

<style>
  #prospectlist-athlete-picks, #prospectlist-athlete-picks .box-card-footer,
  #prospectlist-athlete-picks .athlete-pick-body {
    /*width: 356px !important;*/
    width: 24% !important;
  }
</style>
