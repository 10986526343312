<template>
  <fragment>
    <!-- @todo: Re-implement when needed. MyInvites is pauing development during player app reskin. -->
    <!-- <div class="search-wrapper">
      <PlayerSearch />
    </div> -->
    <table class="table">
      <thead>
        <tr>
          <th v-for="header in headers" :id="`th-${header}`" class="table-header-cell" :key="header">
            <div class="table-header-cell-label">
              <span>{{ header }}</span>
              <PositionFilterButton v-if="header === 'Position'" v-bind="{params}" />
              <StatusFilterButton v-if="header === 'Status'" v-bind="{params}" />
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <PlayerRow
          v-for="player in players"
          :key="player.name"
          v-bind="{player, setActiveAdditionalInfoId, activeAdditionalInfoId}"
        />
      </tbody>
      <pagination
        :pagination="pagination"
        :params="params"
        :resource-count="players.length"
        :on-retrieve-resource="getPlayers"
      />
    </table>
  </fragment>
</template>

<script>
import { Fragment } from 'vue-frag';
import Button from '@components/Button';
import Pagination from '@screens/myinvites/components/Pagination';
import PlayerSearch from '@screens/myinvites/InviteListDetails/InviteListDetailsTable/components/PlayerSearch';
import PlayerRow from '@screens/myinvites/InviteListDetails/InviteListDetailsTable/components/PlayerRow/PlayerRow';
import StatusFilterButton from '@screens/myinvites/InviteListDetails/InviteListDetailsTable/components/StatusFilterButton';
import PositionFilterButton from '@screens/myinvites/InviteListDetails/InviteListDetailsTable/components/PositionFilterButton';

export default {
  name: 'InvitesListDetailsTable',
  components: {
    Button,
    Fragment,
    PlayerRow,
    Pagination,
    PlayerSearch,
    StatusFilterButton,
    PositionFilterButton,
  },
  props: ['players', 'params', 'pagination', 'getPlayers'],
  data() {
    return {
      headers: [
        'Select',
        'Player Name',
        'Email',
        'Position',
        'Date Invited',
        'Status',
        'Player Actions'
      ],
      activeAdditionalInfoId: null,
    }
  },
  methods: {
    setActiveAdditionalInfoId(id = null) {
      this.activeAdditionalInfoId = this.activeAdditionalInfoId === id ? null : id;
    },
  }
}
</script>

<style lang="scss" scoped>
.search-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1px;
}

.table {
  font-family: 'DINPro', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0;
  text-align: center !important;
}

tr {
  border-bottom: 0 !important;
}

.table-header-cell {
  border-top: 0 !important;
  font-size: 14px;
  line-height: 18px;
  padding: 0 12px;
}

.table-header-cell-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 48px;
}

td {
  border-top: 0;
  color: #161616;
  position: relative;
}
</style>
