<template>
<!-- <div :id="'lightgallery' + this.$vnode.key" >
  <a v-if="type === 'image'" :href="url" :data-src="url">
    <img class="attribute-media" src="/build/images/img_icon32.png" style="height:16px; width:16px" alt="view">
  </a>

  <a v-if="type === 'video'" data-sub-html="video caption1" :data-html="'video' + this.$vnode.key">
    <img class="attribute-media" src="/build/images/video_icon32.png" style="height:16px; width:16px" alt="view">
  </a> -->

  <!-- <div :id="'lightgallery' + this.$vnode.key" >
    <img v-if="type === 'image'" :src="url" />
    <video v-if="type === 'video'" :src="url" controls></video>
  </div> -->

<span>
  <img v-on:click="modal()" class="attribute-media" :src="type == 'image' ? '/build/images/img_icon32.png' : '/build/images/video_icon32.png'" style="height:16px; width:16px" alt="view">

  <!-- Modal -->
  <div class="modal fade attribute-modal" :id="'mediaModal' + this.$vnode.key" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <img v-if="type === 'image'" :src="url" />
          <video v-if="type === 'video'" :src="url" controls></video>
        </div>
      </div>
    </div>
  </div>
</span>
<!-- <div v-if="type === 'video'" style="display:none;" :id="'video' + this.$vnode.key" >
  <video class="lg-video-object lg-html5" controls preload="none">
    <source :src="url">
    Sorry, your browser does not support HTML5 video.
  </video>
</div>

</div> -->

</template>

<script>
  import axios from 'axios'

  export default {

    name: 'AttributeMedia',
    props: ['type','url','key'],

    mounted: function() {
      // $('#lightgallery' + this.$vnode.key).lightGallery({
      //   selector: 'a'
      // });
    },

    methods: {
      modal: function() {
        $('#mediaModal'+this.$vnode.key).modal();
      }
    }

  }
</script>
