<template>
  <div class="list-container">
    <h5>{{ heading }}</h5>
    <ul class="attribute-list" v-if="category.length > 0">
      <li class="list-item" v-for="attribute in category">{{attribute}}</li>
    </ul>
    <p v-else>N/A</p>
  </div>
</template>

<script>
export default {
  name: "AttributeList",
  props: {
    heading: {
      type: String,
      required: true
    },
    category: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.list-container {
}

.attribute-list {
  list-style-type: none;
  padding: 0;
}

.list-item {

}
</style>
