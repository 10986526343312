<template>
  <fragment>
    <FormulateForm
      :name="name"
      v-model="formValues"
      @submit="updatePlayer"
      #default="{ hasErrors, isLoading }"
    >
      <div class="form-group">
        <FormInput name="parent_name" label="Parent Name" :dark="true" validation="required" />
      </div>
      <div class="form-group">
        <FormInput name="parent_email" label="Parent Email" :dark="true" />
      </div>
      <div class="form-group">
        <FormInput name="parent_phone" label="Parent Phone" :dark="true" />
      </div>
      <div class="form-group">
        <FormInput name="phone" label="Athlete Phone" :dark="true" validation="required"/>
      </div>
      <div class="form-footer">
        <div class="action-buttons">
          <Button
            type="submit"
            :isLoading="isLoading"
            :isDisabled="hasErrors || isLoading"
            :endIcon="submitEndIcon"
          >
            Submit
          </Button>
          <Button
            type="button"
            color="secondary"
            @onClick="$emit('onCancel')"
            :isDisabled="isLoading"
          >
            Cancel
          </Button>
        </div>
      </div>
    </FormulateForm>
  </fragment>
</template>

<script>
  import { Fragment } from 'vue-frag';
  import Button from '@components/Button';
  import FormInput from '@components/FormInput';
  import CircleCheckMark from '@components/svg/CircleCheckMark';

  export default {
    name: 'EditPlayerAdditionalInfoForm',
    components: { Fragment, FormInput, Button, CircleCheckMark },
    props: {
      player: {},
      defaultValues: {},
      name: '',
    },
    data() {
      return {
        isDrawerOpen: false,
        submitEndIcon: CircleCheckMark,
        formValues: this.defaultValues,
      }
    },
    methods: {
      async updatePlayer(values) {
        const controller = new AbortController();

        try {
          await axios.post(
            `/api/invited-players/${this.player.id}/update`,
            values,
            {
              signal: controller.signal,
            }
          );

          this.$emit('onSuccess');
          this.toaster({
            type: 'success',
            heading: 'Success',
            message: 'Player updated successfully.'
          });
        } catch (error) {
          this.toaster({
            type: 'error',
            heading: 'Error',
            message: 'Failed to update player.'
          });
          console.error(error);
        }
      }
    }
  }
</script>

<style scoped>
  .form-footer {
    margin-top: 2rem;
  }
  .action-buttons {
    display: flex;
    justify-content: space-between;
  }
  .action-buttons button {
    flex: 0 0 calc(50% - 8px);
  }
</style>
