<template>
  <div class="col-12 col-md-12 col-lg-7 col-xl-9">
      <div v-if="this.profileIsLoading" class="text-center"><span class="spinner-border spinner-border"></span></div>
      <div class="profile-card" v-show="!this.profileIsLoading">

        <!-- ////////////////////////////////////////////////////////////////////// -->
        <!-- Dropdown Options -->
        <!-- ////////////////////////////////////////////////////////////////////// -->

        <!-- Web View -->
        <DesktopNav
          v-bind="{
            role,
            player,
            sport,
            activeTabKey,
            tabKeys,
            handleTabChange,
            organizationid,
            endorsements,
            isAuthorized,
            handleTabChangeToSluggerScience,
            addToProspectsBeforeSubscriptionEnded,
          }"
        />

        <!-- Mobile -->
        <MobileNav
          v-bind="{
            activeTabKey,
            tabKeys,
            sport,
            handleTabChange,
            player,
            isAuthorized,
          }"
        />


        <!-- ////////////////////////////////////////////////////////////////////// -->
        <!-- Tab Content -->
        <!-- ////////////////////////////////////////////////////////////////////// -->
        <div class="tab-content">
          <div v-if="activeTabKey === tabKeys.attributes">
            <div class="recruit-info-container" v-if="updating && this.activeProfile !== 'player'">
              <update-profile :player-id="player.id" :high_school="high_school"></update-profile>
            </div>
            <div class="recruit-info-container" v-else-if="! updating">
              <div class="recruit-info" v-if="sport === 'football'">
                <div class="text-right">
                  <a v-if="organizationid === player.profile.data.organization_id && ! updating" @click="updating = ! updating" class="btn btn-primary">
                    <i class="fa fa-pencil"></i>
                  </a>
                </div>
                <h4>Measurables</h4>
                <div class="row">
                  <div class="stats col-sm-12">
                    <div class="stat-item text-center" v-for="(value, key) in player.profile.data.measurables.data" :key="key">
                      <div type="button" class="stat-key" data-toggle="modal" :data-target="'#measurablesModal' + key">
                        <div class="stat-title">
                        {{ getDisplayName(key) }}
                        <i class="mdi mdi-history"></i>
                        </div>
                      </div>
                      <div class="stat-value">
                        {{ returnValueOrNA(value) }}
                        <span v-for="file in player.profile.data.attribute_files.data" v-if="file.attribute_name === key">
                          <AttributeMediaGallery :key="key + '-' + file.type" :type="file.type" :attribute="key.replace(/_/g, ' ')" :url="file.file_upload.data.optimized_url || file.file_upload.data.url"></AttributeMediaGallery>
                        </span>
                      </div>
                      <div class="modal fade" :id="'measurablesModal' + key" tabindex="-1" role="dialog" aria-labelledby="measurablesModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="measurablesModalLabel">{{ getDisplayName(key) }} History</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <div class="row">
                                <div class="col-sm-5 offset-sm-1 pr-0 text-left">
                                  <p class="stat-revisions-created pl-3 pb-3 mb-3 border-bottom" v-for="(value) in player.profile.data.revisions.data.measurables_revisions[key]">{{new Date(value.created_at).toLocaleDateString('en-us') || 'Cleared'}}</p>
                                </div>
                                <div class="col-sm-5 pl-0 text-right">
                                  <p class="stat-revisions-value pr-3 pb-3 mb-3 border-bottom" v-for="(value) in player.profile.data.revisions.data.measurables_revisions[key]">{{formatRevision(key, value.new_value) || 'Cleared'}}</p>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <!-- <button type="button" class="btn btn-primary">Show More</button> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="recruit-info" v-if="sport === 'football'">
                <h4>Testing</h4>
                <div class="row">
                  <div class="stats col-sm-12">
                    <div class="stat-item text-center stats-row-testing" v-for="(value, key) in player.profile.data.testing.data">
                      <div type="button" class="stat-key" data-toggle="modal" :data-target="'#testingModal' + key">
                        <div class="stat-title">
                          {{ key.replace(/_/g, ' ').toUpperCase() }}
                          <i class="mdi mdi-history"></i>
                        </div>
                      </div>
                      <div class="stat-value">
                        {{ value && value !== 'null null' ? addStringToTestingInfo(key, value) : 'N/A' }}
                        <span v-for="file in player.profile.data.attribute_files.data" v-if="file.attribute_name === key">
                          <AttributeMediaGallery :key="key + '-' + file.type" :type="file.type" :attribute="key.replace(/_/g, ' ')" :url="file.file_upload.data.optimized_url || file.file_upload.data.url"></AttributeMediaGallery>
                        </span>
                      </div>
                      <div class="modal fade" :id="'testingModal' + key" tabindex="-1" role="dialog" aria-labelledby="testingModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="testingModalLabel">{{ key.replace(/_/g, ' ').toUpperCase() }} History</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <div class="row">
                                <div class="col-sm-5 offset-sm-1 pr-0 text-left">
                                  <p class="stat-revisions-created pl-3 pb-3 mb-3 border-bottom" v-for="(value) in player.profile.data.revisions.data.testing_revisions[key]">{{new Date(value.created_at).toLocaleDateString('en-us')|| 'Cleared'}}</p>
                                </div>
                                <div class="col-sm-5 pl-0 text-right">
                                  <p class="stat-revisions-value pr-3 pb-3 mb-3 border-bottom" v-for="(value) in player.profile.data.revisions.data.testing_revisions[key]">{{formatRevision(key, value.new_value) || 'Cleared'}}</p>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <!-- <button type="button" class="btn btn-primary">Show More</button> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="recruit-info" v-if="sport === 'football'">
                <h4>Academics</h4>
                <div class="row">
                  <div class="stats col-sm-12">
                    <div class="stat-item text-center" v-for="(value, key) in player.profile.data.academics.data">
                      <!-- Modal is set up and ready to go if we decide to use it in the ong run, just uncomment the button and its closing div -->
                      <div type="button" class="stat-key" data-toggle="modal" :data-target="'#academicsModal' + key">
                        <div class="stat-title">
                          <span v-if="key === 'unweighted_gpa'">UNWEIGHTED GPA</span>
                          <span v-else-if="key === 'sat'">SAT</span>
                          <span v-else-if="key === 'act'">ACT</span>
                          <span v-else-if="key === 'ncaa_id'">NCAA ID</span>
                          <span v-else-if="key === 'desired_major'">DESIRED MAJOR</span>
                          <span v-else-if="key === 'transcript' && currentRole === 'super admin'">TRANSCRIPT</span>
                        </div>
                      </div>
                      <div v-if="key === 'transcript' && currentRole === 'super admin' && isAuthorized" class="stat-value">
                        <AttributeMediaGallery v-if="value" key="transcript" type="transcript" :attribute="key.replace(/_/g, ' ')" :url="value?.uri"></AttributeMediaGallery>
                        <span v-else>N/A</span>
                      </div>
                      <div v-else-if="key === 'transcript' && currentRole === 'super admin'" class="stat-value">
                        <a class="btn btn-primary modal-log-btn" type="button" data-toggle="modal" data-target="#publicLoginModal">View</a>
                      </div>
                      <div v-else class="stat-value">
                        {{ value || 'N/A' }}
                        <span v-for="file in player.profile.data.attribute_files.data" v-if="file.attribute_name === key">
                          <AttributeMediaGallery :key="key + '-' + file.type" :type="file.type" :attribute="key.replace(/_/g, ' ')" :url="file.file_upload.data.optimized_url || file.file_upload.data.url"></AttributeMediaGallery>
                        </span>
                      </div>
                      <div class="modal fade" :id="'academicsModal' + key" tabindex="-1" role="dialog" aria-labelledby="academicsModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="academicModalLabel">{{ key.replace(/_/g, ' ').toUpperCase() }} History</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <p class="stat-revisions" v-for="(value) in player.profile.data.revisions.data.academics_revisions[key]">{{new Date(value.created_at).toLocaleDateString('en-us') || 'Cleared'}}</p>
                            </div>
                            <div class="modal-footer">
                              <!-- <button type="button" class="btn btn-primary">Show More</button> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div v-if="sport !== 'football'">
                <div v-for="rows in attributeTables">
                  <div class="recruit-info" v-if="rows.key !== 'gk technical assessment' && rows.key !== 'technical testing'">
                    <h4>{{ rows.key }}</h4>
                    <div class="row">
                      <div class="stats col-sm-12">
                        <div class="stat-item text-center" v-for="(value, key) in rows.data">
                          <div type="button" class="stat-key" data-toggle="modal" :data-target="'#testingModal' + key">
                            <div class="stat-title">
                              {{ getDisplayName(key) }}
                              <i v-if="shouldDisplayHistory(rows.key)" class="mdi mdi-history"></i>
                            </div>
                          </div>
                          <div class="stat-value">
                            <div v-if="!['gk technical assessment', 'additional testing'].includes(rows.key)">
                              <template v-if="key !== 'transcript'">
                                {{ returnValueOrNA(value) }}
                              </template>
                              <span v-for="file in player.profile.data.attribute_files.data" v-if="file.attribute_name === key">
                                <AttributeMediaGallery :key="key + '-' + file.type" :type="file.type" :attribute=getDisplayName(key) :url="file.file_upload.data.optimized_url || file.file_upload.data.url"></AttributeMediaGallery>
                              </span>
                            </div>
                            <div v-else>
                              <span v-if="player.profile.data.attribute_files.data.filter(file => file.attribute_name === key).length === 0 ">N/A</span>
                              <span v-for="file in player.profile.data.attribute_files.data" v-if="file.attribute_name === key">
                              <AttributeMediaGallery :key="key + '-' + file.type" :type="file.type" :attribute=getDisplayName(key) :url="file.file_upload.data.optimized_url || file.file_upload.data.url"></AttributeMediaGallery>
                            </span>
                            </div>
                            <div v-if="key === 'transcript' && isAuthorized" class="stat-value">
                              <AttributeMediaGallery v-if="value" key="transcript" type="transcript" :attribute="key.replace(/_/g, ' ')" :url="value"></AttributeMediaGallery>
                              <span v-else>N/A</span>
                            </div>
                            <div v-else-if="key === 'transcript' && currentRole === 'super admin'" class="stat-value">
                              <a class="btn btn-primary modal-log-btn" type="button" data-toggle="modal" data-target="#publicLoginModal">View</a>
                            </div>
                          </div>
                          <div v-if="shouldDisplayHistory(rows.key)" class="modal fade" :id="'testingModal' + key" tabindex="-1" role="dialog" aria-labelledby="testingModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 class="modal-title" id="testingModalLabel">{{ getDisplayName(key) }} History</h5>
                                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <div class="row" v-if="rows.key === 'measurables'">
                                    <div class="col-sm-5 offset-sm-1 pr-0 text-left">
                                      <p class="stat-revisions-created pl-3 pb-3 mb-3 border-bottom" v-for="(value) in player.profile.data.revisions.data.measurables_revisions[key]">{{new Date(value.created_at).toLocaleDateString('en-us')|| 'Cleared'}}</p>
                                    </div>
                                    <div class="col-sm-5 pl-0 text-right">
                                      <p class="stat-revisions-value pr-3 pb-3 mb-3 border-bottom" v-for="(value) in player.profile.data.revisions.data.measurables_revisions[key]">{{formatRevision(key, value.new_value) || 'Cleared'}}</p>
                                    </div>
                                  </div>
                                  <div class="row" v-if="rows.key === 'testing'">
                                    <div class="col-sm-5 offset-sm-1 pr-0 text-left">
                                      <p class="stat-revisions-created pl-3 pb-3 mb-3 border-bottom" v-for="(value) in player.profile.data.revisions.data.testing_revisions[key]">{{new Date(value.created_at).toLocaleDateString('en-us')|| 'Cleared'}}</p>
                                    </div>
                                    <div class="col-sm-5 pl-0 text-right">
                                      <p class="stat-revisions-value pr-3 pb-3 mb-3 border-bottom" v-for="(value) in player.profile.data.revisions.data.testing_revisions[key]">{{formatRevision(key, value.new_value) || 'Cleared'}}</p>
                                    </div>
                                  </div>
                                  <div class="row" v-if="rows.key === 'academics'">
                                    <div class="col-sm-5 offset-sm-1 pr-0 text-left">
                                      <p class="stat-revisions-created pl-3 pb-3 mb-3 border-bottom" v-for="(value) in player.profile.data.revisions.data.academics_revisions[key]">{{new Date(value.created_at).toLocaleDateString('en-us')|| 'Cleared'}}</p>
                                    </div>
                                    <div class="col-sm-5 pl-0 text-right">
                                      <p class="stat-revisions-value pr-3 pb-3 mb-3 border-bottom" v-for="(value) in player.profile.data.revisions.data.academics_revisions[key]">{{formatRevision(key, value.new_value) || 'Cleared'}}</p>
                                    </div>
                                  </div>
                                </div>
                                <div class="modal-footer">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="recruit-info" v-if="baseballStats && isAuthorized">
                  <div class="stats-header" >
                    <h4>Stats</h4>
                    <div type="button" class="" data-toggle="modal" data-target="#statsFilterModal">
                        <i class="fas fa-filter "></i>
                    </div>
                  </div>
                  <div v-if="statsFiltered">
                    <p>Showing Stats From:<br />{{StatsFromDate}} - {{statsToDate}}</p>
                  </div>
                  <div v-for="entry in baseballStats">
                    <div class="flex-row m-b-30" v-for="([type, data]) in Object.entries(entry)" style="padding-right: 10px">
                      <div v-if="type !== 'date' && type !== 'image'">
                        <h5>{{type}}</h5>
                        <div class="stats justify-content-between m-t-20">
                          <div v-for="({abbrev, id}) in getStatDataForType(type)">
                            <p class="stat-abbrev">{{abbrev}}:</p>
                            <b>{{ data[id] }}</b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal fade" id="statsFilterModal" tabindex="-1" role="dialog">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="measurablesModalLabel">Filter</h5>
                          <button id="closeFilterButton" type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <p>Filter stats from:</p>
                          <div class="row mb-4">
                              <div class="col-sm-4 pl-0 stats-filter-container">
                                <div class="btn-group" data-html2canvas-ignore>
                                  <el-select
                                    class="stats-dropdown-filter"
                                    placeholder="Month"
                                    :value="null"
                                    v-model="statsFromMonth"
                                  >
                                    <el-option v-for="v in months" :key="v.value" :value="v.value" :label="v.text">{{ v.text }}</el-option>
                                  </el-select>
                                </div>
                              </div>
                              <div class="col-sm-4 pl-0 stats-filter-container">
                                <div class="btn-group" data-html2canvas-ignore>
                                  <el-select
                                    class="stats-dropdown-filter"
                                    placeholder="Day"
                                    :value="null"
                                    v-model="statsFromDay"
                                  >
                                    <el-option v-for="v in statsFromDaysInMonth" :key="v" :value="v">{{ v }}</el-option>
                                  </el-select>
                                </div>
                              </div>
                              <div class="col-sm-4 pl-0 stats-filter-container">
                                <div class="btn-group" data-html2canvas-ignore>
                                  <el-select
                                    class="stats-dropdown-filter"
                                    placeholder="Year"
                                    :value="null"
                                    v-model="statsFromYear"
                                  >
                                    <el-option v-for="v in years" :key="v" :value="v">{{ v }}</el-option>
                                  </el-select>
                                </div>
                              </div>
                            </div>
                          <p>To:</p>
                          <div class="row">
                            <div class="col-sm-4 pl-0 stats-filter-container">
                              <div class="btn-group" data-html2canvas-ignore>
                                <el-select
                                  class="stats-dropdown-filter"
                                  placeholder="Month"
                                  :value="null"
                                  v-model="statsToMonth"
                                >
                                  <el-option v-for="v in months" :key="v.value" :value="v.value" :label="v.text">{{ v.text }}</el-option>
                                </el-select>
                              </div>
                            </div>
                            <div class="col-sm-4 pl-0 stats-filter-container">
                              <div class="btn-group" data-html2canvas-ignore>
                                <el-select
                                  class="stats-dropdown-filter"
                                  placeholder="Day"
                                  :value="null"
                                  v-model="statsToDay"
                                >
                                  <el-option v-for="v in statsToDaysInMonth" :key="v" :value="v">{{ v }}</el-option>
                                </el-select>
                              </div>
                            </div>
                            <div class="col-sm-4 pl-0 stats-filter-container">
                              <div class="btn-group" data-html2canvas-ignore>
                                <el-select
                                  class="stats-dropdown-filter"
                                  placeholder="Year"
                                  :value="null"
                                  v-model="statsToYear"
                                >
                                  <el-option v-for="v in years" :key="v" :value="v">{{ v }}</el-option>
                                </el-select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" v-on:click="getFilteredStats" class="btn btn-primary" :disabled="isFilterDisabled">Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!------
              Prospectlist / Comments (Prospectlist Only)
              -------->
              <div v-if="comments" id="prospectlist-comment-container">
                  <prospectlist-athlete-comments :comments="comments"></prospectlist-athlete-comments>
              </div>
            </div>
          </div>
          <!------------
           Slugger Science Data
          -------------->
            <div v-if="role !== 'fan' && activeTabKey === tabKeys.sluggerScience">
              <div class="recruit-info-container" v-if="updating && activeProfile !== 'player'">
                <update-profile :player-id="player.id" :high_school="high_school"></update-profile>
              </div>
              <div class="recruit-info-container" v-else-if="! updating">
                <div class="recruit-info">
                  <div class="plr-10" v-if="activeTabKey === tabKeys.sluggerScience && isAuthorized">
                    <slugger-science-data
                      :display-titles="sluggerScienceData.displayTitles"
                      :values="sluggerScienceData.values"
                    >
                    </slugger-science-data>
                  </div>
                </div>
              </div>
            </div>
          <!------------
           Pro Day Videos
          -------------->
          <div id="pro" v-if="role != 'fan' && activeTabKey === tabKeys.proDayVideos">
            <div class="plr-10" v-if="this.isAuthorized">
              <div class="col-sm-12 col-md-6 initial-position">
                <div class="form-group">
                  <label>Positions</label>
                  <select name="position_id" class="position form-control select2" v-model="selectedPosition" v-select2>
                    <option v-if="selectedPosition != null" :key="position.id" v-for="position in player.profile.data.positions.data" :value="position.id">{{ position.name }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div>
              <div v-if="isAuthorized" class="add-footage plr-10" style="display: none;">
                <add-footage :organization-id="organizationid" type="film" :player-id="player.id" :positions="player.profile.data.positions.data" :position_start="selectedPosition.toString()"></add-footage>
              </div>
              <div class="success plr-10 text-center" style="display: none;">
                <p>Footage uploaded successfully. Once the video has finished processing it will be available for viewing.</p>
              </div>

              <!-- for sport.softball -->
              <span v-if="sport === 'softball'">
                <!-- for softball.hitter and softball.pitcher positions -->
                <span v-if="[18, 19, 28, 29].includes(selectedPosition)">
                  <!-- for softball.hitter position -->
                  <span v-if="[19, 29].includes(selectedPosition)">
                    <!-- display all hitter videos with a header 'hitter' -->
                    <h4 style="margin: 11px 21px; text-align: center;">Hitting Videos</h4>
                    <div class="row plr-10 justify-content-center film-outer">
                      <player-video v-for="video in getActiveFootage().film" :ref="`pro_day_vid-${video.id}`" :key="video.id" :video="video" :should-loop="selectedVideo === video.id" :player-name="player.first_name + ' ' + player.last_name" @video-toggle="selectedVideoHandler" :is-attribute-file="false" :unauthorized="!isAuthorized"></player-video>
                    </div>
                  </span>

                  <!-- for softball.pitcher position -->
                  <span v-if="[18, 28].includes(selectedPosition)">
                    <!-- only display script videos that are 'fielding' (.ie only 'fielding' pitching videos) and display with a header 'Fielding' -->
                    <h4 style="margin: 11px 21px; text-align: center;">Fielding Videos</h4>
                    <div class="row plr-10 justify-content-center film-outer">
                      <player-video v-for="video in getActiveFootage().film" :ref="`pro_day_vid-${video.id}`" :key="video.id" :video="video" v-if="video.owner.name.toLowerCase().includes('fielding')" :should-loop="selectedVideo === video.id" :player-name="player.first_name + ' ' + player.last_name" @video-toggle="selectedVideoHandler" :is-attribute-file="false" :unauthorized="!isAuthorized"></player-video>
                    </div>

                    <!-- only display script videos that are not 'fielding' (.ie all other pitching videos) and display with a header 'Pitching' -->
                    <h4 style="margin: 11px 21px; text-align: center;">Pitching Videos</h4>
                    <div class="row plr-10 justify-content-center film-outer">
                      <player-video v-for="video in getActiveFootage().film" :ref="`pro_day_vid-${video.id}`" :key="video.id" :video="video" v-if="!video.owner.name.toLowerCase().includes('fielding')" :should-loop="selectedVideo === video.id" :player-name="player.first_name + ' ' + player.last_name" @video-toggle="selectedVideoHandler" :is-attribute-file="false" :unauthorized="!isAuthorized"></player-video>
                    </div>
                  </span>
                </span>

                <!-- for all other softball positions -->
                <span v-else>
                  <div class="row plr-10 justify-content-center film-outer">
                    <player-video v-for="video in getActiveFootage().film" :ref="`pro_day_vid-${video.id}`" :key="video.id" :video="video" :should-loop="selectedVideo === video.id" :player-name="player.first_name + ' ' + player.last_name" @video-toggle="selectedVideoHandler" :is-attribute-file="false" :unauthorized="!isAuthorized"></player-video>
                  </div>
                </span>
              </span>

              <!-- for mens/womens soccer -->
              <span v-else-if="['mens_soccer','womens_soccer'].includes(sport)">
                <!-- soccer goalies -->
                <span v-if="[38, 48].includes(selectedPosition)">
                  <h4 style="margin: 11px 21px; text-align: center;">Technical Testing</h4>
                  <div class="row plr-10 justify-content-center film-outer">
                    <player-video
                      v-for="video in getActiveFootage().film"
                      :ref="`pro_day_vid-${video.id}`"
                      :key="video.id"
                      :video="video"
                      v-if="['4x4 Foundation Box Footwork','4x4 Foundation Box Handling','Low Dive','Collapse Dive','3-Way Shot Stopping Right Side','3-Way Shot Stopping Left Side'].includes(video.owner.name)"
                      :should-loop="selectedVideo === video.id"
                      :player-name="player.first_name + ' ' + player.last_name"
                      @video-toggle="selectedVideoHandler"
                      :is-attribute-file="false"
                      :unauthorized="!isAuthorized"
                    ></player-video>
                  </div>

                  <h4 style="margin: 11px 21px; text-align: center;">Positional Highlights</h4>
                  <div class="row plr-10 justify-content-center film-outer">
                    <player-video
                      v-for="video in getActiveFootage().film"
                      :ref="`pro_day_vid-${video.id}`"
                      :key="video.id"
                      :video="video"
                      v-if="['Handling','Crosses','Distribution','Shot Stopping'].includes(video.owner.name)"
                      :should-loop="selectedVideo === video.id"
                      :player-name="player.first_name + ' ' + player.last_name"
                      @video-toggle="selectedVideoHandler"
                      :is-attribute-file="false"
                      :unauthorized="!isAuthorized"
                    ></player-video>
                  </div>
                </span>
                <!-- soccer players -->
                <span v-else>
                  <h4 style="margin: 11px 21px; text-align: center;">Technical Testing</h4>
                  <div class="row plr-10 justify-content-center film-outer">
                    <player-video
                      v-for="video in getActiveFootage().film"
                      :ref="`pro_day_vid-${video.id}`"
                      :key="video.id"
                      :video="video"
                      v-if="['Figure 8 (no ball)','Figure 8','5-10-5 Shuttle (no ball)','5-10-5 Shuttle'].includes(video.owner.name)"
                      :should-loop="selectedVideo === video.id"
                      :player-name="player.first_name + ' ' + player.last_name"
                      @video-toggle="selectedVideoHandler"
                      :is-attribute-file="false"
                      :unauthorized="!isAuthorized"
                    ></player-video>
                  </div>

                  <h4 style="margin: 11px 21px; text-align: center;">Positional Highlights</h4>
                  <div class="row plr-10 justify-content-center film-outer">
                    <player-video
                      v-for="video in getActiveFootage().film"
                      :ref="`pro_day_vid-${video.id}`"
                      :key="video.id"
                      :video="video"
                      v-if="['1v1 Duels','Air Challenges','Decision Making','Goals & Assists'].includes(video.owner.name)"
                      :should-loop="selectedVideo === video.id"
                      :player-name="player.first_name + ' ' + player.last_name"
                      @video-toggle="selectedVideoHandler"
                      :is-attribute-file="false"
                      :unauthorized="!isAuthorized"
                    ></player-video>
                  </div>
                </span>
              </span>

              <!-- for all other sports -->
              <span v-else>
                <h4 v-if="[18, 19, 28, 29].includes(selectedPosition)" style="margin: 11px 21px; text-align: center;">{{ (selectedPosition === 18 || selectedPosition === 28) ? 'Pitch' : 'Hitting' }} Videos</h4>
                <div class="row plr-10 justify-content-center film-outer">
                  <player-video v-for="video in getActiveFootage().film" :ref="`pro_day_vid-${video.id}`" :key="video.id" :video="video" :should-loop="selectedVideo === video.id" :player-name="player.first_name + ' ' + player.last_name" @video-toggle="selectedVideoHandler" :is-attribute-file="false" :unauthorized="!isAuthorized"></player-video>
                </div>
              </span>

              <div class="row plr-10 justify-content-center film-outer pb-10" v-if="getActiveFootage().customFilm && getActiveFootage().customFilm.length > 0">
                <player-video v-for="video in getActiveFootage().customFilm" :ref="`pro_day_vid-${video.id}`" :key="video.id" :video="video" :should-loop="selectedVideo === video.id" :player-name="player.first_name + ' ' + player.last_name" @video-toggle="selectedVideoHandler" :is-attribute-file="false" :unauthorized="!isAuthorized"></player-video>
              </div>

              <div v-if="['baseball','softball'].includes(sport)">
                <!-- <p v-if="player.profile.data.attribute_files.data.length <= 0" style="margin: 11px 21px;" class="no-footage">This player does not currently have any film for viewing.</p>
                <h4 v-if="[18, 19, 28, 29].includes(selectedPosition)" style="margin: 11px 21px; text-align: center;">{{ (selectedPosition === 18 || selectedPosition === 28) ? 'Pitch' : 'Hitting' }} Videos</h4> -->
                <div v-if="[18, 19, 28, 29].includes(selectedPosition)" class="row plr-10 justify-content-center film-outer">
                  <player-video v-if="(selectedPosition === 28 || selectedPosition === 18) && video.attribute_name.replace(/_/g, ' ').slice(3).includes('pitch') || (selectedPosition === 29 || selectedPosition === 19) && video.attribute_name.replace(/_/g, ' ').slice(3).includes('hitting')" v-for="video in player.profile.data.attribute_files.data" :key="video.id" :video="video" :should-loop="selectedVideo === video.id" :player-name="player.first_name + ' ' + player.last_name" @video-toggle="selectedVideoHandler" :is-attribute-file="true" :unauthorized="!isAuthorized"></player-video>
                </div>

                <h4 v-if="[29].includes(selectedPosition) && video.attribute_name.replace(/_/g, ' ').slice(3).includes('blast')" v-for="video in player.profile.data.attribute_files.data" style="margin: 11px 21px; text-align: center;">Blast Photos</h4>
                <h4 v-if="[18, 28].includes(selectedPosition)" style="margin: 11px 21px; text-align: center;">Rapsodo Photos</h4>
                <div class="row plr-10 justify-content-center film-outer" style="margin: 11px 21px;">
                  <player-image v-show="[18, 19, 28].includes(selectedPosition) && photo.attribute_name.replace(/_/g, ' ').slice(3).includes('rapsodo') || selectedPosition === 29 && photo.attribute_name.replace(/_/g, ' ').slice(3).includes('blast')" v-for="photo in player.profile.data.attribute_files.data" :key="photo.id" :title="photo.attribute_name.replace(/_/g, ' ').slice(3)" :image="photo.file_upload.data.url"></player-image>
                </div>

                <div v-if="selectedPosition === 18 || selectedPosition === 28">
                  <h4 v-if="pitchTypes.length > 0" style="margin: 11px 21px; text-align: center;">Grip</h4>
                  <div v-if="pitchTypes.length > 0" class="row film-outer" style="margin: 11px 21px;">
                    <br/>
                    <player-image v-for="pitchType in pitchTypes" :ref="`pro_day_grip-${pitchType.id}`" :key="pitchType.id" :image="getPitchTypeUrl(pitchType)" :title="pitchType.name"></player-image>
                  </div>
                </div>
              </div>
            </div>
          </div>
           <!------------
           Interview
          -------------->
          <div v-if="role !== 'fan' && activeTabKey === tabKeys.interview">
            <div class="row plr-10 justify-content-center interview-outer" v-if="activeTabKey === tabKeys.interview">
              <player-video v-for="video in player.footage.data.interviews" :ref="`interview_vid-${video.id}`" :key="video.id" :video="video" :should-loop="selectedVideo === video.id" :player-name="player.first_name + ' ' + player.last_name" @video-toggle="selectedVideoHandler" :is-attribute-file="false" :unauthorized="!isAuthorized"></player-video>
              <p v-if="player.footage.data.interviews.length <= 0" style="margin: 11px 21px;" class="no-questions">This player does not currently have any interviews for viewing.</p>
            </div>
            <div v-if="isAuthorized">
              <h4 style="margin: 11px 21px; text-align: center;">Endorsements</h4>
              <div class="row plr-10 justify-content-center">
                <player-video v-for="video in endorsements" :ref="`endorsement_vid-${video.id}`" :key="video.id" :video="video" :should-loop="selectedVideo === video.id" :player-name="video.endorser.name"  @video-toggle="selectedVideoHandler" :is-attribute-file="false" is-endorsement="true" :unauthorized="!isAuthorized"></player-video>
              </div>
            </div>
          </div>
          <!------------
           Additional Footage
          -------------->
          <div v-if="role !== 'fan' && isAuthorized && activeTabKey === tabKeys.additionalFootage">

            <button type="button" class="btn btn-primary add-footage_btn">Add Footage</button>

            <div class="additional-footage_wrap" style="display: none;" v-if="activeTabKey === tabKeys.additionalFootage">
              <additional-footage :organization-id="organizationid" :player-id="player.id"></additional-footage>
            </div>
             <div class="add-footage_success plr-10 text-center" style="display: none;">
              <p>Success! Your additional footage has been submitted. Once the video has finished processing it will be available for viewing.</p>
            </div>

            <div class="row plr-10 justify-content-center additional-footage-outer" v-if="activeTabKey === tabKeys.additionalFootage">
              <player-video v-for="video in player.footage.data.additionalFootage" :key="video.id" :video="video" :ref="`footage_vid-${video.id}`" :should-loop="selectedVideo === video.id" :player-name="player.first_name + ' ' + player.last_name" :authuser="authuser" @video-toggle="selectedVideoHandler" :is-attribute-file="false" :unauthorized="!isAuthorized"></player-video>
              <p v-if="player.footage.data.additionalFootage.length <= 0" style="margin: 11px 21px;" class="no-add-footage">This player does not currently have any additional footage for viewing.</p>
            </div>
          </div>
           <!------------
           Notes
          -------------->
          <div id="notes" v-if="role !== 'fan' && isAuthorized && activeTabKey === tabKeys.notes">
            <notes type="User" :id="player.id" :key="player.id" :authuser="this.authuser" :organizationid="this.organizationid" :alertableid="this.alertableid" :user="this.player" ref="notes" v-if="activeTabKey === tabKeys.notes"></notes>
          </div>
          <!------
          Events / Game Schedule (Baseball Only)
          -------->
          <div v-if="role !== 'fan' && ['baseball','softball'].includes(sport) && isAuthorized && activeTabKey === tabKeys.events">
            <events :userId="player.id" ref="events" v-if="activeTabKey === tabKeys.events"/>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  import SluggerScienceData from "@components/SluggerScienceData";
  import MobileNav from '@components/PlayerProfile/components/MobileNav.vue';
  import DesktopNav from '@components/PlayerProfile/components/DesktopNav/DesktopNav.vue';

  export default {
    name: 'PlayerProfileContent',
    components: { DesktopNav, MobileNav, SluggerScienceData },
    props: {
      player: {},
      role: '',
      sport: '',
      organizationid: '',
      attributeTables: [],
      profileIsLoading: true,
      pitchTypes: [],
      isAuthorized: false,
      updating: false,
      endorsements: [],
      comments: [],
      authuser: {},
      alertableid: '',
      selected_position: '',
      high_school: '',
      addToProspectsBeforeSubscriptionEnded: null,
      activeProfile: '',
      baseballStats: {},
      statsFromDay: null,
      statsFromDaysInMonth: '',
      statsFromMonth: null,
      statsFromYear: null,
      StatsFromDate: null,
      statsToDay: null,
      statsToDaysInMonth: '',
      statsToMonth: null,
      statsToYear: null,
      statsToDate: null,
      submitStatsFilter: false,
      statsFiltered: false,
      getDisplayName: function() {},
      getFilteredStats: function() {},
      isFilterDisabled: function() {},
      getStatDataForType: function() {},
      getSluggerScienceData: function() {},
    },
    computed: {
      tabKeys() {
        return {
          notes: 'notes',
          events: 'events',
          interview: 'interview',
          attributes: 'attributes',
          proDayVideos: 'proDayVideos',
          additionalFootage: 'additionalFootage',
          sluggerScience: 'sluggerScience',
          fundamentals: 'fundamentals',
        }
      },
    },
    data() {
      return {
        currentRole: this.$store.state.user.role,
        activeTabKey: 'attributes',
        activeTabLabel: 'Attributes',
        activeFootage: this.getActiveFootage,
        selectedVideo: null,
        selectedPosition: this.selected_position,
        years: Array.from(Array(new Date().getFullYear() - 2015), (_, i) => (i + 2016).toString()),
        months: [
          {text: 'January', value: 1},
          {text: 'February', value: 2},
          {text: 'March', value: 3},
          {text: 'April', value: 4},
          {text: 'May', value: 5},
          {text: 'June', value: 6},
          {text: 'July', value: 7},
          {text: 'August', value: 8},
          {text: 'September', value: 9},
          {text: 'October', value: 10},
          {text: 'November', value: 11},
          {text: 'December', value: 12}
        ],
      }
    },
    methods: {
      handleTabChange(tab, label) {
        this.activeTabKey = tab;
        this.activeTabLabel = label ?? this.tabKeys[tab];
      },
      handleTabChangeToSluggerScience() {
        this.handleTabChange(this.tabKeys.sluggerScience);
        this.getSluggerScienceData();
      },
      returnValueOrNA(value) {
        if ((value === 'null mph') || (value === 'null seconds') || (value === null) || (value === 'null null') || (value === '0.00')) {
          return 'N/A';
        } else {
          return value;
        }
      },
      getActiveFootage() {
        let footage = this.player.footage.data.film
        // unauthorized users cannot sort by position
        if (this.isAuthorized) {
          footage = footage.filter(video => video.owner.position_id === this.selectedPosition);
        }

        const response = {
          film: footage.filter(film => !film.owner.user_id),
          customFilm: footage.filter(film => film.owner.user_id),
        };

        return response;
      },
      addStringToTestingInfo(key, value) {
        if (key.includes('dash') || key.includes('shuttle') || key.includes('l_drill')) {
          return value + ' seconds'
        }
        return value
      },
      formatRevision(key, value) {
        return window.Helpers.measurements.formatMeasurementRevision(key, value)
      },
      selectedVideoHandler(video) {
        if (this.selectedVideo !== video.id) {
          this.selectedVideo = video.id
          for (const ref in this.$refs) {
            if (this.selectedVideo !== ref.split('-')[1] && this.$refs[ref][0].loaded) {
              this.$refs[ref][0].videoPlayer.pause()
            }
          }
        }
      },
      shouldDisplayHistory(attributeName) {
        return (attributeName === 'measurables' || attributeName === 'testing' || attributeName === 'academics');
      },
      getPitchTypeUrl(pitchType) {
        return pitchType['user-videos'].data.file_upload.data.url
      },
    }
  }
</script>
