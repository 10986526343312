<template>
  <form @submit.prevent="saveIntQuestion()" id="add-questions">
    <input type="hidden" name="user_id" :value="playerId">
    <input type="hidden" name="organization_id" :value="organizationId">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <h4>New Interview Question:</h4>
            <textarea required type="text" maxlength="25000" rows="5" name="question" placeholder="Type your question here..." class="form-control"></textarea>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label>Video</label>
            <input type="file" name="video" class="form-control" accept="video/*" required>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button type="submit" class="btn btn-primary" :disabled="saving">{{ saving ? 'Saving...' : 'Save' }}</button>
        </div>
      </div>
    </div>
  </form>
</template>


<script>

  import axios from 'axios'

  export default {

    props: {
      playerId: Number,
      organizationId: String,
    },

    data: function() {
      return {
        saving: false
      }
    },

    methods: {
      saveIntQuestion() {
        this.saving = true
        let formData = new FormData($('#add-questions')[0])

        axios.post('/api/questions', formData).then(response => {
          if (response.data.data.file_upload !== undefined) {
            $('.add-questions').hide();
            $('.no-questions').hide();
            $('.int-question-success').show();
            this.saving = false
          }
        }).catch(error => {
          console.log(error)
          this.saving = false
        })

      }
    }

  }

</script>



