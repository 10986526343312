<template>
  <tr class="player-table-row" v-if="playerData.config.isAccepted">
    <td class="player-table-column" v-for="(value, key) in playerData.attributes" :key="key" @keydown="clearErrorMessage($event.target.name)">
      <!-- All-Other Input Data Fields -->
      <div v-if="key!=='height' && key!=='weight' && key!=='cellphone' && key!=='positions'" style="max-width: 125px;">
        <div v-if="!isEditorMode" class="playerData-text">
          {{playerData.attributes[key]}}

          <div class="edit-box-message">
            <small v-if="key==='email' && isMemberText!==''" class="form-text text-muted">
              {{isMemberText}}
            </small>
          </div>
        </div>

        <div v-if="isEditorMode">
          <div class="edit-box-input input-group input-group-sm">
            <input type="text" class="form-control player-import-input" v-bind:class="{'border border-danger': getErrorMessage(key)}" :name="key" :placeholder="key" v-model="playerData.attributes[key]">
          </div>

          <div class="edit-box-message">
            <small class="form-text text-muted" v-if="getIsRequired(key)">Required</small>
            <small class="form-text text-muted text-red" v-text="getErrorMessage(key)"></small>
          </div>
        </div>
      </div>

      <!-- Positons Custom Data Fields -->
      <div v-if="key==='positions'" style="min-width: 185px; max-width: 185px;">
        <div v-if="!isEditorMode" class="playerData-text">
          {{playerData.attributes[key].string}}

          <div class="edit-box-message">
            <small v-if="hasUnidentifiedPositions" class="form-text text-muted">
              {{getPositionErrorsWarning()}}
            </small>
          </div>
        </div>

        <div v-if="isEditorMode">
          <div class="edit-box-input input-group input-group-sm" id="player_positions_select">
            <div class="input-group input-group-sm">
              <multiselect
                    v-model="selectedPositions"
                    :options="getPositions"
                    :multiple="true"
                    :close-on-select="false"
                    :preserve-search="true"
                    placeholder="Select Position(s)"
                    :searchable="false"
                    :show-labels="false"
                    @close="handleOnPositionChange()"
                >
                <template slot="tag">{{ '' }}</template>
                <template slot="selection" slot-scope="{ values, search, isOpen }">
                    <span style="text-transform: uppercase;" v-if="values.length && values.length===1">{{ values.length }} Position<br />Selected</span>
                    <span style="text-transform: uppercase;" v-if="values.length && values.length>1">{{ values.length }} Positions<br />Selected</span>


                    <span style="text-transform: uppercase;" v-if="isOpen && values.length < 1">0 Positions<br />Selected</span>

                    <!-- selection actions buttons for accept and clear selected options -->
                    <span class="gray-back select_action_btn" v-if="isOpen && values.length >= 0">
                    <i class="fa fa-lg fa-check" aria-hidden="true" @click="confirmSelectedYears"></i>
                    <span class="gray-back select_action_btn" v-if="isOpen && values.length > 0">
                    <i class="fa fa-lg fa-trash-o" aria-hidden="true" @click="clearSelectedYears" ></i>
                  </span>
                  </span>
                </template>
                <template slot="caret">{{ '' }}</template>
              </multiselect>
            </div>
          </div>
          <div class="edit-box-message">
            <small class="form-text text-muted" v-if="getIsRequired(key)">Required</small>
            <small class="form-text text-muted text-red" v-text="getPositionErrors()"></small>
          </div>
        </div>
      </div>

      <!-- Height Custom Data Fields -->
      <div v-if="key==='height'" style="min-width: 150px; max-width: 150px;">
        <div v-if="!isEditorMode">
          {{playerData.attributes[key].string}}
        </div>

        <div v-if="isEditorMode">
          <div class="edit-box-input" v-bind:class="{'border border-danger': getErrorMessage(key)}">
            <div class="input-group input-group-sm">

              <input type="text" class="form-control player-import-input align-text-center" aria-label="Feet" aria-describedby="basic-addon1" name="feet" placeholder="feet" v-model="playerData.attributes[key].feet" @change="handleOnHeightChange">

              <div class="input-group-append">
                <span class="input-group-text" id="basic-addon1">'</span>
              </div>

              <input type="text" class="form-control player-import-input align-text-center" aria-label="Inches" aria-describedby="basic-addon2" name="inch" placeholder="inches" v-model="playerData.attributes[key].inch" @change="handleOnHeightChange">

              <div class="input-group-append">
                <span class="input-group-text" id="basic-addon2">"</span>
              </div>
            </div>
          </div>
          <div class="edit-box-message">
            <small class="form-text text-muted" v-if="getIsRequired(key)">Required</small>
            <small class="form-text text-muted text-red" v-text="getErrorMessage(key)"></small>
          </div>
        </div>
      </div>

      <!-- Weight Custom Data Fields -->
      <div v-if="key==='weight'" style="min-width: 95px; max-width: 95px;">
        <div v-if="!isEditorMode">
          {{weightString}}
        </div>

        <div v-if="isEditorMode">
          <div class="edit-box-input" v-bind:class="{'border border-danger': getErrorMessage(key)}">
            <div class="input-group input-group-sm">

              <input type="text" class="form-control player-import-input align-text-center" aria-label="Feet" aria-describedby="basic-addon3" :name="key" :placeholder="key" v-model="playerData.attributes[key]" @change="handleOnWeightChange">

              <div class="input-group-append">
                <span class="input-group-text" id="basic-addon3">lbs</span>
              </div>
            </div>
          </div>
          <div class="edit-box-message">
            <small class="form-text text-muted" v-if="getIsRequired(key)">Required</small>
            <small class="form-text text-muted text-red" v-text="getErrorMessage(key)"></small>
          </div>
        </div>
      </div>

      <!-- Cellphone Custom Data Fields -->
      <div v-if="key==='cellphone'" style="min-width: 220px; max-width: 220px;">
        <div v-if="!isEditorMode">
          {{playerData.attributes[key].string}}
        </div>

        <div v-if="isEditorMode">
          <div class="edit-box-input" v-bind:class="{'border border-danger': getErrorMessage(key)}">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon4">(</span>
              </div>

              <input type="text" class="form-control player-import-input align-text-center" aria-label="areacode" aria-describedby="basic-addon4" name="areacode" placeholder="---" maxlength="3" v-model="playerData.attributes[key].areacode" @change="handleOnCellphoneChange">

              <div class="input-group-append">
                <span class="input-group-text" id="basic-addon4">)</span>
              </div>

              <input type="text" class="form-control player-import-input align-text-center" aria-label="exchangecode" aria-describedby="basic-addon5" name="exchangecode" placeholder="---" maxlength="3" v-model="playerData.attributes[key].exchangecode" @change="handleOnCellphoneChange">

              <div class="input-group-append">
                <span class="input-group-text" id="basic-addon5">-</span>
              </div>

              <input type="text" class="form-control player-import-input align-text-center" aria-label="extensioncode" aria-describedby="basic-addon2" name="extensioncode" placeholder="----" maxlength="4" v-model="playerData.attributes[key].extensioncode" @change="handleOnCellphoneChange">
            </div>
          </div>
          <div class="edit-box-message">
            <small class="form-text text-muted" v-if="getIsRequired(key)">Required</small>
            <small class="form-text text-muted text-red" v-text="getErrorMessage(key)"></small>
          </div>
        </div>
      </div>
    </td>

    <td class="player-table-column">
      <div class="button-wrapper float-right">
        <div class="btn-group" role="group">
          <button style="min-width: 70px; max-width: 70px;" type="button" class="btn btn-sm" v-bind:class="{'btn-info': isEditorMode, 'btn-secondary': !isEditorMode}" v-on:click.stop.prevent="toggleInterfaceMode">{{editorButtonText}}</button>
          <button style="min-width: 70px; max-width: 70px;" type="button" class="btn btn-danger btn-sm" v-on:click.stop.prevent="remove_player_data">Remove</button>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
  import Multiselect from 'vue-multiselect'

  const EDITOR_MODE = true; // user interface mode
  const ACCEPTED_MODE = false;  // user interface mode

  const PATH_NAME = window.location.pathname.split('/')[1];
  const PROSPECTS_SCREEN = 'prospects';
  const ROSTER_SCREEN = 'roster'

  export default {
    components: {
      Multiselect
    },

    props: [
      'index',
      'playerData',
      'playerValidator',
      'requiredFields',
    ],
    data() {
      return {
        interfaceMode: EDITOR_MODE,
        errors: {},
        screen: PATH_NAME,
        positions: [],
        selectedPositions: [],
        showPositionsError: true,
      }
    },

    computed: {
      isEditorMode(){
        return (this.interfaceMode === EDITOR_MODE);
      },
      editorButtonText(){
        return (this.interfaceMode === EDITOR_MODE) ? 'Save' : 'Edit';
      },
      weightString(){
        return (this.playerData.attributes.weight > 0) ? this.playerData.attributes.weight+' lbs'  : '';
      },
      isMemberText(){
        const prospectText = "The following player already exists in the platform and will be added to your prospects list.";
        const rosterText = "The following player already exists in the platform. You may attempt to add this player to your roster. If they already exists in another high school's roster a request to transfer will be sent to the player.";

        const isMemberText = (this.screen === PROSPECTS_SCREEN) ? prospectText : rosterText;

        return (this.getIsMember()) ? isMemberText : '';
      },
      getPositions(){
        const positions = this.positions.map((position) => {
          return position.name;
        });

        return (typeof positions !== 'undefined' && positions !== null) ? positions : [];
      },
      hasUnidentifiedPositions(){
        return (this.playerData.config.mappedPositions.failedStr.length);
      }
    },

    methods: {
      getErrorMessage(key){
        return this.errors[key];
      },
      clearErrorMessage(key){
        // if key is from height values feet or inch
        // then re-set key to 'height'
        if(key === 'feet' || key === 'inch'){
          key = 'height';
        }

        // if key is from cellphone values areacode, exchangecode, or extensioncode
        // then re-set key to 'cellphone'
        if(key === 'areacode' || key === 'exchangecode' || key === 'extensioncode'){
          key = 'cellphone';
        }

        // delete error message
        delete this.errors[key];
      },
      getIsRequired(key){
        return (this.requiredFields[key]);
      },
      getIsMember(){
        return (this.playerData.config.isMember);
      },
      getPositionErrors(){
        var failedStr = this.playerData.config.mappedPositions.failedStr;
        failedStr = failedStr.substring(0, failedStr.length - 2);
        var errorText = '';
        if(failedStr.length > 0)
          var errorText = "The following position(s) could not be identified ["+failedStr+"].";

        return errorText;
      },
      getPositionErrorsWarning(){
        var failedStr = this.playerData.config.mappedPositions.failedStr;
        failedStr = failedStr.substring(0, failedStr.length - 2);
        var errorText = '';
        if(failedStr.length > 0)
          var errorText = "The following position(s) could not be identified ["+failedStr+"]. Click 'Edit' to make corrections.";

        return errorText;
      },
      handleOnPositionChange(initialize=null){
        // show the unidentified positions until the user makes a change to the positions dropdown menu
        // then clear the positions error message so it will not appear again
        if(!initialize) if(this.showPositionsError === true) this.showPositionsError = false;
        if(this.showPositionsError === false) this.playerData.config.mappedPositions.failedStr = '';

        // re-match up selected playerPositions with associated sport data in positions
        const selectedPositions = this.selectedPositions.map((name) => {
          return this.positions.filter((position) => (position.name === name))[0];
        });

        // convert seleted postions into a string value
        var selectedPositionsString = '';
        selectedPositions.forEach(position => selectedPositionsString += position.shortcode+',');
        if(selectedPositionsString.length > 0) selectedPositionsString = selectedPositionsString.substring(0, selectedPositionsString.length - 1);
        this.playerData.attributes.positions.string = selectedPositionsString;
        this.playerData.attributes.positions.array = selectedPositions;
      },

      /**
       * Closes the vue-multiselect component and triggers the @close="change()" method
       */
      confirmSelectedYears (event) {
        // note: this is a workaround to close the vue-multiselect component by clicking the confirm button
        // if the user clicks the confirm selection button,
        // removes focus from any focused element (i.e the vue-multiselect component)
        // setting off the onBlur event of element, thus triggering the close method call for component
        // that initiates the change() method
        if (document.activeElement) {
              document.activeElement.blur();
        }
      },

      /**
      * Reset Multiselected years
      */
      clearSelectedYears() {
         this.selectedPositions = [];
      },
      handleOnHeightChange(){
        // update height values feet and inch
        const feet = parseInt(this.playerData.attributes.height.feet);
        const inch = parseFloat(this.playerData.attributes.height.inch);
        this.playerData.attributes.height.feet = (feet > 0) ? feet : '';
        this.playerData.attributes.height.inch = (inch >= 0) ? inch : '';

        // validate player
        const errors = this.playerValidator(this.playerData);

        // if height values are valid, then set totalInches and height string
        // else set them to an empty string
        if(typeof errors.height === 'undefined' && this.playerData.attributes.height.inch !== ''){

          // if inches being 12 or higher, then increment feet by one and set inch to zero
          if(this.playerData.attributes.height.inch >= 12){
            this.playerData.attributes.height.inch = 0;
            this.playerData.attributes.height.feet += 1;
          }
          else{
            this.playerData.attributes.height.inch = inch;
          }

          this.playerData.attributes.height.totalInches = (parseFloat(this.playerData.attributes.height.feet*12)+parseFloat(this.playerData.attributes.height.inch));
          this.playerData.attributes.height.string = this.playerData.attributes.height.feet+'\' '+this.playerData.attributes.height.inch+'\"';
        }
        else{
          this.playerData.attributes.height.totalInches = '';
          this.playerData.attributes.height.string = '';
        }
      },
      handleOnCellphoneChange(){
        // validate player
        const errors = this.playerValidator(this.playerData);

        // if cellphone values are valid, then set cellphone, string, and isValid to true
        // else set them to empty string and isValid to false
        if(typeof errors.cellphone === 'undefined'){
          this.playerData.attributes.cellphone.cellphone = this.playerData.attributes.cellphone.areacode+this.playerData.attributes.cellphone.exchangecode+this.playerData.attributes.cellphone.extensioncode;

          // if cellphone is not empty, then set string and isValid to true
          // else set string to empty and isValid to false
          if(this.playerData.attributes.cellphone.cellphone !== ''){
            this.playerData.attributes.cellphone.string = '('+this.playerData.attributes.cellphone.areacode+') '+this.playerData.attributes.cellphone.exchangecode+'-'+this.playerData.attributes.cellphone.extensioncode;
            this.playerData.attributes.cellphone.isValid = true;
          }
          else{
            this.playerData.attributes.cellphone.string = '';
            this.playerData.attributes.cellphone.isValid = false;
          }
        }
        else{
          this.playerData.attributes.cellphone.string = '';
          this.playerData.attributes.cellphone.cellphone = '';
          this.playerData.attributes.cellphone.isValid = false;
        }
      },
      handleOnWeightChange(){
        const weight = parseFloat(this.playerData.attributes.weight);
        this.playerData.attributes.weight = (weight > 0) ? weight.toFixed(2) : ''
      },
      toggleInterfaceMode(editMode=null){
        this.interfaceMode = !this.interfaceMode;

        // if interface mode just went from editorMode to acceptedMode
        // then save playerData
        if(this.interfaceMode === ACCEPTED_MODE){
          this.save_player_data();
        }
        else{
          // this will put player in editingMode
          window.EventBus.publish('sds_update_player_editingMode', {
            index: this.index,
            interfaceMode: this.interfaceMode
          });
        }
      },
      emailValidationResponseHandler(playerData){
        // update player data related to players email
        this.playerData.attributes.email = playerData.attributes.email;
        this.playerData.config.isMember = playerData.config.isMember;
        this.playerData.config.isEmailValid = playerData.config.isEmailValid;

        // deep copy current errors
        var errors = JSON.parse(JSON.stringify(this.errors));

        // if prospect screen, then validate email/cellophone 'either requirement'
        // else roster screen, then validate email requirement
        if(this.screen === PROSPECTS_SCREEN){
          // if email is valid and cellphone is NOT
          if(playerData.config.isEmailValid && playerData.config.phoneEmail.isValid === false){
            delete errors['email'];

            if(!(playerData.attributes.cellphone.cellphone.length > 0))
              delete errors['cellphone'];

            this.playerData.config.isMember = playerData.config.isMember;
            this.playerData.config.isEmailValid = playerData.config.isEmailValid;

            this.requiredFields['cellphone'] = false;
          }
          // if email is valid and cellphone is valid
          else if(playerData.config.isEmailValid && playerData.config.phoneEmail.isValid === true){
            delete errors['email'];
            delete errors['cellphone'];

            this.playerData.config.isMember = playerData.config.isMember;
            this.playerData.config.isEmailValid = playerData.config.isEmailValid;

            this.requiredFields['cellphone'] = false;
          }
          // if email is NOT valid and cellphone is NOT
          else if(!playerData.config.isEmailValid && playerData.config.phoneEmail.isValid === false){
            const message = "A valid email OR cellphone is required.";
            errors['email'] = message;

            if(this.errors.cellphone){
              errors['cellphone'] = this.errors.cellphone;
            }
            else{
              errors['cellphone'] = message;
            }

            this.playerData.config.isMember = playerData.config.isMember;
            this.playerData.config.isEmailValid = playerData.config.isEmailValid;

            this.requiredFields['email'] = true;
            this.requiredFields['cellphone'] = true;
          }
          // if email is NOT valid and cellphone is valid
          else if(!playerData.config.isEmailValid && playerData.config.phoneEmail.isValid === true){
            // if email is empty string, remove email error AND use phoneEmail validation data
            // else, add email error AND use email validation data
            if(playerData.attributes.email.length === 0 || playerData.attributes.email === ''){
              delete errors['email'];
              this.requiredFields['email'] = false;

              this.playerData.config.isMember = playerData.config.phoneEmail.isMember;
              this.playerData.config.isEmailValid = playerData.config.phoneEmail.isValid;
            }
            else{
              errors['email'] = "Must be a valid email address.";
              this.requiredFields['email'] = false;

              this.playerData.config.isMember = playerData.config.isMember;
              this.playerData.config.isEmailValid = playerData.config.isEmailValid;
            }

            delete errors['cellphone'];
          }
        }
        else{
          if(this.playerData.config.isEmailValid){
            delete errors['email'];
          }
          else{
            errors['email'] = "Valid email address required.";
          }
        }

        // if player is a new row, then supress error message for first time
        // else, toggle error display mode depending on value of errors
        if(playerData.id === 'new'){
          delete errors['email'];
          delete errors['cellphone'];
        }
        else{
          this.togglePlayerErrorDisplayMode(errors);
        }

        // save player's current editingMode
        this.playerData.config.isEditorMode = this.interfaceMode;

        // save player data
        window.EventBus.publish('sds_save_player_data', {
          index: this.index,
          playerData: this.playerData
        });
      },
      save_player_data(){
        // validate player
        const errors = this.playerValidator(this.playerData, this.emailValidationResponseHandler);

        this.togglePlayerErrorDisplayMode(errors);

        // save player's current editingMode
        this.playerData.config.isEditorMode = this.interfaceMode;

        // // save player data
        window.EventBus.publish('sds_save_player_data', {
          index: this.index,
          playerData: this.playerData
        });
      },
      togglePlayerErrorDisplayMode(errors){
        // if there are no errors then set player as valid and toggle interface to saved
        // else if player is new, then initialize player by: set player id, clear any errors, and set interface to editorMode
        // else player is invalid, then set player as invalid, set errors, and set interface to editorMode
        if($.isEmptyObject(errors)){
          this.playerData.config.isValid = true;
          this.errors = {};
          this.interfaceMode = ACCEPTED_MODE;
        }
        else if(this.playerData.id === 'new'){
          this.playerData.id = this.index;
          this.errors = {};
          this.interfaceMode = EDITOR_MODE;
        }
        else{
          this.playerData.config.isValid = false;
          this.errors = errors;
          this.interfaceMode = EDITOR_MODE;
        }
      },
      flushOutErrorsOnLoad(){
        this.save_player_data();

        // trigger heightChange so that it rounds the decimal to nearest quarter
        if(typeof this.playerData.attributes.height !== 'undefined')
          this.handleOnHeightChange();
      },
      initalizeSportPositionsData(){
        // set positions displayed in dropdown options
        this.positions = this.playerData.config.sport.positions;

        // set players postions scanned from file
        this.selectedPositions = this.playerData.config.mappedPositions.selectedPositions;

        // show any position errors from the scanned file
        const initialize = true;
        this.handleOnPositionChange(initialize);
        this.showPositionsError = true;
      },
      remove_player_data(){
        window.EventBus.publish('sds_remove_player_data', {index: this.index});
      },
    },

    created() {
      window.EventBus.subscribe('sds_batch_save_players', () => {
        this.save_player_data();
      });
    },

    mounted() {
      this.flushOutErrorsOnLoad();
      this.initalizeSportPositionsData();
    },
  }
</script>

<style>
  th {
    text-align: left;
  }

  td {
    text-align: left;
    vertical-align: middle !important;
  }

  .align-text-right {
    text-align: right;
  }

  .align-text-center {
    text-align: center;
  }

  .text-red {
    color: red !important;
  }

  .player-table-column {
    vertical-align: top !important;
  }

  .player-import-input {
    /*border: solid 1px #7ccafb;*/
  }

  .player-import-input::-webkit-input-placeholder { /* Edge */
    color: red !important;
    font-style: italic !important;
    text-align: center;
    vertical-align: middle;
    font-size: 0.85rem;
  }

  .player-import-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: red !important;
    font-style: italic !important;
    text-align: center;
    vertical-align: middle;
    font-size: 0.85rem;
  }

  .player-import-input::placeholder {
    color: #4682B4 !important;
    font-style: italic !important;
    text-align: left;
    vertical-align: middle;
    font-size: 0.85rem;
  }

  .playerData-text {
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
  }

  /* CSS for Multi-Selector */

    #player_positions_select {
    cursor: pointer;
    padding: 0px;
    font-size: 14px;
    display: inline-block;
    color: #212529;
  }

  #player_positions_select .select_action_btn .fa-trash-o {
    color: red;
    font-size: larger;
  }

  #player_positions_select .select_action_btn .fa-check {
    color: green;
    font-size: larger;
  }

  #player_positions_select .multiselect__tags::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
  }
  #player_positions_select .multiselect__single {
    text-transform: uppercase;
    font-size: 14px;
    color: #212529;
    background: transparent;
    padding-right: 20px;
  }
  #player_positions_select .multiselect__placeholder {
    text-transform: uppercase;
    font-size: 14px;
    color: #212529;
    padding-top: 0px;
  }
  #player_positions_select .multiselect {
    font-size: 14px;
    color: #aaa;
  }
  #player_positions_select .multiselect__tags {
    padding: 0px;
    background: transparent;
    border: none;
    color: #212529;
  }
  #player_positions_select .multiselect__content {
    background-color: #333;
    border: none;
  }
  #player_positions_select .multiselect__option--highlight {
    background-color: white;
    color: black
  }
  #player_positions_select .multiselect__content-wrapper {
    position: absolute;
    border: none;
    border-radius: 3px;
    left: -100px;
    float: left;
    width: 330px;
  }
  #player_positions_select .multiselect__option--highlight {
    background-color: #282828;
    color: #fff;
  }
  #player_positions_select .multiselect__option--highlight.multiselect__option--selected {
    background-color: #282828;
  }
  #player_positions_select .multiselect__option--selected {
    background-color: #282828;
    color: #E7F816;
  }

</style>
