import SportPlayer from "./SportPlayer.js";
import store from "../../store";

export default () => {
    const pathname = window.location.pathname.split('/')[1];

    Vue.prototype.$SportPlayer = new SportPlayer();

    $.ajaxSetup({
        headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
        }
    });

  window.SDSPusher.getPusherInfo().then(() => {
        const authId = $("#authId").val();
        window.SDSPusher.createPusherChannel(
            "/api/user/authorize-pusher",
            `private-user_channel-${authId}`
        );
    window.SDSPusher.bindChannelEvent("hs_player_import", (response) => {
        var successMessage = "PLAYER IMPORT COMPLETED SUCCESSFULLY!";
        var playerImportMessage = "Number of players imported: "+response.successCount+" out of "+response.totalCount;

        var saveMode = false;
        window.EventBus.publish('sds_toggleImportMode', {saveMode: saveMode});

        Swal.fire({
            title: successMessage,
            text: playerImportMessage,
            type: "success",
            timer: 4500,
        });
    });
    window.SDSPusher.bindChannelEvent("hs_player_roster_import", (response) => {
        var successMessage = "PLAYER IMPORT COMPLETED SUCCESSFULLY!";
        var playerImportMessage = response.successCount ? "Number of players imported: "+response.successCount+" out of "+response.totalCount : "";
        var playerTransferMessage = response.transferCount ? "<br/>Number of transfer requests sent: "+response.transferCount+" out of "+response.totalCount : "";
        var playerPendingTransferMessage = response.pendingTransferCount ? "<br/>Number of duplicate transfer requests: "+response.pendingTransferCount+" out of "+response.totalCount : "";

        var saveMode = false;
        window.EventBus.publish('sds_toggleImportMode', {saveMode: saveMode});

        Swal.fire({
            title: successMessage,
            html: playerImportMessage + playerTransferMessage + playerPendingTransferMessage,
            type: "success",
            timer: 4500,
        });
    });
    window.SDSPusher.bindChannelEvent("mass_message", () => {
        window.vueInstance.$emit('fetchUserMessage');
        toastr.success("", "Mass message sent successfully!");
    });
    });
  window.deleteRecruitFromRoster = function (id, e) {
    Swal.fire({
        title: `Remove from ${
            pathname === 'roster' ? "Roster" : "Prospects"
        } list?`,
        text: `This will remove the player and any attributes associated with the player from your ${
            pathname === 'roster' ? "roster" : "prospects"
        } list.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, remove player!",
        cancelButtonText: "No, cancel!",
        confirmButtonClass: "btn btn-confirm mt-2",
        cancelButtonClass: "btn btn-cancel ml-2 mt-2",
        reverseButtons: true
    }).then(result => {
        if (result.value) {
            $.ajax({
                url: `/roster/delete/${id}?roster=${pathname === 'roster'}`,
                type: "DELETE"
            }).done(d => {
              // d is the id of the record deleted
                if (d > 0) {
                    window.vueInstance.$refs['roster-screen'].$refs['roster-table'].silentRefresh();
                } else {
                  // ajax error
                    Swal.fire({
                        title: "Error!",
                        text:
                        "There was an error removing the player from your roster list.",
                        type: "error"
                    });
                }
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
    });
  };
    window.updateOffer = async function (id, data, offered) {
        if (!offered) {
            return await bootbox.prompt({
                title: "Choose Player's Position To Offer",
                inputType: "select",
                inputOptions: data.split(",").map(v => ({ text: v, value: v })),
                value: data.split(",")[0],
                async callback(result) {
                    if (result !== null) {
                        await axios
                        .get(`/api/players/${id}/toggle-offer?position=${result}`)
                        .then(response => {
                            window.vueInstance.$refs['roster-screen'].$refs['roster-table'].silentRefresh();
                            window.vueInstance.$children[0].setStats();
                        });
                    }
                }
            });
        } else {
            return await axios.get(`/api/players/${id}/toggle-offer`).then(response => {
                window.vueInstance.$refs['roster-screen'].$refs['roster-table'].silentRefresh();
                window.vueInstance.$children[0].setStats();
            });
        }
    };
    window.updateInterest = async(id, interest) => axios.get(`/api/players/${id}/update-interest/${interest}`)
    window.clearInterest = async id => axios.get(`/api/players/${id}/clear-interest`)
    window.updateStatus = async(id, type) => axios.get(`/api/players/${id}/update-status/${type}`)
    window.clearStatus = async id => axios.get(`/api/players/${id}/clear-status`)

    function setSelect2Settings()
    {
        $("#player_position").select2();
        $("#grad_year_search").select2();
    }

  /* EXPORT TO CSV FUNCTION
  * prepCSVRow is taken from Stackoverflow - https://stackoverflow.com/questions/40428850/how-to-export-data-from-table-to-csv-file-using-jquery
  * Convert data array to CSV string
  * @param arr {Array} - the actual data
  * @param columnCount {Number} - the amount to split the data into columns
  * @param initial {String} - initial string to append to CSV string
  * return {String} - ready CSV string
  */
    function prepCSVRow(arr, columnCount, initial)
    {
        var row = ""; // this will hold data
        var delimeter = ","; // data slice separator, in excel it's `;`, in usual CSv it's `,`
        var newLine = "\r\n"; // newline separator for CSV row

      /*
      * Convert [1,2,3,4] into [[1,2], [3,4]] while count is 2
      * @param _arr {Array} - the actual array to split
      * @param _count {Number} - the amount to split
      * return {Array} - splitted array
      */
        function splitArray(_arr, _count)
        {
            var splitted = [];
            var result = [];
            _arr.forEach(function (item, idx) {
                if ((idx + 1) % _count === 0) {
                    splitted.push(item);
                    result.push(splitted);
                    splitted = [];
                } else {
                    splitted.push(item);
                }
            });
            return result;
        }
        var plainArr = splitArray(arr, columnCount);
      // don't know how to explain this
      // you just have to like follow the code
      // and you understand, it's pretty simple
      // it converts `['a', 'b', 'c']` to `a,b,c` string
        plainArr.forEach(function (arrItem) {
            arrItem.forEach(function (item, idx) {
                row += item + (idx + 1 === arrItem.length ? "" : delimeter);
            });
            row += newLine;
        });
        return initial + row;
    }

    $(document).ready(() => {
        $("body").tooltip({ selector: "[data-toggle=tooltip]" });

        function sortNumbersIgnoreText(a, b, high)
        {
            const reg = /[+-]?((\d+(\.\d*)?)|\.\d+)([eE][+-]?[0-9]+)?/;

          // Removes everything before the #
          // the function parses the 3 from <h3> as the
          // number without these two lines of code.
            a = a.substring(a.indexOf("#"), a.length);
            b = b.substring(b.indexOf("#"), b.length);

            a = a.match(reg);
            a = a !== null ? parseInt(a[0]) : high;
            b = b.match(reg);
            b = b !== null ? parseInt(b[0]) : high;
            return a < b ? -1 : a > b ? 1 : 0;
        }

        jQuery.extend(jQuery.fn.dataTableExt.oSort, {
            "sort-numbers-ignore-text-asc": function (a, b) {
                return sortNumbersIgnoreText(a, b, Number.POSITIVE_INFINITY);
            },
            "sort-numbers-ignore-text-desc": function (a, b) {
                return sortNumbersIgnoreText(a, b, Number.NEGATIVE_INFINITY) * -1;
            }
        });

    // default filter
    let advanced_search = {};
    if (Cookies.get("advanced_search") != null) {
        advanced_search = JSON.parse(Cookies.get("advanced_search"));
    } else {
        advanced_search = {
            position: "",
            grad_year: ""
        };
    }

    const defaultRosterPosition = advanced_search.position;
    const defaultRosterYear = advanced_search.grad_year;

    const positionNotNull =
      defaultRosterPosition != undefined &&
      defaultRosterPosition != null &&
      defaultRosterPosition != "";

    const yearNotNull =
      defaultRosterYear != undefined &&
      defaultRosterYear != null &&
      defaultRosterYear != "";

    setSelect2Settings();
    $("#roster-table_filter").hide();

    // Hide default search so we can use our own
    $("#roster-table_wrapper > div:first-child").hide();

    $("#roster-table_info").hide();

    // order event

    // Row reorder event

    //Team Roster Tools
    $("[export-roster]").click(() => {
        if (!$("#players-to-export").hasClass("show")) {
            $("#player-export-list tbody").empty();
            $("[accept-export]")
            .text("Accept")
            .prop("disabled", false);
        }
        axios
        .get(
            `/api/organizations/${$("[organizationId]").attr(
                "organizationId"
            )}/export-roster?datatable=true`
        )
        .then(result => {
            const sportName = store.state.user.current_sport;
            result.data.data.forEach((player, index) => {
                $(`<tr>
                <td>${player.first_name}</td>
                <td>${player.last_name}</td>
                <td>${positionsFunction(player.profile)}</td>
                <td>${player.profile[`${sportName}_profile`].jersey_num}</td>
                <td>${player.profile.height}</td>
                <td>${player.profile.weight}</td>
                <td>${player.profile.twitter}</td>
                <td>${player.profile.phone}</td>
                <td>${player.email}</td>
                </tr>`).appendTo("#player-export-list tbody");
            });
        });
      $("#players-to-export").modal();
    });

    function positionsFunction(profile)
    {
        const sportName = store.state.user.current_sport;
        if (sportName == "football" && profile.football_profile != null) {
            return profile.football_profile.positions.map(p => p.shortcode).join("/")
        } else if (sportName == "baseball" && profile.baseball_profile != null) {
            return profile.baseball_profile.positions.map(p => p.shortcode).join("/")
        } else if (sportName == "softball" && profile.softball_profile != null) {
            return profile.softball_profile.positions.map(p => p.shortcode).join("/")
        } else {
            return "";
        }
    }

    $("[decline-export]").on("click", () => {
        $("#player-export-list tbody").empty();
        $("[accept-export]")
        .text("Accept")
        .prop("disabled", false);
      });

    $("[accept-export]").on("click", function () {
        $(this)
        .text("Exporting...")
        .prop("disabled", true);

      /* EXPORT TO CSV SET UP
      * CSV Converting is taken from Stackoverflow - https://stackoverflow.com/questions/40428850/how-to-export-data-from-table-to-csv-file-using-jquery
      */
        var titles = [];
        var data = [];

      /*
      * Get the table headers, this will be CSV headers
      * The count of headers will be CSV string separator
      */
        $("#players-to-export th").each(function () {
            titles.push($(this).text());
        });

      /*
      * Get the actual data, this will contain all the data, in 1 array
      */
        $("#players-to-export td").each(function () {
            data.push($(this).text());
        });

      /*
      * Convert our data to CSV string
      */
        var CSVString = prepCSVRow(titles, titles.length, "");
        CSVString = prepCSVRow(data, titles.length, CSVString);

      /*
      * Make CSV downloadable
      */
        var downloadLink = document.createElement("a");
        var blob = new Blob(["\ufeff", CSVString]);
        var url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = "roster_export.csv";

      /*
      * Actually download CSV
      */
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        $("#players-to-export").modal('hide');
    });
    });
}
