<template>
<!--  <div class="btn btn-primary">-->
<!--    Invite Players-->
<!--    <input type="file" @change="fileChosen" accept=".xls, .xlsx, .csv" hidden>-->
<!--  </div>-->
  <div>
    <div class="dropdown m-r-5">
      <button class="btn btn-primary waves-effect waves-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Invite Players
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a href="imports/ExampleInviteImport.csv" download="InviteExample.csv" class="dropdown-item">Download Example Invite File</a>
        <label class="dropdown-item">
          Invite Players
          <input type="file" @change="fileChosen" accept=".xls, .xlsx, .csv" hidden>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InviteButton",
  methods: {
    fileChosen(event) {
      let file = event.target.files[0];
      this.$emit('file_chosen',file);
    }
  }
}
</script>

<style scoped lang="scss">
.btn {
  font-family: KairosSans,sans-serif;
  text-transform:uppercase;
}
</style>
