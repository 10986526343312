<template>
  <b-container fluid>
    <b-row class="header">
      <b-col sm="12" md="3" class="headerCol">Measurables</b-col>
      <b-col sm="12" md="3" class="headerCol">Testing</b-col>
      <b-col sm="12" md="3" class="headerCol">Academics</b-col>
      <b-col sm="12" md="3" class="headerCol" style="border-right:0;">Additional Info</b-col>
    </b-row>
    <b-row class="dataRow">
      <b-col sm="12" md="3" class="dataCol">
        <b-form-group
        >
          <b-form-checkbox-group
              v-model="measurables"
              :options="sportAttributes.measurable_options"
              name="measurables"
              stacked
          ></b-form-checkbox-group>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3" class="dataCol">
        <b-form-group
        >
          <b-form-checkbox-group
              v-model="testing"
              :options="sportAttributes.testing_options"
              name="testing"
              stacked
          ></b-form-checkbox-group>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3" class="dataCol">
        <b-form-group
        >
          <b-form-checkbox-group
              v-model="academics"
              :options="academic_options"
              name="academic"
              stacked
          ></b-form-checkbox-group>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3" class="dataCol" style="border:0;">
        <b-form-group
        >
          <b-form-checkbox-group
              v-model="additional"
              :options="additional_options"
              name="additional"
              stacked
          ></b-form-checkbox-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="invite-footer">
        <back-button @back="$emit('back')"></back-button>
        <button @click="submitInfo" class="btn btn-primary">Continue</button>
      </b-col>
    </b-row>

  </b-container>
</template>
<script>
import {BContainer, BRow, BCol,BFormGroup, BFormCheckboxGroup } from 'bootstrap-vue';
import BackButton from './BackButton'
import store from "../../../store";

export default {
  name: 'ProfileAttributePicker',
  components: {BContainer,BRow,BCol,BFormGroup, BFormCheckboxGroup, BackButton },
  data: () => ({
    sport: store.state.user.current_sport,
    measurables: [],
    testing: [],
    academics: [],
    additional: [],
    academic_options: [
      { text: 'GPA/Transcript',value: 'unweighted_gpa'},
      { text: 'ACT',value: 'act'},
      { text: 'SAT',value: 'sat'},
      { text: 'Desired Major',value: 'desired_major'},
      { text: 'NCAA ID',value: 'ncaa_id'}
    ],
    additional_options: [
      { text: 'Interview Videos',value: 'interview_videos'},
      { text: 'Player & Parent Contact Info', value: 'contact_info'},
      { text: 'Coach Info',value: 'coach_info'},
      { text: 'Social Media',value: 'social_media'}
    ],
    measurable_options: [],
    testing_options: [],
  }),
  methods: {
    submitInfo(){
      this.$emit('attributes_selected', this.selected);
    }
  },
  computed: {
    selected() {
      let options = [
      ...this.academic_options,
      ...this.additional_options,
      ...this.measurable_options,
      ...this.testing_options,
      ]

      let html_pretty_text = {};
      options.forEach(function(option){
        html_pretty_text[option.value] = option.text
      })

      return {
        measurables: this.measurables,
        testing: this.testing,
        academics: this.academics,
        additional: this.additional,
        html_pretty_text: html_pretty_text,
      }
    },
    sportAttributes() {
      //set attributes by sport
      switch(this.sport) {
        case 'football':
          this.additional_options = [
            { text: 'Pro Day videos', value: 'pro_day_videos' },
            ...this.additional_options,
          ]
          this.measurable_options = [
            { text: 'Height',value: 'height'},
            { text: 'Weight',value: 'weight'},
            { text: 'Wingspan',value: 'wing_span'},
            { text: 'Hand Size',value: 'hand_size'},
            { text: 'Positions',value: 'positions'},
            { text: 'Hudl Link', value: 'hudl_link'},
            ...this.measurable_options,
          ]
          this.testing_options = [
            { text: '40 Yard Dash',value: 'forty_yard_dash'},
            { text: '10 Yard Dash',value: 'ten_yard_dash'},
            { text: 'Shuttle',value: 'shuttle'},
            { text: 'L Drill',value: 'l_drill'},
            { text: 'Bench',value: 'bench'},
            { text: 'Squat',value: 'squat'},
            { text: 'Broad Jump',value: 'standing_broad_jump'},
            ...this.testing_options,
          ]
          break;
        case 'baseball':
          this.additional_options = [
            { text: 'Fundamentals', value: 'fundamentals' },
            ...this.additional_options,
          ]
          this.measurable_options = [
            { text: 'Height',value: 'height'},
            { text: 'Weight',value: 'weight'},
            { text: 'Bats',value: 'bats'},
            { text: 'Throws',value: 'throws'},
            ...this.measurable_options,
          ]
          this.testing_options = [
            { text: 'Home To First',value: 'home_to_first'},
            { text: '60 Yard Dash',value: '60_yard_dash'},
            { text: 'Strength',value: 'strength'},
            ...this.testing_options,
          ]
          break;
        case 'softball':
          this.additional_options = [
            { text: 'Fundamentals', value: 'fundamentals' },
            ...this.additional_options,
          ]
          this.measurable_options = [
            { text: 'Height',value: 'height'},
            { text: 'Bats',value: 'bats'},
            { text: 'Throws',value: 'Throws'},
            ...this.measurable_options,
          ]
          this.testing_options = [
            { text: 'Home To 1st',value: 'home_to_1st'},
            { text: 'Second To Home',value: '2nd_to_home'},
            { text: 'Strength',value: 'strength'},
            ...this.testing_options,
          ]
          break;
        default:
          this.additional_options = [
            ...this.additional_options,
          ]
          this.measurable_options = [
            ...this.measurable_options,
          ]
          this.testing_options = [
            ...this.testing_options,
          ]
          break;
      }

      return {
        additional_options: this.additional_options,
        measurable_options: this.measurable_options,
        testing_options: this.testing_options,
      }
    }
  }
}
</script>

<style scoped>
.header{
  background:black;
  color:white;
  border-top:5px solid #E7F818;
  border-radius:5px 5px 0px 0px;
  padding:15px 0px 15px 15px;
}
.headerCol{
  font-family: KairosSans,sans-serif;
  text-transform:uppercase;
  border-right:1px solid white;
  color:white;
}
.dataRow{
  background:white;
  margin-top:0;
  padding:35px 0px 35px 35px;
}
.dataCol{
  margin-top:0;
  font-family: KairosSans,sans-serif;
  border-right:1px solid #646464;
  padding:35px 0px 35px 35px;
  color:black;
}

.invite-footer {
  padding: 1rem 2rem;
  background: #f9f9f9;
  border-radius: 0 0 5px 5px;
  display: flex;
  justify-content: space-between;
}

.btn {
  font-family: KairosSans,sans-serif;
  text-transform:uppercase;
}
</style>
