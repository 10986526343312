<template>
  <div class="public-player-profile-uploads">
    <h5 class="section-title">uploads</h5>
    <Tabs
      :end-icon="ChevronUpIcon"
      :options="tabs"
      :selected-option="selectedTab"
      @onClick="setSelectedTab"
    />
    <MeasurablesPanel v-if="selectedTab === tabKeys.measurables"/>
    <AcademicsPanel v-if="selectedTab === tabKeys.academics"/>
    <TestingPanel v-if="selectedTab === tabKeys.testing"/>
    <PositionDrillsPanel v-if="selectedTab === tabKeys.positionDrills"/>
    <SluggerSciencePanel v-if="selectedTab === tabKeys.sluggerScience"/>
    <InterviewsPanel v-if="selectedTab === tabKeys.interviews"/>
  </div>
</template>

<script>
import Tabs from "@components/Tabs";
import ChevronUpIcon from "@components/svg/ChevronUpIcon";
import TestingPanel from "@components/PlayerProfile/PublicProfile/components/TestingPanel";
import InterviewsPanel from "@components/PlayerProfile/PublicProfile/components/InterviewsPanel";
import AcademicsPanel from "@components/PlayerProfile/PublicProfile/components/AcademicsPanel";
import MeasurablesPanel from "@components/PlayerProfile/PublicProfile/components/MeasurablesPanel";
import PositionDrillsPanel from "@components/PlayerProfile/PublicProfile/components/PositionDrillsPanel";
import SluggerSciencePanel from "@components/PlayerProfile/PublicProfile/components/SluggerSciencePanel";

export default {
  name: "PlayerStatsUploads",
  components: {
    Tabs,
    TestingPanel,
    ChevronUpIcon,
    AcademicsPanel,
    MeasurablesPanel,
    SluggerSciencePanel,
    PositionDrillsPanel,
    InterviewsPanel
  },
  inject: ['player'],
  data() {
    return {
      defaultTabs: {
        measurables: 'Measurables',
        academics: 'Academics',
        testing: 'Testing',
        positionDrills: 'Position Drills'
      },
      tabKeys: {
        measurables: 'measurables',
        academics: 'academics',
        testing: 'testing',
        positionDrills: 'positionDrills',
        sluggerScience: 'sluggerScience',
        interviews: 'interviews'
      },
      ChevronUpIcon,
      selectedTab: 'measurables'
    }
  },
  computed: {
    tabs() {
      if (this.player?.slugger_science !== null) {
        Object.assign(this.defaultTabs, {sluggerScience: 'Slugger Science'});
      }
      if (this.player?.interviews?.length > 0) {
        Object.assign(this.defaultTabs, {interviews: 'Interviews'});
      }
      return this.defaultTabs;
    }
  }
  ,
  methods: {
    setSelectedTab(tab) {
      this.selectedTab = tab;
    }
  }
}
</script>
