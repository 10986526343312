<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.2002 3.00098H15.2002V13.3343H2.2002V3.00098ZM3.2002 4.00098V5.17604L8.7002 7.62048L14.2002 5.17604V4.00098H3.2002ZM14.2002 6.27036L8.7002 8.7148L3.2002 6.27036V12.3343H14.2002V6.27036Z" fill="black"/>
  </svg>
</template>

<script>
  export default {
    name: 'EmailIcon',
  };
</script>
