<template>
  <div>
    <div class="row">
      <div class="col-8">
        <div class="p-20">
          <EventSchedule :events="mappedEvents" ref="event-schedule"/>
        </div>
      </div>
      <div class="col-4 align-self-end">
        <div class="p-20 mb-5">
          <div class="eventDisplay">
          </div>
        </div>
        <div class="p-20">
          <h3>Next Event</h3>
          <template v-if="upcomingEvents.length > 0">
            <event-card :date="nextEvent.date" :time="nextEvent.time" :location="nextEvent.location" :type="nextEvent.type" :next="true"/>
          </template>
          <template v-else>
            <h6>No upcoming events for player</h6>
          </template>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 p-20">
        <UpcomingEvents :events="upcomingEvents"/>
      </div>
    </div>
  </div>
</template>

<script>

import EventCard from './EventCard'
import moment from 'moment'
export default {
  name: 'Events',
  components: { EventCard },
  props: {
    userId: {type: Number, default: null, required: true}
  },
  computed: {
    sortedEvents: (v) => v.events?.sort(v.sortByDate),
    mappedEvents: (v) => v.sortedEvents.map(({ event_time_string: start, type: title, address: address, city: city, state: state, zip: zip, description: description }) => ({ start, title, address, city, state, zip, description })),
    nextEvent: (v) => v.computeNextEvent(v),
    upcomingEvents: (v) => v.sortedEvents.filter(v.filterPastEvents),
  },
  data() {
    return {
      events: []
    }
  },
  created() {
  },
  mounted() {
    this.fetchUserEventSchedule()
  },
  methods: {
    async fetchUserEventSchedule() {
      const { data: { data: events } } = await axios.get(`/api/game-schedule-events`, { params: { user_id: this.userId } })
      this.events = events
    },
    sortByDate(a, b) {
      return moment(a.event_time_string).unix() > moment(b.event_time_string).unix() ? 1 : -1
    },
    filterPastEvents(event) {
      const todayUnix = moment(new Date()).unix() * 1000
      const eventUnix = moment(event.event_time_string).unix() * 1000
      return eventUnix >= todayUnix
    },
    buildEventCardObj(event) {
      const dateSplits = event?.event_time_string?.split(' ')[0]?.split('-')
      const timeSplit = event?.event_time_string?.split(' ')[1]?.split(':')
      const date = new Date(new Date().setFullYear(dateSplits[0], dateSplits[1] - 1, dateSplits[2])).toLocaleDateString()
      const time = new Date(new Date().setHours(timeSplit[0], timeSplit[1], timeSplit[2])).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})
      const location = `${event.address}, ${event.city}, ${event.state}`
      return { date, time, location, type: event.type }
    },
    computeNextEvent(instance) {
      const next = instance.upcomingEvents[0]
      return instance.buildEventCardObj(next)
    },
  }
}
</script>

<style>

</style>
