<template>
  <data-tables-server id="CoachTable" :class="{'d-none': !visible}"
    :data="coaches"
    :total="count"
    :pagination-props="{
      pageSizes: [10, 25, 50, 100],
      background: true,
      layout: 'prev, pager, next, sizes, ->, total'
    }"
    class="table"
    @query-change="loadData"
  >
    <el-table-column label="Name" sort-by="name" sortable>
      <template slot-scope="{ row: { name } }">
        <span class="coach-name">{{ name }}</span>
      </template>
    </el-table-column>
    <el-table-column label="Email" sort-by="email" sortable>
      <template slot-scope="{ row: { email } }">{{ email }}</template>
    </el-table-column>
    <el-table-column label="Actions" v-if="!['player','coach'].includes(role)" align="right">
      <template slot-scope="{ row: { id } }">
        <a :href="userEditUrl + `/users/${id}/edit`" class="edit">
          <i class="mdi mdi-lead-pencil"></i>
        </a>
      </template>
    </el-table-column>
  </data-tables-server>
</template>
<script>
import DataTablesServer from '@components/ui/DataTablesServer'

export default {
  name: 'CoachesTable',
  components: { DataTablesServer },
  props: {
    role: {type: String, default: "", required: true},
    organizationId: {type: String, default: "", required: true},
    initiallyVisible: {type: Boolean, default() {return false}, required: false}
  },
  data() {
    return {
      visible: this.initiallyVisible,
      url: `/api/organizations/${this.organizationId}/coaches?dt=true`,
      previousQuery: null,
      apiData: null,
      loading: false,
      userEditUrl: `/organizations/${this.organizationId}`,
      sportname: null,
    }
  },
  computed: {
    coaches: v => _.get(v, 'apiData.data', []),
    count: v => _.get(v, 'apiData.meta.pagination.total', 0)
  },
  methods: {
    async loadData(query) {
      this.previousQuery = query
      this.loading = true
      const { page, pageSize: length } = query
      const sportname = this.sportname;
      const data = { page, length, sportname }

      const url = `${this.url}&${new URLSearchParams(data).toString()}`
      const response = await axios.get(url)
      this.apiData = response.data
      this.loading = false
    },
  }, 
  created() {
    window.EventBus.subscribe('sds_handleAdminSportTabChange', (data) => {
      this.sportname = data.sportname;
      this.loadData(this.previousQuery);
    });
  }
}
</script>

<style scoped>

</style>
