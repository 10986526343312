<template>
  <div class="lite-profile-banner">
    <h3 class="heading">Partial Profile</h3>
    <p class="body">This user’s profile is hidden because their subscription is currently inactive.</p>
  </div>
</template>

<script>
  export default {
    name: 'LiteProfileBanner',
  }
</script>

<style lang="scss" scoped>
  .lite-profile-banner {
    background-color: #000;
    border-radius: 4px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 1rem;

    .heading {
      font-family: 'KairosSans', 'sans-serif';
      font-size: 16px;
      font-weight: 700;
      color: #fff;
    }
    .body {
      font-family: 'DINPro';
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      margin-bottom: 0;
      text-align: center;
    }
  }
</style>
