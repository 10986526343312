<template>
  <ul v-if="isCollegeFootballCoach">
    <li>
      <a v-bind:href="recruitsUrl" class="waves-effect waves-primary">
        <i class="ti-agenda"></i><span>Search Recruits</span>
      </a>
    </li>
    <li v-if="showMyInvites">
      <a v-bind:href="myInvitesUrl" :class="returnActiveClass('my-invites')">
        <i class="ti-check-box"></i><span>My Invites</span>
      </a>
    </li>
    <li v-if="showVerifiedPlayers">
      <a v-bind:href="verifiedPlayersUrl" :class="returnActiveClass('verified')">
        <i class="ti-check-box"></i><span>Verified Players</span>
      </a>
    </li>
    <li>
      <a v-bind:href="prospectsUrl" :class="returnActiveClass('prospect')">
        <i class="ti-id-badge"></i><span>Prospects</span>
      </a>
    </li>
    <li>
      <a v-bind:href="bigBoardUrl" :class="returnActiveClass('board')">
        <i class="ti-blackboard"></i><span>{{ bigBoardText }}</span>
      </a>
    </li>
    <li>
      <a v-bind:href="filmRoomUrl" :class="returnActiveClass('film')">
        <i class="ti-video-clapper"></i><span>Film Room</span>
      </a>
    </li>
    <li>
      <a v-bind:href="messagesUrl" :class="returnActiveClass('messages')">
        <i class="mdi mdi-message-text"></i><span>Messages</span>
        <span v-if="(messageTotal > 0)" class="badge badge-pink noti-icon-badge">{{ messageTotal }}</span>
      </a>
    </li>
  </ul>
</template>

<script>
import OrgTypes from '@consts/organization-types'
import Sports from '@consts/sports'
import {mapGetters} from 'vuex'
export default  {
  name: 'CollegeMenu',
  props: {
    returnActiveClass: {type: function (){}},
    attributes: Object,
  },
  computed: {
    ...mapGetters(['user','messageTotal','isCoach']),
    isHigherEd: ({ attributes }) => attributes.organizationtype === OrgTypes.COLLEGE,
    recruitsUrl: ({ attributes }) => attributes.recruitsurl,
    myInvitesUrl: ({ attributes }) => attributes.myinvitesurl,
    verifiedPlayersUrl: ({ attributes }) => attributes.verifiedplayersurl,
    prospectsUrl: ({ attributes }) => attributes.prospectsurl,
    bigBoardUrl: ({ attributes }) => attributes.bigboardurl,
    filmRoomUrl: ({ attributes }) => attributes.filmroomurl,
    messagesUrl: ({ attributes }) => attributes.messagesurl,
    bigBoardText: ({ attributes }) => attributes.bigboardtext,
    showMyInvites: ({ attributes }) => attributes.myinvitesflag,
    showVerifiedPlayers: ({ attributes }) => attributes.verifiedplayersflag,
    isCollegeFootballCoach: function () {
      return this.isCoach && this.isHigherEd && this.user.current_sport === Sports.FOOTBALL
    },
  },
}
</script>
