<template>
  <div>
    <div class="top-container">
      <a class="back-to-index-anchor" href="/my-invites">
        <img alt="left arrow icon" class="back-to-index-img" src="/build/images/icon_small_black_left_arrow.svg">
        <span class="back-to-index-span">BACK TO MY INVITES OVERVIEW</span>
      </a>
    </div>
    <div class="header-container">
      <div class="title-wrapper">
        <p class="header-title">{{ name }}</p>
        <EditInviteSingleButton :key="listId" v-bind="{listId, name, summary}" />
      </div>
      <p v-if="summary" class="header-description">{{ summary }}</p>
    </div>
  </div>
</template>

<script>
  import EditInviteSingleButton from '@screens/myinvites/InviteListDetails/InviteListDetailsHeader/components/EditInviteSingleButton';

  export default {
    name: 'InviteListDetailsHeader',
    components: { EditInviteSingleButton },
    props: {
      listId: {type: Number, required: true},
      name: {type: String, required: true},
      summary: {type: String},
    },
  }
</script>

<style lang="scss" scoped>
.header-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  padding: 0 16px;
}

.title-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header-title {
  color: #000;
  font-family: KairosSans, sans-serif;
  font-size: 20px;
  line-height: 28px;
  text-transform: uppercase;
  margin-bottom: 0;
  margin-top: 0.25rem;
}

.header-description {
  color: #525252;
  font-size: 14px;
  font-family: 'DINPro', sans-serif;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;
  max-width: 800px;
  resize: none;
  width: 100%;
}

.top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 26.63px 0 36.13px 16px;
}

.back-to-index-anchor {
  display: flex;
  font-family: KairosSans, sans-serif;
  font-size: 14px;
  letter-spacing: 0.16px;
  line-height: 18px;
}

.back-to-index-img {
  height: 14px;
}

.back-to-index-span {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 0 0 9.29px;
}
</style>
