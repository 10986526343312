<template>
  <div>
    <data-tables-server id="RolesTable"
      :data="roles"
      :total="count"
      :pagination-props="{
        disabled: true
      }"
      class="table"
      @query-change="loadData"
    >
      <el-table-column v-if="!this.organizationId" label="Organization" sort-by="organization" sortable>
        <template slot-scope="{ row: { organization } }">{{ organization }}</template>
      </el-table-column>
      <el-table-column label="Sport" sort-by="sport" sortable>
        <template slot-scope="{ row: { sport } }">{{ sport }}</template>
      </el-table-column>
      <el-table-column label="Role" sort-by="role" sortable>
        <template slot-scope="{ row: { role } }">{{ role }}</template>
      </el-table-column>
      <!-- el-table-column label="Actions" v-if="!['player','coach'].includes(role)" align="right" needs guarding but might not do through prop-->      
      <el-table-column label="Actions" align="right">
        <template slot-scope="{ row }">
          <span @click="deleteRole(row.id)" id="remove-role"><i class="mdi mdi-delete-forever red"></i></span>
          <!-- add an edit role, changes only the role for the selected sport/role combination -->
        </template>
      </el-table-column>
    </data-tables-server>
    <button type="button" class="btn btn-primary waves-effect waves-light" @click="displayAddRole()">Add Role</button>
    <div class="row" style="margin-top: 10px;" v-show="this.addRoleFormVisible">
      <div v-if="!this.organizationId" class="col-6 col-sm-6 col-md-6 col-lg-6">
        <select id="add-role-organization" class="form-control form-control-sm" v-model="addOrganization"/>
      </div>
      <div v-if="!this.sportId" class="col-6 col-sm-6 col-md-6 col-lg-6">
        <select id="add-role-sport" class="form-control form-control-sm" v-model="addSport"/>
      </div>
      <div class="col-6 col-sm-6 col-md-6 col-lg-6">
        <select id="add-role-role" class="form-control form-control-sm" v-model="addRole"/>
      </div>
      <button v-if="!this.organizationId" type="button" class="btn btn-primary waves-effect waves-light" style="margin-left: 10px; margin-top: 10px;" @click="createNewUserRoleContext()">Create Role</button>
      <button v-if="this.organizationId" type="button" class="btn btn-primary waves-effect waves-light" style="margin-left: 10px; margin-top: 10px;" @click="createNewOrgUserRoleContext()">Create Role</button>
    </div>
  </div>
</template>

<script>
import legacyCode from './utils'
import DataTablesServer from '@components/ui/DataTablesServer'

export default {
  name: 'RolesTable',
  components: { DataTablesServer },
  props: {
    organizationId: {type: String, default: null, required: false},
    userId: {type: String, default: "", required: true},
    sportId: {type: Number, default: null, required: false}
  },
  data() {
    return {
      visible: true,
      userUrl: `/api/user/${this.userId}/user-role-context`,
      orgUserUrl: `/api/organization/${this.organizationId}/user/${this.userId}/user-role-context`,
      previousQuery: null,
      apiData: null,
      loading: false,
      addRoleFormVisible: false,
      addSport: this.sportId ?? '', 
      addRole: '',
      addOrganization: ''
    }
  },
  mounted() {
    legacyCode()
  },
  computed: {
    roles: v => _.get(v, 'apiData', []),
    count: v => _.get(v, 'apiData.meta.pagination.total', 0)
  },
  methods: {
    async loadData(query) {
      this.previousQuery = query
      this.loading = true
      const dataUrl = (this.organizationId ? this.orgUserUrl : this.userUrl) + (this.sportId ? '?sport=' + this.sportId : '');
      console.log(dataUrl);

      const response = await axios.get(dataUrl)
      this.apiData = response.data
      this.loading = false
    },
    deleteRole(id) {
      Swal.fire({
      title: 'Remove this users role?',
      text: 'This will remove the user role from the system.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, remove role!',
      cancelButtonText: 'No, cancel!',
      confirmButtonClass: 'btn btn-confirm mt-2',
      cancelButtonClass: 'btn btn-cancel ml-2 mt-2',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        $.ajax({
          url: `/api/delete-user-role-context/${id}`,
          type: 'DELETE',
        })
        .done((d) => {
          if (d.data.response = 'success') {
            window.location.reload();
            Swal.fire({
              title: 'Removed!',
              text: "The user's role has been removed.",
              type: 'success',
              timer: 1700,
            });
          } else {
            // ajax error
            Swal.fire({
              title: 'Error!',
              text: "There was an error removing the user's role.",
              type: 'error',
            });
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {}
    });
    },
    displayAddRole() {
      this.addRoleFormVisible = !this.addRoleFormVisible;
    },
    createNewOrgUserRoleContext() {
      if (this.addSport && this.addRole) {
        $.ajax({
          url: `/api/organization/${this.organizationId}/user/${this.userId}/create-user-role-context`,
          type: 'POST',
          data: {
            sport: this.addSport, 
            role: this.addRole
            }
        })
        .done((d) => {
          if (d.data.response = 'success') {
            window.location.reload();
            Swal.fire({
              title: 'Added!',
              text: "The user's role has been added.",
              type: 'success',
              timer: 1700, 
            })
          }
        })
      } else {
        Swal.fire({
          title: 'Both fields required',
          text: 'Sport and Role are required fields',
          type: 'warning',
        })
      }
    },
    createNewUserRoleContext() {
      if (this.addSport && this.addRole && this.addOrganization) {
        $.ajax({
          url: `/api/user/${this.userId}/create-user-role-context`,
          type: 'POST',
          data: {
            sport: this.addSport, 
            role: this.addRole,
            organization: this.addOrganization
            }
        })
        .done((d) => {
          if (d.data.response = 'success') {
            window.location.reload();
            Swal.fire({
              title: 'Added!',
              text: "The user's role has been added.",
              type: 'success',
              timer: 1700, 
            })
          }
        })
      } else {
        Swal.fire({
          title: 'All fields required',
          text: 'Sport, Role and Organization are required fields',
          type: 'warning',
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
