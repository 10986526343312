<template>
  <div>
    <advanced-search ref="advanced-search"
      :states="JSON.parse(states)"
      :majors="JSON.parse(majors)"
      :saved-searches="JSON.parse(savedSearches)"
      v-on:search="(payload) => advancedSearch(payload,1)"
    />
    <basic-search ref="basic-search" v-on:filter-change="handleFilterChange"/>
    <recruits-table ref="recruits-table"
      :sport="sport"
      :download-flag="downloadFlag"
    />
  </div>
</template>

<script>
import RecruitsTable from './RecruitsTable'
import AdvancedSearch from './AdvancedSearch'
import legacyCode from './utils'
import BasicSearch from './BasicSearch'

export default {
  name: 'Recruits',
  props: {
    states: {type: String, default: '', required: true},
    majors: {type: String, default: '', required: true},
    isFan: {type: String, default: false, required: true},
    savedSearches: {type: String, default: '', required: true},
    sport: {type: String, default: 'baseball', required: false},
    downloadFlag: String
  },
  components: { BasicSearch, RecruitsTable, AdvancedSearch },
  methods: {
    advancedSearch(payload, page) {
      this.$refs['recruits-table'].advancedSearch(payload, page);
      this.$refs['recruits-table'].resetCurrentPage(page);
    },
    handleFilterChange(filters) {
      this.$refs['recruits-table'].filters = filters
    }
  },
  mounted() {
    legacyCode()
  }
}
</script>

<style scoped>

</style>
