<template>
    <div id="health">
        <div class="bar-items" v-if="measurements.length > 0">
            <div class="time-period">
                <select name="time_period" class="select2" v-model="total_months" v-select2>
                    <option value="2">1 month</option>
                    <option value="4">3 months</option>
                    <option value="7">6 months</option>
                    <option value="13">1 year</option>
                </select>
            </div>
            <h3 class="exporter gray-text" @click="exportMeasurements()"><a><i class="fas fa-file-export"></i>Export</a></h3>
        </div>
        <div class="row" v-if="measurements.length > 0">
            <div class="col-12 col-lg-4 col-xl-2">
                <div class="row ml-0 mr-0">
                    <div class="col-12 col-sm-6 col-lg-12" style="margin-bottom: 15px; padding: 0px 20px;">
                        <select name="group" class="select2 select-measurements" v-model="measurement" v-select2>
                            <option v-for="mes in this.measurements" :value="mes.id" v-if="mes.type.unit !='note'">{{mes.name}}</option>
                        </select>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-12" style="margin-bottom: 15px; padding: 0px 20px;">
                        <select name="group" class="select2" v-model="group" v-select2>
                            <option value="">All</option>
                            <option value="Offense">Offense</option>
                            <option value="Defense">Defense</option>
                            <option value="Special Teams">Special Teams</option>
                        </select>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-12" style="margin-bottom: 15px; padding: 0px 20px;">
                        <select name="positions" class="select2" v-model="position" v-select2>
                            <option value="">All</option>
                            <option v-for="position in this.positions" :value="position.shortcode">{{position.shortcode}}</option>
                        </select>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-12" style="margin-bottom: 15px; padding: 0px 20px;">
                        <select name="grad_year" class="select2" v-model="grad_year" v-select2>
                            <option value="">All</option>
                            <option :value="getCohortValue()">SR</option>
                            <option :value="getCohortValue() + 1">JR</option>
                            <option :value="getCohortValue() + 2">SF</option>
                            <option :value="getCohortValue() + 3">FR</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-8 col-xl-10">
                <div class="col-12 pl-0 pr-0 canvas-box">
                    <p v-if="this.loading" class="chart-loader">Loading...</p>
                    <canvas id="chart" class="ct-chart ct-square"></canvas>
                </div>
                <div class="col-12 row ml-0 mr-0 pl-0 pr-0">
                    <div class="col-12 col-lg-6 col-xl-3 pl-0 pr-0 playerbox-outer" v-for="(player, index) in this.players">
                        <div class="card-box playerbox" :style="`border-bottom: 8px solid rgba(${colors[index].r + ',' + colors[index].g + ',' + colors[index].b + ',1'});`">
                            <div class="col-12">
                                <img :src="player.profile.data.photo" alt="user" class="rounded-circle">
                                <h2>{{ player.full_name }}</h2>
                                <div class="row mr-0 attributes-container">
                                    <h3 class="text-center attribute col-4">{{ player.profile.data.positions.data.slice(0,2).map(p => p.shortcode).join(', ') + (player.profile.data.positions.data.length > 2 ? ' ...'  : '') }}</h3>
                                    <h3 class="text-center attribute col-4">{{ player.profile.data.measurables.data.weight != null ? player.profile.data.measurables.data.weight : '-' }}</h3>
                                    <h3 class="text-center attribute col-4">{{ getCohortWithYear(player.profile.data.basics.data.grad_year) }}</h3>
                                </div>
                                <span class="remove-player" @click="removePlayer(index)"><i class="fa fa-close"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-3 pl-0 pr-0 playerbox-outer" v-for="player in (-1 * (this.players.length - 4))">
                        <a>
                            <div class="card-box playerbox add-player" data-dynamic-component="search-roster" data-modal-title="Search Player" data-props="">
                                <div class="col-12 text-container">
                                    <h2>+ Add Player</h2>
                                </div>
                            </div>
                        </a>
                    </div>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12" v-if="this.measurements.length == 0 && !this.loading">
                <p class="text-center">No measurements available</p>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {

        name: 'Health',

        props: {
            organization_color: String
        },

        data: function() {
          return {
            loading: true,
            positions: [],
            measurements: [],
            labels: [],
            health: [],
            total_months: 13,
            grad_year: "",
            position: '',
            group: "",
            measurement: 1,
            chart: false,
            created: false,
            players: [],
            colors: [
                '#333',
                '#999',
                '#993cd6'
            ]
          }
        },

        mounted() {
            this.getPositions()
        },

        watch: {
            total_months: function() {
                this.getHealth()
            },
            grad_year: function() {
                this.getHealth()
            },
            position: function() {
                this.getHealth()
            },
            group: function() {
                this.getHealth()
            },
            measurement: function() {
                this.getHealth()
            },
            players: function() {
                this.getHealth()
            }
        },

        methods: {
            getPositions() {
                axios.get('/api/positions').then((response) => {
                    this.positions = response.data.data
                    this.getMeasurements()
                }).catch((error) => {
                    console.log(error)
                })
            },

            getCohortValue() {
                return (new Date).getMonth() > 5
                  ? ((new Date).getFullYear() + 1)
                  : (new Date).getFullYear()
            },

            getMeasurements() {
                this.loading = true
                axios.get('/api/measurements').then((response) => {
                    this.measurements = response.data.data
                }).then(() => {
                    if (this.measurements.length > 0) {
                        this.measurement = this.measurements[0].id
                    }

                    $('.select2').select2();
                    this.convertColorsToHex()
                }).then(() => {
                    this.getHealth()
                }).catch((error) => {
                    console.log(error)
                })
            },

            getHealth() {
                this.loading = true
                axios.get('/api/health', {
                    params: this.getHealthParams()
                }).then((response) => {
                    this.health = response.data.data
                }).then(() => {
                    this.labels = this.getMonthLabels()
                }).then(() => {
                    this.createChart()
                    this.updateChart()
                }).then(() => {
                    this.loading = false
                }).catch((error) => {
                    this.loading = false
                    console.log(error)
                })
            },

            getHealthParams() {
                let data = {
                    total_months: this.total_months,
                    measurement: this.measurement
                }

                if (this.grad_year != '') {
                    data.grad_year = this.grad_year
                }

                if (this.position != '') {
                    data.position = this.position
                }

                if (this.group != '') {
                    data.group = this.group
                }

                if (this.players.length > 0) {
                    data.player_ids = this.players.map(player => player.id)
                }

                return data
            },

            removePlayer(index) {
                this.players.splice(index, 1)
                if (this.chart.data.datasets.length > 1) {
                    this.chart.data.datasets.splice(index, 1)
                }
            },

            getChartGradient(color) {
                let canvasWidth = parseInt($('#chart').width())
                let canvasHeight = parseInt($('#chart').height())
                let gradient = $('#chart')[0].getContext('2d').createLinearGradient(0,0,canvasWidth, (canvasHeight * 2 ))
                gradient.addColorStop(0, `rgba(${color.r},${color.g},${color.b},.9)`)
                gradient.addColorStop(1, 'rgba(255,255,255,0)')

                return gradient
            },

            getMonthLabels() {
                let labels = []
                for(let count = 1; count <= parseInt(this.total_months); count++)
                {
                    let date = new Date()
                    date.setMonth((date.getMonth() - parseInt(this.total_months)) + count)
                    labels.push(date.toLocaleString('default', {month: 'long'}))
                }

                return labels
            },

            getExportData() {
                let data = $('.select-measurements').select2('data')[0].text + ',\n'
                if (this.players.length > 0) {
                    this.players.forEach((player, index) => {
                        data += player.full_name + ',' + this.health[index].join(',') + ',\n'
                    })
                } else {
                    data += this.health[0].join(',') + ',\n'
                }
                return data
            },

            exportMeasurements() {
                const file = new Blob([this.getExportData()], { type: 'text/csv' });
                const event = new MouseEvent('click', {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                    clientX: 20,
                });

                $('<a data-display-none />').attr({
                    href: `${window.URL.createObjectURL(file)}`,
                    download: `Measurements`,
                })[0].dispatchEvent(event);
            },

            getDataset(color, data = [0,0,0]) {
                return {
                    label: '',
                    fill: true,
                    lineTension: 0,
                    data: data,
                    borderWidth: 3,
                    backgroundColor: this.getChartGradient(color),
                    pointBackgroundColor: `rgba(${color.r},${color.g},${color.b},1)`,
                    pointBorderColor: `rgba(${color.r},${color.g},${color.b},1)`,
                    borderColor: `rgba(${color.r},${color.g},${color.b},1)`
                }
            },

            updateSets() {
                let self = this
                this.health.forEach((set, index) => {
                    if (index > self.chart.data.datasets.length - 1) {
                        self.chart.data.datasets.push(self.getDataset(self.colors[index], set))
                    } else {
                        self.chart.data.datasets[index] = self.getDataset(self.colors[index], set)
                    }
                })
            },

            updateChart() {
                let self = this
                this.chart.data.labels = this.labels
                this.chart.data.datasets.points = this.labels
                this.updateSets()
                this.chart.update()
            },

            resizeChart() {
                let chart = $('#chart')[0].getContext('2d')
                chart.canvas.height = chart.canvas.width
                this.updateSets()
                this.chart.update()
            },

            createChart() {
                let self = this
                if (! this.created) {
                    let chart = $('#chart')[0].getContext('2d')
                    chart.canvas.height = chart.canvas.width
                    this.chart = new Chart($('#chart')[0], {
                        type: 'line',
                        data: {
                            labels: [50,50,50],
                            datasets: [self.getDataset(self.colors[0])]
                        },
                        options: {
                            animation: false,
                            elements: {
                                spanGaps: true,
                                point: {
                                    radius: 0
                                },
                                line: {
                                    tension: 0,
                                    fill: false,
                                    stepped: false,
                                    borderDash: []
                                }
                            },
                            responsive: true,
                            maintainAspectRatio: true,
                            onResize: function() {
                                self.resizeChart()
                        },
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    display:true,
                                },
                                ticks: {
                                    display: true
                                }
                            }],
                            yAxes: [{
                                ticks: {
                                    display: true
                                },
                                gridLines: {
                                    display:true,
                                    drawBorder: false
                                }
                            }]
                        },

                        tooltips: {
                            yAlign: 'bottom',
                            xAlign: 'center',
                            yPadding: 40,
                            xPadding: 40,
                            borderColor: 'rgba(0,0,0,.5)',
                            borderWidth: 1,
                            bodyFontFamily: 'KairosSans',
                            callbacks: {
                            labelColor: function(tooltipItem, chart) {
                              return {
                                backgroundColor: '#fff'
                              };
                            },
                            labelTextColor: function(tooltipItem, chart) {
                              return '#000';
                            },
                            title: function() {}
                          },
                          backgroundColor: '#fff',
                          displayColors: false
                        },
                        legend: {
                          labels: {
                            boxWidth: 0,
                            display: false
                          }
                        }
                      }
                    })
                    this.created = true
                }

            },

            convertColorsToHex() {
                this.colors.unshift(this.organization_color)
                for (let x = 0; x < this.colors.length; x++) {
                    this.colors[x] = this.hexToRgb(this.colors[x])
                }
            },

            hexToRgb(hex) {
              var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
              hex = hex.replace(shorthandRegex, function(m, r, g, b) {
                return r + r + g + g + b + b;
              });

              var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
              return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
              } : null;
            },

            getCohortWithYear(year) {
                let date = (new Date).getMonth() > 5
                  ? (parseInt((new Date).getFullYear()) + 1)
                  : parseInt((new Date).getFullYear())

                switch (parseInt(year)) {
                    case date:
                        return 'SR';
                        break;
                    case date + 1:
                        return 'JR';
                        break;
                    case date + 2:
                        return 'SO';
                        break;
                    case date + 3:
                        return 'FR';
                        break;
                }

                if (year > date + 4) {
                    return 'FR';
                } else {
                    return 'SR';
                }
            }
        }
    }
</script>
