<template>
  <fragment>
    <div :class="`modal-background ${isOpen ? 'modal-background--open ' : ''}`" @click="$emit('onClose')" />
    <div class="video-player-container">
      <IconButton class="modal-close" @onClick="$emit('onClose')" title="Close Modal">
        <CloseIcon />
      </IconButton>
      <h4 v-if="videoTitle" class="modal-title">{{ videoTitle }}</h4>
      <video :src="videoUrl" ref="statVideoPlayer" controls :class="videoTitle ? 'has-title' : ''" />
    </div>
  </fragment>
</template>

<script>
import { Fragment } from 'vue-frag';
import IconButton from '@components/IconButton';
import CloseIcon from '@components/svg/CloseIcon';

export default {
  name: 'VideoPlayerModal',
  props: ['videoUrl', 'isOpen', 'videoTitle'],
  components: { Fragment, IconButton, CloseIcon },
  mounted() {
    this.$refs.statVideoPlayer.play();

    window.addEventListener('keydown', (event) => {
      if (this.isOpen && event.key === 'Escape') {
        this.$emit('onClose');
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener('keydown', (event) => {
      if (this.isOpen && event.key === 'Escape') {
        this.$emit('onClose');
      }
    });
  },
}
</script>
