<template>
  <tfoot v-if="resourceCount > 0">
    <tr>
      <td colspan="1000">
        <div>
          <div class="footer-sub-div">
            <h5 class="filtered-items-select">
              ITEMS PER PAGE: &nbsp;
              <select @change="onRetrieveResource(params)" v-model="params.limit" name="limit">
                <option value="10" :selected="params.limit === 10">10</option>
                <option value="50" :selected="params.limit === 50">50</option>
                <option value="100" :selected="params.limit === 100">100</option>
              </select>
            </h5>
            <h5 class="summary-of-items">
              {{ pagination.from }} – {{ pagination.to }} of {{ pagination.total }} {{ `ITEM${pagination.total > 1 ? 'S' : ''}` }}
            </h5>
          </div>
          <div class="footer-sub-div">
            <h5 class="filtered-pages-select">
              <select v-model="params.page" name="page" @change="onRetrieveResource(params.page)">
                <option v-for="page in pagination.last_page" :value="page" :selected="params.page === page">{{ page }}</option>
              </select>
              &nbsp;OF {{ pagination ? pagination.last_page : 1 }} {{ `PAGE${pagination.last_page > 1 ? 'S' : ''}` }}
            </h5>
            <button @click="updatePage(pagination.current_page - 1)" :disabled="pagination.current_page === 1" class="page-buttons">
              <img class="disabled-img" src="/build/images/icon_left_triangle.svg" alt="">
            </button>
            <button @click="updatePage(pagination.current_page + 1)" :disabled="pagination.current_page === pagination.last_page" class="page-buttons">
              <img class="disabled-img" src="/build/images/icon_right_triangle.svg" alt="">
            </button>
          </div>
        </div>
      </td>
    </tr>
  </tfoot>
</template>

<script>

export default {
  name: 'Pagination',
  props: {
    pagination: Object,
    onRetrieveResource: {type: function (){}},
    resourceCount: Number,
    colSpan: Number,
    params: Object
  },
  methods: {
    updatePage(page) {
      this.params.page = page;
      this.onRetrieveResource(this.params);
    }
  }
}
</script>

<style scoped lang="scss">
tfoot {
  border-bottom: transparent !important;
  border-top: 2px solid #dee2e6;

  tr {
    border-bottom: transparent !important;
    cursor: revert !important;

    td {
      padding: 0 0 0 1.75rem;
      text-align: start !important;

      div {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;

        .footer-sub-div {
          width: fit-content;
        }

        h5 {
          align-items: center;
          display: flex;
          height: 3rem;
          margin-bottom: 0;
        }

        select {
          background: transparent;
          border: 0;
          cursor: pointer;
          font-family: 'KairosSans', sans-serif;
          height: 3rem;
        }
      }

      .filtered-items-select {
        color: #161616;
        border-right: 2px solid #dee2e6;
        padding-right: 0.5rem;
      }

      .summary-of-items {
        color: #525252;
        padding-left: 1rem;
      }

      .filtered-pages-select {
        color: #161616;
        border-left: 2px solid #dee2e6;
        padding: 0 0.5rem;
      }

      .page-buttons {
        align-items: center;
        background: transparent;
        border: transparent;
        border-left: 2px solid #dee2e6;
        display: flex;
        color: #161616;
        height: 48px;
        padding: 20px;
        width: 48px;

        .disabled-img {
          cursor: revert;
        }
      }
    }
  }
}
</style>
