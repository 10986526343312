<template>
  <div class="d-flex justify-center w-full">
    <div class="row left-div">
      <div class="form-group col-md-4">Rows per page
        <select id="per_page" name="per_page" v-model="per_page" @change="changePageSize()">
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
      <div class="form-group col-md-8">
        <label for="page">Jump to page</label>
        <input id="page" name="page" class="small-input" @change="jumpPage()" type="number" min="1" :max="pagination.meta.last_page" v-model="pagination.meta.current_page" />
      </div>
    </div>
    <nav aria-label="Pagination">
      <template v-for="link in pagination.links">
        <button v-if="link.active" :key="link.label"
          class="px-3 py-1 border border-gray-300 text-gray-500 bg-white cursor-default"
          aria-current="page" v-html="link.label" />
        <button v-else @click="changePage(link.url)"
          class="px-3 py-1 border border-gray-300 text-gray-500 bg-gray hover:bg-gray-50"
          :disabled="!link.url"
          v-html="link.label" />
      </template>
    </nav>
    <div class="right-div" v-if="pagination.meta.total">{{ pagination.meta.total.toLocaleString() }} results</div>
  </div>
</template>

<script>
  export default {
    name: 'UsersListPaginator',
    inject: ['pagination', 'getUsers'],
    data() {
      return {
        per_page: 10
      }
    },
    watch: {
      pagination: {
        handler() {
          this.per_page = this.pagination.meta.per_page;
        }
      }
    },
    methods: {
      changePage(url) {
        this.getUsers(url);
      },
      changePageSize() {
        let url = new URLSearchParams(this.pagination.links.find(link => link.active === true).url);
        url.set('per_page', this.per_page);
        this.getUsers(decodeURIComponent(url.toString()));
      },
      jumpPage() {
        let url = new URLSearchParams(this.pagination.links.find(link => link.active === true).url);
        url.set('page', this.pagination.meta.current_page);
        this.getUsers(decodeURIComponent(url.toString()));
      }
    },
  }
</script>

<style scoped lang="scss">
.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.w-full {
  width: 100%;
}
.bg-gray {
  background-color: rgba(110, 110, 110, 0.075);
  :hover {
    background-color: #fff;
    background-color: rgba(110, 110, 110, 0.15);
  }
}
.border {
    border-width: 1px;
}
.border-gray-300 {
    --border-opacity: 1;
    border-color: #d1d5db;
    border-color: rgba(209, 213, 219, var(--border-opacity));
}
.px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.text-gray-500 {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity));
}
.cursor-default {
    cursor: default;
}
.left-div {
  flex: 1;
  text-align: left;
}
.right-div {
  flex: 1;
  text-align: right;
}
.small-input {
  width: 5rem;
}
</style>