<template>
  <td class="action-items-div">
    <!--    for 2.1 iteration -->
    <!--    <div class="action-buttons" @mouseenter="setHovered(invite.id)">-->
    <!--      <img class="trash-icon" src="/build/images/icon_trash.svg" alt="">-->
    <!--      <div class="popOver" v-if="hovered === invite.id" @mouseleave="closePopup">-->
    <!--        <div>-->
    <!--          <button @click="closePopup" class="cancel-button"><span>Cancel</span></button>-->
    <!--          <button class="archive-button"><span>Archive List</span></button>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="action-buttons" @mouseenter="setHoveredTwo(invite.id)">
      <img alt="" src="/build/images/icon_mail.svg">
      <div v-if="hoveredTwo === invite.id" class="popOver popOver-mail" @mouseleave="closePopup">
        <p>
          Send a mass message to every player on this invite list or select “View Invite List” to send individual messages to players.
        </p>
        <div>
          <button class="cancel-button" @click="openListInNewTab(invite.id)"><span>View Invite List</span></button>
          <button :disabled="isEmailSending" class="archive-button" @click="sendInviteToPlayersInList(invite)">
            <span>Send To All</span></button>
        </div>
      </div>
    </div>
  </td>
</template>

<script>
export default {
  name: 'ActionItems',
  props: {
    hovered: Number,
    setHovered: Function,
    hoveredTwo: Number,
    setHoveredTwo: Function,
    closePopup: Function,
    invite: Object,
  },
  data() {
    return {
      isEmailSending: false,
    }
  },
  methods: {
    openListInNewTab(id) {
      window.open(`/my-invites/${id}`)
    },
    sendInviteToPlayersInList(list) {
      this.isEmailSending = true;
      const controller = new AbortController();

      axios.post(`/api/player-invites/${list.id}/send-mass-invite`,
        [],
        {signal: controller.signal})
        .then(() => {
          this.toaster({
            type: 'success',
            heading: 'Success!',
            message: `You’ve just invited “${list.name}” to join the Signing Day Sports’ platform.`
          });
        }).catch((error) => {
          if (!axios.isCancel(error)) {
            this.toaster({
              type: 'error',
              heading: 'Error',
              message: 'Failed to send player invite.'
            });
          }
        }).finally(() => {
          this.isEmailSending = false;
        });
    }
  },
}
</script>

<style lang="scss" scoped>
.action-items-div {
  display: flex;
  justify-content: center;
}

.action-buttons {
  background: transparent;
  border: transparent;
  cursor: pointer;
  position: relative;
  width: fit-content;

  &:first-child {
    margin-right: 19px;
  }
}

.underlined {
  text-decoration: underline;
}

.popOver {
  background: #393939;
  border-radius: 2px;
  color: #fff;
  display: flex;
  flex-direction: column;
  left: 50%;
  padding: 16px 20.5px;
  position: absolute;
  text-align: start;
  top: 150%;
  transform: translateX(-50%);
  z-index: 5;

  div {
    display: flex;
  }

  button {
    background: transparent;
    border: 0;
    color: #fff;
    font-family: KairosSans, sans-serif;
    font-size: 14px;
    font-style: italic;
    line-height: 18px;
    text-transform: uppercase;
    white-space: nowrap;

    span {
      vertical-align: sub;
    }

    &:first-child {
      margin-right: 20px;
    }
  }

  .archive-button {
    background: #000;
    padding: 7px 11px;

  }

  &::after {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #393939;
    content: "";
    position: absolute;
    left: 45.75%;
    top: -11%;
  }
}

.popOver-mail {
  p {
    /* TODO: create global component so we don't have to keep calling the default font */
    font-family: "DINPro", sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.16px;
    line-height: 18px;
  }

  &::after {
    left: 46.75%;
    top: -5%;
  }
}
</style>
