<template>
  <div class="verified-player-wrapper">
<!--    TODO: Will implement in next release SDS-1406-->
<!--    <div class="btn-group btn-group-toggle" data-toggle="button">-->
<!--      <label class="btn" :class="{ currentPage: isListActive, firstJourney: (inviteCount < 1 || processingInvites)}" style="border-radius: 4px 0 0 4px !important;">-->
<!--        <input type="radio" name="options" id="listView" autocomplete="off" checked> List View-->
<!--      </label>-->
<!--      <label class="btn" :class="{ currentPage: isPlayerActive}" style="border-radius: 0 4px 4px 0 !important;">-->
<!--        <input type="radio" name="options" id="playerView" autocomplete="off" :disabled="(inviteCount < 1 || processingInvites) ? true : false"> Player View-->
<!--      </label>-->
<!--    </div>-->
    <div class="row title-container" style="margin-bottom: 24px;">
      <div class="col-lg-12 pl-0">
        <h3 class="mb-0">
          My Invites Overview
        </h3>
<!--        TODO: create global component so we don't have to keep calling the default font -->
        <p style="font-size: 14px; font-family: 'DINPro', sans-serif; line-height: 18px; font-weight: 400; letter-spacing: 0.16px; color: #525252;">My Invites gives coaches the ability to upload a list of players from their camps,
          visits or make a completely custom list. This allows coaches to view the progress of
          the players' profile to fully start the evaluation process. Get started by importing a list of prospects.
        </p>
      </div>
    </div>
    <div class="invite-container">
      <my-invites-lists
        :sport_name="sport_name"
        @file_chosen="importData"
      ></my-invites-lists>
    </div>
    <b-modal id="errorModal" title="Error" v-model="showErrorModal">
      <p class="my-4">{{ errorMessage }}</p>
    </b-modal>
  </div>
</template>

<script>
import utilityCode from "./utils";
import {BButton, BButtonGroup, BModal} from 'bootstrap-vue';
import readXlsxFile from "read-excel-file";
import MyInvitesLists from "./MyInvitesLists";

export default {
  name: 'MyInvites',
  props: {
    organization_id: {type: String, default: '0', required: false},
    organization_name: {type: String, default: null, required: false},
    coach_name: {type: String, default: null, required: false},
    sport_name: {type: String, default: 'football', required: false},
  },
  components: {BButton, BButtonGroup, BModal, MyInvitesLists},
  data: () => ({
    processingInvites: false,
    errorMessage: '',
    invites: [],
    players: [],
    showErrorModal: false,
    playerData: [],
    isListActive: true,
    isPlayerActive: false
  }),
  methods: {
    async reloadInvites() {
      await this.getInvites()
      this.processingInvites = false
    },
    getInvites() {
      axios.get('/api/player-invites').then(({ data: { data } }) => {
        this.invites = data
      }).catch((error) => console.log(error))
    },
    importData(file) {
      if (!file) {
        this.showError('Error reading your file.  Please try again with a valid xls, csv or xlsx file');
        return false;
      }
      const ext = file.name.split('.').pop().toLowerCase();

      switch(ext) {
        case 'xlsx': {
          this.readXlsx(file)
          break
        }
        case ('csv'): {
          this.readCsv(file)
          break
        }
      }
      this.processingInvites = true;
    },
    readXlsx(file) {
      const schema = {
        'name': {
          prop: 'name',
          type: String,
          required: true
        },
        'email': {
          prop: 'email',
          type: String,
          required: true
        },
        'parent_email': {
          prop: 'parent_email',
          type: String,
          required: true
        },
        'grad_year': {
          prop: 'grad_year',
          type: Number
        },
        'phone': {
          prop: 'phone',
          type: String
        }
      }

      readXlsxFile(file, { schema }).then(({ rows, errors }) => {
        if (!!errors) console.log(errors)

        this.playerData = rows
      })
    },
    readCsv(file) {
      const reader = new FileReader()

      reader.readAsText(file);

      reader.onload = async () => {
        await this.csvToArray(reader.result)
      }
    },
    csvToArray(str, delimiter = ',') {
      var headers = str.slice(0, str.indexOf("\r")).split(delimiter);
      headers = this.fixHeaders(headers);
      const rows = str.slice(str.indexOf("\n") + 1).split("\r");

      const data = rows.map((row) => {
        const values = row.split(delimiter);
        return headers.reduce((object, header, index) => {
          object[header] = values[index]
          return object
        }, {})
      })
      this.playerData = data
    },
    showError(message) {
      this.errorMessage = message;
      this.showErrorModal = true;
    },
    fixHeaders(headers) {
      headers.forEach((element, index) => {
        headers[index] = element.trim().replace('-', '_').replace(' ', '_').trim();
      });
      return headers;
    },
  },
  computed: {
    inviteCount() {
      return this.invites.length
    }
  },
  mounted() {
    this.reloadInvites();
    utilityCode();
  },
}
</script>

<style scoped>
body {
  background: #ffffff !important;
}
.invite-container {
  height: 100%;
  background-position: center;
  background-size: cover;
}
.title-container{
  margin-left: 16px;
  margin-right: 16px;
}

.btn-group{
  margin-bottom: 2rem!important;
  margin-left: 16px;
  margin-right: 16px;
}


.btn{
  border: 1px solid black;
  border-radius:25% !important;
}

.btn-group-toggle > label.btn.focus{
  animation: none !important;
  padding: 0.375rem 0.75rem !important;
  background-color: inherit;
}

/* Used to disable bootstrap focus state from overriding the background color. */
.btn-group-toggle > label.btn.focus.firstJourney{
  background-color: black;
}


.btn-group label{
  background-color: white;
  color: black;
  font-family: KairosSans;
  text-transform: uppercase;
}

.btn-group label input[type="radio"]:disabled{
  cursor: default;
}
.btn-group label:has(> input[type="radio"]:disabled){
  opacity: 50%;
  cursor: default;
}

.btn-group .currentPage{
  background-color: black;
  color: #fff;
}
</style>
