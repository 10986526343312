<template>
  <div class="player-stat-tiles-panel">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PlayerStatTilesPanel",
}
</script>
