<template>
  <div>
    <span class="form-inline pull-right m-b-10">
      <input type="text" id="calendar" class="form-control" placeholder="Search..." @keyup="handleFilterChange">
    </span>
    <data-tables-server id="calendars-table"
      :data="calendars"
      :total="count"
      :loading="loading"
      :pagination-props="{
        pageSizes: [10, 25, 50, 100],
        background: true,
        layout: 'prev, pager, next, sizes, ->, total'
      }"
      :filters="filters"
      class="table"
      @query-change="loadData"
    >
      <el-table-column label="ID" align="center">
        <template slot-scope="{ row }">{{ row.id }}</template>
      </el-table-column>
      <el-table-column label="Name" align="center">
        <template slot-scope="{ row }">{{ row.name }}</template>
      </el-table-column>
      <el-table-column label="" class-name="actions-col">
        <template slot-scope="{ row }">
          <button aria-expanded="false" class="btn dropdown-toggle waves-effect waves-light" data-toggle="dropdown" type="button">
            ACTIONS
            <span data-html2canvas-ignore>
            <span class="caret"></span>
          </span>
          </button>
          <div class="dropdown-menu">
            <a :href="`/calendar/${row.id}`" class="dropdown-item">View</a>
            <a :href="`/calendar/${row.id}/edit`" class="dropdown-item">Edit</a>
            <span class="dropdown-item" @click="() => confirm(row)">Delete</span>
          </div>
        </template>
      </el-table-column>
    </data-tables-server>
  </div>
</template>

<script>
import DataTablesServer from '@components/ui/DataTablesServer'

let timeout
export default {
  components: { DataTablesServer },
  name: 'CalendarsTable',
  props: {},
  data() {
    return {
      url: '/api/calendars',
      previousQuery: null,
      apiData: null,
      loading: false,
      filters: [
        { prop: 'name', value: "" }
      ]
    }
  },
  computed: {
    calendars: v => _.get(v, 'apiData.data', []),
    count: v => _.get(v, 'apiData.meta.pagination.total', 0)
  },
  methods: {
    async loadData(query) {
      this.previousQuery = query
      this.loading = true

      const { page, pageSize: length, filters } = query
      const { value: name } = filters[0]
      const data = {page, length, name}

      const url = `${this.url}?${new URLSearchParams(data).toString()}`
      const response = await axios.get(url)
      this.apiData = response.data
      this.loading = false
    },
    handleFilterChange(e) {
      const value = $(e.target).val()
      const setFilter = () => this.filters[0].value = value
      clearTimeout(timeout)
      timeout = setTimeout(setFilter, 300)
    },
    getSwalProps() {
      return {
        title: 'Remove calendar from system?',
        text: 'This will remove the calendar from the system.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove calendar!',
        cancelButtonText: 'No, cancel!',
        confirmButtonClass: 'btn btn-confirm mt-2',
        cancelButtonClass: 'btn btn-cancel ml-2 mt-2',
        reverseButtons: true
      }
    },
    async confirm({ id }) {
      const deleteFn = () => this.delete(id)
      return Swal.fire(this.getSwalProps()).then(async ({ value }) => {
        if (value) await deleteFn()
      })
    },
    async delete(id) {
      await axios.delete(`/api/calendars/${id}`).then(({ data }) => this.deleteCallback(data))
      await this.loadData(this.previousQuery)
    },
    deleteCallback({ data: { response } }) {
      return response === 'success'
        ? Swal.fire({ title: 'Removed!', text: 'The calendar has been removed.', type: 'success', timer: 1700 })
        : Swal.fire({ title: 'Error!', text: 'There was an error removing the calendar.' })
    }
  }
}
</script>

<style scoped>
  .actions-col {
    font-family: KairosSans, serif;
  }
  .actions-col button {
    font-family: KairosSans, serif;
    border-color: rgb(225,225,225);
    border-width: 1px;
    border-radius: 0;
    padding: 7.5px 30px;
    color: black !important;
  }
  .actions-col .dropdown-item {
    cursor: pointer;
  }
</style>
