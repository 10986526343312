<template>
  <div :class="`user-avatar ${className}`">
    <img
      v-if="photo"
      class="user-avatar-image"
      :src="photo"
      :style="{...{ width, height }}"
      :alt="`${user?.first_name} ${user?.last_name} profile image`"
    >
    <div
      v-else
      class="user-avatar-default"
      :style="{...{ width, height }}"
    >
        <span class="initials" :style="{...{ fontSize }}">{{ getInitials() }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'UserAvatar',
    props: {
      user: {
        type: Object,
        default: {},
        required: false,
      },
      width: {
        type: String,
        default: "5rem",
        required: false,
      },
      height: {
        type: String,
        default: "5rem",
        required: false,
      },
      fontSize: {
        type: String,
        default: "2rem",
        required: false,
      },
      className: {
        type: String,
        default: "",
        required: false,
      },
    },
    computed: {
      photo: function() {
        if (this.user?.profile?.photo?.url) return this.user.profile.photo.url;
        if (this.user?.profile?.photo) return this.user.profile.photo;
        if (this.user?.profile?.data?.photo) return this.user.profile.data.photo;
        return null;
      },
    },
    methods: {
      getInitials: function() {
        return `${this.user?.first_name.charAt(0)}${this.user?.last_name.charAt(0)}`;
      }
    }
  }
</script>
