<template>
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16.2475" cy="16.2475" r="16.2475" fill="black"/>
    <path d="M19.451 13.7758C20.4799 14.5139 21.7403 14.9482 23.1017 14.9482V12.3192C22.844 12.3193 22.5871 12.2923 22.335 12.2387V14.3081C20.9738 14.3081 19.7135 13.8738 18.6843 13.1358V18.5007C18.6843 21.1846 16.5163 23.3601 13.8422 23.3601C12.8444 23.3601 11.917 23.0574 11.1466 22.5382C12.0259 23.4404 13.2521 24.0001 14.6087 24.0001C17.283 24.0001 19.4511 21.8246 19.4511 19.1407V13.7758H19.451ZM20.3967 11.1235C19.8709 10.547 19.5257 9.80195 19.451 8.97828V8.64014H18.7244C18.9073 9.68702 19.5311 10.5814 20.3967 11.1235ZM12.838 20.4787C12.5442 20.0921 12.3855 19.6192 12.3862 19.133C12.3862 17.9055 13.3778 16.9102 14.6012 16.9102C14.8292 16.9102 15.0558 16.9452 15.2731 17.0144V14.3267C15.0192 14.2918 14.7629 14.2769 14.5067 14.2824V16.3744C14.2893 16.3052 14.0625 16.27 13.8345 16.2703C12.6111 16.2703 11.6196 17.2654 11.6196 18.4931C11.6196 19.3611 12.1152 20.1126 12.838 20.4787Z" fill="#FF004F"/>
    <path d="M18.6843 13.1357C19.7135 13.8738 20.9738 14.3081 22.335 14.3081V12.2387C21.5752 12.0763 20.9025 11.6778 20.3968 11.1235C19.531 10.5814 18.9073 9.68696 18.7245 8.64014H16.8161V19.1406C16.8117 20.3647 15.8218 21.3559 14.6011 21.3559C13.8817 21.3559 13.2426 21.0118 12.8379 20.4787C12.1151 20.1126 11.6195 19.361 11.6195 18.4931C11.6195 17.2656 12.611 16.2703 13.8344 16.2703C14.0688 16.2703 14.2947 16.3069 14.5066 16.3744V14.2824C11.8795 14.3369 9.7666 16.4912 9.7666 19.1406C9.7666 20.4632 10.2928 21.6622 11.1467 22.5382C11.9171 23.0574 12.8445 23.3602 13.8423 23.3602C16.5165 23.3602 18.6844 21.1845 18.6844 18.5007V13.1357H18.6843Z" fill="white"/>
    <path d="M22.335 12.2385V11.679C21.6498 11.68 20.9781 11.4875 20.3968 11.1233C20.9114 11.6887 21.589 12.0786 22.335 12.2385ZM18.7245 8.63999C18.707 8.53994 18.6936 8.43923 18.6843 8.33814V8H16.0493V18.5005C16.0451 19.7245 15.0553 20.7157 13.8344 20.7157C13.476 20.7157 13.1376 20.6303 12.8379 20.4786C13.2426 21.0116 13.8817 21.3557 14.6011 21.3557C15.8217 21.3557 16.8118 20.3646 16.8161 19.1405V8.63999H18.7245ZM14.5068 14.2823V13.6866C14.2866 13.6564 14.0646 13.6412 13.8424 13.6413C11.1679 13.6413 9 15.8169 9 18.5005C9 20.183 9.85204 21.6658 11.1468 22.538C10.2928 21.662 9.76667 20.463 9.76667 19.1404C9.76667 16.491 11.8795 14.3367 14.5068 14.2823Z" fill="#00F2EA"/>
  </svg>
</template>

<script>
export default {
  name: 'TikTokIcon'
}
</script>
