<template>
  <div>
    <div id="new-note">
      <h4>New Player Note:</h4>
      <textarea type="text" maxlength="25000" rows="5" v-model="reply" name="new-note" placeholder="Type your note here..." class="form-control note-control"></textarea>

      <div class="row">
        <div class="col-md-6">
          <multiselect v-model="selectedtags" :options="removedSportFromPosition" :taggable="true" @tag="addTag" :multiple="true" label="name" track-by="id" group-label="type" group-values="tags" placeholder="Categorize this note"></multiselect>
        </div>
        <div class="col-md-6">
          <multiselect v-model="selectedcoaches" :options="coaches" :multiple="true" label="full_name" track-by="id" placeholder="Tag coaches"></multiselect>
        </div>
      </div>

      <button class="btn btn-primary waves-effect waves-light m-t-10" v-on:click="makeNote" :disabled="isUploading">{{ isUploading ? 'Adding...' : 'Add' }}</button>
    </div>
    <comment v-for="(comment, index) in comments" :key="comment.id" :tags="tags" :coaches="coaches" :getComments="getComments" :comment="comment" :authuser="authuser" :alertableid="alertableid" />
  </div>
</template>

<script>
  import axios from 'axios'
  import Comment from './Comment'
  import Multiselect from 'vue-multiselect'

  export default {
    components: { Multiselect },
    name: 'Notes',
    props: {
      type: String,
      id: Number,
      authuser: String,
      tags: Array,
      coaches: Array,
      alertableid: String,
      embed_refs: {
        type: String,
        default: 'false'
      }
    },
    data() {
      return {
        isUploading: false,
        comments: [],
        selectedtags: [],
        selectedcoaches: [],
        reply: ''
      }
    },

    mounted: function() {
        //
    },

    computed: {
      removedSportFromPosition() {
        return this.tags.map(tag => {
          tag.type = tag.type.includes('position') ? 'position' : tag.type
          return tag
        })
      }
    },

    methods: {
      makeNote: function() {
        if (this.isUploading === false) {
          this.isUploading = true
          const comment = this.reply
          const parentId = this.id
          const selectedtags = this.selectedtags
          const selectedcoaches = this.selectedcoaches
          const parentType = 'User';
          axios.post('/api/comments', {
            commentable_type: parentType,
            commentable_id: parentId,
            comment: comment,
            tags: selectedtags,
            mentions: selectedcoaches,
            link_location: 'recruits'
          }).then((response) => {
            this.isUploading = false
            this.reply = ''
            this.selectedtags = []
            this.selectedcoaches = []
            this.addCommentToCommentsArray(response.data.data)
            // this.$emit('new_note')
          }).catch((error) => {
            this.isUploading = false
            if (error.response.status === 422) {
              toastr.error('Cannot create an empty note.', 'Error!')
            }
          })
        }
      },
      addTag (newTag) {
        axios.post('/api/tags', { name: newTag, type: 'custom' }).then((response) => {
          this.selectedtags.push(response.data)
          this.$root.$emit('updateTags')
        })
      },
      addCommentToCommentsArray(comment) {
        if (this.embed_refs == 'false') {
          let newComments = [comment].concat(window.vueInstance.$refs.player_profile.$refs.notes.comments)
          window.vueInstance.$refs.player_profile.$refs.notes.comments = newComments
        } else {
          let newComments = [comment].concat(window.vueInstance.$refs[this.embed_refs].$refs.notes.comments)
          window.vueInstance.$refs[this.embed_refs].$refs.notes.comments = newComments
        }
      }
    }
  }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
