export default [
  {label: 'Select', type: 'checkbox'},
  {label: 'Player Name', valueKey: 'full_name', type: 'default'},
  {label: 'Positions', valueKey: 'positions', type: 'default'},
  {label: 'Invite Name', valueKey: 'positions', type: 'default'},
  {label: 'Date Invited', valueKey: 'positions', type: 'default'},
  {label: 'NT /WT', valueKey: 'positions', type: 'default'},
  {label: 'Academics', valueKey: 'positions', type: 'default'},
  {label: 'Testing', valueKey: 'positions', type: 'default'},
  {label: 'Profile PC', valueKey: 'positions', type: 'default'},
]
