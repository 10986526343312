<template>
  <div>
    <div class="page-header">
      <h3 class="page-title">Invitation Overview</h3>
      <el-date-picker
        v-model="rangeDates"
        type="daterange"
        range-separator="To"
        start-placeholder="Start date"
        end-placeholder="End date"
        value-format="MM/dd/yyyy"
        @change="setFilters"
      >
      </el-date-picker>
    </div>
    <data-tables-server
        id="invite-table"
        :data="invites"
        :loading="loading"
        :total="pagination.total"
        :current-page="currentPaginationPage"
        row-key="id"
        :filters="filters"
        default-expand-all
        :pager-count="pagination.count"
        @query-change="handleQueryChange"
        :pagination-props="{
          pageSizes: [10, 25, 50, 100],
          background: true,
          layout: 'prev, pager, next, sizes, ->, total'
        }"
    >
      <el-table-column type="expand">
        <template slot-scope="{ row }">
          <div class="invite-data">
            <h4>Requested Attributes:</h4>
            <div class="attributes-container">
              <attribute-list heading="Measurables" :category="row.readable_attributes.measurables"></attribute-list>
              <attribute-list heading="Testing" :category="row.readable_attributes.testing"></attribute-list>
              <attribute-list heading="Academics" :category="row.readable_attributes.academics"></attribute-list>
              <attribute-list heading="Additional" :category="row.readable_attributes.additional"></attribute-list>
            </div>
          </div>
        </template>
      </el-table-column>
        <el-table-column label="Invite" align="left">
          <template slot-scope="{ row }">
            {{ row.name }}
          </template>
        </el-table-column>

        <el-table-column label="Date" align="center" rowspan="1">
          <template slot-scope="{ row }">
            {{ row.date }}
          </template>
        </el-table-column>

        <el-table-column label="Invited Players" align="center" rowspan="1">
          <template slot-scope="{ row }">
            {{  row.player_count }} Players
          </template>
        </el-table-column>
      <el-table-column align="center">
        <template slot-scope="{ row }">
          <button
            class="view-button btn"
            @click="$emit('show_players', row.id)"
          >
            View Players
          </button>
        </template>
      </el-table-column>
    </data-tables-server>
  </div>
</template>

<script>
import DataTablesServer from '@components/ui/DataTablesServer';
import AttributeList from "./components/AttributeList";

import { buildQueryForVueDataTable } from '@lib/vue-data-tables-util'

let timeout

export default {
  name: "ListView",
  components: { DataTablesServer, AttributeList },
  data: () => ({
    loading: false,
    rangeDates: [],
    filters: [
      { search_prop: 'afterDate', value: '' },
      { search_prop: 'beforeDate', value: '' }
    ],
    url: '/api/player-invites',
    invites: [],
    pagination: {},
    previousQuery: null,
    currentPaginationPage: JSON.parse(localStorage.getItem('invitePage')) ?? 1,
  }),
  methods: {
    handleQueryChange(query) {
      clearTimeout(timeout)
      timeout = setTimeout(() => this.loadData(query), 250);
    },
    async loadData(query, silentReload = false) {
      localStorage.setItem('invitePage', JSON.stringify(query.page))
      this.previousQuery = query
      this.loading = !silentReload
      const builder = buildQueryForVueDataTable(query)

      const url = `${this.url}?${builder.build()}`

      const res = await axios.get(url)
      this.invites = res.data.data
      this.pagination = res.data.meta.pagination

      this.loading = false
    },
    setFilters() {
      this.filters = [
        { search_prop: 'afterDate', value: this.rangeDates[0] },
        { search_prop: 'beforeDate', value: this.rangeDates[1] }
      ]
    }
  },
  updated() {
    this.currentPaginationPage = JSON.parse(localStorage.getItem('invitePage'));
  }
}
</script>

<style scoped>
.page-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.view-button {
  color: grey;
}

.view-button:hover {
  color: black;
}

.invite-data {
  padding: 1.5rem 1.5rem;
}

.attributes-container {
  margin-top: 1rem;
  margin-left: 2rem;
  display: flex;
  gap: 2rem;
}
</style>
