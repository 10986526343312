<template>
  <thead>
    <tr>
      <th>&nbsp;</th>
      <th class="sortable" @click="orderBy('last_name')">User
        <i v-if="params.sort === 'last_name'" :class="`fa fa-angle-${params.sort_direction === 'asc' ? 'up' : 'down'}`"></i>
      </th>
      <th>Subscription</th>
      <th>Status</th>
      <th>Sports</th>
      <th>Actions</th>
      <th class="sortable" @click="orderBy('created_at')">Created At
        <i v-if="params.sort === 'created_at'" :class="`fa fa-angle-${params.sort_direction === 'asc' ? 'up' : 'down'}`"></i>
      </th>
      <th class="sortable" @click="orderBy('last_login_at')">Last Login
        <i v-if="params.sort === 'last_login_at'" :class="`fa fa-angle-${params.sort_direction === 'asc' ? 'up' : 'down'}`"></i>
      </th>
    </tr>
  </thead>
</template>

<script>
  export default {
    name: 'UsersListHeader',
    inject: ['pagination', 'getUsers'],
    data() {
      return {
        currentApiUrl: '',
        params: {
          sort: '',
          sort_direction: 'asc',
        },
      }
    },
    watch: {
      pagination: {
        handler() {
          console.log('UsersListHeader detects pagination update')
          this.getActiveApiUrlParameters();
        },
        deep: true,
      },
    },
    methods: {
      getActiveApiUrlParameters() {
        // this.pagination.links is an array of objects and we need to find the object with the active property set to true
        let activeUrl = this.pagination.links.find(link => link.active === true);
        let url = new URLSearchParams(activeUrl.url);
        this.currentApiUrl = activeUrl.url;
        this.params.sort = url.get('sort');
        this.params.sort_direction = url.get('sort_direction');
      },
      orderBy(column) {
        if (this.params.sort === column) {
          this.params.sort_direction = this.params.sort_direction === 'asc' ? 'desc' : 'asc'
        } else {
          this.params.sort = column
          this.params.sort_direction = 'asc'
        }
        let url = new URLSearchParams(this.currentApiUrl);
        url.set('sort', this.params.sort);
        url.set('sort_direction', this.params.sort_direction);
        this.currentApiUrl = decodeURIComponent(url.toString());
        console.log(this.currentApiUrl);
        this.getUsers(this.currentApiUrl);
      },
    }
  }
</script>

<style lang="scss" scoped>
th.sortable{
  cursor: pointer;
  text-decoration: underline;
}
</style>