<template>
  <div
    id="groupMessageModal"
    aria-hidden="true"
    aria-labelledby="groupMessageModalLabel"
    class="modal"
    data-backdrop="true"
    role="dialog"
    tabindex="-1"
  >
    <div class="modal-dialog modal-xl card">
      <div class="modal-content">
        <div class="modal-header">
          <h5>Send Message</h5>
        </div>
        <div class="modal-body card-body">
          <div class="chat-input">
            <div
              class="misc-button"
              style="margin-left: 15px"
              v-on:click="sendCoachContactInformation"
            >
              <i
                class="fa fa-address-book-o btn-primary"
                data-toggle="tooltip"
                title="Send an automated message containing your phone and email"
              ></i>
            </div>
            <textarea
              id="message-input"
              v-model="currentMessage"
              name="message-input"
              placeholder="Type a message..."
              rows="1"
            ></textarea>
            <div class="send-button" @click="createMessage">
              <i class="fa fa-send btn-primary"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "GroupMessage",
  props: {
    profile: { type: Object, default: null, required: false },
    name: { type: String, default: null, required: false },
    selectedPlayersDataLocation: {type: String, default: null, required: false }
  },
  data() {
    return {
      currentMessage: "",
      selectedPlayers: [],
    };
  },
  created() {
    $(() => {
      $('body').on('show.bs.modal', '#groupMessageModal', e => {
        this.selectedPlayers = this.getSelectedPlayers()
        if (this.selectedPlayers.length === 0) {
          e.preventDefault()
          toastr.error('', 'You must select one or more players to message!')
        }
      })
    })
  },
  methods: {
    getPlayer() {
      window.location = "/recruit/" + this.player_id + "/message";
    },
    getSelectedPlayers() {
      const refsArr = this.selectedPlayersDataLocation.split('.')
      let prev, prevKey, initial
      for (const key in refsArr) {
        const name = refsArr[key]
        prev && prevKey ? prev = `${prev}.$refs.${name}` : initial = `$refs.${name}`
        prevKey = name
        prev = prev || initial
      }
      return _.get(window.vueInstance, `${prev}.selected`, [])
    },
    createMessage() {
      if (this.currentMessage !== "" && this.selectedPlayers.length > 0) {
        const body = {
          message: this.currentMessage.trim(),
          user_ids: this.selectedPlayers,
        };

        this.currentMessage = "";
        this.selectedPlayers = [];

        $("#groupMessageModal").modal("hide");

        axios
          .post(
            "/api/messages/group-message?include=messages,users.profile",
            body
          )
          .then(() => {
            toastr.success("", "Group message queued for processing!");
          });
      } else {
        toastr.error('', 'You must select one or more players to message and your message cannot be blank.')
      }
    },
    sendCoachContactInformation() {
      Swal.fire({
        title: "Send these players your contact information?",
        text:
          "This will send an automated message to the players, containing your email and phone number.",
        type: "question",
        showCancelButton: true,
        confirmButtonText: "Send contact information!",
        cancelButtonText: "Cancel!",
        confirmButtonClass: "btn btn-confirm mt-2",
        cancelButtonClass: "btn btn-cancel ml-2 mt-2",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          const { email, phone } = this.profile;
          const oldMessage = this.currentMessage;
          this.currentMessage =
            this.name +
            " has chosen to send you their contact information: \nEmail: " +
            email +
            (!!phone ? "\nPhone: " + phone : "");
          this.createMessage();
          this.currentMessage = oldMessage;
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
    },
  },
};
</script>
