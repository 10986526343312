<template>
  <div id="roster-filter" class="form-inline header-container">
    <div v-if="share" class="row" style="display: flex; flex-direction:row; justify-content: flex-start; margin-bottom: 15px;">
      <div class="col-lg-12" style="display:flex; flex-direction:column;">
        <h3><a :href="getProspectlistsIndexUrl()"><i class="fa fa-chevron-left fa-sm"></i> <span class="text-muted ml-3">Prospect Lists</span></a></h3>
      </div>
    </div>

    <div class="box-1 header-box">
      <h4 v-text="getTitle()" class="page-title"></h4>
      <option-bar 
        class="option-bar" 
        :share="share" 
        :disabledState="disabledState"
        :handleSubmitShareProspectlists="handleSubmitShareProspectlists"
      ></option-bar>
    </div>
    <div class="box-2 filter-box">
      <filter-bar class="filter-bar" :filters="filters"></filter-bar>
    </div>
  </div>
</template>

<script>
  import OptionBar from "./OptionBar";
  import FilterBar from "./FilterBar";

  export default {
    components: { OptionBar, FilterBar },

    props: {
      filters: {type: Array, default() {return []}, required: false},
      share: {type: Boolean, default: false, required: false},
      organization_id: {type: String, default: '0', required: false},
      disabledState: {type: Boolean, default: false, required: false},
      handleSubmitShareProspectlists: {type: Function, default() {return null}, required: false},
    },

    methods: {
      moveFilterToLegacyLocation() {
        $('#prospectListSearchInfo').find('.col-lg-12').append($('#roster-filter'))
      },
      getTitle(){
        return (this.share) ? 'Share Prospect Lists' : 'Prospect Lists';
      },
      getProspectlistsIndexUrl(){
        return '/organizations/'+this.organization_id+'/prospectlist';
      },
    },

    created() {
      $(() => {
        this.moveFilterToLegacyLocation()
      })
    },
  }
</script>

<style scoped>
  .header-container {
    width: 100%;
    /*flex comes in to play*/
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 5px;
  }

  [class ^="box-"] {
    font-family: KairosSans;
    text-transform: uppercase;
    margin-bottom: 10px;
    width: 100%;
    /*flex comes in to play*/
    display: flex;
    justify-content: flex-end;
    align-content: space-between;
    align-items: flex-start;
    gap: 5px;
  }

  .header-box {
    /*flex comes in to play*/
    flex-direction: row;
    justify-content: flex-start;
  }

  .page-title {
    width: 50%;
  }

  .option-bar {
    width: 50%;
    /*flex comes in to play*/
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 5px;
  }

  .filter-box {
    /*flex comes in to play*/
    flex-direction: row;
    justify-content: space-around;
    gap: 5px;
  }
</style>
