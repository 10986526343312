<template>
  <div class="nav-container">
    <div class="flex nav-btns">
      <button class="btn nav-btn" :class="{ 'active': selectedView === 'list' }" @click="setView('list')">List View</button>
      <button class="btn nav-btn" :class="{ 'active': selectedView === 'player' }" @click="setView('player')">Player View</button>
    </div>
    <div class="flex right-btns">
      <invite-button @file_chosen="newInvite"></invite-button>
      <button class="btn black-btn" @click="$emit('export')">Export Invites</button>
    </div>

  </div>
</template>
<script>
import InviteButton from "./InviteButton";

export default {
  name:"TopNavigation",
  components: { InviteButton },
  props: {
    setNav: {
      type: String,
      default: null,
    }
  },
  data: () => ({
    selectedView: 'list'
  }),
  methods: {
    newInvite(file) {
      this.$emit('new_invite', file);
    },
    setView(view) {
      this.selectedView = view
      this.$emit('navigation_changed', this.selectedView)
    }
  },
  computed: {
    changeView() {
      this.selectedView = this.setNav ?? this.selectedView
    }
  }
}
</script>
<style scoped>
.nav-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
}
.flex {
  display: flex;
}
.nav-btns {
  border: 1px solid black;
}
.right-btns {
  gap: 2rem;
}
.btn {
  font-family: KairosSans,sans-serif;
  text-transform:uppercase;
}
.active {
  background: black !important;
  color: white !important;
}
.nav-btn {
  background:white;
  color:black;
}
.black-btn {
  background: black;
  color: white;
}
</style>
