<template>
  <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.458008" width="40" height="40" rx="20" fill="#E7F816"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M24 15.9577L30 12.4927V27.5072L24 24.0422V25.9999H10V14H24V15.9577ZM24 18.2672V21.7327L28 24.0427V15.9572L24 18.2672ZM12 23.9999V16H22V23.9999H12Z" fill="black"/>
  </svg>
</template>

<script>
export default {
  name: "YellowVideoCameraIcon"
}
</script>
