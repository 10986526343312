<template>
  <svg width="16" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.708313 13.5V13.625H0.833313H11.1666H11.2916V13.5V12.6667C11.2916 9.74416 8.92249 7.375 5.99998 7.375C3.07747 7.375 0.708313 9.74416 0.708313 12.6667V13.5ZM5.99998 0.375C4.36614 0.375 3.04165 1.69949 3.04165 3.33333C3.04165 4.96718 4.36614 6.29167 5.99998 6.29167C7.63382 6.29167 8.95831 4.96718 8.95831 3.33333C8.95831 1.69949 7.63382 0.375 5.99998 0.375ZM4.29165 3.33333C4.29165 2.38985 5.05649 1.625 5.99998 1.625C6.94347 1.625 7.70831 2.38985 7.70831 3.33333C7.70831 4.27682 6.94347 5.04167 5.99998 5.04167C5.05649 5.04167 4.29165 4.27682 4.29165 3.33333ZM5.99998 8.625C8.13405 8.625 9.88186 10.279 10.0313 12.375H1.96867C2.1181 10.279 3.86591 8.625 5.99998 8.625Z" fill="#454545" stroke="#454545" stroke-width="0.25"/>
  </svg>
</template>

<script>
export default {
  name: "PublicUserIcon"
}
</script>
