<template>
  <div class="user-profile-card-container">
    <div class="sent-from-container">
      <div class="sent-from">
        <h5><span v-html="('Sent From').replace(/ /g, '&nbsp;')"></span></h5>
      </div>
    </div>
    <div>
      <div class="profile-container">
        <div class="profile-image" min-width="110" align-v="center" align="center" fixed="left">
          <img
            :src="profile_photo"
            class="rounded-circle"
            height="60"
            style="padding: 7px"
            width="60"
          />
        </div>

        <div class="profile-data" id="roster-player" align-v="center" label="Player" width="225" fixed="left">
            <div>
              <h5 class="player-name">
                <template v-if="full_name">
                  <span v-html="full_name.replace(/ /g, '&nbsp;')"></span>
                </template>
              </h5>
              <div class="roster-city-state">
                <template v-if="organization.name">
                  <!-- using v-html here because some schools have names that've been escaped (ie - O'Hanahan) -->
                  <span v-html="organization.name.replace(/ /g, '&nbsp;')"></span>
                  <br/>
                </template>
                <template v-if="organization.state">
                  <span v-html="(organization.city+', '+organization.state).replace(/ /g, '&nbsp;')"></span>
                </template>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  const pathname = window.location.pathname;

  export default {
    props: {
      prospectlist: {type: Object, default() {return {}}, required: false},
    },

    data() {
      return {
        creator: {},
        organization: {},
      };
    },
    computed: {
      full_name() {
        return (this.creator.data) ? this.creator.data.name : ''
      },
      profile_photo() {
        return (this.creator.data) ? this.creator.data.photo : ''
      },
    },
    mounted() {
      this.creator = this.prospectlist.data.creator;
      this.organization = this.prospectlist.data.organization
    },
  };
</script>

<style scoped>
  .user-profile-card-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .sent-from-container {
    border-right-style: solid;
    border-right-color: #E7F816;
    font-size:small;
    display:flex;
    padding-right: 25px;
    margin-right: 20px;
  }

  .sent-from {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .sent-from h5 {
    margin:0;
    padding:0;
  }

  .profile-container {
    display: flex;
    align-content: flex-start;
  }

  .profile-image {
    /*border: 1px solid blue;*/
  }

  .profile-data {
    /*border: 1px solid red;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: small;
  }

  .player-name {
    margin: 0;
    padding: 0;
  }
</style>
