<template>
  <b-container>
    <b-row class="header">
      <b-col class="headerCol" style="text-align: right;">
        &nbsp;
      </b-col>
    </b-row>
    <b-row sm="6" offset-sm="3" md="3" offset-md="4" class="dataRow">
      <b-col>
        <h1>Please confirm the below information</h1>
        <p>
          You are about to invite {{ invite_data.invited_players.length }} players to your platform.  The information you're requesting is below.</p>
        <ul>
          <li v-for="attribute in attributes"
              :key="attribute">{{ invite_data.requested_attributes.html_pretty_text[attribute] }}</li>
        </ul>
        <p>Invite Title: {{ invite_data.title  }}</p>
        <p>Invite Message: {{ invite_data.message }}</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="invite-footer">
        <back-button @back="$emit('back')"></back-button>
        <button @click="$emit('send_invite')" type="button" class="btn btn-primary" style="text-transform:uppercase;padding: 7px 30px; font-family: KairosSans,sans-serif;">Send Invitation</button>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { BContainer, BRow, BCol } from "bootstrap-vue";
import BackButton from './BackButton'

export default {
  name: "ConfirmInvite",
  components: { BContainer,BRow,BCol, BackButton },
  props: {
    count: {
      type:Number,
      required:true
    },
    invite_data: {
      type: Object,
      required: true
    }
  },
  computed: {
    attributes() {
      let attributes = []
      Object.values(this.invite_data.requested_attributes).forEach(function(attribute){
        if(attribute instanceof Array) attributes.push(...attribute)
      })
      return attributes
    }
  }
}
</script>
<style scoped>
.header{
  background:black;
  color:white;
  border-top:5px solid #E7F818;
  border-radius:5px 5px 0px 0px;
  padding:15px 0px 15px 15px;
}
.headerCol{
  font-family: KairosSans,sans-serif;
  text-transform:uppercase;
  color:white;
}
.dataRow{
  padding:35px;
  background:#f0f0f0;
}

.invite-footer {
  padding: 1rem 2rem;
  background: #f9f9f9;
  border-radius: 0 0 5px 5px;
  display: flex;
  justify-content: space-between;
}
</style>
