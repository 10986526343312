<template>
  <div class="verified-player-wrapper">
    <div class="invite-container" v-if="inviteCount < 1 || processingInvites">
      <first-journey v-if="inviteCount < 1 && !processingInvites"
        :sport_name="sport_name"
        @file_chosen="importData"
      ></first-journey>

      <invite-players v-else-if="processingInvites"
        :organization_id="organization_id" :organization_name="organization_name" :coach_name="coach_name"
        :sport_name="sport_name" :player_data="playerData" @invitation_complete="reloadInvites"
      ></invite-players>
    </div>

    <invitation-overview v-else
      :invites="invites"
      @new_invite="importData"
      @reload="reloadInvites"
    ></invitation-overview>

    <b-modal id="errorModal" title="Error" v-model="showErrorModal">
      <p class="my-4">{{ errorMessage }}</p>
    </b-modal>
  </div>
</template>

<script>
import utilityCode from "../myinvites/utils";
import FirstJourney from "./FirstJourney";
import InvitationOverview from "../myinvites/InvitationOverview";
import InvitePlayers from "../myinvites/InvitePlayers";
import {BButton, BButtonGroup, BModal} from 'bootstrap-vue';
import readXlsxFile from "read-excel-file";

export default {
  name: 'VerifiedPlayersScreen',
  props: {
    organization_id: {type: String, default: '0', required: false},
    organization_name: {type: String, default: null, required: false},
    coach_name: {type: String, default: null, required: false},
    sport_name: {type: String, default: 'football', required: false},
  },
  components: {BButton, BButtonGroup, BModal, FirstJourney, InvitationOverview, InvitePlayers},
  data: () => ({
    processingInvites: false,
    errorMessage: '',
    invites: [],
    players: [],
    showErrorModal: false,
    playerData: []
  }),
  methods: {
    async reloadInvites() {
      await this.getInvites()
      this.processingInvites = false
    },
    getInvites() {
      axios.get('/api/player-invites').then(({ data: { data } }) => {
        this.invites = data
      }).catch((error) => console.log(error))
    },
    importData(file) {
      if (!file) {
        this.showError('Error reading your file.  Please try again with a valid xls, csv or xlsx file');
        return false;
      }
      const ext = file.name.split('.').pop().toLowerCase();

      switch(ext) {
        case 'xlsx': {
          this.readXlsx(file)
          break
        }
        case ('csv'): {
          this.readCsv(file)
          break
        }
      }
      this.processingInvites = true;
    },
    readXlsx(file) {
      const schema = {
        'name': {
          prop: 'name',
          type: String,
          required: true
        },
        'email': {
          prop: 'email',
          type: String,
          required: true
        },
        'grad_year': {
          prop: 'grad_year',
          type: Number
        },
        'phone': {
          prop: 'phone',
          type: String
        }
      }

      readXlsxFile(file, { schema }).then(({ rows, errors }) => {
        if (!!errors) console.log(errors)

        this.playerData = rows
      })
    },
    readCsv(file) {
      const reader = new FileReader()

      reader.readAsText(file);

      reader.onload = async () => {
        await this.csvToArray(reader.result)
      }
    },
    csvToArray(str, delimiter = ',') {
      //console.log(str)
      var headers = str.slice(0, str.indexOf("\r")).split(delimiter);
      headers = this.fixHeaders(headers);
      const rows = str.slice(str.indexOf("\n") + 1).split("\r");

      const data = rows.map((row) => {
        const values = row.split(delimiter);
        return headers.reduce((object, header, index) => {
          object[header] = values[index]
          return object
        }, {})
      })
      this.playerData = data
      //console.log(data)
    },
    showError(message) {
      this.errorMessage = message;
      this.showErrorModal = true;
    },
    fixHeaders(headers) {
      headers.forEach((element, index) => {
        headers[index] = element.trim().replace('-', '_').replace(' ', '_').trim();
      });
      return headers;
    },
  },
  computed: {
    inviteCount() {
      return this.invites.length
    }
  },
  mounted() {
    this.reloadInvites();
    utilityCode();
  },
}
</script>

<style scoped>
.invite-container {
  height: 100%;
  background-position: center;
  background-size: cover;
}
</style>
