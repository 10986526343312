<template>
  <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.50001 0.292969L0.292908 4.50008L1.00001 5.20718L4.50001 1.70718L8.00001 5.20718L8.70712 4.50007L4.50001 0.292969Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "ChevronUpIcon"
}
</script>
