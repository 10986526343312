<template>
  <Modal
    heading="Request Transcript"
    :isOpen="isOpen"
    @onClose="$emit('onClose')"
    theme="light"
    :styles="{ maxWidth: '350px' }"
  >
    <form @submit="handleSubmit" data-parsley-validate>
      <div class="input-wrapper">
        <label for="name">Name *</label>
        <input id="name" class="form-control" name="name" v-model="form.name" required>
      </div>
      <div class="input-wrapper">
        <label for="email">Email *</label>
        <input id="email" class="form-control" on name="email" v-model="form.email" required data-parsley-type="email" />
      </div>
      <div class="button-wrapper">
        <Button type="submit" color="secondary">Submit</Button>
      </div>
    </form>
  </Modal>
</template>

<script>
  import Modal from '@components/Modal';
  import Button from '@components/Button';

  export default {
    name: 'TranscriptRequestModal',
    components: { Modal, Button },
    inject: ['player'],
    data() {
      return {
        form: {
          name: null,
          email: null,
          user_id: this.player.id,
        },
      }
    },
    props: {
      isOpen: {
        type: Boolean,
        default: false,
      },
      onClose: {
        type: Function,
        default: () => {},
      },
    },
    methods: {
      handleSubmit: async function(e) {
        e.preventDefault();
        const { signal } = new AbortController();

        try {
          await axios.post('/api/request-transcript', this.form, { signal });
          this.toaster({
            type: 'success',
            heading: 'Request sent successfully.',
          });
          this.$emit('onClose');
        } catch(error) {
          console.error(error);
          this.toaster({
            type: 'error',
            heading: 'Could not send request.',
          });
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .input-wrapper {
    margin-top: 16px;
  }
  .button-wrapper{
    bottom: 16px;
    position: absolute;
    right: 16px;
  }
</style>
