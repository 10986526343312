<template>
	<div class="comment comment-container row">
        <div class="col-12">
            <img :src="comment.author_pic" alt="user" class="comment-photo img-circle" />
            <a class="link font-weight-bold" :href="'/user/' + comment.author">{{comment.author_name}}</a>
            <span class="sl-date" style="float: right;">{{ comment.created }}</span>
            <div class="btn-group" v-if="commentIsEditable(comment) || commentIsDeleteable(comment)">
                <button class="btn btn-primary btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                </button>
                <div class="dropdown-menu">
                    <a v-if="commentIsEditable(comment)" class="dropdown-item" v-on:click="editComment(comment)">Edit</a>
                    <a v-if="commentIsDeleteable(comment)" class="dropdown-item" v-on:click="deleteComment(comment)">Delete</a>
                </div>
            </div>
            <div v-if="commentEditing" class="content modify-comment">
                <div class="comment row">
                    <div class="col-12 relative">
                        <textarea placeholder="Add Comment" auto-hyperlink class="post-box__textarea post-edit comment-box" data-expanding-textarea v-model="comment.comment"></textarea>
                    </div>
                    <div class="col-12 text-right">
                    	<button class="btn btn-primary waves-effect waves-light edit-comment" v-on:click="updateComment" :disabled="isUploading">Update</button>
                        <button class="btn btn-primary waves-effect waves-light btn-dark text-white edit-comment" :disabled="isUploading" v-on:click="cancelUpdateComment">Cancel</button>
                    </div>
                </div>
            </div>
            <p v-else v-html="(comment.comment !== null) ? linkText(comment.comment) : comment.comment" class="comment-content"></p>
            <span v-if="!commentEditing" class="link m-r-10 cursor-pointer">
                <i v-on:click="like" v-bind:class="{'icon ion-ios7-heart': (comment.liked > 0), 'icon ion-ios7-heart-outline' : (comment.liked == 0)}"></i>
                <span>{{this.comment.total_likes}}</span>
            </span>
        </div>
    </div>
</template>

<script>
	import axios from 'axios'
	import Multiselect from 'vue-multiselect'

	export default {
		name: 'PostComment',
		props: {
		  comment: Object
		},
		data() {
		  return {
		    isUploading: false,
		    commentEditing: false,
		    refs: window.vueInstance.$refs,
		    original_content: this.comment.comment
		  }
		},
		methods: {
			commentIsEditable: function(comment) {
                if(this.refs.post.role == 'super admin') {
                    return true
                }
                else if (comment.author == this.refs.post.user_id) {
                    return true
                }
                return false
            },
            commentIsDeleteable: function(comment) {
                if (this.refs.post.role == 'super admin') {
                    return true
                } else if ((this.refs.post.organization_id == comment.organization_id) &&
                		   (this.refs.post.role == 'organization admin')) {
                    return true
                } else if (this.refs.post.user_id == comment.author) {
                    return true
                }
                return false
            },
            linkText: function(e) {
                 return linkifyStr(e);
            },
            editComment: function(comment) {
            	this.commentEditing = (!this.commentEditing) ? true : false
            },
            deleteComment: function(comment) {
                Swal.fire({
                  title: 'Are you sure?',
                  text: "This will remove the comment from the system.",
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, remove comment!',
                  cancelButtonText: 'No, cancel!',
                  confirmButtonClass: 'btn btn-confirm mt-2',
                  cancelButtonClass: 'btn btn-cancel ml-2 mt-2',
                  reverseButtons: true
                }).then((result) => {
                  if (result.value) {
                    axios.delete('/api/comments/' + comment.id).then((response) => {
                        this.$emit('update_comments')
                        Swal.fire({
                            title: 'Removed!',
                            text: 'The post has been removed.',
                            type: 'success',
                            timer: 1700,
                            onClose: () => {
                            }
                        })
                    }).catch((error) => {
                        console.log(error)
                        Swal.fire({
                            title: 'Error!',
                            text: 'There was an error removing the post.',
                            type: 'error',
                        })
                    })
                  } 
                  else if (result.dismiss === Swal.DismissReason.cancel) {}
                });
            },
            updateComment: function() {
        		axios.put('/api/comments/' + this.comment.id, {
        			id: this.comment.id, 
        			comment: this.comment.comment
        		}).then((response) => {
        			this.commentEditing = false
        			this.original_content = this.comment.comment
        			this.$forceUpdate
        		}).catch((error)=> {
        			console.log(error)
        		})
            },
            cancelUpdateComment: function() {
                this.commentEditing = false
                this.comment.comment = this.original_content
    			this.$forceUpdate
            },
            like: function() {
            	this.comment.liked = (this.comment.liked > 0) ? 0 : 1
                axios.post('/api/like', {
                    'likeable_type': 'Comment',
                    'likeable_id': this.comment.id
                }).then((response) => {
                    (response.data.data.attached == true) 
                        ? this.comment.total_likes++ : this.comment.total_likes-- 
                }).catch((error) => {
                    console.log(error)
                })
            }
		}


	}
</script>