<template>

  <data-tables-server
    id="recruit-table"
    :data="recruits"
    :loading="loading"
    :total="count"
    :filters="filters"
    :current-page="currentPaginationPage"
    :pagination-props="{
      pageSizes: [10, 25, 50, 100],
      background: true,
      layout: 'prev, pager, next, sizes, ->, total'
    }"
    :table-props="{
      'row-key': 'id',
      'class': 'table datatable'
    }"
    @query-change="(e) => this.handleQueryChange(e)"
  >
    <el-table-column label="Add" v-if="!isFan" align="center" width="50">
      <template slot-scope="{ row }">
        <div
          data-toggle="tooltip"
          :title="getAddButtonToolTip(row)"
          :class="[0,1].includes(row.in_roster) ? 'removeBtn' : 'addBtn'"
          @click="(e) => handleAddRemoveClick(e, row)"
        >
          {{ [0,1].includes(row.in_roster) ? '&mdash;' : '+' }}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Photo" align="center" width="150">
      <template slot-scope="{ row }">
        <a :href="'/recruit/' + row.id" target="_blank" class="d-flex justify-content-center">
          <user-avatar :user="row"/>
        </a>
      </template>
    </el-table-column>
    <el-table-column id="roster-player" label="Player" width="250">
      <template slot-scope="{ row }">
        <div>
          <a :href="'/recruit/' + row.id" target="_blank">
            <h4 class="player-name">{{ row.full_name }}</h4>
          </a>
          <div class="roster-city-state">
            <template v-if="row.high_school != null">
              <div v-if="row.high_school != null" v-html="`${row.high_school}, ${row.grad_year}<br>`"></div>
              <div v-if="row.state != null">{{ row.city }}, {{ row.state }}</div>
            </template>
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column align="center">
      <template slot-scope="{ row }" v-if="isSluggerScienceProfile(row.profile)">
        <img src="/build/images/SluggerScience.png" alt="" width="50" height="50">
      </template>
    </el-table-column>
    <el-table-column label="Positions" align="center">
      <template slot-scope="{ row }">{{ getRenderedPositions(row) }}</template>
    </el-table-column>
    <el-table-column v-if="sport === 'football' || sport === 'baseball'" label="HT/WT" align="center">
      <template slot-scope="{ row }">
        <span v-html="renderHTWT(row)"/>
      </template>
    </el-table-column>
    <el-table-column v-if="sport === 'softball' || sport === 'mens_soccer' ||sport === 'womens_soccer'" label="HEIGHT" align="center">
      <template slot-scope="{ row }">
        <span v-html="renderHTWT(row)"/>
      </template>
    </el-table-column>
    <el-table-column label="Progress" v-if="!isFan" align="center">
      <template slot-scope="{ row }">
        <div class="text-center player-progress d-flex flex-column">
          <div :class="['inner-progress', {'full-progress org-bg': isComplete(row.profile)}]">
            <a :href="`/recruit/${row.id}`" target="_blank">
              <div data-toggle="tooltip" data-original-title="Profile"
                :class="['round m-2', {'org-bg org-text': row.profile.has_profile}]"
              >
                <i class="fas fa-user"></i>
              </div>
            </a>
            <a :href="`/recruit/${row.id}#int`" target="_blank">
              <div data-toggle="tooltip" data-original-title="Interview"
                :class="['round m-2', {'org-bg org-text': row.profile.has_interviews}]"
              >
                <i class="fas fa-info"></i>
              </div>
            </a>
            <a :href="`/recruit/${row.id}#foot`" target="_blank">
              <div data-toggle="tooltip" data-original-title="Videos"
                :class="['round m-2', {'org-bg org-text': row.profile.has_videos}]"
              >
                <i class="fas fa-film"></i>
              </div>
            </a>
          </div>
          <div class="text-center player-progress pt-1" v-if="renderSportTHSCAShowcase(row) === 1">
            <p>THSCA Showcase Attended</p>
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Actions" align="center">
      <template slot-scope="{ row }">
        <button aria-expanded="false" class="btn action-btn dropdown-toggle waves-effect waves-light" data-toggle="dropdown" type="button">
          ACTIONS <span data-html2canvas-ignore><span class="caret"></span></span></button>
        <div class="dropdown-menu">
          <a :href="`/recruit/${row.id}`" target="_blank" class="dropdown-item">View</a>
          <a :href="`/messages?user=${row.id}`" class="dropdown-item">Message</a>
          <a v-if="downloadFlag === 'true'" :href="`/player-card/${row.id}?download`" class="dropdown-item">Download Player Card</a>
        </div>
      </template>
    </el-table-column>
      <el-table-column
        v-if="!isFan"
        fixed="right"
        label="Message"
        align="center"
      >
        <template v-slot="{ row }">
          <input
            class="form-check-input"
            :id="'recruits-selected-player-'+row.id"
            type="checkbox"
            :checked="isSelectedPlayer(row.id)"
            :name="'recruits-selected-player-'+row.id"
            :value="row.id"
            @click="handleSelectedPlayers"
          />
        </template>
      </el-table-column>
  </data-tables-server>
</template>

<script>
import DataTablesServer from '@components/ui/DataTablesServer';
import UserAvatar from '@components/UserAvatar';
import { buildQueryForVueDataTable } from '@lib/vue-data-tables-util';

export default {
  components: { DataTablesServer, UserAvatar },
  name: 'RecruitsTable',
  props: {
    sport: {type: String, default: 'baseball', required: false},
    downloadFlag: String
  },
  data() {
    return {
      url: '/api/get-recruits',
      isFan: JSON.parse(this.$parent.isFan),
      loading: false,
      previousQuery: null,
      prevQuery: null,
      apiData: null,
      selected: [],
      filters: [],
      currentPaginationPage: JSON.parse(localStorage.getItem('page')) ? JSON.parse(localStorage.getItem('page')) : 1
    }
  },
  computed: {
    recruits: (r) => _.get(r, 'apiData.data', []),
    count: (r) => _.get(r, 'apiData.meta.pagination.total', 0),
    isAdvancedMode: (r) => JSON.parse(_.get(r.$parent.$refs['advanced-search'], 'visible', false))
  },
  watch: {
    recruits: () => {
      setTimeout(() => {
        $('.player-progress [data-toggle="tooltip"]').tooltip()
      }, 5)
    }
  },
  mounted() {
    $(() => {
      this.filters = this.getFilterData()
    })
  },
  created() {
    window.onbeforeunload = this.saveCookies
    $(() => {
      this.getOrResetCookies()
    })
  },
  updated() {
    this.currentPaginationPage = JSON.parse(localStorage.getItem('page'));
  },
  methods: {
    isSluggerScienceProfile(profile){
      if(typeof profile.user === 'undefined'){
        return false;
      }
      if(!profile.user.slugger_science_profile){
        return false;
      }

      return profile.user.slugger_science_profile;
    },
    getRenderedPositions(row) {
      return this.renderPositions(row)?.map(p => p.shortcode)?.join(', ')
    },
    getAddButtonToolTip(row) {
      switch (row.in_roster) {
        case 0: return 'Remove from Prospects'
        case 1: return 'Remove from Roster'
        default: return 'Add to Prospects'
      }
    },
    getPaginationData() {
      const { innerCurrentPage: page, innerPageSize: length } = this.$children[0]
      return { page, length }
    },
    getFilterData() {
      return this.$parent.$refs['basic-search'].getFilters()
    },
    getAdvancedSearchCriteria() {
      return this.$parent.$refs['advanced-search'].buildAdvancedSearchObjectFromFilters()
    },
    hasProfile(row) {
      return row.profile || false
    },
    hasOrganization(row) {
      return row.organizations && row.organizations.data.length > 0 || false
    },
    renderImage(row) {
      return this.hasProfile(row) ? row.profile.photo : '/build/images/default-profile.png'
    },
    renderHTWT(row) {
      if(this.sport !== 'softball') {
        const {height_weight} = row.profile
        return height_weight.length > 3 ? height_weight.replace('-', '/') : height_weight
      } else {
        const {height} = row.profile
        return height
      }
    },
    renderPositions(row) {
      switch (this.sport) {
        case 'football': return row.profile.football_profile.positions
        case 'baseball': return row.profile.baseball_profile.positions
        case 'softball': return row.profile.softball_profile.positions
        case 'mens_soccer': return row.profile.mens_soccer_profile.positions
        case 'womens_soccer': return row.profile.womens_soccer_profile.positions
      }
    },
    isComplete({ progress_profile, progress_interview, progress_videos }) {
      return progress_interview && progress_profile && progress_videos
    },
    renderSportTHSCAShowcase(row) {
      switch (this.sport) {
        case 'football': return row.profile.football_profile.thsca_showcase
        case 'baseball': return row.profile.baseball_profile.thsca_showcase
        case 'softball': return row.profile.softball_profile.thsca_showcase
      }
    },
    async handleAddRemoveClick({ target }, { id, in_roster, profile }) {
      return $(target).hasClass('removeBtn')
        ? await window.removeFromRoster(id, target, profile?.positions[0]?.shortcode || 'QB', in_roster)
        : await window.addRecruitToRoster(id, target, profile?.positions[0]?.shortcode || 'QB')
    },
    async handleQueryChange(query) {
      if (query.type === 'init') return
      return this.isAdvancedMode
        ? await this.advancedSearch(this.getAdvancedSearchCriteria())
        : await this.loadData(query)
    },
    handleSelectedPlayers(event){
      const target = $(event.target);
      const isChecked = target.is(':checked');
      const playerID = JSON.parse(target.val());

      let selected = _.cloneDeep(this.selected)

      if(playerID > 0) {
        if(isChecked){
          // add to selected
          selected.push(playerID)
        }
        else{
          // remove from selected
          var i = 0;
          while (i < selected.length) {
            if (selected[i] === playerID) {
              selected.splice(i, 1);
            } else {
              ++i;
            }
          }
        }
      }

      this.selected = _.cloneDeep(selected)
    },
    isSelectedPlayer(playerID){
      return (this.selected.indexOf(playerID) > -1)
    },
    async loadData(query) {
      localStorage.setItem('page', JSON.stringify(query.page));

      // reset query-page to 1 if there is a change to any of the basic search fields
      if( (this.prevQuery !== null) && (!_.isEqual(this.prevQuery.filters, query.filters)) ){
        query.page = 1
        this.resetCurrentPage(query.page)
      }

      this.prevQuery = _.cloneDeep(query)
      this.loading = true
      const builder = buildQueryForVueDataTable(query)
      const q = `${builder.build()}&basic=true`
      const url = `${this.url}?${q}`
      this.previousQuery = q

      const response = await axios.get(url)
      this.apiData = response.data

      this.loading = false
    },
    async advancedSearch(filters, firstPage = 0) {
      this.loading = true
      const { page, length } = this.getPaginationData()
      const data = {advanced_search: JSON.stringify(filters), page, length}
      if (this.$parent.$refs['advanced-search'].statsSearch === true) {
        const stats = this.$parent.$refs['advanced-search'].$refs['stats-search'].getSearchedFields()
        data['stats_search'] = JSON.stringify({stats_search: stats})
      }
      const q = new URLSearchParams(data).toString()
      const url = `${this.url}?${q}`
      this.previousQuery = q
      localStorage.setItem('page', JSON.stringify(firstPage || page));
      const response = await axios.get(url)
      this.apiData = response.data
      this.loading = false
    },
    saveCookies() {
      Cookies.set('recruits_table', JSON.stringify({selected: this.selected}), { expires: Infinity })
    },
    getOrResetCookies() {
      const { type } = performance.getEntriesByType('navigation')[0]
      const cookie = JSON.parse(Cookies.get('recruits_table'))

      switch (type) {
        case 'reload':
        case 'back_forward': return Object.keys(cookie).forEach(k => _.set(this, k, cookie[k])) && this.silentRefresh()
        case 'navigate':
          this.resetCurrentPage(1)
          return Cookies.expire('roster_table')
      }
    },
    resetCurrentPage(page) {
      localStorage.setItem('page', JSON.stringify(page))
      this.currentPaginationPage = page
      this.selected = []
    }
  }
}
</script>

<style scoped>
  .action-btn {
    border-color: rgb(225,225,225);
    border-width: 1px;
    border-radius: 0;
    padding: 8px 30px;
    font-family: KairosSans,serif;
    color: black !important;
  }
  .dropdown-menu {
    z-index: 2;
  }
</style>
