<template>
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="17.0324" cy="17.0488" r="16.2475" fill="#555555"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.23792 11.563H24.3282V23.6352H9.23792V11.563ZM10.7469 13.072V14.0907L16.783 16.7734L22.8192 14.0907V13.072H10.7469ZM22.8192 15.7421L16.783 18.4248L10.7469 15.7421V22.1262H22.8192V15.7421Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "MailIcon"
}
</script>
