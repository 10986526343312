export default () => {
  const { EventBus } = window

  $.ajaxSetup({
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
    },
  });

  window.SDSPusher.getPusherInfo().then(() => {
    const authId = $('#authId').val()
    window.SDSPusher.createPusherChannel('/api/user/authorize-pusher', `private-user_channel-${authId}`)
    window.SDSPusher.bindChannelEvent('mass_message', () => {
      window.vueInstance.$emit('fetchUserMessage');
      toastr.success('', 'Mass message sent successfully!');
    })
  })

  window.deleteUser = function (data) {
    Swal.fire({
      title: 'Remove user from system?',
      text: 'This will remove the user from the system.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, remove user!',
      cancelButtonText: 'No, cancel!',
      confirmButtonClass: 'btn btn-confirm mt-2',
      cancelButtonClass: 'btn btn-cancel ml-2 mt-2',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        $.ajax({
          url: `/api/users/${data}`,
          type: 'DELETE',
        })
        .done((d) => {
          if (d.data.response = 'success') {
            Swal.fire({
              title: 'Removed!',
              text: 'The user has been removed.',
              type: 'success',
              timer: 1700,
              onClose: () => {
                $(`tr#${data}`).remove();
              },
            });
          } else {
            // ajax error
            Swal.fire({
              title: 'Error!',
              text: 'There was an error removing the user.',
              type: 'error',
            });
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {}
    });
  }

  $(document).ready(() => {

    $("body").tooltip({ selector: '[data-toggle=tooltip]' });

    let query = {};

    initializeOrganizationFilter();
    initializeRoleFilter();
    initializeSportFilter();

    function initializeOrganizationFilter() {
      $('#organization').select2({
        placeholder: 'Organization',
        minimumInputLength: 3,
        ajax: {
          url: '/api/organization-autocomplete-ajax',
          dataType: 'json',
          processResults: function (data) {
            return {
              results:  $.map(data, function (item) {
                return {
                  text: item.name,
                  id: item.id
                }
              })
            };
          },
          cache: true
        }
      });
    }

    function initializeRoleFilter() {
      $('#role').select2({
        placeholder: 'User Type',
        ajax: {
          url: '/api/role-autocomplete-ajax',
          dataType: 'json',
          processResults: function (data) {
            return {
              results:  $.map(data, function (item) {
                return {
                  text: item.name,
                  id: item.name
                }
              })
            };
          },
          cache: true
        }
      });
    }

    function initializeSportFilter() {
      $('#sport').select2({
        placeholder: 'Sport',
        ajax: {
          url: '/api/sport-autocomplete-ajax',
          dataType: 'json',
          processResults: function (data) {
            return {
              results: $.map(data, function (item) {
                return {
                  text: item.name.replace('_', ' '),
                  id: item.name
                }
              })
            };
          },
          cache: true
        }
      });
    }

    function resetPlaceholders() {
      $('#organization').val('').trigger('change.select2');
      $('#role').val('').trigger('change.select2');
      $('#sport').val('').trigger('change.select2');
      $('#users-search').val('')
      $('#email-search').val('')

      window.vueInstance.$refs['screen-users'].$refs['users-table'].filters[0].value = ''
      window.vueInstance.$refs['screen-users'].$refs['users-table'].filters[1].value = ''
      window.vueInstance.$refs['screen-users'].$refs['users-table'].filters[2].value = ''
      window.vueInstance.$refs['screen-users'].$refs['users-table'].filters[3].value = ''
      window.vueInstance.$refs['screen-users'].$refs['users-table'].filters[4].value = ''
    }

    $('#organization').on('select2:select', (e) => {
      const { id } = e.params.data
      window.vueInstance.$refs['screen-users'].$refs['users-table'].filters[0].value = id
    });

    $('#role').on('select2:select', (e) => {
      const { id } = e.params.data
      window.vueInstance.$refs['screen-users'].$refs['users-table'].filters[1].value = id
    });

    $('#sport').on('select2:select', (e) => {
      const { id } = e.params.data
      window.vueInstance.$refs['screen-users'].$refs['users-table'].filters[4].value = id
    })

    $('#clearFilters').on('click', () => {
      resetPlaceholders();
      initializeOrganizationFilter();
      initializeRoleFilter();
      initializeSportFilter();
    });

    $.fn.DataTable.ext.pager.numbers_length = 5;
  });
}
