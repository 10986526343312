<template>
	<div>
    <form id="hsImport" name="hs-import" @submit.prevent="handleSubmitPlayerImportForm">
      <div class="table-responsive">
      <table class="table table-hover" id="player-import-list">
          <thead>
              <header-data-row :fieldNameHeaders="fieldNameHeaders"></header-data-row>
          </thead>
          <tbody>
            <player-data-row v-for="(playerData, index) in playerList"
              :playerData="playerData"
              :playerValidator="playerValidator"
              :requiredFields="requiredFields"
              :newPlayerTemplate="newPlayerTemplate"
              :index="index"
              :key="index"
            ></player-data-row>
          </tbody>
      </table>
    </div>
    </form>
  </div>
</template>

<script>
  $.ajaxSetup({
    headers: {
      "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
    }
  });

  import PlayerDataRow from "./PlayerDataRow";

  const PATH_NAME = window.location.pathname.split('/')[1];
  // possible values of PATH_NAME
  const PROSPECTS_SCREEN = 'prospects';
  const ROSTER_SCREEN = 'roster'

  export default {
    components: {
      PlayerDataRow
    },

  	props: {
      screen: PATH_NAME,
    },

  	data() {
      return {
        fieldNameHeaders: {},
        playerList: [],
        playerValidator: (playerData)=>{return console.log('Please provide a form validator.');},
        requiredFields: {},
        newPlayerTemplate: {},
      }
    },

  	computed: {
      acceptedPlayers(){
        return this.getAcceptedPlayers();
      },
      isProspectScreen(){
        return (this.screen === PROSPECTS_SCREEN);
      },
      isProspectScreen(){
        return (this.screen === ROSTER_SCREEN);
      },
    },

  	methods: {
      // CODE HERE USED FOR THE INTERFACE FORM
      getAcceptedPlayers(){
        return this.playerList.filter(player => player.config.isAccepted);
      },
      getValidAcceptedPlayers(){
        return this.playerList.filter((player) => {
          if(player.config.isAccepted && player.config.isValid)
            return player;
        });
      },
      getInvalidAcceptedPlayers(){
        return this.playerList.filter((player) => {
          if(player.config.isAccepted && !player.config.isValid)
            return player;
        });
      },
      getAcceptedPlayersInEditingMode(){
        return this.playerList.filter((player) => {
          if(player.config.isAccepted && player.config.isEditorMode)
            return player;
        });
      },
      updatePlayerEditingMode(index, editingMode){
        this.playerList[index].config.isEditorMode = editingMode;
      },
      initializePlayerData(playerList, fieldNameHeaders, playerValidator, requiredFields, newPlayerTemplate, isAllHeightNumerical){
        // if height data is all numerical, then initialize playerData and show modal window
        // else, display error message and halt initializing
        if(isAllHeightNumerical){
          this.fieldNameHeaders = fieldNameHeaders;
          this.playerList = playerList;
          this.playerValidator = playerValidator;
          this.requiredFields = requiredFields;
          this.newPlayerTemplate = newPlayerTemplate;
          this.updatePlayerCount();

          $("#players-to-import").modal('show');
        }
        else{
          const eMessage = `Only numerical data is allowed for the height field. Please ensure that all height data is formatted in the following way: 5'10" or 70".`;
          Swal.fire({
            title: "Validation Errors!",
            text: eMessage,
            type: "error",
            timer: 4500,
          });
          $('#players-to-import').modal('hide');
        }
      },
      removePlayerData(index){
        this.playerList[index].config.isAccepted = false;
        this.updatePlayerCount();
      },
      addPlayerData(){
        // deep copy newPlayer template and push new copy to playerList
        this.playerList.push(JSON.parse(JSON.stringify(this.newPlayerTemplate)));
        this.updatePlayerCount();
      },
      savePlayerData(index, playerData){
        this.playerList[index] = playerData;
      },
      saveAllPlayerData(){
        // have all player rows save/validate their input values
        window.EventBus.publish('sds_batch_save_players');
        this.updatePlayerCount();
      },
      updatePlayerCount(){
        const playerCount = this.getAcceptedPlayers().length;
        window.EventBus.publish('sds_updatePlayerCount', {playerCount: playerCount});
      },
      validateForDuplicateEmails(validPlayers){
        const player_container = {};
        const duplicate_container = [];

        validPlayers.forEach((player, index) => {
          const email = player.attributes.email;

          if(typeof player_container[email] === 'undefined'){
            player_container[email] = player;
          }
          else{
            duplicate_container[player.id] = player;
            duplicate_container[player_container[email].id] = player_container[email];
          }
        });

        if(Object.keys(duplicate_container).length > 0){
          duplicate_container.sort((a, b) => (a.attributes.email > b.attributes.email) ? 1 : -1)
        }

        const result = (Object.keys(duplicate_container).length > 0) ? duplicate_container : false;

        return result;
      },
      handleSubmitPlayerImportForm(){
        // if there are no players in editingMode then begin processing all player data
        // else, display error message that all players must be saved first
        if($.isEmptyObject(this.getAcceptedPlayersInEditingMode())){
          // get the list of accepted players that have valid and invalid input values
          const validPlayers = this.getValidAcceptedPlayers();
          const invalidPlayers = this.getInvalidAcceptedPlayers();

          // validate for duplicate emails
          var duplicateEmails = this.validateForDuplicateEmails(validPlayers);

          //if no validation errors and there are players to import then send api request to save
          // else display error message
          if(!duplicateEmails && $.isEmptyObject(invalidPlayers) && !$.isEmptyObject(validPlayers)){
            // confirm with user that they want to submit their imported player list
            Swal.fire({
              title: `Are you sure you want to import the ${PATH_NAME === PROSPECTS_SCREEN ? "Prospects" : "Roster"} list?`,
              text: `This will add all the players and any attributes associated with the players to your ${PATH_NAME === PROSPECTS_SCREEN ? "prospects" : "roster"} list.`,
              type: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, add players!",
              cancelButtonText: "No, cancel!",
              confirmButtonClass: "btn btn-confirm mt-2",
              cancelButtonClass: "btn btn-cancel ml-2 mt-2",
              reverseButtons: true
            })
            .then(result => {
              if(result.value === true){
                // process player data for submission to backend processing
                this.submitImportedPlayerList(validPlayers);
              }
              else if(result.dismiss === 'cancel'){
                // do nothing
              }
            });
          }
          else if($.isEmptyObject(invalidPlayers) && $.isEmptyObject(validPlayers)){
            const eMessage = '<h4>Please add at least one player before importing your player list.</h4>';
            Swal.fire({
              title: "Validation Errors!",
              html: eMessage,
              type: "error",
            });
          }
          else if(duplicateEmails){
            var eMessage = '<h4>Please make sure each players has a unique email address. The following players have duplicate emails:</h4>';
            const dupLength = duplicateEmails.length;

            duplicateEmails.forEach((player, index) => {
              const player_full_name = player.attributes.firstname+' '+player.attributes.lastname;
              const player_text = player.attributes.email+' <i class="ti-arrow-right"></i> '+player_full_name;
              eMessage += '<h5 style="color: #f27474;" class="float-left ml-5"><i class="ti-flag-alt-2 mr-3"></i> '+player_text+'</h5>';
            });

            Swal.fire({
              title: "Validation Errors!",
              html: eMessage,
              type: "error",
            });
          }
          else{
            const eMessage = '<h4>Please make sure all player data is VALID before importing your player list.</h4>';
            Swal.fire({
              title: "Validation Errors!",
              html: eMessage,
              type: "error",
            });
          }
        }
        else{
            const eMessage = '<h4>Please ensure that all player data is SAVED before importing your player list.</h4>';
            Swal.fire({
              title: "Validation Errors!",
              html: eMessage,
              type: "error",
            });
        }
      },
      submitImportedPlayerList(playerList){
        const formData = new FormData();
        const endpoint = PATH_NAME === PROSPECTS_SCREEN ? 'prospects' : 'players';

        const saveMode = true;
        window.EventBus.publish('sds_toggleImportMode', {saveMode: saveMode});
        toastr.success("", "Import queued for processing!");

        formData.append("playerList", JSON.stringify(playerList));

        axios
          .post(`/api/organizations/import-${endpoint}`, formData)
          .then(result => {
            $("#players-to-import").modal("hide");
            window.vueInstance.$refs['roster-screen'].$refs['roster-table'].silentRefresh();
          })
          .catch(error => {
            $("#players-to-import").modal("hide");
            toastr.error('', error.response.data.message);
            window.vueInstance.$refs['roster-screen'].$refs['roster-table'].silentRefresh();
          });
      },



      // CODE HERE USED TO EXTRACT PLAYERDATA FROM IMPORTED FILE
      extractPlayerImportData(playerImportData, sport) {
          // set formData with user player import spreadsheet data and selected sport id
          const formData = new FormData();
          formData.append("players", playerImportData.files[0]);
          formData.append("sport_id", sport.id);

          // set api endpoint for extracting data from user 'spreadsheet' file
          const previewUrl = '/api/organizations/extract-playerlist-data';

          // set success call back methods for prosect or player
          const successCallback = (response) => (PATH_NAME === PROSPECTS_SCREEN)
            ? this.onProspectImportSuccess(response, sport)
            : this.onRosterImportSuccess(response, sport);

          axios
          .post(previewUrl, formData)
          .then(response => {
            successCallback(response);
          });
      },

      onRosterImportSuccess(response, sport) {
          const headerData = response.data["headerData"];
          const playerData = response.data["playerData"];
          const playerList = [];

          // get and set the sport positions for dropdown-menu
          sport['positions'] = response.data["sportPositions"];

          // capture organization id if it exists
          var orgID = '';
          if($('#sdsOrganization').attr("orgID") > 0){
            orgID = $('#sdsOrganization').attr("orgID");
          }

          const required = {
            graduationyear: true,
            firstname: true,
            lastname: true,
            email: true,
          };

          const playerValidator = this.$SportPlayer.getPlayerValidator(required);

          const fieldNameHeaders = {
            graduationyear: 'Grad Year',
            jersey: 'Jersey #',
            firstname: 'First Name',
            lastname: 'Last Name',
            email: 'Email',
            cellphone: 'Phone',
            position: 'Positions',
            height: 'Height',
            weight: 'Weight (lbs)',
            password: 'Password',
          };

          var newPlayerTemplate = {
            'id': 'new',
            'attributes': {
              'graduationyear': '',
              'jersey': '',
              'firstname': '',
              'lastname': '',
              'email': '',
              'cellphone': {
                'areacode': '',
                'exchangecode': '',
                'extensioncode': '',
                'cellphone': '',
                'string': '',
                'isValid': false,
              },
              'positions': {
                'string': '',
                'array': [],
              },
              'height': {
                'feet': '',
                'inch': '',
                'totalInches': '',
                'string': '',
                'isValid': false,
              },
              'weight': '',
              'password': '',
            },
            config: {
              'organization_id': orgID,
              'isAccepted': true,
              'isValid': false,
              'isMember': false,
              'isEmailValid': false,
              'isEditorMode': true,
              'sport': sport,
              'mappedPositions': {
                'selectedPositions': [],
                'positions': '',
                'success': [],
                'failed': [],
                'failedStr': '',
              },
            },
          };

          // get field keys
          const fieldKeys = this.$SportPlayer.mapHeaderToFields(newPlayerTemplate.attributes, headerData);

          // exctract playerData from imported player list into playerList array
          var counter = 0;
          var isAllHeightNumerical = true;
          playerData.forEach((player, index) => {
            // perform data mutations
            const playerCellphone = this.getPlayerCellphone(player[fieldKeys.cellphone]);

            // weight validation
            // remove any non-numerical chars except decimal point (if any) and convert to float value
            player[fieldKeys.weight] = parseFloat(String(player[fieldKeys.weight]).replace(/[^\d.]/g, ''));

            // height validation
            const playerHeight = this.getPlayerHeight(String(player[fieldKeys.height]));


            // jersey validation
            // remove any non-numerical chars
            player[fieldKeys.jersey] = String(player[fieldKeys.jersey]).replace(/\D/g,'');

            // mapPlayerPositions
            const mappedPositions = this.$SportPlayer.mapPlayerToPosition(player[fieldKeys.positions], sport['positions'], sport.name);

            const playerData = {
              id: counter++,
              attributes: {
                'graduationyear': (player[fieldKeys.graduationyear]) ? player[fieldKeys.graduationyear] : '',
                'jersey': (player[fieldKeys.jersey]) ? player[fieldKeys.jersey] : '',
                'firstname': (player[fieldKeys.firstname]) ? player[fieldKeys.firstname] : '',
                'lastname': (player[fieldKeys.lastname]) ? player[fieldKeys.lastname] : '',
                'email': (player[fieldKeys.email]) ? player[fieldKeys.email] : '',
                'cellphone': {
                  areacode: (playerCellphone.isValid) ? playerCellphone.areacode : '',
                  exchangecode: (playerCellphone.isValid) ? playerCellphone.exchangecode : '',
                  extensioncode: (playerCellphone.isValid) ? playerCellphone.extensioncode : '',
                  cellphone: (playerCellphone.isValid) ? playerCellphone.cellphone : '',
                  string: (playerCellphone.isValid) ? playerCellphone.string : '',
                  isValid: playerCellphone.isValid,
                },
                'positions': {
                  'string': (player[fieldKeys.positions]) ? player[fieldKeys.positions] : '',
                  'array': [],
                },
                'height': {
                  feet: (playerHeight.isValid) ? playerHeight.feet : '',
                  inch: (playerHeight.isValid) ? playerHeight.inch : '',
                  totalInches: (playerHeight.isValid) ? playerHeight.totalInches : '',
                  string: (playerHeight.isValid) ? playerHeight.string : '',
                  isValid: playerHeight.isValid,
                },
                'weight': !isNaN(player[fieldKeys.weight]) ? player[fieldKeys.weight] : '',
                'password': (player[fieldKeys.password]) ? player[fieldKeys.password] : '',
              },
              config: {
                organization_id: '',
                isAccepted: true,
                isValid: false,
                isMember: player['isMember'],
                isEmailValid: player['isEmailValid'],
                isEditorMode: true,
                sport: sport,
                mappedPositions: mappedPositions,
              },
            };

            // check if height values is numerical data only
            if(typeof playerHeight !== 'undefined' && playerHeight.isNumericalStr === false)
              isAllHeightNumerical = false;

            playerList.push(playerData);
          });

          this.initializePlayerData(playerList, fieldNameHeaders, playerValidator, required, newPlayerTemplate, isAllHeightNumerical);
      },
      onProspectImportSuccess(response, sport) {
          const headerData = response.data["headerData"];
          const playerData = response.data["playerData"];
          const playerList = [];

          // get and set the sport positions for dropdown-menu
          sport['positions'] = response.data["sportPositions"];

          // capture organization id if it exists
          var orgID = '';
          if($('#sdsOrganization').attr("orgID") > 0){
            orgID = $('#sdsOrganization').attr("orgID");
          }

          const required = {
            graduationyear: true,
            firstname: true,
            lastname: true,
            email: true,
            cellphone: true,
          };

          const playerValidator = this.$SportPlayer.getPlayerValidator(required);

          const fieldNameHeaders = {
            graduationyear: 'Grad Year',
            highschool: 'High School',
            firstname: 'First Name',
            lastname: 'Last Name',
            email: 'Email',
            cellphone: 'Phone',
            positions: 'Position(s)',
          };

          var newPlayerTemplate = {
            'id': 'new',
            'attributes': {
              'graduationyear': '',
              'highschool': '',
              'firstname': '',
              'lastname': '',
              'email': '',
              'cellphone': {
                'areacode': '',
                'exchangecode': '',
                'extensioncode': '',
                'cellphone': '',
                'string': '',
                'isValid': false,
              },
              'positions': {
                'string': '',
                'array': [],
              },
            },
            config: {
              'organization_id': orgID,
              'isAccepted': true,
              'isValid': false,
              'isMember': false,
              'isEmailValid': false,
              'isEditorMode': true,
              'sport': sport,
              'mappedPositions': {
                'selectedPositions': [],
                'positions': '',
                'success': [],
                'failed': [],
                'failedStr': '',
              },
              'phoneEmail': {},
            },
          };

          // get field keys
          const fieldKeys = this.$SportPlayer.mapHeaderToFields(newPlayerTemplate.attributes, headerData);

          // exctract playerData from imported player list into playerList array
          var counter = 0;
          var isAllHeightNumerical = true;
          playerData.forEach((player, index) => {
            // perform data mutations
            const playerCellphone = this.getPlayerCellphone(player[fieldKeys.cellphone]);

            // mapPlayerPositions
            const mappedPositions = this.$SportPlayer.mapPlayerToPosition(player[fieldKeys.positions], sport['positions'], sport.name);

            const playerData = {
              id: counter++,
              attributes: {
                'graduationyear': (player[fieldKeys.graduationyear]) ? player[fieldKeys.graduationyear] : '',
                'highschool': (player[fieldKeys.highschool]) ? player[fieldKeys.highschool] : '',
                'firstname': (player[fieldKeys.firstname]) ? player[fieldKeys.firstname] : '',
                'lastname': (player[fieldKeys.lastname]) ? player[fieldKeys.lastname] : '',
                'email': (player[fieldKeys.email]) ? player[fieldKeys.email] : '',
                'cellphone': {
                  areacode: (playerCellphone.isValid) ? playerCellphone.areacode : '',
                  exchangecode: (playerCellphone.isValid) ? playerCellphone.exchangecode : '',
                  extensioncode: (playerCellphone.isValid) ? playerCellphone.extensioncode : '',
                  cellphone: (playerCellphone.isValid) ? playerCellphone.cellphone : '',
                  string: (playerCellphone.isValid) ? playerCellphone.string : '',
                  isValid: playerCellphone.isValid,
                },
                'positions': {
                  'string': (player[fieldKeys.positions]) ? player[fieldKeys.positions] : '',
                  'array': [],
                },
              },
              config: {
                organization_id: '',
                isAccepted: true,
                isValid: false,
                isMember: player['isMember'],
                isEmailValid: player['isEmailValid'],
                isEditorMode: true,
                sport: sport,
                mappedPositions: mappedPositions,
                phoneEmail: {},
              },
            };

            // check if height values is numerical data only
            if(typeof playerHeight !== 'undefined' && playerHeight.isNumericalStr === false)
              isAllHeightNumerical = false;

            playerList.push(playerData);
          });

          this.initializePlayerData(playerList, fieldNameHeaders, playerValidator, required, newPlayerTemplate, isAllHeightNumerical);
      },

      getPlayerCellphone(cellphone){
        const result = {
          areacode: null,
          exchangecode: null,
          extensioncode: null,
          cellphone: null,
          string: null,
          isValid: false,
        };

        // get cellphone
        const phoneData = String(cellphone).replace(/\D/g,'');

        result.areacode = phoneData.slice(0, 3);
        result.exchangecode = phoneData.slice(3, 6);
        result.extensioncode = phoneData.slice(6, 10);
        result.cellphone = result.areacode+result.exchangecode+result.extensioncode;
        result.string = '('+result.areacode+') '+result.exchangecode+'-'+result.extensioncode;

        // validate cellphone
        const rex = (/^\d{10}$/);
        const cellphoneStr = result.areacode+result.exchangecode+result.extensioncode;
        const match = rex.exec(cellphoneStr);

        result.isValid = (match) ? true : false;

        return result;
      },

      getPlayerHeight(height) {
        // check if height only has numerical values
        var isNumericalStr = true;
        const isHeightDefined = (typeof height !== 'undefined') && (height !== '') && (height !== null) && (height !== "null");
        if(isHeightDefined){
          //Replace any fancy quotes with normal quotes for regex test
          height = height.replace(/[\u2018\u2019]/g, "'")
            .replace(/[\u201C\u201D]/g, '"');

          /* Regex Explanation
          // Check for heights with ' & " separating the numbers (optional), 6'4", 76', and 76 would be accepted, 6'4, '' will fail
            ^          # Start of string
            (?!        # Assert that the following can't match here:
            $         # the end of string marker (excluding empty strings from match)
            |          # or
            .*\'      # any string that contains a '
            [^\x22]+  # if anything follows that doesn't include a "
            $         # until the end of the string (excluding invalid input like 1'2)
            )          # End of lookahead assertion
            (?:        # Start of non-capturing group:
            ([0-9]+)  # Match an integer, capture it in group 1
            \'        # Match a ' (mandatory)
            )?         # Make the entire group optional
            (?:        # Start of non-capturing group:
            ([0-9]+)  # Match an integer, capture it in group 2
            \x22?     # Match a " (optional)
            )?         # Make the entire group optional
            $          # End of string
          */
          const pattern = /^(?!$|.*\'[^\x22]+$)(?:([0-9]+)\')?(?:([0-9]+)\x22?)?$/;
          const regexp = new RegExp(pattern);
          isNumericalStr = regexp.test(height);

          if(isNumericalStr){
            if(height.includes(`'`) && height.includes(`"`)){
              //Both Feet and Inches 5'8"
              const inches = (+height.split(`'`)[0] * 12) + (+height.split(`'`, 2)[1].split(`"`)[0]);
              height = this.convertHeightToString(inches);
            } else if(height.includes(`'`) && !height.includes(`"`)){
              // Only Feet no Inches 8'
              height = this.convertHeightToString(+height.split(`'`)[0] * 12);
            } else {
              // Only Inches No Feet 56"
              height = this.convertHeightToString(+height.split(`"`)[0]);
            }
          } else {
            // not a valid Height String the entire function should return to throw validation error.
            return {
              feet: 0,
              inch: 0,
              totalInches: 0,
              string: '',
              isValid: false,
              isNumericalStr: isNumericalStr,
            };
          }

        }

        var match, inch;

        // set result
        const result = {
          feet: 0,
          inch: 0,
          totalInches: 0,
          string: '',
          isValid: false,
          isNumericalStr: isNumericalStr,
        };

        // if match made,then grab feet, inch, decimal, totalInches, and string
        match = this.matchHeightData(height)

        if(match){
          result.feet = (parseInt(match.feet) >= 0) ? parseInt(match.feet) : 0;
          result.inch = match.inch;
          result.totalInches = (result.feet*12) + result.inch;

          // make height into a string
          result.string = result.feet+'\' '+result.inch+'"';

          if(result.totalInches > 0){
            result.isValid = true;
          }
        }

        return result;
      },

      convertHeightToString(height){
        const feet = Math.floor(height / 12);
        const inches = (height - (feet * 12));
        const heightStr = feet+'\' '+inches+'"';

        return heightStr;
      },

      matchHeightData(height) {
        // local var
        var match;

        // initialize data
        const data = {
          feet: null,
          inch: null,
        };

        // set regex to match height values, including decimal inches
        const rex = (/^(\d+)'\s*(\d+)?(\d+)*(?:''|")$/);

        match = rex.exec(height)
        if(Array.isArray(match)){
          data.feet = parseInt(match[1],10);
          data.inch = parseInt(match[2],10);

          return data;
        }

        return undefined;
      },
    },
    created() {
      window.EventBus.subscribe('sds_extractPlayerImportData', (data) => {
        this.extractPlayerImportData(data.playerImportData, data.sport);
      });

      window.EventBus.subscribe('sds_remove_player_data', (data) => {
        this.removePlayerData(data.index);
      });

      window.EventBus.subscribe('sds_save_player_data', (data) => {
        this.savePlayerData(data.index, data.playerData);
      });

      window.EventBus.subscribe('sds_handleSubmitPlayerImportForm', () => {
        this.handleSubmitPlayerImportForm();
      });

      window.EventBus.subscribe('sds_update_player_editingMode', (data) => {
        this.updatePlayerEditingMode(data.index, data.interfaceMode);
      });

      window.EventBus.subscribe('sds_addPlayerData', () => {
        this.addPlayerData();
      });

      window.EventBus.subscribe('sds_saveAllPlayerData', () => {
        this.saveAllPlayerData();
      });
    },
  }

  Vue.component('header-data-row', {
    template: `
      <tr>
        <th v-for="(headerName, key) in fieldNameHeaders">
          {{headerName}}
        </th>
        <th style="text-align: center;">
          Actions
        </th>
      </tr>
    `,
    props: [
      'fieldNameHeaders',
    ],
  });
</script>

<style>

.table-responsive {
  min-height: 50vh;
}
</style>
