// only use this in playerProfile.vue
export default class Player {
  constructor({ player, sport }) {
    this.player = player
    this.sport = sport
    this.sportBasics = player?.profile?.data?.basics?.data?.sportBasics
  }
  getSport = () => this.sport
  /* SOFTBALL & BASEBALL PLAYER METHODS */
  getPitchTypes = () => this.player.profile.data.basics.data.sportBasics.data.pitch_types
  getPitchTypeMap = () => this.getPitchTypes()?.map(({id, name, shortcode, pivot}) => ({
    id, name: name?.replace(' - ', ' '), shortcode, velocity_low: pivot?.velocity_low, velocity_high: pivot?.velocity_high,
  }))
  getTypesForRender = () => {
    const pitches = this.getPitchTypeMap()
    switch (this.sport) {
      case 'baseball': 
      case 'softball': return {
        pitches: pitches?.filter(({ name: n }) => n)?.map(({ shortcode: s }) => s).join(', ')
      }
      case 'football': return { pitches: [] }
    }
  }
  getVelocitiesForRender = () => {
    const pitches = this.getPitchTypeMap()
    switch (this.sport) {
      case 'baseball':
      case 'softball': return {
        pitches: pitches?.filter(({ name: n }) => n)?.map(({ velocity_high: v }) => v || 0).join(', ')
      }
      case 'football': return { pitches: [] }
    }
  }
}
