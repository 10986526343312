<template>
  <div class="add-btn-container">
    <div
      v-if="!player.user_id"
      class="add-btn inactive"
      >
        <i class="fa fa-plus" aria-hidden="true"></i>
    </div>
    <div
      v-if="!!player.user_id && player.in_prospects"
      data-toggle="tooltip"
      title="In prospects"
      class="add-btn"
    >
      <i class="fa fa-check" aria-hidden="true"></i>
    </div>
    <div
      v-if="!!player.user_id && !player.in_prospects"
      data-toggle="tooltip"
      title="Add to Prospects"
      class="add-btn"
      @click="$emit('addToProspects')"
    >
      <i class="fa fa-plus" aria-hidden="true"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddButton",
  props: {
    player: {
      type: Object,
      required: true
    }
  },
}
</script>

<style scoped>
.add-btn-container {
  display: inline-block;
}

.add-btn {
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-left: 1.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: bold;
  font-size: 14px !important;
  color: #000;
  background-color: #e7f816;
}

.inactive {
  cursor: default;
  color: #000;
  background-color: lightgray;
  opacity: 50%;
}

.fa-check {
  font-size: 14px;
  padding-top: 0;
}
</style>
