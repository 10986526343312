<template>
  <div>
    <el-card id="athlete-pick-list" class="box-card">
      <div slot="header" class="clearfix">
        <span><h4 class="page-title prospectlist-title-short" v-text="listname"></h4></span>
      </div>
      <div class="athlete-pick-body">
        <div class="text item athlete-pick-item">
          <!-- place athlete profile here -->
          <athlete-profile-card v-for="(player, index) in players"
            :key="index"
            :player="player"
            :sport_id="sport_id"
            :sport_name="sport_name"
            :handlePlayerCommentsChange="handlePlayerCommentsChange"
            :handlePlayerRemove="handlePlayerRemove"></athlete-profile-card>
        </div>
        <div class="box-card-footer">
          <div class="button-group">
            <a :href="getCancelHref()" class="btn btn-secondary btn-lg" role="button">Cancel</a>
            <button v-if="isEditor" type="button" class="btn btn-lg btn-primary" @click="handleProfilelistUpdate(players)" :disabled="!isInputsUpdated">Save</button>
            <button v-else type="button" class="btn btn-lg btn-primary" @click="handleProfilelistSave(players)" :disabled="!isInputsUpdated">Create</button>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
  import AthleteProfileCard from './AthleteProfileCard'

  export default {
    components: { AthleteProfileCard },

    props:
    {
      players: {type: Object, default() {return {}}, required: false},
      listname: {type: String, default: 'List Name', required: false},
      organization_id: {type: String, default: '', required: false},
      sport_id: {type: String, default: '1', required: false},
      sport_name: {type: String, default: 'football', required: false},
      handlePlayerCommentsChange: {type: Function, default() {return null}, required: false},
      handlePlayerRemove: {type: Function, default() {return null}, required: false},
      handleProfilelistSave: {type: Function, default() {return null}, required: false},
      handleProfilelistUpdate: {type: Function, default() {return null}, required: false},
      isEditor: {type: Boolean, default() {return false}, required: false},
      isInputsUpdated: {type: Boolean, default() {return false}, required: false},
    },

    methods: {
      getCancelHref(){
        return '/organizations/'+this.organization_id+'/prospectlist';
      },
    },
  }
</script>

<style scoped>
  .text {
    font-size: 14px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

  .box-card {
    background: #FBFBFB;
    width: 100%;
    padding-top: 66px;
    padding-bottom: 10px;
  }

  #athlete-pick-list {
    /*height: 100vh;*/

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: center;
  }

  h4.page-title {
    font: Quantico;
    font-size: 31px;
    font-style: italic;
    line-height: 44.33px;
    width: 100%;
  }

  .prospectlist-title-short {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .athlete-pick-body {
    position: fixed; /* Fixed Sidebar (stay in place on scroll) */
    top: 140px; /* Stay at the top */
    right: 0;
    width: 100%;
  }

  .athlete-pick-item {
    max-height: 72vh;
    overflow-y: auto;
    width: 100%;

    display: flex;
    flex-direction: column;
    padding-bottom: 140px;
  }

  .box-card-footer {
    z-index: 2; /* Stay on top */
    position: fixed; /* Fixed Sidebar (stay in place on scroll) */
    bottom: 0; /* Stay at the bottom */
    right: 0;
    width: 100%;
    background: white;

    margin: 0;
    padding-top: 25px;
    padding-bottom: 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    gap: 20px;
  }

</style>

<style>
  #athlete-pick-list .el-card__header  {
    z-index: 2; /* Stay on top */
    padding: 0;
    margin: 0px 10px 0px 0px;
    padding-top: 20px;
    padding-left: 25px;
    background: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  #athlete-pick-list .el-card__body {
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
</style>
