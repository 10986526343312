<template>
  <div class="col-12 col-lg-6 col-xl-4 image-card-container">
    <div class="image-card card-box">
      <div class="heading">
        <div class="text-center">
          <p class="text-uppercase title">{{title}}</p>
          <input type="button" v-on:click="toggleImage" :class="'btn btn-primary ' + 'showImageButton ' + `image_${image.id ? image.id : ''}`" :value="imageShowing ? 'Hide' : 'View'">
        </div>
      </div>
      <div class="imageBox" v-if="imageShowing">
        <img :src="image" style="width:100%;height: auto;margin-top:20px"/>
      </div>
    </div>
  </div>
</template>

<style>
  .image-js .vjs-play-progress .vjs-time-tooltip {
    color: rgba(255, 255, 255, 0) !important;
    background-color: rgba(155,155,155,0) !important;
  }

  .image-js .mwi-time-tooltip {
    color: #000;
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 0.3em;
    color: #000;
    float: right;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
    padding: 6px 8px 8px 8px;
    pointer-events: none;
    position: absolute;
    top: -3.4em;
    visibility: hidden;
    z-index: 1;
  }

  .image-js .vjs-progress-control:hover .mwi-time-tooltip,
  .image-js .vjs-progress-control:hover .vjs-progress-holder:focus .mwi-time-tooltip {
    display: block;
    font-size: 0.6em;
    visibility: visible;
  }
</style>

<script>
  export default {
    name: 'PlayerImage',
    props: ['image', 'title'],
    data() {
      return {
        imageShowing: false,
        loaded: false,
        imagePlayer: null
      }
    },
    methods: {
      toggleImage: function (event) {
        this.imageShowing = ! this.imageShowing
      }
    }
  }
</script>
