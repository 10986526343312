<template>
    <div  v-bind:id="'comment-wrapper' + comment.id" class="comment" :class="filteredComment ? 'highlight' : ''" v-if="comment.comment !== '[deleted]' || comment.total_replies > 0" >
        <div class="info">
            <img v-bind:src="comment.comment != '[deleted]' ? comment.author_pic : '/build/images/default-profile.png'" class="comment-photo" />
            <strong>{{ comment.comment !== '[deleted]' ? comment.author_name : '[deleted]' }}</strong>,
            <i v-bind:title="comment.timestamp"> {{ comment.created }}</i>
            <div class="btn-group" v-if="comment.comment !== '[deleted]' && this.deleted == false">
                <a href="javascript:void(0)" class="dropdown-toggle waves-effect waves-light" data-toggle="dropdown" aria-expanded="false"><span class="caret"></span></a>
                <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 38px, 0px); top: 0px; left: 0px; will-change: transform;">
                    <a href="javascript:void(0)" class="dropdown-item" v-on:click="follow" v-if="authuser != comment.author && comment.following === false">Turn on Alerts</a>
                    <a href="javascript:void(0)" class="dropdown-item" v-on:click="follow" v-if="authuser != comment.author && comment.following === true">Turn off Alerts</a>
                    <a v-if="authuser == comment.author" href="javascript:void(0)" class="dropdown-item" v-on:click="edit">Edit</a>
                    <a v-if="authuser == comment.author" href="javascript:void(0)" class="dropdown-item" v-on:click="remove">Delete</a>
                </div>
            </div>
            <!-- <span class="dropdown"  v-if="comment.comment != '[deleted]'">
              <button class="btn dropdown-toggle btn-sm" type="button" data-toggle="dropdown">
              <span class="caret"></span></button>
              <ul class="dropdown-menu">
                <li v-if="authuser != comment.author">
                  <a href="#" v-on:click="follow" v-if="comment.following === false">Turn on Alerts</a>
                  <a href="#" v-on:click="follow" v-if="comment.following === true">Turn off Alerts</a>
                </li>
                <li v-if="authuser == comment.author"><a href="#" v-on:click="edit">Edit</a></li>
                <li v-if="authuser == comment.author"><a href="#" v-on:click="remove">Delete</a></li>
              </ul>
            </span> -->
        </div>
        <div v-if="comment.tags.length > 0 || comment.mentions.length > 0" style="margin-bottom: .3rem;">
          <div class="small" v-if="comment.tags.length > 0">Tags: <span class="tags">{{comment.tags.map(tag => tag.name).join(', ')}}</span></div>
          <div class="small" v-if="comment.mentions.length > 0">Mentions: <span class="tags">{{ comment.mentions.map(mention => mention.first_name + ' ' + mention.last_name).join(', ') }}</span></div>
        </div>
        <div class="body" v-bind:id="'comment' + comment.id">{{ comment.comment }}</div>
        <div class="body" v-bind:id="'edit' + comment.id" style="display:none;">
          <textarea placeholder="Type your note here..."  rows="5" maxlength="25000" class="form-control">{{ comment.comment }}</textarea>
          <button class="btn-sm btn-primary waves-effect waves-light" v-on:click="update" style="z-index: 99;">Update</button>
          <button class="btn-sm btn-secondary waves-effect waves-light" v-on:click="edit" style="z-index: 99;">Cancel</button>
        </div>
        <div class="actions" v-bind:id="'actions' + comment.id" v-show="authuser !== null" v-if="comment.comment != '[deleted]' || comment.total_replies > 0">
            <a href="javascript:void(0)" v-on:click="likeComment" :class="comment.liked === 1 ? 'liked':''" v-model="like"> {{comment.liked === 1 ? 'Liked':'Like &nbsp;'}}</a>
            <a href="javascript:void(0)" v-on:click="commentReplyClick"> Comment</a>
            <a v-if="comment.total_replies > 0" href="javascript:void(0)" v-on:click="handleReplies">{{this.showReplies ? 'Hide' : 'View'}} Replies</a>
            <span v-if="comment.total_likes > 0"> {{ comment.total_likes == 1 ? '1 like' : comment.total_likes + ' likes' }} </span>
            <!-- <span> [<a href="#" v-on:click="follow">Get notified of replies</a>] </span> -->
        </div>
        <div class="new-comment" v-if="visible">
          <textarea type="text" maxlength="25000" rows="5" v-model="reply" name="new-note" placeholder="Type your note here..." class="form-control"></textarea>

          <div class="row">
            <div class="col-md-6">
              <multiselect v-model="selectedtags" :options="tags" :taggable="true" @tag="addTag" :multiple="true" label="name" track-by="id" group-label="type" group-values="tags" placeholder="Categorize this note"></multiselect>
            </div>
            <div class="col-md-6">
              <multiselect v-model="selectedcoaches" :options="coaches" :multiple="true" label="full_name" track-by="id" placeholder="Tag coaches"></multiselect>
            </div>
          </div>

          <button class="btn btn-primary waves-effect waves-light m-t-10" v-on:click="commentReply">Add</button>
        </div>
        <div v-show="showReplies">
          <comment v-for="reply in replies" :key="reply.id" :tags="tags" :coaches="coaches" :getComments="getComments" :filter="filter" :comment="reply" :authuser="authuser" :alertableid="alertableid" :showChildren="false"/>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import Comment from './Comment'
    import Multiselect from 'vue-multiselect'

    Vue.directive('focus', {
        inserted: function (el) {
            el.focus();
        },
        update: function (el) {
            Vue.nextTick(function() {
                  el.focus();
            })
        }
    })

    export default {
        components: { Multiselect },
        name: 'Comment',
        props: {
            comment: Object,
            getComments: Function,
            authuser: String,
            tags: Array,
            coaches: Array,
            filter: Array,
            alertableid: String,
            showChildren: Boolean
        },
        data() {
            return {
                replies: [],
                like: '',
                visible: false,
                filteredComment: false,
                selectedtags: [],
                selectedcoaches: [],
                reply: '',
                deleted: false,
                showReplies: false
            }
        },

        mounted: function() {
          if (this.authuser == null) {
            this.showReplies = true;
            this.getReplies();
          }
          this.showReplies = this.showChildren
          if(this.alertableid == this.comment.id) {
            const commentElement = document.getElementById('comment-wrapper' + this.alertableid);
            const yCoord = commentElement.getBoundingClientRect().top + window.pageYOffset;
            const yOffset = -90;

            window.scrollTo({
              top: yCoord + yOffset,
              behavior: 'smooth'
            });
            document.getElementById('comment-wrapper' + this.alertableid).classList.add('focus')
          }
        },

        methods: {
            isFiltered: function() {
              this.filteredComment = false;
              this.comment.tags.forEach(tag => {
                this.filter.forEach(filter => {
                  if (filter == tag.id) {
                    this.filteredComment = true;
                  }
                })
              })
            },
            commentReplyClick: function() {
                this.visible = !this.visible
            },
            likeComment: function() {
              axios.post('/api/like', {
                likeable_type: 'Comment',
                likeable_id: this.comment.id
              }).then((response) => {
                if ( response.data.data.attached > 0 ) {
                  this.comment.total_likes = this.comment.total_likes + 1
                  this.comment.liked = 1
                }
                if ( response.data.data.detached > 0 ) {
                  this.comment.total_likes = this.comment.total_likes - 1
                  this.comment.liked = 0
                }
              })
            },
            commentReply: function() {
                const comment = this.reply
                const parentId = this.comment.id;
                const selectedtags = this.selectedtags
                const selectedcoaches = this.selectedcoaches
                const parentType = 'Comment';
                this.reply = '';
                axios.get(`/api/comments/${this.comment.id}/replies`,).then((response) => {
                  if(this.replies.length == 0) {
                    this.replies = response.data.data.concat(this.replies)
                  }
                }).then(() => {
                  axios.post('/api/comments', {commentable_type: parentType, commentable_id: parentId, comment: comment, tags: selectedtags, mentions: selectedcoaches }).then((response) => {
                    this.replies = [response.data.data].concat(this.replies)
                    this.visible = false
                    this.showReplies = true
                    this.comment.total_replies += 1
                  }).catch((error) => {
                      console.log(error)
                  })
                }).catch((error) => {
                  console.log(error)
                })
            },
            handleReplies: function() {
              if(!this.showReplies) {
                if(this.replies.length == 0) {
                  this.getReplies()
                }
              }
              this.showReplies = this.showReplies ? false : true
            },
            getReplies: function() {
              axios.get(`/api/comments/${this.comment.id}/replies`,).then((response) => {
                this.replies = response.data.data.concat(this.replies)
              }).catch((error) => {
                console.log(error)
              })
            },
            follow: function() {
              axios.post('/api/alert-subscription', {id: this.comment.id, type: 'App\\Comment'}).then((response) => {
                  this.comment.following = response.data
                  if (response.data === true) {
                    toastr.success('You will be alerted of new replies to this comment.', '');
                  } else {
                    toastr.success('Alerts for this comment are now off.', '');
                  }
              }).catch((error) => {
                  console.log(error.response.data.message)
                  toastr.error('Something went wrong: Unable to follow', '');
              })
            },
            subscribe: function() {
              axios.post('/api/subscribe', {}).then((response) => {

              }).catch((error) => {
                  console.log(error.response.data.message)
                  toastr.error('Something went wrong: Unable to subscribe', '');
              })
            },
            edit: function() {
              $('#edit' + this.comment.id).toggle();
              $('#comment' + this.comment.id).toggle();
              $('#edit' + this.comment.id + '>textarea').focus();
            },
            update: function() {
              let edited_comment = $('#edit' + this.comment.id + '>textarea').val();

              axios.patch('/api/comments/' + this.comment.id, {id: this.comment.id, comment: edited_comment }).then((response) => {
                // comment updated, show it on page
                $('#comment' + this.comment.id).html(edited_comment);
                $('#edit' + this.comment.id).toggle();
                $('#comment' + this.comment.id).toggle();
              }).catch((error) => {
                  // comment not updated
                  console.log(error.response.data.message)
                  toastr.error('Error: ' + error.response.data.message, '');
              })
            },
            remove: function() {
              Swal.fire({
                title: 'Delete comment?',
                text: "You cannot undo this action",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete!',
                cancelButtonText: 'No, cancel!',
                confirmButtonClass: 'btn btn-confirm mt-2',
                cancelButtonClass: 'btn btn-cancel ml-2 mt-2',
                reverseButtons: true
              }).then((result) => {
                if (result.value) {
                  axios.delete('/api/comments/' + this.comment.id).then((response) => {
                    // replace content with [deleted];
                    $('#comment' + this.comment.id).html('[deleted]');
                    $('#actions' + this.comment.id).toggle();
                    toastr.warning('Comment has been deleted', 'Deleted');
                    this.deleted = true
                  }).catch((error) => {
                      console.log(error)
                      toastr.error('Error: ' + error.response.data.message, '');
                  })
                } else if (result.dismiss === Swal.DismissReason.cancel) {}
              })
            },
            addTag (newTag) {
              axios.post('/api/tags', { name: newTag, type: 'custom' }).then((response) => {
                this.selectedtags.push(response.data)
                this.$root.$emit('updateTags')
              })
            }
        },

        watch: {
          filter(newVal, oldVal){
            this.isFiltered();
          },
        }
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
