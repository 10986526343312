<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.99985 6.58579L1.63589 0.221832L0.22168 1.63605L6.58564 8.00001L0.22168 14.364L1.63589 15.7782L7.99985 9.41422L14.3638 15.7782L15.778 14.364L9.41407 8.00001L15.778 1.63605L14.3638 0.221832L7.99985 6.58579Z" :fill="fill"/>
  </svg>
</template>

<script>
  export default {
    name: 'CloseIcon',
    props: {
      fill: '#fff',
    }
  }
</script>
