<template>
  <div id="weightRoomVerification">
    <div class="row">
        <div class="col-md-12 col-lg-12 col-xl-12">
            <div class="">
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a href="#health" data-toggle="tab" aria-expanded="false" class="nav-link" @click="switchTab('health')">
                            HEALTH
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#verification" data-toggle="tab" aria-expanded="true" class="nav-link active" @click="switchTab('verification')">
                            VERIFICATION
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#measurements" data-toggle="tab" aria-expanded="false" class="nav-link" @click="switchTab('measurements')">
                            MEASUREMENTS
                        </a>
                    </li>
                </ul>
                <div class="nav-border"></div>
                <div class="tab-content">
                    <div class="tab-pane" id="health">
                        <div class="row">
                            <div class="col-lg-12 col-xs-12">
                              <health />
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane active" id="verification">
                        <div class="row ml-0 mr-0">
                            <div class="col-12 pl-0 pr-0">
                              <recently-viewed />
                            </div>
                            <div class="col-12 pl-0 pr-0">
                                <table class="table datatable table-bordered display sd-alt-table" id="organization-verification-table">
                                    <thead class="table-header">
                                        <tr>
                                            <th class="d-none"></th>
                                            <th>POS</th>
                                            <th>VERIFICATION</th>
                                            <th>PLAYER & ACTIVITY</th>
                                            <th>REPS & SET</th>
                                            <th>AGE</th>
                                            <th>Weight</th>
                                        </tr>
                                    </thead>
                                    <tbody id="verification-tbody" class="text-capitalize">
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane" id="measurements">
                        <div class="row" v-if="showMeasurements">
                            <div class="col-lg-12 col-xs-12">
                              <a href="" class="add-measurement" data-dynamic-component="measurement-form" data-modal-title="Create Measurement" data-props="">
                                <span class="addBtn">+</span>
                                Add Measurement
                              </a>
                              <measurements />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
    import axios from 'axios'
    import Multiselect from 'vue-multiselect'
    import RecentlyViewed from './RecentlyViewed'
    import VerificationDetail from './VerificationDetail'

    export default {
        name: 'WeightRoom',
        props: {
        },
        data() {
            return {
              pending_measurements: [],
              showMeasurements: false,
              showHealth: false
            }
        },

        mounted: function() {
          this.initializeVerification()
        },

        methods: {
          
          initializeVerification() {
            this.createMeasurementsTable()
          },

          createMeasurementsTable() {
            let self = this
            const measurementDt = $('#organization-verification-table').DataTable({
            processing: true,
            serverSide: true,
            responsive: true,
            searching: false,
            stateSave: true,
            dom: 'frt<"bottom"lp><"bottom2"i>',
            ajax: '/api/pending-measurements?datatable=true',
            rowId: 'id',
            language: {
              paginate: {
                previous: "<i class='fa fa-angle-left'>",
                next: "<i class='fa fa-angle-right'>",
              },
            },
            columns: [
              {
                data: 'id',
                name: 'id',
                visible: false,
              },
                            {
                data: null,
                name: null,
                className: 'align-middle text-center',
                orderable: false,
                render(data, type, row, meta) {
                  let positions = row.user.profile.positions.map(p => p.shortcode).join(', ');
                  return positions.length > 0 ? positions : '-';
                },
              },
              {
                data: null,
                name: null,
                className: 'align-middle text-center',
                orderable: false,
                render(data, type, row, meta) {
                  let content = `<div style="max-width: 150px;position:relative;margin-left:auto;margin-right:auto;">`
                  if (self.getMeasurementVideo(row) != null) {
                    content += `
                      <video class="video-js" data-setup='{"controls": true, "autoplay": false, "preload": "auto", "muted": false, "loop": true}'>
                        <source src="${self.getMeasurementVideo(row)}"></source>
                      </video>`
                  } else if (this.getMeasurementImage(row)) {
                    content += `<img src="${self.getMeasurementImage(row)}" alt="Thumbnail">`
                  } 
                            
                  content += `
                    <div class="preview" style="" 
                        data-modal-component="verification-detail" 
                        data-props=":measurement_id='${row.id}'"></div>
                    </div>`
                  return content
                },
              },
              {
                data: null,
                name: null,
                className: 'align-middle text-center',
                orderable: false,
                render(data, type, row, meta) {
                  return `<h3>${row.user.name}<br>"${row.measurement.name}"</h3>`;
                },
              },
              {
                data: null,
                name: null,
                className: 'align-middle text-center',
                orderable: false,
                render(data, type, row, meta) {
                  let key = row.measurement.type.input_type + '_val';
                  return `<h3 class="gray-text">${row[key]}</h3>`;
                },
              },
              {
                data: null,
                name: null,
                className: 'align-middle text-center',
                orderable: false,
                render(data, type, row, meta) {
                  return `<h3 class="gray-text">${row.user.profile.age}</h3>`;
                },
              },
              {
                data: null,
                name: null,
                className: 'align-middle text-center',
                orderable: false,
                render(data, type, row, meta) {
                  return `<h3 class="gray-text">${row.user.profile.weight}</h3>`;
                },
              },
            ],
          });

          measurementDt.draw()
          $.fn.DataTable.ext.pager.numbers_length = 5

          },

          getMeasurementImage(measurement) {
            return measurement.photo.thumbnail_path != null 
              ? measurement.photo.thumbnail_path
              : measurement.photo.url
          },

          getMeasurementVideo(measurement) {
            return measurement.video != null
              ? measurement.video.file_upload.url
              : null
          },

          switchTab(value) {
            switch (value) {
              case 'health' :
                this.showHealth = true
                break;
              case 'measurements' :
                this.showMeasurements = true
                break;
            }

            this.resizeResponsiveElements()
          },

          resizeResponsiveElements() {
            setTimeout(() => {
               $($.fn.dataTable.tables(true)).DataTable().columns.adjust().responsive.recalc()
            }, 200)

            if ($('#recentlyViewedSlider').length > 0 ) {
              setTimeout(() => {
                $('#recentlyViewedSlider').slick('setPosition')
                console.log($('#recentlyViewedSlider').length)
              }, 200)
            }
          }

        },

        watch: {

        }
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
