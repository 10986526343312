<template>
  <button
    @click="$emit('onClick')"
    :class="`btn waves-effect waves-light ${className}`"
    :title="title"
    v-bind="$attrs"
  >
    <i v-if="isLoading" class="fa fa-spinner fa-spin"></i>
    <slot v-else></slot>
  </button>
</template>

<script>
  export default {
    name: 'IconButton',
    props: {
      title: {
        type: String,
        required: true,
      },
      className: {
        type: String,
        required: false,
      },
      isLoading: {
        type: Boolean,
        required: false,
        default: false,
      }
    }
  }
</script>

<style scoped lang="scss">
  button {
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline-flex;
    height: 2rem;
    justify-content: center;
    padding: 0;
    width: 2rem;

    svg {
      fill: #000;
    }

    &:hover {
      background-color: rgba(125,125,125,0.1);
    }

    // Commenting this out until we get further direction.
    // &:focus {
    //   background-color: rgba(125,125,125,0.1);
    //   box-shadow: 0 0 0 5px #000 !important;
    //   outline: 3px solid #fff !important;
    // }

    &:disabled {
      background-color: transparent !important;
      cursor: not-allowed;

      svg {
        fill: #777;
      }
    }
  }
</style>
