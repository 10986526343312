<template>
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4921_148462)">
      <path d="M33 16.5C33 7.3873 25.6127 0 16.5 0C7.3873 0 0 7.3873 0 16.5C0 24.7355 6.03378 31.5617 13.9219 32.7996V21.2695H9.73242V16.5H13.9219V12.8648C13.9219 8.72953 16.3853 6.44531 20.1542 6.44531C21.9589 6.44531 23.8477 6.76758 23.8477 6.76758V10.8281H21.7671C19.7175 10.8281 19.0781 12.1001 19.0781 13.4062V16.5H23.6543L22.9228 21.2695H19.0781V32.7996C26.9662 31.5617 33 24.7355 33 16.5Z" fill="#1877F2"/>
      <path d="M22.9228 21.2695L23.6543 16.5H19.0781V13.4062C19.0781 12.1014 19.7175 10.8281 21.7671 10.8281H23.8477V6.76758C23.8477 6.76758 21.9595 6.44531 20.1542 6.44531C16.3853 6.44531 13.9219 8.72953 13.9219 12.8648V16.5H9.73242V21.2695H13.9219V32.7996C15.6302 33.0668 17.3698 33.0668 19.0781 32.7996V21.2695H22.9228Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_4921_148462">
        <rect width="33" height="33" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'FacebookIcon'
}
</script>
