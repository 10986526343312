<template>
  <div :class="{ 'col-xl-12 full-review': count <= 1, 'col-xl-6': count > 1, 'player-container': true, 'overlaid': overlaid }" :id="'player-' + player.id">
    <div class="film-room-card">
      <div class="widget-user player" v-show="! overlaid">
        <div class="info wid-u-info">
          <h3 class="text-center"><a :href="`/recruit/${player.id}`">{{ player.first_name }} {{ player.last_name }}</a></h3>
        </div>
        <i class="fa fa-times hide-show" @click="toggle(player)"></i>
      </div>
      <div class="tab-content">
        <div :class="{'tab-pane': true, film: true, 'film-left': true, active: filteredFilm.length > 0 || overlaid}" v-if="filteredFilm.length > 0">
          <div class="row">
            <div class="col-sm-12">
              <div class="input-group" v-show="! overlaid && count == 1">
                <button class="toggle-video no-text-wrap" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ getFilmNameForSide('left') }} <i class=" mdi mdi-chevron-down"></i></button>
                <div class="dropdown-menu">
                  <a v-for="(film, index) in filteredFilm" :class="{'dropdown-item': true, 'active': index == active.film.left}" @click="count == 1 ? activate('film', index, 'left') : changeScript(getFilmName(film))" :key="index">{{ getFilmName(film) }}</a>
                </div>
              </div>
              <film :key="active.film.left" :film="filteredFilm[active.film.left]" :playing="(status) => playing(status)" :overlaid="overlaid" />
            </div>
          </div>
        </div>
        <div :class="{'tab-pane': true, film: true, 'film-right': true, active: filteredFilm.length > 0 || overlaid}" v-if="filteredFilm.length > 0 && count == 1">
          <div class="row">
            <div class="col-sm-12">
              <div class="input-group" v-show="! overlaid && count == 1">
                <button class="toggle-video no-text-wrap" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ getFilmNameForSide('right') }} <i class=" mdi mdi-chevron-down"></i></button>
                <div class="dropdown-menu">
                  <a v-for="(film, index) in filteredFilm" :class="{'dropdown-item': true, 'active': index == active.film.right}" @click="activate('film', index, 'right')" :key="index">{{ getFilmName(film) }}</a>
                </div>
              </div>
              <film :key="active.film.right" :film="filteredFilm[active.film.right]" :playing="(status) => playing(status)" :overlaid="overlaid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Film from './Film'

export default {
  name: 'Player',
  props: [
    'player',
    'toggle',
    'count',
    'playing',
    'overlaid',
    'changeScript',
    'selectedPosition'
  ],

  data() {
    return {
      active: {
        film: {
          left: 0,
          right: 0
        },
        interview: 0
      },
      filteredFilm: []
    }
  },

  mounted: function() {
    let self = this
    let filtered = this.filteredFilm

    self.player.footage.film.map((film) => {
      if(film.owner?.position === this.selectedPosition || (this.selectedPosition === 'P' && film?.attribute_name?.includes('pitch'))) {
        this.filteredFilm.push(film)
      }
    })
    this.$root.$on('activateFilm', function(script) {
      let active = 'nope'
      for (let i = 0; i < filtered.length; i++) {
        if (filtered[i]?.owner?.name === script) {
          active = i
        } else if (filtered[i]?.attribute_name  === script) {
          active = i
        }
      }
      self.activate('film', active, 'left')
    })
  },

  methods: {
    activate(video, value, side) {
      $('video', '.' + video).each((i, e) => {
        e.pause()
      })
      this.active[video][side] = value
    },
    getFilmNameForSide(direction){
      if (this?.filteredFilm[this.active.film[direction]]?.attribute_name?.includes('pitch')) {
        return this?.filteredFilm[this.active.film[direction]]?.attribute_name?.replace(/sb_|_pitch_video/g, '').charAt(0).toUpperCase() + this?.filteredFilm[this.active.film[direction]]?.attribute_name?.replace(/sb_|_pitch_video/g, '').slice(1).replaceAll('_', ' ')
      } else {
        return !this.filteredFilm[this.active.film[direction]]?.owner?.name ? this.filteredFilm[this.active.film[direction]]?.attribute_name : this.filteredFilm[this.active.film[direction]]?.owner?.name
      }
    },
    getFilmName(film){
      if (film?.attribute_name?.includes('pitch')) {
        return film?.attribute_name?.replace(/sb_|_pitch_video/g, '').charAt(0).toUpperCase() + film?.attribute_name?.replace(/sb_|_pitch_video/g, '').slice(1).replaceAll('_', ' ')
      } else {
        return !film?.owner?.name ? film?.attribute_name : film?.owner?.name
      }
    }
  }
}
</script>
