<template>
  <Fragment>
    <div :class="`nav-bar ${navBarClassName}`">
      <ul class="nav-bar-list">
        <li v-for="[key, value] in optionsArray" class="nav-bar-list-item">
          <button :class="`nav-bar-list-item-button ${selectedOption === key ? 'selected' : ''}`" @click="handleOptionChange(key)">
            <span :class="`nav-bar-list-item-button-span ${selectedOption === key ? 'selected' : ''}`">{{ value }}</span>
          </button>
        </li>
      </ul>
    </div>
    <div :class="`dropdown-navigation ${dropdownClassName}`" @click="toggleIsDropdownActive">
      <div :class="`navigation-menu-dropdown ${!isDropdownActive ? 'inverted' : ''}`">
        <component :is="startIcon" v-if="startIcon"></component>
        <h4 class="navigation-selected-dropdown-title">{{ options[selectedOption] }}</h4>
        <component :is="endIcon" v-if="endIcon"></component>
      </div>
      <div v-show="isDropdownActive" class="dropdown-sub-menu">
        <div v-for="[key, value] in optionsArray" class="dropdown-nav-item">
          <button v-if="selectedOption !== key" class="nav-item-button" @click="handleOptionChange(key)">
            {{ value }}
          </button>
        </div>
      </div>
    </div>
  </Fragment>
</template>

<script>
import { Fragment } from 'vue-frag';

export default {
  name: "Tabs",
  components: { Fragment },
  props: {
    navBarClassName: {
      type: String,
      default: '',
      required: false
    },
    dropdownClassName: {
      type: String,
      default: '',
      required: false
    },
    // Options should be an object with key value pairs
    options: {
      type: Object,
      default: null,
      required: true
    },
    selectedOption: {
      type: String,
      default: '',
      required: false
    },
    startIcon: {
      type: Object,
      default: null,
      required: false
    },
    endIcon: {
      type: Object,
      default: null,
      required: false
    },
  },
  data() {
    return {
      isDropdownActive: false,
    }
  },
  computed: {
    optionsArray() {
      return Object.entries(this.options)
    }
  },
  methods: {
    handleOptionChange(option) {
      this.$emit('onClick', option);
    },
    toggleIsDropdownActive() {
      this.isDropdownActive = !this.isDropdownActive;
    }
  }
}
</script>
