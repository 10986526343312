<template>
  <div class="message-app">
    <div class="chat-screen">
      <div class="chat-screen-header">
        <template v-if="activeGroup">
          <h2>{{ getUsersString(activeGroup) }}</h2>
          <div class="chat-screen-header-button" data-toggle="dropdown">
            <i class="fa fa-ellipsis-h"></i>
          </div>
          <div class="dropdown-menu">
            <a class="dropdown-item" href="#" v-on:click="visitUser()"
              >View Profile</a
            >
          </div>
        </template>
      </div>
      <div class="chat-screen-messages">
        <template v-if="loading === true">
          <div class="chat-screen-messages-loading">
            <h1>Loading</h1>
          </div>
        </template>
        <template v-if="activeGroup && loading === false">
          <template v-for="(message, index) in activeGroup.messages.data">
            <div
              :key="index"
              :class="
                message.user_id === currentUser.id
                  ? 'chat-screen-messages-message flipped'
                  : 'chat-screen-messages-message'
              "
              v-if="
                deletedMessages.indexOf(message.id) === -1 &&
                (index === 0 ||
                  activeGroup.messages.data[index - 1].user_id !==
                    message.user_id)
              "
            >
              <user-avatar
                :user="getUserFromId(message.user_id)"
                width="3rem"
                height="3rem"
                fontSize="1.5rem"
                :style="(index + 1 >= activeGroup.messages.data.length &&
                    message.user_id !== currentUser.id) ||
                  (message.user_id !== currentUser.id &&
                    activeGroup.messages.data[index + 1].user_id !==
                      message.user_id &&
                    activeGroup.users.data.length > 2)
                    ? { top: '47.5px' }
                    : null
                "
              />
              <div class="chat-screen-messages-message-content">
                <a
                  v-if="message.user_id === currentUser.id"
                  :class="
                    (messageIsLink(message.message) != 'video' &&
                    messageIsLink(message.message) != 'image'
                      ? 'non-image '
                      : '') + 'chat-action-button'
                  "
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  ><i v-if="candelete" class="message-ellipsis fa fa-ellipsis-h"></i
                ></a>
                <div v-if="candelete" class="dropdown-menu">
                  <a class="dropdown-item" @click="deleteMessage(message.id)"
                    >Delete</a
                  >
                </div>
                <p
                  class="chat-screen-messages-message-content-username"
                  v-if="
                    (index + 1 >= activeGroup.messages.data.length &&
                      message.user_id !== currentUser.id) ||
                    (message.user_id !== currentUser.id &&
                      activeGroup.messages.data[index + 1].user_id !==
                        message.user_id &&
                      activeGroup.users.data.length > 2)
                  "
                >
                  {{
                    message.user.first_name +
                    " " +
                    message.user.last_name
                  }}
                </p>
                <div
                  :class="
                    messageIsLink(message.message) === 'pdf' ||
                    messageIsLink(message.message) === false
                      ? 'chat-screen-messages-message-content-bubble'
                      : 'chat-screen-messages-message-content-media'
                  "
                >
                  <div v-if="messageIsLink(message.message) === 'image'">
                    <span
                      ><a :href="message.message" target="_blank"
                        ><img :src="message.message" alt="" /></a
                    ></span>
                  </div>
                  <div v-else-if="messageIsLink(message.message) === 'video'">
                    <span>
                      <a
                        :href="message.message"
                        target="_blank"
                        v-if="videoIsQT(message.message)"
                      >
                        <video
                          :src="message.message"
                          height="auto"
                          width="auto"
                          controls
                        ></video>
                      </a>
                      <video
                        v-else
                        :src="message.message"
                        height="auto"
                        width="auto"
                        controls
                      ></video>
                    </span>
                  </div>
                  <div v-else-if="message.endorsement">
                    <span>
                      <p>{{ message.message }}</p>
                    </span>
                    <div v-if="message.endorsement.data.active_request" v-show="!completed" type="button" class="endorsement-modal-btn" data-toggle="modal" :data-target="'#endorsementModal'">
                      <p class="endorsement-modal-btn-text">COMPLETE REQUEST</p>
                    </div>
                    <div class="modal fade" :id="'endorsementModal'" tabindex="-1" role="dialog">
                      <div class="modal-dialog" role="document">
                        <div class="modal-content endorsement-modal">
                          <div class="modal-header endorsement-header">
                            <h2 class="endorsement-header-text">{{ message.user.full_name }}</h2>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="resetState">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body endorsement-body">
                            <h4 class="endorsement-subheader">Upload Endorsement</h4>
                            <div v-if="file === null" class="endorsement-upload-box">
                              <label for="file-upload">
                                <i class="fa fa-cloud-upload fa-5x upload-icon" />
                              </label>
                              <input id="file-upload" type="file" accept="video/*" @change="selectedVideo">
                            </div>
                            <div v-else-if="file && !uploading" class="endorsement-upload-box part-two">
                              <h4 class="selected-file-name">{{ file.name }}</h4>
                              <i class="fa fa-check-square fa-5x file-check" />
                            </div>
                            <div v-else class="endorsement-upload-box part-three">
                              <h4 class="uploading-subheader">Upload in progress...</h4>
                              <h3 class="upload-percent">{{ uploadPercent }}%</h3>
                            </div>
                            <button v-show="!uploading" class="endorsement-upload-btn" @click="uploadAndSubmit(message.endorsement.data)">UPLOAD</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <!--<span v-if="messageIsLink(message.message) === 'pdf'">
                      <p>
                        <a :href="message.message" target="_blank" class="msg-hyperlink" style="text-decoration: underline;">View "{{retrieveCalendarTitle(message.message)}}" Calendar</a>
                      </p>
                    </span>  -->
                    <span><p v-html="linkifyString(message.message)"></p></span>
                  </div>
                  <span class="time-stamp"
                    ><p>{{ getShortTimeString(message) }}</p></span
                  >
                </div>
              </div>
            </div>
            <div
              v-else-if="deletedMessages.indexOf(message.id) === -1"
              :class="
                message.user_id === currentUser.id
                  ? 'chat-screen-messages-message flipped second-message'
                  : 'chat-screen-messages-message second-message'
              "
            >
              <div class="chat-screen-messages-message-content">
                <a
                  v-if="message.user_id === currentUser.id"
                  :class="
                    (messageIsLink(message.message) != 'video' &&
                    messageIsLink(message.message) != 'image'
                      ? 'non-image '
                      : '') + 'chat-action-button'
                  "
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  ><i v-if="candelete" class="message-ellipsis fa fa-ellipsis-h"></i
                ></a>
                <div v-if="candelete" class="dropdown-menu">
                  <a class="dropdown-item" @click="deleteMessage(message.id)"
                    >Delete</a
                  >
                </div>
                <p
                  class="chat-screen-messages-message-content-username"
                  v-if="
                    (index + 1 >= activeGroup.messages.data.length &&
                      message.user_id !== currentUser.id) ||
                    (message.user_id !== currentUser.id &&
                      activeGroup.messages.data[index + 1].user_id !==
                        message.user_id &&
                      activeGroup.users.data.length > 2)
                  "
                >
                  {{
                    message.user.first_name +
                    " " +
                    message.user.last_name
                  }}
                </p>
                <div
                  :class="
                    messageIsLink(message.message) === 'pdf' ||
                    messageIsLink(message.message) === false
                      ? 'chat-screen-messages-message-content-bubble'
                      : 'chat-screen-messages-message-content-media'
                  "
                >
                  <div v-if="messageIsLink(message.message) === 'image'">
                    <span
                      ><a :href="message.message" target="_blank"
                        ><img :src="message.message" alt="" /></a
                    ></span>
                  </div>
                  <div v-else-if="messageIsLink(message.message) === 'video'">
                    <span>
                      <a
                        :href="message.message"
                        target="_blank"
                        v-if="videoIsQT(message.message)"
                      >
                        <video
                          :src="message.message"
                          height="auto"
                          width="auto"
                          controls
                        ></video>
                      </a>
                      <video
                        v-else
                        :src="message.message"
                        height="auto"
                        width="auto"
                        controls
                      ></video>
                    </span>
                  </div>
                  <div v-else>
                    <span><p v-html="linkifyString(message.message)"></p></span>
                  </div>
                  <!--  <div v-else-if="messageIsLink(message.message) === 'pdf'">
                    <span><a v-if="message.user_id === currentUser.id" class="msg-hyperlink" :href="message.message" target="_blank" style="text-decoration: underline;">View "{{retrieveCalendarTitle(message.message)}}" Calendar </a></span>
                    <span><a  v-if="message.user_id !== currentUser.id" class="msg-hyperlink" :href="message.message" target="_blank" style="text-decoration: underline;">View "{{retrieveCalendarTitle(message.message)}}" Calendar</a></span>
                  </div> -->
                  <span class="time-stamp"
                    ><p>{{ getShortTimeString(message) }}</p></span
                  >
                </div>
              </div>
            </div>
            <h5
              class="date-time"
              v-if="
                index + 1 === activeGroup.messages.data.length ||
                getDateString(message) !==
                  getDateString(activeGroup.messages.data[index + 1])
              "
            >
              {{ getDateString(message) }}
            </h5>
          </template>
        </template>
        <div class="chat-screen-messages-spacer"></div>
        <div
          class="chat-screen-messages-load-more"
          v-if="
            pagination.current_page < pagination.total_pages &&
            !pagination.loading
          "
        >
          <button v-on:click="loadMore()">Load earlier messages</button>
        </div>
      </div>
      <div class="chat-input" v-if="activeGroup && loading === false">
        <div
          v-if="fan == 'no'"
          class="misc-button"
          style="margin-left: 15px"
          v-on:click="sendCoachContactInformation"
        >
          <i
            data-toggle="tooltip"
            title="Send an automated message containing your phone and email"
            class="fa fa-address-book-o"
          ></i>
        </div>

        <!-- <div v-if="fan == 'no'" class="misc-button btn dropdown" data-toggle="dropdown" aria-expanded="false"><i data-toggle="tooltip" title="Send Recruiting Calendar" class="fa fa-calendar"></i></div>
        <div class="dropdown-menu">
          <a v-for="calendar in calendars" class="dropdown-item" v-on:click="pasteLink(calendar.file.data.url)">{{ calendar.name }}</a>
        </div> -->
        <div
          v-if="fan == 'no'"
          data-toggle="tooltip"
          title="Upload and send a video file"
          class="misc-button"
          v-on:click="triggerInput"
        >
          <i class="fa fa-video-camera"></i>
          <input
            id="video-input"
            type="file"
            accept="video/*"
            style="display: none"
            v-on:change="uploadFile"
          />
        </div>
        <div
          v-if="fan == 'no'"
          data-toggle="tooltip"
          title="Upload and send an image file"
          class="misc-button"
          v-on:click="triggerInput"
        >
          <i class="fa fa-file-image-o"></i>
          <input
            id="image-input"
            type="file"
            accept="image/*"
            style="display: none"
            v-on:change="uploadFile"
          />
        </div>
        <textarea
          name="message-input"
          id="message-input"
          placeholder="Type a message..."
          v-model="currentMessage"
          v-on:keyup="(e) => chatInputListener(e, activeGroup.id)"
          rows="1"
        ></textarea>
        <div class="send-button" v-on:click="createMessage(activeGroup.id)">
          <i class="fa fa-send"></i>
        </div>
      </div>
    </div>
    <div :class="closed ? 'chat-sidebar closed' : 'chat-sidebar'">
      <div class="chat-sidebar-search">
        <p>My Conversations</p>
        <input
          type="text"
          placeholder="Search Conversations..."
          v-model="groupFilter"
        />
        <div
          :class="closed ? 'toggle-sidebar closed' : 'toggle-sidebar'"
          v-on:click="toggleSidebar()"
        >
          <i :class="closed ? 'fa fa-plus' : 'fa fa-minus'"></i>
        </div>
      </div>
      <div class="chat-sidebar-groups">
        <!-- <div class='chat-sidebar-groups-group' v-on:click="startConversation()">
          <h4 class="start-a-convo" ><i class="fa fa-plus"></i> START A CONVERSATION</h4>
        </div> -->
        <div
          class="chat-sidebar-groups-group"
          data-dynamic-component="start-conversation"
        >
          <h4 class="start-a-convo">
            <i class="fa fa-plus"></i> START A CONVERSATION
          </h4>
        </div>
        <div
          v-if="this.su || this.coach"
          class="chat-sidebar-groups-group"
          data-dynamic-component="start-group-conversation"
          :data-props="`
            su='${this.su}'
            coachname='${this.coachname}'
            coachemail='${this.coachemail}'
            coachphone='${this.coachphone}'`"
        >
          <h4 class="start-a-convo">
            <i class="fa fa-plus"></i> Send a group message
          </h4>
        </div>
        <div v-if="!ready">
          <div class="loading">
            <span>
              <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <br />
              Loading...
            </span>
          </div>
        </div>
        <template
          v-for="group in filterGroups(groups)"
          v-on:click="changeGroup(group.id)"
        >
          <div class="container"></div>
          <div
            :key="group.id"
            :class="
              activeGroup && group.id === activeGroup.id
                ? 'chat-sidebar-groups-group active'
                : 'chat-sidebar-groups-group'
            "
            v-on:click="changeGroup(group.id)"
          >
            <user-avatar :user="getFirstUser(group)" />
            <span
              v-if="group.unread_messages"
              class="badge badge-pink noti-icon-badge chat-sidebar-unread-notifier"
            >{{group.count_unread_messages}}</span>
            <div class="chat-sidebar-groups-group-info">
              <h3
                :class="
                  getFirstUser(group).profile.data.basics.data.city > 0 ||
                  (getFirstUser(group).profile.data.basics.data.school.name !=
                    null &&
                    getFirstUser(group).profile.data.basics.data.school.name
                      .length > 0)
                    ? ''
                    : 'v-centered'
                "
              >
                {{ getUsersString(group) }}
              </h3>
              <p
                v-if="
                  getFirstUser(group).profile.data.basics.data.school.name !=
                    null &&
                  getFirstUser(group).profile.data.basics.data.school.name
                    .length > 0
                "
              >
                {{ getFirstUser(group).profile.data.basics.data.school.name }}
              </p>
              <p
                v-if="
                  getFirstUser(group).profile.data.basics.data.city != null &&
                  getFirstUser(group).profile.data.basics.data.city.length > 0
                "
              >
                {{ getFirstUser(group).profile.data.basics.data.city
                }}<span
                  v-if="
                    getFirstUser(group).profile.data.basics.data.city &&
                    getFirstUser(group).profile.data.basics.data.state
                  "
                  >, </span
                >{{ getFirstUser(group).profile.data.basics.data.state }}
              </p>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import fuzzysearch from "fuzzysearch";
import UserAvatar from "@components/UserAvatar";
import {mapGetters} from 'vuex'

export default {
  name: "Messages",
  props: ["fan", "su", "coach", "coachname", "coachemail", "coachphone", "candelete"],
  components: {
    UserAvatar,
  },
  data: function () {
    return {
      ready: false,
      groups: [],
      activeChat: [],
      deletedMessages: [],
      currentUser: {},
      currentMessage: "",
      groupFilter: "",
      closed: false,
      pagination: {
        current_page: 1,
        total_pages: 1,
        loading: false,
        date_time: "",
      },
      loading: false,
      file: null,
      completed: false,
      uploading: false,
      uploadPercent: 0,
      calendars: [],
    };
  },
  watch: {
    async activeGroup(newGroup, oldGroup) {
      if (newGroup) {
        await axios.get("/api/messages/check-in/" + newGroup.id)
        await this.$store.dispatch('fetchUnreadTotal')
      }
    }
  },
  created: async function() {
    // Redesigns our page
    $(".footer").css({ display: "none" });
    $(".content").css({ padding: "0px", "margin-bottom": "0px" });
    $(".container-fluid").css({ padding: "0px" });

    // Log that the user is in this
    setInterval(async () => {
      if (this.activeGroup && this.activeGroup.id > 0) {
        await axios.get("/api/messages/check-in/" + this.activeGroup.id);
      }
    }, 10000);

    await this.$store.dispatch('fetchUserMessage');
    await this.initialMessages();

    await axios.get("/api/calendars").then((calendars) => {
      this.calendars = calendars.data.data;
    });
  },
  computed: {
    activeGroup: function () {
      const activeGroup = this.groups.filter((group) => {
        return group.active === true;
      });
      return activeGroup.length ? activeGroup[0] : undefined;
    },
    ...mapGetters(['user']),
    urlParams: function () {
      return new URLSearchParams(window.location.search);
    }
  },
  methods: {
    async initialMessages() {
      this.currentUser = this.user;
      const groups = this.$store.state.message // ugh...
      if (groups) {
        this.groups = groups.map(group => ({active: false, messages: {data: []}, ...group}));
        // Determines to either open a group, or create a placeholder one
        if (this.urlParams.get('group')) {
          await this.changeGroup(Number(this.urlParams.get('group')));
        } else if (this.urlParams.get('user') && this.urlParams.get('user') !== this.currentUser.id) {
          axios
            .get(`/api/users/${this.urlParams.get("user")}?include=profile`)
            .then((user) => {
              if (user.data.data.message_group !== null) {
                return this.changeGroup(Number(user.data.data.message_group));
              }
              this.groups.push({
                id: -Math.floor(Math.random() * 1000),
                messages: [],
                active: true,
                users: {
                  data: [this.currentUser, user.data.data],
                },
              });
            })
            .catch((error) => {
              this.loading = false;
              history.pushState({}, "Signing Day Sports", "messages");
            })
            .then(() => this.$store.dispatch('fetchUnreadTotal'));
        }
        this.ready = true;
      }
    },
    createMessage(id) {
      // Makes sure the message isn't blank
      if (id < 0 || this.currentMessage != "") {
        const message = this.currentMessage;
        this.currentMessage = "";

        let body = {};
        if (id < 0) {
          body = {
            message: message,
            user_id: new URLSearchParams(window.location.search).get("user"),
          };
        } else {
          body = {
            message: message,
            group_id: id,
          };
        }

        // Pushes a new group, and changes to it, or pushes a new message to the group
        return axios
          .post("/api/messages?include=messages,users.profile", body)
          .then((response) => {
            if (new URLSearchParams(window.location.search).get("user")) {
              response.data.data.active = false;
              this.groups.splice(
                this.groups.findIndex((group) => group.id === id),
                1
              );
              this.groups.push(response.data.data);
              this.loading = true;
              setTimeout(() => this.changeGroup(response.data.data.id), 500);
            } else {
              this.groups[
                this.groups.findIndex((v) => v.active === true)
              ].messages.data.unshift(response.data.data);
            }
          });
      }
    },
    deleteMessage(id) {
      if (this.candelete){
        return axios
          .delete("/api/messages/" + id + "/single")
          .then((response) => {
            this.activeGroup.messages.data.filter((message) => message.id === id);
            this.deletedMessages.push(id);
          });
      }
    },
    chatInputListener(e, id) {
      var input = $("textarea");
      if (e.keyCode === 13 && e.shiftKey) {
        //
      } else if (e.keyCode === 13) {
        this.createMessage(id);
      }
      input.height(0);
      input.height(input[0].scrollHeight + 1);
    },
    getUsersString(group) {
      return group.users.data
        .filter((u) => {
          return u.id !== this.currentUser.id;
        })
        .map((u) => {
          return (
            (u.role === "coach" ? "Coach " : "") +
            u.first_name +
            " " +
            u.last_name
          );
        })
        .join(", ");
    },
    filterGroups(groups) {
      if (this.groupFilter !== "") {
        return groups.filter((group) => {
          return fuzzysearch(
            this.groupFilter.toLowerCase(),
            this.getUsersString(group).toLowerCase()
          );
        });
      }
      return groups;
    },
    async changeGroup(id) {
      if (
        this.groups.findIndex((v) => v.active === true) === -1 ||
        this.groups[this.groups.findIndex((v) => v.active === true)].id !== id
      ) {
        this.loading = true;
        if (this.groups.findIndex((v) => v.active === true) !== -1) {
          this.groups[this.groups.findIndex((v) => v.active === true)].messages = { data: [] };
          this.groups[this.groups.findIndex((v) => v.active === true)].active = false;
          this.pagination.current_page = 1;
          this.pagination.total_pages = 1;
        }

        const date = new Date();

        this.pagination.date_time =
          date.getUTCFullYear() +
          "-" +
          String(date.getUTCMonth() + 1).padStart(2, 0) +
          "-" +
          String(date.getUTCDate()).padStart(2, 0) +
          " " +
          String(date.getUTCHours()).padStart(2, 0) +
          ":" +
          String(date.getUTCMinutes()).padStart(2, 0) +
          ":" +
          String(date.getUTCSeconds()).padStart(2, 0);

        if (id > 0) {
          try {
            const group = await axios.get(
              "/api/messages/" +
                id +
                "?include=messages&page=1&datetime=" +
                this.pagination.date_time
            );
            this.groups[this.groups.findIndex((v) => v.id === id)].messages = group.data.data.messages;
            this.groups[this.groups.findIndex((v) => v.id === id)].active = true;
            this.groups[this.groups.findIndex((v) => v.id === id)].unread_messages = false;
            this.pagination.current_page = 1;
            this.pagination.total_pages =
              group.data.data.messages.meta.pagination.total_pages;
            this.loading = false;

            history.pushState(
              {},
              this.getUsersString(
                this.groups[this.groups.findIndex((v) => v.id === id)]
              ),
              "messages?group=" + id
            );

            const appResponse = await axios.get("/api/app-info");
            const {
              pusher_key: pusherKey,
              pusher_cluster: pusherCluster,
            } = appResponse.data;

            this.pusher = new Pusher(pusherKey, {
              cluster: pusherCluster,
              authEndpoint: "/api/messages/authorize-pusher",
              auth: {
                headers: {
                  "X-Requested-With": "XMLHttpRequest",
                  "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
                },
              },
            });

            var channel = this.pusher.subscribe("private-groupmessages-" + id);

            channel.bind("new_message", (messageData) => {
              if (messageData.data.data.user.data.id !== this.currentUser.id) {
                this.groups[
                  this.groups.findIndex((v) => v.active === true)
                ].messages.data.unshift(messageData.data.data);
              }
            });
          } catch (error) {
            if (this.groups[this.groups.findIndex((v) => v.id === id)] === -1) {
              this.groups[
                this.groups.findIndex((v) => v.id === id)
              ].active = true;
            }

            this.loading = false;
            history.pushState({}, "Signing Day Sports", "messages");
          }
        } else {
          const userId = this.groups[
            this.groups.findIndex((v) => v.id === id)
          ].users.data.filter((u) => u.id !== this.currentUser.id)[0].id;
          history.pushState(
            {},
            this.getUsersString(
              this.groups[this.groups.findIndex((v) => v.id === id)]
            ),
            "messages?user=" + userId
          );
          this.groups[this.groups.findIndex((v) => v.id === id)].active = true;
          this.pagination.current_page = 1;
          this.loading = false;
        }
      } else if (this.groups.findIndex((v) => v.active === true) !== -1) {
        this.groups[
          this.groups.findIndex((v) => v.active === true)
        ].messages = {data:[]};
        this.groups[
          this.groups.findIndex((v) => v.active === true)
        ].active = false;
        this.pagination.current_page = 1;
        this.pagination.total_pages = 1;
        history.pushState(
          {},
          this.getUsersString(
            this.groups[this.groups.findIndex((v) => v.id === id)]
          ),
          "messages"
        );
      }
    },
    loadMore() {
      const id = this.groups.filter((g) => g.active === true)[0].id;
      const newPage = this.pagination.current_page + 1;

      this.pagination.loading = true;

      axios
        .get(
          "/api/messages/" +
            id +
            "?include=messages&page=" +
            newPage +
            "&datetime=" +
            this.pagination.date_time
        )
        .then((messages) => {
          const data = [
            ...this.groups[this.groups.findIndex((v) => v.active === true)].messages.data,
            ...messages.data.data.messages.data,
          ]
          this.groups[
            this.groups.findIndex((v) => v.active === true)
          ].messages = {
            data: data
          };
          this.pagination.current_page = newPage;
          this.pagination.loading = false;
        });
    },
    getDateString(message) {
      const date = new Date(message.created_date_string.replace(" ", "T"));
      if (date.getDate() !== new Date().getDate()) {
        return (
          date.toLocaleDateString("en-us", { month: "short" }).toUpperCase() +
          " " +
          date.getDate() +
          ", " +
          date.getUTCFullYear()
        );
      } else {
        return "TODAY";
      }
    },
    getShortTimeString(message) {
      const date = new Date(message?.created_date_string?.replace(" ", "T"));
      return (
        String(date.getHours() % 12 || 12) +
        ":" +
        String(date.getMinutes()).padStart(2, 0) +
        " " +
        (date.getHours() >= 12 ? "PM" : "AM")
      );
    },
    getFirstUser(group) {
      return group.users.data.filter((u) => u.id !== this.currentUser.id)[0];
    },
    startConversation() {
      window.location = "/recruit";
    },
    sendCoachContactInformation() {
      Swal.fire({
        title: "Send this player your contact information?",
        text:
          "This will send an automated message to the player, containing your email and phone number.",
        type: "question",
        showCancelButton: true,
        confirmButtonText: "Send contact information!",
        cancelButtonText: "Cancel!",
        confirmButtonClass: "btn btn-confirm mt-2",
        cancelButtonClass: "btn btn-cancel ml-2 mt-2",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          var oldMessage = this.currentMessage;

          this.currentMessage =
            this.coachname +
            " has chosen to send you their contact information: \nEmail: " +
            this.coachemail +
            (!!this.coachphone ? "\nPhone: " + this.coachphone : "");

          this.createMessage(this.activeGroup.id);

          this.currentMessage = oldMessage;
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
    },
    triggerInput(e) {
      $(e.target).closest("div").find("input").trigger("click");
    },
    uploadFile(e) {
      if (this.activeGroup.id < 0) {
        this.currentMessage = "";
        return this.createMessage(this.activeGroup.id).then(() => {
          setTimeout(() => this.uploadFile(e), 1000);
        });
      }

      const data = new FormData();
      data.append("file", e.target.files[0], e.target.files[0].name);
      data.append("model", "DirectMessageGroup");
      data.append("id", this.activeGroup.id);

      axios
        .post("/api/upload-file", data)
        .then((file) => {
          toastr.success('File uploaded successfully', '')
          this.pasteLink(file.data.data.url);
        })
        .catch((err) => {
          toastr.error('Error: Unable to upload file', '')
        });
    },
    pasteLink(link) {
      this.currentMessage += link;
      this.createMessage(this.activeGroup.id);
    },
    visitUser() {
      window.location =
        "/recruit/" +
        this.activeGroup.users.data.filter((u) => {
          return u.id !== this.currentUser.id;
        })[0].id;
    },
    messageIsLink: function (message) {
      if (linkify.find(message).length > 0) {
        let fileExt = message.split(".").pop();
        if ($.inArray(fileExt, ["jpeg", "jpg", "png"]) !== -1) {
          return "image";
        } else if ($.inArray(fileExt, ["mov", "mp4", "qt"]) !== -1) {
          return "video";
        } else if ($.inArray(fileExt, ["pdf"]) !== -1) {
          return "pdf";
        }
      }
      return false;
    },
    videoIsQT: function (message) {
      return $.inArray(message.split(".").pop(), ["qt"]) === -1;
    },
    linkifyString: function (message) {
      return linkifyStr(message, {
        className: "msg-hyperlink",
        target: "_blank",
      });
    },
    toggleSidebar: function () {
      this.closed = this.closed ? false : true;
    },
    getUserFromId: function (id) {
      return this.activeGroup.users.data?.filter(({id: i}) => i === id)[0];
    },
    getProfileForUserId: function (id) {
      return this.activeGroup.users.data?.filter(({id: i}) => i === id)[0].profile.data
    },
    selectedVideo(event) {
      this.file = event.target.files[0];

    },
    async uploadAndSubmit(endorsement) {
      this.uploading = true;
      const data = new FormData();
      data.append("id", endorsement.id);
      data.append("file", this.file);
      const config = {
        onUploadProgress: event => {
          let percentCompleted = Math.floor( (event.loaded * 95) / event.total );
          if (percentCompleted > this.uploadPercent) this.uploadPercent = percentCompleted;
        }
      }
      const response = await axios.post("/api/endorsements/file-upload", data, config);
      if (response.status === 200) {
        this.completed = true;
        toastr.success('File uploaded successfully', '')
      } else {
        toastr.error('Error: Unable to upload file', '')
      }
      $('.close').click();
    },
    resetState() {
      this.file = null;
      this.uploading = false;
      this.uploadPercent = 0;
    }
  },
};
</script>
